import React from 'react'
import { withTranslation } from 'react-i18next'
import RoomGroupCard from './RoomGroupCard'
import SupplierStatBox from '../SupplierStatBox/SupplierStatBox'
import SupplierFeaturesBox from '../SupplierFeaturesBox/SupplierFeaturesBox'
import TagsBox from '../TagsBox/TagsBox'
import { Col, Row } from 'reactstrap'
import PropTypes from 'prop-types'

import './RoomsTabContent.scss'

class RoomsTabContent extends React.Component {
  constructor (props) {
    super(props)

    this.getHotelRoomInformation = this.getHotelRoomInformation.bind(this)
  }

  getHotelRoomInformation () {
    const hotelRoomInformation = []

    if (this.props.supplier) {
      this.props.supplier.room_groups.forEach((roomGroup) => {
        hotelRoomInformation.push({
          label: roomGroup.room_type[`name_${this.props.i18n.language}`],
          value: roomGroup.number_of_rooms
        })
      })
    }

    return hotelRoomInformation
  }

  render () {
    const { t } = this.props

    const hotelRoomInformation = this.getHotelRoomInformation()
    const generalRoomsInformation = this.props.supplier && this.props.supplier.general_rooms_information

    return (
      <>
        {this.props.supplier ? (
          <>
            <div className='container'>
              <Row className='m-0 mb-3'>
                {hotelRoomInformation.length > 0 ? (
                  <Col className='mb-3' xs={12} md={6}>
                    <SupplierFeaturesBox
                      title={t('hotel_room_information')}
                      features={this.getHotelRoomInformation()}
                    />
                  </Col>
                ) : (
                  <Col className='mb-3' xs={12} md={6}>
                    <div
                      className='c-content-media-1 contentBox round-element-no-shadow'
                      style={{ height: '100% !important' }}
                    >
                      <div className='c-content-label c-font-uppercase c-font-bold c-bg-regular'>
                        {t('hotel_room_information')}
                      </div>
                      <p style={{ fontSize: '1.0rem' }}>{t('hotel_room_no_information')}</p>
                    </div>
                  </Col>
                )}
                {generalRoomsInformation ? (
                  <>
                    <Col className='mb-3' xs={12} md={6}>
                      <SupplierStatBox
                        title={t('number_of_rooms')}
                        stat={generalRoomsInformation.number_of_rooms}
                      />
                    </Col>
                    {generalRoomsInformation.facilities.length > 0 ? (
                      <Col xs={12} md={12} className='mt-3'>
                        <TagsBox
                          title={t('amenities')}
                          tags={generalRoomsInformation.facilities}
                        />
                      </Col>
                    ) : null}
                  </>
                ) : null}
              </Row>
              <Row className='m-0'>
                {this.props.supplier.room_groups ? (
                  this.props.supplier.room_groups.map((roomGroup) => (
                    <Col key={roomGroup.id} className='mt-3 mb-3' md={6}>
                      <RoomGroupCard
                        roomGroup={roomGroup}
                      />
                    </Col>
                  ))
                ) : null}
              </Row>
            </div>
          </>
        ) : null}
      </>
    )
  }
}

RoomsTabContent.propTypes = {
  supplier: PropTypes.object
}

export default withTranslation()(RoomsTabContent)
