import React from 'react'
import { Col, Row } from 'reactstrap'
import './pages.scss'
import { withTranslation } from 'react-i18next'
import { scrollToTop } from '../../shared/utils'

class Terms extends React.Component {
  renderDe () {
    return (
      <div className='containerFluid' style={{ paddingTop: '40px', paddingBottom: '40px' }}>
        Stand September 2024
        <div className='c-content-title-1'>
          <h1 className='c-font-uppercase'>
            <a className='client-theme--color' href='#serviceProvidersTermsValidFrom'>AGB - Nutzungsbedingungen für Anbieter - Gültig ab 1. November 2024</a>
          </h1>
          <h1 className='c-font-uppercase'>
            <a className='client-theme--color' href='#serviceProvidersTerms'>AGB - Nutzungsbedingungen für Anbieter - Gültig bis 31. Oktober 2024</a>
          </h1>
          <h1 className='c-font-uppercase'>
            <a className='client-theme--color' href='#customersTerms'>AGB - Nutzungsbedingungen für Kunden</a>
          </h1>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        {/* START NEW */}
        <div className='c-content-title-1'>
          <h1 className='c-font-uppercase' id='serviceProvidersTermsValidFrom'>Nutzungsbedingungen für Anbieter - Gültig ab 1. November 2024</h1>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 1 Gegenstand der Nutzungsbedingungen</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Die MICE Portal GmbH (nachfolgend „MICE" genannt) stellt auf www.miceportal.de eine online Buchungsplattform zur Verfügung (nachfolgend <b>„Portal"</b> genannt), über die Hotels, Eventlocations oder Eventanbieter (zusammen <b>„Anbieter"</b>) einen Eintrag erstellen, mit Kunden in Kontakt treten und Buchungen registrierter Kunden annehmen können. Anbieter können ihren individuellen persönlichen Eintrag für ihre Angebote erstellen und die weiteren, auf dem Portal jeweils aktuell zur Verfügung stehenden unentgeltlichen sowie entgeltlichen Dienste im Rahmen der jeweiligen Verfügbarkeit nutzen. Nähere Informationen zu den Diensten finden sich in der Beschreibung der Dienste, § 8.</p>
        <p>(2) Die vorliegenden Nutzungsbedingungen regeln die Zurverfügungstellung der Dienste durch MICE und die Nutzung dieser Dienste durch Anbieter.</p>
        <p>(3) Informationen zu den Datenschutzrichtlinien bei MICE erhalten Sie <a className='client-theme--color' href='https://corporate.miceportal.com/datenschutz' target='_blank' rel='noopener noreferrer'>hier</a>.</p>
        <p>(4) Diese Nutzungsbedingungen finden nur gegenüber Anbietern Anwendung, die Unternehmer im Sinn des § 14 BGB sind.</p>

        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 2 Änderungen der Nutzungsbedingungen</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE behält sich vor, diese Nutzungsbedingungen jederzeit mit Wirksamkeit auch innerhalb der bestehenden Vertragsverhältnisse zu ändern, soweit die einzelnen Änderungen für Anbieter zumutbar sind und sich die Notwendigkeit einer Änderung durch folgende Gründe ergeben: Gesetzliche Änderungen, Änderungen in der Rechtsprechung, bestehende Unklarheiten in den Nutzungsbedingungen, Sicherheitsaspekte des Portals, Änderungen der Funktionen des Portals. Im Rahmen einer solchen Änderung können folgende Regelungen der Nutzungsbedingungen nicht verändert werden: Der Vertragstyp, die wesentlichen Geschäftseigenschaften („essentialia negotii") und die Haftungsbeschränkung. Über derartige Änderungen wird MICE die Anbieter mindestens 30 Kalendertage vor dem geplanten Inkrafttreten der Änderungen in Kenntnis setzen. Sofern die Anbieter nicht innerhalb von 30 Tagen ab Zugang der Mitteilung widersprechen und die Inanspruchnahme der Dienste auch nach Ablauf der Widerspruchsfrist fortsetzen, gelten die Änderungen ab Fristablauf als wirksam vereinbart. Im Falle eines Widerspruchs wird der Vertrag zu den bisherigen Bedingungen fortgesetzt. In der Änderungsmitteilung wird MICE die Anbieter auf ihr Widerspruchsrecht und auf die Folgen hinweisen.</p>
        <p>(2) Bei Änderungen der Umsatzsteuer ist MICE zu einer dieser Änderung entsprechenden Anpassung der Vergütung für kostenpflichtige Dienstleistungen wie z.B. „MICE ADS" und „MICE Professional Account" (siehe § 8) berechtigt, ohne dass das vorgenannte Widerspruchsrecht besteht.</p>
        <p>(3) MICE ist jederzeit berechtigt, auf dem Portal unentgeltlich bereitgestellte Dienste zu ändern, neue Dienste unentgeltlich oder entgeltlich verfügbar zu machen und die Bereitstellung unentgeltlicher Dienste einzustellen. MICE wird hierbei jeweils auf die berechtigten Interessen des Anbieter Rücksicht nehmen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 3 Anmeldung auf dem Portal</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Die Nutzung der auf dem Portal verfügbaren Dienste setzt eine Anmeldung der Anbieter voraus. Ein Anspruch auf die Teilnahme besteht nicht. Teilnahmeanträge können von MICE bei Vorliegen eines sachlich gerechtfertigten Grundes zurückgewiesen werden.</p>
        <p>(2) Die Anmeldung ist nur Unternehmen im Sinn des § 14 BGB erlaubt.</p>
        <p>(3) MICE bietet Anbietern die Wahl zwischen einem Basis-Account und einem Professional-Account. Die Anmeldung zum Basis-Account ist kostenfrei und beinhaltet die Akzeptanz dieser Nutzungsbedingungen. Der Professional-Account bietet gegen Zahlung einer monatlichen Nutzungsvergütung ein deutlich erweitertes Leistungsangebot. Die aktuelle für den Professional-Account zu zahlende Nutzungsvergütung wird während des Anmeldevorgangs angezeigt. Näheres zum jeweiligen Leistungsangebot findet sich unter § 8.</p>
        <p>(4) Anbieter können jederzeit vom Basis-Account zum Professional-Account wechseln. Hierzu müssen Anbieter in dem Portal über ihren persönlichen Einstellungen auf den Reiter „Marketing" wechseln und dort das passende Produkt auswählen.</p>
        <p>(5) Die während des Anmeldevorgangs von MICE erfragten Kontaktdaten und sonstigen Angaben müssen vom Anbieter vollständig und korrekt angegeben werden. Bei der Anmeldung einer juristischen Person ist zusätzlich die vertretungsberechtigte natürliche Person anzugeben.</p>
        <p>(6) Nach Angabe aller erfragten Daten, werden diese von MICE auf Vollständigkeit und Plausibilität überprüft. Sind die Angaben aus Sicht von MICE korrekt und bestehen aus Sicht von MICE keine sonstigen Bedenken, schaltet MICE den beantragten Zugang frei und benachrichtigt den Anbieter hiervon per E-Mail. Die E-Mail gilt als Annahme des Antrags auf Abschluss eines Vertrages nach den vorliegenden AGB.</p>
        <p>(7) Die Freischaltung des kostenpflichtigen „Professional Account" oder eines anderen Marketing-Produkts erfolgt nach Annahme des Angebots von MICE durch den Anbieter. Die Vertragslaufzeit der Marketingprodukte wie z.B. den Professional Account ergibt sich aus dem Angebot von MICE und beschreibt auch den Abrechnungszeitraum. Zur Klarstellung: Diese AGB gelten bereits ab dem Moment der Freischaltung des Accounts. Das gilt auch dann, wenn der Account vor Beginn der im Angebot genannten Vertragslaufzeit freigeschaltet wird.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 4 Verantwortung für die Zugangsdaten</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Im Verlauf des Anmeldevorgangs werden die Anbieter gebeten, einen Benutzernamen und ein Passwort anzugeben. Mit diesen Daten können Anbieter sich nach der Freischaltung des Zugangs und der Bestätigung gem. § 4 (3) auf dem Portal einloggen und ihren Eintrag bearbeiten. Es liegt in der Verantwortung des Anbieters, dass der Benutzername nicht Rechte Dritter, insbesondere keine Namens- oder Markenrechte verletzt und nicht gegen die guten Sitten verstößt.</p>
        <p>(2) Die Zugangsdaten einschließlich des Passworts sind geheim zu halten und unbefugten Dritten nicht zugänglich zu machen.</p>
        <p>(3) Es liegt weiter in der Verantwortung des Anbieters sicher zu stellen, dass der Zugang zu dem Portal und die Nutzung der auf dem Portal zur Verfügung stehenden Dienste ausschließlich durch den Anbieter bzw. durch der von diesen bevollmächtigten Personen erfolgt. Steht zu befürchten, dass unbefugte Dritte von den Zugangsdaten Kenntnis erlangt haben oder erlangen werden, ist MICE unverzüglich zu informieren.</p>
        <p>(4) Anbieter haften für jedwede Nutzung und/oder sonstige Aktivität, die unter deren Zugangsdaten ausgeführt wird, nach den gesetzlichen Bestimmungen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 5 Eintrag im Portal</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Anbieter können unter Beachtung der nachfolgenden Regelungen einen Eintrag erstellen und Inhalte auf dem Portal einstellen und damit für Dritte, insbesondere Kunden, verfügbar machen.</p>
        <p>(2) Anbieter können im Rahmen der vorliegenden Nutzungsbedingungen und den Vorgaben von MICE ihren Eintrag im Portal individuell gestalten.</p>
        <p>(3) MICE führt im Regelfall keine Überprüfung der Angaben in den Einträgen durch. MICE behält sich dabei das Recht vor, angegebene Attribute und Eigenschaften, sofern sie nicht der Wahrheit entsprechen oder zu einem irritierenden Suchergebnis führen, zu ändern.</p>
        <p>(4) Bei der Wahl der Bilder im Rahmen des Eintrags eines Professional Accounts behält MICE sich das Recht vor, andere Bilder anzufordern, wenn die Qualität nicht den Anforderungen entspricht, die im Portal auf der entsprechenden Seite hinterlegt sind oder die Aussagekraft nicht den Vorstellungen von MICE entspricht.</p>
        <p>(5) Mit dem Einstellen von Inhalten räumen Anbieter MICE jeweils ein unentgeltliches und übertragbares Nutzungsrecht an den jeweiligen Inhalten ein, insbesondere</p>
        <p>– zur Speicherung der Inhalte auf dem Server von MICE sowie deren Veröffentlichung, insbesondere deren öffentlicher Zugänglichmachung (z.B. durch Anzeige der Inhalte auf dem Portal),</p>
        <p>– zur Bearbeitung und Vervielfältigung, soweit dies für die Vorhaltung bzw. Veröffentlichung der jeweiligen Inhalte erforderlich ist.</p>
        <p>Soweit Anbieter die eingestellten Inhalte wieder von dem Portal herunternehmen, erlischt das an MICE vorstehend eingeräumte Nutzungs- und Verwertungsrecht. MICE bleibt jedoch berechtigt, zu Sicherungs- und/oder Nachweiszwecken erstellte Kopien aufzubewahren.</p>
        <p>(6) Anbieter sind für die von ihnen eingestellten Inhalte voll verantwortlich. MICE übernimmt nicht zwingend eine Überprüfung der Inhalte auf Vollständigkeit, Richtigkeit, Rechtmäßigkeit, Aktualität, Qualität und Eignung für einen bestimmten Zweck.</p>
        <p>(7) Anbieter erklären und gewährleisten gegenüber MICE daher, dass sie der alleinige Inhaber sämtlicher Rechte, an den von ihnen auf dem Portal eingestellten Inhalten sind, oder aber anderweitig berechtigt sind (z.B. durch eine wirksame Erlaubnis des Rechteinhabers), die Inhalte auf dem Portal einzustellen und die Nutzungs- und Verwertungsrechte nach dem vorstehenden Absatz (5) zu gewähren.</p>
        <p>(8) MICE behält sich das Recht vor, das Einstellen von Inhalten abzulehnen und/oder bereits eingestellte Inhalte ohne vorherige Ankündigung zu bearbeiten, zu sperren oder zu entfernen, sofern das Einstellen der Inhalte durch den Anbieter oder die eingestellten Inhalte selbst zu einem Verstoß gegen § 10 geführt haben. MICE wird hierbei jedoch auf Ihre berechtigten Interessen Rücksicht nehmen und das mildeste Mittel zur Abwehr des Verstoßes gegen § 10 wählen.</p>
        <p>(9) Anbieter sind dazu verpflichtet, ihre Daten (einschließlich der Kontaktdaten) aktuell zu halten. Tritt während der Dauer der Teilnahme eine Änderung der angegebenen Daten ein, so sind die Angaben unverzüglich auf dem Portal zu korrigieren.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 6 Beendigung der Teilnahme</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Den Basis-Account können Anbieter jederzeit kündigen, indem diese sich von der Teilnahme am Portal abmelden.</p>
        <p>(2) Für den Professional-Account gilt eine Mindestvertragslaufzeit von 12 Monaten, beginnend mit dem im Angebot genannten Vertragsbeginn (§ 3 Abs. 7). Ab dem Ablauf der Mindestvertragslaufzeit verlängert sich der Vertrag jeweils automatisch um Verlängerungsperioden von jeweils 12 Monaten, sofern nicht eine Partei den Vertrag unter Einhaltung einer Kündigungsfrist von 8 Kalenderwochen vor Ablauf der Mindestvertragslaufzeit bzw. der jeweiligen Verlängerungsperiode kündigt.</p>
        <p>(3) Mit Wirksamwerden der Kündigung endet das Vertragsverhältnis und Anbieter dürfen Ihren Zugang nicht mehr nutzen. MICE behält sich vor, den Benutzernamen sowie das Passwort mit Wirksamwerden der Kündigung zu sperren.</p>
        <p>(4) MICE ist berechtigt, mit Ablauf von 30 Kalendertagen nach Wirksamwerden der Kündigung und nach Ablauf etwaiger gesetzlicher Vorhaltungsfristen sämtliche im Rahmen der Teilnahme entstandenen Daten unwiederbringlich zu löschen, sofern MICE nicht aus datenschutzrechtlichen Gründen sogar dazu verpflichtet ist, diese Daten früher zu löschen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 7 Buchungen und Vertragsabschluss mit Kunden</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Wenn ein Kunde eine Buchung über das Portal bei einem Anbieter anfragt und der Anbieter mit dem Kunden einen entsprechenden Vertrag abschließt, werden Kunde und Anbieter Vertragspartner. Es gelten in diesem Verhältnis, zwischen Kunde und Anbieter, ausschließlich die vertraglichen Regelungen dieser Parteien, die im Vertrag genannt werden.</p>
        <p>(2) Wenn der Anbieter den Kunden kontaktiert und diesen auffordert seine Anfrage nicht im Portal, sondern direkt an diesen zu schicken, um eine Buchung im Portal zu umgehen, hat MICE das Recht, den Eintrag des Anbieters zu löschen.</p>
        <p>(3) MICE erhebt bei Zustandekommen einer Buchung, deren Anfrage und Angebot auf dem Portal initiiert wurde, eine Vermittlungsprovision in Höhe von 12% auf alle im Angebot bzw. im Reservierungsvertrag gelisteten, gebuchten und in Anspruch genommenen Leistungen.</p>
        <p>MICE berechnet die Vermittlungsprovision nach Abschluss der Veranstaltung nur anhand der tatsächlich in Anspruch genommenen Leistungen. Zur Klarstellung: Hierbei erfolgt keine Anpassung des Prozentsatzes aber eine Anpassung soweit die tatsächlich in Anspruch genommene und abgerechnete Leistung von der gebuchten Leistung abweicht.</p>
        <p>Leistungen, die ein Anbieter von seinem Subunternehmer („Dritten") bezieht, unterliegen nicht dieser Kommissionsregelung.</p>
        <p>(4) Die Verpflichtung zur Zahlung einer <b>Vermittlungsprovision entfällt</b>, wenn zum Zeitpunkt der Angebotsabgabe der Anbieter für den Zeitpunkt des Veranstaltungsdatums als Rahmenvertragspartner in der entsprechenden Kundeninstanz von MICE hinterlegt ist.</p>
        <p>Dies ist der Fall, wenn die kommerziellen Daten dieses Rahmenvertrages in der MICE Portal Instanz des Kunden freigeschaltet sind. Freigeschaltet bedeutet, dass der Kunde die Daten an MICE übergibt und MICE diese für das Portal live schaltet.</p>
        <p>Für Buchungen, die außerhalb des vereinbarten und hinterlegten Rahmenvertragszeitraums erfolgen, ist der Anbieter verpflichtet, die Provision gemäß Absatz (3) zu zahlen und dies dementsprechend in seinem Angebot zu berücksichtigen. Die Ablehnung der Provision aufgrund einer Berufung auf einen zukünftigen Rahmenvertrag kann nicht berücksichtigt werden.</p>
        <p>In diesem Fall (also im Fall einer Buchung außerhalb des vereinbarten und hinterlegten Rahmenvertragszeitraums) erhält der Anbieter die Anfrage des Kunden mit der Aufforderung 12% Kommission in seine Angebotspreise zu inkludieren. Stellt sich zum Zeitpunkt der Veranstaltung heraus, dass Kunde und Anbieter in der Zwischenzeit einen Rahmenvertrag geschlossen haben und diese Raten auch im MICE Portal hinterlegt wurden, liegt es im Ermessen von MICE ihre Provisionsansprüche an den Anbieter um 50% zu reduzieren.</p>
        <p>(5) Nach Veranstaltungsende sendet MICE dem Anbieter eine Aufforderung zu, die IST-Kosten der Veranstaltung im MICE Portal zu hinterlegen, um die Provisionsrechnung auf Grund der tatsächlich in Anspruch genommenen Daten zu erheben. Der Anbieter ist verpflichtet, die Daten innerhalb von 3 Arbeitstagen nach Rechnungserstellung im Portal zu hinterlegen. Verstößt der Anbieter gegen diese Verpflichtung, ist MICE berechtigt, die Provision anhand des vertragsgegenständlichen Reservierungsbetrages zu berechnen. Darüber hinaus dienen diese Rechnungsinformationen, je nach Kundenvereinbarung für eine schnelle Rechnungsbearbeitung. Bei Abweichungen der in Anspruch genommenen Leistungen, hat MICE das Recht die Kundenrechnung einzusehen. Die Vermittlungsprovision bezieht sich auf den netto Betrag zuzüglich der zum Zeitpunkt der Rechnungsstellung gültigen Mehrwertsteuer.</p>
        <p>(6) Für den Fall, dass eine Reservierung kostenpflichtige storniert wurde und hierfür eine Stornorechnung erstellt wird, ist MICE berechtigt, die Vermittlungsprovision in Höhe von 12 % gemäß Absatz 3 auf die Stornorechnung zu erheben.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 8 Inanspruchnahme der Dienste</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE bietet auf dem Portal sowohl unentgeltliche (Basic-Account) als auch kostenpflichtige Dienste wie den Professional Account und weitere Marketing- und Werbeprodukte an. Soweit zwischen den Parteien nichts anderes vereinbart ist, gelten die Preise gemäß aktueller Preisliste. Aktualisierte Preise für zukünftige Dienste werden per E-Mail im PDF-Format versendet.</p>
        <p>(2) Der konkrete Umfang des Professional-Accounts und Basic-Accounts kann der <a className='client-theme--color' href='https://corporate.miceportal.com/de/mice-online-marketing' target='_blank' rel='noopener noreferrer'>Website</a> entnommen werden. Beide beinhalten die Möglichkeit eines Eintrages mit unlimitiertem Inhalt, Teilnahme am Buchungsportal und Support. Generell gibt es im Rahmen des kostenfreien Basic-Accounts aber kein Recht auf Anzeige des Eintrags im Portal. Ebenso hat der Anbieter keinen Anspruch auf ein bestimmtes Ranking seines Eintrages. Das Ranking erfolgt anhand eines „Qualitätsscore", der <a className='client-theme--color' href='https://1789719.fs1.hubspotusercontent-eu1.net/hubfs/1789719/Partner%20Managment/Datenscore-factsheetDE.pdf' target='_blank' rel='noopener noreferrer'>[hier]</a> genauer erläutert wird. Der Erwerb der folgenden Zusatzfunktionen hat einen Einfluss auf das Ranking.</p>
        <p>(3) Der Professional-Account umfasst zusätzlich Leistungen wie hervorgehobenes Ranking, Analyse Tools, Direktsuche, Message Center und gesteigerten Support. Die Nutzung der innerhalb des Professional-Accounts verfügbaren Dienste ist mit der monatlichen Nutzungsvergütung für die Registrierung des Professional Accounts abgegolten. Bestehet zum Zeitpunkt der Angebotsabgabe ein Professional-Accounts vermindern sich die Provisionsansprüche für diese Buchung gemäß § 7 von 12% auf 10%. Im Übrigen gilt entsprechen § 7.</p>
        <p>(4) Dienstleiser können MICE-Ads zubuchen, um entsprechende Werbeanzeigen im Portal zu schalten. Dadurch lässt sich die Reichweite erhöhen und die Zielgruppe besser erreichen. Der konkreten Funktionen von MICE-Ads können der Webseite entnommen werden.</p>
        <p>(5) Für den kostenpflichtigen Professional Account sowie für sämtliche kostenpflichtige Dienste (auch solche, die innerhalb des Basic-Accounts zugänglich sind) gewährleistet MICE in seinem Verantwortungsbereich eine Verfügbarkeit von 99 % im Monatsmittel. Nicht in die Berechnung der Verfügbarkeit fallen die regulären Wartungsfenster des Portals, die jeden Sonntag zwischen 18:00 und 5:00 Uhr liegen.</p>
        <p>(6) Sämtliche angegebenen Entgelte verstehen sich zuzüglich der jeweils geltenden gesetzlichen Mehrwertsteuer. Die Freischaltung Ihres Professional Accounts erfolgt spätestens nach zwei Werktagen nach Angebotsannahme. Die Vertragslaufzeit entspricht den Angaben aus dem Angebot (vgl. § 3 Abs. 7). Die Zahlung erfolgt jährlich zu Beginn der im Angebot genannten Vertragslaufzeit. Zahlungsziel 14 Tage ohne Abzug. Bei Kauf eines Werbeplatzes erhalten Sie bis spätestens 30 Tage vor Aktivierung Ihrer Werbung eine gesonderte Rechnung.</p>
        <p>(7) Bei Verzug ist MICE berechtigt, Verzugszinsen in Höhe von 9 Prozentpunkte über dem Basiszinssatz zu verlangen, wenn der Anbieter nicht einen geringeren oder MICE einen höheren Schaden nachweist.</p>
        <p>(8) Die Aufrechnung ist nur mit unbestrittenen oder rechtskräftig festgestellten Gegenforderungen erlaubt. Ein Zurückbehaltungsrecht kann nur geltend gemacht werden, wenn es auf demselben Vertragsverhältnis beruht.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 9 Schutz der Inhalte, Verantwortlichkeit für Inhalte Dritter</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Die auf dem Portal verfügbaren Inhalte stammen teilweise von MICE und teilweise von anderen Anbietern bzw. sonstigen Dritten. Inhalte der Anbieter sowie sonstiger Dritter werden nachfolgend zusammenfassend „Drittinhalte" genannt. MICE führt bei Drittinhalten keine Prüfung auf Vollständigkeit, Richtigkeit und Rechtmäßigkeit durch und übernimmt daher keinerlei Verantwortung oder Gewährleistung für die Vollständigkeit, Richtigkeit, Rechtmäßigkeit und Aktualität der Drittinhalte, sofern die Rechtswidrigkeit nicht offensichtlich und MICE bekannt ist. Dies gilt auch im Hinblick auf die Qualität der Drittinhalte und deren Eignung für einen bestimmten Zweck, und auch, soweit es sich um Drittinhalte auf verlinkten externen Webseiten handelt.</p>
        <p>(2) Sämtliche Inhalte auf dem Portal sind Drittinhalte, ausgenommen diejenigen Inhalte, die mit einem Urheberrechtsvermerk von MICE versehen sind.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 10 Verbotene Aktivitäten</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Anbietern sind jegliche Aktivitäten auf bzw. im Zusammenhang mit dem Portal untersagt, die gegen geltendes Recht verstoßen, Rechte Dritter verletzen oder gegen die Grundsätze des Jugendschutzes verstoßen. Insbesondere sind folgende Handlungen untersagt:</p>
        <p>– das Einstellen, die Verbreitung, das Angebot und die Bewerbung pornografischer, gegen Jugendschutzgesetze, gegen Datenschutzrecht und/oder gegen sonstiges Recht verstoßender und/oder betrügerischer Inhalte, Dienste und/oder Produkte;</p>
        <p>– die Verwendung von Inhalten, durch die andere Anbieter, Kunden oder Dritte beleidigt oder verleumdet werden;</p>
        <p>– die Nutzung, das Bereitstellen und das Verbreiten von Inhalten, Diensten und/oder Produkten, die gesetzlich geschützt oder mit Rechten Dritter (z.B. Urheberrechte) belastet sind, ohne hierzu ausdrücklich berechtigt zu sein.</p>
        <p>(2) Des Weiteren sind Anbietern auch unabhängig von einem eventuellen Gesetzesverstoß bei der Einstellung eigener Inhalte auf dem Portal sowie bei der Kommunikation mit Kunden (z.B. durch Versendung persönlicher Mitteilungen) die folgenden Aktivitäten untersagt:</p>
        <p>– die Verbreitung von Viren, Trojanern und anderen schädlichen Dateien;</p>
        <p>– die Versendung von Junk- oder Spam-Mails sowie von Kettenbriefen;</p>
        <p>– die Verbreitung anzüglicher, anstößiger, sexuell geprägter, obszöner oder diffamierender Inhalte bzw. Kommunikation sowie solcher Inhalte bzw. Kommunikation die geeignet sind/ist, Rassismus, Fanatismus, Hass, körperliche Gewalt oder rechtswidrige Handlungen zu fördern bzw. zu unterstützen (jeweils explizit oder implizit);</p>
        <p>– die Belästigung von Kunden, z.B. durch mehrfaches persönliches Kontaktieren ohne oder entgegen der Reaktion des Kunden sowie das Fördern bzw. Unterstützen derartiger Belästigungen;</p>
        <p>– die Aufforderung von Kunden zur Preisgabe von Kennwörtern oder personenbezogener Daten für kommerzielle oder rechts- bzw. gesetzeswidrige Zwecke;</p>
        <p>– die Verbreitung und/oder öffentliche Wiedergabe von auf dem Portal verfügbaren Inhalten, soweit dies nicht ausdrücklich vom jeweiligen Urheber gestattet oder als Funktionalität auf dem Portal ausdrücklich zur Verfügung gestellt wird.</p>
        <p>(3) Ebenfalls untersagt ist jede Handlung, die geeignet ist, den reibungslosen Betrieb des Portals zu beeinträchtigen, insbesondere die Systeme von MICE übermäßig zu belasten.</p>
        <p>(4) Sollte eine illegale, missbräuchliche, vertragswidrige oder sonst wie unberechtigte Nutzung des Portals bekannt werden, so ist MICE umgehend zu informieren. MICE wird den Vorgang dann prüfen und ggf. angemessene Schritte einleiten.</p>
        <p>(5) Bei Vorliegen eines Verdachts auf rechtswidrige bzw. strafbare Handlungen ist MICE berechtigt und ggf. auch verpflichtet, die Aktivitäten zu überprüfen und ggf. geeignete rechtliche Schritte einzuleiten. Hierzu kann auch die Zuleitung eines Sachverhalts an die Staatsanwaltschaft gehören.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 11 Sperrung von Zugängen/ Einträgen</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE kann den Zugang zu dem Portal und Einträge vorübergehend oder dauerhaft sperren, wenn konkrete Anhaltspunkte vorliegen, dass Anbieter gegen diese Nutzungsbedingungen und/oder geltendes Recht verstoßen bzw. verstoßen haben, oder wenn MICE ein sonstiges berechtigtes Interesse an der Sperrung hat. Bei der Entscheidung über eine Sperrung wird MICE die berechtigten Interessen des Anbieters angemessen berücksichtigen.</p>
        <p>(2) Im Falle der vorübergehenden bzw. dauerhaften Sperrung sperrt MICE die Zugangsberechtigung und benachrichtigt den Betroffenen hierüber per E-Mail. Bei einer längerfristigen (mehr als 30 Tagen) oder dauerhaften Sperrung werden Vorauszahlungen (z.B. für ein Professional-Account) anteilig zurückerstattet.</p>
        <p>(3) Im Falle einer vorübergehenden Sperrung reaktiviert MICE nach Ablauf der Sperrzeit die Zugangsberechtigung und benachrichtigt den Betroffenen hierüber per E-Mail. Eine dauerhaft gesperrte Zugangsberechtigung kann nicht wiederhergestellt werden. Dauerhaft gesperrte Anbieter sind von der Teilnahme an dem Portal dauerhaft ausgeschlossen und dürfen sich nicht erneut auf dem Portal anmelden. Der Eintrag wird umgehend gelöscht.</p>
        <p>(4) MICE kann das Nutzerkonto eines Anbieters sperren, wenn Hinweise darauf bestehen, dass das Nutzerkonto unautorisiert verwendet wurde und/oder es unternommen oder versucht wird, von dem Nutzerkonto aus einem unautorisierten Zugang zu dem Portal oder den dahinter liegenden Systemen von MICE (Backend), etwa durch Nutzung eigener Software, zu erlangen ("Hacking"). MICE wird in einem solchen Fall den betreffenden Anbieter über seine in dem Nutzer-Account hinterlegte E-Mail-Adresse umgehend benachrichtigen und ihm den Zugang über das Anlegen eines neuen Nutzerkontos wieder ermöglichen, es sei denn, es liegen Tatsachen vor, welche die Annahme nahelegen, dass der Anbieter versucht hat, sich unautorisiert Zugang zu den Systemen von MICE zu verschaffen.</p>

        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 12 Datenschutz</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Zu den Qualitätsansprüchen von MICE gehört es, verantwortungsbewusst mit den persönlichen Daten der Anbieter und Kunden („personenbezogene Daten") umzugehen. Die sich aus der Anmeldung auf dem Portal sowie aus der Nutzung der verfügbaren Dienste ergebenden personenbezogenen Daten werden von MICE daher nur verarbeitet, soweit dies zur vertragsgemäßen Leistungserbringung erforderlich und durch gesetzliche Vorschriften erlaubt, oder vom Gesetzgeber angeordnet ist. MICE wird personenbezogenen Daten vertraulich sowie entsprechend den Bestimmungen des geltenden Datenschutzrechts behandeln und nicht an Dritte weitergeben.</p>
        <p>(2) Darüber hinaus verwendet MICE personenbezogenen Daten nur, soweit der Betroffene hierzu ausdrücklich eingewilligt hat. Eine erteilte Einwilligung kann jederzeit widerrufen werden.</p>
        <p>(3) Nähere Informationen zum Datenschutz sind in der <a className='client-theme--color' href='https://www.miceportal.com/data-privacy'>Datenschutzerklärung</a>, aufrufbar.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 13 Haftung</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Sollten durch die Nutzung von auf dem Portal unentgeltlich zur Verfügung gestellten Diensten ein Schaden entstehen, so haftet MICE nur, soweit der Schaden aufgrund der vertragsgemäßen Nutzung der unentgeltlichen Inhalte und/oder Dienste entstanden ist, und nur bei Vorsatz (einschließlich Arglist) und grober Fahrlässigkeit von MICE.</p>
        <p>(2) Im Rahmen der Nutzung kostenpflichtiger Dienste haftet MICE nach Maßgabe der nachfolgenden Regelungen in Absatz 3 bis 5.</p>
        <p>(3) Für Schäden, die durch MICE oder durch dessen gesetzlichen Vertreter, leitende Angestellte oder einfache Erfüllungsgehilfen vorsätzlich oder grob fahrlässig verursacht wurde, haftet MICE unbeschränkt.</p>
        <p>(4) In Fällen der leicht fahrlässigen Verletzung von nur unwesentlichen Vertragspflichten haftet MICE nicht. Wesentliche Vertragspflichten sind solche Pflichten, die für die Erreichung des Vertragsziels von besonderer Bedeutung sind, ebenso alle diejenigen Pflichten, die im Fall einer schuldhaften Verletzung dazu führen können, dass die Erreichung des Vertragszwecks gefährdet wird. In diesen Fällen ist die Haftung von MICE für leicht fahrlässig verursachte Schäden, auf die diejenigen Schäden beschränkt, mit deren Entstehung im Rahmen des jeweiligen Vertragsverhältnisses typischerweise gerechnet werden muss (vertragstypisch vorhersehbare Schäden). Dies gilt auch bei leicht fahrlässigen Pflichtverletzungen der gesetzlichen Vertreter, leitenden Angestellten bzw. einfachen Erfüllungsgehilfen von MICE.</p>
        <p>(5) Die vorstehende Haftungsbeschränkung gilt nicht im Falle von Arglist, im Falle von Körper- bzw. Personenschäden, für die Verletzung von Garantien sowie für Ansprüche aus Produkthaftung.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 14 Umfang der erlaubten Nutzung/ Überwachung der Nutzungsaktivitäten</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Die Nutzungsberechtigung beschränkt sich auf den Zugang zu dem Portal sowie auf die Nutzung der auf dem Portal jeweils verfügbaren Dienste m Rahmen der Regelungen dieser Nutzungsbedingungen, etwaiger vertraglicher Vorgaben, MICE-Richtlinien und Leitfäden.</p>
        <p>(2) Für die Schaffung der im Verantwortungsbereich der Anbieter zur vertragsgemäßen Nutzung der Dienste notwendigen technischen Voraussetzungen sind diese selbst verantwortlich. MICE schuldet diesbezüglich keine Beratung.</p>
        <p>(3) MICE weist darauf hin, dass Nutzungsaktivitäten im gesetzlich zulässigen Umfang überwacht werden können. Dies beinhaltet ggf. auch die Protokollierung von IP-Verbindungsdaten und Chatverläufen sowie deren Auswertungen bei einem konkreten Verdacht eines Verstoßes gegen die vorliegenden Nutzungsbedingungen und/oder bei einem konkreten Verdacht auf das Vorliegen einer sonstigen rechtswidrigen Handlung oder Straftat.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 15 Schlussbestimmungen</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Sofern in diesen Nutzungsbedingungen nicht ausdrücklich etwas anderes angegeben ist, sind sämtliche Erklärungen, die im Rahmen der Teilnahme an dem Portal abgegeben werden, in Schriftform oder per E-Mail abzugeben. Die E-Mail-Adresse von MICE lautet: <a className='client-theme--color' href='mailto:info@miceportal.com'>info@miceportal.com</a>. Die postalische Anschrift von MICE lautet Am Bachfeld 1, 85395 Attenkirchen. Änderungen der Kontaktdaten bleiben vorbehalten. Im Fall einer solchen Änderung wird MICE den Anbieter hierüber in Kenntnis setzen.</p>
        <p>(2) Sollte eine Bestimmung dieser Nutzungsbedingungen unwirksam sein oder werden, so bleibt die Rechtswirksamkeit der übrigen Bestimmungen hiervon unberührt. Anstelle der unwirksamen Bestimmung gilt eine wirksame Bestimmung als vereinbart, die der von den Parteien gewollten wirtschaftlich am nächsten kommt.</p>
        <p>(3) Diese Nutzungsbedingungen unterliegen dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts (Convention of Contracts for the International Sales of Goods, CISG).</p>
        <p>(4) Für alle Streitigkeiten, die sich aus oder im Zusammenhang mit diesem Vertrag oder über dessen Gültigkeit ergeben, ist, soweit nicht gesetzlich zwingend etwas anderes bestimmt ist, ausschließlich und für alle Verfahrensarten der Gerichtsstand München vereinbart.</p>
        <hr style={{ marginTop: '30px', marginBottom: '30px' }} />
        {/* END NEW */}
        <div className='c-content-title-1'>
          <h1 className='c-font-uppercase' id='serviceProvidersTerms'>Nutzungsbedingungen für Anbieter - Gültig bis 31. Oktober 2024</h1>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 1 Gegenstand der Nutzungsbedingungen</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Die MICE Portal GmbH (nachfolgend „MICE“ genannt) stellt auf www.miceportal.de eine online Buchungsplattform zur Verfügung (nachfolgend <strong>„Portal“</strong> genannt), über die Hotels, Eventlocations oder Eventanbieter (zusammen <strong>„Anbieter“</strong>) einen Eintrag erstellen, mit Kunden in Kontakt treten und Buchungen registrierter Kunden annehmen können. Anbieter können ihren individuellen persönlichen Eintrag für ihre Angebote erstellen und die weiteren, auf dem Portal jeweils aktuell zur Verfügung stehenden unentgeltlichen sowie entgeltlichen Dienste im Rahmen der jeweiligen Verfügbarkeit nutzen. Nähere Informationen zu den Diensten finden sich in der Beschreibung der Dienste, § 8.</p>
        <p>(2) Die vorliegenden Nutzungsbedingungen regeln die Zurverfügungstellung der Dienste durch MICE und die Nutzung dieser Dienste durch Anbieter.</p>
        <p>(3) Informationen zu MICE erhalten Sie <a className='client-theme--color' href='https://corporate.miceportal.com/datenschutz' target='_blank' rel='noopener noreferrer'>hier</a>.</p>
        <p>(4) Diese Nutzungsbedingungen finden nur gegenüber Anbietern Anwendung, die Unternehmer im Sinn des § 14 BGB sind.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 2 Änderungen der Nutzungsbedingungen</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE behält sich vor, diese Nutzungsbedingungen jederzeit mit Wirksamkeit auch innerhalb der bestehenden Vertragsverhältnisse zu ändern, soweit die einzelnen Änderungen für Anbieter zumutbar sind und sich die Notwendigkeit einer Änderung durch folgende Gründe ergeben: Gesetzliche Änderungen, Änderungen in der Rechtsprechung, bestehende Unklarheiten in den Nutzungsbedingungen, Sicherheitsaspekte des Portals, Änderungen der Funktionen des Portals. Im Rahmen einer solchen Änderung können folgende Regelungen der Nutzungsbedingungen nicht verändert werden: Der Vertragstyp, die wesentlichen Geschäftseigenschaften („essentialia negotii“) und die Haftungsbeschränkung Über derartige Änderungen wird MICE die Anbieter mindestens 30 Kalendertage vor dem geplanten Inkrafttreten der Änderungen in Kenntnis setzen. Sofern die Anbieter nicht innerhalb von 30 Tagen ab Zugang der Mitteilung widersprechen und die Inanspruchnahme der Dienste auch nach Ablauf der Widerspruchsfrist fortsetzen, gelten die Änderungen ab Fristablauf als wirksam vereinbart. Im Falle eines Widerspruchs wird der Vertrag zu den bisherigen Bedingungen fortgesetzt. In der Änderungsmitteilung wird MICE die Anbieter auf ihr Widerspruchsrecht und auf die Folgen hinweisen.</p>
        <p>(2) Bei Änderungen der Umsatzsteuer ist MICE zu einer dieser Änderung entsprechenden Anpassung der Vergütung für kostenpflichtige Dienstleistungen wie „MICE ADS“ und „MICE Professional Account“ (siehe § 8) berechtigt, ohne dass das vorgenannte Widerspruchsrecht besteht.</p>
        <p>(3) MICE ist jederzeit berechtigt, auf dem Portal unentgeltlich bereitgestellte Dienste zu ändern, neue Dienste unentgeltlich oder entgeltlich verfügbar zu machen und die Bereitstellung unentgeltlicher Dienste einzustellen. MICE wird hierbei jeweils auf die berechtigten Interessen des Anbieter Rücksicht nehmen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 3 Anmeldung auf dem Portal</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Die Nutzung der auf dem Portal verfügbaren Dienste setzt eine Anmeldung der Anbieter voraus. Ein Anspruch auf die Teilnahme besteht nicht. MICE ist berechtigt, Teilnahmeanträge können bei Vorliegen eines sachlich gerechtfertigten Grundes zurückzuweisen werden.</p>
        <p>(2) Die Anmeldung ist nur Unternehmen im Sinn des § 14 BGB erlaubt. Bei einer juristischen Person muss die Anmeldung durch eine unbeschränkt geschäftsfähige und vertretungsberechtigte natürliche Person erfolgen.</p>
        <p>(3) MICE bietet Anbieter die Wahl zwischen einem Basis-Account und einem Professional-Account. Die Anmeldung zum Basis-Account ist kostenfrei und beinhaltet die Akzeptanz dieser Nutzungsbedingungen. Der Professional-Account bietet gegen Zahlung einer monatlichen Nutzungsvergütung ein deutlich erweitertes Leistungsangebot. Die aktuelle für den Professional-Account zu zahlende Nutzungsvergütung wird während des Anmeldevorgangs angezeigt. Näheres zum jeweiligen Leistungsangebot findet sich unter § 8.</p>
        <p>(4) Anbieter können jederzeit vom Basis-Account zum Professional-Account wechseln. Hierzu müssen Anbieter in dem Portal über ihren persönlichen Einstellungen auf den Reiter „Marketing“ wechseln und dort das passende Produkt auswählen.</p>
        <p>(5) Die während des Anmeldevorgangs von MICE erfragten Kontaktdaten und sonstigen Angaben müssen vom Anbieter vollständig und korrekt angegeben werden. Bei der Anmeldung einer juristischen Person ist zusätzlich die vertretungsberechtigte natürliche Person anzugeben.</p>
        <p>(6) Nach Angabe aller erfragten Daten, werden diese von MICE auf Vollständigkeit und Plausibilität überprüft. Sind die Angaben aus Sicht von MICE korrekt und bestehen aus Sicht von MICE keine sonstigen Bedenken, schaltet MICE den beantragten Zugang frei und benachrichtigt den Anbieter hiervon per E-Mail. Die E-Mail gilt als Annahme des Antrags auf Abschluss eines Vertrages nach den vorliegenden AGB.</p>
        <p>(7) Die Freischaltung des kostenpflichtigen „Professional Account“ oder eines anderen Marketing-Produkts erfolgt durch die Annahme des Angebots von MICE, welches der Anbieter unterzeichnet eingescannt per E-Mail an <a className='client-theme--color' href='mailto:info@miceportal.com'>info@miceportal.com</a> zurücksendet. Die Laufzeit des Vertrags beginnt ab dem nächsten Ersten des Folgemonats nach dem Tag der Freischaltung.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 4 Verantwortung für die Zugangsdaten</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Im Verlauf des Anmeldevorgangs werden die Anbieter gebeten, einen Benutzernamen und ein Passwort anzugeben. Mit diesen Daten können Anbieter sich nach der Freischaltung des Zugangs und der Bestätigung gem. § 4 (3) auf dem Portal einloggen und ihren Eintrag bearbeiten. Es liegt in der Verantwortung des Anbieters, dass der Benutzername nicht Rechte Dritter, insbesondere keine Namens- oder Markenrechte verletzt und nicht gegen die guten Sitten verstößt.</p>
        <p>(2) Die Zugangsdaten einschließlich des Passworts sind geheim zu halten und unbefugten Dritten nicht zugänglich zu machen.</p>
        <p>(3) Es liegt weiter in der Verantwortung des Anbieters sicher zu stellen, dass der Zugang zu dem Portal und die Nutzung der auf dem Portal zur Verfügung stehenden Dienste ausschließlich durch den Anbieter bzw. durch der von diesen bevollmächtigten Personen erfolgt. Steht zu befürchten, dass unbefugte Dritte von den Zugangsdaten Kenntnis erlangt haben oder erlangen werden, ist MICE unverzüglich zu informieren.</p>
        <p>(4) Anbieter haften für jedwede Nutzung und/oder sonstige Aktivität, die unter deren Zugangsdaten ausgeführt wird, nach den gesetzlichen Bestimmungen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 5 Eintrag im Portal</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Anbieter können unter Beachtung der nachfolgenden Regelungen einen Eintrag erstellen und Inhalte auf dem Portal einstellen und damit für Dritte, insbesondere Kunden, verfügbar machen.</p>
        <p>(2) Anbieter können im Rahmen der vorliegenden Nutzungsbedingungen und den Vorgaben von MICE ihren Eintrag im Portal individuell gestalten.</p>
        <p>(3) MICE führt im Regelfall keine Überprüfung der Angaben in den Einträgen durch. MICE behält sich dabei das Recht vor, angegebene Attribute und Eigenschaften, sofern sie nicht der Wahrheit entsprechen oder zu einem irritierenden Suchergebnis führen, zu ändern.</p>
        <p>(4) Bei der Wahl der Bilder im Rahmen des Eintrags eines Professional Accounts behält MICE sich das Recht vor, andere Bilder anzufordern, wenn die Qualität nicht den Anforderungen entspricht, die im Portal auf der entsprechenden Seite hinterlegt sind oder die Aussagekraft nicht den Vorstellungen von MICE entspricht.</p>
        <p>(5) Mit dem Einstellen von Inhalten räumen Anbieter MICE jeweils ein unentgeltliches und übertragbares Nutzungsrecht an den jeweiligen Inhalten ein, insbesondere</p>
        <p>– zur Speicherung der Inhalte auf dem Server von MICE sowie deren Veröffentlichung, insbesondere deren öffentlicher Zugänglichmachung (z.B. durch Anzeige der Inhalte auf dem Portal),</p>
        <p>– zur Bearbeitung und Vervielfältigung, soweit dies für die Vorhaltung bzw. Veröffentlichung der jeweiligen Inhalte erforderlich ist, und</p>
        <p>Soweit Anbieter die eingestellten Inhalte wieder von dem Portal herunternehmen, erlischt das an MICE vorstehend eingeräumte Nutzungs- und Verwertungsrecht. MICE bleibt jedoch berechtigt, zu Sicherungs- und/oder Nachweiszwecken erstellte Kopien aufzubewahren.</p>
        <p>(6) Anbieter sind für die von ihnen eingestellten Inhalte voll verantwortlich. MICE übernimmt nicht zwingend eine Überprüfung der Inhalte auf Vollständigkeit, Richtigkeit, Rechtmäßigkeit, Aktualität, Qualität und Eignung für einen bestimmten Zweck.</p>
        <p>(7) Anbieter erklären und gewährleisten gegenüber MICE daher, dass sie der alleinige Inhaber sämtlicher Rechte, an den von ihnen auf dem Portal eingestellten Inhalten sind, oder aber anderweitig berechtigt sind (z.B. durch eine wirksame Erlaubnis des Rechteinhabers), die Inhalte auf dem Portal einzustellen und die Nutzungs- und Verwertungsrechte nach dem vorstehenden Absatz (5) zu gewähren.</p>
        <p>(8) MICE behält sich das Recht vor, das Einstellen von Inhalten abzulehnen und/oder bereits eingestellte Inhalte ohne vorherige Ankündigung zu bearbeiten, zu sperren oder zu entfernen, sofern das Einstellen der Inhalte durch den Anbieter oder die eingestellten Inhalte selbst zu einem Verstoß gegen § 10 geführt haben.  Der MICE wird hierbei jedoch auf Ihre berechtigten Interessen Rücksicht nehmen und das mildeste Mittel zur Abwehr des Verstoßes gegen § 10 wählen.</p>
        <p>(9) Anbieter sind dazu verpflichtet, ihre Daten (einschließlich der Kontaktdaten) aktuell zu halten. Tritt während der Dauer der Teilnahme eine Änderung der angegebenen Daten ein, so sind die Angaben unverzüglich auf dem Portal zu korrigieren.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 6 Beendigung der Teilnahme</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Den Basis-Account können Anbieter jederzeit kündigen, indem diese sich von der Teilnahme am Portal abmelden.</p>
        <p>(2) Für den Professional-Account gilt eine Mindestvertragslaufzeit von 12 Monaten, beginnend mit der Freischaltung des Accounts § 4 (4). Ab dem Ablauf der Mindestvertragslaufzeit verlängert sich der Vertrag jeweils automatisch um Verlängerungsperioden von jeweils 12 Monaten, sofern nicht eine Partei den Vertrag unter Einhaltung einer Kündigungsfrist von 12 Kalenderwochen vor Ablauf der Mindestvertragslaufzeit bzw. der jeweiligen Verlängerungsperiode kündigt.</p>
        <p>(3) Mit Wirksamwerden der Kündigung endet das Vertragsverhältnis und Anbieter dürfen Ihren Zugang nicht mehr nutzen. MICE behält sich vor, den Benutzernamen sowie das Passwort mit Wirksamwerden der Kündigung zu sperren.</p>
        <p>(4) MICE ist berechtigt, mit Ablauf von 30 Kalendertagen nach Wirksamwerden der Kündigung und nach Ablauf etwaiger gesetzlicher Vorhaltungsfristen sämtliche im Rahmen der Teilnahme entstandenen Daten unwiederbringlich zu löschen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 7 Buchungen und Vertragsabschluss mit Kunden</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Wenn ein Kunde eine Buchung über das Portal beim Anbieter anfragt und der Anbieter mit dem Kunden einen entsprechenden Vertrag abschließt, werden nur diese Vertragspartner. Es gelten in diesem Verhältnis zwischen Kunden und Anbieter ausschließlich der vertraglichen Regelungen dieser Parteien, die im Eintrag genannt werden.</p>
        <p>(2) Wenn der Anbieter den Kunden kontaktiert und diesen auffordert seine Anfrage nicht im Portal, sondern direkt an diesen zu schicken, um eine Buchung im Portal zu umgehen, hat MICE das Recht, den Eintrag zu löschen.</p>
        <p>(3) MICE erhebt bei Zustandekommen einer Buchung durch einen Kunden eine Vermittlungsprovision in Höhe von 10% auf alle im Reservierungsvertrag gelisteten und gebuchten Leistungen. Leistungen, die ein Anbieter von seinem Subunternehmer („Dritten“) bezieht, unterliegen <strong>nicht</strong> dieser Kommissionsregelung. Die Verpflichtung zur Zahlung einer Vermittlungsprovision entfällt, wenn dies zum Zeitpunkt der Buchung in einem Rahmenvertrag zwischen Anbieter und Kunden entsprechend geregelt ist und die kommerziellen Daten dieses Rahmenvertrag in der MICE Portal Instanz des Kunden freigeschaltet sind. Freigeschaltet bedeutet, dass der Kunde die Daten an MICE übergibt und MICE diese für das Portal live schaltet. Berufen sich Anbieter und Kunde auf einen Rahmenvertrag, der zum Zeitpunkt einer Buchung noch nicht in der MICE Portal Instanz des Kunden freigeschaltet war, reduziert sich die Vermittlungsprovision nach Abs. 3 Satz 1 um 50%.</p>
        <p>(4) MICE stellt die Rechnung über die Vermittlungsprovision nach Veranstaltungsablauf auf die tatsächlich in Anspruch genommenen Leistungen aus. Bei Abweichungen der in Anspruch genommenen Leistungen, hat MICE das Recht die Kundenrechnung einzusehen. Die Vermittlungsprovision bezieht sich auf den netto Betrag zuzüglich der derzeitig gültigen Mehrwertsteuer.</p>
        <p>(5) Für den Fall, dass eine kostenpflichtige Buchung storniert und hierfür eine Stornorechnung erstellt wird, ist MICE berechtigt, die Vermittlungsprovision in Höhe von 10 % gemäß Absatz 3 auf die Stornorechnung zu erheben.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 8 Inanspruchnahme der Dienste</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE bietet auf dem Portal sowohl unentgeltliche (Basic-Account) als auch kostenpflichtige Dienste wie den Professional Account und MICE-Ads an. Soweit zwischen den Parteien nichts anderes vereinbart ist, geltenden die Preise gemäß aktueller Preisliste. Aktualisierte Preise werden per E-Mail im PDF-Format versendet.</p>
        <p>(2) Der konkrete Umfang des Professional-Accounts und Basic-Accounts kann der <a className='client-theme--color' href='https://corporate.miceportal.com/de/mice-online-marketing'>Webseite</a> entnommen werden. Beide beinhalten die Möglichkeit eines Eintrages mit unlimitiertem Inhalt, Teilnahme am Buchungsportal und Support. Generell gibt es im Rahmen des kostenfreien Basic-Accounts aber kein Recht auf Anzeige des Eintrags im Portal. Ebenso hat der Anbieter keinen Anspruch auf ein bestimmtes Ranking seines Eintrages. Das Ranking erfolgt anhand eines „Qualitätsscore“, der [<a className='client-theme--color' href='https://1789719.fs1.hubspotusercontent-eu1.net/hubfs/1789719/Partner%20Managment/Datenscore-factsheetDE.pdf'>hier</a>] genauer erläutert wird. Das Buchen der folgenden Bezahlfunktionen haben einen Einfluss auf das Ranking.</p>
        <p>(3) Der Professional-Account umfasst zusätzlich Leistungen wie Analyse Tools, Direktsuche, Message Center und gesteigerten Support. Die Nutzung der innerhalb des Professional-Accounts verfügbaren Dienste ist mit der monatlichen Nutzungsvergütung für die Registrierung des Professional Accounts abgegolten.</p>
        <p>(4) Dienstleiser können MICE-Ads zu buchen, um entsprechende Werbeanzeigen im Portal zu schalten. Dadurch lässt sich die Reichweite erhöhen und die Zielgruppe besser erreichen. Der konkreten Funktionen von MICE-Ads können der Webseite entnommen werden.</p>
        <p>(5) Für den kostenpflichtigen Professional Account sowie für sämtliche kostenpflichtige Dienste (auch solche, die innerhalb des Basic-Accounts zugänglich sind) gewährleistet MICE in seinem Verantwortungsbereich eine Verfügbarkeit von 98 % im Monatsmittel. Nicht in die Berechnung der Verfügbarkeit fallen die regulären Wartungs-fenster des Portals, die jeden Sonntag zwischen 18:00 und 5:00 Uhr liegen.</p>
        <p>(6) Sämtliche angegebenen Entgelte verstehen sich zuzüglich der jeweils geltenden gesetzlichen Mehrwertsteuer. Die Freischaltung Ihres Professional Accounts erfolgt spätestens nach zwei Werktagen nach Angebotsannahme. Die Vertragslaufzeit beginnt ab dem nächsten Ersten des Folgemonats. Die Zahlung erfolgt jährlich zu Beginn der Vertragslaufzeit. Zahlungsziel sofort ohne Abzug. Bei Kauf eines Werbeplatzes erhalten Sie bis spätestens 30 Tage vor Aktivierung Ihrer Werbung eine gesonderte Rechnung.</p>
        <p>(7) Bei Verzug ist MICE berechtigt, Verzugszinsen in Höhe von 9 Prozentpunkte über dem Basiszinssatz zu verlangen, wenn der Anbieter nicht einen geringeren oder MICE einen höheren Schaden nachweist.</p>
        <p>(8) Die Aufrechnung ist nur mit unbestrittenen oder rechtskräftig festgestellten Gegenforderungen erlaubt. Ein Zurückbehaltungsrecht kann nur geltend gemacht werden, wenn es auf demselben Vertragsverhältnis beruht.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 9 Schutz der Inhalte, Verantwortlichkeit für Inhalte Dritter</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Die auf dem Portal verfügbaren Inhalte stammen teilweise von MICE und teilweise von anderen Anbietern bzw. sonstigen Dritten. Inhalte der Anbieter sowie sonstiger Dritter werden nachfolgend zusammenfassend <strong>„Drittinhalte“</strong> genannt. MICE führt bei Drittinhalten keine Prüfung auf Vollständigkeit, Richtigkeit und Rechtmäßigkeit durch und übernimmt daher keinerlei Verantwortung oder Gewährleistung für die Vollständigkeit, Richtigkeit, Rechtmäßigkeit und Aktualität der Drittinhalte, sofern die Rechtswidrigkeit nicht offensichtlich und MICE bekannt ist. Dies gilt auch im Hinblick auf die Qualität der Drittinhalte und deren Eignung für einen bestimmten Zweck, und auch, soweit es sich um Drittinhalte auf verlinkten externen Webseiten handelt.</p>
        <p>(2) Sämtliche Inhalte auf dem Portal sind Drittinhalte, ausgenommen diejenigen Inhalte, die mit einem Urheberrechtsvermerk von MICE versehen sind.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 10 Verbotene Aktivitäten</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Anbietern sind jegliche Aktivitäten auf bzw. im Zusammenhang mit dem Portal untersagt, die gegen geltendes Recht verstoßen, Rechte Dritter verletzen oder gegen die Grundsätze des Jugendschutzes verstoßen. Insbesondere sind folgende Handlungen untersagt:</p>
        <p>– das Einstellen, die Verbreitung, das Angebot und die Bewerbung pornografischer, gegen Jugendschutzgesetze, gegen Datenschutzrecht und/oder gegen sonstiges Recht verstoßender und/oder betrügerischer Inhalte, Dienste und/oder Produkte;</p>
        <p>– die Verwendung von Inhalten, durch die andere Anbieter, Kunden oder Dritte beleidigt oder verleumdet werden;</p>
        <p>– die Nutzung, das Bereitstellen und das Verbreiten von Inhalten, Diensten und/oder Produkten, die gesetzlich geschützt oder mit Rechten Dritter (z.B. Urheberrechte) belastet sind, ohne hierzu ausdrücklich berechtigt zu sein.</p>
        <p>(2) Des Weiteren sind Anbietern auch unabhängig von einem eventuellen Gesetzesverstoß bei der Einstellung eigener Inhalte auf dem Portal sowie bei der Kommunikation mit Kunden (z.B. durch Versendung persönlicher Mitteilungen) die folgenden Aktivitäten untersagt:</p>
        <p>– die Verbreitung von Viren, Trojanern und anderen schädlichen Dateien;</p>
        <p>– die Versendung von Junk- oder Spam-Mails sowie von Kettenbriefen;</p>
        <p>– die Verbreitung anzüglicher, anstößiger, sexuell geprägter, obszöner oder diffamierender Inhalte bzw. Kommunikation sowie solcher Inhalte bzw. Kommunikation die geeignet sind/ist, Rassismus, Fanatismus, Hass, körperliche Gewalt oder rechtswidrige Handlungen zu fördern bzw. zu unterstützen (jeweils explizit oder implizit);</p>
        <p>– die Belästigung von Kunden, z.B. durch mehrfaches persönliches Kontaktieren ohne oder entgegen der Reaktion des Kunden sowie das Fördern bzw. Unterstützen derartiger Belästigungen;</p>
        <p>– die Aufforderung von Kunden zur Preisgabe von Kennwörtern oder personenbezogener Daten für kommerzielle oder rechts- bzw. gesetzeswidrige Zwecke;</p>
        <p>– die Verbreitung und/oder öffentliche Wiedergabe von auf dem Portal verfügbaren Inhalten, soweit dies nicht ausdrücklich vom jeweiligen Urheber gestattet oder als Funktionalität auf dem Portal ausdrücklich zur Verfügung gestellt wird.</p>
        <p>(3) Ebenfalls untersagt ist jede Handlung, die geeignet ist, den reibungslosen Betrieb des Portals zu beeinträchtigen, insbesondere die Systeme von MICE übermäßig zu belasten.</p>
        <p>(4) Sollte eine illegale, missbräuchliche, vertragswidrige oder sonst wie unberechtigte Nutzung des Portals bekannt werden, so ist MICE umgehend zu informieren. MICE wird den Vorgang dann prüfen und ggf. angemessene Schritte einleiten.</p>
        <p>(5) Bei Vorliegen eines Verdachts auf rechtswidrige bzw. strafbare Handlungen ist MICE berechtigt und ggf. auch verpflichtet, die Aktivitäten zu überprüfen und ggf. geeignete rechtliche Schritte einzuleiten. Hierzu kann auch die Zuleitung eines Sachverhalts an die Staatsanwaltschaft gehören.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 11 Sperrung von Zugängen/ Einträgen</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE kann den Zugang zu dem Portal und Einträge vorübergehend oder dauerhaft sperren, wenn konkrete Anhaltspunkte vorliegen, dass Anbieter gegen diese Nutzungsbedingungen und/oder geltendes Recht verstoßen bzw. verstoßen haben, oder wenn MICE ein sonstiges berechtigtes Interesse an der Sperrung hat. Bei der Entscheidung über eine Sperrung wird MICE die berechtigten Interessen des Anbieters angemessen berücksichtigen.</p>
        <p>(2) Im Falle der vorübergehenden bzw. dauerhaften Sperrung sperrt MICE die Zugangsberechtigung und benachrichtigt den Betroffenen hierüber per E-Mail.</p>
        <p>(3) Im Falle einer vorübergehenden Sperrung reaktiviert MICE nach Ablauf der Sperrzeit die Zugangsberechtigung und benachrichtigt den Betroffenen hierüber per E-Mail. Eine dauerhaft gesperrte Zugangsberechtigung kann nicht wiederhergestellt werden. Dauerhaft gesperrte Anbieter sind von der Teilnahme an dem Portal dauerhaft ausgeschlossen und dürfen sich nicht erneut auf dem Portal anmelden. Der Eintrag wird umgehend gelöscht.</p>
        <p>(4) MICE kann das Nutzerkonto eines Anbieters sperren, wenn Hinweise darauf bestehen, dass das Nutzerkonto unautorisiert verwendet wurde und/oder es unternommen oder versucht wird, von dem Nutzerkonto aus einem unautorisierten Zugang zu dem Portal oder den dahinter liegenden Systemen von MICE (Backend), etwa durch Nutzung eigener Software, zu erlangen ("Hacking“). MICE wird in einem solchen Fall den betreffenden Anbieter über seine in dem Nutzer-Account hinterlegte E-Mail-Adresse umgehend benachrichtigen und ihm den Zugang über das Anlegen eines neuen Nutzerkontos wieder ermöglichen, es sei denn, es liegen Tatsachen vor, welche die Annahme nahelegen, dass der Anbieter versucht hat, sich unautorisiert Zugang zu den Systemen von MICE zu verschaffen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 12 Datenschutz</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Zu den Qualitätsansprüchen von MICEs gehört es, verantwortungsbewusst mit den persönlichen Daten der Anbieter und Kunden (<strong>„personenbezogene Daten“</strong>) umzugehen. Die sich aus der Anmeldung auf dem Portal sowie aus der Nutzung der verfügbaren Dienste ergebenden personenbezogenen Daten werden von MICE daher nur verarbeitet, soweit dies zur vertragsgemäßen Leistungserbringung erforderlich und durch gesetzliche Vorschriften erlaubt, oder vom Gesetzgeber angeordnet ist. MICE wird personenbezogenen Daten vertraulich sowie entsprechend den Bestimmungen des geltenden Datenschutzrechts behandeln und nicht an Dritte weitergeben.</p>
        <p>(2) Darüber hinaus verwendet MICE personenbezogenen Daten nur, soweit der Betroffene hierzu ausdrücklich eingewilligt hat. Eine erteilte Einwilligung kann jederzeit widerrufen werden.</p>
        <p>(3) Nähere Informationen zum Datenschutz sind in der <a className='client-theme--color' href='/data-privacy'>Datenschutzerklärung</a>, aufrufbar.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 13 Haftung</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Sollten durch die Nutzung von auf dem Portal unentgeltlich zur Verfügung gestellten Diensten ein Schaden entstehen, so haftet MICE nur, soweit der Schaden aufgrund der vertragsgemäßen Nutzung der unentgeltlichen Inhalte und/oder Dienste entstanden ist, und nur bei Vorsatz (einschließlich Arglist) und grober Fahrlässigkeit von MICE.</p>
        <p>(2) Im Rahmen der Nutzung kostenpflichtiger Dienste haftet MICE nach Maßgabe der nachfolgenden Regelungen in Absatz 3 bis 5.</p>
        <p>(3) Für Schäden, die durch MICE oder durch dessen gesetzlichen Vertreter, leitende Angestellte oder einfache Erfüllungsgehilfen vorsätzlich oder grob fahrlässig verursacht wurde, haftet MICE unbeschränkt.</p>
        <p>(4) In Fällen der leicht fahrlässigen Verletzung von nur unwesentlichen Vertragspflichten haftet MICE nicht. Im Übrigen ist die Haftung von MICE für leicht fahrlässig verursachte Schäden, auf die diejenigen Schäden beschränkt, mit deren Entstehung im Rahmen des jeweiligen Vertragsverhältnisses typischerweise gerechnet werden muss (vertragstypisch vorhersehbare Schäden). Dies gilt auch bei leicht fahrlässigen Pflichtverletzungen der gesetzlichen Vertreter, leitenden Angestellten bzw. einfachen Erfüllungsgehilfen von MICE.</p>
        <p>(5) Die vorstehende Haftungsbeschränkung gilt nicht im Falle von Arglist, im Falle von Körper- bzw. Personenschäden, für die Verletzung von Garantien sowie für Ansprüche aus Produkthaftung.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 14 Umfang der erlaubten Nutzung/ Überwachung der Nutzungsaktivitäten</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Die Nutzungsberechtigung beschränkt sich auf den Zugang zu dem Portal sowie auf die Nutzung der auf dem Portal jeweils verfügbaren Dienste m Rahmen der Regelungen dieser Nutzungsbedingungen, etwaiger vertraglicher Vorgaben, MICE-Richtlinien und Leitfäden.</p>
        <p>(2) Für die Schaffung der im Verantwortungsbereich der Anbieter zur vertragsgemäßen Nutzung der Dienste notwendigen technischen Voraussetzungen sind diese selbst verantwortlich. MICE schuldet diesbezüglich keine Beratung.</p>
        <p>(3) MICE weist darauf hin, dass Nutzungsaktivitäten im gesetzlich zulässigen Umfang überwacht werden können. Dies beinhaltet ggf. auch die Protokollierung von IP-Verbindungsdaten und Chatverläufen sowie deren Auswertungen bei einem konkreten Verdacht eines Verstoßes gegen die vorliegenden Nutzungsbedingungen und/oder bei einem konkreten Verdacht auf das Vorliegen einer sonstigen rechtswidrigen Handlung oder Straftat.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 15 Schlussbestimmungen</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Sofern in diesen Nutzungsbedingungen nicht ausdrücklich etwas anderes angegeben ist, sind sämtliche Erklärungen, die im Rahmen der Teilnahme an dem Portal abgegeben werden, in Schriftform oder per E-Mail abzugeben. Die E-Mail-Adresse von MICE lautet: <a className='client-theme--color' href='mailto:info@miceportal.com'>info@miceportal.com</a>. Die postalische Anschrift von MICE lautet Am Bachfeld 1, 85395 Attenkirchen. Änderungen der Kontaktdaten bleiben vorbehalten. Im Fall einer solchen Änderung wird MICE-Anbieter hierüber in Kenntnis setzen.</p>
        <p>(2) Sollte eine Bestimmung dieser Nutzungsbedingungen unwirksam sein oder werden, so bleibt die Rechtswirksamkeit der übrigen Bestimmungen hiervon unberührt. Anstelle der unwirksamen Bestimmung gilt eine wirksame Bestimmung als vereinbart, die der von den Parteien gewollten wirtschaftlich am nächsten kommt.</p>
        <p>(3) Diese Nutzungsbedingungen unterliegen dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts (Convention of Contracts for the International Sales of Goods, CISG).</p>
        <p>(4) Ausschließlicher Gerichtsstand für alle sich aus diesen Nutzungsbedingungen ergebenden Streitigkeiten ist der Sitz von MICE.</p>
        <hr style={{ marginTop: '30px', marginBottom: '30px' }} />
        <div className='c-content-title-1'>
          <h1 className='c-font-uppercase' id='customersTerms'>Nutzungsbedingungen für Kunden</h1>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 1 Gegenstand der Nutzungsbedingungen</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Die MICE Portal GmbH (nachfolgend „MICE“ genannt) stellt auf www.miceportal.de eine online Buchungsplattform zur Verfügung (nachfolgend „Portal“ genannt), über die registrierte Kunden mit gelisteten Hotels, einer Eventlocation oder einem Eventdienstleister (zusammen „Dienstleister“) in Kontakt treten, kommunizieren und Buchungen vornehmen können.</p>
        <p>(2) Die vorliegenden Nutzungsbedingungen regeln die Zurverfügungstellung der Dienste durch MICE und die Nutzung dieser Dienste durch ordnungsgemäß ange-meldete Kunden.</p>
        <p>(3) Informationen zu MICE erhalten Sie <a href='https://corporate.miceportal.com' target='_blank' rel='noopener noreferrer'>hier</a>.</p>
        <p>(4) Diese Nutzungsbedingungen finden nur gegenüber Kunden Anwendung, die Unternehmer im Sinn des § 14 BGB sind.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 2 Änderungen der Nutzungsbedingungen</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE behält sich vor, diese Nutzungsbedingungen jederzeit mit Wirksamkeit auch innerhalb der bestehenden Vertragsverhältnisse zu ändern. Über derartige Änderungen wird MICE Kunden mindestens 30 Kalendertage vor dem geplanten Inkrafttreten der Änderungen in Kenntnis setzen. Sofern Kunden nicht innerhalb von 30 Tagen ab Zugang der Mitteilung widersprechen und die Inanspruchnahme der Dienste auch nach Ablauf der Widerspruchsfrist fortsetzen, gelten die Änderungen ab Fristablauf als wirksam vereinbart. Im Falle eines Widerspruchs wird der Vertrag zu den bisherigen Bedingungen fortgesetzt. In der Änderungsmitteilung wird MICE Kunden auf ihr Widerspruchsrecht und auf die Folgen hinweisen.</p>
        <p>(2) MICE ist jederzeit berechtigt, auf dem Portal unentgeltlich bereitgestellte Dienste zu ändern, neue Dienste unentgeltlich oder entgeltlich verfügbar zu machen und die Bereitstellung unentgeltlicher Dienste einzustellen. MICE wird hierbei jeweils auf die berechtigten Interessen der Kunden Rücksicht nehmen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 3 Schutz der Inhalte, Verantwortlichkeit für Inhalte Dritter</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Die auf dem Portal verfügbaren Inhalte sind überwiegend geschützt durch das Urheberrecht oder durch sonstige Schutzrechte und stehen jeweils im Eigentum von MICE, der Dienstleister oder sonstiger Dritter, welche die jeweiligen Inhalte zur Verfügung gestellt haben. Die Zusammenstellung der Inhalte als Solche ist ggf. geschützt als Datenbank oder Datenbankwerk im Sinn der §§ 4 Abs. 2, 87a Abs. 1 UrhG. Kunden dürfen diese Inhalte lediglich gemäß dieser Nutzungsbedingungen sowie im auf dem Portal vorgegebenen Rahmen nutzen.</p>
        <p>(2) Die auf dem Portal verfügbaren Inhalte stammen teilweise von MICE und teilweise von anderen Dienstleistern bzw. sonstigen Dritten. Inhalte der Dienstleister sowie sonstiger Dritter werden nachfolgend zusammenfassend „Drittinhalte“ genannt. MICE führt bei Drittinhalten keine Prüfung auf Vollständigkeit, Richtigkeit und Rechtmäßigkeit durch und übernimmt daher keinerlei Verantwortung oder Gewährleistung für die Vollständigkeit, Richtigkeit, Rechtmäßigkeit und Aktualität der Drittinhalte, sofern die Rechtswidrigkeit nicht offensichtlich und MICE bekannt ist. Dies gilt auch im</p>
        <p>Hinblick auf die Qualität der Drittinhalte und deren Eignung für einen bestimmten Zweck, und auch, soweit es sich um Drittinhalte auf verlinkten externen Webseiten handelt.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 4 Registrierung und Teilnahme</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Die Nutzung der auf dem Portal verfügbaren Dienste, insbesondere der Abschluss einer Buchung, setzt eine Registrierung der Kunden voraus. Ein Anspruch auf die Teilnahme besteht nicht. MICE ist berechtigt, Teilnahmeanträge ohne Angabe von Gründen zurückzuweisen.</p>
        <p>(2) Die Registrierung ist nur Unternehmen im Sinn des § 14 BGB erlaubt. Bei einer juristischen Person muss die Anmeldung durch eine unbeschränkt geschäftsfähige und vertretungsberechtigte natürliche Person erfolgen.</p>
        <p>(3) Bei der Registrierung sind die markierten Pflichtangaben auszufüllen. Dies sind insbesondere: Kontaktdaten der Firma, Vorname, Nachname des Users, E-Mailadresse.</p>
        <p>(4) Im Verlauf der Registrierung werden Kunden gebeten, einen Benutzernamen und ein Passwort anzugeben. Mit diesen Daten können die Kunden sich nach der Freischaltung ihres Zugangs und ihrer Bestätigung auf dem Portal einloggen. Es liegt in der Verantwortung des Kunden, dass der Benutzername nicht Rechte Dritter, insbesondere keine Namens- oder Markenrechte verletzt und nicht gegen die guten Sitten verstößt.</p>
        <p>(5) Die Zugangsdaten einschließlich des Passworts sind von Kunden geheim zu halten und unbefugten Dritten nicht zugänglich zu machen.</p>
        <p>(6) Es liegt weiter in der Verantwortung des Kunden sicher zu stellen, dass der Zugang zu dem Portal und die Nutzung der auf dem Portal zur Verfügung stehenden Dienste ausschließlich durch den Kunden selbst bzw. durch die von diesem bevollmächtigten Personen erfolgt. Steht zu befürchten, dass unbefugte Dritte von den Zugangsdaten Kenntnis erlangt haben oder erlangen werden, ist MICE unverzüglich zu informieren.</p>
        <p>(7) Kunden haften für jedwede Nutzung und/oder sonstige Aktivität, die unter den Zugangsdaten ausgeführt wird, nach den gesetzlichen Bestimmungen.</p>
        <p>(8) Kunden sind dazu verpflichtet, ihre Daten (einschließlich der Kontaktdaten) aktuell zu halten. Tritt während der Dauer der Teilnahme eine Änderung der angegebenen Daten ein, so haben Kunden die Angaben unverzüglich auf dem Portal in Ihren persönlichen Einstellungen zu korrigieren. Sollte dies nicht gelingen, so sind die geänderten Daten unverzüglich per E-Mail oder Telefax MICE mitzuteilen.</p>
        <p>(9) Kunden können sich jederzeit von der Teilnahme am Portal abmelden. Mit Abmeldung endet das Vertragsverhältnis und Kunden dürfen Ihren Zugang nicht mehr nutzen. MICE behält sich vor, den Benutzernamen sowie das Passwort mit Abmeldung zu sperren.</p>
        <p>(10) MICE ist berechtigt, mit Ablauf von 30 Kalendertagen nach Abmeldung und nach Ablauf etwaiger gesetzlicher Vorhaltungsfristen sämtliche im Rahmen der Teilnahme entstandenen Daten unwiederbringlich zu löschen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 5 Buchungen und Vertragsabschluss mit Kunden</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Wenn ein Kunde eine Buchung über das Portal beim Dienstleister anfragt und der Dienstleister mit dem Kunden einen entsprechenden Vertrag abschließt, werden nur diese Vertragspartner. Es gelten in diesem Verhältnis zwischen Kunden und Dienstleister ausschließlich die vertraglichen Regelungen dieser Parteien, die im Eintrag genannt werden.</p>
        <p>(2) Wenn der Dienstleister den Kunden kontaktiert und diesen auffordert seine Anfrage nicht im Portal, sondern direkt an diesen zu schicken, um eine Buchung im Portal zu umgehen, hat MICE das Recht, dem Kunden die weitere Teilnahme am Portal zu untersagen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 6 Dienste und Verfügbarkeiten</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Inhalt und Umfang der Dienste bestimmen sich nach den jeweils aktuell auf dem Portal verfügbaren Funktionalitäten.</p>
        <p>(2) Zu den auf dem Portal verfügbaren Diensten können auch Dienste Dritter gehören, zu welchen MICE lediglich den Zugang vermittelt. Für die Inanspruchnahme derartiger Dienste – die jeweils als Dienste Dritter kenntlich gemacht sind – können von diesen Nutzungsbedingungen abweichende oder zusätzliche Regelungen gelten, auf die MICE hinweisen wird.</p>
        <p>(3) Ein Anspruch auf die Nutzung der auf dem Portal verfügbaren Dienste besteht nur im Rahmen der technischen und betrieblichen Möglichkeiten bei MICE. MICE bemüht sich um eine möglichst unterbrechungsfreie Nutzbarkeit seiner Dienste. Jedoch können durch technische Störungen (wie z. B. Unterbrechung der Stromversorgung, Hardware- und Softwarefehler, technische Probleme in den Datenleitungen) zeitweilige Beschränkungen oder Unterbrechungen auftreten.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 7 Umfang der erlaubten Nutzung der Kunden, Überwachung der Nutzungsaktivitäten</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Die Nutzungsberechtigung beschränkt sich auf den Zugang zu dem Portal sowie auf die Nutzung der auf dem Portal jeweils verfügbaren Dienste im Rahmen der Regelungen dieser Nutzungsbedingungen.</p>
        <p>(2) Für die Schaffung der im Verantwortungsbereich der Kunden zur vertragsgemäßen Nutzung der Dienste notwendigen technischen Voraussetzungen sind die Kunden selbst verantwortlich. MICE schuldet keine diesbezügliche Beratung.</p>
        <p>(3) MICE weist darauf hin, dass Nutzungsaktivitäten im gesetzlich zulässigen Umfang überwacht werden können. Dies beinhaltet ggf. auch die Protokollierung von IP-Verbindungsdaten und Gesprächsverläufen sowie deren Auswertungen bei einem konkreten Verdacht eines Verstoßes gegen die vorliegenden Nutzungsbedingungen und/oder bei einem konkreten Verdacht auf das Vorliegen einer sonstigen rechtswidrigen Handlung oder Straftat.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 8 Nutzungsrecht an auf dem Portal verfügbaren Inhalten</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Soweit nicht in diesen Nutzungsbedingungen oder auf dem Portal eine weitergehende Nutzung ausdrücklich erlaubt oder auf dem Portal durch eine entsprechende Funktionalität (z. B. Download-Button) ermöglicht wird,</p>
        <p>– dürfen Kunden die auf dem Portal verfügbaren Inhalte ausschließlich zur Ausübung der beruflichen Tätigkeit online abrufen und anzeigen. Dieses Nutzungsrecht ist auf die Dauer der vertragsgemäßen Teilnahme an dem Portal beschränkt;</p>
        <p>– ist es Kunden untersagt, die auf dem Portal verfügbaren Inhalte ganz oder teilweise zu bearbeiten, zu verändern, zu übersetzen, vorzuzeigen oder vorzuführen, zu veröffentlichen, auszustellen, zu vervielfältigen oder zu verbreiten. Ebenso ist es untersagt, Urhebervermerke, Logos und sonstige Kennzeichen oder Schutzvermerke zu entfernen oder zu verändern.</p>
        <p>(2) Zum Herunterladen von Inhalten („Download“) sowie zum Ausdrucken von Inhalten sind Kunden nur berechtigt, soweit eine Möglichkeit zum Download bzw. zum Ausdrucken auf dem Portal als Funktionalität (z. B. mittels eines Download-Buttons) zur Verfügung steht.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 9 Sperrung von Zugängen</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE kann den Zugang zu dem Portal vorübergehend oder dauerhaft sperren, wenn konkrete Anhaltspunkte vorliegen, dass Kunden gegen diese Nutzungsbedingungen und/oder geltendes Recht verstoßen bzw. verstoßen haben, oder wenn MICE ein sonstiges berechtigtes Interesse an der Sperrung hat. Bei der Entscheidung über eine Sperrung wird MICE die berechtigten Interessen des Kunden angemessen berücksichtigen.</p>
        <p>(2) Im Falle der vorübergehenden bzw. dauerhaften Sperrung sperrt MICE die Zugangsberechtigung und benachrichtigt den Kunden hierüber per E-Mail.</p>
        <p>(3) Im Falle einer vorübergehenden Sperrung reaktiviert MICE nach Ablauf der Sperrzeit die Zugangsberechtigung und benachrichtigt den Kunden hierüber per E-Mail. Eine dauerhaft gesperrte Zugangsberechtigung kann nicht wiederhergestellt werden. Dauerhaft gesperrte Kunden sind von der Teilnahme an dem Portal dauerhaft ausgeschlossen und dürfen sich nicht erneut auf dem Portal registrieren.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 10 Datenschutz</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Zu den Qualitätsansprüchen von MICE gehört es, verantwortungsbewusst mit den persönlichen Daten der Dienstleister und Kunden (diese Daten werden nachfolgend „personenbezogene Daten“ genannt) umzugehen. Die sich aus der Registrierung auf dem Portal sowie aus der Nutzung der verfügbaren Dienste ergebenden personenbezogenen Daten werden von MICE daher nur verarbeitet, soweit dies zur vertragsgemäßen Leistungserbringung erforderlich und durch gesetzliche Vorschriften erlaubt, oder vom Gesetzgeber angeordnet ist. MICE wird personenbezogenen Daten vertraulich sowie entsprechend den Bestimmungen des geltenden Datenschutzrechts behandeln und nicht an Dritte weitergeben.</p>
        <p>(2) Hierüber hinaus verwendet MICE personenbezogenen Daten nur, soweit der Betroffene hierzu ausdrücklich eingewilligt hat. Eine erteilte Einwilligung kann jederzeit widerrufen werden.</p>
        <p>(3) Nähere Informationen zum Datenschutz sind in der <a className='client-theme--color' href='/data-privacy'>Datenschutzerklärung</a> zu finden.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 11 Haftung</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Im Rahmen der Nutzung der Dienste haftet MICE nach Maßgabe der nachfolgenden Regelungen.</p>
        <p>(2) Für Schäden, die durch MICE oder durch dessen gesetzlichen Vertreter, leitende Angestellte oder einfache Erfüllungsgehilfen vorsätzlich oder grob fahrlässig verursacht wurde, haftet MICE unbeschränkt.</p>
        <p>(3) In Fällen der leicht fahrlässigen Verletzung von nur unwesentlichen Vertragspflichten haftet MICE nicht. Im Übrigen ist die Haftung von MICE für leicht fahrlässig verursachte Schäden auf die diejenigen Schäden beschränkt, mit deren Entstehung im Rahmen des jeweiligen Vertragsverhältnisses typischerweise gerechnet werden muss (vertragstypisch vorhersehbare Schäden). Dies gilt auch bei leicht fahrlässigen Pflichtverletzungen der gesetzlichen Vertreter, leitenden Angestellten bzw. einfachen Erfüllungsgehilfen von MICE.</p>
        <p>(4) Die vorstehende Haftungsbeschränkung gilt nicht im Falle von Arglist, im Falle von Körper- bzw. Personenschäden, für die Verletzung von Garantien sowie für Ansprüche aus Produkthaftung.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 12 Schlussbestimmungen</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Sofern in diesen Nutzungsbedingungen nicht ausdrücklich etwas anderes angegeben ist, sind sämtliche Erklärungen, die im Rahmen der Teilnahme an dem Portal abgegeben werden, in Schriftform oder per E-Mail abzugeben. Die E-Mail-Adresse von MICE lautet info@miceportal.com. Die postalische Anschrift von MICE lautet MICE Portal GmbH, Am BAchfeld 1, 85395 Attenkirchen. Änderungen der Kontaktdaten bleiben vorbehalten. Im Fall einer solchen Änderung wird MICE die Kunden hierüber in Kenntnis setzen.</p>
        <p>(2) Sollte eine Bestimmung dieser Nutzungsbedingungen unwirksam sein oder werden, so bleibt die Rechtswirksamkeit der übrigen Bestimmungen hiervon unberührt. An Stelle der unwirksamen Bestimmung gilt eine wirksame Bestimmung als vereinbart, die der von den Parteien gewollten wirtschaftlich am nächsten kommt.</p>
        <p>(3) Diese Nutzungsbedingungen unterliegen dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts (Convention of Contracts for the International Sales of Goods, CISG).</p>
        <p>(4) Ausschließlicher Gerichtsstand für alle sich aus diesen Teilnahme- und Nut-zungsbedingungen ergebenden Streitigkeiten ist der Sitz von MICE.</p>
      </div>
    )
  }

  renderEn () {
    return (
      <div className='containerFluid' style={{ paddingTop: '40px', paddingBottom: '40px' }}>
        As of September 2024
        <div className='c-content-title-1'>
          <h1 className='c-font-uppercase'>
            <a className='client-theme--color' href='#serviceProvidersTermsValidFrom'>AGB - Terms of Use for Service Provider - Valid from 1st of november 2024</a>
          </h1>
          <h1 className='c-font-uppercase'>
            <a className='client-theme--color' href='#serviceProvidersTerms'>AGB - Terms of Use for Service Provider - Valid until 31st of october 2024</a>
          </h1>
          <h1 className='c-font-uppercase'>
            <a className='client-theme--color' href='#customersTerms'>AGB - Terms of Use for Customers</a>
          </h1>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <div className='c-content-title-1'>
          <h1 className='c-font-uppercase' id='serviceProvidersTermsValidFrom'>Terms of Use for Service Provider - Valid from 1st of november 2024</h1>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 1 Subject of the Terms of Use</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE Portal GmbH (hereinafter referred to as "MICE") provides an online booking platform (hereinafter referred to as the "Portal") at www.miceportal.de, via which hotels, event locations or event providers (collectively referred to as "Providers") can create an entry, contact customers and accept bookings from registered customers. Providers can create their own personalised entry for their offers and use the other free and paid services currently available on the portal, subject to availability. Further information on the services can be found in the description of the services, § 8.</p>
        <p>(2) These Terms of Use govern the provision of services by MICE and the use of these services by providers.</p>
        <p>(3) Information regards data security on MICE is available <a className='client-theme--color' href='https://corporate.miceportal.com/en/data-protection' target='_blank' rel='noopener noreferrer'>here</a>.</p>
        <p>(4) These Terms of Use shall only apply to providers who are entrepreneurs („Unternehmer) within the meaning of Section 14 of the German Civil Code (BGB).</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 2 Changes to the terms of use</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE reserves the right to amend these Terms of Use at any time, including within existing contractual relationships, insofar as the individual amendments are reasonable for providers and the need for amendment arises for the following reasons: Legal changes, changes in case law, existing ambiguities in the terms of use, security aspects of the portal, changes to the functions of the portal. The following provisions of the Terms of Use cannot be changed as part of such an amendment: The type of contract, the essential business characteristics ("essentialia negotii") and the limitation of liability. MICE shall notify the providers of such changes at least 30 calendar days before the planned entry into force of the changes. If the providers do not object within 30 days of receipt of the notification and continue to use the services even after expiry of the objection period, the changes shall be deemed to have been effectively agreed from the expiry of the deadline. In the event of an objection, the contract shall be continued under the previous conditions. In the notification of change, MICE shall inform the providers of their right to object and of the consequences.</p>
        <p>(2) In the event of changes in VAT, MICE shall be entitled to adjust the remuneration for chargeable services such as "MICE ADS" and "MICE Professional Account" (see § 8) in line with this change, without the aforementioned right of objection.</p>
        <p>(3) MICE shall be authorised at any time to change services provided free of charge on the portal, to make new services available free of charge or against payment and to discontinue the provision of free services. In doing so, MICE shall take into account the legitimate interests of the provider.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 3 Registration on the portal</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) The use of the services available on the portal requires the providers to register. There is no entitlement to participation. Applications for participation may be rejected by MICE for objectively justified reasons.</p>
        <p>(2) Registration is only permitted for companies within the meaning of § 14 BGB („Unternehmer").</p>
        <p>(3) MICE offers providers the choice between a basic account and a professional account. Registration for the basic account is free of charge and includes acceptance of these terms of use. The professional account offers a significantly extended range of services against payment of a monthly usage fee. The current usage fee to be paid for the Professional account is displayed during the registration process. Further details on the respective range of services can be found under § 8.</p>
        <p>(4) Providers can switch from the basic account to the professional account at any time. To do this, providers must switch to the "Marketing" tab in the portal via their personal settings and select the appropriate product there.</p>
        <p>(5) The contact details and other information requested by MICE during the registration process must be provided completely and correctly by the provider. When registering a legal entity, the natural person authorised to represent the entity must also be stated.</p>
        <p>(6) Once all the requested data has been provided, MICE shall check it for completeness and plausibility. If the information is correct from MICE's point of view and MICE has no other concerns, MICE shall activate the requested access and notify the provider of this by e-mail. This e-mail shall be deemed to be the acceptance of the application to conclude a contract in accordance with these GTC.</p>
        <p>(7) The activation of the fee-based "Professional Account" or another marketing product takes place after acceptance of the offer from MICE by the provider. The contract term for the "Professional Account" is specified in the offer from MICE and also describes the billing period. For clarification: These GTC shall apply from the moment the account is activated. This also applies if the account is activated before the start of the contract term stated in the offer.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 4 Responsibility for the access data</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) During the registration process, providers are asked to enter a username and password. Providers can use this data to log in to the portal and edit their entry once access has been activated and confirmation has been given in accordance with § 4 (3). It is the responsibility of the provider to ensure that the username does not infringe the rights of third parties, in particular name or trademark rights, and does not offend common decency.</p>
        <p>(2) The access data, including the password, must be kept secret and not made accessible to unauthorised third parties.</p>
        <p>(3) It is also the responsibility of the provider to ensure that access to the portal and use of the services available on the portal is carried out exclusively by the provider or by persons authorised by the provider. If there is reason to fear that unauthorised third parties have obtained or will obtain knowledge of the access data, MICE must be informed immediately.</p>
        <p>(4) Providers are liable for any use and/or other activity carried out under their access data in accordance with the statutory provisions.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 5 Entry in the portal</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Providers may create an entry and post content on the portal and thus make it available to third parties, in particular customers, subject to the following provisions.</p>
        <p>(2) Providers can customise their entry in the portal within the framework of these Terms of Use and the specifications of MICE.</p>
        <p>(3) As a rule, MICE does not check the information in the entries. MICE reserves the right to change specified attributes and properties if they do not correspond to the truth or lead to an irritating search result.</p>
        <p>(4) When selecting images for the entry of a Professional Account, MICE reserves the right to request other images if the quality does not meet the requirements set out in the portal on the corresponding page or if the informative value does not meet MICE's expectations.</p>
        <p>(5) By posting content, providers grant MICE a free and transferable right of use to the respective content, in particular
        - to store the content on the MICE server and to publish it, in particular to make it publicly accessible (e.g. by displaying the content on the portal),
        - for processing and reproduction, insofar as this is necessary for the provision or publication of the respective content.
        If providers remove the posted content from the portal, the right of use and exploitation granted to MICE above shall expire. However, MICE shall remain authorised to retain copies made for backup and/or verification purposes.
        </p>
        <p>(6) Providers are fully responsible for the content they post. MICE does not necessarily check the content for completeness, accuracy, legality, topicality, quality and suitability for a specific purpose.</p>
        <p>(7) Providers therefore declare and warrant to MICE that they are the sole owner of all rights to the content they post on the portal, or that they are otherwise authorised (e.g. by effective permission from the rights holder) to post the content on the portal and to grant the rights of use and exploitation in accordance with the above paragraph (5).</p>
        <p>(8) MICE reserves the right to refuse the posting of content and/or to edit, block or remove content that has already been posted without prior notice if the posting of content by the provider or the posted content itself has led to an infringement of Section 10. However, MICE shall take your legitimate interests into account and choose the mildest means of defence against the violation of Section 10.</p>
        <p>(9) Suppliers are obliged to keep their data (including contact details) up to date. If a change occurs to the data provided during the period of participation, the information must be corrected on the portal without delay.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 6 Termination of participation</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Providers can cancel the basic account at any time by unsubscribing from participation in the portal.</p>
        <p>(2) A minimum contract term of 12 months applies to the Professional Account, beginning with the start of the contract specified in the offer (Section 3 (7)). From the expiry of the minimum contract term, the contract shall be automatically extended by renewal periods of 12 months in each case, unless one party cancels the contract subject to a notice period of 8 calendar weeks before the expiry of the minimum contract term or the respective renewal period.</p>
        <p>(3) When the cancellation takes effect, the contractual relationship shall end and providers may no longer use your access. MICE reserves the right to block the user name and password when the cancellation takes effect.</p>
        <p>(4) MICE shall be entitled to irretrievably delete all data generated in the course of participation 30 calendar days after the cancellation takes effect and after the expiry of any statutory retention periods, unless MICE is obliged to delete this data earlier for reasons of data protection law.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 7 Bookings and conclusion of contracts with customers</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) If a customer requests a booking from a provider via the portal and the provider concludes a corresponding contract with the customer, the customer and provider become contractual partners. In this relationship between the customer and the provider, only the contractual provisions of these parties as stated in their contract shall apply.</p>
        <p>(2) If the provider contacts the customer and asks the customer to send their enquiry directly to the provider instead of via the portal in order to avoid a booking in the portal, MICE shall have the right to delete the provider's entry.</p>
        <p>(3) MICE shall charge a commission of 12% on all services listed, booked and utilised in the offer or reservation contract when a booking is made for which the enquiry and offer were initiated on the portal. MICE calculates the agency commission after the conclusion of the event only on the basis of the services actually utilised. For clarification: The percentage rate will not be adjusted, but the fee will be adjusted if the service actually utilised and invoiced differs from the service booked. Services that a provider obtains from its subcontractor ("third party") are not subject to this commission regulation.</p>
        <p>(4) The obligation to pay an agency commission shall not apply if, at the time the offer is submitted, the provider is registered as a framework contract partner ("Rahmenvertragspartner") in the corresponding customer instance of MICE for the date of the event. This is the case when the commercial data of this framework agreement is activated in the customer's MICE portal instance. Activated means that the customer transfers the data to MICE and MICE activates it for the portal. For bookings made outside the agreed and deposited framework agreement period, the provider is obliged to pay the commission in accordance with paragraph (3) and to take this into account accordingly in its offer. The rejection of the commission due to a reference to a future framework agreement cannot be taken into account. In this case (i.e. in the event of a booking outside the agreed and deposited framework agreement period), the provider receives the customer's enquiry with the request to include 12% commission in its offer prices. If it transpires at the time of the event that the customer and provider have concluded a framework agreement in the meantime and these rates have also been declared in the MICE portal, MICE may reduce its commission claims against the provider by 50% at its discretion.</p>
        <p>(5) After the end of the event, MICE shall send the provider a request to enter the actual costs of the event in the MICE portal in order to calculate the commission invoice on the basis of the data actually utilised. The provider is obliged to enter the data in the portal within 3 working days of invoicing. If the provider breaches this obligation, MICE shall charge the commission on the basis of the contractual reservation amount. In addition, depending on the customer agreement, this invoice information is used for fast invoice processing. In the event of discrepancies in the services utilised, MICE shall have the right to inspect the customer invoice. The agency commission refers to the net amount plus the VAT applicable at the time of invoicing.</p>
        <p>(6) In the event that a reservation of a customer is cancelled for a fee and a cancellation invoice is issued for this, MICE shall be entitled to charge the agency commission of 12% on the cancellation invoice in accordance with paragraph 3.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 8 Utilisation of the services</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE offers both free (Basic Account) and fee-based services on the portal, such as the Professional Account and other marketing and advertising products. Unless otherwise agreed between the parties, the prices according to the current price list shall apply. Updated prices for future services will be sent by e-mail in PDF format.</p>
        <p>(2) The specific scope of the Professional Account and Basic Account can be found on the <a className='client-theme--color' href='https://corporate.miceportal.com/en/mice-online-marketing'>website</a>. Both include the possibility of an entry with unlimited content, participation in the booking portal and support. In general, however, there is no right to display the entry in the portal as part of the free Basic account. The provider is also not entitled to a specific ranking for their entry. The ranking is based on a "quality score", which is explained in more detail <a className='client-theme--color' href='https://1789719.fs1.hubspotusercontent-eu1.net/hubfs/1789719/Partner%20Managment/Datenscore-factsheetDE.pdf' target='_blank' rel='noopener noreferrer'>[here]</a>. The purchase of the following additional functions has an influence on the ranking.</p>
        <p>(3) The Professional Account includes additional services such as highlighted ranking, analysis tools, direct search, message centre and enhanced support. The use of the services available within the Professional Account is covered by the monthly usage fee for the registration of the Professional Account. If a Professional Account exists at the time the offer is submitted, the commission claims for this booking are reduced from 12% to 10% in accordance with § 7. Otherwise, § 7 applies accordingly.</p>
        <p>(4) Service providers can book MICE Ads to place corresponding adverts in the portal. This increases the reach and makes it easier to reach the target group. The specific functions of MICE Ads can be found on the website.</p>
        <p>(5) For the fee-based Professional Account and for all fee-based services (including those that are accessible within the Basic Account), MICE guarantees availability of 99% on a monthly average within its area of responsibility. The regular maintenance windows of the portal, which are every Sunday between 6:00 pm and 5:00 am, are not included in the calculation of availability.</p>
        <p>(6) All fees stated are subject to the applicable statutory value added tax. Your Professional Account will be activated no later than two working days after acceptance of the offer. The contract term corresponds to the details in the offer (see § 3 para. 7). Payment is made annually at the beginning of the contract term specified in the offer. The Payment term is 14 days without deduction.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 9 Protection of content, responsibility for third-party content</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) The content available on the portal originates partly from MICE and partly from other providers or other third parties. Content from providers and other third parties is hereinafter collectively referred to as "third-party content". MICE does not check third-party content for completeness, accuracy and legality and therefore assumes no responsibility or warranty for the completeness, accuracy, legality and timeliness of third-party content, unless the illegality is obvious and known to MICE. This also applies with regard to the quality of the third-party content and its suitability for a specific purpose, and also insofar as third-party content on linked external websites is concerned.</p>
        <p>(2) All content on the portal is third-party content, with the exception of content that is labelled with a copyright notice from MICE.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 10 Prohibited activities</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Providers are prohibited from any activities on or in connection with the portal that violate applicable law, infringe the rights of third parties or violate the principles of the protection of minors. In particular, the following actions are prohibited:</p>
        <p>- the posting, distribution, offering and advertising of pornographic content, services and/or products that violate youth protection laws, data protection laws and/or other laws and/or fraudulent content, services and/or products;</p>
        <p>- the use of content that insults or defames other providers, customers or third parties;</p>
        <p>- the use, provision and distribution of content, services and/or products that are legally protected or encumbered with third-party rights (e.g. copyrights) without being expressly authorised to do so.</p>
        <p>(2) Furthermore, providers are prohibited from the following activities when posting their own content on the portal and when communicating with customers (e.g. by sending personal messages), irrespective of any violation of the law:</p>
        <p>- the spread of viruses, Trojans and other malicious files;</p>
        <p>- the sending of junk or spam mails and chain letters;</p>
        <p>- the dissemination of lewd, offensive, sexually explicit, obscene or defamatory content or communication as well as content or communication that is likely to promote or support racism, fanaticism, hatred, physical violence or unlawful acts (in each case explicitly or implicitly);</p>
        <p>- the harassment of customers, e.g. through repeated personal contact without or contrary to the reaction of the customer, as well as the promotion or support of such harassment;</p>
        <p>- requesting customers to disclose passwords or personal data for commercial or illegal or unlawful purposes;</p>
        <p>- the distribution and/or public reproduction of content available on the portal, unless this is expressly authorised by the respective author or expressly made available as a functionality on the portal.</p>
        <p>(3) Also prohibited is any action that is likely to impair the smooth operation of the portal, in particular to place an excessive load on MICE's systems.</p>
        <p>(4) Should any illegal, abusive, non-contractual or otherwise unauthorised use of the portal become known, MICE must be informed immediately. MICE shall then investigate the matter and take appropriate action if necessary.</p>
        <p>(5) If there is a suspicion of illegal or criminal offences, MICE shall be entitled and, if necessary, obliged to review the activities and, if necessary, to take appropriate legal action. This may also include forwarding the facts of the case to the public prosecutor's office.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 11 Blocking of access/entries</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE may temporarily or permanently block access to the portal and entries if there are concrete indications that providers are violating or have violated these Terms of Use and/or applicable law, or if MICE has another legitimate interest in blocking access. When deciding whether to block an entry, MICE shall give due consideration to the legitimate interests of the provider.</p>
        <p>(2) In the event of temporary or permanent blocking, MICE shall block the access authorisation and notify the person concerned by email. In the event of a longer-term (more than 30 days) or permanent blocking, advance payments (e.g. for a professional account) shall be refunded on a pro rata basis.</p>
        <p>(3) In the event of temporary blocking, MICE shall reactivate the access authorisation after the blocking period has expired and notify the person concerned by e-mail. Permanently blocked access authorisation cannot be restored. Permanently blocked providers are permanently excluded from participating in the portal and may not register on the portal again. The entry will be deleted immediately.</p>
        <p>(4) MICE may block the user account of a provider if there are indications that the user account has been used in an unauthorised manner and/or an attempt has been made or is being made to gain unauthorised access to the portal or the underlying systems of MICE (backend) from the user account, for example by using its own software ("hacking"). In such a case, MICE shall immediately notify the provider concerned via the e-mail address stored in the user account and enable the provider to regain access by creating a new user account, unless there are facts that suggest that the provider has attempted to gain unauthorised access to MICE's systems.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 12 Data protection</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) One of MICE's quality standards is to handle the personal data of providers and customers ("personal data") responsibly. The personal data resulting from registration on the portal and from the use of the available services shall therefore only be processed by MICE insofar as this is necessary for the contractual provision of services and is permitted by law or ordered by the legislator. MICE shall treat personal data confidentially and in accordance with the provisions of the applicable data protection law and shall not pass it on to third parties.</p>
        <p>(2) Beyond this, MICE shall only use personal data if the data subject has expressly consented to this. Any consent given can be revoked at any time.</p>
        <p>(3) Further information on data protection can be found in the privacy policy.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 13 Liability</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) If damage is caused by the use of services provided free of charge on the portal, MICE shall only be liable to the extent that the damage was caused by the contractual use of the free content and/or services and only in the event of intent (including fraudulent intent) and gross negligence on the part of MICE.</p>
        <p>(2) Within the scope of the use of chargeable services, MICE shall be liable in accordance with the following provisions in paragraphs 3 to 5.</p>
        <p>(3) MICE shall be liable without limitation for damages caused by MICE or its legal representatives, executives or simple vicarious agents through wilful intent or gross negligence.</p>
        <p>(4) MICE shall not be liable in cases of slightly negligent breach of non-essential contractual obligations. Essential contractual obligations are those obligations that are of particular importance for the achievement of the contractual objective, as well as all those obligations which, in the event of a culpable breach, could jeopardise the achievement of the purpose of the contract. In these cases (breach of essential contractual obligations), MICE's liability for damages caused by slight negligence shall be limited to those damages that can typically be expected to occur in the context of the respective contractual relationship (foreseeable damages typical for the contract – "vertragstypisch vorhersehbare Schäden"). This shall also apply to slightly negligent breaches of duty by MICE's legal representatives, executives or simple vicarious agents.</p>
        <p>(5) The above limitation of liability shall not apply in the event of fraudulent intent ("Arglist"), in the event of bodily injury or personal injury, for the breach of guarantees and for claims arising from product liability.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 14 Scope of authorised use/monitoring of usage activities</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) The authorisation of use is limited to access to the portal and to the use of the services available on the portal within the framework of the provisions of these Terms of Use, any contractual requirements, MICE guidelines and guidelines.</p>
        <p>(2) The providers themselves shall be responsible for creating the technical conditions necessary for the contractual use of the services. MICE shall not be obliged to provide any advice in this regard.</p>
        <p>(3) MICE points out that usage activities may be monitored to the extent permitted by law. This may also include the logging of IP connection data and chat histories and their evaluation in the event of a concrete suspicion of a breach of these Terms of Use and/or in the event of a concrete suspicion of the existence of another illegal act or criminal offence.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 15 Final provisions</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Unless expressly stated otherwise in these Terms of Use, all declarations made in connection with participation in the portal must be made in writing or by e-mail. The e-mail address of MICE is: info@miceportal.com. The postal address of MICE is Am Bachfeld 1, 85395 Attenkirchen. We reserve the right to change the contact details. In the event of such a change, MICE shall inform the provider accordingly.</p>
        <p>(2) Should any provision of these Terms of Use be or become invalid, this shall not affect the legal validity of the remaining provisions. In place of the invalid provision, a valid provision shall be deemed to have been agreed which comes as close as possible to the economic intention of the parties.</p>
        <p>(3) These Terms of Use are subject to the law of the Federal Republic of Germany with the exclusion of the UN Convention on Contracts for the International Sale of Goods (CISG).</p>
        <p>(4) For all disputes arising from or in connection with this contract or its validity, the exclusive place of jurisdiction for all types of proceedings shall be Munich, unless otherwise stipulated by law.</p>
        <hr style={{ marginTop: '30px', marginBottom: '30px' }} />
        <div className='c-content-title-1'>
          <h1 className='c-font-uppercase' id='serviceProvidersTerms'>Terms of Use for Service Provider - Valid until 31st of october 2024</h1>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 1 Subject of the Terms of Use</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE Portal GmbH (hereinafter referred to as "MICE") provides an online booking platform on www.miceportal.de (hereinafter referred to as "<strong>Portal</strong>"), which allows hotels, event locations or event providers (together referred to as "<strong>Provider</strong>") to create an entry, contact customers and accept bookings from registered customers. Providers can create their individual personal entry for their offers and use the other free and paid services currently available on the portal within the scope of their respective availability. More detailed information on the services can be found in the description of the services, § 8.</p>
        <p>(2) This terms of use regulate the provision of services by MICE and the use of these services by Providers.</p>
        <p>(3) Information on MICE is available <a className='client-theme--color' href='https://corporate.miceportal.com/data-protection' target='_blank' rel='noopener noreferrer'>here</a>.</p>
        <p>(4) These Terms of Use shall only apply to providers who are entrepreneurs within the meaning of § 14 BGB (German Civil Code).</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 2 Changes to the Terms of Use</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE reserves the right to change these terms of use at any time with effect also within the existing contractual relationships, insofar as the individual changes are reasonable for Providers and the necessity of a change results from the following reasons: Legal changes, changes in jurisdiction, existing ambiguities in the terms of use, security aspects of the portal, changes in the functions of the portal. Within the framework of such an amendment, the following provisions of the Terms of Use cannot be changed: The type of contract, the essential business characteristics ("essentialia negotii") and the limitation of liability. MICE will inform the providers about such changes at least 30 calendar days before the planned entry into force of the changes. If the providers do not object within 30 days of receipt of the notification and continue to use the services even after the expiry of the objection period, the changes shall be deemed to have been agreed with effect from the expiry of the period. In the event of an objection, the contract will be continued under the previous conditions. In the notification of change, MICE will inform the providers of their right to object and of the consequences.</p>
        <p>(2) In the event of changes in value added tax, MICE is entitled to adjust the remuneration for fee-based services such as "MICE ADS" and "MICE Professional Account" (see § 8) in accordance with this change without the aforementioned right of objection.</p>
        <p>(3) MICE is entitled to change services provided free of charge at any time on the portal, to make available new services free of charge or against payment  and to discontinue the provision of free services. In doing so, MICE will take the justified interests of the provider into account.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 3 Registration on the Portal</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) The use of the services available on the portal requires a registration of the providers. There is no entitlement to participation. MICE is entitled to reject applications for participation if there is an objectively justified reason.</p>
        <p>(2) Registration is only permitted for companies in the sense of § 14 BGB. In the case of a legal entity, the registration must be made by a natural person with unlimited legal capacity and the right to represent the entity.</p>
        <p>(3) MICE offers providers the choice between a basic account and a professional account. The registration for the basic account is free of charge and includes the acceptance of these terms of use. The professional account offers a significantly extended range of services against payment of a monthly usage fee. The current usage fee to be paid for the professional account is displayed during the registration process. More details on the respective service offer can be found under § 8.</p>
        <p>(4) Providers can switch from the basic account to the professional account at any time. To do so, providers must switch to the "Marketing" tab in the portal via their personal settings and select the appropriate product there.</p>
        <p>(5) The contact data and other information requested by MICE during the registration process must be provided completely and correctly by the Provider. When registering a legal entity, the natural person authorized to represent the entity must also be indicated.</p>
        <p>(6) After all requested data has been provided, it will be checked by MICE for completeness and plausibility. If the information is correct from the point of view of MICE and if there are no other concerns from the point of view of MICE, MICE will activate the requested access and notify the provider of this by e-mail. The e-mail shall be deemed to be acceptance of the application for the conclusion of a contract in accordance with these GTC.</p>
        <p>(7) The activation of the fee-based "professional account" or another marketing product takes place through the acceptance of the offer of MICE, which the provider signs and sends back scanned via e-mail to <a className='client-theme--color' href='mailto:info@miceportal.com'>info@miceportal.com</a>. The term of the contract shall commence from the first of the month following the day of activation.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 4 Responsibility for the access data</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) During the registration process, providers are asked to enter a user name and password. With this data, providers can log in to the portal and edit their entry after activation of access and confirmation in accordance with § 4 (3). It is the responsibility of the provider to ensure that the user name does not violate the rights of third parties, in particular no name or trademark rights and does not offend common decency.</p>
        <p>(2) The access data including the password must be kept secret and must not be made accessible to unauthorized third parties.</p>
        <p>(3) It is also the responsibility of the Provider to ensure that access to the portal and the use of the services available on the portal is carried out exclusively by the Provider or by persons authorized by the Provider. If it is to be feared that unauthorized third parties have gained or will gain knowledge of the access data, MICE must be informed immediately.</p>
        <p>(4) Providers are liable for any use and/or other activity carried out under their access data in accordance with the statutory provisions.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 5 Entry in the portal</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Providers may create an entry and post content on the portal and thus make it available to third parties, in particular customers, in compliance with the following regulations.</p>
        <p>(2) Providers can individually design their entry in the portal within the framework of these terms of use and the specifications of MICE.</p>
        <p>(3) As a rule, MICE does not check the information in the entries. MICE reserves the right to change specified attributes and properties if they do not correspond to the truth or lead to an irritating search result.</p>
        <p>(4) When selecting the images within the scope of the entry of a professional account, MICE reserves the right to request other images if the quality does not meet the requirements that are stored in the portal on the corresponding page or if the expressiveness does not meet the expectations of MICE.</p>
        <p>(5) By posting content, Providers grant MICE in each case a free and transferable right of use to the respective content, in particular</p>
        <p>– to store the contents on the server of MICE as well as to publish them, in particular to make them publicly accessible (e.g. by displaying the contents on the portal),</p>
        <p>– to edit and reproduce, insofar as this is necessary for the provision or publication of the respective content, and</p>
        <p>Insofar as providers remove the posted content from the portal, the right of use and exploitation granted to MICE above shall expire. However, MICE remains entitled to keep copies made for backup and/or verification purposes.</p>
        <p>(6) Providers are fully responsible for the content they post. MICE does not necessarily assume a review of the content for completeness, accuracy, legality, timeliness, quality and suitability for a particular purpose.</p>
        <p>(7) Providers therefore declare and warrant to MICE that they are the sole owner of all rights to the content they post on the portal, or are otherwise entitled (e.g. through effective permission from the rights holder) to post the content on the portal and to grant the rights of use and exploitation in accordance with the above paragraph (5).</p>
        <p>(8) MICE reserves the right to refuse the posting of content and/or to edit, block or remove content already posted without prior notice if the posting of the content by the provider or the posted content itself has led to a violation of § 10.  However, MICE will take your justified interests into account and choose the mildest means to avert the violation of § 10.</p>
        <p>(9) Providers are obliged to keep their data (including contact details) up to date. If a change in the data provided occurs during the period of participation, the information must be corrected on the portal without delay.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 6 Termination of participation</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Providers may terminate the basic account at any time by logging out of participation in the portal.</p>
        <p>(2) A minimum contract term of 12 months applies to the professional account, beginning with the activation of the account § 4 (4). From the expiry of the minimum contract term, the contract will be automatically extended by extension periods of 12 months in each case, unless one party terminates the contract by giving 12 calendar weeks' notice prior to the expiry of the minimum contract term or the respective extension period.</p>
        <p>(3) When the termination takes effect, the contractual relationship ends and Provider may no longer use their access. MICE reserves the right to block the user name and the password when the termination becomes effective.</p>
        <p>(4) MICE is entitled to irretrievably delete all data created in the course of participation upon expiry of 30 calendar days after the termination takes effect and after expiry of any statutory retention periods.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 7 Bookings and conclusion of contracts with customers</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) If a customer requests a booking from the provider via the portal and the provider concludes a corresponding contract with the customer, only these become contractual partners. In this relationship between the customer and the provider, only the contractual provisions of these parties named in the entry apply.</p>
        <p>(2) If the Provider contacts the customer and asks him to send his request directly to him instead of in the portal in order to avoid booking in the portal, MICE has the right to delete the entry.</p>
        <p>(3) MICE charges a commission of 10% on all services which are subject to a reservation contract which is accepted by a customer via the Portal. Services which a Provider obtains from its subcontractors ("third parties") are not subject to this commission regulation. The obligation to pay a commission does not apply if this is regulated accordingly in a framework agreement between Provider and customer at the time of the booking and the commercial data of this framework agreement is activated in the customer's MICE portal instance (activated means that the customer transfers the data to MICE and MICE makes it live for the portal). If the Provider and customer refer to a framework agreement which was not yet activated in the customer's MICE portal instance at the time of a booking, the brokerage commission shall be reduced by 50% in accordance with Paragraph 3 Sentence 1.</p>
        <p>(4) MICE will issue the invoice for the agency commission after the end of the event for the services actually used. In the event of discrepancies in the services used, MICE has the right to inspect the customer invoice. The agency commission refers to the net amount plus the currently valid value added tax.</p>
        <p>(5) In the event that a chargeable booking is cancelled, and a cancellation invoice is issued for this, MICE is entitled to charge the agency commission in the amount of 10% in accordance with paragraph 3 on the cancellation invoice.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 8 Use of the services</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE offers both free (basic account) and paid services such as the professional account and MICE Ads on the portal. Unless otherwise agreed between the parties, the prices apply according to the current price list. Updated prices will be sent by e-mail in PDF format.</p>
        <p>(2) The specific scope of the professional account and basic account can be found on the <a className='client-theme--color' href='https://corporate.miceportal.com/en/mice-online-marketing'>website</a>. Both include the possibility of an entry with unlimited content, participation in the booking portal and support. In general, however, the free basic account does not give the provider the right to display the entry in the portal. Likewise, the provider has no right to a certain ranking of his entry. The ranking is based on a "quality score", which is explained in more detail at [<a className='client-theme--color' href='https://1789719.fs1.hubspotusercontent-eu1.net/hubfs/1789719/Partner%20Managment/Datenscore-FactsheetEN.pdf'>here</a>]. The booking of the following payment functions have an influence on the ranking.</p>
        <p>(3) The professional account includes additional services such as analysis tools, direct search, message center and increased support. The use of the services available within the professional account is compensated with the monthly usage fee for the registration of the professional account.</p>
        <p>(4) Service providers can book MICE ads to display corresponding advertisements on the portal. This allows to increase the reach and to better reach the target group. The specific functions of MICE-Ads can be found on the website.</p>
        <p>(5) For the fee-based professional account as well as for all fee-based services (including those accessible within the basic account), MICE guarantees an availability of 98% on a monthly average within its area of responsibility. The calculation of availability does not include the regular maintenance windows of the portal, which are every Sunday between 6:00 pm and 5:00 am.</p>
        <p>(6) All stated fees are subject to the applicable statutory value-added tax. Your professional account will be activated no later than two working days after acceptance of the offer. The contract term begins on the first of the following month. Payment is made annually at the beginning of the contract period. Payment is due immediately without deduction. If you purchase an advertising space, you will receive a separate invoice no later than 30 days before activation of your advertising.</p>
        <p>(7) In the event of delay, MICE is entitled to demand late payment interest in the amount of 9 percentage points above the base interest rate, unless the Provider proves a lower damage or MICE proves a higher damage.</p>
        <p>(8) Offsetting is only permitted with undisputed or legally established counterclaims. A right of retention can only be asserted if it is based on the same contractual relationship.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 9 Protection of content, responsibility for third-party content</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) The content available on the portal originates partly from MICE and partly from other providers or other third parties. Content of the providers and other third parties is hereinafter collectively referred to as "<strong>third-party content</strong>". MICE does not check the completeness, correctness and legality of third-party content and therefore does not assume any responsibility or warranty for the completeness, correctness, legality and up-to-dateness of the third-party content, unless the illegality is obvious and known to MICE. This also applies with regard to the quality of the third-party content and its suitability for a particular purpose, and also insofar as it concerns third-party content on linked external websites.</p>
        <p>(2) All content on the portal is third-party content, except for that content which is marked with a copyright notice by MICE.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 10 Prohibited activities</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Providers are prohibited from any activities on or in connection with the portal that violate applicable law, infringe the rights of third parties or violate the principles of the protection of minors. In particular, the following actions are prohibited:</p>
        <p>– the posting, distribution, offer and advertising of pornographic content, services and/or products that violate the laws for the protection of minors, data protection laws and/or other laws and/or are fraudulent;</p>
        <p>– the use of content that insults or defames   other providers, customers or third parties;</p>
        <p>– the use, provision and distribution of content, services and/or products that are protected by law or encumbered   with third-party rights (e.g. copyrights) without being expressly authorized to do so.</p>
        <p>(2) Furthermore, even irrespective of any violation of the law, providers are prohibited from the following activities when posting their own content on the portal and when communicating with Customers (e.g. by sending personal messages):</p>
        <p>– the distribution of viruses, trojans and other malicious files;</p>
        <p>– the sending of junk or spam e-mails and chain letters;</p>
        <p>– The dissemination of lewd, offensive, sexually oriented, obscene or defamatory content or communication, as well as content or communication that is suitable to promote or support racism, fanaticism, hatred, physical violence or illegal acts (in each case explicitly or implicitly);</p>
        <p>– harassing customers, e.g. by contacting them personally several times without or contrary to the customer's reaction, as well as encouraging or supporting such harassment;</p>
        <p>– asking customers to disclose passwords or personal data for commercial or illegal purposes;</p>
        <p>– the dissemination and/or public reproduction of content available on the portal, unless this is expressly permitted by the respective author or expressly provided as functionality on the portal.</p>
        <p>(3) Also prohibited is any action that is likely to impair the smooth operation of the portal, in particular to place an excessive load on the systems of MICE.</p>
        <p>(4) Should an illegal, abusive, non-contractual or otherwise unauthorized use of the portal become known, MICE is to be informed immediately. MICE will then examine the matter and, if necessary, initiate appropriate steps.</p>
        <p>(5) In the event of suspicion of illegal or punishable actions, MICE is entitled and, if necessary, also obliged to check the activities and, if necessary, to initiate suitable legal steps. This may also include the forwarding of a case to the public prosecutor's office.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 11 Blocking of accesses/entries </h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE may block access to the portal and entries temporarily or permanently if there are concrete indications that providers violate or have violated these terms of use and/or applicable law, or if MICE has another justified interest in the blocking. When deciding on a blocking, MICE will give due consideration to the legitimate interests of the provider.</p>
        <p>(2) In the event of temporary or permanent blocking, MICE shall block the access authorization and notify the person concerned of this by e-mail.</p>
        <p>(3) In the event of a temporary blocking, MICE reactivates the access authorization after the blocking period has expired and notifies the person concerned of this by e-mail. A permanently blocked access authorization cannot be restored. Permanently blocked providers are permanently excluded from participation in the portal and are not allowed to register on the portal again. The entry will be deleted immediately.</p>
        <p>(4) MICE may block the user account of a provider if there are indications that the user account has been used in an unauthorized manner and/or it has been undertaken or attempted to gain unauthorized access to the portal or the underlying systems of MICE (backend) from the user account, for example by using own software ("hacking"). In such a case, MICE will immediately notify the provider concerned via his e-mail address stored in the user account and allow him access again by creating a new user account, unless there are facts which suggest that the provider has attempted to gain unauthorized access to the systems of MICE.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 12 Data protection</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) It is part of MICE's quality standards to handle the personal data of the providers and customers ("<strong>personal data</strong>") responsibly. The personal data resulting from the registration on the portal as well as from the use of the available services will therefore only be processed by MICE insofar as this is necessary for the contractual provision of services and is permitted by legal regulations or ordered by the legislator. MICE will treat personal data confidentially and in accordance with the provisions of the applicable data protection law and will not pass them on to third parties.</p>
        <p>(2) Beyond this, MICE will only use personal data if the person concerned has expressly consented to this. Any consent given may be revoked at any time.</p>
        <p>(3) More detailed information on data protection can be found in the <a className='client-theme--color' href='/data-privacy'>privacy policy</a>.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 13 Liability</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Should damage occur due to the use of services provided free of charge on the portal, MICE is only liable insofar as the damage occurred due to the contractual use of the free content and/or services and only in the case of intent (including fraudulent intent) and gross negligence on the part of MICE.</p>
        <p>(2) Within the scope of the use of chargeable services, MICE is liable in accordance with the following provisions in paragraphs 3 to 5.</p>
        <p>(3) MICE is liable without limitation for damages caused by MICE or by its legal representatives, executives or simple vicarious agents intentionally or through gross negligence.</p>
        <p>(4) MICE is not liable in cases of slightly negligent breach of only minor contractual obligations. Apart from that, the liability of MICE for damages caused by slight negligence is limited to those damages which must typically be expected to occur within the framework of the respective contractual relationship (contract-typical foreseeable damages). This also applies in the case of slightly negligent breaches of duty by the legal representatives, executive employees or simple vicarious agents of MICE.</p>
        <p>(5) The above limitation of liability does not apply in the case of fraudulent intent, in the case of bodily injury or personal damage, for the breach of warranties and for claims arising from product liability.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 14 Scope of Permitted Use/Monitoring of Use Activities</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) The authorization of use is limited to access to the portal and to the use of the services available on the portal in each case within the framework of the provisions of these terms of use, any contractual specifications, MICE guidelines and guides.</p>
        <p>(2) The providers themselves are responsible for creating the technical conditions necessary for the contractual use of the services within their area of responsibility. MICE does not owe any advice in this regard.</p>
        <p>(3) MICE points out that usage activities can be monitored to the extent permitted by law. This may also include the logging of IP connection data and chat histories as well as their evaluation in the event of a concrete suspicion of a violation of these terms of use and/or in the event of a concrete suspicion of the existence of another illegal act or criminal offense.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 15 Final provisions</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Unless expressly stated otherwise in these terms of use, all declarations made in the context of participation in the portal must be made in writing or by e-mail. The e-mail address of MICE is: <a className='client-theme--color' href='mailto:info@miceportal.com'>info@miceportal.com</a>. The postal address of MICE is Am Bachfeld 1, 85395 Attenkirchen. Contact details are subject to change. In the event of such a change, MICE will inform the Provider thereof.</p>
        <p>(2) Should any provision of these terms of use be or become invalid, this will not affect the legal validity of the remaining provisions. In place of the invalid provision, a valid provision will deemed to have been agreed which comes as close as possible to the economic purpose intended by the parties.</p>
        <p>(3) These terms of use shall be governed by the laws of the Federal Republic of Germany, excluding the UN Convention on Contracts for the International Sale of Goods (CISG).</p>
        <p>(4) The exclusive place of jurisdiction for all disputes arising from these terms of use is the registered office of MICE.</p>
        <hr style={{ marginTop: '30px', marginBottom: '30px' }} />
        <div className='c-content-title-1'>
          <h1 className='c-font-uppercase' id='customersTerms'>Terms of Use for Customers</h1>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 1 Subject of the Terms of Use</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE Portal GmbH (hereinafter referred to as "MICE") provides an online booking platform available under www.miceportal.de (hereinafter referred to as "<strong>Portal</strong>") where registered customers can contact listed hotels, an event location or an event service provider (collectively referred to as "<strong>Service Provider</strong>"), communicate and make reservations.</p>
        <p>(2) The present Terms of Use govern the provision of services by MICE and the use of these services by duly registered customers.</p>
        <p>(3) Information about MICE is available <a className='client-theme--color' href='https://corporate.miceportal.com/' target='_blank' rel='noopener noreferrer'>here</a>.</p>
        <p>(4) These Terms of Use shall only apply to customers who are entrepreneurs within the meaning of § 14 BGB (German Civil Code).</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 2 Changes of the Terms of Use</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE  reserves  the  right  to  change  these  Terms  ofUse  at  any  time  with  effect within  the  existing  contractual  relationships. MICE  shall  notify  the  customer  of  such changes at least 30 calendar days prior to the planned effective date of such changes. Unless  customers  object  within  30  days  of  receipt  of  the  notice  and  continue  to make  use of  the  services  even  after  the  expiry  of  the objection  period,  the  changes shall  be  deemed  to  have  been  validly  agreed  upon  from  the expiry  of  the  period.  In the event of an objection, the contract shall be continued under the previous conditions. In the notice of change, MICE shall inform the customer of its right to object and of the consequences.</p>
        <p>(2) MICE  is  entitled  at  any  time  to  change  services  provided  free  of  charge  on  the Portal, to make new services available free of charge or against payment and to discontinue  the  provision  of  services  free  of  charge. MICE  will  take  the  legitimate  interests of the customer into consideration.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 3 Protection of contents, Responsibility for contents of third parties</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) The contents available on the portal are predominantly protected by copyright or other  protective  rights  and  are  the  property  of  MICE,  the  service  provider  or  other third parties who have made the respective contents available. The compilation of the contents as such may be protected as a database or database work within the meaning  of  §§  4  para.  2,  87a  para.  1  German  Copy  Right  Act.  Customers  may  only  use this content in accordance with these Terms of Use and within the framework specified on the Portal.</p>
        <p>(2) The content available on the Portal derivespartly from MICE and partly from other service  providers or  other  third parties.  Contents of  the  service  providers  as  well  as other  third  parties  are  hereinafter  collectively  referred  to  as  "Third  Party  Contents". MICE does not check Third Party Content for completeness, correctness and legality and therefore does not assume any responsibility or warranty for the completeness, correctness, legality and validity of the third party content, unless the illegality is obvious and MICE is aware of it. This also applies with regard to the quality of the third-party content and its suitability for a specific purpose, and also insofar as third-party content is concerned on linked external websites.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 4 Registration and Participation</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) The  use  of  the  services  available  on  the  portal,  in  particular  the  conclusion  of  a booking, requires the registration of the customers. There is no entitlement to participation. MICE is entitled to reject participation requests without giving reasons.</p>
        <p>(2) Registration  is  only  permitted  for  companies  within  the  meaning  of  §  14  BGB (German Civil Code). In the case of a legal entity, registration must be made by a natural person with unlimited legal capacity and power of representation.</p>
        <p>(3) When  registering,  the mandatory  information marked  must be  completed.  These are  in  particular:  Contact  data  of  the  company,  first  name,  surname  of  the  user,  e-mail address.</p>
        <p>(4) In  the  course  of  registration,  customers  are  asked  to  provide  a  user  name  and password. With these data the customers can log in to the portal after the activation of their access and their confirmation. It is the customer's responsibility to ensure that the  user  name does  not  infringe  the  rights  of  third  parties,  in  particular  name or  trademark rights, and does not offend common decency.</p>
        <p>(5) The access data including the password shall be kept secret by the customer and shall not be made accessible to unauthorized third parties.</p>
        <p>(6) It is also the responsibility of the customer to ensure that access to the portal and use  of the  services  available  on  the  portal  is  exclusively  by  the  customer  himself  or by persons authorized by him. If it is to be feared that unauthorized third parties have gained or will gain knowledge of the access data, MICE must be informed immediately.</p>
        <p>(7) Customers are liable for any use and/or other activity carried out under the access data in accordance with the statutory provisions.</p>
        <p>(8) Customers are obliged to keep their data (including contact details) up to date. In the event of a change in the data provided during the period of participation, Customers  shall  immediately  correct  the  data  in  their  personalsettings  on  the  Portal. If  a change is not possible, the newdata must be communicated to MICE immediately by e-mail or fax.</p>
        <p>(9) Customers can cancel their participation in the portal at any time. Upon deregistration  the  contractual relationship  ends  and customers  may  no  longer  use  your  access. MICE  reserves  the  right  to  block  the  user  name  as  well  as  the  password  by logging out.</p>
        <p>(10) MICE is entitled to irretrievably delete all data created within the scope of participation at the end of 30 calendar days after deregistration and after expiry of any statutory retention periods.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 5 Bookings und Conclusion of contrcats with customers</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) If a customer requests a booking via the portal from the service provider and the service  provider  concludes  a  corresponding  contract  with  the  customer,  only  these parties  become  contractual  partners.  In  this  relationship  between  the  customer  and the service provider, the contractual regulations of these parties, which are named in the entry, apply exclusively.</p>
        <p>(2) If  the  Service  Provider  contacts  the  customer  and  requests  the  customer  not  to send  his  request  in  the  Portal,  but  directly  to  the  customer  in  order  to  circumvent  a booking in the Portal, MICEhas the right to prohibit the customer from further participation in the Portal.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 6 Services and Availabilities</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) The content and scope of the services are determined by the functionalities currently available on the Portal.</p>
        <p>(2) The services available on the Portal may also include services of third parties to which MICE merely provides access. For the use of such services - each of which is marked as a third party service - regulations deviating from or additional to these Terms of Use may apply, to which MICE will refer.</p>
        <p>(3) A claim to the use of the services available on the portal exists only within the scope of the technical and operational possibilities at MICE. MICE shall endeavour to ensure that its services can be used as uninterruptedly as possible. However, temporary restrictions or interruptions may occur due to technical malfunctions (e.g. interruption of the power supply, hardware and software errors, technical problems in the data lines).</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 7 Scope of permitted use, monitoring of activities</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) The right of use is limited to the access to the Portal as well as to the use of the services available on the Portal within the scope of the regulations of these terms of use.</p>
        <p>(2) The customers themselves are responsible for creating the technical prerequisites necessary  for  the  contractual  use  of  the  services  within  their  area  of  responsibility. MICE does not owe any advice in this regard.</p>
        <p>(3) MICE points out that usage activities can be monitored to the extent permitted by law. This may also include the logging of IP connection data and call histories as well as their analysis in the event of a concrete suspicion of a violation of these Termsof Use and/or in the event of a concrete suspicion of the existence of another illegal act or criminal offence.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 8 Right ofuseon content available on the Portal</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Insofar  as  these  Terms  of  Use  or  the  Portal  do  not  expressly  permit  any  further use or make it possible on the Portal through appropriate functionality (e.g. download button),</p>
        <p> - customers may access and display the content available on the Portal online exclusively for the purpose of exercising their professional activity. This right of use is limited to the duration of the contractual participation in the Portal;</p>
        <p> - it is forbidden for customers to edit, change, translate, present or demonstrate, publish,  exhibit, duplicate or  distribute  the  content  available  on  the Portal  in  whole or  in part.  It  is  also  prohibited  to  remove  or  change  copyright  notices,  logos  and  other marks or protective notices.</p>
        <p>(2) Customers are only entitled to download content and print out content if there is a possibility  to  download  or  print  it  on  the  portal  as  functionality  (e.g.  by  means  of  a download button).</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 9 Blocking of Access</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE may temporarily or permanently block access to the Portal if there are concrete indications  that  customers  violate  or  have  violated  these  Terms  of  Use and/or applicable  law,  or if  MICE  has  any  other  justified  interest  in  the  blocking.  MICE  will consider the legitimate interests of the customer deciding on a blocking.</p>
        <p>(2) In the event of temporary or permanent blocking, MICE shall block the access authorization and notify the customer thereof by e-mail.</p>
        <p>(3) In case of a temporary blocking, MICE reactivates the access authorization after the  expiration  of  the  blocking  period  and  notifies  the  customer  thereof  by  e-mail. A permanently blocked access authorization cannot be restored. Permanently blocked customers are permanently excluded from participation in the Portal and may not re-register on the Portal.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 10 Data Protection</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) The  quality  standards of  MICE include  the  responsible  handling  of  the  personal data of service providers and customers (this data is hereinafter referred to as "Per-sonal Data").  Personal  Data  resulting  from  the  registration  on  the  Portal  as  well  as from the use of the available services will therefore only be processed by MICE as far as  this  is  necessary  for  the  contractual  provision  of  services  and  permitted  by  legal regulations  or  ordered  by  the  legislator.  MICE  will  treat  Personal  Data  confidentially and  in  accordance  with  the  provisions  of  the  applicable  data  protection  law  and  will not pass it on to third parties.</p>
        <p>(2) In  addition,  MICE  shall  only  use  Personal Data  if  the  person  concerned  has  ex-pressly consented to this. A given consent can be revoked at any time.</p>
        <p>(3) More detailed information on data protection can be found in the data protection declaration, which can be accessed here by clicking on the link to the data protection declaration.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 11 Liability</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) MICE shall be liable within the framework of the use of the services in accordance with the following provisions.</p>
        <p>(2) MICE  shall  have  unlimited  liability  for damages  caused  intentionally  or  through gross negligence by MICE or its legal representatives, executives or simple vicarious agents.</p>
        <p>(3) MICE shall not be liable in cases of slightly negligent violation of only insignificant contractual  obligations.  Furthermore,  the liability of  MICE for  damages  caused  by slight negligence shall be limited to those damages which must typically be expected to occur within the scope of the respective contractual relationship (typically foreseeable  damages).  This also applies to slightly negligent  breaches  of  duty  by  the legal representatives, executives or simple vicarious agents of MICE.</p>
        <p>(4) The above limitation of liability does not apply in the case of fraudulent intent, in the  case  of  bodily  injury  or  personal  injury,  forthe  violation  of  guarantees  and  for claims arising from product liability.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>§ 12 Final Provisions</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>(1) Unless expressly stated otherwise in these Terms of Use, all declarations made in connection with participation in the Portal shall be made in writing or by e-mail. The e-mail  address of  MICE  is  <a className='client-theme--color' href='mailto:info@miceportal.com'>info@miceportal.com</a>.  The postal address of  MICE is Am Bachfeld 1, 85395 Attenkirchen. Contact details are subject to change without notice. In the event of such a change, MICE will notify the customer thereof.</p>
        <p>(2) Should  any provision of  these  Terms  of Use  be or  become  invalid,  this  shall not affect the validity of the remaining provisions. In place of the invalid provision, a valid provision  shall  be  deemed  to  have  been  agreed  which  comes  closest  in  economic terms to the provision intended by the parties.</p>
        <p>(3) These  Terms  of  Use  shall  be  governed  by  the  laws  of  the  Federal  Republic  of Germany  to  the  exclusion  of  the  UN  Convention  on  Contracts  for  the  International Sales of Goods (CISG).</p>
        <p>(4) The exclusive place of jurisdiction for all disputes arising from these Terms of Use shall be the registered office of MICE.</p>
      </div>
    )
  }

  componentDidMount () {
    scrollToTop()
  }

  render () {
    return (
      <Row>
        <Col>
          {
            this.props.i18n.language === 'de'
              ? this.renderDe()
              : this.renderEn()
          }
        </Col>
      </Row>
    )
  }
}

export default withTranslation()(Terms)
