export const ADD_TO_CART = 'ADD_TO_CART'
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const FETCH_SEARCH_SESSION = 'FETCH_SEARCH_SESSION'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS'
export const CHANGE_SEARCH_BOUNDING_BOX = 'CHANGE_SEARCH_BOUNDING_BOX'
export const CHANGE_SEARCH_LOCATION = 'CHANGE_SEARCH_LOCATION'
export const CHANGE_SEARCH_QUERY = 'CHANGE_SEARCH_QUERY'
export const SAVE_RESULT_PAGE_FILTER = 'SAVE_RESULT_PAGE_FILTER'
export const OPENED_SUPPLIER_POPUP = 'OPENED_SUPPLIER_POPUP'
export const SET_MAP_VIEW = 'SET_MAP_VIEW'
export const REDIRECT_TO_SPECIFIED_URL = 'REDIRECT_TO_SPECIFIED_URL'
export const TOGGLE_STICKY_BOTTOM_BAR = 'TOGGLE_STICKY_BOTTOM_BAR'
export const SAVE_SUPPLIER = 'SAVE_SUPPLIER'
export const CHECK_LOGIN = 'CHECK_LOGIN'
export const SET_BREADCRUMB_HISTORY = 'SET_BREADCRUMB_HISTORY'
export const SET_HIGHLIGHTED_SUPPLIER = 'SET_HIGHLIGHTED_SUPPLIER'
