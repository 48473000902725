import React from 'react'
import './ConventionBureauDetails.scss'
import { Col, Row } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import SupplierImageGridGallery from '../SupplierImageGridGallery/SupplierImageGridGallery'
import SupplierTermsConditions from '../SupplierTermsConditions/SupplierTermsConditions'
import SimpleMap from '../SimpleMap/SimpleMap'
import SupplierDetailedRatingBox from '../SupplierDetailedRatingBox/SupplierDetailedRatingBox'
import ClientFeedbackCarouselBox from '../ClientFeedbackCarouselBox/ClientFeedbackCarouselBox'
import SupplierSuitableForCarouselBox from '../SupplierSuitableForCarouselBox/SupplierSuitableForCarouselBox'
import { getLogoUrl } from '../../shared/utils'
import SupplierNoteList from '../SupplierNoteList/SupplierNoteList'

class ConventionBureauDetails extends React.Component {
  constructor (props) {
    super(props)
    this.getThumbnailUrl = this.getThumbnailUrl.bind(this)
  }

  getConventionBureauInfo (key) {
    return this.props.supplier &&
    this.props.supplier.supplier_convention_bureau &&
    this.props.supplier.supplier_convention_bureau[key]
      ? this.props.supplier.supplier_convention_bureau[key]
      : null
  }

  getThumbnailUrl () {
    return getLogoUrl(this.props.hit)
  }

  render () {
    const { t } = this.props
    const detailsKey = 'supplier.home.tabs.details.'

    return (
      <>
        <div className='container'>
          <Row className='m-0 mb-3'>
            <Col className='mb-3' md={12}>
              <SupplierSuitableForCarouselBox
                title={t('suitable_for')}
                items={this.props.supplier.supplier_convention_bureau.suitable_fors}
              />
            </Col>
          </Row>
          {this.props.clientContracts && this.props.clientContracts.items && this.props.clientContracts.items.length > 0 ? (
            <Row className='pl-3 pr-3 m-0 mt-3 mb-3'>
              <SupplierTermsConditions
                startDate={this.props.startDate}
                contracts={this.props.clientContracts.items}
                title={t(`${detailsKey}terms.title`)}
              />
            </Row>
          ) : null}
          <Row className='pl-3 pr-3 m-0 mt-3 mb-3'>
            <Col md={12} className='supplier-feature-box-round' style={{ background: '#ffffff', padding: '15px' }}>
              {this.props.hit && this.props.hit._geoloc ? (
                <SimpleMap
                  center={{
                    lat: this.props.hit._geoloc.lat,
                    lng: this.props.hit._geoloc.lng
                  }}
                  zoom={14}
                  height='350px'
                  markers={[
                    {
                      lat: this.props.hit._geoloc.lat,
                      lng: this.props.hit._geoloc.lng,
                      title: this.props.hit.name || 'Hotel Leonardo Da Vinci',
                      imageUrl: this.getThumbnailUrl()
                    }
                  ]}
                />
              ) : null}
            </Col>
          </Row>
          <Row className='mt-3 mb-3 supplier-feature-box-round' style={{ marginLeft: '15px', marginRight: '15px', background: '#ffffff' }}>
            {this.props.clientFeedbacks.summary && this.props.clientFeedbacks.summary.feedback_average > 0 ? (
              <Col className='pl-0 pr-0' xl={4} lg={4} md={6} sm={12}>
                <SupplierDetailedRatingBox ratingsSummary={this.props.clientFeedbacks.summary} />
              </Col>
            ) : (
              <Col className='pl-0 pr-0' xl={4} lg={4} md={6} sm={12}>
                <div className='suplierRatingBoxWrapper'>
                  <div className='c-content-label c-font-uppercase c-font-bold c-bg-regular'>
                    {t('rating_items.rating_title')}
                  </div>
                  <div className='m-0 mt-3 mb-3'>
                    <ul className='list-unstyled supplierRatingList'>
                      <li className='supplierRatingListItem'>
                        <div className='supplierRatingListItemLabel'>{t('rating_items.execution_of_specification')}</div>
                        <div className='supplierRatingListItemProgressBar'>
                          <div className='defaultProgressBar' />
                        </div>
                      </li>
                      <li className='supplierRatingListItem'>
                        <div className='supplierRatingListItemLabel'>{t('rating_items.quality_of_service')}</div>
                        <div className='supplierRatingListItemProgressBar'>
                          <div className='defaultProgressBar' />
                        </div>
                      </li>
                      <li className='supplierRatingListItem'>
                        <div className='supplierRatingListItemLabel'>{t('rating_items.professionalism')}</div>
                        <div className='supplierRatingListItemProgressBar'>
                          <div className='defaultProgressBar' />
                        </div>
                      </li>
                      <li className='supplierRatingListItem'>
                        <div className='supplierRatingListItemLabel'>{t('rating_items.quality_of_communication')}</div>
                        <div className='supplierRatingListItemProgressBar'>
                          <div className='defaultProgressBar' />
                        </div>
                      </li>
                      <li className='supplierRatingListItem'>
                        <div className='supplierRatingListItemLabel'>{t('rating_items.price_quality_relation')}</div>
                        <div className='supplierRatingListItemProgressBar'>
                          <div className='defaultProgressBar' />
                        </div>
                      </li>
                    </ul>
                    <div className='m-0 mt-3 mb-3'>
                      <p>{t('rating_items.no_rating')}</p>
                    </div>
                  </div>
                </div>
              </Col>
            )}
            {this.props.clientFeedbacks.items && this.props.clientFeedbacks.items.length > 0 ? (
              <Col className='pl-0' xl={8} lg={8} md={6} sm={12}>
                <ClientFeedbackCarouselBox
                  title={t('supplier.home.tabs.details.feedback.title')}
                  feedbacks={this.props.clientFeedbacks.items}
                />
              </Col>
            ) : (
              <Col xl={8} lg={8} md={6} sm={12}>
                <div className='clientFeedbackWrapper'>
                  <div className='c-content-label c-font-uppercase c-font-bold c-bg-regular'>{t('supplier.home.tabs.details.feedback.title')}</div>
                  <div className='react-multi-carousel-list clientFeedbackCarouselContainer' style={{ marginTop: '25px' }}>
                    <ul className='react-multi-carousel-track' style={{ width: '100%' }}>
                      <li className='react-multi-carousel-item  clientFeedbackCarouselItem' style={{ flex: '1 1 auto' }}>
                        <div className='item'>
                          <div className='c-content-testimonial-3 c-option-light'>
                            <div className='c-content' style={{ fontSize: '1.1rem' }}>
                              {t('supplier.home.tabs.details.feedback.no_feedback_notice')}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
        {this.props.supplier && this.props.supplier.supplier_convention_bureau_general_images && this.props.supplier.supplier_convention_bureau_general_images.length > 2 ? (
          <Row className='ml-3 mr-3 mt-5'>
            <SupplierImageGridGallery images={this.props.supplier.supplier_convention_bureau_general_images} />
          </Row>
        ) : (
          <Row className='ml-3 mr-3 mt-5' />
        )}
        <SupplierNoteList
          notes={this.props.supplier.notes}
          supplierId={this.props.supplier.id}
        />
      </>
    )
  }
}
export default withTranslation()(ConventionBureauDetails)
