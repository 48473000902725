import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './SupplierCard.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import SupplierContract from '../SupplierContract/SupplierContract'
import defaultThumbnail from '../../assets/images/blank-hotel.jpg'
import { withTranslation } from 'react-i18next'
import { getSupplierURL, hasValidContracts, getDeliverableSpecificImages } from '../../shared/utils'
import { connectInsightedHit } from '../../services/algolia'
import SuppliersIndexParams from '../../shared/suppliersIndexParams'
import AddToCart from '../Cart/AddToCart'
import { extractQueryParams } from '../../services/urlStateParser'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css'
import SupplierMarkup from '../SupplierMarkup/SupplierMarkup'
import { memoize } from 'lodash'

var Carousel = require('react-responsive-carousel').Carousel

class SupplierCard extends React.Component {
  constructor (props) {
    super(props)
    this.linkRef = React.createRef()
    this.handleInsights = this.handleInsights.bind(this)
    this.memoizedGetSupplierURL = memoize(function (params, objectID, deliverables) { return getSupplierURL(params, objectID, deliverables) })
    this.handleItemClick = this.handleItemClick.bind(this)
  }

  async handleInsights () {
    if (this.props.insights) {
      if (this.props.hit.__queryID) {
        this.props.insights('clickedObjectIDsAfterSearch', { eventName: 'Supplier Clicked' })
      }
    }
  }

  handleItemClick () {
    if (this.linkRef.current) {
      this.linkRef.current.click()
    }
  }

  render () {
    const { t } = this.props
    const queryParams = extractQueryParams(this.props)
    const suppliersIndexParams = new SuppliersIndexParams(queryParams)
    const projectStartDateUnix = suppliersIndexParams.getStartDateUnix()
    const deliverableImages = getDeliverableSpecificImages(this.props.hit.thumbnails || [], queryParams.type)
    const supplierURL = this.memoizedGetSupplierURL(queryParams, this.props.hit.objectID, this.props.hit.deliverable_types)
    return (
      <div className='supplierCardWrapper' id={'supplier-card-' + this.props.hit.objectID} key={this.props.hit.objectID}>
        <div
          className='carouselContainer'
          style={{
            border: '0px solid #ccc',
            borderRadius: '0px'
          }}
        >
          <Carousel
            infiniteLoop
            showIndicators={false}
            showThumbs={false}
            showArrows
            showStatus={false}
            onClickItem={this.handleItemClick}
            swipeable={false}
            useKeyboardArrows
            renderArrowPrev={(clickHanlder) => <button tabIndex='-1' onClick={clickHanlder} type='button' aria-label={t('aria_labels.previous_slide')} className='control-arrow control-prev' />}
            renderArrowNext={(clickHanlder) => <button tabIndex='-1' onClick={clickHanlder} type='button' aria-label={t('aria_labels.next_slide')} className='control-arrow control-next' />}
          >
            {deliverableImages && deliverableImages.length > 0 ? (
              deliverableImages.map((item, idx) => (
                <div key={idx}>
                  <img
                    // TODO show thumbnail from right deliverable type
                    src={item.thumbnail ? item.thumbnail : defaultThumbnail}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src = defaultThumbnail
                    }}
                    alt=''
                  />
                </div>
              )
              )
            ) : (
              <div>
                <img src={defaultThumbnail} alt='' />
              </div>
            )}
          </Carousel>
          {hasValidContracts(this.props.hit.contracts, projectStartDateUnix) ? (
            <div className='ribbon-professional' aria-hidden='true' style={{ position: 'absolute', zIndex: '1002' }}>
              <span className='client-theme--supplier-card-ribbon'>{t('contract')}</span>
            </div>
          ) : null}
        </div>
        <SupplierMarkup containerStyle={{ paddingLeft: '10px' }} hit={this.props.hit} />
        <div className='supplierDetailContainer'>
          <div className='supplierName'>
            <Tooltip
              placement='bottomLeft'
              trigger={['hover', 'focus']}
              overlay={this.props.hit.name}
              arrowContent={<div className='rc-tooltip-arrow-inner' />}
            >
              <Link ref={this.linkRef} to={supplierURL} onClick={this.handleInsights}>
                {this.props.hit.name}
              </Link>
            </Tooltip>
          </div>
          <div className='supplierStreet'>
            <h5 className='supplierStreetHeading' style={{}}>
              <span className='supplierStreetMarker' style={{}}>
                <i className='far fa-map-marker-alt' />
              </span>
              {this.props.hit.post_code && this.props.hit.city
                ? this.props.hit.post_code + ' ' + this.props.hit.city
                : this.props.hit.street}
            </h5>
          </div>
          <div className='supplierDetails'>
            <span>{t('number_of_conference_rooms')}: <strong>{this.props.hit.capacity_number_of_conference_rooms}</strong></span>
            <span>{t('max_conference_capacity')}: <strong>{this.props.hit.capacity_max_conference_capacity}</strong></span><br />
            <span>{t('number_of_rooms')}: <strong>{this.props.hit.number_of_rooms}</strong></span>
          </div>
          <div className='supplierContractContainer'>
            <SupplierContract
              contracts={this.props.hit.contracts}
              contractsWrapperClass='pt-1 ml-0 mr-0 supplierCardContractWrapper'
              projectStartDateUnix={projectStartDateUnix}
            />
          </div>
        </div>
        <div className='actionContainer'>
          <AddToCart hit={this.props.hit} type={queryParams && queryParams.type} containerJustifyContent='center' />
        </div>
      </div>

    )
  }
}

SupplierCard.propTypes = {
  t: PropTypes.func,
  addToCart: PropTypes.func,
  removeFromCart: PropTypes.func,
  hit: PropTypes.object,
  requestedIds: PropTypes.array,
  notes: PropTypes.object
}

export default connectInsightedHit(withRouter(withTranslation()(SupplierCard)))
