import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import { Configure, Index, connectStateResults } from 'react-instantsearch-dom'
import { languageSettings } from '../../LanguageSettings.js'
import { ConnectedCarouselOfSuppliersLite } from '../ConnectedCarousel/ConnectedCarouselOfSuppliers'
import { ConnectedCarouselOfMiceMomentsLite } from '../ConnectedCarousel/ConnectedCarouselOfMiceMoments'
import queryString from 'qs'
import { extractQueryParams } from '../../services/urlStateParser'
import SuppliersIndexParams from '../../shared/suppliersIndexParams'
import MomentsIndexParams from '../../shared/momentsIndexParams'
import './TrendlineSection.scss'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'

const ResultContainer = connectStateResults(
  ({ allSearchResults, props, children }) => {
    const result = allSearchResults && allSearchResults[props.indexId]
    const hasResult = result && result.nbHits !== 0

    return !allSearchResults && !hasResult ? null : children
  })

class TrendlineSection extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      section: {}
    }
  }

  cleanFilters (filters) {
    Object.keys(filters).forEach(key => {
      filters[key] = filters[key].trim()
    })
  }

  goToResultPageWithParams (filters, trendlineMode) {
    this.cleanFilters(filters)
    const location = this.props.searchParams.location
    const lqt = this.props.searchParams.lqt
    const region = this.props.searchParams.region
    const type = this.props.searchParams.type
    const locale = this.props.searchParams.locale
    const startDate = this.props.searchParams.start_date
    const pathname = trendlineMode === 'moments' ? '/moments' : '/results'
    this.props.history.push({
      pathname: pathname,
      search: queryString.stringify({ location, lqt, region, type, locale, start_date: startDate, show_map: true, ...filters })
    })
  }

  render () {
    const { searchLocation, t, i18n, trendline, searchParams } = this.props
    const sectionName = trendline[`name_${i18n.language}`]
    const sectionDesc = trendline[`description_${i18n.language}`]
    const trendlineMode = trendline.index_name || 'suppliers'
    const indexId = sectionName && `${sectionName.replace(/\s+/g, '')}${trendlineMode}`
    const filters = queryString.parse(trendline.filters)
    const type = searchParams && searchParams.type ? searchParams.type : null
    const indexParams = trendlineMode === 'moments' ? (new MomentsIndexParams({ type, ...filters })) : (new SuppliersIndexParams({ type, ...filters }))
    const allFilters = indexParams.getAllFilters()
    const indexName = trendlineMode === 'moments' ? window.ALGOLIA_MOMENTS_INDEX_NAME : window.ALGOLIA_INDEX_NAME

    return (
      trendline && Object.keys(trendline).length > 0 ? (
        <ResultContainer indexId={indexId}>
          <div className='containerFluid trendline'>
            <Row>
              <Col xs={12}>
                <h3 className='trendline-heading'>
                  {searchLocation ? (
                    t('something_in_location', { something: sectionName, location: searchLocation.name })
                  ) : (
                    sectionName
                  )}
                </h3>
                <h4 className='trendline-description'>{sectionDesc}</h4>
              </Col>
            </Row>
            <Row style={{ marginTop: '34px' }}>
              <Col md={12}>
                <div className='supplierPreviewCarouselContainer'>
                  <Index
                    indexId={indexId}
                    indexName={indexName}
                  >
                    <Configure
                      queryLanguages={languageSettings.languageList}
                      filters={allFilters}
                      page={0}
                      hitsPerPage={25}
                      aroundRadius={window.DEFAULT_AROUND_RADIUS}
                      aroundLatLng={
                        searchLocation
                          ? `${searchLocation.lat}, ${searchLocation.lng}`
                          : ''
                      }
                    />
                    {trendlineMode === 'moments' ? <ConnectedCarouselOfMiceMomentsLite /> : <ConnectedCarouselOfSuppliersLite />}
                  </Index>
                </div>
              </Col>
              <Col xs={12} className='pl-0 pr-0 d-flex justify-content-center'>
                <div className='show-all-button-container'>
                  <ButtonWithIcon
                    labelColor='#FFFFFF'
                    padding='6px 32px'
                    label={t('show_all')}
                    className='client-theme--bg text-white'
                    onClick={() => this.goToResultPageWithParams(filters, trendlineMode)}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </ResultContainer>
      ) : null
    )
  }
}

TrendlineSection.propTypes = {
  searchParams: PropTypes.object,
  searchLocation: PropTypes.object,
  t: PropTypes.func,
  i18n: PropTypes.object,
  history: PropTypes.object,
  trendline: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
  return {
    searchLocation: state.app.searchLocation,
    searchParams: extractQueryParams(ownProps)
  }
}

export default withRouter(
  connect(mapStateToProps)(withTranslation()(TrendlineSection))
)
