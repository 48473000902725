import React from 'react'
import './Checkbox.scss'

export default function Checkbox ({ value, label, isChecked, onChange, name }) {
  return (
    <label className='custom-checkbox'>
      <input
        type='checkbox'
        name={name}
        value={value}
        checked={isChecked}
        onChange={onChange}
      />
      {label}
    </label>
  )
}
