import React from 'react'
import { AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import { Row, Col, Card, CardBody } from 'reactstrap'
import HashtagsSelect from '../../../HashtagsSelect/HashtagsSelect'
import Can from '../../../Layout/Can'
import { TextInput } from '../../../FormElements/FormElements'
import TextInputGroup from '../../../TextInputGroup/TextInputGroup'
import Checkbox from '../../../Checkbox/Checkbox'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { getSelectedOptions } from '../../../../shared/utils'
import './EventGastronomyGeneral.scss'

class EventGastronomyGeneral extends React.Component {
  days () {
    return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  }

  render () {
    const { t, i18n } = this.props

    return (
      this.props.supplier && this.props.supplier.supplier_event_gastronomy ? (
        <div>
          <Row className='m-0 mt-3 mb-3'>
            <Col className='p-0' xs={12}>
              <Card>
                <CardBody>
                  <AccordionItem>
                    <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                      <AccordionItemButton>
                        <h5 className='u-position-relative'>
                          {t('supplier.edit.general.facts_and_figures')}
                          <div className='accordion__arrow' role='presentation' />
                        </h5>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row className='mt-3 mb-3'>
                        <Col xs={12}>
                          <TextInput
                            label='Max. Seating Capacity'
                            name='capacity'
                            value={this.props.supplier.supplier_event_gastronomy.capacity}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-3'>
                        <Col xs={12}>
                          <label>{t('supplier.edit.general.suitable_for')}</label>
                          <Select
                            name='suitable_fors'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={this.props.supplier.supplier_event_gastronomy.suitable_fors}
                            getOptionValue={(option) => option.attribute_name}
                            getOptionLabel={option => option[`name_${i18n.language}`]}
                            placeholder={t('select_all_that_apply')}
                            value={getSelectedOptions(this.props.supplier.supplier_event_gastronomy.suitable_fors)}
                            isSearchable
                            isClearable
                            isMulti
                            onChange={(values) => this.props.onMultipleSelectChange('suitable_fors', values, true)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-3'>
                        <Col xs={12}>
                          <label>{t('supplier.edit.general.characteristics')}</label>
                          <Select
                            name='characteristics'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={this.props.supplier.supplier_event_gastronomy.characteristics}
                            getOptionValue={(option) => option.attribute_name}
                            getOptionLabel={option => option[`name_${i18n.language}`]}
                            placeholder={t('your_selection')}
                            value={getSelectedOptions(this.props.supplier.supplier_event_gastronomy.characteristics)}
                            isSearchable
                            isClearable
                            isMulti
                            onChange={(values) => this.props.onMultipleSelectChange('characteristics', values, true)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-3'>
                        <Col xs={12}>
                          <label htmlFor='eventGastronomyCuisine'>{t('supplier.edit.general.cuisine')}</label>
                          <Select
                            id='eventGastronomyCuisine'
                            name='cuisine'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={this.props.supplier.supplier_event_gastronomy.cuisine}
                            getOptionValue={(option) => option.attribute_name}
                            getOptionLabel={option => option[`name_${i18n.language}`]}
                            placeholder={t('select_all_that_apply')}
                            value={getSelectedOptions(this.props.supplier.supplier_event_gastronomy.cuisine)}
                            isSearchable
                            isClearable
                            isMulti
                            onChange={(values) => this.props.onMultipleSelectChange('cuisine', values, true)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-3'>
                        <Col xs={12}>
                          <label>{t('payment_options')}</label>
                          <Select
                            name='payment_info'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={this.props.supplier.payment_info.payment_options}
                            getOptionValue={(option) => option.attribute_name}
                            getOptionLabel={option => option[`name_${i18n.language}`]}
                            placeholder={t('select_all_that_apply')}
                            value={getSelectedOptions(this.props.supplier.payment_info.payment_options)}
                            isSearchable
                            isMulti
                            onChange={(values) => this.props.onPaymentSelectChange(values)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                      </Row>

                      <Can perform='manage' on='all'>
                        <Row className='mt-3 mb-3'>
                          <Col xs={12}>
                            <label>{t('hashtags')}</label>
                            <HashtagsSelect
                              name='hashtags'
                              value={this.props.supplier.supplier_event_gastronomy.hashtags}
                              onChange={this.props.onDeliverableChange}
                              className='defaultSelectDropdown'
                              classNamePrefix='defaultSelectDropdown'
                            />
                          </Col>
                        </Row>
                      </Can>
                    </AccordionItemPanel>
                  </AccordionItem>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className='m-0 mt-3 mb-3'>
            <Col className='p-0' xs={12}>
              <Card>
                <CardBody>
                  <AccordionItem>
                    <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                      <AccordionItemButton>
                        <h5 className='u-position-relative'>
                          {t('supplier.edit.general.opening_hours')}
                          <div className='accordion__arrow' role='presentation' />
                        </h5>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row className='event-gastronomy-days-header'>
                        <Col md={2} />
                        <Col md={2}>{t('supplier.edit.general.opening_hours_from')}</Col>
                        <Col md={2}>{t('supplier.edit.general.opening_hours_to')}</Col>
                        <Col md={2} />
                        <Col md={2}>{t('supplier.edit.general.opening_hours_from')}</Col>
                        <Col md={2}>{t('supplier.edit.general.opening_hours_to')}</Col>
                      </Row>

                      {this.days().map(day =>
                        <Row key={day} className='event-gastronomy-day'>
                          <Col md={2}>{day}</Col>
                          <Col md={2}>
                            <TextInput
                              name={`${day}_from`}
                              value={this.props.supplier.supplier_event_gastronomy[`${day}_from`]}
                              onChange={this.props.onDeliverableChange}
                            />
                          </Col>
                          <Col md={2}>
                            <TextInput
                              name={`${day}_until`}
                              value={this.props.supplier.supplier_event_gastronomy[`${day}_until`]}
                              onChange={this.props.onDeliverableChange}
                            />
                          </Col>
                          <Col md={2}>and</Col>
                          <Col md={2}>
                            <TextInput
                              name={`${day}_from`}
                              value={this.props.supplier.supplier_event_gastronomy[`${day}_from2`]}
                              onChange={this.props.onDeliverableChange}
                            />
                          </Col>
                          <Col md={2}>
                            <TextInput
                              name={`${day}_until2`}
                              value={this.props.supplier.supplier_event_gastronomy[`${day}_until2`]}
                              onChange={this.props.onDeliverableChange}
                            />
                          </Col>
                        </Row>
                      )}
                    </AccordionItemPanel>
                  </AccordionItem>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className='m-0 mt-3 mb-3'>
            <Col className='p-0' xs={12}>
              <Card>
                <CardBody>
                  <AccordionItem>
                    <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                      <AccordionItemButton>
                        <h5 className='u-position-relative'>
                          {t('supplier.edit.general.distances.title')}
                          <div className='accordion__arrow' role='presentation' />
                        </h5>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row className='mt-1 mb-1'>
                        <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                          <label>{t('supplier.edit.general.distances.airport')}</label>
                          <TextInputGroup
                            name='distance_airport'
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='km'
                            value={this.props.supplier.supplier_event_gastronomy.distance_airport}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                          <label>{t('supplier.edit.general.distances.city_center')}</label>
                          <TextInputGroup
                            name='distance_city'
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='km'
                            value={this.props.supplier.supplier_event_gastronomy.distance_city}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                          <label>{t('supplier.edit.general.distances.main_road')}</label>
                          <TextInputGroup
                            name='distance_main_road'
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='km'
                            value={this.props.supplier.supplier_event_gastronomy.distance_main_road}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                          <label>{t('supplier.edit.general.distances.train_station')}</label>
                          <TextInputGroup
                            name='distance_station'
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='km'
                            value={this.props.supplier.supplier_event_gastronomy.distance_station}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                          <label>{t('supplier.edit.general.distances.public_transport')}</label>
                          <TextInputGroup
                            name='distance_public_transport'
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='km'
                            value={this.props.supplier.supplier_event_gastronomy.distance_public_transport}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                          <label>{t('supplier.edit.general.distances.local_station')}</label>
                          <TextInputGroup
                            name='distance_local_station'
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='km'
                            value={this.props.supplier.supplier_event_gastronomy.distance_local_station}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                          <label>{t('supplier.edit.general.distances.convention')}</label>
                          <TextInputGroup
                            name='distance_convention'
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='km'
                            value={this.props.supplier.supplier_event_gastronomy.distance_convention}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                      </Row>
                    </AccordionItemPanel>
                  </AccordionItem>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className='m-0 mt-3 mb-3'>
            <Col className='p-0' xs={12}>
              <Card>
                <CardBody>
                  <AccordionItem>
                    <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                      <AccordionItemButton>
                        <h5 className='u-position-relative'>
                          {t('supplier.edit.general.parking')}
                          <div className='accordion__arrow' role='presentation' />
                        </h5>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row className='mt-1 mb-1'>
                        <Col className='mt-2 mb-2' md={4} sm={6} xs={12}>
                          <TextInput
                            name='parking_spaces'
                            className='borderPrimary'
                            label={`${t('supplier.edit.general.max_parking')} *`}
                            placeholder='0'
                            value={this.props.supplier.supplier_event_gastronomy.parking_spaces}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={4} sm={6} xs={12}>
                          <TextInput
                            name='parking_spaces_outside'
                            className='borderPrimary'
                            label={`${t('supplier.edit.general.outside_parking')} *`}
                            placeholder='0'
                            value={this.props.supplier.supplier_event_gastronomy.parking_spaces_outside}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={4} sm={6} xs={12}>
                          <TextInput
                            name='parking_spaces_garage'
                            className='borderPrimary'
                            label={`${t('supplier.edit.general.inside_parking')} *`}
                            placeholder='0'
                            value={this.props.supplier.supplier_event_gastronomy.parking_spaces_garage}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={4} sm={6} xs={12}>
                          <TextInput
                            name='parking_spaces_count'
                            className='borderPrimary'
                            label={`${t('supplier.edit.general.parking_spaces_count')} *`}
                            placeholder='0'
                            value={this.props.supplier.supplier_event_gastronomy.parking_spaces_count}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-3'>
                        <Col md={3} sm={6} xs={12}>
                          <Checkbox
                            name='private_parking'
                            checked={this.props.supplier.supplier_event_gastronomy.private_parking}
                            onChange={this.props.onDeliverableChange}
                            label={t('supplier.edit.general.private_parking')}
                          />
                        </Col>
                        <Col md={3} sm={6} xs={12}>
                          <Checkbox
                            name='parking_public'
                            checked={this.props.supplier.supplier_event_gastronomy.parking_public}
                            onChange={this.props.onDeliverableChange}
                            label={t('supplier.edit.general.public_parking')}
                          />
                        </Col>
                        <Col md={3} sm={6} xs={12}>
                          <Checkbox
                            name='parking_free'
                            checked={this.props.supplier.supplier_event_gastronomy.parking_free}
                            onChange={this.props.onDeliverableChange}
                            label={t('supplier.edit.general.free_parking')}
                          />
                        </Col>
                        <Col md={3} sm={6} xs={12}>
                          <Checkbox
                            name='shuttle_service'
                            checked={this.props.supplier.supplier_event_gastronomy.shuttle_service}
                            onChange={this.props.onDeliverableChange}
                            label={t('supplier.edit.general.shuttle_service')}
                          />
                        </Col>
                      </Row>
                    </AccordionItemPanel>
                  </AccordionItem>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : null
    )
  }
}

EventGastronomyGeneral.propTypes = {
  supplier: PropTypes.object,
  onChange: PropTypes.func,
  onDeliverableChange: PropTypes.func,
  onPaymentInfoChange: PropTypes.func
}

export default withTranslation()(EventGastronomyGeneral)
