import React, { Component } from 'react'
import './SupplierDetailedRatingBox.scss'
import { Progress, Row } from 'reactstrap'
import SupplierCardFeedback from '../SupplierCardFeedback/SupplierCardFeedback'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

class SupplierDetailedRatingBox extends Component {
  ratingItems () {
    return (Object.entries(this.props.ratingsSummary.feedback_percentage_per_item)
      .filter(ratingItem => ratingItem && ratingItem[1])
      .map((ratingItem) => {
        return {
          key: ratingItem[0],
          label: this.props.t(`rating_items.${ratingItem[0]}`),
          value: ratingItem[1]
        }
      }))
  }

  render () {
    const { t, ratingsSummary } = this.props
    if (!ratingsSummary || ratingsSummary.feedback_amount === 0) return null

    return (
      <div className='suplierRatingBoxWrapper'>
        <Row className='m-0'>
          <SupplierCardFeedback containerStyle={{ position: 'relative', padding: '15px' }} feedback={ratingsSummary.feedback_average} />
        </Row>
        <Row className='m-0 mt-3 mb-3'>
          <ul className='list-unstyled supplierRatingList'>
            {this.ratingItems().map(({ key, label, value }) => (
              <li className='supplierRatingListItem' key={key}>
                <div className='supplierRatingListItemLabel'>{label}</div>
                <div className='supplierRatingListItemProgressBar'>
                  <Progress barClassName='defaultProgressBarColor' className='defaultProgressBar' value={value}>
                    {`${value.toFixed(0)}%`}
                  </Progress>
                </div>
              </li>
            ))}
          </ul>
          <Row className='m-0 mt-3 mb-3'>
            <p>{t('overall_rating_based_on_verified_user_reviews', { count: ratingsSummary.feedback_amount })}</p>
          </Row>
        </Row>
      </div>
    )
  }
}

SupplierDetailedRatingBox.propTypes = {
  ratingsSummary: PropTypes.object.isRequired
}

export default withTranslation()(SupplierDetailedRatingBox)
