import React from 'react'
import './SupplierPolicy.scss'
import { withTranslation } from 'react-i18next'

class SupplierPolicy extends React.Component {
  render () {
    const { i18n, t } = this.props

    return (
      <>
        <div className='supplierPolicyWrapper'>
          <h3>{this.props.heading ? this.props.heading : 'Cancellation terms (according to the master contract)'}</h3>
          {this.props.policy}
        </div>
        <div className='supplierPolicyWrapper'>
          {window.SHOP_TERMS_AND_CONDITIONS && window.SHOP_TERMS_AND_CONDITIONS[i18n.language] && window.SHOP_TERMS_AND_CONDITIONS[i18n.language].length > 0 ? (
            <>
              <h3>{t('terms_and_conditions')}</h3>
              {window.SHOP_TERMS_AND_CONDITIONS[i18n.language].map((tnc, idx) => (
                <span key={idx} className='terms-and-conditions' style={{ paddingRight: '6px' }}>
                  <a key={idx} href={tnc[1]} rel='noopener noreferrer' target='_blank'>
                    {tnc[0]}
                  </a>
                </span>
              )
              )}
            </>
          ) : null}
        </div>
      </>
    )
  }
}
export default withTranslation()(SupplierPolicy)
