import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { withTranslation } from 'react-i18next'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import { isValidTenderType, hasValidContracts, logAdvertisementEvent } from '../../shared/utils'
import SuppliersIndexParams from '../../shared/suppliersIndexParams'
import { extractQueryParams } from '../../services/urlStateParser'
import { connectInsightedHit } from '../../services/algolia'
import SupplierNote from '../SupplierNote/SupplierNote'
import { AddSupplierModal } from '../Modals/Modals'
import Select from 'react-select'
import './AddToCart.scss'
import { startCase } from 'lodash'
import { addToCart, removeFromCart } from '../../redux/actions'

const _ = require('lodash')

class AddToCart extends Component {
  constructor (props) {
    super(props)

    this.state = {
      supplierModal: false,
      selected_deliverable_types: this.props.hit.selected_deliverable_types || []
    }

    this.handleClickAddToCart = this.handleClickAddToCart.bind(this)
    this.handleClickRemoveFromCart = this.handleClickRemoveFromCart.bind(this)
    this.renderButton = this.renderButton.bind(this)
    this.handleSupplierModalClosure = this.handleSupplierModalClosure.bind(this)
    this.addToCart = this.addToCart.bind(this)
    this.handleSupplierValidation = this.handleSupplierValidation.bind(this)
    this.handleSelectedDeliverableTypesUpdate = this.handleSelectedDeliverableTypesUpdate.bind(this)
  }

  handleSupplierValidation () {
    let isValid = true

    if (this.props.searchSession) {
      if (this.props.searchSession.only_contracted) {
        isValid = this.checkForValidContracts()
      }

      if (isValid && this.props.searchSession.allow_specific_supplier_types) {
        isValid = this.checkForShopAllowedSupplierTypes()
      }

      if (isValid && this.props.searchSession.service_type_name) {
        isValid = this.checkForTenderAllowedSupplierTypes()
      }
    }

    if (isValid && this.props.searchParams && this.props.searchParams.type) {
      isValid = this.checkForValidTenderType()
    }

    if (isValid) {
      isValid = this.checkForBlacklistStatus()
    }

    if (isValid) {
      this.addToCart()
      if (this.props.insights && this.props.hit.__queryID) {
        this.props.insights('convertedObjectIDsAfterSearch', { eventName: 'Supplier Added to Cart' })
      }
    }
  }

  addToCart () {
    this.props.hit.selected_deliverable_types = this.state.selected_deliverable_types
    this.props.addToCart(this.props.hit)

    if (this.state.supplierModal) {
      this.handleSupplierModalClosure()
    }

    this.setState({ selected_deliverable_types: [] })

    if (this.props.forAdvertisement) {
      logAdvertisementEvent(
        'city_ad_added_to_cart',
        this.props.advertisementID,
        this.props.hit.objectID,
        { projectNumber: this.props.searchSession.project_number, seriesBookingID: this.props.searchSession.series_booking_id }
      )
    }
  }

  isInProject () {
    const requestedSuppliers = (this.props.searchSession && this.props.searchSession.requested_suppliers) || []
    return requestedSuppliers.includes(this.props.hit.objectID)
  }

  handleSelectedDeliverableTypesUpdate (values) {
    this.setState({ selected_deliverable_types: (values || []) })
  }

  checkForValidContracts () {
    const suppliersIndexParams = new SuppliersIndexParams(this.props.searchParams)
    const projectStartDateUnix = suppliersIndexParams.getStartDateUnix()

    if (hasValidContracts(this.props.hit.contracts, projectStartDateUnix)) {
      return true
    }

    toast(this.props.t('only_contracted_supplier_can_be_added'))
    return false
  }

  checkForShopAllowedSupplierTypes () {
    const { searchSession, hit, type, t } = this.props
    const allowedSupplierTypes = searchSession.allow_specific_supplier_types
    const isHotel = (!type && hit.deliverable_types.includes('SupplierHotel')) || type === 'hotel'

    const result = isHotel || hit.deliverable_types.every(function (n) {
      return allowedSupplierTypes.includes(n.replace('Supplier', '').toLowerCase())
    })

    if (!result) {
      toast(t('supplier_cannot_be_added'))
    }

    return result
  }

  checkForTenderAllowedSupplierTypes () {
    const { searchSession, hit } = this.props
    const serviceTypeName = `Supplier${startCase(searchSession.service_type_name).replace(' ', '')}`
    let isValid = true

    if (['SupplierHotel', 'SupplierLocation'].includes(serviceTypeName)) {
      const selectedDeliverableTypes = this.state.selected_deliverable_types.length > 0 ? this.state.selected_deliverable_types : hit.deliverable_types
      isValid = _.difference(selectedDeliverableTypes, ['SupplierHotel', 'SupplierLocation']).length === 0
    } else if (this.state.selected_deliverable_types.length > 0) {
      isValid = JSON.stringify(this.state.selected_deliverable_types) === JSON.stringify([serviceTypeName])
    } else if (hit.deliverable_types.length === 1) {
      isValid = JSON.stringify(hit.deliverable_types) === JSON.stringify([serviceTypeName])
    }

    if (!isValid) {
      toast(this.props.t('supplier_cannot_be_added'))
    }

    return isValid
  }

  checkForValidTenderType () {
    if (isValidTenderType(this.props.hit.deliverable_types, this.props.searchParams.type)) {
      return true
    }

    toast(this.props.t('supplier_cannot_be_added'))
    return false
  }

  isBlacklisted () {
    return !!this.blacklistedNote()
  }

  blacklistedNote () {
    if (!this.props.hit || !this.props.hit.notes) {
      return null
    }

    const notes = this.props.hit.notes[window.CURRENT_SUBDOMAIN] || this.props.hit.notes.general

    if (!notes) {
      return null
    }

    return notes.find(note => note.blacklist)
  }

  checkForBlacklistStatus () {
    if (!this.isBlacklisted()) {
      return true
    }

    const notice = this.props.t('supplier_cannot_be_added')
    const reason = this.blacklistedNote()[`comment_${this.props.i18n.language}`]
    toast(`${notice}\n\r${reason}`)
    return false
  }

  isInCart () {
    return this.props.cartItems && this.props.cartItems.some(item => item.objectID === this.props.hit.objectID)
  }

  isDisabled () {
    return (this.isInCart() || this.isInProject() || this.isBlacklisted())
  }

  getLabel () {
    const { t } = this.props

    if (this.isInCart()) {
      return '1'
    } else if (this.isInProject()) {
      return t('requested')
    } else {
      return t('add_to_wallet')
    }
  }

  handleClickAddToCart () {
    if (this.isDisabled()) {
      if (this.isBlacklisted()) {
        toast(this.props.t('supplier_blacklisted_response'), { draggable: false })
      } else if (this.isInCart()) {
        this.props.history.push('/cart')
      } else {
        toast(this.props.t('supplier_cannot_be_added'))
      }
    } else if (this.props.hit.deliverable_types && this.props.hit.deliverable_types.length > 1) {
      this.setState({ supplierModal: true })
    } else {
      this.handleSupplierValidation()
    }
  }

  handleSupplierModalClosure () {
    this.setState({ supplierModal: false, selected_deliverable_types: [] })
  }

  handleClickRemoveFromCart () {
    this.setState({ selected_deliverable_types: [] })
    this.props.hit.selected_deliverable_types = []
    this.props.removeFromCart(this.props.hit)
  }

  renderForAdvertisement () {
    if (this.isInCart()) {
      return (
        <button
          style={{ position: 'relative', bottom: '0px', borderColor: 'black' }}
          className='defaultButton'
          onClick={this.handleClickRemoveFromCart}
        >
          <i className='fa fa-trash' />
        </button>
      )
    }

    return (
      <button
        onClick={this.handleClickAddToCart}
        disabled={this.isDisabled()}
        className='defaultButton client-theme--bg client-theme--font-color '
        style={{ bottom: '0px' }}
      >
        <i className='fa fa-shopping-bag c-cart-icon' />
        &nbsp;{this.props.searchSession
          ? this.props.t('add')
          : this.props.t('create_meeting')}
      </button>
    )
  }

  renderButton () {
    const label = this.getLabel()
    return (
      <ButtonWithIcon
        id='gtm-addSupplier-supplierCard'
        labelColor='#FFFFFF'
        iconColor='#FFFFFF'
        padding={this.props.addButtonPadding || '6px 8px'}
        icon={`fa ${this.isInProject() ? 'fa-check' : 'fa-shopping-bag'}`}
        label={this.getLabel()}
        className={`client-theme--bg ${this.isInProject() || this.isBlacklisted() ? 'add-to-cart-btn-disabled' : ''}`}
        onClick={this.handleClickAddToCart}
        ariaLabel={`${label === '1' ? this.props.t('already_in_cart') : label} - ${this.props.hit.name || ''}`}
      />
    )
  }

  render () {
    const { t } = this.props

    return (
      this.props.forAdvertisement
        ? this.renderForAdvertisement()
        : (
          <>
            <ul
              style={{
                display: 'flex',
                justifyContent: this.props.containerJustifyContent || 'normal',
                alignItems: 'center',
                margin: '0px',
                width: '100%'
              }}
              className='list-unstyled'
            >
              <li
                style={{
                  width: this.isInCart() ? '30%' : (this.props.notInCartButtonWidth || '65%'),
                  paddingRight: this.isInCart() ? '10px' : '0px'
                }}
              >
                {this.isBlacklisted()
                  ? (
                    <SupplierNote note={this.blacklistedNote()}>
                      {this.renderButton()}
                    </SupplierNote>
                  )
                  : this.renderButton()}
              </li>
              {this.isInCart()
                ? (
                  <li style={{ width: '20%' }}>
                    <ButtonIcon
                      iconStyle={{
                        padding: '4px',
                        fontSize: '12px',
                        color: '#FFF',
                        ...this.props.deleteIconStyle
                      }}
                      className='client-theme--color'
                      icon='fal fa-trash-alt'
                      label=''
                      onClick={this.handleClickRemoveFromCart}
                    />
                  </li>
                )
                : null}
            </ul>
            <AddSupplierModal
              isOpened={this.state.supplierModal || false}
              title={this.props.hit.name}
              onConfirm={this.handleSupplierValidation}
              onCancel={this.handleSupplierModalClosure}
              isDisabled={this.state.selected_deliverable_types.length === 0}
            >
              <label htmlFor='deliverableTypes'>{t('choose_supplier_service_type')}</label>
              <Select
                id='deliverableTypes'
                name='categories'
                className='defaultSelectDropdown'
                classNamePrefix='defaultSelectDropdown'
                options={this.props.hit.deliverable_types}
                getOptionValue={option => option}
                getOptionLabel={option => t(`categories.${option.toLowerCase()}`)}
                placeholder={t('select_all_that_apply')}
                value={this.state.selected_deliverable_types}
                isSearchable
                isClearable
                isMulti
                onChange={this.handleSelectedDeliverableTypesUpdate}
                style={{ margin: '6px 0' }}
              />
            </AddSupplierModal>
          </>
        )
    )
  }
}

AddToCart.propTypes = {
  addButtonPadding: PropTypes.string,
  deleteIconStyle: PropTypes.object,
  notInCartButtonWidth: PropTypes.string,
  containerJustifyContent: PropTypes.string,
  hit: PropTypes.object.isRequired,
  forAdvertisement: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {
  return {
    cartItems: state.app.cartItems,
    searchSession: state.app.searchSession,
    searchParams: extractQueryParams(ownProps)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addToCart: payload => dispatch(addToCart(payload)),
    removeFromCart: payload => dispatch(removeFromCart(payload))
  }
}

export default withRouter(
  connectInsightedHit(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddToCart)))
)
