import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

function TextPreview ({
  text,
  href,
  previewLength = 100,
  minimal = false,
  style,
  t
}) {
  const [showFullText, setShowFullText] = useState(false)
  const condensedText = text && text.substring(0, previewLength)
  const isTextCondensable = text && text.length > previewLength
  const suffix = isTextCondensable ? '...' : ''
  return (
    <>
      {text && text !== '' ? (
        minimal ? (
          <>
            {showFullText ? text : condensedText + suffix}
            {!href && isTextCondensable ? (
              <span className='seeMore client-theme--color' onClick={() => setShowFullText(!showFullText)}>
                {showFullText ? t('see_less') : t('see_more')}
              </span>
            ) : isTextCondensable ? (
              <Link className='seeMore client-theme--color' to={href}>{t('see_more')}</Link>
            ) : null}
          </>
        ) : (
          <div style={styles.textContainerStyle}>
            <div style={styles.text}>
              {showFullText ? text : condensedText + suffix}
              {!href && isTextCondensable ? (
                <div className=' client-theme--color' style={styles.seeMore} onClick={() => setShowFullText(!showFullText)}>
                  {showFullText ? t('see_less') : t('see_more')}
                </div>
              ) : isTextCondensable ? (
                <Link to={href}>
                  <div className=' client-theme--color' style={styles.seeMore}>
                    {t('see_more')}
                  </div>
                </Link>
              ) : null}
            </div>
          </div>
        )
      ) : null}
    </>
  )
}

const styles = {
  textContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  text: {
    fontSize: '12px'
  },
  seeMore: {
    fontSize: '12px',
    paddingLeft: '5px',
    cursor: 'pointer',
    display: 'inline-block',
    textDecoration: 'underline'
  }
}

export default withTranslation()(TextPreview)
