import IndexParams from './IndexParams'

export const momentFacets = {
  type: 'deliverable_types',
  category: 'category',
  sustainable: 'sustainable',
  hashtag: 'hashtag'
}

class MomentsIndexParams extends IndexParams {
  get index () {
    return window.ALGOLIA_MOMENTS_INDEX_NAME
  }

  ignoredSuppliersFilterKeys () {
    return this.searchParams.facet_only ? Object.keys(momentFacets) : []
  }

  /* Returns a long (possibly) string with various search
   * criteria joined with " AND ".
   * That string is supposed to be consumed as a valid Algolia query.
   * */
  getAllFilters () {
    /* all these functions willbe called with: (checkFun, allParams)
     * The may call `checkFun(key)` to easily get the value only if it passes a test of isValueValid()
     * */
    const ignoredKeys = this.ignoredSuppliersFilterKeys()
    return this.getAllFiltersUsing(
      this.getContractFilter,
      this.getMatchingSuppliersFilterWithout(ignoredKeys),
      this.getHashtagFilter,
      this.getAreaOfOperationFilter,
      (check) => { return check('algolia') },
      (check) => { return check('moments') }
    )
  }

  getAllowedFacets (key = null, value = null) {
    if (value) {
      switch (key) {
        case 'category':
        case 'suitable':
        case 'chain_name':
        case 'airplus':
        case 'hashtags':
        case 'hashtag':
        case 'sustainable':
        case 'hashtags.de':
        case 'certificates':
        case 'type':
        case 'capacity_event_floor_space':
        case 'capacity_max_square_meter':
        case 'conference_rooms':
        case 'capacity_number_of_conference_rooms':
        case 'number_of_rooms':
        case 'feedback_average':
        case 'micepoints':
        case 'dehoga':
        case 'query':
          return { key, value }
        default:
          return false
      }
    } else {
      return false
    }
  }
}

export default MomentsIndexParams
