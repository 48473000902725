import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import './GetListed.scss'
import { scrollToTop, addScriptTag } from '../../shared/utils'

class GetListed extends Component {
  constructor (props) {
    super(props)

    this.state = {
      hubspotFrame: null
    }
  }

  componentDidMount () {
    scrollToTop()

    if (window.KONVENIT_ENVIRONMENT !== 'test') {
      if (!window.hbspt) {
        this.loadHubspotScript()
      } else {
        this.createHubSpotForm()
      }
    }
  }

  createHubSpotForm () {
    window.hbspt && this.element && window.hbspt.forms.create({
      portalId: '1789719',
      formId: 'af703055-19e2-46b5-8e3c-445ea3afe120',
      target: `#${this.element.getAttribute('id')}`
    })
  }

  loadHubspotScript () {
    addScriptTag({
      src: '//js.hsforms.net/forms/v2.js',
      defer: true,
      onload: () => {
        this.createHubSpotForm()
      }
    })
  }

  render () {
    const { t } = this.props
    return (
      <div className='c-layout-page' id='pageid'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 c-get-listed'>
              <div className='c-content-title-1 text-center'>
                <h3>
                  {t('become_part_of_miceportal')}
                </h3>
                <div className='c-line-center c-theme-bg client-theme--bg' />
              </div>
              <div id='hubspotForm' ref={el => (this.element = el)} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(GetListed)
