import React from 'react'
import PropTypes from 'prop-types'
import './SupplierEquipmentBox.scss'
import Tooltip from 'rc-tooltip'

class SupplierEquipmentBox extends React.Component {
  // This component expects these properties to be present:
  //
  // - title (string),
  // - locale (string, e.g. 'de'; used to fetch the right names from the equipment)
  // - equipment (an array of category objects, each containing also an array of equipment objects)
  //
  // See the test of this component for a basic example of this equipment structure.
  // It normally comes from the backend.

  render () {
    const locale = this.props.locale
    return (
      <div
        className='c-content-media-1 c-overflow-hide supplier-feature-box-round'
      >
        <div className='c-content-label c-font-uppercase c-font-bold c-bg-regular'>
          {this.props.title}
        </div>
        <ul className='supplierEquipmentList'>
          {this.props.equipment.map((category) => (
            <li key={category.key} className='supplierEquipmentListItem'>
              <span className='supplierEquipmentListItemLabel'>
                {category[`name_${locale}`]}
              </span>
              <span className='supplierEquipmentListItemValue'>
                {category.equipment.map((item) =>
                  <Tooltip
                    placement='bottom'
                    trigger={['hover', 'focus']}
                    overlay={item[`name_${locale}`]}
                    arrowContent={<div className='rc-tooltip-arrow-inner' />}
                    key={item.key}
                  >
                    <div
                      className={(`supplierEquipmentListImageWrapper ${category.key}`)}
                      style={{ backgroundImage: 'url(' + item.image_url + ')' }}
                    />
                  </Tooltip>
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

SupplierEquipmentBox.propTypes = {
  title: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  equipment: PropTypes.array.isRequired
}

export default SupplierEquipmentBox
