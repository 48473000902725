import React from 'react'
import './MiceMomentsTabContent.scss'
import { Row, Col } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import comingSoonBanner from '../../assets/images/coming_soon.png'
import Heading from '../Heading/Heading'
import MiceMomentCard from '../MiceMoments/MiceMomentCard'
var dayjs = require('dayjs')
function MiceMomentsTabContent ({ supplier, deliverable, t }) {
  if (deliverable && deliverable.mice_moments && deliverable.mice_moments.length > 0) {
    const currentTimestamp = dayjs().unix()
    const validMiceMoments = deliverable.mice_moments.filter((miceMoment) => {
      if (!miceMoment.active) {
        return false
      }
      if (miceMoment.start_date) {
        const startDateTimestamp = dayjs(miceMoment.start_date, 'YYYY-MM-DD', true).unix()
        const endDateTimestamp = miceMoment.end_date ? dayjs(miceMoment.end_date, 'YYYY-MM-DD', true).unix() : 2147483647
        return (currentTimestamp > startDateTimestamp && currentTimestamp < endDateTimestamp)
      }
      return true
    })
    return (
      <div className='container'>
        <Row>
          {validMiceMoments.map((miceMoment) => (
            <Col key={miceMoment.id} xs={12} md={6} xl={4} className='mb-3'>
              <MiceMomentCard
                value={miceMoment}
                supplier={supplier}
                hideButtons
              />
            </Col>
          ))}
        </Row>
      </div>
    )
  } else {
    return (
      <div className='container'>
        <Heading>{t('coming_soon')}</Heading>
        <p className='mt-4 mb-4 lead'>{t('coming_soon_info')}</p>
        <Row className='m-0 mt-3 mb-3 justify-content-center'>
          <img className='' src={comingSoonBanner} alt='' />
        </Row>
      </div>
    )
  }
}
export default withTranslation()(MiceMomentsTabContent)
