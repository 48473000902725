import React, { Fragment } from 'react'
import './SupplierCardList.scss'
import { Row } from 'reactstrap'
import SupplierCard from '../SupplierCard/SupplierCard'
import NoResultsNote from './../Results/NoResultsNote'
import { connect } from 'react-redux'
import { Configure, Pagination, connectHits } from 'react-instantsearch-dom'
import { extractQueryParams } from '../../services/urlStateParser'
import { withRouter } from 'react-router-dom'
import { hasAreaOfOperation } from '../../shared/utils'
import BannerAdDisplay from '../BannerAd/BannerAdDisplay'
import AdvertisementList from '../AdvertisementList/AdvertisementList'
import { useTranslation } from 'react-i18next'

function getGeoFilters (searchLocation, queryParams) {
  const insidePolygon = []

  if (queryParams && queryParams.type && hasAreaOfOperation(queryParams.type)) {
    return {}
  }

  if (searchLocation && searchLocation.region) {
    const coordinates = searchLocation.region.coordinates.split(';').filter(v => v !== '')

    for (let i = 0; i < coordinates.length; i++) {
      const coords = coordinates[i].split(':')

      insidePolygon.push(parseFloat(coords[0]))
      insidePolygon.push(parseFloat(coords[1]))
    }
  }

  if (insidePolygon.length > 0) {
    return { insidePolygon: insidePolygon.toString() }
  } else if (searchLocation) {
    return { aroundLatLng: `${searchLocation.lat}, ${searchLocation.lng}` }
  }

  return {}
}

function handleScrollMapList () {
  window.scrollTo(0, 0)
}

function getHitIds (hits) {
  if (hits && hits.length) {
    return hits.map((h) => h.objectID)
  } else {
    return []
  }
}

const SupplierCardList = ({ hits, queryParams, searchLocation }) => {
  const defaultAroundRadius = window.DEFAULT_AROUND_RADIUS || 'all'
  const hitIds = getHitIds(hits)
  const { t } = useTranslation()

  React.useEffect(() => {
    if (hitIds.length && window.dataLayer) {
      window.dataLayer.push({ algoliaDisplayedObjectIDs: hitIds.join(','), algoliaIndexName: '' })
    }
  }, [hitIds])

  return (
    <div className='c-container'>
      <Configure
        {...getGeoFilters(searchLocation, queryParams)}
        aroundRadius={searchLocation ? defaultAroundRadius : 'all'}
      />
      {hits && hits.length > 0 ? (
        <Row className='ml-0 mr-0 supplierCardListContainer' style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          {hits.map((hit, idx) => (
            <Fragment key={hit.objectID}>
              <div
                className='supplierCardContainer'
              >
                <SupplierCard hit={hit} />
              </div>
              {searchLocation && idx === (hits.length - 1) ? <AdvertisementList searchLocation={searchLocation} pageSection='upper' subdomain={window.CURRENT_SUBDOMAIN} queryParams={queryParams} /> : null}
              {searchLocation && idx === (hits.length - 1) ? <AdvertisementList searchLocation={searchLocation} pageSection='lower' subdomain={window.CURRENT_SUBDOMAIN} queryParams={queryParams} /> : null}
              {idx === (hits.length - 1) && <BannerAdDisplay key='bannerad' variant='results-advt' />}
            </Fragment>
          ))}
        </Row>
      ) : null}
      <NoResultsNote limit={3} context='suppliers' />
      <Row
        style={{
          paddingLeft: '0',
          paddingRight: '0',
          paddingTop: '10px',
          paddingBottom: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div xs={12} onClick={handleScrollMapList}>
          <Pagination
            defaultRefinement={queryParams.page || 1}
            showFirst
            showPrevious
            showNext
            showLast
            padding={2}
            className='supplier-card-pagination'
            translations={{
              ariaPrevious: t('previous_page'),
              ariaNext: t('next_page'),
              ariaFirst: t('first_page'),
              ariaLast: t('last_page')
            }}
          />
        </div>
      </Row>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    searchLocation: state.app.searchLocation,
    queryParams: extractQueryParams(ownProps)
  }
}

export default withRouter(connect(mapStateToProps)(connectHits(SupplierCardList)))
