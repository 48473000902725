import React from 'react'
import { Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

class ValidationErrors extends React.Component {
  render () {
    const { validationErrors, erroredSections, t } = this.props
    if (validationErrors && validationErrors.full_messages) {
      return (
        <Row>
          <Col md={12}>
            <ul className='alert alert-danger validation-errors'>
              {
                validationErrors.full_messages.map((validationError, index) =>
                  <li key={`validation-error-${index}`}>{validationError}</li>
                )
              }
              {
                erroredSections && erroredSections.length > 0 ? (
                  <a className='font-weight-bold' href={`#${erroredSections[0]}`}>&gt;&gt; {t('supplier.edit.alert.go_to_section')}</a>
                ) : null
              }
            </ul>
          </Col>
        </Row>
      )
    } else {
      return null
    }
  }
}

ValidationErrors.propTypes = {
  validationErrors: PropTypes.object,
  erroredSections: PropTypes.array,
  t: PropTypes.func
}

export default withTranslation()(ValidationErrors)
