import React from 'react'
import Container from './Layout/Container'
import './SupplierAdmin.scss'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ProAccount from '../../assets/images/pro-account.jpg'
import SupportIcon from '../../assets/images/support-icon.png'
import Can from '../Layout/Can'
import { withTranslation } from 'react-i18next'

class Marketing extends React.Component {
  constructor (props) {
    super(props)

    this.adminPath = this.adminPath.bind(this)
  }

  adminPath (path) {
    const { supplier, match } = this.props

    return `/${match.params.userType}/${supplier.id}/admin/${path}`
  }

  renderHero () {
    const { t } = this.props

    return (
      <div className='c-content-box c-size-md c-bg-white c-bg-img-top'>
        <div className='container'>
          <div className='c-content-feature-11'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='c-content-title-1' style={{ textAlign: 'left' }}>
                  <h1 style={{ fontWeight: '300' }} dangerouslySetInnerHTML={{ __html: t('supplier.admin.marketing.hero.subtitle') }} />
                  <div className='c-line-left c-theme-bg' style={{ marginTop: '15px' }} />
                </div>
                <p>{t('supplier.admin.marketing.hero.title')}</p>
                <div style={{ marginTop: '15px' }}>
                  <p><b>{t('supplier.admin.marketing.hero.you_get')}</b></p>
                  <p><i className='fa fa-check' style={{ color: '#a3c95e' }} /> {t('supplier.admin.marketing.hero.better_ranking')}</p>
                  <p><i className='fa fa-check' style={{ color: '#a3c95e' }} /> {t('supplier.admin.marketing.hero.best_position_in_category')}</p>
                  <p><i className='fa fa-check' style={{ color: '#a3c95e' }} /> {t('supplier.admin.marketing.hero.more_advertisement_opportunities')}</p>
                  <p><i className='fa fa-check' style={{ color: '#a3c95e' }} /> {t('supplier.admin.marketing.hero.analytics_tools')}</p>
                </div>
                <a href='https://sites.miceportal.com/professional_account' className='btn c-theme-btn btn-md c-btn-uppercase c-btn-bold c-btn-square c-btn-login' style={{ marginTop: '30px' }} target='_blank' rel='noopener noreferrer'>{t('supplier.admin.marketing.hero.get_professional_account_now')}</a>
              </div>
              <div className='col-md-6 c-grid'>
                <img src={ProAccount} style={{ paddingTop: '50px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render () {
    const { t } = this.props

    return (
      <Container renderHero={() => this.renderHero()}>
        <div className='c-content-box c-size-md c-bg-grey' style={{ paddingTop: '30px' }}>
          <div className='container'>
            <div className='row' data-auto-height='true'>
              <div className='col-md-4 c-margin-b-30'>
                <div className='c-content-media-2'>
                  <Link to={this.adminPath('records')} className='c-title c-font-uppercase c-font-bold c-theme-on-hover'>{t('supplier.admin.marketing.my_record')}</Link>
                  <p>{t('supplier.admin.marketing.manage_your_record')}</p>
                  <Link to={this.adminPath('records')} className='btn c-theme-btn btn-md c-btn-uppercase c-btn-bold c-btn-square c-btn-login' style={{ marginTop: '20px', marginBottom: '20px' }}>
                    {t('lets_go')}
                  </Link>
                </div>
              </div>
              <div className='col-md-4 c-margin-b-30'>
                <div className='c-content-media-2'>
                  <Link to={this.adminPath('analytics')} className='c-title c-font-uppercase c-font-bold c-theme-on-hover'>{t('supplier.admin.marketing.my_analytics')}</Link>
                  <p>{t('supplier.admin.marketing.your_contact_details')}</p>
                  <Link to={this.adminPath('analytics')} className='btn c-theme-btn btn-md c-btn-uppercase c-btn-bold c-btn-square c-btn-login' style={{ marginTop: '20px', marginBottom: '20px' }}>
                    {t('lets_go')}
                  </Link>
                </div>
              </div>
              <div className='col-md-4 c-margin-b-30'>
                <div className='c-content-media-2'>
                  <Can perform='read' on='TableauMessage'>
                    <Link
                      to={this.adminPath('messages')}
                      className='c-title c-font-uppercase c-font-bold c-theme-on-hover'
                    >
                      {t('supplier.admin.marketing.my_messages')}
                    </Link>
                  </Can>
                  <Can not perform='read' on='TableauMessage'>
                    <span className='c-title c-font-uppercase c-font-bold'>{t('supplier.admin.marketing.my_messages')}</span>
                  </Can>

                  <p>{t('supplier.admin.marketing.everything_in_one_place')}</p>

                  <Can perform='read' on='TableauMessage'>
                    <Link
                      to={this.adminPath('messages')}
                      className='btn c-theme-btn btn-md c-btn-uppercase c-btn-bold c-btn-square c-btn-login'
                      style={{ marginTop: '20px', marginBottom: '20px' }}
                    >
                      {t('lets_go')}
                    </Link>
                  </Can>
                  <Can not perform='read' on='TableauMessage'>
                    <span
                      className='btn c-theme-btn btn-md c-btn-uppercase c-btn-bold c-btn-square c-btn-login disabled'
                      style={{ marginTop: '20px', marginBottom: '20px' }}
                    >
                      {t('lets_go')}
                    </span>
                  </Can>

                </div>
              </div>
            </div>
            <div className='row' data-auto-height='true'>
              <div className='col-md-4 c-margin-b-30'>
                <div className='c-content-media-2'>
                  <a href='https://sites.miceportal.com/ads' className='c-title c-font-uppercase c-font-bold c-theme-on-hover' target='_blank' rel='noopener noreferrer'>{t('supplier.admin.marketing.my_advertisements')}</a>
                  <p>{t('supplier.admin.marketing.create_advertisement')}</p>
                  <a href='https://sites.miceportal.com/ads' className='btn c-theme-btn btn-md c-btn-uppercase c-btn-bold c-btn-square c-btn-login' style={{ marginTop: '20px', marginBottom: '20px' }} target='_blank' rel='noopener noreferrer'>
                    {t('lets_go')}
                  </a>
                </div>
              </div>
              <div className='col-md-4 c-margin-b-30'>
                {!this.props.supplier.isA('KonvenitUser')
                  ? (
                    <div className='c-content-media-2'>
                      <Link to={this.adminPath('settings')} className='c-title c-font-uppercase c-font-bold c-theme-on-hover'>{t('supplier.admin.marketing.my_settings')}</Link>
                      <p>{t('supplier.admin.marketing.manage_your_users')}</p>
                      <Link to={this.adminPath('settings')} className='btn c-theme-btn btn-md c-btn-uppercase c-btn-bold c-btn-square c-btn-login' style={{ marginTop: '20px', marginBottom: '20px' }}>
                        {t('lets_go')}
                      </Link>
                    </div>
                  )
                  : null}
              </div>
              <div className='col-md-4 c-margin-b-30'>
                <div className='c-content-media-2 c-bg-green'>
                  <center><img src={SupportIcon} style={{ maxWidth: '120px', marginBottom: '10px' }} />
                    <h3 className='c-font-uppercase c-font-white c-font-bold' style={{ fontSize: '1.5rem' }}>
                      {t('supplier.admin.marketing.need_help')}
                    </h3>
                    <p className='c-font c-font-white' style={{ fontSize: '1.3rem', fontWeight: 600 }}>
                      <i className='fa fa-phone' />
                      +49 8168 99 74 1000
                    </p>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

Marketing.propTypes = {
  supplier: PropTypes.object,
  match: PropTypes.object
}

export default withRouter(withTranslation()(Marketing))
