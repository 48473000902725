import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import SearchBarPlaceSuggest from '../SearchBar/SearchBarPlaceSuggest'

import {
  FilterElementDeliverableType,
  DatePickerElement,
  FilterBarContainer
} from './FilterInputs'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import { useQueryParams } from '../../services/urlStateParser'
import 'react-dates/lib/css/_datepicker.css'
import './FilterBar.scss'
import MoreFilters from './MoreFilters'

const SupplierFilterBar = ({
  hideSupplierSearchBar,
  isInsideHeader,
  suitable,
  locationId
}) => {
  const queryParams = useQueryParams()
  const [showFilterModal, setShowFilterModal] = React.useState(false)

  const { t } = useTranslation()

  const applyMoreFilters = (selectedFilters) => {
    queryParams.updateV2(selectedFilters, '/results')
    setShowFilterModal(false)
  }

  React.useEffect(() => {
    moment.locale(queryParams.params.locale)
  }, [queryParams.params.locale])

  const numAppliedFilters = React.useCallback(() => {
    const notMoreFilters = ['location', 'type', 'query', 'chain_name', 'region', 'locale', 'lqt', 'facet_only', 'micepoints', 'contracts', 'sustainable', 'feedback_average', 'show_map']
    if (queryParams && queryParams.params && Object.keys(queryParams.params).length > 0) {
      const parsedParams = queryParams.getParsedParams()
      const appliedMoreFilters = Object.keys(parsedParams).reduce((acc, key) => {
        if (!notMoreFilters.includes(key)) {
          if (Array.isArray(parsedParams[key])) {
            acc.push(...parsedParams[key])
          } else {
            acc.push(key)
          }
        }
        return acc
      }, [])
      return appliedMoreFilters.length
    }
    return null
  }, [queryParams.params])

  return (
    <>
      <FilterBarContainer>
        <SearchBarPlaceSuggest
          resultsFrom='suppliers'
          hideSupplierSearchBar={hideSupplierSearchBar}
          isInsideHeader={isInsideHeader}
          suitable={suitable}
          locationId={locationId}
        />
        <DatePickerElement queryParams={queryParams} queryPath='/results' />
        <FilterElementDeliverableType
          queryParams={queryParams}
          queryPath='/results'
          noBackgroundHighlight
        />
        <div style={{ margin: '0 10px' }}>
          <ButtonWithIcon
            padding='8.5px 20px'
            className='client-theme--bg client-theme--font-color'
            label={t('more_filters')}
            icon='fas fa-sliders'
            onClick={() => setShowFilterModal(true)}
          />
          {numAppliedFilters() > 0 ? (<span className='badge badge-absolute client-theme--bg client-theme--font-color'>{numAppliedFilters()}</span>) : null}
        </div>
      </FilterBarContainer>
      <MoreFilters
        isOpened={showFilterModal}
        onConfirm={applyMoreFilters}
        onCancel={() => setShowFilterModal(false)}
        queryParams={queryParams}
      />
    </>
  )
}

export default SupplierFilterBar
