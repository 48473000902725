import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { withTranslation } from 'react-i18next'

class Loading extends React.Component {
  render () {
    const { t } = this.props

    return (
      <div className='containerFluid'>
        <Row className='m-0 mt-3 mb-3'>
          <Col className='p-0' md={12}>
            <Card>
              <CardBody>
                {t('common.loading')}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default withTranslation()(Loading)
