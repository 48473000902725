import React from 'react'
import './DetailsTabContent.scss'
import { withTranslation } from 'react-i18next'
import HotelDetails from '../HotelDetails/HotelDetails'
import LocationDetails from '../LocationDetails/LocationDetails'
import EventGastronomyDetails from '../EventGastronomyDetails/EventGastronomyDetails'
import TransferDetails from '../TransferDetails/TransferDetails'
import PersonalServiceDetails from '../PersonalServiceDetails/PersonalServiceDetails'
import CateringDetails from '../CateringDetails/CateringDetails'
import EventFacilityDetails from '../EventFacilityDetails/EventFacilityDetails'
import ConventionBureauDetails from '../ConventionBureauDetails/ConventionBureauDetails'

class DetailsTabContent extends React.Component {
  renderContent () {
    if (this.props.view) {
      switch (this.props.view.replace(/-/g, '_')) {
        case 'hotel':
          return <HotelDetails {...this.props} />
        case 'location':
          return <LocationDetails {...this.props} />
        case 'event_gastronomy':
          return <EventGastronomyDetails {...this.props} />
        case 'transfer':
          return <TransferDetails {...this.props} />
        case 'personal_service':
          return <PersonalServiceDetails {...this.props} />
        case 'catering':
          return <CateringDetails {...this.props} />
        case 'event_facility':
          return <EventFacilityDetails {...this.props} />
        case 'convention_bureau':
          return <ConventionBureauDetails {...this.props} />
      }
    }
  }

  render () {
    return this.renderContent()
  }
}
export default withTranslation()(DetailsTabContent)
