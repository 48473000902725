export const errorHandler = (error) => {
  if (!(error && error.response && error.response.status)) {
    console.error('An error occurred', error)
    return
  }
  switch (error.response.status) {
    case 403:
      window.location.href = '/access_denied'
      break
    case 404:
      window.location.href = '/404'
      break
    default:
      break
  }
}
