import React, { Fragment as div } from 'react'
import './SupplierFeatureCarouselBox.scss'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2400 },
    items: 1
  },
  largeDesktop: {
    breakpoint: { max: 2400, min: 1600 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 1600, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}

class SupplierFeatureCarouselBox extends React.Component {
  // This component expects these properties:
  //
  // - title (string, required),
  // - locale (string, e.g. 'de'; used to fetch the right names from the items)
  // - items (an array of object with `key`, `image_url` and `name_[locale]` fields)
  //
  // If the `items` is present, then the `locale` must also be present.

  render () {
    const locale = this.props.locale
    const items = (this.props.items || []).filter(item => item.image_url)

    return (
      <div className='c-content-media-1 contentBox supplier-feature-box-round' style={{ height: '100%' }}>
        <div className='c-content-label c-font-uppercase c-font-bold c-bg-regular'>{this.props.title}</div>
        {items.length > 0 ? (
          <>
            <Carousel
              itemClass='supplierFeatureCarouselItem'
              containerClass='supplierFeatureCarouselContainer'
              infinite
              responsive={responsive}
            >
              {items.map((item) => (
                <div key={item.key} className='supplierFeatureCarouselItemContainer'>
                  <div className='supplierFeatureCarouselItemImageContainer'>
                    <img src={item.image_url} alt='' />
                  </div>
                  <div className='supplierFeatureCarouselItemImageCaptionContainer'>{item[`name_${locale}`]}</div>
                </div>
              ))}
            </Carousel>
          </>
        ) : null}
      </div>
    )
  }
}

SupplierFeatureCarouselBox.propTypes = {
  title: PropTypes.string.isRequired,
  locale: PropTypes.string,
  items: PropTypes.array
}

export default SupplierFeatureCarouselBox
