import React from 'react'
import './TextInputGroup.scss'
import { UUID } from '../../shared/utils'

class TextInputGroup extends React.Component {
  constructor (props) {
    super(props)
    this.UUID = UUID()
    this.inputStyleBorderModifier = this.inputStyleBorderModifier.bind(this)
  }

  defaultLabelStyle () {
    return {
      position: 'absolute',
      top: '-23px',
      width: '100%',
      fontSize: '0.85rem',
      margin: '0px'
    }
  }

  inputStyleBorderModifier (prependText = false, appendText = false) {
    let borderStyle = {}
    if (!prependText && appendText) {
      borderStyle = { borderRight: '1px solid transparent !important' }
    } else if (prependText && !appendText) {
      borderStyle = { borderLeft: '1px solid transparent !important' }
    } else if (prependText && appendText) {
      borderStyle = { borderLeft: '1px solid transparent !important', borderRight: '1px solid transparent !important' }
    } else {
      borderStyle = {}
    }
    return borderStyle
  }

  render () {
    const propStyle = this.props.style || {}
    const modifyBorder = this.inputStyleBorderModifier(this.props.prependText, this.props.appendText)
    const style = Object.assign({}, propStyle, modifyBorder)
    const inputId = this.props.id ? this.props.id : `UUID-${this.UUID}`
    return (
      <div className='input-group mt-3'>
        {this.props.label ? (
          <label
            style={this.props.labelStyle ? this.props.labelStyle : this.defaultLabelStyle()}
            htmlFor={inputId}
          >
            {this.props.label}
          </label>
        ) : null}
        {this.props.prependText ? (
          <div className='input-group-prepend'>
            <span className='input-group-text inputGroupPrependTextStyle'>{this.props.prependText}</span>
          </div>
        ) : null}
        <input
          type='text'
          name={this.props.name ? this.props.name : inputId}
          className={`form-control noBorderRadius ${this.props.className} ${this.props.error ? 'is-invalid' : ''}`}
          style={style}
          id={inputId}
          value={this.props.value || ''}
          placeholder={this.props.placeholder || ''}
          onChange={this.props.onChange}
        />
        {this.props.appendText ? (
          <div className='input-group-append'>
            <span className='input-group-text inputGroupAppendTextStyle'>{this.props.appendText}</span>
          </div>
        ) : null}
        {this.props.error ? (
          <div className='invalid-feedback'>
            {this.props.error}
          </div>
        ) : null}
      </div>
    )
  }
}
export default TextInputGroup
