import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import './Results.scss'
import { connect } from 'react-redux'
import { scrollToTop } from '../../shared/utils'
import { withTranslation } from 'react-i18next'
import queryString from 'qs'
import { extractQueryParams } from '../../services/urlStateParser'
import MomentsResults from './MomentsResults.js'
import SupplierResults from './SupplierResults'

class Results extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      activeFilter: this.getActiveFilter()
    }
    this.timer = null
    this.changeResultsFilter = this.changeResultsFilter.bind(this)
    this.getActiveFilter = this.getActiveFilter.bind(this)
    this.handleToggleMapView = this.handleToggleMapView.bind(this)
  }

  getActiveFilter () {
    const currentFilter = []
    if (this.props.queryParams) {
      if (this.props.queryParams.contracts) {
        currentFilter.push('contracts')
      }
      if (this.props.queryParams.sustainable) {
        currentFilter.push('sustainable')
      }
    }
    return currentFilter
  }

  getResultsSource () {
    return this.props.location.pathname.startsWith('/moments') ? 'moments' : 'suppliers'
  }

  componentDidMount () {
    scrollToTop()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.queryParams !== this.props.queryParams) {
      this.setState({ activeFilter: this.getActiveFilter() })
    }
  }

  newResultsFilter (pathname, params = {}) {
    const queryParams = { ...this.props.queryParams }

    return {
      pathname: pathname,
      search: queryString.stringify({ ...queryParams, ...params })
    }
  }

  changeResultsFilter (params) {
    const pathname = this.props.location.pathname
    this.props.history.push(this.newResultsFilter(pathname, params))
  }

  toggleMapViewUrl () {
    const showMap = !(this.props.queryParams.show_map === 'true')

    return {
      pathname: this.props.location.pathname,
      search: queryString.stringify({ ...this.props.queryParams, show_map: showMap })
    }
  }

  handleToggleMapView () {
    this.props.history.push(this.toggleMapViewUrl())
  }

  renderTabSelector () {
    const tabs = [
      { key: 'suppliers', href: this.newResultsFilter('/results') },
      { key: 'moments', href: this.newResultsFilter('/moments') }
    ]
    tabs.forEach(item => {
      item.title = this.props.t(`search.results_from.${item.key}.title`)
      item.label = this.props.t(`search.results_from.${item.key}.label`)
      item.activeClassnames = (this.getResultsSource() === item.key) ? 'active border-0' : ''
    })

    return (
      <div className='tabSelector containerFluid'>
        <nav className=''>
          {tabs.map(item => <Link key={item.key} to={item.href} className={`${item.activeClassnames}`}><div className='title'>{item.title}</div><div>{item.label}</div></Link>)}
        </nav>
      </div>
    )
  }

  render () {
    const isMapView = this.props.queryParams.show_map === 'true'
    return (
      <div className='c-container c-bg-grey-1'>
        {this.getResultsSource() === 'moments' ? (
          <MomentsResults
            withMap={isMapView}
            onMapToggle={this.toggleMapViewUrl()}
            queryParams={this.props.queryParams}
            queryPathname={this.props.location.pathname}
          />
        ) : (
          <SupplierResults
            isMapView={isMapView}
            changeResultsFilter={this.changeResultsFilter}
            onToggleMapView={this.handleToggleMapView}
            queryParams={this.props.queryParams}
            searchLocation={this.props.searchLocation}
            activeFilter={this.state.activeFilter}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    searchLocation: state.app.searchLocation,
    searchSession: state.app.searchSession,
    queryParams: extractQueryParams(ownProps)
  }
}

export default withRouter(connect(mapStateToProps)(withTranslation()(Results)))
