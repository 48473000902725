import React from 'react'
import axios from 'axios'
import HotelAmenities from './HotelAmenities'
import LocationAmenities from './LocationAmenities'
import Loading from '../Loading'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { areAttributesMappedToBool, scrollToTop } from '../../../../shared/utils'

class Amenities extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      supplier: {},
      amenityImages: [],
      wellnessImages: [],
      gastronomyImages: [],
      validationErrors: null,
      hasUserMadeChanges: false,
      submitStatus: 'nothing-to-save'
    }

    this.keyDeliverable = null
    this.getFormattedFormData = this.getFormattedFormData.bind(this)
    this.getValidCloudinaryImages = this.getValidCloudinaryImages.bind(this)
    this.setElementOrderForImages = this.setElementOrderForImages.bind(this)
    this.mapValuesToBool = this.mapValuesToBool.bind(this)
    this.mapValuesToObject = this.mapValuesToObject.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleMultipleSelectChange = this.handleMultipleSelectChange.bind(this)
    this.handleImageUploadComplete = this.handleImageUploadComplete.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleRemoveImage = this.handleRemoveImage.bind(this)
    this.handleAddImage = this.handleAddImage.bind(this)
    this.handleMoveImage = this.handleMoveImage.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  componentDidMount () {
    if (this.props.deliverableType) {
      this.keyDeliverable = `supplier_${this.props.deliverableType}`
    }

    this.initializeStateWithExistingImages()
    this.initializeStateWithExistingFormData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.supplier !== this.props.supplier) {
      this.initializeStateWithExistingImages()
      this.initializeStateWithExistingFormData()
    }
    // we only need to take action for the first change made by the user
    if (!prevState.hasUserMadeChanges && this.state.hasUserMadeChanges) {
      this.props.updateHandler(null, 'Amenities')
      this.setState({ submitStatus: 'ready-to-save' })
    }
  }

  initializeStateWithExistingImages () {
    const supplierDeliverable = this.props.supplier[`supplier_${this.props.deliverableType}`]
    let amenityImages = []
    let wellnessImages = []
    let gastronomyImages = []

    if (this.props.supplier && supplierDeliverable) {
      if (supplierDeliverable.amenity_images && supplierDeliverable.amenity_images.length > 0) {
        amenityImages = [...supplierDeliverable.amenity_images]
      }

      if (supplierDeliverable.wellness_images && supplierDeliverable.wellness_images.length > 0) {
        wellnessImages = [...supplierDeliverable.wellness_images]
      }

      if (supplierDeliverable.gastronomy_images && supplierDeliverable.gastronomy_images.length > 0) {
        gastronomyImages = [...supplierDeliverable.gastronomy_images]
      }

      this.setState({ amenityImages, wellnessImages, gastronomyImages })
    }
  }

  initializeStateWithExistingFormData () {
    if (this.props.supplier) {
      this.setState({ supplier: { ...this.props.supplier }, hasUserMadeChanges: false, submitStatus: 'nothing-to-save' })
    }
  }

  handleAddImage (imageCategory) {
    const images = [...this.state[imageCategory]]
    images.push({ cloudinary_public_id: null, url: null })
    this.setState({ [imageCategory]: images, hasUserMadeChanges: true })
  }

  handleRemoveImage (imageCategory, id) {
    const images = [...this.state[imageCategory]]
    const image = { ...this.state[imageCategory][id] }

    image._destroy = true
    images[id] = image
    this.setState({ [imageCategory]: images, hasUserMadeChanges: true })
  }

  handleMoveImage (imageCategory, idx, newIdx) {
    if (newIdx >= 0 && newIdx < this.state[imageCategory].length) {
      const images = [...this.state[imageCategory]]
      const otherImage = images[newIdx]

      images[newIdx] = images[idx]
      images[idx] = otherImage

      for (let i = 0; i < images.length; i++) {
        images[i].element_order = i
      }

      this.setState({ [imageCategory]: images, hasUserMadeChanges: true })
    }
  }

  handleChange (event) {
    if (event && event.currentTarget) {
      const value = event.currentTarget.type === 'checkbox' ? event.currentTarget.checked : event.currentTarget.value
      this.setState({ supplier: { ...this.state.supplier, [event.currentTarget.name]: value }, hasUserMadeChanges: true })
    }
  }

  handleMultipleSelectChange (attributeName, values, deliverable = null) {
    if (attributeName) {
      let supplier = { ...this.state.supplier }
      if (deliverable) {
        const filteredOptions = this.mapValuesToObject(supplier[this.keyDeliverable][attributeName], values)
        if (areAttributesMappedToBool(attributeName)) {
          const mappedBoolValues = this.mapValuesToBool(filteredOptions)
          supplier[this.keyDeliverable] = { ...supplier[this.keyDeliverable], ...mappedBoolValues }
        }
        supplier[this.keyDeliverable] = { ...supplier[this.keyDeliverable], [attributeName]: filteredOptions }
      } else {
        const filteredOptions = this.mapValuesToObject(supplier[attributeName], values)
        if (areAttributesMappedToBool(attributeName)) {
          const mappedBoolValues = this.mapValuesToBool(filteredOptions)
          supplier = { ...supplier, ...mappedBoolValues }
        }
        supplier[attributeName] = filteredOptions
      }
      this.setState({ supplier: supplier, hasUserMadeChanges: true })
    }
  }

  handleImageUploadComplete (imageCategory, id, response) {
    const images = [...this.state[imageCategory]]
    const image = { ...this.state[imageCategory][id] }
    if (response && response.public_id) {
      if (id !== null && id >= 0) {
        image.cloudinary_public_id = response.public_id
        image.url = response.url
        images[id] = image
      } else {
        images.push({ cloudinary_public_id: response.public_id, url: response.url })
      }
      this.setState({ [imageCategory]: images, hasUserMadeChanges: true })
    }
  }

  handleReset () {
    this.setState({
      validationErrors: null,
      hasUserMadeChanges: false,
      submitStatus: 'nothing-to-save'
    })
    this.props.resetHandler()
  }

  handleSubmit () {
    this.setState({ submitStatus: 'in-progress' })

    axios.defaults.withCredentials = true
    axios
      .put(`/suppliers/${this.props.supplier.id}.json`, { ...this.getFormattedFormData(), locale: this.props.i18n.language })
      .then(response => {
        this.setState({
          validationErrors: null,
          hasUserMadeChanges: false,
          submitStatus: 'save-successful'
        })
        this.props.updateHandler(response.data, null)
      })
      .catch(error => {
        this.setState({
          validationErrors: error.response && error.response.data && error.response.data.errors,
          submitStatus: 'submit-error',
          hasUserMadeChanges: true
        })
        scrollToTop()
      })
  }

  getFormattedFormData () {
    const supplier = { ...this.state.supplier }

    supplier[this.keyDeliverable].amenity_images_attributes = this.setElementOrderForImages(this.getValidCloudinaryImages(this.state.amenityImages))
    supplier[this.keyDeliverable].wellness_images_attributes = this.setElementOrderForImages(this.getValidCloudinaryImages(this.state.wellnessImages))
    supplier[this.keyDeliverable].gastronomy_images_attributes = this.setElementOrderForImages(this.getValidCloudinaryImages(this.state.gastronomyImages))

    supplier[`${this.keyDeliverable}_attributes`] = supplier[this.keyDeliverable]

    return {
      facet: 'amenities',
      deliverable_type: this.props.deliverableType,
      locale: this.props.i18n.language,
      supplier: supplier
    }
  }

  getValidCloudinaryImages (images) {
    return images && images.length > 0 ? images.filter(image => { return image.cloudinary_public_id !== null }) : []
  }

  setElementOrderForImages (images) {
    return images && images.length > 0 ? images.map((image, idx) => { image.element_order = idx + 1; return image }) : []
  }

  mapValuesToObject (attributeOptions, values) {
    return attributeOptions.map((option) => {
      if (values && values.find(value => value.attribute_name === option.attribute_name)) {
        option.checked = true
      } else {
        option.checked = false
      }
      return option
    })
  }

  mapValuesToBool (values) {
    let mappedValues = {}
    if (values && values.length > 0) {
      values.map(value => {
        mappedValues = { ...mappedValues, [value.attribute_name]: value.checked }
      })
    }
    return mappedValues
  }

  renderAmenities () {
    switch (this.props.deliverableType) {
      case 'hotel':
        return (
          <HotelAmenities
            supplier={this.state.supplier}
            uploadWidget={this.props.uploadWidget}
            onChange={this.handleChange}
            onMultipleSelectChange={this.handleMultipleSelectChange}
            onSubmit={this.handleSubmit}
            images={{ amenity: this.state.amenityImages, wellness: this.state.wellnessImages, gastronomy: this.state.gastronomyImages }}
            onAddImage={this.handleAddImage}
            onRemoveImage={this.handleRemoveImage}
            onMoveImage={this.handleMoveImage}
            onImageUploadComplete={this.handleImageUploadComplete}
            saveButtonDisabled={this.state.saveButtonDisabled}
            saveButtonLabel={this.props.t(this.state.saveButtonLabelKey)}
            saveButtonIcon={this.state.saveButtonIcon}
            validationErrors={this.state.validationErrors}
            resetButtonIcon={this.state.resetButtonIcon}
            resetButtonLabel={this.props.t(this.state.resetButtonLabelKey)}
            onReset={this.handleReset}
            hasUserMadeChanges={this.state.hasUserMadeChanges}
            submitStatus={this.state.submitStatus}
          />
        )
      case 'location':
        return (
          <LocationAmenities
            supplier={this.state.supplier}
            uploadWidget={this.props.uploadWidget}
            onChange={this.handleChange}
            onMultipleSelectChange={this.handleMultipleSelectChange}
            onSubmit={this.handleSubmit}
            images={{ amenity: this.state.amenityImages, wellness: this.state.wellnessImages, gastronomy: this.state.gastronomyImages }}
            onAddImage={this.handleAddImage}
            onRemoveImage={this.handleRemoveImage}
            onMoveImage={this.handleMoveImage}
            onImageUploadComplete={this.handleImageUploadComplete}
            saveButtonDisabled={this.state.saveButtonDisabled}
            saveButtonLabel={this.props.t(this.state.saveButtonLabelKey)}
            saveButtonIcon={this.state.saveButtonIcon}
            validationErrors={this.state.validationErrors}
            resetButtonIcon={this.state.resetButtonIcon}
            resetButtonLabel={this.props.t(this.state.resetButtonLabelKey)}
            onReset={this.handleReset}
            hasUserMadeChanges={this.state.hasUserMadeChanges}
            submitStatus={this.state.submitStatus}
          />
        )
      default:
        return ''
    }
  }

  render () {
    if (this.props.supplier && this.props.supplier.id) {
      return this.renderAmenities()
    } else {
      return <Loading />
    }
  }
}

Amenities.propTypes = {
  supplier: PropTypes.object,
  uploadWidget: PropTypes.object,
  deliverableType: PropTypes.string
}

export default withTranslation()(Amenities)
