import React from 'react'
import { useTranslation } from 'react-i18next'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

import { supplierFacets, supplierFilters } from '../../shared/suppliersIndexParams'
import { momentFacets } from '../../shared/momentsIndexParams'
import { useQueryParams } from '../../services/urlStateParser'

import './Tag.scss'

function translateMultipleValues (t, strValues, scope = undefined) {
  if (scope) {
    return ('' + strValues)
      .split(/\s*,\s*/)
      .map(key => t(`${scope}.${key.replace(/-/g, '_')}`))
  } else {
    return ('' + strValues)
      .split(/\s*,\s*/)
      .map(key => t(`${key.replace(/-/g, '_')}`))
  }
}

function filterValue (t, filter) {
  switch (filter.key) {
    case 'type':
      return ['0', 0].includes(filter.value) ? '' : translateMultipleValues(t, filter.value, 'supplier.deliverable_type_names').join(', ')
    case 'suitable':
      return translateMultipleValues(t, filter.value, 'suitable_for_types').join(', ')
    case 'payment_methods':
      return translateMultipleValues(t, filter.value).join(', ')
    case 'category':
    case 'room_amenity':
    case 'equipment_amenity':
    case 'wellness_amenity':
    case 'sport_amenity':
    case 'gastronomy_amenity':
    case 'amenity':
      return translateMultipleValues(t, filter.value, 'filters').join(', ')
    case 'sustainable':
      return t(`sustainable.${filter.value.toLowerCase()}`)
    default:
      return filter.value
  }
}

function filterName (t, filter) {
  return t(`filter_tags.${filter.key}`, filter.key)
}

export const TagContent = ({ label, onClick }) => {
  const { t } = useTranslation()
  return (
    <div className='mr-2 mt-1 mb-1 inline-block'>
      <button
        className='label label-primary c-font-slim c-tag'
        style={{ background: 'none', color: '#626262' }}
        onClick={onClick}
        aria-label={t('aria_labels.delete') + ' ' + label}
      >
        <i className='fa fa-times' />
        {label}
      </button>
    </div>
  )
}

const RefinementTagBase = ({ items, refine, label, filter, onClick }) => {
  const myItems = items.filter((item) => item.attribute === (supplierFacets[filter.key] || momentFacets[filter.key]))
  return <TagContent label={label} onClick={() => { onClick(); refine(myItems) }} />
}
const RefinementTag = connectCurrentRefinements(RefinementTagBase)

export const Tag = ({ filter }) => {
  const queryParams = useQueryParams()
  const removeParam = () => Array.isArray(supplierFilters[filter.key]) ? supplierFilters[filter.key].forEach(f => { queryParams.remove(f, filter.key) }) : queryParams.remove(supplierFilters[filter.key], filter.key)
  const { t } = useTranslation()
  const label = filterValue(t, filter) === 'true' ? filterName(t, filter) : `${filterName(t, filter)}: ${filterValue(t, filter)}`

  if (!['0', 0].includes(filter.value)) {
    if (supplierFacets[filter.key] || momentFacets[filter.key]) {
      return <RefinementTag label={label} filter={filter} onClick={removeParam} />
    } else {
      return <TagContent label={label} onClick={removeParam} />
    }
  }
}
