import React from 'react'
import PropTypes from 'prop-types'
import './CartItem.scss'
import { hasValidContracts, getLogoUrl } from '../../shared/utils'
import SuppliersIndexParams from '../../shared/suppliersIndexParams'
import { withTranslation } from 'react-i18next'
import { CartItemLoader } from '../../shared/contentLoaders'
import { Link } from 'react-router-dom'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import { removeFromCart } from '../../redux/actions'
import { connect } from 'react-redux'

class CartItem extends React.Component {
  render () {
    const { t } = this.props

    const suppliersIndexParams = new SuppliersIndexParams(this.props.searchParams)
    const projectStartDateUnix = suppliersIndexParams.getStartDateUnix()
    return this.props.item ? (
      <div className='row c-cart-table-row cartItemContainer ml-0 mr-0'>
        <div className='col-md-4 cart-image'>
          <img
            src={getLogoUrl(this.props.item)}
            alt={this.props.item.name}
          />
        </div>
        <div className='col-md-6 cart-desc'>
          <Link to={`/hotel/${this.props.item.objectID}`}>
            <div className='item-name'>{this.props.item.name}</div>
          </Link>
          {this.props.item.city && this.props.item.post_code ? (
            <div style={{ marginTop: '15px' }}>{`${this.props.item.post_code} ${this.props.item.city}`}</div>
          ) : null}
          {this.props.item.deliverable_types && this.props.item.deliverable_types.length > 0 ? (
            <div
              style={{
                marginTop: '10px',
                width: '100%',
                display: 'block'
              }}
            >

              {this.props.item.selected_deliverable_types && this.props.item.selected_deliverable_types.length > 0 ? (
                this.props.item.selected_deliverable_types.map((deliverableType, idx) => {
                  return (
                    <span
                      key={idx}
                      className='label c-font-slim supplierTypeLabel inline-block float-left'
                    >
                      {deliverableType}
                    </span>
                  )
                })
              ) : (
                this.props.item.deliverable_types.map((deliverableType, idx) => {
                  return (
                    <span
                      key={idx}
                      className='label c-font-slim supplierTypeLabel inline-block float-left'
                    >
                      {deliverableType}
                    </span>
                  )
                })
              )}
              {hasValidContracts(this.props.item.contracts, projectStartDateUnix) ? (
                <span
                  key='contract'
                  className='label c-font-slim labelPrimary supplierTypeLabel inline-block float-left client-theme--bg'
                >
                  {t('contract')}
                </span>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className='col-md-2 col-sm-12 c-cart-remove d-flex justify-content-center'>
          <ButtonWithIcon
            iconSize='16px'
            iconColor='#FFF'
            padding='8px 18px'
            className='w-50'
            background='#3f444a'
            icon='fal fa-trash-alt'
            label=''
            onClick={() => this.props.removeFromCart(this.props.item)}
            ariaLabel={t('aria_labels.delete_supplier')}
          />
        </div>
      </div>
    ) : (
      <CartItemLoader />
    )
  }
}

const mapStateToProps = state => {
  return {
    cartItems: state.app.cartItems
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeFromCart: payload => dispatch(removeFromCart(payload))
  }
}

CartItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  sku: PropTypes.string,
  image: PropTypes.string,
  location: PropTypes.string,
  labels: PropTypes.array,
  averageResponseTime: PropTypes.string,
  item: PropTypes.object,
  removeFromCart: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CartItem))
