import React from 'react'
import './SupplierRates.scss'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { withTranslation } from 'react-i18next'

class SupplierRates extends React.Component {
  render () {
    const { t, i18n } = this.props
    const servicePackagePrices = this.props.servicePackagePrices
    const prices = this.props.prices
    const publicRates = this.props.publicRates

    const servicePackagePriceColumns = [
      {
        Header: t('supplier.home.tabs.details.terms.tabs.rates.table_heading.service_package'),
        accessor: `service_package.name_${i18n.language}`
      },
      {
        id: 'contractRate',
        Header: publicRates ? t('supplier.home.tabs.details.terms.tabs.rates.table_heading.contract_rate_mini_shop') : t('supplier.home.tabs.details.terms.tabs.rates.table_heading.contract_rate'),
        accessor: d => d ? t('supplier.home.tabs.details.terms.tabs.rates.service_package_price', { per_person: d.price_per_person.formatted, per_item: d.price_per_item.formatted }) : ''
      }
    ]

    const priceColumns = [
      {
        Header: t('supplier.home.tabs.details.terms.tabs.rates.table_heading.service'),
        accessor: `service.label_${i18n.language}`
      },
      {
        id: 'contractRate',
        Header: publicRates ? t('supplier.home.tabs.details.terms.tabs.rates.table_heading.contract_rate_mini_shop') : t('supplier.home.tabs.details.terms.tabs.rates.table_heading.contract_rate'),
        accessor: d => d && d.price ? d.price.formatted : ''
      }
    ]

    return (
      <>
        <div className=''>
          <ul className='list-unstyled contractRatesFeaturesList'>
            <li>
              <div className='listLabel'>{t('supplier.home.tabs.details.terms.tabs.rates.contract_validity')}</div>
              <div className='listValue'>
                {this.props.validity && this.props.validity.from ? this.props.validity.from : ''}{' '}
                {t('supplier.home.tabs.details.terms.tabs.rates.until')}{' '}
                {this.props.validity && this.props.validity.until ? this.props.validity.until : ''}
              </div>
            </li>
          </ul>
        </div>

        {servicePackagePrices && servicePackagePrices.length > 0
          ? (
            <div className='contractRatesTableContainer servicePackagePrices'>
              <ReactTable minRows={1} showPagination={false} data={servicePackagePrices} columns={servicePackagePriceColumns} />
            </div>
          )
          : ''}

        <div className='contractRatesTableContainer prices'>
          <ReactTable minRows={5} showPagination={false} data={prices} columns={priceColumns} />
        </div>
      </>
    )
  }
}
export default withTranslation()(SupplierRates)
