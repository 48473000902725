import React from 'react'
import ContentLoader from 'react-content-loader'

export const ResultsLoader = () => (
  <ContentLoader height={475} width={400} speed={2} primaryColor='#f3f3f3' secondaryColor='#ecebeb'>
    <rect x='16' y='12' rx='0' ry='0' width='25' height='8' />
    <rect x='56' y='12' rx='0' ry='0' width='25' height='8' />
    <rect x='94' y='12' rx='0' ry='0' width='25' height='8' />
    <rect x='351' y='12' rx='0' ry='0' width='25' height='8' />
    <rect x='20' y='32' rx='0' ry='0' width='66' height='66' />
    <rect x='116' y='32' rx='0' ry='0' width='66' height='66' />
    <rect x='207' y='32' rx='0' ry='0' width='66' height='66' />
    <rect x='302' y='32' rx='0' ry='0' width='66' height='66' />

    <rect x='20' y='108' rx='0' ry='0' width='66' height='66' />
    <rect x='116' y='108' rx='0' ry='0' width='66' height='66' />
    <rect x='207' y='108' rx='0' ry='0' width='66' height='66' />
    <rect x='302' y='108' rx='0' ry='0' width='66' height='66' />

    <rect x='20' y='182' rx='0' ry='0' width='66' height='66' />
    <rect x='116' y='182' rx='0' ry='0' width='66' height='66' />
    <rect x='207' y='182' rx='0' ry='0' width='66' height='66' />
    <rect x='302' y='182' rx='0' ry='0' width='66' height='66' />

    <rect x='20' y='256' rx='0' ry='0' width='66' height='66' />
    <rect x='116' y='256' rx='0' ry='0' width='66' height='66' />
    <rect x='207' y='256' rx='0' ry='0' width='66' height='66' />
    <rect x='302' y='256' rx='0' ry='0' width='66' height='66' />
  </ContentLoader>
)

export const BreadcrumbLoader = () => (
  <ContentLoader height={15} width={400} speed={2} primaryColor='#f3f3f3' secondaryColor='#ecebeb'>
    <rect x='18' y='5' rx='0' ry='0' width='365' height='5' />
  </ContentLoader>
)

export const CartItemLoader = () => (
  <ContentLoader height={35} width={400} speed={2} primaryColor='#f3f3f3' secondaryColor='#ecebeb'>
    <rect x='18' y='8' rx='0' ry='0' width='365' height='7' />
    <rect x='18' y='19' rx='0' ry='0' width='365' height='12' />
  </ContentLoader>
)

export const HeaderLoader = () => (
  <ContentLoader height={35} width={400} speed={2} primaryColor='#f3f3f3' secondaryColor='#ecebeb'>
    <rect x='18' y='8' rx='0' ry='0' width='365' height='7' />
    <rect x='18' y='19' rx='0' ry='0' width='365' height='12' />
  </ContentLoader>
)

export const FooterLoader = () => (
  <ContentLoader height={120} width={400} speed={2} primaryColor='#f3f3f3' secondaryColor='#ecebeb'>
    <rect x='18' y='10' rx='0' ry='0' width='365' height='70' />
    <rect x='17' y='92' rx='0' ry='0' width='365' height='17' />
  </ContentLoader>
)

export const SupplierHomeLoader = () => (
  <ContentLoader height={475} width={400} speed={2} primaryColor='#f3f3f3' secondaryColor='#ecebeb'>
    <rect x='0' y='0' rx='0' ry='0' width='400' height='60' />
    <rect x='0' y='70' rx='0' ry='0' width='400' height='12' />

    <rect x='20' y='90' rx='0' ry='0' width='70' height='68' />
    <rect x='115' y='90' rx='0' ry='0' width='70' height='68' />
    <rect x='215' y='90' rx='0' ry='0' width='70' height='68' />
    <rect x='310' y='90' rx='0' ry='0' width='70' height='68' />

    <rect x='20' y='166' rx='0' ry='0' width='70' height='25' />
    <rect x='115' y='166' rx='0' ry='0' width='70' height='25' />
    <rect x='215' y='166' rx='0' ry='0' width='70' height='25' />
    <rect x='310' y='166' rx='0' ry='0' width='70' height='25' />

    <rect x='20' y='204' rx='0' ry='0' width='360' height='68' />
    <rect x='20' y='292' rx='0' ry='0' width='360' height='50' />
  </ContentLoader>
)

export const SupplierEditHomeLoader = () => (
  <ContentLoader height={475} width={400} speed={2} primaryColor='#f3f3f3' secondaryColor='#ecebeb'>
    <rect x='20' y='0' rx='0' ry='0' width='360' height='20' />

    <rect x='20' y='30' rx='0' ry='0' width='54' height='12' />
    <rect x='116' y='30' rx='0' ry='0' width='36' height='12' />
    <rect x='190' y='30' rx='0' ry='0' width='36' height='12' />
    <rect x='264' y='30' rx='0' ry='0' width='36' height='12' />
    <rect x='338' y='30' rx='0' ry='0' width='42' height='12' />

    <rect x='20' y='50' rx='0' ry='0' width='360' height='150' />
    <rect x='20' y='205' rx='0' ry='0' width='360' height='20' />
    <rect x='20' y='230' rx='0' ry='0' width='360' height='20' />
    <rect x='20' y='255' rx='0' ry='0' width='360' height='20' />
    <rect x='20' y='280' rx='0' ry='0' width='360' height='20' />
    <rect x='20' y='305' rx='0' ry='0' width='360' height='20' />
    <rect x='20' y='330' rx='0' ry='0' width='360' height='20' />
    <rect x='20' y='355' rx='0' ry='0' width='120' height='20' />
  </ContentLoader>
)

export const HomepageCategorySectionLoader = () => (
  <ContentLoader
    speed={2}
    width={600}
    height={150}
    viewBox='0 0 600 150'
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
  >
    <circle cx='565' cy='90' r='15' />
    <rect x='50' y='15' rx='0' ry='0' width='300' height='10' />
    <circle cx='35' cy='90' r='15' />
    <rect x='60' y='35' rx='0' ry='0' width='85' height='100' />
    <rect x='160' y='35' rx='0' ry='0' width='85' height='100' />
    <rect x='260' y='35' rx='0' ry='0' width='85' height='100' />
    <rect x='360' y='35' rx='0' ry='0' width='85' height='100' />
    <rect x='460' y='35' rx='0' ry='0' width='85' height='100' />
  </ContentLoader>
)

export const HomepageTrendlineSectionLoader = () => (
  <ContentLoader
    speed={2}
    width={600}
    height={150}
    viewBox='0 0 600 150'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
  >
    <circle cx='565' cy='90' r='15' />
    <rect x='50' y='10' rx='0' ry='0' width='200' height='10' />
    <circle cx='35' cy='90' r='15' />
    <rect x='60' y='35' rx='0' ry='0' width='85' height='100' />
    <rect x='160' y='35' rx='0' ry='0' width='85' height='100' />
    <rect x='260' y='25' rx='0' ry='0' width='85' height='120' />
    <rect x='360' y='35' rx='0' ry='0' width='85' height='100' />
    <rect x='460' y='35' rx='0' ry='0' width='85' height='100' />
    <rect x='500' y='10' rx='0' ry='0' width='45' height='10' />
  </ContentLoader>
)

export const HomepageCitySectionLoader = () => (
  <ContentLoader
    speed={2}
    width={600}
    height={150}
    viewBox='0 0 600 150'
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
  >
    <rect x='60' y='10' rx='0' ry='0' width='200' height='10' />
    <rect x='60' y='35' rx='0' ry='0' width='70' height='100' />
    <rect x='145' y='35' rx='0' ry='0' width='70' height='100' />
    <rect x='230' y='35' rx='0' ry='0' width='70' height='100' />
    <rect x='315' y='35' rx='0' ry='0' width='70' height='100' />
    <rect x='400' y='35' rx='0' ry='0' width='70' height='100' />
    <rect x='485' y='35' rx='0' ry='0' width='70' height='100' />
  </ContentLoader>
)

export const HomepageSuitableForSectionLoader = () => (
  <ContentLoader
    speed={2}
    width={600}
    height={150}
    viewBox='0 0 600 150'
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
  >
    <circle cx='565' cy='90' r='15' />
    <rect x='50' y='15' rx='0' ry='0' width='300' height='10' />
    <circle cx='35' cy='90' r='15' />
    <rect x='60' y='35' rx='0' ry='0' width='85' height='100' />
    <rect x='160' y='35' rx='0' ry='0' width='85' height='100' />
    <rect x='260' y='35' rx='0' ry='0' width='85' height='100' />
    <rect x='360' y='35' rx='0' ry='0' width='85' height='100' />
    <rect x='460' y='35' rx='0' ry='0' width='85' height='100' />
  </ContentLoader>
)

export const MapWithSupplierMarkersLoader = () => (
  <ContentLoader
    speed={2}
    width={800}
    height={1000}
    viewBox='0 0 800 1000'
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
  >
    <rect x='0' y='0' rx='0' ry='0' width='800' height='1000' />
  </ContentLoader>
)

export const ListOfSuppliersShownOnMapLoader = () => (
  <ContentLoader
    speed={2}
    width={600}
    height={800}
    viewBox='0 0 600 800'
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
  >
    <rect x='0' y='20' rx='2' ry='2' width='250' height='200' />
    <rect x='310' y='20' rx='2' ry='2' width='250' height='200' />
    <rect x='0' y='260' rx='2' ry='2' width='250' height='200' />
    <rect x='310' y='260' rx='2' ry='2' width='250' height='200' />
    <rect x='0' y='500' rx='2' ry='2' width='250' height='200' />
    <rect x='310' y='500' rx='2' ry='2' width='250' height='200' />
  </ContentLoader>
)

export const MiceMomentsExpandedViewLoader = () => (
  <ContentLoader
    speed={2}
    width={600}
    height={700}
    viewBox='0 0 600 700'
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
  >
    <rect x='30' y='60' rx='0' ry='0' width='260' height='200' />
    <rect x='30' y='10' rx='0' ry='0' width='520' height='30' />
    <rect x='30' y='280' rx='0' ry='0' width='520' height='30' />
    <rect x='290' y='60' rx='0' ry='0' width='260' height='200' />
    <rect x='30' y='330' rx='0' ry='0' width='355' height='200' />
    <rect x='410' y='330' rx='0' ry='0' width='140' height='200' />
    <rect x='30' y='550' rx='0' ry='0' width='515' height='80' />
  </ContentLoader>
)
