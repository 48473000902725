class CloudinaryUploadWidget {
  uploadWidget () {
    if (!this.widget) {
      if (window && window.cloudinary) {
        this.widget = window.cloudinary.createUploadWidget(
          {
            cloud_name: window.CLOUDINARY_CLOUD_NAME,
            uploadPreset: window.CLOUDINARY_UPLOAD_PRESET,
            sources: ['local', 'url', 'camera', 'image_search', 'facebook', 'dropbox', 'instagram'],
            googleApiKey: window.GOOGLE_API_KEY ? window.GOOGLE_API_KEY : '',
            showAdvancedOptions: false,
            cropping: false,
            multiple: false,
            defaultSource: 'local',
            styles: {
              palette: {
                window: '#FFFFFF',
                windowBorder: '#90A0B3',
                tabIcon: '#0078FF',
                menuIcons: '#5A616A',
                textDark: '#000000',
                textLight: '#FFFFFF',
                link: '#0078FF',
                action: '#FF620C',
                inactiveTabIcon: '#0E2F5A',
                error: '#F44235',
                inProgress: '#0078FF',
                complete: '#20B832',
                sourceBg: '#E4EBF1'
              },
              fonts: { default: { active: true } }
            }
          },
          (error, response) => {
            if (!error) {
              if (response.event === 'success' && this.uploadCompleteHandler) {
                this.uploadCompleteHandler(response.info)
              }
            } else {
              console.log('Error uploading image to cloudinary:', error)
            }
          }
        )
      } else {
        console.log('It was not possible to create Widget because cloudinary is not defined')
      }
    }

    return this.widget
  }

  open (uploadCompleteHandler, uploadPreset) {
    this.uploadCompleteHandler = uploadCompleteHandler
    this.uploadWidget().update({ uploadPreset: (uploadPreset || window.CLOUDINARY_UPLOAD_PRESET) })
    this.uploadWidget().open()
  }
}

export default CloudinaryUploadWidget
