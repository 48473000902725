import React from 'react'
import Header from '../Header/Header'

const MainVisualLayout = ({
  hideSearchBar,
  hideSupplierSearchBar,
  hideBreadcrumb,
  hideHeaderButtons,
  filterMode,
  suitable,
  locationId,
  children
}) => {
  const layoutContainerClasses = [
    'container-fluid',
    'mainLayoutContainer',
    'sticky-header--inactive',
    'supplier-card-mobile',
    'filter-bar-is-open'
  ]

  return (
    <>
      <Header
        hideSearchBar={hideSearchBar}
        suitable={suitable}
        locationId={locationId}
        hideBreadcrumb={hideBreadcrumb}
        filterMode={filterMode || 'suppliers'}
        hideSupplierSearchBar={hideSupplierSearchBar}
        hideHeaderButtons={hideHeaderButtons}
      />
      <div id='layoutContainer' className={layoutContainerClasses.join(' ')}>
        <div className='row'>
          <div className='content-wrapper contentWrapper'>{children}</div>
        </div>
      </div>
    </>
  )
}

export default MainVisualLayout
