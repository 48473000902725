import React from 'react'
import './SupplierTermsConditions.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import SupplierRates from '../SupplierRates/SupplierRates'
import SupplierBlackoutDates from '../SupplierBlackoutDates/SupplierBlackoutDates'
import SupplierPolicy from '../SupplierPolicy/SupplierPolicy'
import SupplierSpecialAgreement from '../SupplierSpecialAgreement/SupplierSpecialAgreement'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

class SupplierTermsConditions extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      validContracts: []
    }
    this.isContractValid = this.isContractValid.bind(this)
    this.getValidContracts = this.getValidContracts.bind(this)
    this.getContractPolicy = this.getContractPolicy.bind(this)
  }

  componentDidMount () {
    if (this.props.contracts) {
      this.setState({
        validContracts: this.getValidContracts(this.props.contracts)
      })
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.contracts !== prevProps.contracts) {
      if (this.props.contracts) {
        this.setState({
          validContracts: this.getValidContracts(this.props.contracts)
        })
      }
    }
  }

  isContractValid (contract) {
    const validFrom = new Date(contract.valid_from)
    const validUntil = new Date(contract.valid_until)
    const startDate = this.props.startDate

    return startDate >= validFrom && startDate <= validUntil
  }

  getValidContracts (contracts) {
    return contracts.filter(item => this.isContractValid(item))
  }

  getContractPolicy (locale) {
    const terms = this.state.validContracts[0][`cancellation_terms_${locale}`]
    if (terms && terms !== '') {
      return terms
    } else {
      return this.state.validContracts[0].master_contract[`cancellation_terms_${locale}`]
    }
  }

  render () {
    const { t, i18n } = this.props

    return this.state.validContracts && this.state.validContracts.length > 0 ? (
      <>
        <div className='c-content-media-1 supplierTermsConditionsBox'>
          <div className='c-content-label c-font-uppercase c-font-bold c-bg-regular'>
            {this.props.title ? this.props.title : 'Terms'}
          </div>
          <Tabs>
            <TabList>
              <Tab tabid='ratesTab'>{t('supplier.home.tabs.details.terms.tabs.rates.title')}</Tab>
              <Tab tabid='blackOutDatesTab'>{t('supplier.home.tabs.details.terms.tabs.blackout_dates.title')}</Tab>
              <Tab tabid='policyTab'>{t('supplier.home.tabs.details.terms.tabs.policy.title')}</Tab>
            </TabList>
            <TabPanel>
              <SupplierRates
                validity={{
                  from: this.state.validContracts[0][`formatted_valid_from_${i18n.language}`],
                  until: this.state.validContracts[0][`formatted_valid_until_${i18n.language}`]
                }}
                servicePackagePrices={this.state.validContracts[0].service_package_prices}
                prices={this.state.validContracts[0].prices}
                contractType={this.state.validContracts[0].contract_type}
                publicRates={this.state.validContracts[0].public_rates}
              />
            </TabPanel>
            <TabPanel>
              <SupplierBlackoutDates prices={this.state.validContracts[0].blackout_date_prices} />
            </TabPanel>
            <TabPanel>
              <SupplierPolicy
                heading={t('supplier.home.tabs.details.terms.tabs.policy.heading')}
                policy={this.getContractPolicy(i18n.language)}
              />
              {this.state.validContracts[0][`special_agreement_${i18n.language}`] ? (
                <SupplierSpecialAgreement
                  heading={t('supplier.home.tabs.details.terms.tabs.special_agreement.heading')}
                  specialAgreement={this.state.validContracts[0][`special_agreement_${i18n.language}`].split('\n')}
                />
              ) : null}
            </TabPanel>
          </Tabs>
        </div>
      </>
    ) : null
  }
}

SupplierTermsConditions.propTypes = {
  title: PropTypes.string,
  contracts: PropTypes.any,
  startDate: PropTypes.object.isRequired
}

export default withTranslation()(SupplierTermsConditions)
