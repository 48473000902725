import axios from 'axios'

export const fetchTrendlines = (page) => {
  const filters =
      `filters[page]=${page}` +
      `&filters[subdomain]=${window.CURRENT_SUBDOMAIN}`

  return axios
    .get(`trendline_sections.json?${filters}`)
    .then(response => response.data)
    .catch(error => {
      console.log('fetch trendline sections error', error)
    })
}
