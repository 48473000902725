import React from 'react'
import './NavigationCartMenu.scss'
import { Link, withRouter } from 'react-router-dom'
import MenuCartItem from '../MenuCartItem/MenuCartItem'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { redirectToSpecifiedUrl } from '../../redux/actions'
import { withTranslation } from 'react-i18next'
import { getSearchCompletePath } from '../../shared/utils'
import { extractQueryParams } from '../../services/urlStateParser'

class NavigationCartMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showMenu: false
    }
    this.handleCheckoutClick = this.handleCheckoutClick.bind(this)
    this.handleShowMenu = this.handleShowMenu.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleCheckoutClick () {
    const searchLocation = this.props.searchLocation
    this.props.redirectToSpecifiedUrl(getSearchCompletePath({ searchLocation }, this.props.queryParams))
  }

  handleShowMenu () {
    this.setState({ showMenu: true })
  }

  handleBlur (e) {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      this.setState({ showMenu: false })
    }
  }

  render () {
    const { t, cartItems } = this.props
    const params = this.props.location.search || '?'
    return (
      <div onBlur={this.handleBlur} ref={cartMenuNode => (this.cartMenuNode = cartMenuNode)}>
        <Link aria-label={t('cart')} onFocus={this.handleShowMenu} to={`/cart/${params}`} className='c-btn-icon c-cart-toggler cartIconButton'>
          <i className='fa fa-shopping-bag ml-2' />{' '}
          <span className={`c-cart-number client-theme--color bg-white ${cartItems && cartItems.length > 0 ? 'c-cart-number-active' : ''}`} aria-hidden='true'>
            {cartItems && cartItems.length ? cartItems.length : 0}
          </span>
        </Link>
        <div
          role='menu'
          aria-labelledby='cart menu'
          className={`c-cart-menu ${this.state.showMenu ? 'show-menu' : ''}`}
          id='cart-menu'
          style={{
            zIndex: 10,
            boxShadow: 'hsl(0, 0%, 80%) 0 0px 16px',
            borderRadius: '17px'
          }}
        >
          <h1>{t('cart_title')}</h1>
          <ul className='c-cart-menu-items'>
            {cartItems && cartItems.length > 0 ? (
              cartItems.map((item, idx) => <MenuCartItem key={idx} item={item} />)
            ) : (
              <li>
                <div className='emptyWalletText'>{t('cart_is_empty')}</div>
              </li>
            )}
          </ul>
          <div className='c-cart-menu-footer'>
            <Link tabIndex='0' to={`/cart/${params}`} className='btn btn-md c-btn c-btn-square c-btn-grey-3 c-font-white c-font-bold c-center c-font-uppercase viewCartButton noBorderRadius'>
              {t('view_cart')}
            </Link>

            <button
              tabIndex='0'
              onBlur={this.handleHideCartMenu}
              onClick={this.handleCheckoutClick}
              className='btn btn-md c-btn c-btn-square c-theme-btn c-font-white c-font-bold c-center c-font-uppercase noBorderRadius client-theme--btn-bg client-theme--border-color'
            >
              {this.props.searchSession ? t('add_to_meeting') : t('create_meeting')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.app.isLoggedIn,
    user: state.app.user,
    searchSession: state.app.searchSession,
    searchLocation: state.app.searchLocation,
    queryParams: extractQueryParams(ownProps),
    cartItems: state.app.cartItems
  }
}

const mapDispatchToProps = dispatch => {
  return {
    redirectToSpecifiedUrl: payload => dispatch(redirectToSpecifiedUrl(payload))
  }
}

NavigationCartMenu.propTypes = {
  cartItems: PropTypes.array,
  isLoggedIn: PropTypes.bool,
  user: PropTypes.object,
  searchParams: PropTypes.object,
  searchSession: PropTypes.object,
  redirectToSpecifiedUrl: PropTypes.func,
  t: PropTypes.func,
  searchLocation: PropTypes.object
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NavigationCartMenu)))
