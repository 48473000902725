import React from 'react'
import Select from 'react-select'
import axios from 'axios'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

class SupplierNew extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedOptions: [this.options()[0]]
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  options () {
    const { t } = this.props
    return [
      { label: t('categories.supplierhotel'), value: 'hotel' },
      { label: t('categories.supplierlocation'), value: 'location' },
      { label: t('categories.suppliertransfer'), value: 'transfer' },
      { label: t('categories.suppliercatering'), value: 'catering' },
      { label: t('categories.supplierpersonalservice'), value: 'personal_service' },
      { label: t('categories.suppliereventfacility'), value: 'event_facility' },
      { label: t('categories.suppliereventgastronomy'), value: 'event_gastronomy' }
    ]
  }

  handleChange (value) {
    this.setState({ selectedOptions: [value] })
  }

  createNewDeliverable () {
    const supplierId = this.props.match.params.objectID
    const deliverableType = this.state.selectedOptions[0].value

    axios
      .post(`/suppliers/${supplierId}/deliverables.json`, { deliverable_type: deliverableType })
      .then(response => {
        this.props.history.push({
          pathname: `/suppliers/${supplierId}/edit/${deliverableType}`
        })
      })
      .catch(error => {
        console.log('Error while creating supplier', error)
      })
  }

  createNewSupplier () {
    const deliverableType = this.state.selectedOptions[0].value

    axios
      .post('/suppliers.json', { deliverable_type: deliverableType })
      .then(response => {
        const supplierId = response.data.id

        this.props.history.push({
          pathname: `/suppliers/${supplierId}/edit/${deliverableType}`
        })
      })
      .catch(error => {
        alert('Error while creating supplier') // eslint-disable-line
        console.log('Error while creating supplier', error)
      })
  }

  handleSubmit () {
    if (this.props.match.params.objectID) {
      this.createNewDeliverable()
    } else {
      this.createNewSupplier()
    }
  }

  render () {
    const { t } = this.props
    return (
      <div>
        <div className='container'>
          <div className='c-content-title-1'>
            <h3 className='c-center c-font-lowercase'>
              <span style={{ fontSize: '68px', fontWeight: 700 }}>1</span>
              <span>{t('supplier.new.title')}</span>
            </h3>
            <div className='c-line-center c-theme-bg' />
          </div>

          <div className='c-content-feature-2-grid'>
            <div className='row'>
              <div className='col-md-6 offset-3'>
                <center>
                  <Select
                    id='chooseDeliverable'
                    className='defaultSelectDropdown'
                    classNamePrefix='defaultSelectDropdown'
                    options={this.options()}
                    isSearchable={false}
                    isMulti={false}
                    onChange={this.handleChange}
                    value={this.state.selectedOptions}
                    style={{ margin: '6px 0' }}
                  />
                  <a
                    className='c-feature-15-btn btn c-btn btn-lg c-theme-btn c-font-uppercase c-font-bold c-btn-square'
                    style={{ marginTop: '20px', marginBottom: '20px', color: '#fff' }}
                    href='#'
                    onClick={this.handleSubmit}
                  >{t('common.create')}
                  </a>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SupplierNew.propTypes = {
  supplier: PropTypes.object
}

export default withTranslation()(SupplierNew)
