import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { TextInput } from '../../../../FormElements/FormElements'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'react-i18next'
import ButtonWithIcon from '../../../../ButtonWithIcon/ButtonWithIcon'
import axios from 'axios'
import SuccessMessage from '../../../../SuccessMessage/SuccessMessage'
import ValidationErrors from '../../../../ValidationErrors/ValidationErrors'
import { scrollToRef } from '../../../../../shared/utils'
const _ = require('lodash')

class AddressesForm extends Component {
  constructor (props) {
    super(props)

    this.componentRef = React.createRef()

    const { t } = props

    this.supplierAttributes = [
      'id',
      'invoice_name',
      'invoice_street1',
      'invoice_street2',
      'invoice_post_code',
      'invoice_city',
      'contract_name',
      'contract_street1',
      'contract_street2',
      'contract_post_code',
      'contract_city',
      'contract_country',
      'vat_number',
      'invoice_vat_number',
      'commercial_register_number',
      'land_registration_number'
    ]

    this.state = {
      showSuccessMessage: false,
      isSubmiting: false,
      errors: {},
      supplier: {},
      saveButtonDisabled: true,
      resetButtonIcon: 'fa fa-refresh',
      resetButtonLabel: t('supplier.edit.button.reset.label'),
      unsavedChanges: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  componentDidMount () {
    const supplier = {}
    this.supplierAttributes.forEach((supplierAttribute) => {
      supplier[supplierAttribute] = this.props.supplier[supplierAttribute]
    })

    this.setState({ supplier })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.supplier !== this.props.supplier) {
      this.initializeStateWithExistingFormData()
    }

    if (!this.state.unsavedChanges && !_.isEmpty(prevState.supplier) && JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.handleUnsavedChanges(prevState)
    }
  }

  initializeStateWithExistingFormData () {
    if (this.props.supplier) {
      const supplier = {}
      this.supplierAttributes.forEach((supplierAttribute) => {
        supplier[supplierAttribute] = this.props.supplier[supplierAttribute]
      })

      this.setState({ supplier })
    }
  }

  handleUnsavedChanges (prevState) {
    // This check is to make sure to not detect changing the unsavedChanges attribute as unsaved changes by the user
    // So in this case it means that the user save his changes and the unsavedChanges attribute returned to false
    // Then we don't need to disable the tabs
    if (!prevState.unsavedChanges) {
      this.setState({ unsavedChanges: true, saveButtonDisabled: false })
      this.props.updateHandler(null, 'SettingsPage')
    }
  }

  handleChange ({ target }) {
    const value = target.value
    const name = target.name

    this.setState({
      showSuccessMessage: false,
      supplier: {
        ...this.state.supplier,
        [name]: value
      }
    })
  }

  handleReset () {
    const { t } = this.props

    this.setState({
      errors: {},
      saveButtonDisabled: true,
      resetButtonIcon: 'fa fa-spinner fa-spin',
      resetButtonLabel: t('supplier.edit.button.reset.reseting_label')
    })
    this.props.resetHandler()

    setTimeout(() => this.setState({
      resetButtonIcon: 'fa fa-refresh',
      resetButtonLabel: t('supplier.edit.button.reset.label'),
      unsavedChanges: false
    }), 2000)
  }

  handleSubmit () {
    this.setState({
      errors: {},
      isSubmiting: true,
      showSuccessMessage: false
    }, function () {
      axios
        .put(`/suppliers/${this.props.supplier.id}.json`, { supplier: this.state.supplier, facet: 'invoice_data', locale: this.props.i18n.language })
        .then(response => {
          scrollToRef(this.componentRef)
          this.setState({ showSuccessMessage: true, isSubmiting: false })
          this.props.updateHandler(response.data, null)
          this.setState({ unsavedChanges: false, saveButtonDisabled: true })
        })
        .catch(error => {
          switch (error.response.status) {
            case 403:
              window.location.href = '/pages/access_denied'
              break
            case 422:
              scrollToRef(this.componentRef)
              this.setState({ errors: error.response.data.errors, isSubmiting: false })
              break
          }
        })
    })
  }

  render () {
    const { t } = this.props

    return (
      <div ref={this.componentRef}>
        <Row className='mt-0 mb-2'>
          <Col xs={12}>
            <h4>{t('invoice_address')}</h4>
          </Col>
        </Row>

        {this.props.enableUnsavedChangesTracker && this.state.unsavedChanges ? (
          <Col className='p-0 d-flex justify-content-center align-items-center'>
            <ButtonWithIcon
              labelColor='#FFFFFF'
              iconColor='#FFFFFF'
              padding='12px'
              icon={this.state.resetButtonIcon}
              label={this.state.resetButtonLabel}
              className='btn-danger c-btn-square c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold'
              onClick={this.handleReset}
              disabled={this.state.saveButtonDisabled || this.state.isSubmiting}
            />
          </Col>
        ) : null}

        <SuccessMessage message={t('changes_saved')} hidden={!this.state.showSuccessMessage} />
        <ValidationErrors validationErrors={this.state.errors} />

        <Row className='mt-0 mb-2'>
          <Col xs={12}>
            <TextInput
              name='invoice_name'
              error={this.state.errors.invoice_name}
              value={this.state.supplier.invoice_name}
              label={t('invoice_field', { field: t('name') })}
              onChange={this.handleChange}
              placeholder={t('name_placeholder')}
            />
          </Col>
        </Row>
        <Row className='mt-2 mb-2'>
          <Col sm={6} xs={12}>
            <TextInput
              name='invoice_street1'
              error={this.state.errors.invoice_street1}
              value={this.state.supplier.invoice_street1}
              label={t('invoice_field', { field: t('address_1') })}
              onChange={this.handleChange}
              placeholder={t('address_1_placeholder')}
            />
          </Col>
          <Col sm={6} xs={12}>
            <TextInput
              name='invoice_post_code'
              error={this.state.errors.invoice_post_code}
              value={this.state.supplier.invoice_post_code}
              label={t('invoice_field', { field: t('zip_code') })}
              onChange={this.handleChange}
              placeholder={t('zip_code_placeholder')}
            />
          </Col>
        </Row>
        <Row className='mt-0 mb-2'>
          <Col sm={6} xs={12}>
            <TextInput
              name='invoice_street2'
              error={this.state.errors.invoice_street2}
              value={this.state.supplier.invoice_street2}
              label={t('invoice_field', { field: t('address_2') })}
              onChange={this.handleChange}
              placeholder={t('address_2_placeholder')}
            />
          </Col>
          <Col sm={6} xs={12}>
            <TextInput
              name='invoice_city'
              error={this.state.errors.invoice_city}
              value={this.state.supplier.invoice_city}
              label={t('invoice_field', { field: t('city') })}
              onChange={this.handleChange}
              placeholder={t('city_placeholder')}
            />
          </Col>
        </Row>
        <Row className='mt-0 mb-2'>
          <Col sm={6} xs={12}>
            <TextInput
              name='invoice_vat_number'
              error={this.state.errors.invoice_vat_number}
              value={this.state.supplier.invoice_vat_number}
              label={t('invoice_vat_number')}
              onChange={this.handleChange}
              tooltip={<Trans i18nKey='vat_number_info' />}
            />
          </Col>
        </Row>
        <Row className='mt-5 mb-2'>
          <Col xs={12}>
            <h4>{t('contract_field.operating_company')}</h4>
          </Col>
        </Row>
        <Row className='mt-0 mb-2'>
          <Col xs={12}>
            <TextInput
              name='contract_name'
              error={this.state.errors.contract_name}
              value={this.state.supplier.contract_name}
              label={t('contract_field.operating_company_name')}
              onChange={this.handleChange}
              placeholder={t('name_placeholder')}
            />
          </Col>
        </Row>
        <Row className='mt-2 mb-2'>
          <Col sm={6} xs={12}>
            <TextInput
              name='contract_street1'
              error={this.state.errors.contract_street1}
              value={this.state.supplier.contract_street1}
              label={t('contract_field.address_addition')}
              onChange={this.handleChange}
              placeholder={t('address_1_placeholder')}
            />
          </Col>
          <Col sm={6} xs={12}>
            <TextInput
              name='contract_post_code'
              error={this.state.errors.contract_post_code}
              value={this.state.supplier.contract_post_code}
              label={t('contract_field.post_code')}
              onChange={this.handleChange}
              placeholder={t('zip_code_placeholder')}
            />
          </Col>
        </Row>
        <Row className='mt-0 mb-2'>
          <Col sm={6} xs={12}>
            <TextInput
              name='contract_street2'
              error={this.state.errors.contract_street2}
              value={this.state.supplier.contract_street2}
              label={t('contract_field.contract_street')}
              onChange={this.handleChange}
              placeholder={t('address_2_placeholder')}
            />
          </Col>
          <Col sm={6} xs={12}>
            <TextInput
              name='contract_city'
              error={this.state.errors.contract_city}
              value={this.state.supplier.contract_city}
              label={t('contract_field.city')}
              onChange={this.handleChange}
              placeholder={t('city_placeholder')}
            />
          </Col>
        </Row>
        <Row className='mt-0 mb-2'>
          <Col sm={6} xs={12}>
            <TextInput
              name='contract_country'
              error={this.state.errors.contract_country}
              value={this.state.supplier.contract_country}
              label={t('contract_field.country')}
              onChange={this.handleChange}
              placeholder={t('country_placeholder')}
            />
          </Col>
          <Col sm={6} xs={12}>
            <TextInput
              name='vat_number'
              error={this.state.errors.vat_number}
              value={this.state.supplier.vat_number}
              label={t('vat_number')}
              onChange={this.handleChange}
              tooltip={<Trans i18nKey='vat_number_info' />}
            />
          </Col>
        </Row>
        <Row className='mt-0 mb-2'>
          <Col sm={6} xs={12}>
            <TextInput
              name='commercial_register_number'
              error={this.state.errors.commercial_register_number}
              value={this.state.supplier.commercial_register_number}
              label={t('commercial_register_number')}
              onChange={this.handleChange}
              tooltip={<Trans i18nKey='commercial_register_number_info' />}
            />
          </Col>
          <Col sm={6} xs={12}>
            <TextInput
              name='land_registration_number'
              error={this.state.errors.land_registration_number}
              value={this.state.supplier.land_registration_number}
              label={t('land_registration_number')}
              onChange={this.handleChange}
              tooltip={
                <Trans
                  i18nKey='land_registration_number_info'
                  components={[<a href={`https://e-justice.europa.eu/109/${this.props.i18n.language}/land_registers_in_eu_countries?init=true`} key={0} style={{ color: 'blue' }} target='_blank' rel='noopener noreferrer' />]}
                />
              }
            />
          </Col>
        </Row>
        <Row className='m-0 mt-4 mb-4'>
          <Col className='pl-0' xl={6} md={6} sm={8} xs={12}>
            <ButtonWithIcon
              icon={`fa ${this.state.isSubmiting ? 'fa-spinner fa-spin' : 'fa-save'}`}
              label={t('update_invoice_information')}
              className='client-theme--font-color client-theme--bg'
              onClick={this.handleSubmit}
              disabled={this.state.saveButtonDisabled || this.state.isSubmiting}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

AddressesForm.propTypes = {
  supplier: PropTypes.object.isRequired
}

export default withTranslation()(AddressesForm)
