import './NoResultsNote.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SearchContext } from '../../contexts/SearchContext'

function NoResultsNote ({ limit, context }) {
  const { momentHitsCount, supplierHitsCount } = React.useContext(SearchContext)
  const { i18n } = useTranslation()
  const lang = i18n.language

  const nHits = (context === 'moments')
    ? momentHitsCount
    : supplierHitsCount

  if (nHits > limit) {
    return null
  } else if (nHits < 0) {
    return (
      <aside className='NoResultsNote inProgress'>
        {lang === 'de' ? <p>Initialisierung...</p> : <p>Initializing...</p>}
      </aside>
    )
  } else {
    /* eslint-disable *//* -- eslint demands some very strange things with indentation :( */
    return (
      <aside className='NoResultsNote'>
        { lang === 'de' ? (
          <>
            <p>
              Ups... es gibt { nHits > 0 ? 'nicht viele' : 'keine' } Ergebnisse,
              die mit Ihrer Anfrage übereinstimmen.
            </p>
            <p>
              Tipp #1: Oftmals hilft es die Suchfilter zu entfernen, z.B. Zimmer oder Konferenzräume.
            </p>
            <p>
              Tipp #2: Sie können zudem aus der Karte herauszoomen oder in einer anderen Region suchen.
            </p>
            <p>
              Tipp #3: Wenn Sie einen bestimmten Anbieter nicht finden, können Sie die Registrierung hier beantragen:
              <a href="https://sites.miceportal.com/dienstleister-registrieren" target="_blank" rel="noopener noreferrer">
                https://sites.miceportal.com/dienstleister-registrieren
              </a>
            </p>
          </>
        ) : (
          <>
            <p>
              There are { nHits > 0 ? 'very few' : 'no' } results matching your query.
            </p>
            <p>
              Tipp #1: It often helps to remove the search filters, e.g. rooms or conference rooms.
            </p>
            <p>
              Tipp #2: You can also zoom out of the map or search in a different region.
            </p>
            <p>
              Tipp #3: If you do not find a specific provider,
              you can request registration here,
              on <a href="https://sites.miceportal.com/en/dienstleister-registrieren" target="_blank" rel="noopener noreferrer">
                https://sites.miceportal.com/en/dienstleister-registrieren
              </a>.
            </p>
          </>
        )}
      </aside>
    )
    /* eslint-enable */
  }
}

NoResultsNote.propTypes = {
  limit: PropTypes.number.isRequired,
  context: PropTypes.oneOf(['moments', 'suppliers']).isRequired
}

export default NoResultsNote
