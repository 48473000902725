import React from 'react'
import './SupplierNotes.scss'
import SupplierNote from '../SupplierNote/SupplierNote'
import Can from '../Layout/Can'
import { wrapAsAnInstanceOf } from '../../config/ability'
var dayjs = require('dayjs')
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
const NOTE_TYPES = ['blacklist', 'note', 'compliance_comment']

class SupplierNotes extends React.Component {
  isNoteValid (note) {
    if (!note) return false
    if (note.permanent) return true

    const now = dayjs.utc()
    const validFrom = dayjs.utc(note.valid_from)
    const validUntil = dayjs.utc(note.valid_until)

    return now.isBetween(validFrom, validUntil, 'day', '[]')
  }

  getNotes () {
    const { notes } = this.props
    const allNotes = []

    if (notes) {
      return allNotes.concat(notes[window.CURRENT_SUBDOMAIN] || []).concat(notes.general || [])
    }
    return allNotes
  }

  render () {
    const notes = this.getNotes()
    return NOTE_TYPES.map((noteType) => {
      const note = notes.find(note => (note.type === noteType && this.isNoteValid(note)))

      if (note) {
        return (
          <Can key={noteType} perform='read' on={wrapAsAnInstanceOf(note, 'Note')}>
            <SupplierNote
              supplierId={this.props.supplierId}
              note={note}
            />
          </Can>
        )
      }
    })
  }
}
export default SupplierNotes
