import React from 'react'
import './NavigationActionButtons.scss'
import { withTranslation } from 'react-i18next'
import { withRouter, Link } from 'react-router-dom'
import LanguageSelector from '../LanguageSelector/LanguageSelector'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getAccountUrl } from '../../shared/utils'

class NavigationActionButtons extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showMenu: false
    }
    this.accountUrl = this.accountUrl.bind(this)
    this.canAccessDashboard = this.canAccessDashboard.bind(this)
    this.handleShowMenu = this.handleShowMenu.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  accountUrl () {
    const { user } = this.props
    return getAccountUrl(user)
  }

  getReturnUrl () {
    const { pathname, search, hash } = this.props.location
    if (pathname && pathname !== '/') {
      return window.encodeURIComponent(`${pathname}${search}${hash}`)
    }

    return `/home?locale=${this.props.i18n.language}`
  }

  canAccessDashboard () {
    const user = this.props.user
    return user && (user.is_client_user || user.roles_symbols.includes('employee'))
  }

  handleShowMenu () {
    this.setState({ showMenu: true })
  }

  handleBlur (e) {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      this.setState({ showMenu: false })
    }
  }

  render () {
    const { t, user, i18n } = this.props

    return (
      <div className='c-topbar c-topbar-dark transition'>
        <nav className='c-top-menu topMenu' style={{ position: 'relative', top: '8px' }}>
          <ul onBlur={this.handleBlur} ref={userMenuNode => (this.userMenuNode = userMenuNode)} className='c-ext c-theme-ul client-theme--font-color'>
            <li className='c-lang dropdown c-last'>
              {this.props.isLoggedIn ? (
                <>
                  <Link onFocus={this.handleShowMenu} to='' className='client-theme--font-color' aria-label={t('my_account')}>
                    <i className='fas fa-user-circle' style={{ fontSize: '2rem' }} aria-label={t('my_account')} />
                  </Link>
                  <ul
                    className={`dropdown-menu pull-right ${this.state.showMenu ? 'show-menu' : ''}`}
                    role='menu'
                  >
                    {user && (
                      <li className='active'>
                        <a>{user.full_name}</a>
                      </li>
                    )}

                    <li>
                      {/^https?:\/\//.test(this.accountUrl()) ? (
                        <a href={this.accountUrl()}>
                          <i className='fa fa-cog' />{' '}
                              MICE Portal
                        </a>
                      ) : (
                        <a href={this.accountUrl()} className='c-btn-link'>
                          <i className='fa fa-cog' />{' '}
                          {t('my_account')}
                        </a>
                      )}
                    </li>

                    {
                      this.canAccessDashboard() && (
                        <li>
                          <a href={user.is_client_user ? '/home' : '/administration'}>
                            <i className='fa fa-dashboard' />{' '}
                            Dashboard
                          </a>
                        </li>
                      )
                    }

                    <li>
                      <a href='/logout' className='dropdown-item c-btn-link'>
                        <i className='text-danger fa fa-unlock-alt' title={t('logout')} />{' '} {t('logout')}
                      </a>
                    </li>
                  </ul>
                </>
              ) : (
                <a aria-label={t('aria_login')} href={`/login?locale=${i18n.language}&return_url=${this.getReturnUrl()}`}>
                  <i className='fas fa-user-circle' style={{ fontSize: '2rem' }} />
                </a>
              )}
            </li>
          </ul>
          <LanguageSelector />
        </nav>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.app.isLoggedIn,
    user: state.app.user,
    searchSession: state.app.searchSession
  }
}

NavigationActionButtons.propTypes = {
  isLoggedIn: PropTypes.bool,
  user: PropTypes.object,
  t: PropTypes.func,
  searchSession: PropTypes.object,
  history: PropTypes.object
}

export default connect(mapStateToProps)(withTranslation()(withRouter(NavigationActionButtons)))
