import React, { Component } from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import PropTypes from 'prop-types'
import './StartPage.scss'
import TrendlineSection from '../TrendlineSection/TrendlineSection'
import { fetchTrendlines } from '../../resources/requests'
import StructuredDataGenerator from '../../services/structuredDataSchema'
import { HomepageTrendlineSectionLoader } from '../../shared/contentLoaders'
import BannerAd from '../BannerAd/BannerAd'
import HashTags from '../HashTags/HashTags'
import { fetchTopHashtagsFromAlgolia, getSearchCompletePath } from '../../shared/utils'
import { connect } from 'react-redux'
import { extractQueryParams } from '../../services/urlStateParser'
import queryString from 'qs'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import SearchBar from '../SearchBar/SearchBar'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import { redirectToSpecifiedUrl } from '../../redux/actions'
import graphicMeeting from '../../assets/images/start_page/startpage_graphic_meeting.svg'
import graphicWizard from '../../assets/images/start_page/startpage_graphic_wizard.svg'
import _ from 'lodash'

class StartPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sections: {},
      trendlines: [],
      topHashtags: [],
      windowWidth: (window && window.innerWidth) || 768
    }
    this.hashtagHrefCallback = this.hashtagHrefCallback.bind(this)
    this.pathToResultPageWithParams = this.pathToResultPageWithParams.bind(this)
    this.handleCheckoutClick = this.handleCheckoutClick.bind(this)
    this.handleWindowResize = this.handleWindowResize.bind(this)
    this.handleWindowResizeThrottled = _.throttle(this.handleWindowResize, 300)
    this.handleBookEventClick = this.handleBookEventClick.bind(this)
  }

  handleWindowResize () {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidMount () {
    this.fetchTrendlineData()
    this.getTrendingTags()
    window.addEventListener('resize', this.handleWindowResizeThrottled)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleWindowResizeThrottled)
  }

  setStructuredData () {
    if (this.locale !== this.props.i18n.language) {
      StructuredDataGenerator.forStartPage()
      this.locale = this.props.i18n.language
    }
  }

  async fetchTrendlineData () {
    fetchTrendlines('start-page')
      .then((data) => {
        this.setState(() => ({
          trendlines: data
        }))
      })
  }

  trendlinesData (section) {
    return this.state.trendlines.filter(t => t.page_slot === section)[0] || {}
  }

  hashtagHrefCallback (hashtagName) {
    return this.pathToResultPageWithParams({ query: hashtagName }, '/moments')
  }

  getTopHashtags () {
    const lat = (this.props.searchLocation && this.props.searchLocation.lat) || 52.52
    const lng = (this.props.searchLocation && this.props.searchLocation.lng) || 13.41
    return fetchTopHashtagsFromAlgolia(lat, lng).then(topHashtags => (topHashtags), error => (error))
  }

  pathToResultPageWithParams (params, pathname) {
    const { location, lqt, region, type, locale } = this.props.searchParams
    const startDate = this.props.searchParams.start_date

    return {
      pathname: (pathname || '/results'),
      search: queryString.stringify({ location, lqt, region, type, locale, start_date: startDate, show_map: true, ...params })
    }
  }

  handleCheckoutClick () {
    this.props.redirectToSpecifiedUrl(
      getSearchCompletePath({ searchLocation: this.props.searchLocation }, this.props.searchParams)
    )
  }

  handleBookEventClick () {
    this.props.history.push(this.pathToResultPageWithParams({}, '/moments'))
  }

  renderWizardBoxContent (t) {
    return (
      <Row className='m-0 container-md'>
        <Col className='m-auto p-4' xs={12} xl={8}>
          <div className='w-100 d-flex justify-content-center align-items-center mt-4'>
            <ButtonWithIcon
              id='gtm-startPageWizardStart'
              labelColor='#FFFFFF'
              padding='6px 32px'
              label={t('start_page.box_wizard.button_label')}
              className='client-theme--bg text-white'
              onClick={this.handleCheckoutClick}
            />
          </div>
        </Col>
        <Col className='m-auto' xs={12}>
          <div className='d-flex justify-content-center align-items-center graphic-container'>
            <img className='startpage-graphic client-theme--color' src={graphicWizard} alt='' />
          </div>
        </Col>
      </Row>
    )
  }

  getTrendingTags () {
    axios
      .get('/admin/tags/trending_tags.json')
      .then((response) => {
        this.setState({
          topHashtags: response.data
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({ topHashtags: [] })
      })
  }

  render () {
    const { t } = this.props
    return (
      <div className='c-layout-page c-start-page'>
        <div
          className='containerFluid'
        >
          <BannerAd position='home' />
          <Row className='mr-0 ml-0'>
            <Col xs={{ size: 12 }}>
              <h1 className='c-header-title'>
                {t('start_page.header.new_title')}
              </h1>
            </Col>
            <Col className='pr-0 pl-0' xs={{ size: 12, offset: 0 }}>
              <HashTags values={this.state.topHashtags} hrefCallback={this.hashtagHrefCallback} className='mainNavigation' />
            </Col>
          </Row>
          <Row className='mr-0 ml-0 box-card-container'>
            <div className='container-xxl'>
              <Col className='pl-0 or-left' xs={12} md={6}>
                <div className='box-card'>
                  <div className='header overlay-filter client-theme--bg'>
                    <h2 className='title'>{t('start_page.box_search.title')}</h2>
                    <h3 className='sub-title'>{t('start_page.box_search.subtitle')}</h3>
                  </div>
                  <div className='box-body'>
                    <Tabs defaultIndex={0}>
                      <TabList>
                        <Tab className='react-tabs__tab client-theme--border-color client-theme--color'>{t('start_page.tab_titles.suppliers')}</Tab>
                        <Tab className='react-tabs__tab client-theme--border-color client-theme--color'>{t('start_page.tab_titles.moments')}</Tab>
                        {this.state.windowWidth < 768 ? (<Tab className='react-tabs__tab client-theme--border-color client-theme--color'>{t('start_page.tab_titles.wizard')}</Tab>) : null}
                      </TabList>
                      <TabPanel>
                        <div className='w-100 p-4'>
                          <SearchBar resultsFrom='suppliers' searchInputPlaceholder={t('search_here_location')} hideSupplierSearchBar />
                        </div>
                        <div className='d-flex justify-content-center align-items-center graphic-container'>
                          <img className='startpage-graphic client-theme--color' src={graphicMeeting} alt='' />
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className='w-100 p-4'>
                          <SearchBar resultsFrom='moments' searchInputPlaceholder={t('search_here_location')} hidePlaceSearch hideSupplierSearchBar />
                        </div>
                        <div className='d-flex justify-content-center align-items-center graphic-container'>
                          <img className='startpage-graphic client-theme--color' src={graphicMeeting} alt='' />
                        </div>
                      </TabPanel>
                      {this.state.windowWidth < 768 ? (
                        <TabPanel>
                          {this.renderWizardBoxContent(t)}
                        </TabPanel>) : null}
                    </Tabs>
                  </div>
                </div>
              </Col>
              <div className='or-separator'>{t('start_page.or')}</div>
              <Col className='d-none d-md-block pr-0 or-right' xs={12} md={6}>
                <div className='box-card'>
                  <div className='header overlay-filter client-theme--bg'>
                    <h2 className='title'>{t('start_page.box_wizard.title')}</h2>
                    <h3 className='sub-title'>{t('start_page.box_wizard.subtitle')}</h3>
                  </div>
                  <div className='box-body'>
                    <Tabs defaultIndex={0}>
                      <TabList>
                        <Tab className='react-tabs__tab client-theme--border-color client-theme--color'>{t('start_page.tab_titles.wizard')}</Tab>
                      </TabList>
                      <TabPanel>
                        {this.renderWizardBoxContent(t)}
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </Col>
            </div>
          </Row>
        </div>

        {this.state.trendlines && this.trendlinesData('top') ? (
          <TrendlineSection
            trendline={this.trendlinesData('top')}
          />
        ) : <HomepageTrendlineSectionLoader />}
        <div className=' how-it-works-section'>
          <div className='header-container' xs={12}>
            <div className='title'>{t('start_page.hiw.title')}</div>
            <div className='sub-title'>{t('start_page.hiw.subtitle')}</div>
          </div>
          <Row className='mr-0 ml-0'>
            <Col className='d-none d-md-block pl-0 pr-0' xs={12} md={6}>
              <div className='hiw-image-container'>
                <img className='hiw-image client-theme--color' src={graphicWizard} />
              </div>
            </Col>
            <Col className='pl-0 pr-0' xs={12} md={6}>
              <ol className='hiw-list suppliers-list'>
                <li>{t('start_page.hiw.create_event.steps.one')}</li>
                <li>{t('start_page.hiw.create_event.steps.two')}</li>
                <li>{t('start_page.hiw.create_event.steps.three')}</li>
                <li>{t('start_page.hiw.create_event.steps.four')}</li>
              </ol>
            </Col>
            <Col className='pl-0 pr-0 d-flex justify-content-center align-items-center' xs={12}>
              <div className='hiw-button-container'>
                <ButtonWithIcon
                  labelColor='#FFFFFF'
                  padding='6px 32px'
                  label={t('start_page.hiw.create_event.button_label')}
                  className='client-theme--bg text-white'
                  onClick={this.handleCheckoutClick}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '130px', marginBottom: '80px' }} className='mr-0 ml-0'>
            <Col className='pl-0 pr-0' xs={12} md={6}>
              <ol className='hiw-list moments-list'>
                <li>{t('start_page.hiw.book_event.steps.one')}</li>
                <li>{t('start_page.hiw.book_event.steps.two')}</li>
                <li>{t('start_page.hiw.book_event.steps.three')}</li>
                <li>{t('start_page.hiw.book_event.steps.four')}</li>
              </ol>
            </Col>
            <Col className='d-none d-md-block' xs={12} md={6}>
              <div className='hiw-image-container'>
                <img className='hiw-image client-theme--color' src={graphicMeeting} alt='' />
              </div>
            </Col>
            <Col className='pl-0 pr-0 d-flex justify-content-center align-items-center' xs={12}>
              <div className='hiw-button-container'>
                <ButtonWithIcon
                  labelColor='#FFFFFF'
                  padding='6px 32px'
                  label={t('start_page.hiw.book_event.button_label')}
                  className='client-theme--bg text-white'
                  onClick={this.handleBookEventClick}
                />
              </div>
            </Col>
          </Row>
        </div>

        {this.state.trendlines && this.trendlinesData('bottom') ? (
          <TrendlineSection
            trendline={this.trendlinesData('bottom')}
          />
        ) : <HomepageTrendlineSectionLoader />}
      </div>
    )
  }
}

StartPage.propTypes = {
  t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => {
  return {
    searchLocation: state.app.searchLocation,
    searchParams: extractQueryParams(ownProps)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    redirectToSpecifiedUrl: payload => dispatch(redirectToSpecifiedUrl(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(StartPage)))
