import React from 'react'
import PropTypes from 'prop-types'
import { CustomMarker } from 'react-instantsearch-dom-maps'
import { cloudinaryImageUrl } from '../../shared/cloudinaryUtils'
import './MomentMapMarker.scss'
import DistanceDurationBox from '../DistanceDurationBox/DistanceDurationBox'

export const MomentMapMarker = ({ hit, highlighted, handleClick, searchLocation }) => {
  const [distance, setDistance] = React.useState(null)
  const [duration, setDuration] = React.useState(null)
  const [showsDistance, setShowsDistance] = React.useState(false)
  const itemImage = hit.details ? cloudinaryImageUrl(hit.details.cloudinary_id) : null
  const itemStyle = itemImage ? { backgroundImage: `url("${itemImage}")` } : {}
  const handleMouseEnter = () => {
    if (!showsDistance) {
      setDirectionData()
    }
  }

  const setDirectionData = () => {
    const directionService = new window.google.maps.DistanceMatrixService()
    const origin = new window.google.maps.LatLng(hit._geoloc.lat, hit._geoloc.lng)
    const destination = new window.google.maps.LatLng(searchLocation.lat, searchLocation.lng) /* FIXME: <-- This line triggers 'Uncaught TypeError: c is null' (sometimes? And what is 'c'?) */
    return directionService.getDistanceMatrix({
      origins: [origin],
      destinations: [destination],
      travelMode: 'DRIVING'
    }, (result, status) => {
      if (status === 'OK') {
        if (result && result.rows && result.rows.length > 0) {
          const { distance, duration } = result.rows[0].elements[0]
          if (distance || duration) {
            setShowsDistance(true)
            setDistance(distance)
            setDuration(duration)
          }
        }
      } else {
        setShowsDistance(false)
      }
    })
  }

  return (
    <CustomMarker
      onMouseEnter={handleMouseEnter}
      hit={hit}
      className={`mapMarkerLite${highlighted ? ' highlighted' : ''}`}
    >
      <div className='title'><span>{hit.title}</span></div>
      {showsDistance ? (
        <DistanceDurationBox
          distance={distance}
          duration={duration}
        />) : null}
      <div className='markerWrapper' onClick={handleClick}>
        <div className='pin'>
          <div className='image' style={itemStyle} />
        </div>
      </div>
    </CustomMarker>
  )
}

MomentMapMarker.propTypes = {
  hit: PropTypes.object.isRequired,
  highlighted: PropTypes.bool
}

export default MomentMapMarker
