export const languageSettings = {
  defaultLanguage: 'de',
  languageList: ['en', 'de'],
  languageMetadata: {
    en: { flag: 'gb', label: 'English' },
    de: { flag: 'de', label: 'Deutsch' }
  },
  languageFlag: function (language) {
    return this.languageMetadata[language].flag
  },
  languageLabel: function (language) {
    return this.languageMetadata[language].label
  }
}
