import React from 'react'
import { Link } from 'react-router-dom'
import SupplierAdminNavbar from './Navbar'
import Logo from '../../Logo/Logo'
import '../SupplierAdmin.scss'

class Header extends React.Component {
  render () {
    return (
      <header
        className='c-layout-header c-layout-header-4 c-layout-header-default-mobile c-supplier-header'
        id='supplierAdminHeader'
      >
        <div className='container-fluid client-theme--bg fixed-top' style={{ padding: '0 30px' }}>
          <div className='c-navbar head-pad' id='nav-head'>
            <div className='c-navbar-wrapper d-flex justify-content-between align-items-center'>
              <div className='c-brand'>
                <Link to='/'>
                  <Logo />
                </Link>
              </div>
              {!this.props.hideNavBar ? (
                <SupplierAdminNavbar supplier={this.props.supplier} />
              ) : null}
            </div>
          </div>
        </div>
      </header>
    )
  }
};

export default Header
