import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  LOGIN_USER,
  LOGOUT_USER,
  FETCH_SEARCH_SESSION,
  UPDATE_SEARCH_PARAMS,
  CHANGE_SEARCH_BOUNDING_BOX,
  CHANGE_SEARCH_LOCATION,
  CHANGE_SEARCH_QUERY,
  SAVE_RESULT_PAGE_FILTER,
  REDIRECT_TO_SPECIFIED_URL,
  TOGGLE_STICKY_BOTTOM_BAR,
  SET_MAP_VIEW,
  OPENED_SUPPLIER_POPUP,
  SAVE_SUPPLIER,
  CHECK_LOGIN,
  SET_BREADCRUMB_HISTORY,
  SET_HIGHLIGHTED_SUPPLIER
} from '../actionTypes'

const initialState = {
  cartItems: [],
  isStickyBottomBarActive: false,
  isLoggedIn: false,
  isDoneCheckingLoginStatus: false,
  searchParams: {},
  user: {},
  redirectionURL: null,
  isMapView: false,
  breadcrumbHistory: [{
    pathname: '',
    url: '/'
  }],
  trendlines: [],
  openedSupplierPopup: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_STICKY_BOTTOM_BAR: {
      return {
        ...state,
        isStickyBottomBarActive: action.payload
      }
    }
    case ADD_TO_CART: {
      const exist = (state.cartItems || []).find(i => i.objectID === action.payload.objectID)
      if (exist !== undefined) {
        return state
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, action.payload]
        }
      }
    }
    case CHECK_LOGIN: {
      return {
        ...state,
        isDoneCheckingLoginStatus: action.payload
      }
    }
    case LOGIN_USER: {
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true
      }
    }
    case LOGOUT_USER: {
      return {
        ...state,
        user: {},
        isLoggedIn: false
      }
    }
    case REMOVE_FROM_CART: {
      const newItems = state.cartItems.filter(item => action.payload.objectID !== item.objectID)
      return {
        ...state,
        cartItems: newItems
      }
    }
    case UPDATE_SEARCH_PARAMS: {
      return {
        ...state,
        searchParams: action.payload
      }
    }
    case CHANGE_SEARCH_BOUNDING_BOX: {
      return {
        ...state,
        searchBoundingBox: action.payload
      }
    }
    case CHANGE_SEARCH_LOCATION: {
      return {
        ...state,
        searchLocation: action.payload
      }
    }
    case CHANGE_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload
      }
    }
    case FETCH_SEARCH_SESSION: {
      return {
        ...state,
        searchSession: action.payload
      }
    }
    case SAVE_RESULT_PAGE_FILTER: {
      return {
        ...state,
        resultPageFilter: action.payload
      }
    }
    case REDIRECT_TO_SPECIFIED_URL: {
      return {
        ...state,
        redirectionURL: action.payload
      }
    }
    case OPENED_SUPPLIER_POPUP: {
      return {
        ...state,
        openedSupplierPopup: action.payload
      }
    }
    case SET_MAP_VIEW: {
      return {
        ...state,
        isMapView: action.payload
      }
    }
    case SAVE_SUPPLIER: {
      return {
        ...state,
        supplier: action.payload
      }
    }
    case SET_BREADCRUMB_HISTORY: {
      return {
        ...state,
        breadcrumbHistory: action.payload
      }
    }
    case SET_HIGHLIGHTED_SUPPLIER: {
      return {
        ...state,
        highlightedSupplier: action.payload
      }
    }
    default:
      return state
  }
}
