import React from 'react'
import {
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import './MoreFilters.scss'
import SearchInput from '../Input/SearchInput'
import { StaticRefinementList } from '../Filters/StaticRefinementList'
import { StaticRefinementSelect } from '../Filters/StaticRefinementSelect'
import NumericInput from '../Input/NumericInput'
import { RatingSelect } from '../Filters/RatingSelect'
import Checkbox from '../Input/Checkbox'
import List from '../List/List'
import ReactSlider from 'react-slider'
import { useHistory } from 'react-router-dom'
import queryString from 'qs'
import moreFilters from '../../config/moreFilters.json'

const { category, suitable, room_amenity, amenity, wellness_amenity, sport_amenity, gastronomy_amenity, payment_methods, equipment_amenity, parking, distance, conference_room_details } = moreFilters

export default function MoreFilters ({
  isOpened,
  onCancel,
  onConfirm,
  containerClassName = 'filter-modal',
  queryParams
}) {
  const [isDisabled, setIsDisabled] = React.useState(true)
  const [selectedFilters, setSelectedFilters] = React.useState({})
  const { t } = useTranslation()
  const history = useHistory()
  const containerRef = React.useRef(null)

  const handleFilterChange = (event) => {
    const isChecked = event.currentTarget.checked
    setSelectedFilters((filters) => ({
      ...filters,
      [event.currentTarget.value]: isChecked || undefined
    }))
  }

  const handleBarrierFreeRoomAmenityChange = (event) => {
    const isChecked = event.currentTarget.checked
    const { room_amenity } = selectedFilters
    if (room_amenity && Array.isArray(room_amenity)) {
      // there are already other filters, if checked add accessible else remove accessible
      let updatedRoomAmenities = [...room_amenity]
      if (isChecked) {
        updatedRoomAmenities.push('accessible')
      } else {
        updatedRoomAmenities = updatedRoomAmenities.filter(a => a !== 'accessible')
      }
      setSelectedFilters((filters) => ({
        ...filters,
        room_amenity: updatedRoomAmenities
      }))
    } else {
      const updatedAmenity = isChecked ? ['accessible'] : undefined
      setSelectedFilters((filters) => ({
        ...filters,
        room_amenity: updatedAmenity
      }))
    }
  }

  const handleFilterValueChange = (filterName, filterValue) => {
    setSelectedFilters((filters) => ({
      ...filters,
      [filterName]: filterValue
    }))
  }

  const handleDistanceFilterChange = (filterName, filterValue) => {
    if (filterValue === 0) {
      filterValue = undefined
    }
    handleFilterValueChange(filterName, filterValue)
  }

  const handleMultiSelectChange = (filterName, selectedOptions) => {
    let optionValues = []
    if (Array.isArray(selectedOptions)) {
      optionValues = selectedOptions
    }
    setSelectedFilters((filters) => ({
      ...filters,
      [filterName]: optionValues
    }))
  }

  const handleDehogaChange = (value) => {
    let val = Number(value)
    const { dehoga } = selectedFilters
    if (Number(dehoga) === value || value === 0) {
      val = undefined
    }
    handleFilterValueChange('dehoga', val)
    handleFilterValueChange('micepoints', val)
  }

  const removeAllFilters = () => {
    const { location, lqt, region } = queryParams.params
    history.push({ search: queryString.stringify({ location, lqt, region }) })
    setSelectedFilters({})
  }

  const handleFilterSearch = (query) => {
    console.log('search: ', query)
  }

  const isBarrierFreeRoomAmenity = React.useCallback(() => {
    const { room_amenity } = selectedFilters
    if (room_amenity && Array.isArray(room_amenity)) {
      return room_amenity.includes('accessible')
    } else if (room_amenity === 'accessible') {
      return true
    } else {
      return false
    }
  }, [selectedFilters])

  React.useEffect(() => {
    if (JSON.stringify(selectedFilters) === '{}') {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [selectedFilters])

  React.useEffect(() => {
    if (JSON.stringify(queryParams.params) === '{}') {
      setIsDisabled(true)
      setSelectedFilters({})
    } else {
      setIsDisabled(false)
      const parsedParams = queryParams.getParsedParams()
      setSelectedFilters(parsedParams)
    }
  }, [queryParams])

  return (
    <Modal
      isOpen={isOpened}
      toggle={onCancel}
      className={containerClassName}
      zIndex='999999'
      contentClassName='filter-modal-container'
      scrollable
      fullscreen='sm'
    >
      <ModalHeader
        className='d-flex justify-content-center align-items-center filter-header'
        close={
          <ButtonIcon
            className='mice-dark-bg position-absolute'
            containerStyle={{
              width: '32px',
              height: '32px',
              right: '20px'
            }}
            iconStyle={{
              padding: '4px',
              fontSize: '16px',
              color: '#FFF'
            }}
            icon='far fa-close'
            onClick={onCancel}
            rounded
            ariaLabel={t('close')}
          />
        }
        toggle={onCancel}
        tag='div'
      >
        Filter
        <div className='search-filters d-none'><SearchInput onChange={handleFilterSearch} /></div>
      </ModalHeader>
      <ModalBody>
        <div ref={containerRef} className='more-filter-container'>
          <Row className='mt-3 mb-5 filter-group'>
            <Col className='mt-2 mb-3' xs={12}>
              <h4>{t('filter_my_meeting')}</h4>
            </Col>
            <Col className='mt-2 mb-2' xs={12}>
              <label className='filter-label w-100 text-center'>{t('participants')}</label>
              <Col className='p-0' xs={12}>
                <NumericInput
                  name='participants'
                  value={selectedFilters.participants}
                  onChange={(val) =>
                    handleFilterValueChange('participants', val)}
                  step={20}
                  ariaLabel={t('participants')}
                />
              </Col>
            </Col>
            <Col className='mt-2 mb-2' xs={12}>
              <label className='filter-label w-100 text-center'>{t('filter_number_of_rooms')}</label>
              <Col className='p-0' xs={12}>
                <NumericInput
                  name='number_of_rooms'
                  value={selectedFilters.number_of_rooms}
                  onChange={(val) =>
                    handleFilterValueChange('number_of_rooms', val)}
                  step={10}
                  ariaLabel={t('filter_number_of_rooms')}
                />
              </Col>
            </Col>
            <Col className='mt-2 mb-2' xs={12}>
              <label className='filter-label w-100 text-center'>{t('filter_conference_rooms')}</label>
              <Col className='p-0' xs={12}>
                <NumericInput
                  name='capacity_number_of_conference_rooms'
                  value={
                    selectedFilters.capacity_number_of_conference_rooms
                  }
                  onChange={(val) =>
                    handleFilterValueChange(
                      'capacity_number_of_conference_rooms',
                      val
                    )}
                  step={1}
                  ariaLabel={t('filter_conference_rooms')}
                />
              </Col>
            </Col>
            <Col className='mt-2 mb-2' xs={12} sm={12}>
              <label className='filter-label w-100 text-center'>{t('dehoga_stars')}</label>
              <Col className='p-0 d-flex justify-content-center' xs={12}>
                <RatingSelect
                  id='dehoga-select'
                  emptySymbol='far fa-star'
                  fullSymbol='fas fa-star'
                  value={selectedFilters.dehoga || 0}
                  onChange={handleDehogaChange}
                  ariaLabel={t('dehoga_stars')}
                />
              </Col>
            </Col>
            <Col className='mt-2 mb-2' xs={12} sm={12} md={12}>
              <label className='filter-label'>{t('category')}</label>
              <StaticRefinementSelect
                attribute='category'
                options={category}
                appliedFilters={selectedFilters.category}
                onChange={handleMultiSelectChange}
                ariaLabel={t('category')}
              />
            </Col>
            <Col className='mt-2 mb-2' xs={12} sm={12} md={12}>
              <label className='filter-label'>{t('suitable_for')}</label>
              <StaticRefinementSelect
                attribute='suitable'
                options={suitable}
                appliedFilters={selectedFilters.suitable}
                onChange={handleMultiSelectChange}
                ariaLabel={t('suitable_for')}
              />
            </Col>
          </Row>
          <Row className='mt-3 mb-5 filter-group'>
            <Col className='mt-2 mb-5' xs={12}>
              <h4>{t('general_information')}</h4>
            </Col>
            {parking.map((parkingFilter) => (
              <Col key={parkingFilter} className='mt-2 mb-2' xs={12}>
                <label className='filter-label w-100 text-center'>
                  {t(`filters.${parkingFilter}`)}
                </label>
                <NumericInput
                  name={parkingFilter}
                  value={selectedFilters[parkingFilter]}
                  onChange={(val) =>
                    handleFilterValueChange(parkingFilter, val)}
                  step={1}
                  ariaLabel={t(`filters.${parkingFilter}`)}
                />
              </Col>
            ))}
            <Col className='mt-2 mb-2' xs={12}>
              <label className='filter-label'>{t('payment_options')}</label>
              <List>
                <li key='airplus'>
                  <Checkbox
                    label='Airplus'
                    value='airplus'
                    name='airplus'
                    isChecked={selectedFilters.airplus || false}
                    onChange={handleFilterChange}
                  />
                </li>
                <StaticRefinementList
                  attribute='payment_methods'
                  values={payment_methods}
                  appliedFilters={selectedFilters.payment_methods}
                  onChange={handleFilterValueChange}
                  ariaLabel={t('payment_options')}
                />
              </List>
            </Col>
            <Col className='mt-2 mb-2' xs={12}>
              <label className='filter-label'>{t('distance_filter')}</label>
              <List>
                {distance.map((distanceFilter) => (
                  <li key={distanceFilter}>
                    <Row className='m-0 p-0 align-items-center'>
                      <Col className='m-0 p-0' xs={12} sm={4}>
                        <span>{t(`filters.${distanceFilter}`)}</span>
                      </Col>
                      <Col className='m-0 p-0' xs={12} sm={8}>
                        <ReactSlider
                          defaultValue={selectedFilters[distanceFilter]}
                          className='horizontal-slider'
                          thumbClassName='slider-thumb'
                          trackClassName='slider-track'
                          onAfterChange={(value, index) =>
                            handleDistanceFilterChange(distanceFilter, value)}
                          renderThumb={(props, state) => (
                            <div {...props} aria-label={t(`filters.${distanceFilter}`)}>{state.valueNow}</div>
                          )}
                          max={30}
                        />
                      </Col>
                    </Row>
                  </li>
                ))}
              </List>
            </Col>
          </Row>
          <Row className='mt-3 mb-5 filter-group'>
            <Col className='mt-2 mb-5' xs={12}>
              <h4>{t('hotel_room_information')}</h4>
            </Col>
            <Col className='mt-2 mb-2 p-0' xs={12} md={12}>
              <label className='filter-label w-100 text-center'>{t('filters.accessible')}</label>
              <div className='grid justify-content-center'>
                <Checkbox
                  label={t('filters.accessible')}
                  value='accessibility'
                  name='accessibility'
                  isChecked={isBarrierFreeRoomAmenity()}
                  onChange={handleBarrierFreeRoomAmenityChange}
                />
              </div>
            </Col>
            <Col className='mt-2 mb-2' xs={12} sm={12}>
              <label className='filter-label w-100 text-center'>{t('number_of_rooms')}</label>
              <NumericInput
                name='number_of_rooms'
                value={selectedFilters.number_of_rooms}
                onChange={(val) =>
                  handleFilterValueChange('number_of_rooms', val)}
                step={10}
                ariaLabel={t('number_of_rooms')}
              />
            </Col>
            <Col className='mt-2 mb-2' xs={12} sm={12}>
              <label className='filter-label'>{t('room_amenities')}</label>
              <StaticRefinementSelect
                attribute='room_amenity'
                options={room_amenity}
                appliedFilters={selectedFilters.room_amenity}
                onChange={handleMultiSelectChange}
                ariaLabel={t('room_amenities')}
              />
            </Col>
          </Row>
          <Row className='mt-3 mb-5 filter-group'>
            <Col className='mt-2 mb-5' xs={12}>
              <h4>{t('conference_room_details')}</h4>
            </Col>
            <Col className='' xs={12} sm={12}>
              <Row className='no-gutters'>
                {conference_room_details.map((conference_room_detail) => (
                  <Col key={conference_room_detail} className='mt-2 mb-2 p-0' xs={12}>
                    <label className='filter-label w-100 text-center'>
                      {t(conference_room_detail)}
                    </label>
                    <NumericInput
                      name={conference_room_detail}
                      value={selectedFilters[conference_room_detail]}
                      onChange={(val) =>
                        handleFilterValueChange(conference_room_detail, val)}
                      step={50}
                      ariaLabel={t(conference_room_detail)}
                    />
                  </Col>
                ))}
                <Col className='mt-2 mb-2 p-0' xs={12} md={12}>
                  <label className='filter-label w-100 text-center'>{t('location_type')}</label>
                  <Col className='mt-2 mb-2 p-0' xs={12} md={12}>
                    <div className='grid justify-content-center'>
                      <Checkbox
                        label={t('location_indoor')}
                        value='location_indoor'
                        name='location_indoor'
                        isChecked={selectedFilters.location_indoor || false}
                        onChange={handleFilterChange}
                      />
                      <Checkbox
                        label={t('location_outdoor')}
                        value='location_outdoor'
                        name='location_outdoor'
                        isChecked={selectedFilters.location_outdoor || false}
                        onChange={handleFilterChange}
                      />
                    </div>
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col className='mt-2 mb-2' xs={12} sm={12}>
              <label className='filter-label'>
                {t('conference_room_equipment')}
              </label>
              <StaticRefinementSelect
                attribute='equipment_amenity'
                options={equipment_amenity}
                appliedFilters={selectedFilters.equipment_amenity}
                onChange={handleMultiSelectChange}
                ariaLabel={t('conference_room_equipment')}
              />
            </Col>
          </Row>
          <Row className='mt-3 mb-5 filter-group'>
            <Col className='mt-2 mb-5' xs={12}>
              <h4>{t('amenities')}</h4>
            </Col>
            <Col className='mt-2 mb-2' xs={12} sm={12} md={12}>
              <label className='filter-label'>{t('general_amenities')}</label>
              <StaticRefinementSelect
                attribute='amenity'
                options={amenity}
                appliedFilters={selectedFilters.amenity}
                onChange={handleMultiSelectChange}
                ariaLabel={t('general_amenities')}
              />
            </Col>
            <Col className='mt-2 mb-2' xs={12} sm={12} md={12}>
              <label className='filter-label'>{t('wellness_amenities')}</label>
              <StaticRefinementSelect
                attribute='wellness_amenity'
                options={wellness_amenity}
                appliedFilters={selectedFilters.wellness_amenity}
                onChange={handleMultiSelectChange}
                ariaLabel={t('wellness_amenities')}
              />
            </Col>
            <Col className='mt-2 mb-2' xs={12} sm={12} md={12}>
              <label className='filter-label'>{t('sports_amenities')}</label>
              <StaticRefinementSelect
                attribute='sport_amenity'
                options={sport_amenity}
                appliedFilters={selectedFilters.sport_amenity}
                onChange={handleMultiSelectChange}
                ariaLabel={t('sports_amenities')}
              />
            </Col>
            <Col className='mt-2 mb-2' xs={12} sm={12} md={12}>
              <label className='filter-label'>{t('gastronomy_amenities')}</label>
              <StaticRefinementSelect
                attribute='gastronomy_amenity'
                options={gastronomy_amenity}
                appliedFilters={selectedFilters.gastronomy_amenity}
                onChange={handleMultiSelectChange}
                ariaLabel={t('gastronomy_amenities')}
              />
            </Col>
          </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <Col className='ml-0 mr-0 p-0' xs={12} sm={4} md={3} lg={2}>
          <ButtonWithIcon
            padding='4px 8px'
            label={t('clear_all')}
            onClick={removeAllFilters}
            icon='fa fa-times'
          />
        </Col>
        <Col xs={12} sm={8} md={9} lg={10} className='m-0 p-0 d-flex justify-content-end'>
          <Col className='ml-0 mr-0' xs={6} md={4} lg={3}>
            <ButtonWithIcon
              padding='4px 8px'
              label={t('cancel')}
              onClick={onCancel}
              border='1px solid'
            />
          </Col>
          <Col className='ml-0 mr-0' xs={6} md={4} lg={3}>
            <ButtonWithIcon
              padding='4px 8px'
              label={t('apply_filter')}
              className='client-theme--bg client-theme--font-color'
              onClick={() => onConfirm(selectedFilters)}
              disabled={isDisabled}
            />
          </Col>
        </Col>
      </ModalFooter>
    </Modal>
  )
}
