import axios from 'axios'
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  LOGIN_USER,
  LOGOUT_USER,
  FETCH_SEARCH_SESSION,
  CHANGE_SEARCH_BOUNDING_BOX,
  CHANGE_SEARCH_LOCATION,
  CHANGE_SEARCH_QUERY,
  OPENED_SUPPLIER_POPUP,
  SAVE_RESULT_PAGE_FILTER,
  REDIRECT_TO_SPECIFIED_URL,
  TOGGLE_STICKY_BOTTOM_BAR,
  SAVE_SUPPLIER,
  CHECK_LOGIN,
  SET_BREADCRUMB_HISTORY,
  SET_HIGHLIGHTED_SUPPLIER
} from './actionTypes'
import { getObjectsFromAlgolia, getObjectFromAlgolia } from '../services/algolia'
import { placesService } from '../services/googlePlaces'
import ability from '../config/ability'

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

export const toggleStickyBottomBar = payload => ({
  type: TOGGLE_STICKY_BOTTOM_BAR,
  payload: payload
})

export const redirectToSpecifiedUrl = url => ({
  type: REDIRECT_TO_SPECIFIED_URL,
  payload: url
})

export const loginUser = user => ({
  type: LOGIN_USER,
  payload: user
})

export const logoutUser = user => ({
  type: LOGOUT_USER,
  payload: user
})

export const loginStatusFetched = (status) => ({
  type: CHECK_LOGIN,
  payload: status
})

export const changeSearchBoundingBox = item => ({
  type: CHANGE_SEARCH_BOUNDING_BOX,
  payload: item
})

export const changeSearchLocation = item => ({
  type: CHANGE_SEARCH_LOCATION,
  payload: item
})

export const changeSearchQuery = item => ({
  type: CHANGE_SEARCH_QUERY,
  payload: item
})

export const saveResultPageFilter = value => ({
  type: SAVE_RESULT_PAGE_FILTER,
  payload: value
})

export const saveSupplier = value => ({
  type: SAVE_SUPPLIER,
  payload: value
})

export const addToFECart = value => ({
  type: ADD_TO_CART,
  payload: value
})

export const setBreadcrumbHistory = value => ({
  type: SET_BREADCRUMB_HISTORY,
  payload: value
})

export const fetchSearchSession = () => {
  return dispatch => {
    const endpoint = '/search/hotel/current_session'

    axios
      .get(endpoint, {}, { headers })
      .then(response => {
        dispatch({
          type: FETCH_SEARCH_SESSION,
          payload: response.data.search_session
        })
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const saveSearchLocation = (placeId, type = 'places') => {
  return dispatch => {
    const responseHandler = result => {
      if (!result) return

      if (type === 'region') {
        const coordinates = result.coordinates.split(';').filter(v => v !== '')

        let minLat = 10000
        let maxLat = -10000
        let minLng = 10000
        let maxLng = -10000

        for (let i = 0; i < coordinates.length; i++) {
          const coords = coordinates[i].split(':')

          if (coords[0] < minLat) { minLat = coords[0] }
          if (coords[0] > maxLat) { maxLat = coords[0] }
          if (coords[1] < minLng) { minLng = coords[1] }
          if (coords[1] > maxLng) { maxLng = coords[1] }
        }

        const viewport = {
          northEast: {
            lat: minLat,
            lng: minLng
          },
          southWest: {
            lat: maxLat,
            lng: maxLng
          }
        }

        const newLocation = {
          region: result,
          viewport,
          name: result.name_de,
          country: {
            long_name: 'Germany',
            short_name: 'DE'
          }
        }

        dispatch(changeSearchLocation(newLocation))
        dispatch(changeSearchBoundingBox(viewport))
      } else {
        let { lng, lat } = result.geometry.location
        let { viewport } = result.geometry

        if (type === 'places') {
          const viewportNorthEast = viewport.getNorthEast()
          const viewportSouthWest = viewport.getSouthWest()

          viewport = {
            northEast: {
              lat: viewportNorthEast.lat(),
              lng: viewportNorthEast.lng()
            },
            southWest: {
              lat: viewportSouthWest.lat(),
              lng: viewportSouthWest.lng()
            }
          }

          lat = lat()
          lng = lng()
        }

        const hasCountry = comp => comp.types.some(t => t === 'country')
        const hasLocality = comp => comp.types.some(t => t === 'locality')
        const addressCountry = result.address_components.filter(hasCountry)[0]
        const addressLocality = result.address_components.filter(hasLocality)[0]
        const newLocation = {
          lat,
          lng,
          viewport,
          placeId,
          name: result.name,
          city: addressLocality,
          country: addressCountry
        }

        dispatch(changeSearchLocation(newLocation))
        dispatch(changeSearchBoundingBox(viewport))
      }
    }

    if (type === 'api') {
      axios
        .get(`/api/v2/locations/${placeId}`, {}, { headers })
        .then(response => responseHandler(response.data))
        .catch(error => {
          console.log('fetch miceplace locations error', error)
        })
    } else if (type === 'region') {
      axios
        .get(`/api/v2/city_pois/${placeId}`, {}, { headers })
        .then(response => responseHandler(response.data))
        .catch(error => {
          console.log('fetch miceplace city poi error', error)
        })
    } else if (type === 'places') {
      const placesServiceObject = placesService()
      if (placesServiceObject && typeof placesServiceObject.getDetails === 'function') {
        placesServiceObject.getDetails(
          {
            placeId,
            fields: ['place_id', 'geometry', 'name', 'types', 'adr_address', 'address_component']
          },
          responseHandler
        )
      }
    }
  }
}

export const getSupplierFromAlgolia = (objectID) => {
  return async function (dispatch) {
    return getObjectFromAlgolia(objectID)
      .then(hit => dispatch(saveSupplier(hit)))
      .catch(err => {
        console.log(err)
      })
  }
}

export const checkLoginStatus = (isLoggedIn) => {
  return dispatch => {
    dispatch(loginStatusFetched(false))
    axios
      .get('/logged_in', { withCredentials: true })
      .then(response => {
        if (response.data.logged_in && !isLoggedIn) {
          ability.update(response.data.rules)
          dispatch(loginUser(response.data.current_person))
        } else if (!response.data.logged_in && isLoggedIn) {
          dispatch(logoutUser(response.data.current_person == null))
        }
        dispatch(loginStatusFetched(true))
      })
      .catch(error => {
        dispatch(loginStatusFetched(true))
        console.log('check login error', error)
      })
  }
}

export const fetchCartItems = () => {
  return dispatch => {
    axios
      .get('/search/hotel/cart_items', {}, { headers })
      .then(response => {
        return getObjectsFromAlgolia(response.data.supplier_ids)
      })
      .then(items => {
        items.map(item => dispatch(addToFECart(item)))
      })
      .catch(error => {
        console.log('fetch cart_items error', error)
      })
  }
}

export const addToCart = item => {
  return dispatch => {
    const id = item.objectID

    const headers = {
      contentType: 'application/json',
      Accept: 'application/json'
    }

    const endpoint = `/search/hotel/add/${id}`
    const data = { id: id, selected_deliverable_types: item.selected_deliverable_types }
    axios
      .post(endpoint, data, { headers })
      .then(() => {
        dispatch(addToFECart(item))
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const removeFromCart = item => {
  return dispatch => {
    const id = item.objectID

    const data = { id }
    const endpoint = `/search/hotel/remove/${id}`

    axios
      .post(endpoint, data, { headers })
      .then(() => {
        dispatch({
          type: REMOVE_FROM_CART,
          payload: item
        })
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}

export const changeOpenedSupplierPopup = value => ({
  type: OPENED_SUPPLIER_POPUP,
  payload: value
})

export const setHighlightedSupplier = value => ({
  type: SET_HIGHLIGHTED_SUPPLIER,
  payload: value
})
