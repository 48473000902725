import React from 'react'
import { NextArrow, PrevArrow } from '../Shared/Shared'

export const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 7,
  slidesToScroll: 7,
  nextArrow: <NextArrow customClassName='client-theme--bg client-theme--font-color' />,
  prevArrow: <PrevArrow customClassName='client-theme--bg client-theme--font-color' />,
  responsive: [
    {
      breakpoint: 2400,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 7
      }
    },
    {
      breakpoint: 1900,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6
      }
    },
    {
      breakpoint: 1680,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5
      }
    },
    {
      breakpoint: 1170,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 567,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

export const settingsLite = {
  dots: false,
  centerMode: true,
  className: 'slick-slider-center-container',
  centerPadding: '40px',
  infinite: true,
  slidesToShow: 7,
  slidesToScroll: 1,
  nextArrow: <NextArrow customClassName='lite-arrow' />,
  prevArrow: <PrevArrow customClassName='lite-arrow' />,
  responsive: [
    {
      breakpoint: 1900,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1170,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 567,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}
