import React from 'react'
import { Col, Row } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Carousel } from 'react-responsive-carousel'

import PlaceholderImage from '../../assets/images/image-placeholder-square.jpg'

class RoomGroupCard extends React.Component {
  render () {
    const { t, i18n, roomGroup } = this.props

    return (
      <>
        {roomGroup ? (
          <Row className='m-0 p-2 contentBox supplier-feature-box-round' style={{ height: '100%' }}>
            <Col className='mt-3' md={12}>
              <Carousel
                infiniteLoop
                showIndicators={false}
                showThumbs={false}
                showArrows
                showStatus={false}
                swipeable={false}
              >
                {roomGroup.images && roomGroup.images.length > 0 ? (
                  roomGroup.images.map((image, idx) => (
                    <div key={idx} className='conferenceRoomCarouselPhotoContainer'>
                      <img className='conferenceRoomCarouselPhoto' src={`${image.url}`} alt='' />
                    </div>
                  ))
                ) : (
                  <div className='conferenceRoomCarouselPhotoContainer'>
                    <img className='conferenceRoomCarouselPhoto' src={PlaceholderImage} alt='' />
                  </div>
                )}
              </Carousel>
              <h4 className='c-title c-font-uppercase c-font-bold pt-1 mt-4'>
                {roomGroup.room_type[`name_${i18n.language}`]}
              </h4>

              <div>
                <ul className='supplierFeaturesList'>
                  <li className='supplierFeaturesListItem'>
                    <span>
                      {t('average_room_size')}
                    </span>
                    <span className='supplierFeaturesListItemValue'>
                      {roomGroup.room_average_size}
                    </span>
                  </li>
                </ul>
              </div>

              <div style={{ paddingBottom: '20px' }}>
                {roomGroup.facilities ? (
                  <ul className='tags mt-4'>
                    {roomGroup.facilities.map((facility, index) => <li key={index}>{facility[`name_${i18n.language}`]}</li>)}
                  </ul>
                ) : null}
              </div>
            </Col>
          </Row>
        ) : null}
      </>
    )
  }
}

RoomGroupCard.propTypes = {
  roomGroup: PropTypes.object
}

export default withTranslation()(RoomGroupCard)
