import { RollbarInstance } from '../services/rollbar'

export const RollbarErrorTracking = (() => {
  const error = (...args) => {
    RollbarInstance.error(...args)
  }

  const info = (...args) => {
    RollbarInstance.info(...args)
  }

  return { error, info }
})()

export default RollbarErrorTracking
