import React from 'react'
import { Row, Col } from 'reactstrap'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import { withTranslation } from 'react-i18next'

function ErrorView ({ error, history, t }) {
  function handleGoBack () {
    if (history && history.go) {
      history.go(-2)
    }
  }
  return (
    <>
      <Row className='ml-0 mr-0 mt-4 mb-2'>
        <Col xs={12} className='text-center'>
          <h3 className='lead'>{t('error_try_later')}</h3>
        </Col>
      </Row>
      <Row className='ml-0 mr-0 mt-2 mb-2'>
        <Col xs={12} className='text-center'>
          <p>{error && error.message ? error.message : null}</p>
        </Col>
      </Row>
      <Row className='ml-0 mr-0 mt-2 mb-2'>
        <Col xs={12} sm={{ size: 6, offset: 3 }} md={{ size: 4, offset: 4 }}>
          <ButtonWithIcon
            labelColor='#FFFFFF'
            iconcolor='#FFFFFF'
            icon='fa fa-check'
            label={t('go_back')}
            className='btn c-btn-square c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold'
            onClick={handleGoBack}
          />
        </Col>
      </Row>
    </>
  )
}

export default withTranslation()(ErrorView)
