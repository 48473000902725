import React from 'react'
import './SupplierSpecialAgreement.scss'

class SupplierSpecialAgreement extends React.Component {
  render () {
    return this.props.specialAgreement && this.props.specialAgreement.length > 0 ? (
      <div className='supplierSpecialAgreementWrapper'>
        <h3>{this.props.heading}</h3>
        <ul>
          {this.props.specialAgreement.map((value, index) => {
            if (value.length > 1) {
              return <li key={index}>{value}</li>
            }
          })}
        </ul>
      </div>
    ) : null
  }
}
export default SupplierSpecialAgreement
