import React from 'react'
import { Row, Col } from 'reactstrap'
import TextInputGroup from '../../../TextInputGroup/TextInputGroup'
import { TextInput } from '../../../FormElements/FormElements'
import ImagesGalleryManager from '../../../ImagesGalleryManager/ImagesGalleryManager'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withTranslation } from 'react-i18next'

class RoomGroup extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      roomGroup: {},
      errors: {}
    }

    this.notifyChangeListener = this.notifyChangeListener.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleRoomTypeChange = this.handleRoomTypeChange.bind(this)
    this.handleFacilitiesChange = this.handleFacilitiesChange.bind(this)
    this.getSelectedFacilitiesOptions = this.getSelectedFacilitiesOptions.bind(this)
  }

  componentDidMount () {
    this.setState({
      roomGroup: { ...this.props.roomGroup }
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.roomGroup !== this.props.roomGroup) {
      this.setState({ roomGroup: { ...this.props.roomGroup } })
    }
  }

  notifyChangeListener () {
    if (this.props.onChange) {
      const event = { target: {}, type: 'roomGroup' }
      event.target.name = this.props.name
      event.target.value = this.state.roomGroup
      event.target.value.room_groups_facilities_attributes = event.target.value.room_groups_facilities

      this.props.onChange(event)
    }
  }

  handleRoomTypeChange (selectedRoomType, actionType) {
    switch (actionType.action) {
      case 'select-option':
        this.handleChange({
          target: {
            name: actionType.name,
            value: selectedRoomType.id
          }
        })
        break
    }
  }

  handleFacilitiesChange (newFacilities, actionType) {
    let roomGroupFacilities = [...(this.state.roomGroup.room_groups_facilities || [])]

    switch (actionType.action) {
      case 'select-option':
        roomGroupFacilities.push({ facility_id: actionType.option.id })
        break
      case 'remove-value':
        roomGroupFacilities = roomGroupFacilities.map((roomGroupFacility) => {
          if (actionType.removedValue.id === roomGroupFacility.facility_id) {
            roomGroupFacility._destroy = true
          }

          return roomGroupFacility
        })
        break
      case 'clear':
        roomGroupFacilities = roomGroupFacilities.map((roomGroupFacility) => {
          roomGroupFacility._destroy = true

          return roomGroupFacility
        })
        break
      default:
        console.log('Unexpected event type', actionType)
    }

    this.setState({
      roomGroup: {
        ...this.state.roomGroup,
        room_groups_facilities: roomGroupFacilities
      }
    }, this.notifyChangeListener)
  }

  getSelectedFacilitiesOptions (options) {
    if (this.props.roomFacilities) {
      let roomGroupFacilities = this.state.roomGroup.room_groups_facilities || []
      roomGroupFacilities = roomGroupFacilities.filter((roomGroupFacility) => !roomGroupFacility._destroy)

      const roomGroupFacilitiesIds = roomGroupFacilities.map((roomGroupFacility) => roomGroupFacility.facility_id)

      return this.props.roomFacilities.filter((roomFacility) => {
        const facilityIndex = roomGroupFacilitiesIds.indexOf(roomFacility.id)
        if (facilityIndex > -1) {
          return !roomGroupFacilities[facilityIndex]._destroy
        }

        return false
      })
    } else {
      return []
    }
  }

  getSelectedRoomType () {
    if (this.props.roomTypes && this.state.roomGroup.room_type_id) {
      return this.props.roomTypes.filter((roomType) => { return roomType.id === this.state.roomGroup.room_type_id })
    }
    return []
  }

  handleChange ({ target }) {
    const value = target.value
    const name = target.name

    this.setState({
      roomGroup: {
        ...this.state.roomGroup,
        [name]: value
      }
    }, this.notifyChangeListener)
  }

  render () {
    const { t, i18n } = this.props

    return (
      <>
        <Row className='mt-3 mb-3'>
          <Col className='mt-2 mb-2' md={6} sm={6} xs={12}>
            <Select
              id='roomTypeId'
              name='room_type_id'
              className='defaultSelectDropdown'
              classNamePrefix='defaultSelectDropdown'
              options={this.props.roomTypes}
              placeholder={t('please_select')}
              getOptionValue={option => option.id}
              getOptionLabel={option => option[`name_${i18n.language}`]}
              isSearchable
              isMulti={false}
              onChange={this.handleRoomTypeChange}
              value={this.getSelectedRoomType()}
              style={{ margin: '6px 0' }}
            />
            {this.props.errors && this.props.errors.room_type_id ? (
              <div style={{ marginTop: '.25rem', fontSize: '80%', color: '#dc3545' }}>
                {this.props.errors.room_type_id}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row className='mt-3 mb-3'>
          <Col className='mt-2 mb-2' md={6} sm={6} xs={12}>
            <TextInput
              name='number_of_rooms'
              className='borderPrimary'
              label={t('supplier.edit.rooms.room.number_of_rooms.label')}
              placeholder={t('supplier.edit.rooms.room.number_of_rooms.placeholder')}
              value={this.state.roomGroup.number_of_rooms}
              onChange={this.handleChange}
              error={this.props.errors && this.props.errors.number_of_rooms}
            />
          </Col>
          <Col className='mt-2 mb-2' md={6} sm={6} xs={12}>
            <TextInputGroup
              name='room_average_size'
              label={t('supplier.edit.rooms.room.average_size.label')}
              className='borderPrimary'
              placeholder={t('supplier.edit.rooms.room.average_size.placeholder')}
              appendText='m2'
              value={this.state.roomGroup.room_average_size}
              onChange={this.handleChange}
              error={this.props.errors && this.props.errors.room_average_size}
            />
          </Col>
        </Row>

        <Row className='mt-3 mb-3'>
          <Col md={12} sm={12} xs={12}>
            <label htmlFor='roomGroupsFacilities'>{t('room_amenities')}</label>
            <Select
              id='roomGroupsFacilities'
              name='room_groups_facilities'
              className='defaultSelectDropdown'
              classNamePrefix='defaultSelectDropdown'
              options={this.props.roomFacilities}
              getOptionValue={option => option.id}
              getOptionLabel={option => option[`name_${i18n.language}`]}
              placeholder={t('your_selection')}
              value={this.getSelectedFacilitiesOptions()}
              isSearchable
              isClearable
              isMulti
              onChange={this.handleFacilitiesChange}
              style={{ margin: '6px 0' }}
            />
          </Col>
        </Row>

        <Row className='mt-3 mb-3'>
          <Col className='mt-1 mb-1' xs={12}>
            <h6>{t('supplier.edit.rooms.room.upload_images.label')}</h6>
          </Col>
        </Row>

        <ImagesGalleryManager
          name='images_attributes'
          images={this.state.roomGroup.images}
          onChange={this.handleChange}
        />
      </>
    )
  }
}

RoomGroup.propTypes = {
  name: PropTypes.string,
  roomFacilities: PropTypes.array,
  roomGroup: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func
}

export default withTranslation()(RoomGroup)
