import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import ButtonWithIcon from '../../../../ButtonWithIcon/ButtonWithIcon'
import { TextInput } from '../../../../FormElements/FormElements'
import Heading from '../../../../Heading/Heading'
import SuccessMessage from '../../../../SuccessMessage/SuccessMessage'
import ValidationErrors from '../../../../ValidationErrors/ValidationErrors'
import axios from 'axios'
import Select from 'react-select'
import { scrollToRef } from '../../../../../shared/utils'
const _ = require('lodash')

class ContactForm extends Component {
  constructor (props) {
    super(props)

    this.componentRef = React.createRef()

    this.state = {
      pristine: true,
      isSubmiting: false,
      errors: {},
      showSuccessMessage: false,
      contact: {},
      selectedRole: this.props.generateContactRoleObject(this.props.contact.role),
      unsavedChanges: false

    }

    this.handleCreate = this.handleCreate.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.emailActionsErrorCallback = this.emailActionsErrorCallback.bind(this)
    this.handleContactTypeSelected = this.handleContactTypeSelected.bind(this)
  }

  componentDidMount () {
    this.initializeStateWithExistingFormData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.contact !== this.props.contact) {
      this.initializeStateWithExistingFormData()
    }

    if (!this.state.unsavedChanges && !_.isEmpty(prevState.contact) && JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.handleUnsavedChanges(prevState)
    }
  }

  initializeStateWithExistingFormData () {
    this.setState({ pristine: true, unsavedChanges: false, contact: { ...this.props.contact }, selectedRole: this.props.generateContactRoleObject(this.props.contact.role) })
    this.baseUrl = `/suppliers/${this.props.supplierId}`
    this.baseContactsUrl = `${this.baseUrl}/contacts`
  }

  handleUnsavedChanges (prevState) {
    // This check is to make sure to not detect changing the unsavedChanges attribute as unsaved changes by the user
    // So in this case it means that the user save his changes and the unsavedChanges attribute returned to false
    // Then we don't need to disable the tabs
    // also not detect hiding the success msg after save and not detect deleting contact
    if (!prevState.unsavedChanges && !this.state.isDeleting && !prevState.showSuccessMessage) {
      this.setState({ unsavedChanges: true })
      this.props.onUnsavedChanges(true)
    }
  }

  handleChange ({ target }) {
    const value = target.value
    const name = target.name

    this.setState({
      pristine: false,
      contact: {
        ...this.state.contact,
        [name]: value
      }
    })
  }

  handleUpdate () {
    this.handleSubmit('put')
  }

  handleCreate () {
    this.handleSubmit('post')
  }

  handleDelete () {
    this.setState({
      isDeleting: true,
      errors: {}
    }, function () {
      axios({
        method: 'delete',
        url: `${this.baseContactsUrl}/${this.state.contact.role}.json`,
        data: { contact: this.state.contact, locale: this.props.i18n.language }
      })
        .then(response => {
          if (this.props.onDelete) {
            this.props.onDelete()
          }
          this.props.onSavedChanges(false)
        })
        .catch(error => {
          switch (error.response.status) {
            case 403:
              window.location.href = '/pages/access_denied'
              break
            case 422:
              this.setState({ errors: error.response.data.errors, isSubmiting: false })
              scrollToRef(this.componentRef)
              break
          }

          this.setState({ isSubmiting: false })
        })
    })
  }

  handleSubmit (method) {
    this.setState({
      isSubmiting: true,
      showSuccessMessage: false,
      errors: {}
    }, function () {
      let urlComplement = ''

      if (this.state.contact && this.state.contact.person_id) {
        urlComplement = `/${this.state.contact.role}`
      }

      axios({
        method: method,
        url: `${this.baseContactsUrl}${urlComplement}.json`,
        data: { contact: this.state.contact, locale: this.props.i18n.language, current_role: this.props.contact.role }
      })
        .then(response => {
          this.setState({
            isSubmiting: false,
            showSuccessMessage: true,
            contact: {
              ...this.state.contact,
              person_id: response.data.person_id
            }
          }, () => {
            if (this.props.onCreate) {
              this.props.onCreate(this.state.contact)
            }
            this.props.onSavedChanges(false)
          })

          scrollToRef(this.componentRef)

          setTimeout(() => this.setState({
            showSuccessMessage: false
          }), 2000)
        })
        .catch(error => {
          switch (error.response.status) {
            case 403:
              window.location.href = '/pages/access_denied'
              break
            case 422:
              this.setState({
                errors: { ...error.response.data.errors, full_messages: error.response.data.errors.base },
                isSubmiting: false
              })

              scrollToRef(this.componentRef)
              break
          }

          this.setState({ isSubmiting: false })
        })
    })
  }

  emailActionsErrorCallback (error) {
    switch (error.response.status) {
      case 422:
        this.setState({ errors: error.response.data.errors })
        scrollToRef(this.componentRef)
        break
    }
  }

  handleContactTypeSelected ({ value }) {
    this.setState({
      pristine: false,
      selectedRole: this.props.generateContactRoleObject(value),
      contact: {
        ...this.state.contact,
        role: value
      }
    })
  }

  render () {
    const { t } = this.props
    const availableRolesToSelect = this.props.availableRoles(this.props.contact.role)

    return (
      <div className='m-0 mt-3 mb-5' ref={this.componentRef}>
        <Heading size={4}>{t(`contacts_roles.${this.props.contact.role}`)}</Heading>

        <SuccessMessage message={t('changes_saved')} hidden={!this.state.showSuccessMessage} />
        <ValidationErrors validationErrors={this.state.errors} />

        <Row>
          <Col sm={6} xs={12} className='mb-3'>
            <TextInput
              name='first_name'
              error={this.state.errors.first_name}
              value={this.state.contact.first_name}
              label={t('first_name')}
              onChange={this.handleChange}
              placeholder={t('first_name')}
            />
          </Col>

          <Col sm={6} xs={12} className='mb-3'>
            <TextInput
              name='last_name'
              error={this.state.errors.last_name}
              value={this.state.contact.last_name}
              label={t('last_name')}
              onChange={this.handleChange}
              placeholder={t('last_name')}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} xs={12} className='mb-3'>
            <TextInput
              name='phone_number'
              error={this.state.errors.phone_number}
              value={this.state.contact.phone_number}
              label={t('phone_number')}
              onChange={this.handleChange}
              placeholder={t('phone_number_placeholder')}
            />
          </Col>

          <Col sm={6} xs={12} className='mb-3'>
            <TextInput
              name='email'
              error={this.state.errors.email}
              value={this.state.contact.email}
              label={t('email')}
              onChange={this.handleChange}
              placeholder={t('email_address_placeholder')}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} xs={12} className='mb-3'>
            <Select
              name='role'
              className='defaultSelectDropdown mt-0'
              classNamePrefix='defaultSelectDropdown'
              options={availableRolesToSelect}
              placeholder={t('select_user_type')}
              isSearchable
              isMulti={false}
              onChange={this.handleContactTypeSelected}
              value={this.state.selectedRole}
              style={{ margin: '0px 0px 6px 0px' }}
            />
          </Col>
        </Row>
        <Row>
          {this.state.contact.person_id ? (
            <>
              <Col className='mt-xs-2' md={3} sm={6} xs={12}>
                <ButtonWithIcon
                  labelColor='#FFFFFF'
                  iconColor='#FFFFFF'
                  padding='4px'
                  icon='fa fa-save'
                  label={t('save_user')}
                  className='client-theme--bg'
                  onClick={this.handleUpdate}
                  disabled={this.state.pristine || this.state.isSubmiting}
                />
              </Col>
              <Col className='mt-xs-2' md={3} sm={6} xs={12}>
                <ButtonWithIcon
                  border='1px solid'
                  padding='4px'
                  icon='fa fa-trash'
                  label={t('delete_user')}
                  className='danger'
                  onClick={this.handleDelete}
                  disabled={this.state.isSubmiting}
                />
              </Col>
            </>
          ) : (
            <Col className='mt-xs-2' md={3} sm={6} xs={12}>
              <ButtonWithIcon
                labelColor='#FFFFFF'
                iconColor='#FFFFFF'
                padding='4px'
                icon='fa fa-save'
                label={t('create_user')}
                className='client-theme--bg'
                onClick={this.handleCreate}
                disabled={this.state.pristine || this.state.isSubmiting}
              />
            </Col>
          )}
        </Row>
      </div>
    )
  }
}

ContactForm.propTypes = {
  supplierId: PropTypes.number.isRequired,
  contact: PropTypes.object.isRequired
}

export default withTranslation()(ContactForm)
