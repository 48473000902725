import React from 'react'
import './Amenities.scss'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import { Col, Card, CardBody, Row } from 'reactstrap'
import UploadImage from '../../../UploadImage/UploadImage'
import ButtonWithIcon from '../../../ButtonWithIcon/ButtonWithIcon'
import ValidationErrors from '../../../ValidationErrors/ValidationErrors'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import { getSelectedOptions, getButtonLabel, getButtonIcon, isButtonDisabled } from '../../../../shared/utils'
import FormStatus from '../../../FormStatus/FormStatus'

class LocationAmenities extends React.Component {
  render () {
    const { t, i18n } = this.props

    return this.props.supplier && this.props.supplier.supplier_location ? (
      <div className='containerFluid'>
        {this.props.hasUserMadeChanges ? (
          <Col className='p-0 d-flex justify-content-center align-items-center'>
            <ButtonWithIcon
              labelColor='#FFFFFF'
              iconColor='#FFFFFF'
              padding='12px'
              margin='10px'
              icon={getButtonIcon('reset', this.props.submitStatus, this.props.hasUserMadeChanges)}
              label={getButtonLabel('reset', this.props.submitStatus, this.props.hasUserMadeChanges)}
              className='btn-danger c-btn-square c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold'
              onClick={() => this.props.onReset()}
              disabled={isButtonDisabled(this.props.submitStatus)}
            />
          </Col>
        ) : null}
        <Row>
          <Col style={{ padding: '0px' }} xs={12}>
            <ValidationErrors validationErrors={this.props.validationErrors} />

            <Accordion
              allowMultipleExpanded
              allowZeroExpanded
            >
              <AccordionItem style={{ marginBottom: '15px' }}>
                <Card>
                  <CardBody>
                    <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                      <AccordionItemButton>
                        <h5 className='u-position-relative'>
                          {t('supplier.edit.amenities.inhouse_amenities')}
                          <div className='accordion__arrow' role='presentation' />
                        </h5>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row className='m-0 mt-3 mb-3'>
                        <Col className='p-0' xs={12}>
                          <label>{t('supplier.edit.amenities.facilities')}</label>
                          <Select
                            name='amenities'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={this.props.supplier.supplier_location.amenities}
                            getOptionValue={option => option.attribute_name}
                            getOptionLabel={option => option[`name_${i18n.language}`]}
                            placeholder={t('select_all_that_apply')}
                            value={getSelectedOptions(this.props.supplier.supplier_location.amenities)}
                            isSearchable
                            isClearable
                            isMulti
                            onChange={values => this.props.onMultipleSelectChange('amenities', values, true)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                      </Row>
                      <Row className='m-0 mt-3 mb-3'>
                        {this.props.images && this.props.images.amenity && this.props.images.amenity.length > 0 ? (
                          this.props.images.amenity.map((image, idx) =>
                            image._destroy ? null : (
                              <Col key={idx} className='pl-0 pt-3' lg={3} md={4} sm={6} xs={12}>
                                <UploadImage
                                  label={t('click_here_to_upload')}
                                  imageUrl={image.url}
                                  movable
                                  canMoveLeft={idx > 0}
                                  canMoveRight={idx < this.props.images.amenity.length - 1}
                                  uploadWidget={this.props.uploadWidget}
                                  onUploadComplete={response => this.props.onImageUploadComplete('amenityImages', idx, response)}
                                  onRemove={() => this.props.onRemoveImage('amenityImages', idx)}
                                  onMoveLeft={() => this.props.onMoveImage('amenityImages', idx, idx - 1)}
                                  onMoveRight={() => this.props.onMoveImage('amenityImages', idx, idx + 1)}
                                />
                              </Col>
                            )
                          )
                        ) : (
                          <Col
                            className='pl-0 pt-3 d-flex justify-content-center align-items-center'
                            lg={3}
                            md={4}
                            sm={6}
                            xs={12}
                          >
                            <UploadImage
                              label={t('click_here_to_upload')}
                              imageUrl=''
                              mode='c_fill,g_auto'
                              uploadWidget={this.props.uploadWidget}
                              onUploadComplete={response => this.props.onImageUploadComplete('amenityImages', null, response)}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row className='m-0 mt-3 mb-3 pr-4'>
                        <Col className='p-0' xl={3} lg={4} md={4} sm={6}>
                          <ButtonWithIcon
                            className='client-theme--border-color'
                            border='1px solid'
                            label={t('add_image')}
                            icon='fa fa-plus'
                            onClick={() => this.props.onAddImage('amenityImages')}
                          />
                        </Col>
                      </Row>
                    </AccordionItemPanel>
                  </CardBody>
                </Card>
              </AccordionItem>

              <AccordionItem style={{ marginBottom: '15px' }}>
                <Card>
                  <CardBody>
                    <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                      <AccordionItemButton>
                        <h5 className='u-position-relative'>
                          {t('supplier.edit.amenities.gastronomy')}
                          <div className='accordion__arrow' role='presentation' />
                        </h5>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row className='m-0 mt-3 mb-3'>
                        <Col className='p-0' xs={12}>
                          <label>{t('supplier.edit.amenities.gastronomy_facilities_and_menu')}</label>
                          <Select
                            name='gastronomy_amenities'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={this.props.supplier.supplier_location.gastronomy_amenities}
                            getOptionValue={option => option.attribute_name}
                            getOptionLabel={option => option[`name_${i18n.language}`]}
                            placeholder={t('select_all_that_apply')}
                            value={getSelectedOptions(this.props.supplier.supplier_location.gastronomy_amenities)}
                            isSearchable
                            isClearable
                            isMulti
                            onChange={values => this.props.onMultipleSelectChange('gastronomy_amenities', values, true)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                      </Row>
                      <Row className='m-0 mt-3 mb-3'>
                        {this.props.images && this.props.images.gastronomy && this.props.images.gastronomy.length > 0 ? (
                          this.props.images.gastronomy.map((image, idx) =>
                            image._destroy ? null : (
                              <Col key={idx} className='pl-0 pt-3' lg={3} md={4} sm={6} xs={12}>
                                <UploadImage
                                  label={t('click_here_to_upload')}
                                  imageUrl={image.url}
                                  movable
                                  canMoveLeft={idx > 0}
                                  canMoveRight={idx < this.props.images.gastronomy.length - 1}
                                  uploadWidget={this.props.uploadWidget}
                                  onUploadComplete={response => this.props.onImageUploadComplete('gastronomyImages', idx, response)}
                                  onRemove={() => this.props.onRemoveImage('gastronomyImages', idx)}
                                  onMoveLeft={() => this.props.onMoveImage('gastronomyImages', idx, idx - 1)}
                                  onMoveRight={() => this.props.onMoveImage('gastronomyImages', idx, idx + 1)}
                                />
                              </Col>
                            )
                          )
                        ) : (
                          <Col className='p-0 d-flex justify-content-center align-items-center' lg={3} md={4} sm={6} xs={12}>
                            <UploadImage
                              label={t('click_here_to_upload')}
                              imageUrl=''
                              mode='c_fill,g_auto'
                              uploadWidget={this.props.uploadWidget}
                              onUploadComplete={response => this.props.onImageUploadComplete('gastronomyImages', null, response)}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row className='m-0 mt-3 mb-3 pr-4'>
                        <Col className='p-0' xl={3} lg={4} md={4} sm={6}>
                          <ButtonWithIcon
                            className='client-theme--border-color'
                            border='1px solid'
                            label={t('add_image')}
                            icon='fa fa-plus'
                            onClick={() => this.props.onAddImage('gastronomyImages')}
                          />
                        </Col>
                      </Row>
                    </AccordionItemPanel>
                  </CardBody>
                </Card>
              </AccordionItem>
            </Accordion>
            <Row className='m-0 mt-3 mb-3 pr-4'>
              <Col className='pl-0' xl={4} md={4} sm={6} xs={12}>
                <ButtonWithIcon
                  labelColor='#FFFFFF'
                  iconColor='#FFFFFF'
                  padding='12px'
                  icon={getButtonIcon('submit', this.props.submitStatus, this.props.hasUserMadeChanges)}
                  label={getButtonLabel('submit', this.props.submitStatus, this.props.hasUserMadeChanges)}
                  className='btn c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold rounded-pill'
                  onClick={this.props.onSubmit}
                  disabled={isButtonDisabled(this.props.submitStatus)}
                />
              </Col>
              <Col className='pl-0' xl={4} md={4} sm={6} xs={12}>
                <FormStatus
                  id='supplierEditLocationAmenities'
                  submitStatus={this.props.submitStatus}
                  errors={this.props.validationErrors}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    ) : null
  }
}

LocationAmenities.propTypes = {
  supplier: PropTypes.object,
  uploadWidget: PropTypes.object,
  onImageUploadComplete: PropTypes.func,
  onMoveImage: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  saveButtonIcon: PropTypes.string,
  saveButtonLabel: PropTypes.string,
  saveButtonDisabled: PropTypes.bool,
  validationErrors: PropTypes.object
}

export default withTranslation()(LocationAmenities)
