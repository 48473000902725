import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import './HashTags.scss'

export default function HashTags ({ values, className, hrefCallback }) {
  return (
    <ul className={className ? `HashTags ${className}` : 'HashTags'}>
      {values && values.length > 0 && values.map((tag, index) => (
        <li key={tag.id || tag.name}>
          <Link to={hrefCallback(tag.name)}>
            <i className='fa fa-hashtag' aria-hidden='true' /> {tag.name}
          </Link>
        </li>
      ))}
    </ul>
  )
}

HashTags.propTypes = {
  values: PropTypes.array.isRequired,
  hrefCallback: PropTypes.func.isRequired,
  className: PropTypes.string
}
