import React from 'react'
import './ButtonWithIcon.scss'
import PropTypes from 'prop-types'

class ButtonWithIcon extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <button
        style={{
          backgroundColor:
            this.props.background && !this.props.useThemeBgColor
              ? this.props.background
              : null,
          padding: this.props.padding ? this.props.padding : null,
          margin: this.props.margin ? this.props.margin : null,
          border: this.props.border ? this.props.border : null,
          borderRadius: this.props.borderRadius
            ? this.props.borderRadius
            : null
        }}
        className={`defaultButton ${this.props.className}`}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        {...(this.props.hasTooltip && { 'data-tip': true })}
        {...(this.props.dataFor && { 'data-for': this.props.dataFor })}
        aria-label={this.props.ariaLabel || ''}
      >
        {this.props.icon ? (
          <div
            style={{
              color: this.props.iconColor ? this.props.iconColor : null,
              fontSize: this.props.iconSize ? this.props.iconSize : null,
              marginRight: this.props.label ? '5px' : '0px'
            }}
            className={`${this.props.iconContainerClassName || ' '}`}
            aria-hidden='true'
          >
            <i className={this.props.icon} />
          </div>
        ) : null}
        <div
          style={{
            color: this.props.labelColor ? this.props.labelColor : null,
            fontSize: this.props.labelSize ? this.props.labelSize : null
          }}
          className={`defaultButtonLabel ${this.props.labelClassName || ' '}`}
          id={this.props.id ? `${this.props.id}` : ''}
          dangerouslySetInnerHTML={{ __html: this.props.label }}
        />
      </button>
    )
  }
}

ButtonWithIcon.propTypes = {
  background: PropTypes.string,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelColor: PropTypes.string,
  labelSize: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  labelClassName: PropTypes.string,
  iconContainerClassName: PropTypes.string,
  useThemeBgColor: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default ButtonWithIcon
