import React, { Fragment } from 'react'
import './SupplierMapCardList.scss'
import { Row, Col } from 'reactstrap'
import SupplierCard from '../SupplierCard/SupplierCard'
import NoResultsNote from './../Results/NoResultsNote'
import { Pagination, connectHits } from 'react-instantsearch-dom'
import BannerAdDisplay from '../BannerAd/BannerAdDisplay'
import AdvertisementList from '../AdvertisementList/AdvertisementList'
import { withTranslation } from 'react-i18next'

function SupplierMapCardList ({ onHighlightSupplier, hits, queryParams, searchLocation, t }) {
  const handleSupplierMouseEnter = (supplier) => {
    onHighlightSupplier(supplier)
  }

  const handleSupplierMouseLeave = () => {
    onHighlightSupplier(null)
  }

  const handleScrollMapList = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div className='c-container'>
      {hits && hits.length > 0 ? (
        <>
          <div className='m-0 supplier-card-container'>
            {hits.map((hit, idx) => (
              <Fragment key={hit.objectID}>
                <div
                  className='supplier-card-wrapper'
                  onMouseEnter={() => handleSupplierMouseEnter(hit)}
                  onMouseLeave={handleSupplierMouseLeave}
                >
                  <SupplierCard hit={hit} />
                </div>
                {searchLocation && idx === (hits.length - 1) ? <AdvertisementList searchLocation={searchLocation} pageSection='upper' subdomain={window.CURRENT_SUBDOMAIN} /> : null}
                {searchLocation && idx === (hits.length - 1) ? <AdvertisementList searchLocation={searchLocation} pageSection='lower' subdomain={window.CURRENT_SUBDOMAIN} /> : null}
                {idx === (hits.length - 1) && <BannerAdDisplay key='bannerad' variant='results-advt' />}
              </Fragment>
            ))}
          </div>
          <NoResultsNote limit={3} context='suppliers' />
          <Row className='ml-0 mr-0 mt-4 mb-4'>
            <Col xs={12} onClick={handleScrollMapList}>
              <Pagination
                defaultRefinement={queryParams.page || 1}
                showFirst
                showPrevious
                showNext
                showLast
                padding={2}
                className='supplier-card-pagination'
                translations={{
                  ariaPrevious: t('previous_page'),
                  ariaNext: t('next_page'),
                  ariaFirst: t('first_page'),
                  ariaLast: t('last_page')
                }}
              />
            </Col>
          </Row>
        </>
      ) : (
        <NoResultsNote limit={3} context='suppliers' />
      )}
    </div>
  )
}

export default withTranslation()(connectHits(SupplierMapCardList))
