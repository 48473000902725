import React from 'react'
import { AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import { Row, Col, Card, CardBody } from 'reactstrap'
import HashtagsSelect from '../../../HashtagsSelect/HashtagsSelect'
import Can from '../../../Layout/Can'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import { getSelectedOptions } from '../../../../shared/utils'
import AreaOfOperation from '../../../AreaOfOperation/AreaOfOperation'

class ConventionBureauGeneral extends React.Component {
  render () {
    const { t, i18n } = this.props

    return (
      this.props.supplier && this.props.supplier.supplier_convention_bureau ? (
        <div>
          <Row className='m-0 mt-3 mb-3'>
            <Col className='p-0' xs={12}>
              <Card>
                <CardBody>
                  <AccordionItem>
                    <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                      <AccordionItemButton>
                        <h5 className='u-position-relative'>
                          {t('supplier.edit.general.facts_and_figures')}
                          <div className='accordion__arrow' role='presentation' />
                        </h5>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row>
                        <Col xs={12}>
                          <h3>{t('supplier.edit.general.operational_area')}</h3>
                        </Col>
                      </Row>
                      <AreaOfOperation
                        deliverable={this.props.supplier.supplier_convention_bureau}
                        onMultipleSelectChange={this.props.onMultipleSelectChange}
                        onSingleSelectChange={this.props.onSingleSelectChange}
                      />

                      <Row>
                        <Col xs={12}>
                          <h3>{t('supplier.edit.general.characteristics')}</h3>
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-3'>
                        <Col xs={12}>
                          <label>{t('supplier.edit.general.suitable_for')}</label>
                          <Select
                            name='suitable_fors'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={this.props.supplier.supplier_convention_bureau.suitable_fors}
                            getOptionValue={(option) => option.attribute_name}
                            getOptionLabel={option => option[`name_${i18n.language}`]}
                            placeholder={t('select_all_that_apply')}
                            value={getSelectedOptions(this.props.supplier.supplier_convention_bureau.suitable_fors)}
                            isSearchable
                            isClearable
                            isMulti
                            onChange={(values) => this.props.onMultipleSelectChange('suitable_fors', values, true)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                        <Col className='mt-3' xs={12}>
                          <label>{t('payment_options')}</label>
                          <Select
                            name='payment_info'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={this.props.supplier.payment_info.payment_options}
                            getOptionValue={(option) => option.attribute_name}
                            getOptionLabel={option => option[`name_${i18n.language}`]}
                            placeholder={t('select_all_that_apply')}
                            value={getSelectedOptions(this.props.supplier.payment_info.payment_options)}
                            isSearchable
                            isMulti
                            onChange={(values) => this.props.onPaymentSelectChange(values)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                      </Row>
                      <Can perform='manage' on='all'>
                        <Row className='mt-3 mb-3'>
                          <Col xs={12}>
                            <label>{t('hashtags')}</label>
                            <HashtagsSelect
                              name='hashtags'
                              value={this.props.supplier.supplier_convention_bureau.hashtags}
                              onChange={this.props.onDeliverableChange}
                              className='defaultSelectDropdown'
                              classNamePrefix='defaultSelectDropdown'
                            />
                          </Col>
                        </Row>
                      </Can>
                    </AccordionItemPanel>
                  </AccordionItem>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : null
    )
  }
}

ConventionBureauGeneral.propTypes = {
  supplier: PropTypes.object,
  onChange: PropTypes.func,
  onDeliverableChange: PropTypes.func,
  onPaymentInfoChange: PropTypes.func
}

export default withTranslation()(ConventionBureauGeneral)
