import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
import BreadcrumbBar from '../BreadcrumbBar/BreadcrumbBar'
import Logo from '../Logo/Logo'
import './Header.scss'
import NavigationCartMenu from '../NavigationCartMenu/NavigationCartMenu'
import { withTranslation } from 'react-i18next'
import NavigationActionButtons from '../NavigationActionButtons/NavigationActionButtons'
import { fetchCartItems } from '../../redux/actions'
import SupplierFilterBar from '../FilterBar/SupplierFilterBar'
import MomentsFilterBar from '../FilterBar/MomentsFilterBar'

class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      stickyHeader: false
    }
    this.getListedLink = this.getListedLink.bind(this)
    this.listenToScroll = this.listenToScroll.bind(this)
  }

  getListedLink () {
    const currentLocale = this.props.i18n.language
    if (currentLocale === 'de') {
      return 'https://sites.miceportal.com/mice-dienstleistereintrag'
    }

    return 'https://corporate.miceportal.com/en/event-business-community'
  }

  componentDidMount () {
    window.addEventListener('scroll', this.listenToScroll)
    this.props.fetchCartItems()
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll () {
    if (window.pageYOffset > 35) {
      if (!this.state.stickyHeader) {
        this.setState({ stickyHeader: true })
      }
    } else {
      if (this.state.stickyHeader) {
        this.setState({ stickyHeader: false })
      }
    }
  }

  render () {
    const {
      hideBreadcrumb,
      hideSearchBar,
      hideSupplierSearchBar,
      hideHeaderButtons,
      filterMode,
      isLoggedIn,
      t
    } = this.props
    return (
      <>
        <header className='c-layout-header c-layout-header-4 c-layout-header-default-mobile fixed-top c-bg-grey-1'>
          <div className='header-container client-theme--bg theme-filter'>
            <Row className='m-0 justify-content-between'>
              <Col
                className='p-0'
                md={{ size: 1, order: 1 }}
                xs={{ size: 4, order: 1 }}
              >
                <Link to='/'>
                  <Logo />
                </Link>
              </Col>
              <Col
                md={{ size: hideHeaderButtons ? 9 : 3, order: 2 }}
                xs={{ size: 12, order: 3 }}
                className='d-flex align-items-center justify-content-center'
              />
              <Col
                md={{ size: hideHeaderButtons ? 2 : 8, order: 3 }}
                xs={{ size: 8, order: 2 }}
                className='text-right'
              >
                <div className='c-topbar c-topbar-dark transition'>
                  <nav className='c-top-menu c-pull-right topMenu'>
                    {!isLoggedIn && !hideHeaderButtons ? (
                      <a
                        href={this.getListedLink()}
                        target='_blank'
                        className='c-font-uppercase c-get-listed mr-mobile-2 header-button'
                        rel='noopener noreferrer'
                      >
                        <i
                          className='fa fa-house-user mr-1'
                          aria-hidden='true'
                        />
                        {t('start_page.get_listed')}
                      </a>
                    ) : null}
                    {hideSearchBar || hideHeaderButtons ? null : (
                      <a
                        href={`${window.SHOP_URL}/projects/wizard/start`}
                        className='c-font-uppercase c-get-listed client-theme--font-color mr-mobile-5 header-button'
                      >
                        <i className='far fa-calendar-plus mr-2' />
                        {this.props.t('start_page.start_project')}
                      </a>
                    )}
                    <ul className='c-ext c-theme-ul client-theme--font-color mr-2'>
                      <li className='c-cart-toggler-wrapper'>
                        <NavigationCartMenu />
                      </li>
                    </ul>
                    <NavigationActionButtons />
                  </nav>
                </div>
              </Col>
            </Row>
          </div>
          {hideSearchBar ? null : (
            <Row>
              <Col className='p-0 m-auto' md={{ size: 9 }} xs={{ size: 11 }}>
                {filterMode === 'suppliers' ? (
                  <SupplierFilterBar
                    hideSupplierSearchBar={hideSupplierSearchBar}
                    isInsideHeader
                    suitable={this.props.suitable}
                    locationId={this.props.locationId}
                  />
                ) : (
                  <MomentsFilterBar />
                )}
              </Col>
            </Row>
          )}
          {!hideBreadcrumb ? (
            <div className='c-container'>
              <BreadcrumbBar filterMode={filterMode} />
            </div>
          ) : null}
        </header>
      </>
    )
  }
}

Header.defaultProps = {
  hideBreadcrumb: false
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.app.isLoggedIn,
    user: state.app.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCartItems: (payload) => dispatch(fetchCartItems(payload))
  }
}

Header.propTypes = {
  isLoggedIn: PropTypes.bool,
  user: PropTypes.object,
  searchParams: PropTypes.object,
  fetchCartItems: PropTypes.func
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(Header)))
