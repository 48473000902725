import { getI18n } from 'react-i18next'

export default () => {
  const i18n = getI18n()
  const locale = i18n.language

  const result = [
    {
      '@context': 'http://schema.org/',
      '@type': 'Website',
      name: 'MICE Portal',
      url: 'https://www.miceportal.com/',
      potentialAction: {
        '@type': 'SearchAction',
        target: 'https://www.miceportal.com/result?query={search_term_string}',
        'query-input': 'required name=search_term_string'
      }
    },
    {
      '@context': 'http://schema.org/',
      name: 'MICE Portal',
      url: `https://www.miceportal.com/?locale=${locale}`,
      description: i18n.t('structured_data.main_description'),
      image: {
        '@type': 'ImageObject',
        url: 'https://corporate.miceportal.com/hs-fs/hubfs/website/layout/logo_mice-portal.png?width=135&name=logo_mice-portal.png',
        width: '100.16',
        height: '40'
      },
      '@type': 'Organization',
      telephone: '+49 (0) 816899741000',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Am Bachfeld 1',
        addressLocality: 'Attenkirchen',
        addressRegion: 'Bavaria',
        postalCode: '85395',
        addressCountry: 'Germany'
      },
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer support',
        telephone: '[+49 (0) 816899741000]',
        availableLanguage: [
          'English',
          'German'
        ],
        email: 'info@miceportal.com'
      },
      sameAs: [
        'https://de.linkedin.com/company/mice-portal-gmbh',
        'https://www.facebook.com/miceportalgmbh',
        'https://twitter.com/mice_portal',
        'https://www.instagram.com/mice_portal/'
      ]
    }
  ]

  if (!window.structuredData) {
    const seoUrlMap = JSON.parse(document.getElementById('seo-url-map').getAttribute('data'))

    const structuredData = []

    seoUrlMap.forEach((seoUrl) => {
      structuredData.push(
        {
          '@context': 'http://schema.org/',
          '@type': 'SiteNavigationElement',
          name: seoUrl[`title_${locale}`],
          url: seoUrl[`seo_path_${locale}`]
        }
      )
    })

    window.structuredData = structuredData
  }

  return result.concat(window.structuredData)
}
