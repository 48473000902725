import { getI18n } from 'react-i18next'
import { isEmpty } from 'lodash'

export default ({
  supplier,
  feedback
}) => {
  if (isEmpty(supplier)) return

  const locale = getI18n().language
  const type = supplier.supplier_location
  const amenities = [...type.amenities, ...type.gastronomy_amenities, ...type.sport_and_wellness_amenities]

  return [{
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    address: {
      '@type': 'PostalAddress',
      streetAddress: supplier.address.street,
      addressLocality: supplier.city,
      addressRegion: supplier.province,
      postalCode: supplier.address.post_code,
      addressCountry: supplier.address.country[`name_${locale}`]
    },
    name: supplier.name,
    url: `https://www.miceportal.com/location/${supplier.id}?locale=${locale}`,
    image: supplier.supplier_location_general_images[0] ? supplier.supplier_location_general_images[0].url : '',
    email: supplier.email,
    telephone: supplier.phone_number,
    ...(!isEmpty(feedback) && feedback.summary && feedback.summary.feedback_amount > 0 ? {
      aggregateRating: {
        '@type': 'AggregateRating',
        reviewCount: feedback && feedback.summary ? feedback.summary.feedback_amount : null,
        ratingValue: feedback && feedback.summary ? feedback.summary.feedback_average : null,
        bestRating: 5
      }
    } : {}),
    amenityFeature: amenities.map((amenity) => ({
      '@type': 'LocationFeatureSpecification',
      name: amenity[`name_${locale}`]
    }))
  }]
}
