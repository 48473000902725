import React from 'react'
import './HeaderTopBar.scss'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import NavigationActionButtons from '../NavigationActionButtons/NavigationActionButtons'

class HeaderTopBar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showGetOurSolutionNav: false
    }
  }

  render () {
    const { t } = this.props
    return (
      <div id='headerTopBar' className='c-topbar c-topbar-dark transition headerTopBar' style={{ textAlign: 'right', position: 'relative', top: '-8px' }}>
        {this.state.showGetOurSolutionNav ? (
          <div style={{ justifyContent: 'space-evenly' }} className='row m-0'>
            <div className='col-md-6 col-3'>
              <nav className='c-top-menu c-pull-left topMenu'>
                <ul className='c-links c-theme-ul client-theme--color'>
                  <li className='lightColor' style={{ position: 'relative', left: '-3px' }}>
                    <a href='https://www.miceportal.com/mice-softwareloesung'>
                      <i className='ti-home client-theme--color' />{' '}
                      <span className='corporate-link'>{t('get_our_corporate_solution')}</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        ) : null}
        <div className={`${this.state.showGetOurSolutionNav ? 'col-6' : 'col-12'} p-0 mt-3`}>
          <NavigationActionButtons />
        </div>
      </div>
    )
  }
}

HeaderTopBar.propTypes = {
  t: PropTypes.func
}

export default withTranslation()(HeaderTopBar)
