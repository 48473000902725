import React from 'react'
import './HotelDetails.scss'
import { Col, Row } from 'reactstrap'
import SupplierFeaturesBox from '../SupplierFeaturesBox/SupplierFeaturesBox'
import SupplierSuitableForBox from '../SupplierSuitableForBox/SupplierSuitableForBox'
import SupplierTermsConditions from '../SupplierTermsConditions/SupplierTermsConditions'
import SimpleMap from '../SimpleMap/SimpleMap'
import SupplierDetailedRatingBox from '../SupplierDetailedRatingBox/SupplierDetailedRatingBox'
import ClientFeedbackCarouselBox from '../ClientFeedbackCarouselBox/ClientFeedbackCarouselBox'
import SupplierImageGridGallery from '../SupplierImageGridGallery/SupplierImageGridGallery'
import { withTranslation } from 'react-i18next'
import { getLogoUrl, sortArrayByKey } from '../../shared/utils'
import SupplierNoteList from '../SupplierNoteList/SupplierNoteList'
import TagsBox from '../TagsBox/TagsBox'

class HotelDetails extends React.Component {
  constructor (props) {
    super(props)
    this.getThumbnailUrl = this.getThumbnailUrl.bind(this)
    this.getHotelInfo = this.getHotelInfo.bind(this)
    this.renderCategoriesOf = this.renderCategoriesOf.bind(this)
  }

  getHotelInfo (key) {
    return this.props.supplier &&
    this.props.supplier.supplier_hotel &&
    this.props.supplier.supplier_hotel[key]
      ? this.props.supplier.supplier_hotel[key]
      : null
  }

  getThumbnailUrl () {
    return getLogoUrl(this.props.hit)
  }

  renderCategoriesOf (deliverable) {
    const categories = (deliverable && deliverable.categories_list) || []
    const lang = this.props.i18n.language

    return sortArrayByKey(categories.filter(item => item.image_url), `name_${lang}`).map(
      (category, idx) =>
        <Col className='mb-3' key={idx} xl={3} lg={4} md={6} sm={12}>
          <SupplierSuitableForBox imageUrl={category.image_url} label={category[`name_${lang}`]} />
        </Col>
    )
  }

  render () {
    const { t, supplier } = this.props
    const detailsKey = 'supplier.home.tabs.details.'
    return (
      <>
        <div className='container'>
          <Row className='m-0'>
            {supplier.certificates && supplier.certificates.length > 0 ? (
              <Col className='mb-3'>
                <>
                  <TagsBox
                    title={t(`${detailsKey}certificates.title`)}
                    tags={supplier.certificates}
                    certificate_files={supplier.certificate_files}
                  />
                </>
              </Col>
            ) : null}
            {supplier.co2_certificates && supplier.co2_certificates.length > 0 ? (
              <Col className='mb-3'>
                <>
                  <TagsBox
                    title={t(`${detailsKey}co2_certificates.title`)}
                    co2_certificates={supplier.co2_certificates}
                  />
                </>
              </Col>
            ) : null}
          </Row>
          <Row className='m-0'>
            <Col className='mb-3' md={4}>
              <SupplierFeaturesBox
                title={t(`${detailsKey}general_information.title`)}
                features={[
                  {
                    label: t(`${detailsKey}general_information.labels.check_in`),
                    value: this.getHotelInfo('check_in_time')
                  },
                  {
                    label: t(`${detailsKey}general_information.labels.check_out`),
                    value: this.getHotelInfo('check_out_time')
                  },
                  {
                    label: t(`${detailsKey}general_information.labels.late_check_out`),
                    value: this.getHotelInfo('check_out_lat_time') ? 'iconYes' : 'iconNo'
                  },
                  {
                    label: t('number_of_rooms'),
                    value: supplier.general_rooms_information && supplier.general_rooms_information.number_of_rooms
                  },
                  {
                    label: t(`${detailsKey}general_information.labels.number_of_conference_rooms`),
                    value: this.getHotelInfo('capacity_number_of_conference_rooms')
                  },
                  {
                    label: t(`${detailsKey}general_information.labels.last_refurbished`),
                    value: this.getHotelInfo('last_refurbishment')
                  }
                ]}
                paymentIcons={
                  this.props.supplier && this.props.supplier.payment_info ? this.props.supplier.payment_info : {}
                }
              />
            </Col>
            <Col className='mb-3' md={4}>
              <SupplierFeaturesBox
                title={t(`${detailsKey}parking.title`)}
                features={[
                  {
                    label: t(`${detailsKey}parking.labels.max_parking`),
                    value: this.getHotelInfo('parking_spaces')
                  },
                  {
                    label: t(`${detailsKey}parking.labels.inside_parking`),
                    value: this.getHotelInfo('parking_spaces_garage')
                  },
                  {
                    label: t(`${detailsKey}parking.labels.outside_parking`),
                    value: this.getHotelInfo('parking_spaces_outside')
                  },
                  {
                    label: t(`${detailsKey}parking.labels.parking_spaces_count`),
                    value: this.getHotelInfo('parking_spaces_count')
                  },
                  {
                    label: t(`${detailsKey}parking.labels.free_parking`),
                    value: this.getHotelInfo('parking_free') ? 'iconYes' : 'iconNo'
                  },
                  {
                    label: t(`${detailsKey}parking.labels.private_parking`),
                    value: this.getHotelInfo('private_parking') ? 'iconYes' : 'iconNo'
                  },
                  {
                    label: t(`${detailsKey}parking.labels.shuttle_service`),
                    value: this.getHotelInfo('shuttle_service') ? 'iconYes' : 'iconNo'
                  }
                ]}
              />
            </Col>
            <Col className='mb-3' md={4}>
              <SupplierFeaturesBox
                title={t(`${detailsKey}distances.title`)}
                features={[
                  {
                    label: t(`${detailsKey}distances.labels.airport`),
                    value: this.props.hit.distance_airport ? `${this.props.hit.distance_airport} km` : '-'
                  },
                  {
                    label: t(`${detailsKey}distances.labels.train_station`),
                    value: this.props.hit.distance_station ? `${this.props.hit.distance_station} km` : '-'
                  },
                  {
                    label: t(`${detailsKey}distances.labels.city_center`),
                    value: this.props.hit.distance_city ? `${this.props.hit.distance_city} km` : '-'
                  },
                  {
                    label: t(`${detailsKey}distances.labels.main_road`),
                    value: this.props.hit.distance_main_road ? `${this.props.hit.distance_main_road} km` : '-'
                  },
                  {
                    label: t(`${detailsKey}distances.labels.public_transport`),
                    value: this.props.hit.distance_public_transport
                      ? `${this.props.hit.distance_public_transport} km`
                      : '-'
                  },
                  {
                    label: t(`${detailsKey}distances.labels.local_station`),
                    value: this.props.hit.distance_local_station ? `${this.props.hit.distance_local_station} km` : '-'
                  },
                  {
                    label: t(`${detailsKey}distances.labels.convention`),
                    value: this.props.hit.distance_convention ? `${this.props.hit.distance_convention} km` : '-'
                  }
                ]}
              />
            </Col>
          </Row>
          <Row className='m-0 mt-3 mb-3'>
            {this.renderCategoriesOf(this.props.supplier && this.props.supplier.supplier_hotel)}
          </Row>
          {this.props.clientContracts && this.props.clientContracts.items && this.props.clientContracts.items.length > 0 ? (
            <Row className='pl-3 pr-3 m-0 mt-3 mb-3'>
              <SupplierTermsConditions
                startDate={this.props.startDate}
                contracts={this.props.clientContracts.items}
                title={t(`${detailsKey}terms.title`)}
              />
            </Row>
          ) : null}
          <Row className='pl-3 pr-3 m-0 mt-3 mb-3'>
            <Col md={12} className='mt-3 mb-3 supplier-feature-box-round' style={{ background: '#ffffff', padding: '15px' }}>
              {this.props.hit && this.props.hit._geoloc ? (
                <SimpleMap
                  center={{
                    lat: this.props.hit._geoloc.lat,
                    lng: this.props.hit._geoloc.lng
                  }}
                  zoom={14}
                  height='350px'
                  markers={[
                    {
                      lat: this.props.hit._geoloc.lat,
                      lng: this.props.hit._geoloc.lng,
                      title: this.props.hit.name || 'Hotel Leonardo Da Vinci',
                      imageUrl: this.getThumbnailUrl()
                    }
                  ]}
                />
              ) : null}
            </Col>
          </Row>
          <Row className='mt-3 mb-3 supplier-feature-box-round' style={{ marginLeft: '15px', marginRight: '15px', background: '#ffffff' }}>
            {this.props.clientFeedbacks.summary && this.props.clientFeedbacks.summary.feedback_average > 0 ? (
              <Col className='pl-0 pr-0' xl={4} lg={4} md={6} sm={12}>
                <SupplierDetailedRatingBox ratingsSummary={this.props.clientFeedbacks.summary} />
              </Col>
            ) : (
              <Col className='pl-0 pr-0' xl={4} lg={4} md={6} sm={12}>
                <div className='suplierRatingBoxWrapper'>
                  <div className='c-content-label c-font-uppercase c-font-bold c-bg-regular'>
                    {t('rating_items.rating_title')}
                  </div>
                  <div className='m-0 mt-3 mb-3'>
                    <ul className='list-unstyled supplierRatingList'>
                      <li className='supplierRatingListItem'>
                        <div className='supplierRatingListItemLabel'>{t('rating_items.equipment_and_state_of_rooms')}</div>
                        <div className='supplierRatingListItemProgressBar'>
                          <div className='defaultProgressBar' />
                        </div>
                      </li>
                      <li className='supplierRatingListItem'>
                        <div className='supplierRatingListItemLabel'>{t('rating_items.quality_of_catering')}</div>
                        <div className='supplierRatingListItemProgressBar'>
                          <div className='defaultProgressBar' />
                        </div>
                      </li>
                      <li className='supplierRatingListItem'>
                        <div className='supplierRatingListItemLabel'>{t('rating_items.friendliness_of_staff')}</div>
                        <div className='supplierRatingListItemProgressBar'>
                          <div className='defaultProgressBar' />
                        </div>
                      </li>
                      <li className='supplierRatingListItem'>
                        <div className='supplierRatingListItemLabel'>{t('rating_items.equipment_and_state_of_conference_rooms')}</div>
                        <div className='supplierRatingListItemProgressBar'>
                          <div className='defaultProgressBar' />
                        </div>
                      </li>
                      <li className='supplierRatingListItem'>
                        <div className='supplierRatingListItemLabel'>{t('rating_items.price_quality_relation')}</div>
                        <div className='supplierRatingListItemProgressBar'>
                          <div className='defaultProgressBar' />
                        </div>
                      </li>
                    </ul>
                    <div className='m-0 mt-3 mb-3'>
                      <p>{t('rating_items.no_rating')}</p>
                    </div>
                  </div>
                </div>
              </Col>
            )}
            {this.props.clientFeedbacks.items && this.props.clientFeedbacks.items.length > 0 ? (
              <Col className='pl-0' xl={8} lg={8} md={6} sm={12}>
                <ClientFeedbackCarouselBox
                  title={t('supplier.home.tabs.details.feedback.title')}
                  feedbacks={this.props.clientFeedbacks.items}
                />
              </Col>
            ) : (
              <Col xl={8} lg={8} md={6} sm={12}>
                <div className='clientFeedbackWrapper'>
                  <div className='c-content-label c-font-uppercase c-font-bold c-bg-regular'>{t('supplier.home.tabs.details.feedback.title')}</div>
                  <div className='react-multi-carousel-list clientFeedbackCarouselContainer' style={{ marginTop: '25px' }}>
                    <ul className='react-multi-carousel-track' style={{ width: '100%' }}>
                      <li className='react-multi-carousel-item  clientFeedbackCarouselItem' style={{ flex: '1 1 auto' }}>
                        <div className='item'>
                          <div className='c-content-testimonial-3 c-option-light'>
                            <div className='c-content' style={{ fontSize: '1.1rem' }}>
                              {t('supplier.home.tabs.details.feedback.no_feedback_notice')}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
        {this.props.supplier && this.props.supplier.supplier_hotel_general_images && this.props.supplier.supplier_hotel_general_images.length > 2 ? (
          <Row className='m-0'>
            <SupplierImageGridGallery images={this.props.supplier.supplier_hotel_general_images} />
          </Row>
        ) : (
          <Row className='m-0' />
        )}
        <SupplierNoteList
          notes={this.props.supplier.notes}
          supplierId={this.props.supplier.id}
        />
      </>
    )
  }
}
export default withTranslation()(HotelDetails)
