import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { connectHits, Pagination, connectStats, Index, Configure } from 'react-instantsearch-dom'
import { GeoSearch, Control } from 'react-instantsearch-dom-maps'
import queryString from 'qs'
import { scrollToTop } from '../../shared/utils'
import MiceMomentCardLite from '../MiceMoments/MiceMomentCardLite'
import { MomentMapMarker } from '../MiceMoments/MomentMapMarker'
import { SearchContext } from '../../contexts/SearchContext'
import NoResultsNote from './NoResultsNote'
import { mapStyles } from '../../MapStyles.js'
import './MomentsResults.scss'
import { getGoogleMaps } from '../../services/googleMaps'
import { Row, Col } from 'reactstrap'
import BannerAdExtractor from '../BannerAd/BannerAdExtractor'
import SelectedLocationPinMarker from '../SelectedLocationPinMarker/SelectedLocationPinMarker'
import { setHighlightedSupplier } from '../../redux/actions'
var dayjs = require('dayjs')

const MomentHits = connectHits(MiceMomentCardLite.Wrapper)

/* The purpose of this component is to pass the hits counter to the context, so
 * that other objects - maybe upper in the component tree - can read it.
 *
 * (TODO: How can I pass the name of the setter, which I want from the context?
 * If I could do this, then I could reuse this component also in SupplierResults)
 **/
const CounterSnoop = connectStats(({ nbHits }) => {
  const { setMomentHitsCount } = React.useContext(SearchContext)

  // Not sure about useLayoutEffect - it may be better, right? This update will trigger context update, and by it another re-render.
  React.useEffect(() => { setMomentHitsCount(nbHits) }, [nbHits])

  return null
})

const handlePaginationClick = (event) => {
  const et = event.target
  if (et && et.matches && et.matches('.ais-Pagination-link:not(.ais-Pagination-link--selected)')) {
    if (et.matches('.ais-Pagination-item:not(.ais-Pagination-item--disabled) *')) {
      scrollToTop()
    }
  }
}

function MomentsResults ({ t, onMapToggle, queryPathname, queryParams, setHighlightedSupplier, searchLocation, withMap, highlightedSupplier }) {
  const googleMaps = getGoogleMaps()

  function renderMomentsFilter (isMapView) {
    return (
      <nav className='momentsFilter invisible-mobile'>
        <Link className='cbp-filter-item' to={onMapToggle}>
          {t(isMapView ? 'hide_map' : 'show_map')}
        </Link>
      </nav>
    )
  }

  function urlWithReplaced (params) {
    return {
      pathname: queryPathname,
      search: queryString.stringify({ ...queryParams, ...params })
    }
  }

  // NOTE: after clicking on a hashtag it might be nice to also scroll to the top of the page.
  // This behaviour is shared by many, but not all the links here. Make it somehow nice and readable, ok?
  //
  const hashtagRenderer = queryPathname ? tagName => {
    return <Link tabIndex='-1' to={urlWithReplaced({ query: tagName })}>{tagName}</Link>
  } : null

  const searchRegion = searchLocation && searchLocation.region

  const clearHighlightedItem = () => { setHighlightedSupplier(null) }

  const currentTimestamp = dayjs().unix()
  const highlightedId = highlightedSupplier && highlightedSupplier.objectID
  return (
    <Row
      className={`MomentsResults ${withMap ? 'pr-0 with-map' : 'without-map'} ml-0 mr-0`}
    >
      <Index
        indexId='moment-results'
        indexName={window && window.ALGOLIA_MOMENTS_INDEX_NAME}
      >
        <Configure
          filters={`active: true AND start_date_timestamp < ${currentTimestamp} AND end_date_timestamp > ${currentTimestamp}`}
        />
        <Col
          className='itemList'
          onClick={handlePaginationClick}
          lg={{ size: withMap ? 7 : 12, order: 1 }}
          md={{ size: withMap ? 6 : 12, order: 1 }}
          xs={{ size: 12, order: 2 }}
        >
          <div className='momentsFilterWrapper w-100'>
            {renderMomentsFilter(withMap)}
          </div>
          <CounterSnoop />
          <BannerAdExtractor position='moments'>
            <MomentHits
              onMouseEnter={setHighlightedSupplier}
              onMouseLeave={clearHighlightedItem}
              hashtagRenderer={hashtagRenderer}
            />
          </BannerAdExtractor>
          <NoResultsNote limit={3} context='moments' />
          <Pagination
            /* defaultRefinement={props.queryParams.page || 1} - no queryParams here */
            /* translations={ ... } */
            showFirst
            showPrevious
            showNext
            showLast
            padding={2}
            className='supplier-card-pagination'
            translations={{
              ariaPrevious: t('previous_page'),
              ariaNext: t('next_page'),
              ariaFirst: t('first_page'),
              ariaLast: t('last_page')
            }}
          />
        </Col>
        {withMap ? (
          <Col
            className='mapContainer'
            lg={{ size: 5, order: 2 }}
            md={{ size: 6, order: 2 }}
            xs={{ size: 12, order: 1 }}
          >
            <div className='map-wrapper'>
              <GeoSearch
                google={window.google}
                streetViewControl
                mapTypeControl
                rotateControl
                streetViewControlOptions={{ position: googleMaps ? googleMaps.ControlPosition.RIGHT_TOP : '' }}
                zoomControl
                zoomControlOptions={{ position: googleMaps ? googleMaps.ControlPosition.RIGHT_TOP : '' }}
                options={{
                  styles: mapStyles,
                  scrollwheel: false,
                  gestureHandling: 'greedy'
                }}
              >
                {({ hits }) => (
                  <>
                    <Control />
                    {/* searchRegion ? <Region coordinates={searchRegion.coordinates} /> : null */}
                    {hits.map(hit => {
                      const isHighlighted = hit.objectID === highlightedId
                      /* HERE BE DRAGONS! Algolia's <Marker> (used deeper) does NOT handle prop updates.
                      * You MUST unmount and re-render it for any changes to be applied.
                      * That's the reason why the `key` includes the highlighted status flag.
                      * */
                      return (<MomentMapMarker searchLocation={searchLocation} key={`${hit.objectID}-${isHighlighted}`} hit={hit} highlighted={isHighlighted} />)
                    })}
                    {searchLocation && !searchRegion ? (
                      <SelectedLocationPinMarker searchLocation={searchLocation} />
                    ) : null}
                  </>
                )}
              </GeoSearch>
            </div>
          </Col>
        ) : null}
      </Index>
    </Row>
  )
}

MomentsResults.propTypes = {
  withMap: PropTypes.bool,
  onMapToggle: PropTypes.oneOfType([ // whatever the `<Link to=... >` accepts.
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  queryParams: PropTypes.object,
  queryPathname: PropTypes.string
}

const mapStateToProps = state => {
  return {
    searchLocation: state.app.searchLocation,
    highlightedSupplier: state.app.highlightedSupplier
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setHighlightedSupplier: payload => dispatch(setHighlightedSupplier(payload))
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MomentsResults))
