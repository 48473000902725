import React from 'react'
import './SupplierPreview.scss'
import { Col, Row } from 'reactstrap'
import SupplierMarkup from '../SupplierMarkup/SupplierMarkup'
import { Link } from 'react-router-dom'
import defaultThumbnail from '../../assets/images/blank-hotel.jpg'
import { withTranslation, getI18n } from 'react-i18next'
import { getSupplierFormattedAddress } from '../../shared/utils'

function SupplierPreview ({ deliverable, t }) {
  const supplierURL = `/${deliverable.type.replace(/_/g, '-')}/${deliverable.supplier.id}`
  let supplierMainImageURL = defaultThumbnail

  if (deliverable.supplier.main_image) {
    supplierMainImageURL = deliverable.supplier.main_image.url || `https://res-1.cloudinary.com/mice-portal/${deliverable.supplier.main_image.cloudinary_public_id}`
  }

  return (
    <Row className='mr-0 ml-0 mb-4 supplier-preview-container'>
      <Col className='mt-1 mb-3 pl-0 pr-0' xs={12}><p>{t('hosted_by')}</p></Col>
      <Col className='pl-0 pr-0' xs={12} md={2}>
        <Link to={supplierURL}>
          <div className='d-flex justify-content-center'>
            <img className='w-100' src={supplierMainImageURL} alt={deliverable.supplier.name} />
          </div>
        </Link>
      </Col>
      <Col xs={12} md={10}>
        <Col className='pr-0' xs={12}>
          <div className='d-flex'>
            <div className='h4 mb-0 d-inline-block'><Link to={supplierURL}>{deliverable.supplier.name}</Link></div>
            <div className='pl-2 d-inline-block '>
              <SupplierMarkup
                backgroundClass='mt-0 pt-0'
                feedbackAverage={deliverable.supplier.feedback_average}
                professional={deliverable.supplier.professional}
                airplus={deliverable.supplier.payment_info.airplus}
                classification={deliverable.classification}
                micepoints={deliverable.micepoints}
              />
            </div>
          </div>
        </Col>
        <Col className='pr-0' xs={12}>
          <div className='h6'>
            <span className='pr-2 h4'>
              <i className='fa fa-map-marker' />
            </span>
            {getSupplierFormattedAddress(deliverable.supplier.address, getI18n().language)}
          </div>
        </Col>
      </Col>
    </Row>
  )
}

export default withTranslation()(SupplierPreview)
