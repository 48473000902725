import React from 'react'
import PropTypes from 'prop-types'
import Can from '../../Layout/Can'
import { withTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'
import * as qs from 'qs'

class SupplierRecord extends React.Component {
  constructor (props) {
    super(props)

    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this)
  }

  handleDeleteButtonClick () {
    this.props.onDelete(this.props.deliverable)
  }

  linkToShow () {
    let params = {}
    const { supplier, match, deliverable } = this.props

    if (match && match.params) {
      const { userType, objectID } = match.params
      if (userType === 'sales_managers') {
        params = { smId: objectID }
      }
    }
    const query = qs.stringify(params)
    return `/${deliverable.deliverable_type.replace(/_/g, '-')}/${supplier.id}?${query}`
  }

  linkToEdit () {
    const { supplier, deliverable, match } = this.props

    if (match && match.params) {
      const { userType, objectID } = match.params

      if (userType === 'sales_managers') {
        return `/sales_managers/${objectID}/admin/suppliers/${supplier.id}/edit/${deliverable.deliverable_type}`
      }
    }

    return `/suppliers/${supplier.id}/edit/${deliverable.deliverable_type}`
  }

  renderDeleteButton () {
    const { supplier, deliverable } = this.props

    if (deliverable.temporary_record || supplier.deliverables.filter(d => !d.temporary_record).length > 1) {
      return (
        <Can perform='destroy' on={this.props.supplier}>
          <a
            href='#'
            className='btn c-btn-dark c-btn-square c-btn-uppercase c-btn-border-2x c-btn-bold'
            style={{ marginTop: '10px' }}
            onClick={this.handleDeleteButtonClick}
          >
            <i className='fa fa-close' aria-hidden='true' />
            {this.props.t('common.remove')}
          </a>
        </Can>
      )
    }
  }

  renderStars () {
    const stars = []

    if (this.props.deliverable.classification) {
      for (let i = 0; i < this.props.deliverable.classification / 10; i++) {
        stars.push(<figure key={`star-${i}`} className='fa fa-star dehoga' aria-hidden='true' />)
      }
    } else if (this.props.deliverable.micepoints) {
      for (let i = 0; i < this.props.deliverable.micepoints / 10; i++) {
        stars.push(<figure key={`micepoint-${i}`} className='fa fa-circle dehoga' aria-hidden='true' />)
      }
    }

    return stars
  }

  dataQualityScoreSliderPosition (left, right, minPct, maxPct, actualPct) {
    const width = right - left
    const pctWidth = maxPct - minPct

    const offsetPct = actualPct - minPct

    if (offsetPct < 0) {
      return left
    } else if (offsetPct >= maxPct) {
      return right
    } else {
      return (width * offsetPct) / pctWidth + left
    }
  }

  renderProfileHealthBar () {
    const { t } = this.props

    let left

    if (this.props.deliverable.data_quality_score_pct < 20) {
      left = this.dataQualityScoreSliderPosition(-2, 14, 0, 20, this.props.deliverable.data_quality_score_pct) + '%'
    } else if (this.props.deliverable.data_quality_score_pct < 80) {
      left = this.dataQualityScoreSliderPosition(14, 74, 20, 80, this.props.deliverable.data_quality_score_pct) + '%'
    } else {
      left = this.dataQualityScoreSliderPosition(74, 92, 80, 100, this.props.deliverable.data_quality_score_pct) + '%'
    }

    return (
      <div className='progress'>
        {
          ['#e7505a', '#c5b96b', '#84c68f', '#5dc09c', '#84c68f'].map((sliceBackground, index) =>
            <div key={`profile-health-${index}`} className='progress-bar' style={{ width: '20%', borderRight: '2px solid #fff', background: sliceBackground }}>
              <span className='sr-only' />
            </div>
          )
        }

        <div
          className='tooltips'
          data-original-title={t('supplier.admin.records.record.profile_health_hint')}
          data-container='body'
          style={{
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            background: '#fff',
            border: '3px solid #52C9BA',
            position: 'absolute',
            top: '-10px',
            left: left,
            zIndex: 999,
            boxShadow: 'hsl(0, 0%, 80%) 0 0 16px'
          }}
        >
          <i className='fa fa-check' style={{ color: '#52C9BA', fontSize: '16px', lineHeight: '24px', position: 'relative', left: '4px' }} aria-hidden='true' />
        </div>
      </div>
    )
  }

  render () {
    const { t } = this.props

    return (
      <div style={{ position: 'relative' }}>
        {
          this.props.supplier.professional ? (
            <div className='ribbon-professional tooltips' data-original-title='Pro' data-container='body' aria-hidden='true' style={{ position: 'absolute', zIndex: 5, right: '10px' }}>
              <span>{t('supplier.admin.records.record.professional')}</span>
            </div>
          ) : null
        }

        <div className='col-md-12 c-margin-b-20'>
          <div className='row c-content-media-1' data-height='height' style={{ marginRight: '0', paddingTop: '20px', paddingLeft: '10px', paddingBottom: '20px', overflow: 'hidden' }}>
            <div className='col-md-4'>
              <img src={this.props.deliverable.image_url} style={{ maxWidth: '100%', height: 'auto' }} />
            </div>
            <div className='col-md-8'>
              <div className='c-title c-font-uppercase c-font-bold c-font-bold' style={{ marginTop: '0', marginBottom: '0' }}>
                {this.props.supplier.name}
                <span>{this.renderStars()}</span>
              </div>

              <p>
                <span className='label label-primary c-font-slim'>{t(`supplier.deliverable_type_names.${this.props.deliverable.deliverable_type}`)}</span>
                {
                  this.props.deliverable.temporary_record
                    ? <span className='label label-danger c-font-slim'>{t('supplier.admin.records.record.temporary')}</span>
                    : null
                }
              </p>

              <p className='c-font-dark c-font-slim' style={{ fontSize: '14px' }}>
                <i className='fa fa-map-marker' aria-hidden='true' />
                {this.props.supplier.address}
              </p>

              <p className='c-font-dark c-font-slim' style={{ fontSize: '14px' }}>
                <b>{t('supplier.admin.records.record.last_update')}</b>
                {this.props.supplier.updated_at}
              </p>

              <div className='row align-items-center mt-2 mb-2'>
                <div className='col-md-4'>
                  <p className='c-font-dark c-font-slim' style={{ fontSize: '14px' }}>
                    <b>{t('supplier.admin.records.record.profile_health')}</b>
                    <span style={{ fontSize: '18px', fontWeight: 600 }}>{this.props.deliverable.data_quality_score}/{this.props.deliverable.data_quality_maximum_score}</span>
                  </p>
                </div>

                <div className='col-md-8'>
                  <div className='c-body' style={{ position: 'relative', marginTop: '5px' }}>
                    {this.renderProfileHealthBar()}
                  </div>
                </div>
              </div>

              <Link
                className='btn c-btn-square c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold'
                style={{ marginTop: '10px' }}
                to={this.linkToShow()}
              >
                <i className='fa fa-window-maximize' aria-hidden='true' />
                {t('supplier.admin.records.record.view_record')}
              </Link>

              <Link
                className='btn c-btn-dark c-btn-square c-btn-uppercase c-btn-border-2x c-btn-bold'
                style={{ marginTop: '10px' }}
                to={this.linkToEdit()}
              >
                <i className='fa fa-edit' aria-hidden='true' />
                {t('supplier.admin.records.record.edit_record')}
              </Link>

              {this.renderDeleteButton()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SupplierRecord.propTypes = {
  match: PropTypes.object,
  supplier: PropTypes.object,
  deliverable: PropTypes.object,
  t: PropTypes.func,
  onDelete: PropTypes.func
}

export default withTranslation()(withRouter(SupplierRecord))
