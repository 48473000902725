import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './AdvertisementList.scss'
import Advertisement from '../Advertisement/Advertisement'
import axios from 'axios'
import { logAdvertisementEvent } from '../../shared/utils'

const Carousel = require('react-responsive-carousel').Carousel

const headers = {
  contentType: 'application/json',
  Accept: 'application/json'
}

class AdvertisementList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      advertisementFound: false,
      suppliers: [],
      advertisementName: null,
      advertisementID: null
    }
    this.loadAdvertisements = this.loadAdvertisements.bind(this)
  }

  componentDidMount () {
    this.loadAdvertisements()
  }

  componentDidUpdate (prevProps) {
    if (this.props.searchLocation !== prevProps.searchLocation) {
      this.loadAdvertisements()
    }
  }

  loadAdvertisements () {
    const ENDPOINT = 'advertisements.json'
    const searchParams =
      `filters[lat]=${this.props.searchLocation.lat}` +
      `&filters[lng]=${this.props.searchLocation.lng}` +
      `&filters[page_slot]=${this.props.pageSection}` +
      `&filters[subdomain]=${this.props.subdomain}`

    axios
      .get(ENDPOINT + '?' + searchParams, {}, { headers })
      .then((response) => {
        const advertisementID = response.data.id
        const suppliers = response.data.suppliers

        this.setState({
          advertisementFound: true,
          advertisementName: response.data.name,
          advertisementID: advertisementID,
          suppliers: suppliers
        })

        logAdvertisementEvent(
          'city_ad_shown',
          advertisementID,
          suppliers.map((supplier) => supplier.id)
        )
      })
      .catch((e) => {
        this.setState({
          ...this.state,
          advertisementFound: false,
          suppliers: []
        })
      })
  }

  render () {
    const suppliers = this.state.suppliers

    if (this.state.advertisementFound) {
      const advertisementID = this.state.advertisementID

      return (
        <div style={{ gridRowStart: (this.props.pageSection === 'upper' ? '2' : 'inherit') }} className='AdvertisementList'>
          <div className='slider-container'>
            <Carousel
              infiniteLoop
              autoPlay
              interval={6000}
              className='carousel-style'
              showArrows
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              swipeable={false}
            >
              {suppliers.map((supplier, idx) => <Advertisement supplier={supplier} key={idx} advertisementID={advertisementID} />)}
            </Carousel>
          </div>
        </div>
      )
    } else {
      return <div />
    }
  }
}
export default AdvertisementList
