import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { languageSettings } from './LanguageSettings.js'

import { registerLocale } from 'react-datepicker'
import en from 'date-fns/locale/en-GB'
import de from 'date-fns/locale/de'
import RollbarErrorTracking from './shared/RollbarErrorTracking'
var dayjs = require('dayjs')
registerLocale('en', en)
registerLocale('de', de)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: window.reactAppTranslations, // eslint-disable-line
    lng: window.CURRENT_LOCALE,
    whitelist: languageSettings.languageList,
    fallbackLng: {
      default: languageSettings.languageList
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase()
        if (value instanceof Date) return dayjs(value).format(format)
        return value
      }
    },
    saveMissing: true,
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      const errorMessage = `Missing translation key ${key} for language(s): ${lng}`
      if (window.KONVENIT_ENVIRONMENT === 'development') {
        console.error(errorMessage)
      } else if (window.KONVENIT_ENVIRONMENT === 'production') {
        RollbarErrorTracking.error(errorMessage)
      } else if (window.KONVENIT_ENVIRONMENT === 'staging') {
        alert(errorMessage) // eslint-disable-line
      } else {
        throw errorMessage
      }
    }
  })

export default i18n
