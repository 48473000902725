import React from 'react'
import './SupplierStatBox.scss'
import PropTypes from 'prop-types'

class SupplierStatBox extends React.Component {
  render () {
    return (
      <div
        className='c-content-media-1 c-content-v-center c-conf-boxes supplier-feature-box-round no-shadow'
      >
        <div className='c-wrapper'>
          <div className='c-body c-center'>
            <div
              className='c-title c-font-uppercase c-font-bold c-font-bold'
              style={{ fontSize: '16px' }}
            >
              {this.props.title ? this.props.title : ''}
            </div>
            <h3 className='c-font-bold' style={{ fontSize: '40px' }}>
              {this.props.stat && this.props.stat !== 'null' ? (<span>{this.props.stat} <span className='statUnit'>{this.props.unit ? this.props.unit : ''}</span></span>) : '-'}

            </h3>
          </div>
        </div>
      </div>
    )
  }
}

SupplierStatBox.propTypes = {
  title: PropTypes.string.isRequired,
  stat: PropTypes.any
}
export default SupplierStatBox
