import React from 'react'
import { withTranslation } from 'react-i18next'
import './SupplierFilters.scss'
import Switch from 'react-switch'
import { SortBy } from 'react-instantsearch-dom'

function SupplierFilters ({ activeFilter, changeResultsFilter, onToggleMapView, t }) {
  const urlParams = new URLSearchParams(window.location.search)
  const showMapURL = urlParams.get('show_map')

  const handleContractPartnerSwitch = () => {
    if (activeFilter.includes('contracts')) {
      changeResultsFilter({ contracts: undefined })
    } else {
      changeResultsFilter({ contracts: true })
    }
  }
  const handleSustainableSwitch = () => {
    if (activeFilter.includes('sustainable')) {
      changeResultsFilter({ sustainable: undefined })
    } else {
      changeResultsFilter({ sustainable: true })
    }
  }

  return (
    <div className='c-container c-bg-grey-1 filterContainer'>
      <div className='cbp-l-filters-buttonCenter filter-grid'>
        <div className=''>
          <SortBy
            id='sortResults'
            className='sort-by-select'
            defaultRefinement={window.ALGOLIA_INDEX_NAME}
            items={[
              { value: window.ALGOLIA_INDEX_NAME, label: `${t('sort_by')}: ${t('recommended')}` },
              { value: window.ALGOLIA_BEST_RATED_INDEX_NAME, label: `${t('sort_by')}: ${t('best_rated')}` }
            ]}
          />
        </div>
        <div className='d-none d-md-flex align-items-center pl-3 pl-lg-5'>
          <Switch
            id='contracts-filter-switch'
            onChange={handleContractPartnerSwitch}
            checked={activeFilter.includes('contracts')}
            height={14}
            width={26}
            onColor='#a3c95e'
            className='pr-1'
            uncheckedIcon={false}
            checkedIcon={false}
            aria-label={`${t('show_or_hide')} ${t('contracted_partners')}`}
          />
          {t('contracted_partners')}
        </div>
        <div className='d-none d-md-flex align-items-center pl-3 pl-lg-5'>
          <Switch
            id='sustainable-filter-switch'
            onChange={handleSustainableSwitch}
            checked={activeFilter.includes('sustainable')}
            height={14}
            width={26}
            onColor='#a3c95e'
            className='pr-1'
            uncheckedIcon={false}
            checkedIcon={false}
            aria-label={`${t('show_or_hide')} ${t('sustainable_button')}`}
          />
          {t('sustainable_button')}
        </div>
        <div className='d-none d-md-flex align-items-center justify-self-end'>
          <Switch
            id='toggle-map-switch'
            onChange={onToggleMapView}
            checked={showMapURL === 'true'}
            height={14}
            width={26}
            onColor='#a3c95e'
            className='pr-1'
            uncheckedIcon={false}
            checkedIcon={false}
            aria-label={showMapURL === 'true' ? t('hide_map') : t('show_map')}
          />
          {t('show_map')}
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(SupplierFilters)
