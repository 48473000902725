/* eslint-disable */

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from 'react-dom/client';
import "./i18n";
import App from "./App";
import "./styles.scss";
import '@fortawesome/fontawesome-pro/css/all.css';
import configureStore from "./redux/store";
import { Provider } from "react-redux";
import "./assets/fonts/RobotoCondensed-Regular.ttf"
import "./assets/fonts/RobotoCondensed-Bold.ttf"
import "./assets/fonts/RobotoCondensed-Italic.ttf"

document.addEventListener("DOMContentLoaded", () => {
  let initialState  = {}
  const initElement = document.getElementById("redux-initial-store")
  if (initElement) {
    initialState = Object.assign({}, initElement.dataset)
  }
  const store = configureStore(initialState)

  const container = document.body.appendChild(document.createElement('div'));
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  );
});
