import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import './CartItemsOverviewBox.scss'

function CartItemsOverviewBox ({ items, removeFromCart }) {
  const { t } = useTranslation()
  if (items) {
    return (
      <>
        {items && items.length > 0 ? items.map((item, idx) => {
          return (
            <Row className='overview-cart-item justify-content-between w-100 ml-0 mr-0' key={idx}>
              <Col xs={10}>
                <div className='w-100 cart-item-heading'>{item.name}</div>
                <div className='w-100 cart-item-location'>{item.city}</div>
              </Col>
              <Col className='pl-0 pr-2' xs={2}>
                <ButtonWithIcon
                  iconSize='12px'
                  iconColor='#FFF'
                  padding='6px 12px'
                  className=''
                  background='#3f444a'
                  icon='fal fa-trash-alt'
                  label=''
                  onClick={() => removeFromCart(item)}
                  ariaLabel={t('aria_labels.delete_supplier')}
                />
              </Col>
            </Row>)
        }) : null}
      </>)
  }
  return <div>No items</div>
}

export default CartItemsOverviewBox
