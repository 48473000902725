import React from 'react'
import './DistanceDurationBox.scss'

export default function DistanceDurationBox ({ duration, distance }) {
  return (
    (duration && duration.text) || (distance && distance.text)
      ? (
        <div className='direction-wrapper'>
          {duration && duration.text ? (
            <div className='duration'>
              <i className='fa fa-car' />
              {`${duration.text}`}
            </div>) : null}
          {distance && distance.text ? (
            <div className='distance'>
              {`(${distance.text})`}
            </div>
          ) : null}
        </div>)
      : null
  )
}
