import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class Alcatraz extends Component {
  render () {
    if (!window.ALCATRAZ || this.props.disabled || this.props.isLoggedIn) {
      return this.props.children
    }

    return <Redirect to='/pages/access_denied' />
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.app.isLoggedIn
  }
}

Alcatraz.propTypes = {
  isLoggedIn: PropTypes.bool
}

export default connect(mapStateToProps)(Alcatraz)
