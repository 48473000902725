import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { TextInput } from '../../../../FormElements/FormElements'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import ButtonWithIcon from '../../../../ButtonWithIcon/ButtonWithIcon'
import axios from 'axios'
import SuccessMessage from '../../../../SuccessMessage/SuccessMessage'
import ValidationErrors from '../../../../ValidationErrors/ValidationErrors'
import { scrollToRef } from '../../../../../shared/utils'
const _ = require('lodash')

class AirplusForm extends Component {
  constructor (props) {
    super(props)

    this.componentRef = React.createRef()

    const { t } = props

    this.state = {
      showSuccessMessage: false,
      isSubmiting: false,
      errors: {},
      payment_info: {},
      saveButtonDisabled: true,
      resetButtonIcon: 'fa fa-refresh',
      resetButtonLabel: t('supplier.edit.button.reset.label'),
      unsavedChanges: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  componentDidMount () {
    this.setState({ ...this.state, payment_info: { ...this.props.supplier.payment_info } })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.supplier !== this.props.supplier) {
      this.initializeStateWithExistingFormData()
    }

    if (!this.state.unsavedChanges && !_.isEmpty(prevState.payment_info) && JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.handleUnsavedChanges(prevState)
    }
  }

  initializeStateWithExistingFormData () {
    if (this.props.supplier) {
      this.setState({ ...this.state, payment_info: { ...this.props.supplier.payment_info } })
    }
  }

  handleUnsavedChanges (prevState) {
    // This check is to make sure to not detect changing the unsavedChanges attribute as unsaved changes by the user
    // So in this case it means that the user save his changes and the unsavedChanges attribute returned to false
    // Then we don't need to disable the tabs
    if (!prevState.unsavedChanges) {
      this.setState({ unsavedChanges: true, saveButtonDisabled: false })
      this.props.updateHandler(null, 'SettingsPage')
    }
  }

  handleChange ({ target }) {
    const value = target.value
    const name = target.name

    this.setState({
      showSuccessMessage: false,
      payment_info: {
        ...this.state.payment_info,
        [name]: value
      }
    })
  }

  handleReset () {
    const { t } = this.props

    this.setState({
      errors: {},
      saveButtonDisabled: true,
      resetButtonIcon: 'fa fa-spinner fa-spin',
      resetButtonLabel: t('supplier.edit.button.reset.reseting_label')
    })
    this.props.resetHandler()

    setTimeout(() => this.setState({
      resetButtonIcon: 'fa fa-refresh',
      resetButtonLabel: t('supplier.edit.button.reset.label'),
      unsavedChanges: false
    }), 2000)
  }

  handleSubmit () {
    this.setState({
      errors: {},
      isSubmiting: true,
      showSuccessMessage: false
    }, function () {
      axios
        .put(`/suppliers/${this.props.supplier.id}.json`,
          { supplier: { payment_info_attributes: this.state.payment_info }, facet: 'airplus', locale: this.props.i18n.language }
        )
        .then(response => {
          scrollToRef(this.componentRef)
          this.setState({ showSuccessMessage: true, isSubmiting: false })
          this.props.updateHandler(response.data, null)
          this.setState({ unsavedChanges: false, saveButtonDisabled: true })
        })
        .catch(error => {
          switch (error.response.status) {
            case 403:
              window.location.href = '/pages/access_denied'
              break
            case 422:
              scrollToRef(this.componentRef)
              this.setState({ errors: error.response.data.errors.payment_info_attributes, isSubmiting: false })
              break
          }
        })
    })
  }

  render () {
    const { t } = this.props

    return (
      <div ref={this.componentRef}>
        {this.props.enableUnsavedChangesTracker && this.state.unsavedChanges ? (
          <Col className='p-0 d-flex justify-content-center align-items-center'>
            <ButtonWithIcon
              labelColor='#FFFFFF'
              iconColor='#FFFFFF'
              padding='12px'
              icon={this.state.resetButtonIcon}
              label={this.state.resetButtonLabel}
              className='btn-danger c-btn-square c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold'
              onClick={this.handleReset}
              disabled={this.state.saveButtonDisabled || this.state.isSubmiting}
            />
          </Col>
        ) : null}

        <SuccessMessage message={t('changes_saved')} hidden={!this.state.showSuccessMessage} />
        <ValidationErrors validationErrors={this.state.errors} />

        <Row className='mt-0 mb-3'>
          <Col md={4} sm={6} xs={12}>
            <TextInput
              name='airplus_cardacceptorid'
              error={this.state.errors.airplus_cardacceptorid}
              value={this.state.payment_info.airplus_cardacceptorid}
              label={t('single_contract_no')}
              onChange={this.handleChange}
              placeholder={t('single_contract_no_placeholder')}
            />
          </Col>
          <Col md={4} sm={6} xs={12}>
            <TextInput
              name='airplus_posterminalid'
              error={this.state.errors.airplus_posterminalid}
              value={this.state.payment_info.airplus_posterminalid}
              label={t('mice_portal_pos')}
              onChange={this.handleChange}
              placeholder={t('mice_portal_pos_placeholder')}
            />
          </Col>
          <Col md={4} sm={6} xs={12}>
            <TextInput
              name='airplus_customer_id'
              error={this.state.errors.airplus_customer_id}
              value={this.state.payment_info.airplus_customer_id}
              label={t('customer_id')}
              onChange={this.handleChange}
              placeholder={t('customer_id_placeholder')}
            />
          </Col>
        </Row>

        <Row className='m-0 mt-4 mb-4'>
          <Col className='pl-0' xl={4} md={4} sm={8} xs={12}>
            <ButtonWithIcon
              icon={`fa ${this.state.isSubmiting ? 'fa-spinner fa-spin' : 'fa-save'}`}
              label={t('update_airplus_configuration')}
              className='client-theme--font-color client-theme--bg'
              onClick={this.handleSubmit}
              disabled={this.state.saveButtonDisabled || this.state.isSubmiting}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

AirplusForm.propTypes = {
  supplier: PropTypes.object.isRequired
}

export default withTranslation()(AirplusForm)
