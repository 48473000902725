import React from 'react'
import { Route } from 'react-router-dom'
import MainLayout from './MainLayout'
import PropTypes from 'prop-types'

const AppRoute = ({
  path,
  exact,
  component,
  hideSearchBar = true,
  hideSupplierSearchBar,
  hideBreadcrumb = true,
  filterMode,
  hideMiceMomentSearchBar,
  hideHeaderButtons,
  ...otherProps
}) => {
  const Component = component
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => (
        <MainLayout
          hideSearchBar={hideSearchBar}
          hideSupplierSearchBar={hideSupplierSearchBar}
          hideBreadcrumb={hideBreadcrumb}
          hideHeaderButtons={hideHeaderButtons}
          filterMode={filterMode}
        >
          <Component {...otherProps} {...props} />
        </MainLayout>
      )}
    />
  )
}

AppRoute.propTypes = {
  hideHeaderButtons: PropTypes.bool,
  hideSearchBar: PropTypes.bool,
  render: PropTypes.func
}

export default AppRoute
