import React from 'react'
import './ConferenceTabContent.scss'
import { Col, Row } from 'reactstrap'
import SupplierStatBox from '../SupplierStatBox/SupplierStatBox'
import SupplierEquipmentBox from '../SupplierEquipmentBox/SupplierEquipmentBox'
import SupplierSuitableForCarouselBox from '../SupplierSuitableForCarouselBox/SupplierSuitableForCarouselBox'
import SupplierConferenceRoomBox from '../SupplierConferenceRoomBox/SupplierConferenceRoomBox'
import { withTranslation } from 'react-i18next'

class ConferenceTabContent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      view: ''
    }
    this.getInfo = this.getInfo.bind(this)
  }

  getDeliverable () {
    return this.props.supplier && this.props.supplier[`supplier_${this.props.view.replace(/-/g, '_')}`]
  }

  getInfo (key) {
    switch (this.state.view) {
      case 'hotel':
        return this.props.supplier && this.props.supplier.supplier_hotel && this.props.supplier.supplier_hotel[key]
          ? this.props.supplier.supplier_hotel[key]
          : null
      case 'location':
        return this.props.supplier &&
          this.props.supplier.supplier_location &&
          this.props.supplier.supplier_location[key]
          ? this.props.supplier.supplier_location[key]
          : null
      default:
        return null
    }
  }

  componentDidMount () {
    this.setState({ view: this.props.view })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.view !== this.props.view) {
      this.setState({ view: this.props.view })
    }
  }

  render () {
    const { t, i18n } = this.props
    const conferenceKey = 'supplier.home.tabs.conference_spaces.'

    const deliverable = this.getDeliverable()

    return (
      <>
        <div className='container'>
          <Row className='m-0'>
            <Col className='mb-3' md={3}>
              <SupplierStatBox
                title={t(`${conferenceKey}stat_boxes.number_of_spaces`)}
                stat={this.getInfo('capacity_number_of_conference_rooms')}
              />
            </Col>
            <Col className='mb-3' md={3}>
              <SupplierStatBox
                title={t(`${conferenceKey}stat_boxes.event_area`)}
                stat={`${this.getInfo('capacity_max_square_meter')}`}
                unit='m&sup2;'
              />
            </Col>
            <Col className='mb-3' md={3}>
              <SupplierStatBox
                title={t(`${conferenceKey}stat_boxes.max_capacity`)}
                stat={`${this.getInfo('capacity_max_conference_capacity')}`}
                unit='PAX'
              />
            </Col>
            <Col className='mb-3' md={3}>
              <SupplierStatBox
                title={t(`${conferenceKey}stat_boxes.max_height`)}
                stat={`${this.getInfo('capacity_max_height')}`}
                unit='m'
              />
            </Col>
          </Row>
          <Row className='m-0'>
            <Col className='mb-3 mt-3' md={12}>
              <SupplierSuitableForCarouselBox
                title={t('suitable_for')}
                items={deliverable.suitable_fors}
              />
            </Col>
          </Row>
          <Row className='m-0 mt-3 mb-3'>
            <Col md={12} className='mb-3'>
              <SupplierEquipmentBox
                title={t('conference_room_equipment')}
                locale={i18n.language}
                equipment={deliverable.equipment_amenities_v2}
              />
            </Col>
            {this.props.supplier && this.props.supplier.conference_rooms && this.props.supplier.conference_rooms.length > 0
              ? this.props.supplier.conference_rooms.map((room, idx) => (
                <Col key={idx} className='p-3' xl={6} lg={6} md={6} sm={12}>
                  <SupplierConferenceRoomBox room={room} />
                </Col>
              ))
              : null}
          </Row>
        </div>
      </>
    )
  }
}
export default withTranslation()(ConferenceTabContent)
