import React from 'react'
import './RedirectionNotificationPage.scss'

class RedirectionNotificationPage extends React.Component {
  render () {
    return (
      <div className='redirectionPageContainer'>
        <div className='redirectionPageMessageWrapper'>
          <div style={{ width: '100%' }}>
            <p>You are being redirected. Please wait for the page to load</p>
          </div>

          <div style={{ width: '100%' }}>
            <div className='lds-ellipsis'>
              <div className='defaultLoaderItem client-theme--bg' />
              <div className='defaultLoaderItem client-theme--bg' />
              <div className='defaultLoaderItem client-theme--bg' />
              <div className='defaultLoaderItem client-theme--bg' />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default RedirectionNotificationPage
