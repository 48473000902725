import React from 'react'
import './SupplierBlackoutDates.scss'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { withTranslation } from 'react-i18next'

class SupplierBlackoutDates extends React.Component {
  render () {
    const { t, i18n } = this.props
    const columns = [
      {
        Header: t('supplier.home.tabs.details.terms.tabs.blackout_dates.table_heading.start_date'),
        accessor: 'start_date'
      },
      {
        Header: t('supplier.home.tabs.details.terms.tabs.blackout_dates.table_heading.end_date'),
        accessor: 'end_date'
      },
      {
        Header: t('supplier.home.tabs.details.terms.tabs.blackout_dates.table_heading.service'),
        accessor: `service.label_${i18n.language}`
      },
      {
        id: 'blackoutRate',
        Header: t('supplier.home.tabs.details.terms.tabs.blackout_dates.table_heading.blackout_rate'),
        accessor: d => (d.price ? `${d.price.number} ${d.price.currency}` : null)
      }
    ]
    return (
      <div className='blackoutDatesTableContainer'>
        <ReactTable minRows={5} showPagination={false} data={this.props.prices} columns={columns} />
      </div>
    )
  }
}
export default withTranslation()(SupplierBlackoutDates)
