import React from 'react'
import { CustomMarker } from 'react-instantsearch-dom-maps'
import SupplierCard from '../SupplierCard/SupplierCard'
import '../SupplierMapMarker/SupplierMapMarker.scss'
import { useDispatch } from 'react-redux'
import { changeOpenedSupplierPopup } from '../../redux/actions'

const SupplierCardMapPopup = (props) => {
  const dispatch = useDispatch()
  return (
    <CustomMarker
      hit={props.hit}
      className='mapMarker mapMarkerOpened'
    >
      <div className='mapMarkerPopup'>
        <span className='mapMarkerPopup__close-button' onClick={() => dispatch(changeOpenedSupplierPopup(undefined))}>
          <i className='fa fa-times' />
        </span>
        <SupplierCard hit={props.hit} />
      </div>
    </CustomMarker>
  )
}

export default SupplierCardMapPopup
