import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import MainVisualLayout from './MainVisualLayout'
import Footer from './Footer'
import './MainLayout.scss'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { searchClient } from '../../services/algolia'
import { languageSettings } from '../../LanguageSettings.js'
import { ToastContainer } from 'react-toastify'
import {
  changeSearchBoundingBox,
  saveSearchLocation,
  changeSearchLocation
} from '../../redux/actions'
import { SearchContextProvider } from '../../contexts/SearchContext'
import SuppliersIndexParams from '../../shared/suppliersIndexParams'
import MomentsIndexParams from '../../shared/momentsIndexParams'
import { withTranslation } from 'react-i18next'
import { extractQueryParams } from '../../services/urlStateParser'
import { withLanguageSwitcher } from '../../shared/languageHelpers'
import 'react-toastify/dist/ReactToastify.min.css'
import { hasAreaOfOperation, getBoundingBox } from '../../shared/utils'
import { Helmet } from 'react-helmet'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'

class MainLayout extends Component {
  constructor (props) {
    super(props)
    this.rootNodeRef = React.createRef()
    this.updateLocationObject = this.updateLocationObject.bind(this)
    this.handleToggleStagingHeader = this.handleToggleStagingHeader.bind(this)
    this.state = {
      showStagingHeader: true
    }
  }

  componentDidMount () {
    this.updateLocationObject()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.searchParams.location !== this.props.searchParams.location) {
      this.updateLocationObject()
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.rootNodeRef.current.focus()
    }
    if (prevState.showStagingHeader !== this.state.showStagingHeader) {
      // not the best solution but the DOM element that we need to manipulate is coming from a different project (Konvenit), hence this is the best approach
      const staging = document.getElementById('staging')
      if (staging) {
        if (this.state.showStagingHeader) {
          staging.style.display = 'block'
        } else {
          staging.style.display = 'none'
        }
      }
    }
  }

  updateLocationObject () {
    if (this.props.searchParams.location) {
      this.props.saveSearchLocation(this.props.searchParams.location, 'places')
    } else if (this.props.searchParams.region) {
      this.props.saveSearchLocation(this.props.searchParams.region, 'region')
    } else if (this.props.searchParams.lqt) {
      this.props.saveSearchLocation(this.props.searchParams.lqt, 'api')
    } else {
      this.props.changeSearchLocation(null)
      this.props.changeSearchBoundingBox(null)
    }
  }

  getPolygonFilters () {
    const { searchLocation, searchParams } = this.props
    const insidePolygon = []

    // Disable geolocation search for supplier type with area of operation
    if (
      searchParams &&
      searchParams.location &&
      searchParams.type &&
      hasAreaOfOperation(searchParams.type)
    ) {
      return {}
    }

    if (searchLocation && searchLocation.region) {
      const coordinates = searchLocation.region.coordinates
        .split(';')
        .filter((v) => v !== '')

      for (let i = 0; i < coordinates.length; i++) {
        const coords = coordinates[i].split(':')

        insidePolygon.push(parseFloat(coords[0]))
        insidePolygon.push(parseFloat(coords[1]))
      }
    }

    if (insidePolygon.length > 0) {
      return { insidePolygon: insidePolygon.toString() }
    }

    return {}
  }

  newIndexParams () {
    if (this.props.filterMode === 'moments') {
      return new MomentsIndexParams(this.props.searchParams)
    } else {
      return new SuppliersIndexParams(this.props.searchParams)
    }
  }

  handleToggleStagingHeader () {
    this.setState((prevState) => ({
      showStagingHeader: !prevState.showStagingHeader
    }))
  }

  render () {
    const {
      searchParams,
      i18n,
      hideSearchBar,
      hideSupplierSearchBar,
      hideBreadcrumb,
      hideHeaderButtons,
      filterMode,
      t
    } = this.props
    const queryPresent = !!this.props.searchParams.query
    const query = queryPresent ? { query: this.props.searchParams.query } : {}

    const indexParams = this.newIndexParams()
    indexParams.buildAreaOfOperationFilter(this.props.searchLocation)
    const allFilters = indexParams.getAllFilters()
    const filters = allFilters ? { filters: allFilters } : {}

    return (
      <div tabIndex='-1' ref={this.rootNodeRef}>
        <SearchContextProvider>
          <Helmet>
            <title>{i18n.t('page_head.titles.default')}</title>
            <meta
              name='description'
              content={i18n.t('page_head.meta_desc.default')}
            />
          </Helmet>
          <InstantSearch
            indexName={indexParams.index}
            searchClient={searchClient}
          >
            <Configure
              hitsPerPage={24}
              {...filters}
              {...query}
              clickAnalytics
              ruleContexts={[window.CURRENT_SUBDOMAIN]}
              queryLanguages={languageSettings.languageList}
              {...this.getPolygonFilters()}
              insideBoundingBox={getBoundingBox(
                this.props.searchBoundingBox,
                this.props.searchLocation,
                searchParams
              )}
              facets={['*']}
              maxValuesPerFacet={10}
            />
            <MainVisualLayout
              hideSearchBar={hideSearchBar}
              hideBreadcrumb={hideBreadcrumb}
              filterMode={filterMode}
              hideSupplierSearchBar={hideSupplierSearchBar}
              hideHeaderButtons={hideHeaderButtons}
            >
              {this.props.children}
            </MainVisualLayout>
          </InstantSearch>
          {window && window.STAGING ? (
            <div className='staging-button'>
              <ButtonWithIcon
                labelColor='#FFFFFF'
                iconColor='#FFFFFF'
                padding='6px 8px'
                label={
                  this.state.showStagingHeader
                    ? t('hide_staging_header')
                    : t('show_staging_header')
                }
                className='client-theme--bg'
                onClick={this.handleToggleStagingHeader}
              />
            </div>
          ) : null}
          <Footer />
          <ToastContainer
            position='bottom-left'
            progressClassName='defaultToastProgressBar'
            toastClassName='defaultToast'
          />
        </SearchContextProvider>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    searchBoundingBox: state.app.searchBoundingBox,
    searchParams: extractQueryParams(ownProps),
    searchLocation: state.app.searchLocation
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeSearchBoundingBox: (payload) =>
      dispatch(changeSearchBoundingBox(payload)),
    changeSearchLocation: (payload) => dispatch(changeSearchLocation(payload)),
    saveSearchLocation: (payload, type) =>
      dispatch(saveSearchLocation(payload, type))
  }
}

MainLayout.propTypes = {
  children: PropTypes.any,
  saveSearchLocation: PropTypes.func,
  changeSearchLocation: PropTypes.func
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(withLanguageSwitcher(MainLayout)))
)
