import React, { Component } from 'react'
import PropTypes from 'prop-types'

class SuccessMessage extends Component {
  render () {
    return (
      <>
        {(!this.props.hidden) ? (
          <div className='alert alert-success'>
            {this.props.message}
          </div>
        ) : null}
      </>
    )
  }
}

SuccessMessage.propTypes = {
  message: PropTypes.string.isRequired,
  hidden: PropTypes.bool
}

export default SuccessMessage
