import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import './SimpleMap.scss'
import { mapStyles } from '../../MapStyles.js'

const MapMarker = ({ title, imageUrl }) => {
  return (
    <div className='mapMarker'>
      <div className='title'>{title}</div>
      <div className='markerWrapper'>
        <div className='tag'>
          <i className='fa fa-check' />
        </div>
        <div className='pin'>
          <div
            className='image'
            style={{ backgroundImage: 'url(' + imageUrl + ')' }}
          />
        </div>
      </div>
    </div>
  )
}

class SimpleMap extends Component {
  render () {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: this.props.height, width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDbirx40PgELJtR4WSu7xyLqh2KZrAbS_0' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={{
            styles: mapStyles
          }}
        >
          {this.props.markers && this.props.markers.length > 0
            ? this.props.markers.map((marker, idx) => (
              <MapMarker
                key={idx}
                lat={marker.lat}
                lng={marker.lng}
                title={marker.title}
                imageUrl={marker.imageUrl}
              />
            ))
            : null}
        </GoogleMapReact>
      </div>
    )
  }
}

export default SimpleMap
