import React from 'react'
import './SearchInput.scss'
import { useTranslation } from 'react-i18next'

const SearchInput = ({ onChange }) => {
  const { t } = useTranslation()
  return (
    <div className='input-group search-input'>
      <input
        type='text'
        className='form-control border-0'
        placeholder={t('search_here')}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
      <div className='input-group-append'>
        <span className='input-group-text'>
          <i className='fa fa-search' />
        </span>
      </div>
    </div>
  )
}

export default SearchInput
