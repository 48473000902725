import React, { Component } from 'react'
import axios from 'axios'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import { Col, Card, CardBody, Row } from 'reactstrap'
import ButtonWithIcon from '../../../ButtonWithIcon/ButtonWithIcon'
import GeneralRoomsInformationForm from './GeneralRoomsInformationForm'
import RoomGroups from './RoomGroups'
import ValidationErrors from '../../../ValidationErrors/ValidationErrors'
import Loading from '../Loading'
import { withTranslation } from 'react-i18next'
import { scrollToTop, filterObjectKeys, getButtonLabel, getButtonIcon, isButtonDisabled } from '../../../../shared/utils'
import FormStatus from '../../../FormStatus/FormStatus'

class Rooms extends Component {
  constructor (props) {
    super(props)

    this.emptySupplier = { general_rooms_information: {}, room_groups: [], room_facilities: [] }
    this.state = {
      attributes: {},
      pristine: true,
      errors: {},
      supplier: this.emptySupplier,
      resetState: false,
      hasUserMadeChanges: false,
      submitStatus: 'nothing-to-save'
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  componentDidMount () {
    this.initializeStateWithExistingFormData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.supplier !== this.props.supplier) {
      this.initializeStateWithExistingFormData()
    }
    // we only need to take action for the first change made by the user
    if (!prevState.hasUserMadeChanges && this.state.hasUserMadeChanges) {
      this.props.updateHandler(null, 'Rooms')
      this.setState({ submitStatus: 'ready-to-save' })
    }
  }

  initializeStateWithExistingFormData () {
    if (this.props.supplier) {
      this.setState({ supplier: { ...this.emptySupplier, ...this.props.supplier }, hasUserMadeChanges: false, submitStatus: 'nothing-to-save' })
    }
  }

  handleChange ({ target }) {
    const value = target.value
    const name = target.name

    this.setState({
      pristine: false,
      supplier: {
        ...this.state.supplier,
        [name]: value
      },
      hasUserMadeChanges: true
    })
  }

  handleReset () {
    this.setState({
      errors: {},
      pristine: true,
      resetState: true,
      hasUserMadeChanges: false,
      submitStatus: 'nothing-to-save'
    })
    this.props.resetHandler()
  }

  handleSubmit () {
    this.setState({
      errors: {},
      isSubmiting: true,
      submitStatus: 'in-progress'
    }, function () {
      const formKeys = ['general_rooms_information_attributes', 'room_groups_attributes']
      const supplierFormData = filterObjectKeys(this.state.supplier, formKeys)

      axios
        .put(`/suppliers/${this.props.supplier.id}.json`, {
          facet: 'rooms',
          supplier: supplierFormData,
          locale: this.props.i18n.language
        })
        .then(response => {
          this.setState({
            isSubmiting: false,
            hasUserMadeChanges: false,
            submitStatus: 'save-successful'
          })
          this.props.updateHandler(response.data, null)
        })
        .catch(error => {
          switch (error.response.status) {
            case 403:
              window.location.href = '/pages/access_denied'
              break
            case 422:
              scrollToTop()
              this.setState({
                errors: error.response.data.errors,
                isSubmiting: false,
                submitStatus: 'submit-error',
                hasUserMadeChanges: true
              })
              break
          }
        })
    })
  }

  renderComponent () {
    const { t } = this.props

    return (
      <div className='containerFluid'>
        {this.state.hasUserMadeChanges ? (
          <Col className='p-0 d-flex justify-content-center align-items-center'>
            <ButtonWithIcon
              labelColor='#FFFFFF'
              iconColor='#FFFFFF'
              padding='12px'
              margin='10px'
              icon={getButtonIcon('reset', this.state.submitStatus, this.state.hasUserMadeChanges)}
              label={getButtonLabel('reset', this.state.submitStatus, this.state.hasUserMadeChanges)}
              className='btn-danger c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold rounded-pill'
              onClick={this.handleReset}
              disabled={isButtonDisabled(this.state.submitStatus)}
            />
          </Col>
        ) : null}
        <ValidationErrors validationErrors={this.state.errors} />

        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
        >
          <AccordionItem style={{ marginBottom: '15px' }}>
            <Card>
              <CardBody>
                <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                  <AccordionItemButton>
                    <h5 className='u-position-relative'>
                      {t('general_information')}
                      <div className='accordion__arrow' role='presentation' />
                    </h5>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <GeneralRoomsInformationForm
                    name='general_rooms_information_attributes'
                    roomFacilities={this.props.supplier.room_facilities}
                    generalRoomsInformation={this.state.supplier.general_rooms_information}
                    onChange={this.handleChange}
                    resetState={this.state.resetState}
                  />
                </AccordionItemPanel>
              </CardBody>
            </Card>
          </AccordionItem>

          <RoomGroups
            name='room_groups_attributes'
            roomGroups={this.state.supplier.room_groups}
            roomFacilities={this.props.supplier.room_facilities}
            roomTypes={this.props.supplier.room_types}
            errors={this.state.errors.room_groups_attributes}
            onChange={this.handleChange}
          />
        </Accordion>

        <Row className='m-0 mt-4 mb-4'>
          <Col className='pl-0' xl={4} md={4} sm={6} xs={12}>
            <ButtonWithIcon
              labelColor='#FFFFFF'
              iconColor='#FFFFFF'
              padding='12px'
              icon={getButtonIcon('submit', this.state.submitStatus, this.state.hasUserMadeChanges)}
              label={getButtonLabel('submit', this.state.submitStatus, this.state.hasUserMadeChanges)}
              className='btn c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold rounded-pill'
              onClick={this.handleSubmit}
              disabled={isButtonDisabled(this.state.submitStatus)}
            />
          </Col>
          <Col className='pl-0' xl={4} md={4} sm={6} xs={12}>
            <FormStatus
              id='supplierEditRooms'
              submitStatus={this.state.submitStatus}
              errors={this.state.errors}
            />
          </Col>
        </Row>
      </div>
    )
  }

  render () {
    if (this.props.supplier && this.props.supplier.id) {
      return this.renderComponent()
    } else {
      return <Loading />
    }
  }
}

export default withTranslation()(Rooms)
