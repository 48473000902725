import React from 'react'
import SupplierCardLite from '../SupplierCardLite/SupplierCardLite'
import SupplierCard from '../SupplierCard/SupplierCard'
import { connectHits } from 'react-instantsearch-dom'
import 'react-multi-carousel/lib/styles.css'
import './ConnectedCarouselOfSuppliers.scss'
import Slider from 'react-slick'
import { settings, settingsLite } from './CarouselSettings'

const carouselOfSuppliers = ({ hits }) => (
  <div className='d-block'>
    <Slider
      itemClass='supplierPreviewCarouselCardItem'
      containerClass='ssCarouselContainer'
      lazyLoad
      {...settings}
    >
      {hits.map((hit, idx) => (
        <div key={idx} index={idx + 1} className='supplierPreviewCardContainer'>
          <SupplierCard hit={hit} />
        </div>
      ))}
    </Slider>
  </div>
)

const carouselOfSuppliersLite = ({ hits }) => (
  <div className='d-block'>
    {hits && hits.length < 7 ? (
      <Slider
        itemClass='supplierPreviewCarouselCardItem'
        containerClass='ssCarouselContainer'
        lazyLoad
        {...settings}
        accessibility={false}
      >
        {hits.map((hit, idx) => (
          <div key={hit.objectID} index={idx + 1} className='supplierPreviewCardContainer'>
            <SupplierCardLite hit={hit} />
          </div>
        ))}
      </Slider>
    ) : null}
    {hits && hits.length >= 7 ? (
      <Slider
        itemClass='supplierPreviewCarouselCardItem'
        containerClass='ssCarouselContainer'
        lazyLoad
        {...settingsLite}
        accessibility={false}
      >
        {hits.map((hit, idx) => (
          <div key={hit.objectID} index={idx + 1} className='supplierPreviewCardContainer'>
            <SupplierCardLite hit={hit} />
          </div>
        ))}
      </Slider>
    ) : null}
  </div>
)

export const ConnectedCarouselOfSuppliers = connectHits(carouselOfSuppliers)

export const ConnectedCarouselOfSuppliersLite = connectHits(carouselOfSuppliersLite)

export default ConnectedCarouselOfSuppliers
