import React from 'react'
import './StaticRefinementList.scss'
import Checkbox from '../Input/Checkbox'
import { useTranslation } from 'react-i18next'

export const StaticRefinementList = ({
  values,
  appliedFilters = [],
  attribute,
  onChange
}) => {
  const { t } = useTranslation()
  const checkIfRefined = React.useCallback((value) => {
    if (Array.isArray(appliedFilters)) {
      return appliedFilters.findIndex(
        (item) => item === value
      ) !== -1
    } else {
      return appliedFilters === value
    }
  }, [appliedFilters])
  return (
    <>
      {values.map((staticItem) => {
        const isRefined = checkIfRefined(staticItem)
        return (
          <li key={staticItem}>
            <Checkbox
              value={staticItem}
              label={t(staticItem)}
              isChecked={isRefined}
              onChange={(event) => {
                const value = event.currentTarget.value
                let next = []
                if (Array.isArray(appliedFilters)) {
                  next = appliedFilters.includes(value)
                    ? appliedFilters.filter((current) => current !== value)
                    : [...appliedFilters, value]
                } else if (appliedFilters === value) {
                  next = []
                } else {
                  next = [appliedFilters, value]
                }
                onChange(attribute, next)
              }}
            />
          </li>
        )
      })}
    </>
  )
}
