import React from 'react'
import './SupplierCardFeedback.scss'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Tooltip from 'rc-tooltip'
import { UUID } from '../../shared/utils'

class SupplierCardFeedback extends React.Component {
  constructor (props) {
    super(props)
    this.isVisible = this.isVisible.bind(this)
    this.getFeedbackVariables = this.getFeedbackVariables.bind(this)
  }

  isVisible (feedbackValue) {
    return feedbackValue >= 3
  }

  getFeedbackVariables (feedbackValue) {
    if (feedbackValue >= 4.5) {
      return {
        class: 'excellent',
        textKey: 'excellent'
      }
    } else if (feedbackValue >= 4) {
      return {
        class: 'very-good',
        textKey: 'very_good'
      }
    } else if (feedbackValue >= 3.5) {
      return {
        class: 'good',
        textKey: 'good'
      }
    } else if (feedbackValue >= 3) {
      return {
        class: 'average',
        textKey: 'average'
      }
    } else {
      return {}
    }
  }

  render () {
    const { t } = this.props
    const feedbackValue = this.props.feedback ? parseFloat(this.props.feedback).toFixed(2) : 0
    const feedback = this.getFeedbackVariables(feedbackValue)
    return (
      <Tooltip
        placement='topLeft'
        trigger={['hover', 'focus']}
        overlay={feedbackValue ? <span>{feedbackValue} {feedback && feedback.textKey ? t(feedback.textKey) : null}</span> : null} arrowContent={<div className='rc-tooltip-arrow-inner' />}
        id={UUID()}
      >
        {this.isVisible(feedbackValue) ? (
          <div
            style={this.props.containerStyle ? this.props.containerStyle : null}
            className={`supplier-card__feeback supplier-card__feeback--${
              feedback && feedback.class ? feedback.class : null
            }`}
            tabIndex='0'
            aria-label={t('rating') + ' ' + feedbackValue}
          >
            <span aria-hidden='true'>
              <b>{feedbackValue}</b>
            </span>
          </div>
        ) : (
          <div
            style={{ paddingLeft: '10px', display: 'inline-block' }}
          >{'\u00A0'}
          </div>)}
      </Tooltip>
    )
  }
}

SupplierCardFeedback.propTypes = {
  feedback: PropTypes.number,
  t: PropTypes.func
}

export default withTranslation()(SupplierCardFeedback)
