import React from 'react'
import './SustainabilityIcon.scss'
import Tooltip from 'rc-tooltip'
import { useTranslation } from 'react-i18next'

export default function ({ containerClassName = '', hasTooltip = true }) {
  const { t } = useTranslation()
  return (
    hasTooltip ? (
      <Tooltip placement='top' trigger={['hover', 'focus']} overlay={<span>{t('sustainable_button')}</span>} arrowContent={<div className='rc-tooltip-arrow-inner' />}>
        <div className={`sustainable-container ${containerClassName}`} aria-label={t('sustainable_button')} tabIndex='0' role='img'>
          <i className='fas fa-leaf' />
        </div>
      </Tooltip>
    ) : (
      <div className={`sustainable-container ${containerClassName}`} aria-label={t('sustainable_button')}>
        <i className='fas fa-leaf' />
      </div>
    )
  )
}
