import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { scrollToTop, getAdminPath } from '../../../shared/utils'
import '../SupplierAdmin.scss'
import Can from '../../Layout/Can'
import HeaderTopBar from '../../HeaderTopBar/HeaderTopBar'

class SupplierAdminNavbar extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      activePath: props.location.pathname
    }

    this.shouldShowSettings = this.shouldShowSettings.bind(this)
  }

  componentDidMount () {
    this.removeHistoryListener = this.props.history.listen((location) => {
      scrollToTop()

      this.setState({
        activePath: location.pathname
      })
    })
  }

  componentWillUnmount () {
    this.removeHistoryListener()
  }

  isActive (menuItem) {
    return this.state.activePath.includes(menuItem)
  }

  menu (path) {
    const { user, match } = this.props
    let adminPath = getAdminPath(user)

    if (match) {
      const { userType, objectID } = match.params

      adminPath = `/${userType}/${objectID}/admin/`
    }

    return `${adminPath}${path}`
  }

  shouldShowSettings () {
    return this.props.match && this.props.match.params.userType !== 'sales_managers'
  }

  render () {
    const { t } = this.props

    return (
      <>
        {/* Dropdown menu toggle on mobile: c-toggler class can be applied to the link arrow or link itself depending on toggle mode */}
        <nav className='c-mega-menu c-mega-menu-dark c-mega-menu-dark-mobile c-fonts-uppercase c-fonts-bold'>
          <ul className='nav navbar-nav c-theme-nav'>
            <li className={this.isActive('marketing') ? 'c-active' : ''}>
              <Link to={this.menu('marketing')} href='#c-tab2-opt1-3' className='c-link' aria-expanded='true'>
                {t('supplier.admin.navbar.marketing')} <span className='c-arrow c-toggler' />
              </Link>
            </li>

            <li className={this.isActive('analytics') ? 'c-active' : ''}>
              <Link to={this.menu('analytics')} href='#c-tab2-opt1-3' className='c-link' aria-expanded='true'>
                {t('supplier.admin.navbar.analytics')} <span className='c-arrow c-toggler' />
              </Link>
            </li>

            <li className={this.isActive('records') ? 'c-active' : ''}>
              <Link to={this.menu('records')} href='#c-tab2-opt1-3' className='c-link' aria-expanded='true'>
                {t('supplier.admin.navbar.records')} <span className='c-arrow c-toggler' />
              </Link>
            </li>

            <Can perform='read' on='TableauMessage'>
              <li className={this.isActive('messages') ? 'c-active' : ''}>
                <Link to={this.menu('messages')} href='#c-tab2-opt1-3' className='c-link' aria-expanded='true'>
                  {t('supplier.admin.navbar.messages')} <span className='c-arrow c-toggler' />
                </Link>
              </li>
            </Can>

            {this.shouldShowSettings()
              ? (
                <li className={this.isActive('settings') ? 'c-active' : ''}>
                  <Link to={this.menu('settings')} href='#c-tab2-opt1-3' className='c-link' aria-expanded='true'>
                    {t('supplier.admin.navbar.settings')} <span className='c-arrow c-toggler' />
                  </Link>
                </li>
              )
              : null}
          </ul>
        </nav>

        <div className='col-md-3 pl-0 d-flex pull-right align-items-center justify-content-end'>
          <HeaderTopBar />
        </div>
      </>
    )
  }
}

SupplierAdminNavbar.propTypes = {
  supplier: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    user: state.app.user
  }
}

export default connect(mapStateToProps)(withRouter(withTranslation()(SupplierAdminNavbar)))
