import React from 'react'
import { useTranslation } from 'react-i18next'
import { connectRefinementList, connectMenu, Highlight } from 'react-instantsearch-dom'
import moment from 'moment'
import {
  FilterDropMenu,
  FilterElementRange,
  FilterElementDeliverableType,
  FilterBarContainer,
  DatePickerElement
} from './FilterInputs'
import { useQueryParams } from '../../services/urlStateParser'
import { scrollToTop } from '../../shared/utils'

import 'react-dates/lib/css/_datepicker.css'
import './FilterBar.scss'

const Hashtags = ({ queryParams, items, refine, searchForItems }) => {
  return (
    <div className='hashtagsDropMenu'>
      <input
        type='search'
        onChange={event => searchForItems(event.currentTarget.value)}
      />
      <ul className='hashtagsChoices'>
        {items.map(item => (
          <li
            key={item.label}
            onClick={event => {
              event.preventDefault()
              refine(item.value)
              const values = queryParams.asArray('hashtag')
              if (values.includes(item.label)) {
                values.splice(values.indexOf(item.value), 1)
              } else {
                values.push(item.label)
              }
              queryParams.update({ hashtag: values.join(','), facet_only: 1 })
              scrollToTop()
            }}
          >
            <a href='#' style={{ fontWeight: queryParams.asArray('hashtag').includes(item.label) ? 'bold' : '' }}>
              <span>{item.label}</span>
              <span className='count'>({item.count})</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
const ConnectedHashtags = connectRefinementList(Hashtags)

const Category = ({ queryParams, items, isFromSearch, refine }) => {
  const { t } = useTranslation()
  return (
    <ul style={{ listStyleType: 'none' }}>
      {items.map(item => (
        <li
          className='list-item'
          style={{
            color: '#626262',
            borderBottom: '1px solid #ccc',
            padding: '5px 5px 5px 5px',
            fontWeight: item.isRefined ? '600' : '400',
            fontSize: '0.9rem'
          }}
          onClick={event => {
            event.preventDefault()
            refine(item.value)
            queryParams.update({ category: item.value, facet_only: 1 })
            scrollToTop()
          }}
          key={item.value}
        >
          {isFromSearch ? <Highlight attribute='label' hit={item} /> : t(`mice_moment_categories.${item.label}`)} (
          {item.count})
        </li>
      ))}
    </ul>
  )
}
const Sustainable = ({ queryParams, items, isFromSearch, refine }) => {
  const { t } = useTranslation()
  return (
    <ul style={{ listStyleType: 'none' }}>
      {items.map(item => (
        <li
          className='list-item'
          style={{
            color: '#626262',
            borderBottom: '1px solid #ccc',
            padding: '5px 5px 5px 5px',
            fontWeight: item.isRefined ? '600' : '400',
            fontSize: '0.9rem'
          }}
          onClick={event => {
            event.preventDefault()
            refine(item.value)
            queryParams.update({ sustainable: item.value, facet_only: 1 })
            scrollToTop()
          }}
          key={item.value}
        >
          {isFromSearch ? <Highlight attribute='label' hit={item} /> : t(`sustainable.${item.label}`)} (
          {item.count})
        </li>
      ))}
    </ul>
  )
}

export const ConnectedSustainable = connectMenu(Sustainable)
export const ConnectedCategory = connectMenu(Category)

const MomentsFilterBar = () => {
  const queryParams = useQueryParams()

  // HMM, why does this have to happen here?
  React.useEffect(() => { moment.locale(queryParams.params.locale) }, [queryParams.params.locale])

  return (
    <FilterBarContainer isSupplierFilterBar={false}>
      <DatePickerElement queryParams={queryParams} />
      <FilterDropMenu queryParams={queryParams} name='filter_category' fields={['category']}>
        <ConnectedCategory
          queryParams={queryParams}
          attribute='category'
          defaultRefinement={queryParams.params.category}
        />
      </FilterDropMenu>
      <FilterDropMenu queryParams={queryParams} name='filter_hashtag' fields={['hashtag']} menuContainerWidth='160%'>
        <ConnectedHashtags
          queryParams={queryParams}
          attribute='hashtags.de'
          defaultRefinement={queryParams.asArray('hashtag')}
          searchable
        />
      </FilterDropMenu>
      <FilterDropMenu queryParams={queryParams} name='filter_sustainable' fields={['sustainable']}>
        <ConnectedSustainable
          queryParams={queryParams}
          attribute='sustainable'
          defaultRefinement={queryParams.params.sustainable}
        />
      </FilterDropMenu>
      <FilterElementDeliverableType size={2} queryParams={queryParams} queryPath='/moments' />
      <FilterElementRange queryParams={queryParams} field='conference_rooms' queryPath='/moments' additionalOpenClassName='active-filter-radius' />
    </FilterBarContainer>
  )
}

export default MomentsFilterBar
