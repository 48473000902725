import React from 'react'
import Rating from 'react-rating'

export const RatingSelect = ({ id, value, emptySymbol, fullSymbol, onChange, ariaLabel }) => {
  const [rating, setRating] = React.useState(Number(value) || 0)
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onChange(rating)
    } else if (e.key === 'ArrowRight') {
      if (rating < 5) {
        setRating(rating + 1)
      }
    } else if (e.key === 'ArrowLeft') {
      if (rating > 0) {
        setRating(rating - 1)
      }
    }
  }
  React.useEffect(() => {
    setRating(Number(value))
  }, [value])
  return (
    <div
      id={id}
      className='accessible'
      tabIndex={0}
      onKeyUp={handleKeyPress}
      aria-label={ariaLabel}
    >
      <Rating
        className='h3'
        initialRating={rating}
        step={1}
        emptySymbol={emptySymbol}
        fullSymbol={fullSymbol}
        onClick={onChange}
      />
    </div>
  )
}
