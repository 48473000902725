import React from 'react'
import './SquareRadioButton.scss'

class SquareRadioButton extends React.Component {
  render () {
    return (
      <div className='squareRadioInputWrapper'>
        <input
          type='radio'
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          defaultChecked={this.props.defaultChecked}
        />
        <label htmlFor={this.props.id}>
          <h2>{this.props.title}</h2>
        </label>
      </div>
    )
  }
}
export default SquareRadioButton
