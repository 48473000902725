import React from 'react'
import PropTypes from 'prop-types'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import PostCodeInputLine from './PostCodeInputLine'
import { Col, Row } from 'reactstrap'

class RegionalPostCodesInput extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      postCodes: []
    }

    this.initializeStateWithExistingAreaCodes = this.initializeStateWithExistingAreaCodes.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleRemoveClick = this.handleRemoveClick.bind(this)
  }

  componentDidMount () {
    this.initializeStateWithExistingAreaCodes()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.areaCodes !== this.props.areaCodes) {
      this.initializeStateWithExistingAreaCodes()
    }
  }

  initializeStateWithExistingAreaCodes () {
    if (this.props.areaCodes) {
      const postCodes = (this.props.areaCodes || [])
      this.setState({ postCodes: postCodes })
    }
  }

  handleChange (idx, value) {
    const postCodes = this.state.postCodes
    postCodes[idx] = value
    this.setState({ postCodes: postCodes })

    if (this.props.onChange) {
      this.props.onChange({ [this.props.name]: postCodes })
    }
  }

  handleAddClick () {
    const postCodes = this.state.postCodes
    postCodes.push('')
    this.setState({ postCodes: postCodes })
  }

  handleRemoveClick (idx) {
    const postCodes = this.state.postCodes
    postCodes.splice(idx, 1)
    this.setState({ postCodes: postCodes })

    if (this.props.onChange) {
      this.props.onChange({ [this.props.name]: postCodes })
    }
  }

  render () {
    return (
      <div>
        {this.state.postCodes && this.state.postCodes.length > 0 ? (
          this.state.postCodes.map((postCode, idx) => (
            <PostCodeInputLine
              idx={idx}
              key={`post-code-${idx}`}
              postCode={postCode}
              placeholder={this.props.placeholder}
              onChange={this.handleChange}
              onRemove={this.handleRemoveClick}
            />
          ))

        ) : null}
        <Row>
          <Col md={4} sm={6} xs={12}>
            <ButtonWithIcon
              labelColor='#FFFFFF'
              iconColor='#FFFFFF'
              padding='12px'
              icon='fa fa-plus'
              label='Bitte PLZ-Gebiet wählen'
              className='client-theme--bg'
              onClick={this.handleAddClick}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

RegionalPostCodesInput.propTypes = {
  name: PropTypes.string,
  object: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
}

export default RegionalPostCodesInput
