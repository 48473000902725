import React from 'react'
import { Col, Row } from 'reactstrap'
import './pages.scss'
import { withTranslation } from 'react-i18next'
import { scrollToTop } from '../../shared/utils'

const DataProtectionOfficerInfo = {
  company: 'secadair GmbH',
  contact_person: 'Polymnia Georgiadou',
  street: 'Wolfsstraße',
  house_nr: '16',
  post_code: '50667',
  city: 'Köln'
}
class DataPrivacy extends React.Component {
  constructor (props) {
    super(props)
    this.scrollToAnchor = this.scrollToAnchor.bind(this)
  }

  componentDidMount () {
    scrollToTop()

    this.scrollToAnchor()
    window.addEventListener('hashchange', this.scrollToAnchor)
  }

  componentDidUpdate () {
    this.scrollToAnchor()
  }

  componentWillUnmount () {
    window.removeEventListener('hashchange', this.scrollToAnchor)
  }

  scrollToAnchor () {
    const hash = window.location.hash
    if (hash) {
      const anchorId = hash.substring(1)
      const element = document.getElementById(anchorId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  renderDe () {
    return (
      <div className='containerFluid' style={{ paddingTop: '40px', paddingBottom: '40px' }}>
        <div className='c-content-title-1'>
          <h1 className='c-font-uppercase'>Datenschutzerklärung</h1>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Informationen über die Verarbeitung Ihrer Daten</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Wir sind gemäß Art. 12 Datenschutz-Grundverordnung (im Weiteren: DSGVO) verpflichtet, Sie über die Verarbeitung Ihrer Daten bei Nutzung unserer Website zu informieren. Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst und die vorliegende Datenschutzerklärung informiert Sie über die Einzelheiten der Verarbeitung Ihrer Daten sowie über Ihre diesbezüglichen gesetzlichen Rechte.</p>
        <p>Wir behalten uns vor, die Datenschutzerklärung mit Wirkung für die Zukunft anzupassen, insbesondere im Fall der Weiterentwicklung der Website, bei der Nutzung neuer Technologien oder der Änderung der gesetzlichen Grundlagen bzw. der entsprechenden Rechtsprechung.</p>
        <p>Wir empfehlen Ihnen, die Datenschutzerklärung von Zeit zu Zeit zu lesen und einen Ausdruck bzw. eine Kopie zu Ihren Unterlagen zu nehmen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>BEGRIFFSBESTIMMUNGEN</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <ul style={{ paddingLeft: '20px' }}>
          <li>„<strong>Website</strong>“ oder „<strong>Internetpräsenz</strong>“ meint nachfolgend alle Seiten des Verantwortlichen auf <a className='client-theme--color' href='/'>https://www.miceportal.com</a>.
          </li>
          <li>„<strong>Personenbezogene Daten</strong>“ meint alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Identifizierbar ist eine Person, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder Sozialen Identität dieser natürlichen Person sind. Personenbezogene Daten sind daher z.B. der Name, die E-Mail-Adresse und die Telefonnummer einer Person, aber ggf. auch Daten über Vorlieben, Hobbies und Mitgliedschaften.
          </li>
          <li>„<strong>Verarbeitung</strong>“ meint mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgänge oder Vorgangsreihen im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung, die Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, die Verbreitung oder eine andere Form der Bereitstellung, den Abgleich, die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
          </li>
          <li>„<strong>Pseudonymisierung</strong>“ meint die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.
          </li>
          <li>„<strong>Einwilligung</strong>“ meint nachfolgend jede freiwillig für den bestimmten Fall, in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.
          </li>
          <li>„<strong>Google</strong>“ meint im Weiteren die Google, LLC 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; in der Europäischen Union erreichbar unter: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001.
          </li>
        </ul>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Geltungsbereich</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Die Datenschutzerklärung gilt für alle Seiten von https://www.miceportal.com. Sie erstreckt sich nicht auf etwaig verlinkte Websites bzw. Internetpräsenzen von anderen Anbietern.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Verantwortlicher Anbieter</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Für die Verarbeitung von personenbezogenen Daten im Geltungsbereich dieser Datenschutzerklärung ist verantwortlich:
          <br />
          <br />
        </p>
        <p><strong>MICE Portal GmbH</strong>
          <br />Am Bachfeld 1
          <br />85395 Attenkirchen
          <br />Deutschland
          <br />
          <br />Tel. +49 (0)8168 9974-1000
          <br />Fax. +49 (0)8168 9974-1199
          <br />info(at)miceportal.com
        </p>
        <br /> vertreten durch:
        <p>Josephine Gräfin von Brühl
          <br />MICE Portal GmbH
          <br />Am Bachfeld 1, 85395 Attenkirchen
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Fragen zum Datenschutz</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Sollten Sie Fragen zum Thema Datenschutz im Hinblick auf unser Unternehmen bzw. unsere Website haben, können Sie sich an unseren Datenschutzbeauftragten wenden:
          <br />
          <br />
        </p>
        <p><strong>{DataProtectionOfficerInfo.company}</strong>
          <br />{DataProtectionOfficerInfo.contact_person}
          <br />E-Mail: privacy(at)miceportal.com
          <br />Anschrift: {DataProtectionOfficerInfo.street} {DataProtectionOfficerInfo.house_nr}, {DataProtectionOfficerInfo.post_code} {DataProtectionOfficerInfo.city}
          <br />HRB-Nr.: 91147 | Amtsgericht Köln | Jürgen Funke, Daniel Wasser
          <br />{DataProtectionOfficerInfo.street} {DataProtectionOfficerInfo.house_nr} | {DataProtectionOfficerInfo.post_code} {DataProtectionOfficerInfo.city}| +49 221 56947927 | {DataProtectionOfficerInfo.company}
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Sicherheit</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Wir haben umfassende technische und organisatorische Vorkehrungen getroffen, um Ihre personenbezogenen Daten vor unberechtigtem Zugriff, Missbrauch, Verlust und anderen äußeren Störungen zu schützen. Hierzu überprüfen wir unsere Sicherheitsmaßnahmen regelmäßig und passen sie dem Stand der Technik an.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Ihre Rechte</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Sie haben die folgenden Rechte im Hinblick auf die Sie betreffenden personenbezogenen Daten, die Sie uns gegenüber geltend machen können:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Recht auf Auskunft (Art. 15 DSGVO),</li>
          <li>Recht auf Berichtigung (Art. 16 DSGVO) oder Löschung (Art. 17 DSGVO),</li>
          <li>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO),</li>
          <li>Recht auf Widerspruch gegen die Verarbeitung (Art. 21 DSGVO),</li>
          <li>Recht auf Widerruf Ihrer Einwilligung (Art. 7 Abs. 3 DSGVO),</li>
          <li>Recht auf Erhalt der Daten in einem strukturierten, gängigen, maschinenlesbaren Format („Datenübertragbarkeit“) sowie Recht auf Weiterübermittlung der Daten an einen anderen Verantwortlichen, wenn die Voraussetzung des Art. 20 Abs. 1 lit. a, b DSGVO vorliegen (Art. 20 DSGVO)
          </li>
        </ul>
        <p>Sie können Ihre Rechte durch Mitteilung an die im Abschnitt „Verantwortlicher Anbieter“ genannten Kontaktdaten bzw. gegenüber den von uns benannten Datenschutzbeauftragten geltend machen.</p>
        <p>Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die von uns vorgenommene Verarbeitung Ihrer personenbezogenen Daten zu beschweren (Art. 77 DSGVO).</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Nutzung der Website, Zugriffstdaten</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Sie können unsere Website grundsätzlich ohne Offenlegung Ihrer Identität zu rein informatorischen Zwecken nutzen. Beim Abruf der einzelnen Seiten der Website in diesem Sinne werden lediglich Zugriffsdaten an unseren Webspace-Provider übermittelt, damit Ihnen die Website angezeigt werden kann. Das sind die folgenden Daten:
          <br />
          <br />
        </p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Browsertyp/ Browserversion</li>
          <li>verwendetes Betriebssystem</li>
          <li>Sprache und Version der Browsersoftware</li>
          <li>Hostname des zugreifenden Endgerätes</li>
          <li>IP Adresse</li>
          <li>Website, von der die Anforderung kommt</li>
          <li>Inhalt der Anforderung (konkrete Seite)</li>
          <li>Datum und Uhrzeit der Serveranfrage</li>
          <li>Zugriffsstatus/HTTP-Statuscode</li>
          <li>Referrer URL (die zuvor besuchte Seite)</li>
          <li>Übertragene Datenmenge</li>
          <li>Zeitzonendifferenz zu Greenwich Mean Time (GMT)</li>
        </ul>
        <p>
          <br />Die vorübergehende Verarbeitung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der Website an Ihren Rechner technisch zu ermöglichen. Eine Verarbeitung Ihrer IP-Adresse für die Dauer der Sitzung ist dafür erforderlich. Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
        </p>
        <p>Die Zugriffsdaten werden nicht zur Identifizierung von einzelnen Nutzern verwendet und nicht mit anderen Datenquellen zusammengeführt. Die Zugriffsdaten werden gelöscht, wenn sie für die Erreichung des Zweckes ihrer Verarbeitung nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn Sie den Besuch der Website beenden.
          <br />
          <br />
        </p>
        <p>Die Speicherung von IP-Adressen erfolgt in Logfiles, um die Funktionsfähigkeit der Website sicherzustellen. Zudem dienen uns die Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung der Daten zu Marketingzwecken findet auch in diesem Zusammenhang nicht statt. Die Daten werden grundsätzlich nach spätestens sieben Tagen gelöscht, eine darüberhinausgehende Verarbeitung ist im Einzelfall möglich. In diesem Fall wird die IP-Adresse gelöscht oder so verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
          <br />
          <br />
        </p>
        <p>Die Erfassung der Daten zur Bereitstellung der Website und die Verarbeitung der Daten in Logfiles ist für den Betrieb der Website zwingend erforderlich. Sie können Widerspruch gegen die Verarbeitung einlegen. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO). Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Cookies</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Über die zuvor genannten Zugriffsdaten hinaus werden bei der Nutzung der Website sog. Cookies im Internet-Browser des von Ihnen verwendeten Endgerätes gespeichert. Dabei handelt es sich um kleine Textdateien mit einer Zahlenfolge, die lokal im Zwischenspeicher des verwendeten Browsers gespeichert werden. Cookies werden nicht Bestandteil des PC-Systems und können keine Programme ausführen. Sie dienen dazu, unsere Website nutzerfreundlich zu gestalten. Der Einsatz von Cookies kann technisch notwendig sein oder zu anderen Zwecken erfolgen (z.B. Analyse/ Auswertung der Website-Nutzung).
          <br />
          <br />
        </p>
        <p><strong>a) Technisch notwendige Cookies</strong></p>
        <p>Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann. In den Cookies werden dabei folgende Daten verarbeitet:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Spracheinstellungen</li>
          <li>Log-In-Informationen</li>
        </ul>
        <p>
          <br />Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verarbeitet. Wir verwenden auch sog. „Session-Cookies“, die eine Session-ID speichern, mit der sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Session-Cookies sind für die Inanspruchnahme der Website notwendig. Insbesondere können wir damit das verwendete Endgerät wiedererkennen, wenn Sie auf die Website zurückkehren. Wir setzen dieses Cookie ein, um Sie bei Folgebesuchen der Website wiederzuerkennen, wenn Sie über einen Account bei uns verfügen; andernfalls müssten Sie sich bei jedem Besuch wieder neu anmelden. Die Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO. Wir setzen Session-Cookies ein, um die Benutzung der Website attraktiver und effektiver zu gestalten. Die Session-Cookies werden gelöscht, sobald Sie sich ausloggen oder den Browser schließen.
          <br />
          <br />
        </p>
        <p>Die meisten Browser sind so voreingestellt, dass sie Cookies automatisch akzeptieren. Sie können der Verarbeitung Ihrer Daten durch Cookies widersprechen. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO). Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.
          <br />
          <br />
        </p>
        <p>Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.
          <br />
          <br />
        </p>
        <p><strong>b) Technisch nicht notwendige Cookies</strong></p>
        <p>Wir verwenden auf der Website darüber hinaus Cookies, die eine Analyse des Surfverhaltens der Nutzer ermöglichen. In den Cookies werden dabei beispielsweise folgende Daten gespeichert und verarbeitet:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Eingegebene Suchbegriffe</li>
          <li>Häufigkeit von Seitenaufrufen</li>
          <li>Inanspruchnahme von Website-Funktionen</li>
        </ul>
        <p>
          <br />Diese Cookies kommen zum Einsatz, um die Nutzung der Website effizienter und attraktiver zu gestalten. Die Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO. Die technisch nicht notwendigen Cookies werden automatisch nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden können.
          <br />
          <br />
        </p>
        <p>Sie können der Verarbeitung Ihrer Daten durch Cookies widersprechen. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO). Wenn Sie die Nutzung von Cookies nicht wünschen, haben Sie durch veränderte Browsereinstellungen die Möglichkeit, das Ablegen von Cookies generell oder selektiv zu blockieren bzw. bereits gespeicherte Cookies zu entfernen. Sie können sich auch entsprechende Hinweise vor dem Setzen eines Cookies anzeigen lassen. Sollten Sie die Browser-Einstellungen zum Einsatz von Cookies abändern bzw. Cookies deaktivieren, ist der Funktionsumfang dieser Website möglicherweise eingeschränkt.
          <br />
          <br />
        </p>
        <p>Soweit wir Drittanbieter-Cookies in unser Webangebot einbinden, werden wir Sie im Folgenden gesondert darauf hinweisen.
          <br />
          <br />
        </p>
        <p><strong>c) Cookie-Banner-Hinweis</strong></p>
        <p>Beim Aufruf der Website werden Sie durch einen Infobanner über die Verwendung von Cookies zu Analysezwecken informiert und auf diese Datenschutzerklärung verwiesen. Es erfolgt in diesem Zusammenhang auch ein Hinweis darauf, wie die Speicherung von Cookies in den Browsereinstellungen unterbunden werden kann.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Kontaktaufnahme mit unserem Unternehmen</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Bei einer Kontaktaufnahme mit unserem Unternehmen, z.B. per E-Mail oder über das Kontaktformular auf der Website, werden die von Ihnen mitgeteilten personenbezogenen Daten von uns verarbeitet, um Ihre Anfrage zu beantworten.</p>
        <p>Zwingend erforderlich sind für die Bearbeitung von Anfragen über das Kontaktformular auf der Website die Angabe eines Namens bzw. eines Pseudonyms, samt Anrede, sowie eine valide E-Mail-Adresse. Fakultativ können Sie z. B. Ihren Vornamen, Ihr Unternehmen und Unternehmensgröße, sowie Anzahl der organisierten Veranstaltungen, eine Telefonnummer und einen weiteren Nachrichtentext angeben.
          <br />
          <br />
        </p>
        <p>Im Zeitpunkt der Absendung der Nachricht an uns werden des Weiteren die folgenden Daten verarbeitet:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>IP-Adresse</li>
          <li>Datum/ Uhrzeit der Registrierung</li>
        </ul>
        <p>
          <br />Rechtsgrundlage für die Verarbeitung ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f) DSGVO bzw. Art. 6 Abs. 1 S. 1 lit. b) DSGVO, wenn die Kontaktaufnahme auf den Abschluss eines Vertrages abzielt.
        </p>
        <p>Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient uns allein zur Bearbeitung der Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte Interesse an der Verarbeitung der Daten. Die sonstigen während des Absendevorgangs verarbeiteten personenbezogenen Daten dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern und die Sicherheit unserer informationstechnischen Systeme sicherzustellen.
        </p>
        <p>Es erfolgt in diesem Zusammenhang jeweils keine Weitergabe der Daten an Dritte. Die Daten werden ausschließlich für die Verarbeitung der Konversation verarbeitet. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Verarbeitung nicht mehr erforderlich ist, oder schränken die Verarbeitung auf die Einhaltung der bestehenden gesetzlich zwingenden Aufbewahrungspflichten ein.
          <br />
          <br />
        </p>
        <p>Sie haben jederzeit die Möglichkeit, der Verarbeitung Ihrer personenbezogenen Daten für Kontaktanfragen zu widersprechen. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder Verteidigung gegen Rechtsansprüchen dient (Art. 21 Abs. 1 DSGVO). Ein Widerspruchsrecht ist insbesondere gegeben, wenn die Verarbeitung nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der vorhergehenden Beschreibung der Funktionen dargestellt wird. In einem solchen Fall kann die Bearbeitung der Anfrage ggf. nicht fortgeführt werden. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Verarbeitung und Weitergabe personenbezogener Daten zu vertraglichen Zwecken</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Wir verarbeiten Ihre personenbezogenen Daten, wenn und soweit dies für die Anbahnung, Begründung, Durchführung und/ oder Beendigung eines Rechtsgeschäfts mit unserem Unternehmen erforderlich ist. Die Rechtsgrundlage hierfür ergibt sich aus Art. 6 Abs. 1 S. 1 lit. b) DSGVO.</p>
        <p>Nach der Zweckerreichung (z.B. Vertragsabwicklung) werden die personenbezogenen Daten für eine weitere Verarbeitung gesperrt bzw. gelöscht, soweit wir nicht aufgrund einer von Ihnen erteilten Einwilligung (z.B. Einwilligung in die Verarbeitung der E-Mail-Adresse für Zusendung von elektronischer Werbepost), einer vertraglichen Vereinbarung, einer gesetzlichen Ermächtigung (z.B. Ermächtigung zur Zusendung von Direktwerbung) oder aufgrund berechtigter Interessen (z.B. Aufbewahrung zur Durchsetzung von Ansprüchen) zu einer weiteren Aufbewahrung und im jeweiligen Zusammenhang erforderlichen Verarbeitung berechtigt sind.</p>
        <p>Die Weitergabe Ihrer personenbezogenen Daten erfolgt insoweit, wenn</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>es für die Begründung, Durchführung oder Beendigung von Rechtsgeschäften mit unserem Unternehmen erforderlich ist (z.B. bei der Weitergabe von Daten an einen Zahlungsdienstleister/ ein Versandunternehmen zur Abwicklung eines Vertrages mit Ihrer Person), (Art. 6 Abs. 1 S. 1 lit. b) DSGVO), oder
          </li>
          <li>ein Unterauftragnehmer oder Erfüllungsgehilfe, den wir ausschließlich im Rahmen der Erbringung der von Ihnen gewünschten Angebote oder Dienste einsetzen, diese Daten benötigt (solche Hilfspersonen sind, soweit Ihnen nicht ausdrücklich etwas anderes mitgeteilt wird, nur insoweit zur Verarbeitung der Daten berechtigt, als dies für die Erbringung des Angebots oder Services notwendig ist) oder
          </li>
          <li>eine vollstreckbare behördliche Anordnung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO) vorliegt oder</li>
          <li>eine vollstreckbare gerichtliche Anordnung vorliegt (Art. 6 Abs. 1 S. 1 lit. c) DSGVO) oder</li>
          <li>wir von Gesetzes wegen (Art. 6 Abs. 1 S. 1 lit. c) DSGVO) hierzu verpflichtet sind oder</li>
          <li>die Verarbeitung erforderlich ist, um lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen (Art. 6 Abs. 1 S. 1 lit. d) DSGVO) oder
          </li>
          <li>wir zur Verfolgung überwiegender berechtigter Interessen zur Weitergabe ermächtigt bzw. sogar verpflichtet (Art. 6 Abs. 1 S. 1 lit. f) DSGVO) sind.
          </li>
        </ul>
        <p>Eine darüberhinausgehende Weitergabe Ihrer personenbezogenen Daten an andere Personen, Unternehmen oder Stellen erfolgt nicht, es sei denn, Sie haben in eine solche Weitergabe wirksam eingewilligt. Rechtsgrundlage der Verarbeitung ist dann Art. 6 Abs. 1 S. 1 lit. a) DSGVO.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Anmeldung für Veranstaltungen</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Sie können sich auf der Website für Veranstaltungen unseres Unternehmens oder Veranstaltungen, auf denen unser Unternehmen vertreten ist, als Gast anmelden. Grundlage für die Verarbeitung Ihrer Daten für die Teilnahme an Veranstaltungen ist Art. 6 Abs. 1 S. 1 lit b) DSGVO. Die Verarbeitung ist für die Begründung, Durchführung oder Beendigung von Rechtsgeschäften mit unserem Unternehmen erforderlich (z.B. auch bei der Weitergabe von Daten an einen Zahlungsdienstleister/ ein Versandunternehmen zur Abwicklung eines Vertrages mit Ihrer Person).</p>
        <p>Im Anmeldeformular müssen Sie u.a. Ihren Namen, Ihr Unternehmen, Ihre E-Mail-Adresse, Ihre Telefonnummer sowie Unternehmensgröße angeben. Der Zweck der Verarbeitung ist die Kontaktpflege sowie die Verbesserung unserer Außendarstellung und unserer Öffentlichkeitsarbeit. Soweit Sie ihr Interesse für einen persönlichen Termin bekundet haben, werden Ihre Daten auch zur Vorbereitung und Durchführung des Termins verarbeitet.</p>
        <p>Ihre Daten werden an Dritte (z.B. den Veranstalter) zum Zweck der Anmeldung der Veranstaltung beim Veranstalter weitergeben. Eine gezielte Übermittlung Ihrer personenbezogenen Daten in ein sog. Drittland ist nicht geplant.</p>
        <p>Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>E-Mail Marketing</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <strong>Bestandskundenwerbung</strong>
        <p>Wir behalten uns vor, die von Ihnen im Rahmen der Anmeldung mitgeteilte E-Mail-Adresse entsprechend den gesetzlichen Vorschriften dazu zu verarbeiten, um Ihnen während bzw. im Anschluss an die Vertragsabwicklung per E-Mail folgende Inhalte zu übersenden, sofern Sie dieser Verarbeitung Ihrer E-Mail-Adresse nicht bereits widersprochen haben:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>weitere interessante Angebote aus unserem Portfolio,</li>
          <li>zu Veranstaltungen unseres Unternehmens,</li>
          <li>technische Informationen,</li>
          <li>Feedbackanfrage im Nachgang zu Veranstaltungen.</li>
        </ul>
        <p>Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 S. 1 lit. f DSGVO. Wir führen die genannte Verarbeitung zur Kundenpflege durch und zur Steigerung unserer Serviceleistungen durch. Wir löschen Ihre Daten, wenn Sie das Newsletter-Abonnement beenden, spätestens jedoch nach zwei Jahren nach Vertragsbeendigung.
          <br />
          <br />
        </p>
        <p>Wir weisen Sie darauf hin, dass Sie dem Erhalt von Direktwerbung jederzeit widersprechen können, ohne dass Ihnen hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen. Dabei steht Ihnen ein generelles Widerspruchsrecht ohne Angaben von Gründen zu (Art. 21 Abs. 2 DSGVO). Klicken Sie hierzu auf den Abmeldelink im Newsletter oder übersenden Sie uns Ihren Widerspruch an die im Abschnitt „Verantwortlicher Anbieter“ genannten Kontaktdaten.
          <br />
          <br />
        </p>
        <strong>Newsletter</strong>
        <p>Sie haben die Möglichkeit, auf der Website unseren E-Mail-Newsletter zu abonnieren, mit dem wir Sie regelmäßig über folgende Inhalte informieren:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Angebote aus unserem Portfolio,</li>
          <li>Veranstaltungen unseres Unternehmens,</li>
          <li>Informationen zu relevanten Themen /Blogbeiträge</li>
          <li>Feedbackanfragen im Nachgang zu durchgeführten Webinaren.</li>
        </ul>
        <p>
          <br />Für den Empfang des Newsletters ist die Angabe der folgenden personenbezogenen Daten erforderlich.
        </p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Empfänger (Name oder Pseudonym)</li>
          <li>valide E-Mail-Adresse</li>
        </ul>
        <p>
          <br />Die Anmeldung zu unserem E-Mail-Newsletter erfolgt im Double-Optin-Verfahren. Wir senden Ihnen im Anschluss an die Eingabe der als Pflichtfeld gekennzeichneten Daten eine E-Mail an die von Ihnen angegebene E-Mail-Adresse, in der wir Sie um eine ausdrückliche Bestätigung der Anmeldung zum Newsletter bitten (durch Klick auf einen confirm-Link). So stellen wir sicher, dass Sie den Erhalt unseres E-Mail-Newsletters tatsächlich wünschen. Erfolgt die Bestätigung nicht innerhalb von 24&nbsp;Stunden, sperren wir die an uns übermittelten Informationen und löschen diese automatisch nach spätestens einem Monat.
          <br />
          <br />
        </p>
        <p>Des Weiteren werden im Zeitpunkt des Abonnements zudem folgende Daten verarbeitet:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>IP-Adresse</li>
          <li>Datum/ Uhrzeit der Anmeldung zum Newsletter,</li>
          <li>Zeitpunkt des Versands des confirm-Links,</li>
          <li>Zeitpunkt Ihrer Bestätigung des confirm-Links,</li>
        </ul>
        <p>
          <br />Wir verarbeiten Ihre IP-Adresse, den Zeitpunkt der Anmeldung zum Newsletter sowie den Zeitpunkt Ihrer Bestätigung, um Ihre Newsletter-Anmeldung zu dokumentieren und den Missbrauch Ihrer persönlichen Daten zu verhindern. Rechtsgrundlage der Verarbeitung ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f) DSGVO. Wir verarbeiten diese Daten bis zum Ablauf von zwei Jahren nach Vertragsbeendigung. Soweit die Newsletteranmeldung außerhalb eines Vertragsschlusses erfolgt, verarbeiten wir diese Daten bis zum Ablauf von zwei Jahren nach Beendigung des Nutzungsvorgangs. Wir löschen diese Daten, wenn das Newsletter-Abonnement endet.
        </p>
        <p>Nach Ihrer Bestätigung verarbeiten wir die E-Mail-Adresse und Namen/ Pseudonym des betroffenen Empfängers zum Zweck der Zusendung unseres E-Mail-Newsletters. Rechtsgrundlage der Verarbeitung ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. a) DSGVO. Wir löschen diese Daten, wenn Sie das Newsletter-Abonnement beenden.
          <br />
          <br />
        </p>
        <p>Ein Widerruf Ihrer Einwilligung in die Verarbeitung der E-Mail-Adresse für den Erhalt des Newsletters ist jederzeit möglich, entweder per Nachricht an uns (vgl. Sie die Kontaktdaten im Abschnitt „Verantwortlicher Anbieter/ Vertreter des Anbieters in der Europäischen Union“) oder indem Sie direkt den im Newsletter enthaltenen Link zur Abmeldung betätigen. Dabei wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung durch den Widerruf nicht berührt (Art. 13 Abs. 2 lit. c) DSGVO).</p>
        <p>Wir weisen Sie darauf hin, dass wir bei Versand des Newsletters Ihr Nutzerverhalten auswerten. Für diese Auswertung beinhalten die versendeten E-Mails sogenannte Web-Beacons bzw. Tracking-Pixel, die Ein-Pixel-Bilddateien darstellen, die auf unserer Website eingebunden sind. Für die Auswertungen verknüpfen wir die im Abschnitt „Zugriffsdaten“ genannten Daten und die Web-Beacons mit Ihrer E-Mail-Adresse und einer individuellen ID. Auch im Newsletter erhaltene Links enthalten diese ID.
          <br />
          <br />
        </p>
        <p>Mit den gewonnen Daten erstellen wir ein Nutzerprofil, um Ihnen den Newsletter auf Ihre individuellen Interessen zuzuschneiden. Dabei erfassen wir, wann Sie unsere Newsletter lesen, welche Links Sie in diesen anklicken und folgern daraus Ihre persönlichen Interessen. Diese Daten verknüpfen wir mit von Ihnen auf unserer Website getätigten Handlungen. Die Informationen werden solange verarbeitet, wie Sie den Newsletter abonniert haben. Nach einer Abmeldung verarbeiten wir die Daten rein statistisch und anonym.</p>
        <p>Wir bezwecken damit eine Auswertung der Nutzung und Optimierung der E-Mail-Werbung, die wir Ihnen übermitteln. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
          <br />
          <br />
        </p>
        <p>Gegen die Datenverarbeitung zum Zwecke der Direktwerbung steht Ihnen ein generelles Widerspruchsrecht ohne Angabe von Gründen zu (Art. 21 Abs. 2 DSGVO). Sie können diesem Tracking jederzeit widersprechen, indem Sie den gesonderten Abmelde-Link, der in jedem Newsletter bereitgestellt wird, anklicken oder uns über die im Abschnitt „Verantwortlicher Anbieter“ genannten Kontaktdaten informieren.</p>
        <p>Sie können das Tracking auch verhindern, indem Sie in Ihrem E-Mail-Programm die Anzeige von Bildern standardmäßig deaktivieren. In diesem Fall wird Ihnen der Newsletter nicht vollständig angezeigt und Sie können eventuell nicht alle Funktionen nutzen. Wenn Sie die Bilder manuell anzeigen lassen, erfolgt das oben genannte Tracking.
          <br />
          <br />
        </p>
        <strong>E-Mail-Marketing-Dienst „HubSpot“</strong>
        <p>Wir nutzen für den Versand von Newslettern den E-Mail-Marketing-Dienst „HubSpot“ der Firma HubSpot Inc., mit Geschäftssitz in 1801, 25 First St, 2nd Floor, Cambridge, Massachusetts, 02141 Web: https://www.hubspot.com im Weiteren: „HubSpot“). HubSpot ist ein Dienst, mit dem der Newsletterversand organisiert und analysiert werden kann. Die von Ihnen zwecks Newsletterbezug eingegebenen Daten (z.B. E-Mail-Adresse) werden auf den Servern von HubSpot in den USA verarbeitet. HubSpot hat sich dem EU-US-Privacy-Shield unterworfen, <a className='client-theme--color' target='_blank' rel='noopener noreferrer' href='https://www.privacyshield.gov/EU-US-Framework'>https://www.privacyshield.gov/EU-US-Framework</a>.
          <br />
          <br />
        </p>
        <p>Unsere mit HubSpot versandten Newsletter ermöglichen uns die Analyse des Verhaltens der Newsletterempfänger. Hierbei kann u. a. analysiert werden, wie viele Empfänger die Newsletternachricht geöffnet haben und wie oft welcher Link im Newsletter angeklickt wurde. Mit Hilfe des sogenannten Conversion-Trackings kann außerdem analysiert werden, ob nach Anklicken des Links im Newsletter eine vorab definierte Aktion (z.B. Kauf eines Produkts auf unserer Website) erfolgt ist.
          <br />
          <br />
        </p>
        <p>Die Auswertung der genannten Informationen dient dazu, die Lesegewohnheiten der Empfänger zu erkennen, um unsere Newsletter-Inhalte entsprechend besser anzupassen und streuen zu können. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO. Wir verarbeiten diese Daten bis zum Ablauf von zwei Jahren nach Vertragsbeendigung. Soweit die Newsletteranmeldung außerhalb eines Vertragsschlusses erfolgt, verarbeiten wir diese Daten bis zum Ablauf von zwei Jahren nach Beendigung des Nutzungsvorgangs. Wir löschen diese Daten, wenn das Newsletter-Abonnement endet. Von der Speicherdauer bei HubSpot haben wir keine Kenntnis und auf sie keine Einflussmöglichkeit. Weitere Informationen zum Datenschutz und dem Schutz Ihrer Privatsphäre finden Sie unter:
          <br /><a className='client-theme--color' href='https://legal.hubspot.com/privacy-policy?_ga=2.20881147.2130845781.1527015808-1451404429.1527015808&amp;__hstc=261432146.c05db45035dbd13b9a0d79af40c13a1c.1527746990420.1544084358494.1549550993943.239&amp;__hssc=261432146.1.1549550993943&amp;__hsfp=156456894' target='_blank' rel='noopener noreferrer'>https://legal.hubspot.com/privacy-policy?_ga=2.20881147.2130845781.1527015808-1451404429.1527015808</a>.
          <br />
          <br />
        </p>
        <p>Gegen die Datenverarbeitung zum Zwecke der Direktwerbung steht Ihnen ein generelles Widerspruchsrecht ohne Angabe von Gründen zu (Art. 21 Abs. 2 DSGVO). Sie können der Verarbeitung der Daten für HubSpot jederzeit widersprechen, indem Sie den Abmelde-Link am Ende der E-Mails von HubSpot aktivieren oder uns über die im Abschnitt „Verantwortlicher Anbieter“ genannten Kontaktdaten informieren. Damit endet gleichzeitig die Verarbeitung für den Erhalt des Newsletters und für die statistischen Analysen. Ein getrennter Widerspruch des Versandes via HubSpot oder der statistischen Auswertung ist nicht möglich.
          <br />
          <br />
        </p>
        <p>Sie können auf unserer Website Whitepapers herunterladen. Die von Ihnen zu diesem Zwecke angegeben Daten speichern und verarbeiten wir zur Erfüllung Ihrer Anfrage und Zurverfügungstellung des Whitepapers. Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. b) DSGVO. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen. Aufgrund zwingender handels- und steuerrechtlicher Vorschriften sind wir ggf. verpflichtet, Ihre Adress-, Zahlungs- und Bestelldaten für die Dauer von zehn Jahren aufzubewahren. Nach zwei Jahren nach Vertragsbeendigung nehmen wir eine Einschränkung der Verarbeitung vor und reduzieren die Verarbeitung auf die Einhaltung der bestehenden gesetzlichen Verpflichtungen.</p>
        <p>Auch werden die Daten nicht gelöscht, wenn Sie uns die Einwilligung zur weiteren Datenverarbeitung zum Zwecke der E-Mail-Werbung erteilt haben. In diesem Fall verarbeiten wir Ihre Daten auch über die Vertragserfüllung hinaus. Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. a) DSGVO. Ihre Einwilligung können Sie jederzeit per E-Mail an die unter dem Punkt „Verantwortlicher Anbieter“ angegebenen Kontaktdaten ohne Angabe von Gründen widerrufen. Dabei wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung durch den Widerruf nicht berührt (Art. 13 Abs. 2 lit. c) DSGVO). Soweit für die Verarbeitung keine anderweitige Rechtsgrundlage einschlägig sein sollte, löschen wir Ihre Daten im Anschluss an Ihren Widerruf.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Hosting</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Wir verwenden externe Hosting-Leistungen, die der Zurverfügungstellung der folgenden Leistungen dienen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherressourcen und Datenbankdienste, Sicherheits- sowie technische Wartungsleistungen. Dabei werden sämtliche Daten verarbeitet, die für den Betrieb und die Nutzung unserer Website erforderlich sind.</p>
        <p>Wir nutzen externe Hosting-Leistungen für den Betrieb dieses Webangebots. Wir bezwecken mit dem Einsatz externer Hosting-Leistungen eine effiziente und sichere Zurverfügungstellung unseres Webangebots. Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO.</p>
        <p>Die Erfassung der Daten zur Bereitstellung und Nutzung der Website und die Verarbeitung der Daten über externe Webhoster ist für den Betrieb der Internetseite zwingend erforderlich. Sie können Widerspruch gegen die Verarbeitung einlegen. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO). Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Einbindung von Inhalten Dritter</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Auf der Website sind Inhalte Dritter, wie Videos, Kartenmaterial, RSS-Feeds oder Grafiken von anderen Websites eingebunden. Diese Integration setzt immer voraus, dass die Anbieter dieser Inhalte (“Drittanbieter”) die IP-Adressen der Nutzer wahrnehmen. Denn ohne die IP-Adresse können sie die Inhalte nicht an den Browser des jeweiligen Nutzers senden. Die IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich.</p>
        <p>Wir bemühen uns, nur Inhalte von Drittanbietern zu verwenden, die die IP-Adresse lediglich zur Auslieferung der Inhalte verarbeiten. Jedoch haben wir keinen Einfluss darauf, falls die Drittanbieter die IP-Adressen z.B. für statistische Zwecke verarbeiten. Soweit dies uns bekannt ist, klären wir sie nachfolgend darüber auf.</p>
        <p>Bei manchen der Drittanbieter ist eine Verarbeitung von Daten außerhalb der Europäischen Union möglich.</p>
        <p>Sie können widersprechen, indem Sie einen JavaScript-Blocker wie z.B. das Browser-Plugin ‚NoScript‘ installieren (<a href='http://www.noscript.net/' className='client-theme--color' target='_blank' rel='noopener noreferrer'>www.noscript.net</a>) oder das JavaScript in Ihrem Browser deaktivieren. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO). Hierdurch kann es allerdings zu Funktionseinschränkungen auf der Website kommen.
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Google Web Fonts</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Wir nutzen zur einheitlichen Darstellung von Schriftarten sogenannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</p>
        <p>Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 S. 1 lit. f) DSGVO dar. Von der Speicherdauer bei Google haben wir keine Kenntnis und auf sie keine Einflussmöglichkeit.</p>
        <p>Sie können der Verarbeitung widersprechen, indem Sie die Browsereinstellungen verändern, sodass der Browser Web Fonts nicht unterstützt – dann wird jedoch eine Standardschrift von Ihrem Computer genutzt. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO). Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.</p>
        <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a href='https://developers.google.com/fonts/faq' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google: <a href='https://www.google.com/policies/privacy/' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.com/policies/privacy/</a>.
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Google Tag Manager</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Wir verwenden auf unserer Website den Google Tag Manager. Google Tag Manager ist eine Lösung, mit der Vermarkter Website-Tags über eine Oberfläche verwalten können. Das Tool Tag Manager selbst (das die Tags implementiert) ist eine cookielose Domain und erfasst keine personenbezogenen Daten. Das Tool sorgt für die Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen. Google Tag Manager greift nicht auf diese Daten zu. Wenn auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert werden.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>YouTube-Videos</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Wir verwenden auf der Website Plug-ins der Videoplattform YouTube.de bzw. YouTube.com, einem Dienst, der – vertreten durch Google – von der YouTube LLC (Hauptgeschäftssitz in 901 Cherry Avenue, San Bruno, CA 94066, USA; „YouTube“) betrieben wird. Mittels der Plug-ins können wir visuelle Inhalte („Videos“), die wir auf Youtube.de bzw. Youtube.com veröffentlicht haben, auch auf dieser Website einbinden.</p>
        <p>Die Videos sind alle im „erweiterten Datenschutz-Modus“ eingebunden, d. h. dass keine Daten über Sie als Nutzer an YouTube übertragen werden, wenn Sie die Videos nicht abspielen. Erst wenn Sie die Videos abspielen, werden die nachfolgend genannten Daten übertragen. Auf diese Datenübertragung haben wir keinen Einfluss.</p>
        <p>Durch den Besuch auf der Website erhält YouTube die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Zudem werden die unter dem Abschnitt „Zugriffsdaten“ genannten Daten übermittelt. Dies erfolgt unabhängig davon, ob YouTube ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei YouTube nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. YouTube speichert Ihre Daten als Nutzungsprofile und verarbeitet sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des Sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Google verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US-Privacy-Shield unterworfen, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>.
        </p>
        <p>Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO. Die Verarbeitung dient dazu, unser Webangebot attraktiver zu gestalten und Ihnen zusätzlichen Service zu bieten. Von der Speicherdauer bei YouTube haben wir keine Kenntnis und auf sie keine Einflussmöglichkeit.</p>
        <p>Ihnen steht ein Widerspruchsrecht gegen die Verarbeitung zu, wobei Sie sich zur Ausübung dessen an YouTube richten müssen. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO). Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.</p>
        <p>Weitere Informationen zu Zweck und Umfang der Verarbeitung durch YouTube erhalten Sie in der Datenschutzerklärung unter <a href='https://www.google.de/intl/de/policies/privacy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.de/intl/de/policies/privacy</a>.
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Adobe Stock (ehemals Fotolia)</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Wir verwenden auf unserer Website Plugins der Bildagentur Adobe Stock die Bilder und anderes Bildmaterial anbietet. Anbieter der Adobe Stock Komponenten ist die Adobe Systems Software Ireleand Ltd., 4-6 Riverwalk, Citywest Business Campus, Dublin 24, Ireland („Adobe Stock“).</p>
        <p>Adobe Stock gestattet uns das Einbetten von Stockbildern mittels eines Einbettungscodes, z.B. von Text-, Video- oder Bild-Daten, die von einer fremden Webseite bereitgestellt werden. Über die technische Implementierung des Einbettungscodes wird Ihre IP-Adresse an Adobe Stock übertragen. Ferner verarbeitet Adobe Stock Informationen über unsere Website, den genutzten Browsertyp, die Browsersprache, den Zeitpunkt und die Länge des Zugriffs. Darüber hinaus kann Adobe Stock Informationen über die Nutzung unserer Website (z.B. Aufrufen von Unterseiten und Anklicken von Links) sowie andere Interaktionen, die Sie beim Besuch unserer Internetseite ausgeführt haben, erfassen. Adobe Stock verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US Privacy Shield unterworfen, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>. Weitere Informationen und die geltenden Datenschutzbestimmungen von Adobe Stock können unter&nbsp;<a href='https://www.adobe.com/de/privacy/policy.html' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.adobe.com/de/privacy/policy.html</a> abgerufen werden.
        </p>
        <p>Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO. Die Verarbeitung dient dazu, unser Webangebot attraktiver zu gestalten und Ihnen zusätzlichen Service zu bieten. Von der Speicherdauer bei Adobe Stock haben wir keine Kenntnis und auf sie keine Einflussmöglichkeit.</p>
        <p>Ihnen steht ein Widerspruchsrecht gegen die Verarbeitung zu, wobei Sie sich zur Ausübung dessen an Adobe Stock richten müssen. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Einbindung von Google Maps</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Diese Website nutzt auch den Dienst „Google Maps“ von Google zur Darstellung von Karten bzw. Kartenausschnitten und ermöglicht Ihnen damit die komfortable Nutzung der Karten-Funktion auf der Website.</p>
        <p>Durch den Besuch auf der Website erhält Google die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Zudem werden die unter dem Abschnitt „Zugriffsdaten“ genannten Daten an Google übermittelt. Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen.</p>
        <p>Google speichert Ihre Daten als Nutzungsprofile und verarbeitet sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des Sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren.</p>
        <p>Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO. Die Verarbeitung dient dazu, unser Webangebot attraktiver zu gestalten und Ihnen zusätzlichen Service zu bieten. Von der Speicherdauer bei Google haben wir keine Kenntnis und auf sie keine Einflussmöglichkeit.</p>
        <p>Ihnen steht ein Widerspruchsrecht gegen die Verarbeitung zu, wobei Sie sich zur Ausübung dessen an Google richten müssen. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.</p>
        <p>Weitere Informationen zu Zweck und Umfang der Verarbeitung durch den Plug-in-Anbieter erhalten Sie in den Datenschutzerklärungen des Anbieters. Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre: <a href='http://www.google.de/intl/de/policies/privacy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.de/intl/de/policies/privacy</a>. Google verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US Privacy Shield unterworfen, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>. Weiterführende Informationen zu den Nutzungsbedingungen von Google Maps finden Sie unter <a href='https://www.google.com/intl/de_de/help/terms_maps.html' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.com/intl/de_de/help/terms_maps.html</a>.
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Eventilo</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Zur Planung und Organisation von Events nutzen wir den Dienst Eventilo (eventilo.com, Phoenixstraat 28b, 2611AL Delft, Niederlande). Wenn Sie Ihre Daten bei der Terminbuchung eingeben, werden diese an Eventilo übermittelt und dort verarbeitet. Ihre Daten werden innerhalb der Europäischen Union verarbeitet. Von der Speicherdauer haben wir keine Kenntnis und auf sie keinen Einfluss. Die Daten werden ausschließlich zur Terminplanung und Organisation verarbeitet. Zweck der Einbindung von Eventilo ist die Verbesserung unseres Serviceangebotes. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO.</p>
        <p>Sie können der Datenverarbeitung jederzeit widersprechen. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO). Ein Widerspruchsrecht ist insbesondere gegeben, wenn die Verarbeitung nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der vorhergehenden Beschreibung der Funktionen dargestellt wird. In einem solchen Fall kann die Bearbeitung der Anfrage ggf. nicht fortgeführt werden. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen. Sie können Ihr Widerspruchsrecht ausüben durch Mitteilung an die unter dem Punkt „Verantwortlicher Anbieter“ angegebenen Kontaktdaten.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Dienste zu Statistik-, Analyse- und Marketingzwecken</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Wir nutzen Dienste von Drittanbietern zu Statistik-, Analyse- und Marketingzwecken. Auf diese Weise ist es uns möglich, Ihnen eine benutzerfreundliche, optimierte Verwendung der Website zu ermöglichen. Die Drittanbieter verwenden zur Steuerung ihrer Dienste Cookies (vgl. Sie den Abschnitt „Cookies“ zuvor). Personenbezogene Daten werden, soweit nicht nachfolgend abweichend erläutert, nicht verarbeitet.</p>
        <p>Einige der Drittanbieter eröffnen die Möglichkeit, einen direkten Widerspruch gegen den Einsatz des jeweiligen Dienstes, z.B. per Setzen eines Opt-out-Cookies, zu erklären.</p>
        <p>Aktiveren Sie ein entsprechendes Opt-out-Cookie, wird der externe Anbieter künftig keine Daten zu ihrem Nutzungsverhalten mehr verarbeiten. Ein bloß selektiver Widerspruch gegen eine individuell getroffene Auswahl von externen Diensten ist ebenfalls möglich. Wechseln Sie den Browser oder das verwendete Endgerät oder löschen Sie alle Cookies, müssen Sie das Opt-out-Cookie erneut setzen.</p>
        <p>Des Weiteren können Sie der Verwendung von Cookies auch über die Opt-out-Plattform des Bundesverbands Digitale Wirtschaft e.V. (BVDW) unter <a href='http://www.meine-cookies.org/cookies_verwalten/praeferenzmanager.html' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.meine-cookies.org/cookies_verwalten/praeferenzmanager.html</a> oder über die Deaktivierungsseite der Netzwerkwerbeinitiative (Network Advertising Initiative) unter <a href='http://www.networkadvertising.org/choices/' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.networkadvertising.org/choices/</a> direkt widersprechen. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO). Weiterführende Informationen zu nutzungsbasierter Werbung und den Opt-out-Möglichkeiten sind auch unter folgendem Link einsehbar:<a href='http://www.youronlinechoices.com/de/' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.youronlinechoices.com/de/</a>.</p>
        <p>Wir informieren Sie nachfolgend über die aktuell auf unserer Website eingesetzten Dienste externer Anbieter sowie über den Zweck und den Umfang der jeweiligen Verarbeitung im Einzelfall und über Ihre bestehenden Widerspruchsmöglichkeiten.
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Google Analytics</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Um unsere Website optimal auf Ihre Interessen abstimmen zu können, nutzen wir Google Analytics, einen Webanalysedienst von Google. Google Analytics verwendet sog. „Cookies“ (vgl. Sie den Abschnitt „Cookies“ zuvor), die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die darüber erzeugten Informationen über Ihre Benutzung dieser Website werden an einen Server von Google in den USA übertragen und dort verarbeitet.</p>
        <p>Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um für uns Reports über die Website-Aktivitäten zusammenzustellen und um uns weitere mit der Website-Nutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen.</p>
        <p>Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
        </p>
        <p>Diese Website verwendet Google Analytics mit der Erweiterung „_anonymizeIp()“. Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit umgehend gelöscht.
        </p>
        <p>Wir nutzen Google Analytics, um die Nutzung unserer Website zu analysieren und regelmäßig zu verbessern. Über die Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten. Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen werden, hat sich Google dem EU-US Privacy Shield unterworfen, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>. Rechtsgrundlage für die Verarbeitung von Google Analytics ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f) DSGVO. Die Analytics-Cookies werden spätestens nach vierzehn Monaten gelöscht.</p>
        <p>Ihnen steht ein Widerspruchsrecht zu. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO). Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plug-in herunterladen und installieren: <a href='http://tools.google.com/dlpage/gaoptout?hl=de' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://tools.google.com/dlpage/gaoptout?hl=de</a>.
        </p>
        <p>Weitere Informationen des Drittanbieters Google finden Sie unter.</p>
        <p><a href='http://www.google.com/analytics/terms/de.html' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.com/analytics/terms/de.html</a>,
        </p>
        <p>
          <a href='http://www.google.com/intl/de/analytics/learn/privacy.html' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.com/intl/de/analytics/learn/privacy.html</a><em>,</em>
        </p>
        <p><a href='http://www.google.de/intl/de/policies/privacy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.de/intl/de/policies/privacy</a><em>.</em>
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Nutzungsbasierte Online-Werbung</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <strong>Facebook CUSTOM AUDIENCES</strong>
        <p>Weiterhin verwendet die Website die Funktion „Website Custom Audiences“ über das sog. „Facebook-Pixel“ der Facebook Inc. (Anbieter ist die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland, E-Mail: <a href='mailto:impressum-support@support.facebook.com' className='client-theme--color'>impressum-support@support.facebook.com</a>, Datenschutzhinweise unter: <a href='https://www.facebook.com/privacy/explanation' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.facebook.com/privacy/explanation</a> im Weiteren: „Facebook“).</p>
        <p>Dadurch können Nutzern der Website im Rahmen des Besuchs des Sozialen Netzwerkes Facebook oder anderer das Verfahren ebenfalls nutzende Websites interessenbezogene Werbeanzeigen („Facebook-Ads“) dargestellt werden. Wir verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse ist, um unsere Website für Sie interessanter zu gestalten.
        </p>
        <p>Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von Facebook auf. Wir haben keinen Einfluss auf den Umfang und die weitere Verarbeitung der Daten, die durch den Einsatz dieses Tools durch Facebook erhoben werden und informieren Sie daher entsprechend unserem Kenntnisstand: Durch die Einbindung von Facebook Custom Audiences erhält Facebook die Information, dass Sie die entsprechende Webseite unseres Internetauftritts aufgerufen haben, oder eine Anzeige von uns angeklickt haben. Sofern Sie bei einem Dienst von Facebook registriert sind, kann Facebook den Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei Facebook registriert sind bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass der Anbieter Ihre IP-Adresse und weitere Identifizierungsmerkmale in Erfahrung bringt und speichert.</p>
        <p>Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f) DSGVO. Wir speichern in diesem Fall keine personenbezogenen Daten von Ihnen. Von der Speicherdauer bei Facebook haben wir keine Kenntnis und auf sie keine Einflussmöglichkeit.</p>
        <p>Die Deaktivierung der Funktion „Facebook Custom Audiences“ ist für eingeloggte Nutzer unter
          <a href='https://www.facebook.com/settings/?tab=ads#_' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.facebook.com/settings/?tab=ads#_</a> möglich damit Ihr Widerspruchsrecht ausüben. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO).
        </p>
        <p>
          <br />Sie können die Funktion „Facebook Custom Audiences“ auf verschiedene Weise verhindern und damit von ihrem Widerspruchsrecht Gebrauch machen:
        </p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten</li>
          <li>durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a>, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen.</li>
        </ul>
        <p>Weitere Informationen zur Verarbeitung durch Facebook erhalten Sie unter <a href='https://www.facebook.com/about/privacy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.facebook.com/about/privacy</a>.
          <br />
          <br />
        </p>
        <strong>Facebook ANALYTICS</strong>
        <p>Für den Einsatz von Facebook Analytics verwenden wir das sog. „Tracking-Pixel“ der Facebook Inc. (Anbieter ist die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland, E-Mail: <a href='mailto:impressum-support@support.facebook.com'>impressum-support@support.facebook.com</a>, Datenschutzhinweise unter: <a href='https://www.facebook.com/privacy/explanation' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.facebook.com/privacy/explanation</a>; im Weiteren: „Facebook“) zur Nachverfolgung Ihres Nutzerverhaltens. Die durch das Tracking-Pixel-Cookie erlangten Informationen dienen uns allein für statistische Zwecke, werden uns von Facebook anonym übermittelt und geben keinerlei Aufschluss über die Person des Nutzers. Sie werden jedoch durch Facebook im Einklang mit dessen Datenschutzrichtlinien mit Ihrem Facebook-Konto verbunden, gespeichert und entsprechend der Datenverwendungsrichtlinie von Facebook für eigene Werbezwecke genutzt, wobei auch eine Übermittlung Ihrer Daten an die Partner von Facebook erfolgen kann. Selbst wenn Sie nicht bei Facebook registriert sind bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass der Anbieter Ihre IP-Adresse und weitere Identifizierungsmerkmale in Erfahrung bringt und speichert.
        </p>
        <p>Die Verarbeitung Ihrer Daten erfolgt hier aufgrund von Art. 6 Abs. 1 lit. f) DS-GVO. Wir verfolgen mit dem Einsatz von Facebook Analytics das Interesse, unser Webangebot besser auswerten und unser Serviceangebot verbessern zu können. Wir speichern in diesem Fall keine personenbezogenen Daten von Ihnen. Von der Speicherdauer bei Facebook haben wir keine Kenntnis und auf sie keine Einflussmöglichkeit.</p>
        <p>Die Deaktivierung der Funktion „Facebook Analytics“ ist für eingeloggte Nutzer unter <a href='https://www.facebook.com/settings/?tab=ads#_' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.facebook.com/settings/?tab=ads#_</a> möglich, um das Widerspruchsrecht ausüben. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO).</p>
        <p>Sie können die Funktion „Facebook Analytics“ auf verschiedene Weise verhindern und damit von ihrem Widerspruchsrecht Gebrauch machen:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten</li>
          <li>durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a>, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen.</li>
        </ul>
        <p>Nähere Informationen zu den Datenschutzbestimmungen von Facebook entnehmen Sie bitte der zugehörigen Datenschutzrichtlinie unter <a href='https://de-de.facebook.com/about/privacy/' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://de-de.facebook.com/about/privacy/</a>.
          <br />
          <br />
        </p>
        <strong>GOOGLE AdSense</strong>
        <p>Die Website verwendet den Online-Werbedienst Google AdSense von Google, durch den Ihnen auf Ihre Interessen ausgelegte Werbung präsentiert werden kann. Wir verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse sein könnte, um unsere Website für Sie interessanter zu gestalten. Hierzu werden statistische Informationen über Sie erfasst, die durch unsere Werbepartner verarbeitet werden. Diese Werbeanzeigen sind über den Hinweis „Google-Anzeigen“ in der jeweiligen Anzeige erkennbar.</p>
        <p>Durch den Besuch der Website erhält Google die Information, dass Sie unsere Website aufgerufen haben. Dazu nutzt Google einen Web Beacon, um einen Cookie auf Ihrem Rechner zu setzen. Es werden die im Abschnitt „Zugriffsdaten“ genannten Daten übermittelt. Wir haben weder Einfluss auf die erhobenen Daten, noch ist uns der volle Umfang der Datenverarbeitung und die Speicherdauer bekannt. Ihre Daten werden in die USA übertragen und dort verarbeitet. Wenn Sie mit Ihrem Google-Account eingeloggt sind, können Ihre Daten diesem direkt zugeordnet werden. Wenn Sie die Zuordnung mit Ihrem Google-Profil nicht wünschen, müssen Sie sich ausloggen. Es ist möglich, dass diese Daten an Vertragspartner von Google an Dritte und Behörden weitergegeben werden. Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f) DSGVO. Von der Speicherdauer bei Google haben wir keine Kenntnis und auf sie keine Einflussmöglichkeit.</p>
        <p>Diese Website schaltet über Google AdSense keine Anzeigen von Drittanbietern.</p>
        <p>Ihnen steht ein Widerspruchsrecht gegen die Verarbeitung Ihrer Daten zu. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO).</p>
        <p>Sie können die Installation der Cookies von Google AdSense durch folgende Maßnahmen verhindern:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten</li>
          <li>durch Deaktivierung der interessenbezogenen Anzeigen bei Google über den Link <a href='http://www.google.de/ads/preferences' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.de/ads/preferences</a>, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen</li>
          <li>durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a>, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen</li>
          <li>durch dauerhafte Deaktivierung in Ihren Browsern Firefox, Internetexplorer oder Google Chrome unter dem Link <a href='http://www.google.com/settings/ads/plugin' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.com/settings/ads/plugin</a>. Wir weisen Sie darauf hin, dass Sie in diesem Fall gegebenenfalls nicht alle Funktionen dieses Angebots vollumfänglich nutzen können.
          </li>
        </ul>
        <p>Weitere Informationen zu Zweck und Umfang der Verarbeitung sowie weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre erhalten Sie unter: <a href='http://www.google.de/intl/de/policies/technologies/ads' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.de/intl/de/policies/technologies/ads</a>.
        </p>
        <p>Google hat sich dem EU-US Privacy Shield unterworfen, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>.
          <br />
          <br />
        </p>
        <strong>GOOGLE AdWords CONVERSION</strong>
        <p>Wir nutzen das Angebot von Google Adwords von Google, um mit Hilfe von Werbemitteln (sogenannten Google AdWords) auf externen Webseiten auf unsere attraktiven Angebote aufmerksam zu machen. Wir können in Relation zu den Daten der Werbekampagnen ermitteln, wie erfolgreich die einzelnen Werbemaßnahmen sind. Wir verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse ist, unsere Website für Sie interessanter zu gestalten und eine faire Berechnung von Werbe-Kosten zu erreichen.</p>
        <p>Diese Werbemittel werden durch Google über sogenannte „Ad Server“ ausgeliefert. Dazu nutzen wir Ad Server Cookies, durch die bestimmte Parameter zur Erfolgsmessung, wie Einblendung der Anzeigen oder Klicks durch die Nutzer, gemessen werden können. Sofern Sie über eine Google-Anzeige auf unsere Website gelangen, wird von Google AdWords ein Cookie in ihrem PC gespeichert. Diese Cookies verlieren in der Regel nach 30&nbsp;Tagen ihre Gültigkeit und sollen nicht dazu dienen, Sie persönlich zu identifizieren. Zu diesem Cookie werden in der Regel als Analyse-Werte die Unique Cookie-ID, Anzahl Ad Impressions pro Platzierung (Frequency), letzte Impression (relevant für Post-View-Conversions) sowie Opt-out-Informationen (Markierung, dass der Nutzer nicht mehr angesprochen werden möchte) gespeichert.</p>
        <p>Diese Cookies ermöglichen Google, Ihren Internetbrowser wiederzuerkennen. Sofern ein Nutzer bestimmte Seiten der Webseite eines AdWords-Kunden besucht und das auf seinem Computer gespeicherte Cookie noch nicht abgelaufen ist, können Google und der Kunde erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jedem AdWords-Kunden wird ein anderes Cookie zugeordnet. Cookies können somit nicht über die Webseiten von AdWords-Kunden nachverfolgt werden. Wir selbst verarbeiten in den genannten Werbemaßnahmen keine personenbezogenen Daten. Wir erhalten von Google lediglich statistische Auswertungen zur Verfügung gestellt. Anhand dieser Auswertungen können wir erkennen, welche der eingesetzten Werbemaßnahmen besonders effektiv sind. Weitergehende Daten aus dem Einsatz der Werbemittel erhalten wir nicht, insbesondere können wir die Nutzer nicht anhand dieser Informationen identifizieren.
        </p>
        <p>Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von Google auf. Wir haben keinen Einfluss auf den Umfang und die weitere Verarbeitung der Daten, die durch den Einsatz dieses Tools durch Google erhoben werden und informieren Sie daher entsprechend unserem Kenntnisstand: Durch die Einbindung von AdWords Conversion erhält Google die Information, dass Sie den entsprechenden Teil unseres Internetauftritts aufgerufen oder eine Anzeige von uns angeklickt haben. Sofern Sie bei einem Dienst von Google registriert sind, kann Google den Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei Google registriert sind bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass der Anbieter Ihre IP-Adresse in Erfahrung bringt und speichert.
        </p>
        <p>Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f) DSGVO. Von der Speicherdauer bei Google haben wir keine Kenntnis und auf sie keine Einflussmöglichkeit.</p>
        <p>Ihnen steht ein Widerspruchsrecht gegen die Verarbeitung Ihrer Daten zu. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO).</p>
        <p>Sie können die Teilnahme an diesem Tracking-Verfahren auf verschiedene Weise verhindern:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten</li>
          <li>durch Deaktivierung der Cookies für Conversion-Tracking, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain „www.googleadservices.com“ blockiert werden, <a href='https://www.google.de/settings/ads' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.de/settings/ads</a>, wobei diese Einstellung gelöscht werden, wenn Sie Ihre Cookies löschen</li>
          <li>durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a>, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen</li>
          <li>durch dauerhafte Deaktivierung in Ihren Browsern Firefox, Internetexplorer oder Google Chrome unter dem Link <a href='http://www.google.com/settings/ads/plugin' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.com/settings/ads/plugin</a>. Wir weisen Sie darauf hin, dass Sie in diesem Fall gegebenenfalls nicht alle Funktionen dieses Angebots vollumfänglich nutzen können.</li>
        </ul>
        <p>Weitere Informationen zum Datenschutz bei Google finden Sie hier: <a href='http://www.google.com/intl/de/policies/privacy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.com/intl/de/policies/privacy</a> und <a href='https://services.google.com/sitestats/de.html' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://services.google.com/sitestats/de.html</a>. Alternativ können Sie die Webseite der Network Advertising Initiative (NAI) unter http://www.networkadvertising.org besuchen.
        </p>
        <p>Google hat sich dem EU-US Privacy Shield unterworfen, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>.
          <br />
          <br />
        </p>
        <strong>GOOGLE DoubleClick</strong>
        <p>Wir nutzen auf der Website weiterhin das Online-Marketing-Tool „DoubleClick“ von Google. DoubleClick setzt Cookies ein, um für die Nutzer relevante Anzeigen zu schalten, die Berichte zur Kampagnenleistung zu verbessern oder um zu vermeiden, dass ein Nutzer die gleichen Anzeigen mehrmals sieht. Über eine Cookie-ID – das ist eine Ihrem Browser zugeordnete pseudonyme Identifikationsnummer – erfasst Google, welche Anzeigen in welchem Browser geschaltet werden und kann so verhindern, dass diese mehrfach angezeigt werden. Diesem Pseudonym werden Informationen über die User-Aktivitäten auf der Website zugeordnet. Dies ermöglicht Google und seinen Partner-Sites die Schaltung von Anzeigen basierend auf dem vorherigen Besuch von Websites. Darüber hinaus kann DoubleClick mithilfe der Cookie-ID sog. Conversions erfassen, die Bezug zu Anzeigenanfragen haben. So z.B., wenn ein Nutzer eine Double-Click-Anzeige sieht und später mit demselben Browser die Website des Werbetreibenden aufruft und dort etwas kauft.</p>
        <p>Die Informationen, die durch die DoubleClick-Cookies erzeugt werden, überträgt Google an einen Server in den USA und speichert diese dort. Google beachtet die Datenschutzbestimmungen des EU-US Privacy Shield-Abkommens und ist für das „Privacy Shield“ zertifiziert, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>. Eine Übertragung der Daten an Dritte findet nur im Rahmen der gesetzlichen Vorschriften oder der Auftragsverarbeitung statt. Laut Google enthalten DoubleClick-Cookies keine personenbezogenen Informationen.</p>
        <p>Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von Google auf. Wir haben keinen Einfluss auf den Umfang und die weitere Verarbeitung der Daten, die durch den Einsatz dieses Tools durch Google erhoben werden und informieren Sie daher entsprechend unserem Kenntnisstand: Durch die Einbindung von DoubleClick erhält Google die Information, dass Sie den entsprechenden Teil unseres Internetauftritts aufgerufen oder eine Anzeige von uns angeklickt haben. Sofern Sie bei einem Dienst von Google registriert sind, kann Google den Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei Google registriert sind bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass der Anbieter Ihre IP-Adresse in Erfahrung bringt und speichert.</p>
        <p>Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO. Die DoubleClick-Cookies werden spätestens nach sechs Monaten gelöscht. Wir verfolgen mit dem Einsatz von DoubleClick das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse sein könnte, um unsere Website für Sie interessanter zu gestalten.</p>
        <p>Ihnen steht ein Widerspruchsrecht zu. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO).&nbsp; Sie können die Teilnahme an diesem Tracking-Verfahren auf verschiedene Weise widersprechen:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten</li>
          <li>durch Deaktivierung der Cookies für Conversion-Tracking, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain „www.googleadservices.com“ blockiert werden, <a href='https://www.google.de/settings/ads' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.de/settings/ads</a>, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen</li>
          <li>durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a>, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen</li>
          <li>durch dauerhafte Deaktivierung in Ihren Browsern Firefox, Internetexplorer oder Google Chrome unter dem Link <a href='http://www.google.com/settings/ads/plugin' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.com/settings/ads/plugin</a>.
          </li>
        </ul>
        <p>Weitere Informationen zu DoubleClick erhalten Sie unter: <a href='https://www.google.de/doubleclick' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.de/doubleclick</a>, <a href='http://support.google.com/' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://support.google.com</a>, <a href='https://policies.google.com/privacy?hl=de' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://policies.google.com/privacy?hl=de</a> (zum Datenschutz bei Google allgemein).
          <br />
          <br />
        </p>
        <strong>GOOGLE REMARKETING</strong>
        <p>Neben Adwords Conversion nutzen wir die Anwendung Google Remarketing von Google. Hierbei handelt es sich um ein Verfahren, mit dem wir Sie erneut ansprechen möchten. Durch diese Anwendung können Ihnen nach Besuch unserer Website bei Ihrer weiteren Internetnutzung unsere Werbeanzeigen eingeblendet werden. Dies erfolgt mittels in Ihrem Browser gespeicherter Cookies, über die Ihr Nutzungsverhalten bei Besuch verschiedener Websites durch Google erfasst und ausgewertet wird. So kann von Google Ihr vorheriger Besuch unserer Website festgestellt werden. Eine Zusammenführung der im Rahmen des Remarketing erhobenen Daten mit Ihren personenbezogenen Daten, die ggf. von Google verarbeitet werden, findet durch Google laut eigenen Aussagen nicht statt. Insbesondere wird laut Google beim Remarketing eine Pseudonymisierung eingesetzt.</p>
        <p>Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO. Von der Speicherdauer bei Google haben wir keine Kenntnis und auf sie keine Einflussmöglichkeit.</p>
        <p>Ihnen steht ein Widerspruchsrecht gegen die Verarbeitung Ihrer Daten zu. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO). &nbsp;Sie können die Teilnahme an diesem Tracking-Verfahren auf verschiedene Weise verhindern:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten</li>
          <li>durch Deaktivierung der Cookies für Conversion-Tracking, indem Sie Ihren Browser so einstellen, dass Cookies von der Domain „www.googleadservices.com“ blockiert werden, <a href='https://www.google.de/settings/ads' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.de/settings/ads</a>, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen</li>
          <li>durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a>, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen</li>
          <li>durch dauerhafte Deaktivierung in Ihren Browsern Firefox, Internetexplorer oder Google Chrome unter dem Link <a href='http://www.google.com/settings/ads/plugin' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.com/settings/ads/plugin</a>.
          </li>
        </ul>
        <br />
        <strong>LinkedIn Ads</strong>
        <p>Innerhalb unseres Onlineangebotes werden Funktionen und Inhalte des Dienstes LinkedIn eingebunden, angeboten durch die LinkedIn Corporation, 2029 Stierlin Court, Mountain View CA 94043 (im Folgenden „LinkedIn“)</p>
        <p>Dadurch können Nutzern der Website im Rahmen des Besuchs des Sozialen Netzwerkes LinkedIn oder anderer das Verfahren ebenfalls nutzende Websites interessenbezogene Werbeanzeigen („LinkedIn-Ads“) dargestellt werden. Wir verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse ist, um unsere Website für Sie interessanter zu gestalten.
        </p>
        <p>Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von LinkedIn auf. Wir haben keinen Einfluss auf den Umfang und die weitere Verarbeitung der Daten, die durch den Einsatz dieses Tools durch LinkedIn erhoben werden und informieren Sie daher entsprechend unserem Kenntnisstand: Durch die Einbindung von LinkedIn-Ads erhält LinkedIn die Information, dass Sie die entsprechende Webseite unseres Internetauftritts aufgerufen haben, oder eine Anzeige von uns angeklickt haben. Sofern Sie bei einem Dienst von LinkedIn registriert sind, kann LinkedIn den Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei LinkedIn registriert sind bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass der Anbieter Ihre IP-Adresse und weitere Identifizierungsmerkmale in Erfahrung bringt und speichert. LinkedIn hat sich dem EU-US-Privacy-Shield unterworfen (<a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>) und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten.</p>
        <p>Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f) DSGVO. Von der Speicherdauer bei LinkedIn haben wir keine Kenntnis und auf sie keine Einflussmöglichkeit. Weitere Informationen zum Datenschutz bei LinkedIn finden Sie unter <a href='https://www.linkedin.com/legal/privacy-policy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.linkedin.com/legal/privacy-policy.</a>
        </p>
        <p>Ihnen steht ein Widerspruchsrecht gegen die Verarbeitung Ihrer Daten zu. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO). Sie können der Verarbeitung auf verschiedene Weise widersprechen:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>durch Betätigung eines opt-out-Links unter: <a href='https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>.
          </li>
          <li>durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten oder</li>
          <li>durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a>, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen.</li>
        </ul>
        <br />
        <strong>Bing Ads</strong>
        <p>Wir nutzen auf unserer Website das Conversion- und Tracking-Tool Bing Ads der Microsoft Corporation (One Microsoft Way, Redmond, WA 98052-6399, „Microsoft“) ein.</p>
        <p>Dabei wird von Microsoft ein Cookie auf dem Computer der Nutzer gespeichert, um eine Analyse der Benutzung unseres Onlineangebotes zu ermöglichen. Voraussetzung dafür ist, dass der Nutzer über eine Anzeige von Microsoft Bing Ads auf unsere Website gelangt ist. Microsoft und wir können auf diese Weise erkennen, dass jemand auf eine Anzeige geklickt hat, zu unserem Onlineangebot weitergeleitet wurde und eine vorher bestimmte Zielseite erreicht hat. Wir erfahren dabei nur die Gesamtzahl der Nutzer, die auf eine Bing Anzeige geklickt haben und dann zu Zielseite weitergeleitet wurden (Conversions). Es werden keine IP-Adressen gespeichert.</p>
        <p>Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem Server von Microsoft auf. Wir haben keinen Einfluss auf den Umfang und die weitere Verarbeitung der Daten, die durch den Einsatz von Bing Ads veranlasst werden. Microsoft hat sich dem EU-US-Privacy-Shield unterworfen (<a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>) und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten.</p>
        <p>Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f) DSGVO. Von der Speicherdauer bei Microsoft haben wir keine Kenntnis und auf sie keine Einflussmöglichkeit. Weitere Informationen zum Datenschutz bei Microsoft finden Sie unter: <a href='https://privacy.microsoft.com/de-de/privacystatement' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://privacy.microsoft.com/de-de/privacystatement</a>.
        </p>
        <p>Ihnen steht ein Widerspruchsrecht gegen die Verarbeitung Ihrer Daten zu. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO). Sie können der Verarbeitung auf verschiedene Weise widersprechen:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten oder</li>
          <li>durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a>, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen.</li>
        </ul>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Plug-Ins Sozialer Netzwerke</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Auf unserer Website sind Plugins von Sozialen Netzwerken eingebunden. Diese werden von den folgenden Anbietern zur Verfügung gestellt:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Facebook (Anbieter ist die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland, E-Mail:
            <a href='mailto:impressum-support@support.facebook.com'>impressum-support@support.facebook.com</a>, Datenschutzhinweise unter: <a href='https://www.facebook.com/privacy/explanation' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.facebook.com/privacy/explanation</a>; <a href='http://www.facebook.com/about/privacy/your-info-on-other#applications' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.facebook.com/about/privacy/your-info-on-other#applications</a> sowie <a href='http://www.facebook.com/about/privacy/your-info#everyoneinfo' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.facebook.com/about/privacy/your-info#everyoneinfo</a>. Facebook hat sich dem EU-US-Privacy-Shield unterworfen, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>; im Weiteren: „Facebook“)
          </li>
          <li>Google (vgl. Sie die Begriffsbestimmungen, <a href='https://www.google.com/policies/privacy/partners/?hl=de' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.com/policies/privacy/partners/?hl=de</a>. Google hat sich dem EU-US-Privacy-Shield unterworfen, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>)
          </li>
          <li>Instagram Inc., 1601 Willow Road, Menlo Park, California 94025, USA;</li>
          <li>LinkedIn Corporation , 2029 Stierlin Court, Mountain View CA 94043 (<a href='http://www.linkedin.com/legal/privacy-policy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.linkedin.com/legal/privacy-policy</a>. LinkedIn hat sich dem EU-US-Privacy-Shield unterworfen, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>);
          </li>
          <li>Tumblr, Inc., 770 Broadway, New York, NY 10003, USA (<a href='https://www.tumblr.com/privacy/de' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.tumblr.com/privacy/de</a>);</li>
          <li>Twitter Inc.,795 Folsom St., Suite 600, San Francisco, CA 94107, USA (<a href='https://twitter.com/privacy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://twitter.com/privacy</a>. Twitter hat sich dem EU-US-Privacy-Shield unterworfen, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>);
          </li>
          <li>XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland (<a href='https://www.xing.com/privacy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.xing.com/privacy</a>);</li>
        </ul>
        <p>Wir eröffnen Ihnen die Möglichkeit, über den Button direkt mit dem Anbieter des Plug-ins zu kommunizieren. Nur wenn Sie auf das markierte Feld klicken und es dadurch aktivieren, erhält der Plug-in-Anbieter die Information, dass Sie die entsprechende Website unseres Online-Angebots aufgerufen haben. Zudem werden die unter dem Abschnitt „Zugriffsdaten“ genannten Daten übermittelt.</p>
        <p>Im Fall von Facebook und Xing wird nach Angaben der jeweiligen Anbieter in Deutschland die IP-Adresse sofort nach Erhebung anonymisiert. Durch die Aktivierung des Plug-ins werden also personenbezogene Daten von Ihnen an den jeweiligen Plug-in-Anbieter übermittelt und dort (bei US-amerikanischen Anbietern in den USA) verarbeitet. Da der Plug-in-Anbieter die Datenerhebung insbesondere über Cookies vornimmt, empfehlen wir Ihnen, vor dem Klick auf den ausgegrauten Kasten über die Sicherheitseinstellungen Ihres Browsers alle Cookies zu löschen.</p>
        <p>Wir haben weder Einfluss auf die erhobenen Daten und Verarbeitungsvorgänge, noch sind uns der volle Umfang der Datenverarbeitung, die Zwecke der Verarbeitung, die Speicherfristen bekannt. Auch zur Löschung der erhobenen Daten durch den Plug-in-Anbieter liegen uns keine Informationen vor.</p>
        <p>Der Plug-in-Anbieter speichert die über Sie erhobenen Daten als Nutzungsprofile und verarbeitet diese für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur Darstellung von bedarfsgerechter Werbung und um andere Nutzer des Sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren.</p>
        <p>Rechtsgrundlage für die Nutzung der Plug-ins ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f) DSGVO. Über die Plug-ins bieten wir Ihnen die Möglichkeit, mit den Sozialen Netzwerken und anderen Nutzern zu interagieren, so dass wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten können.</p>
        <p>Ihnen steht ein Widerspruchsrecht gegen die Bildung dieser Nutzerprofile zu, wobei Sie sich zur Ausübung dessen an den jeweiligen Plug-in-Anbieter wenden müssen. Ihr Widerspruchsrecht besteht bei Gründen, die sich aus Ihrer besonderen Situation ergeben, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, zudem, wenn die Verarbeitung der Geltendmachung und Ausübung von oder der Verteidigung gegen Rechtsansprüche dient (Art. 21 Abs. 1 DSGVO). Sie können die Bildung von Nutzerprofilen außerdem verschiedene Weise verhindern und damit von ihrem Widerspruchsrecht Gebrauch machen:
        </p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten</li>
          <li>durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der Selbstregulierungs-Kampagne „About Ads“ sind, über den Link <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a>, wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen.</li>
        </ul>
        <p>Die Datenweitergabe erfolgt unabhängig davon, ob Sie ein Konto bei dem Plug-in-Anbieter besitzen und dort eingeloggt sind. Wenn Sie bei dem Plug-in-Anbieter eingeloggt sind, werden Ihre bei uns erhobenen Daten direkt Ihrem beim Plug-in-Anbieter bestehenden Konto zugeordnet. Wenn Sie den aktivierten Button betätigen und z. B. die Seite verlinken, speichert der Plug-in-Anbieter auch diese Information in Ihrem Nutzerkonto und teilt sie Ihren Kontakten öffentlich mit.</p>
        <p>Wir empfehlen Ihnen, sich nach Nutzung eines Sozialen Netzwerks regelmäßig auszuloggen, insbesondere jedoch vor Aktivierung des Buttons, da Sie so eine Zuordnung zu Ihrem Profil bei dem Plug-in-Anbieter vermeiden können.</p>
        <p><a href='https://www.spiritlegal.com/de/' className='client-theme--color' target='_blank' rel='noopener noreferrer'>Copyright&nbsp;© by Spirit Legal LLP</a></p>
        <hr style={{ margin: '25px 0px 25px 0px' }} />
        <h2>DATENSCHUTZERKLÄRUNG SOFTWARE</h2>
        <p>
          <br />Die Nutzung unserer Software ist unter Angabe von personenbezogenen Daten möglich. Die zur Versendung oder Erstellung eines Logins notwendigen personenbezogenen Nutzerdaten werden entweder durch Ihren Arbeitgeber oder von Ihnen persönlich zur Verfügung gestellt. Für die Nutzung einzelner Services unserer Seite können sich hierfür abweichende Regelungen ergeben, die in diesem Falle nachstehend gesondert erläutert werden. Ihre personenbezogenen Daten (z.B. Name, Anschrift, E-Mail, Telefonnummer, u.ä.) werden von uns nur gemäß den Bestimmungen der DSGVO verarbeitet. Daten sind dann personenbezogen, wenn sie eindeutig einer bestimmten natürlichen Person zugeordnet werden können. Die rechtlichen Grundlagen des Datenschutzes finden Sie im Datenschutzgrundverordnung (DSGVO) und dem Telemediengesetz (TMG). Nachstehende Regelungen informieren Sie insoweit über die Art, den Umfang und Zweck der Erhebung, die Nutzung und die Verarbeitung von personenbezogenen Daten durch den Anbieter. Zudem informieren wir Sie, wie Sie sich mit uns in Verbindung setzen können, wenn Sie Fragen zu Ihren persönlichen Daten haben.
        </p>
        <p>Das MICE Portal bietet veranstaltungsbezogene Online-Services an. Alle folgenden Informationen beziehen sich auf all unsere Plattformen. Diese Datenschutzbestimmungen betreffen jegliche personenbezogene Daten, die wir über unsere Plattformen (z.B. Kontakt mit unseren Kundenservice via E-Mail) von Ihnen sammeln.</p>
        <p>Wir empfehlen Ihnen, unsere Datenschutzbestimmung regelmäßig zu lesen, damit Sie immer über unsere aktuellen Datenschutzbestimmungen informiert sind. Sollten Änderungen der Datenschutzbestimmungen, die Sie betreffen, vorgenommen werden, werden Ihnen diese Änderungen mitgeteilt, bevor sie in Kraft treten.</p>
        <p>Nur unter Zustimmung unserer Datenschutzbestimmung ist eine Nutzung der MICE Portal-Plattformen möglich.</p>
        <p>
          <br /><strong>MICE Portal GmbH</strong>
          <br />Am Bachfeld 1
          <br />85395 Attenkirchen
          <br />Deutschland
          <br />
          <br />Tel. +49 (0)8168 9974-1000
          <br />Fax. +49 (0)8168 9974-1199
          <br />info(at)miceportal.com
        </p>
        <br /> vertreten durch:
        <p>Josephine Gräfin von Brühl
          <br />MICE Portal GmbH
          <br />Am Bachfeld 1, 85395 Attenkirchen
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Fragen zum Datenschutz</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Sollten Sie Fragen zum Thema Datenschutz im Hinblick auf unser Unternehmen bzw. unsere Website haben, können Sie sich an unseren Datenschutzbeauftragten wenden:
          <br />
          <br />
        </p>
        <p><strong>{DataProtectionOfficerInfo.company}</strong>
          <br />{DataProtectionOfficerInfo.contact_person}
          <br />E-Mail: privacy(at)miceportal.com
          <br />Anschrift: {DataProtectionOfficerInfo.street} {DataProtectionOfficerInfo.house_nr}, {DataProtectionOfficerInfo.post_code} {DataProtectionOfficerInfo.city}
          <br />HRB-Nr.: 91147 | Amtsgericht Köln | Jürgen Funke, Daniel Wasser
          <br />{DataProtectionOfficerInfo.street} {DataProtectionOfficerInfo.house_nr} | {DataProtectionOfficerInfo.post_code} {DataProtectionOfficerInfo.city}| +49 221 56947927 | {DataProtectionOfficerInfo.company}
        </p>
        <p>Wir weisen darauf hin, dass die internetbasierte Datenübertragung Sicherheitslücken aufweist, ein lückenloser Schutz vor Zugriffen durch Dritte somit unmöglich ist.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Welche Art von persönlichen Informationen werden durch das MICE Portal gesammelt?</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Um Ihnen den Einkauf von Veranstaltungsleistungen zu gewährleisten, benötigen wir bestimmte Informationen. Dabei handelt es sich um grundlegende persönliche Informationen wie Ihren Namen, Ihre Kontaktangaben (z.B. Telefon, E-Mail). Zusätzlich sammeln wir Informationen von Ihrem Computer. Hierbei kann es sich um Ihre IP-Adresse, den genutzten Browser und gegebenenfalls Ihre Spracheinstellung handeln.
          <br />
          <br />
        </p>
        <strong>Persönliche Daten, die wir von Ihnen erhalten.</strong>
        <p>Das MICE Portal sammelt und verarbeitet die von Ihnen bereitgestellten Informationen. Beim Einkauf/Buchen von Veranstaltungsleistungen wird mindestens die Angabe Ihres Namens und Ihrer E-Mail-Adresse benötigt. Möglicherweise benötigen wir auch Ihre Telefonnummer.</p>
        <p>Wenn es zu Kontakt zwischen Ihnen und unserem Kundenservice kommt, telefonisch oder per E-Mail, sammeln wir auch diese Informationen. Nach Veranstaltungsende erhalten Sie gegebenenfalls eine Anfrage vom MICE Portal zur Bewertung des Dienstleisters auf einer Skala von 0-5, wobei 5 die beste Bewertung darstellt. Diese Bewertung ist anonymisiert und dient rein zur Information für künftige Nutzer der Plattform. Die Eingabe persönlicher Daten ist optional.</p>
        <p>Ihre persönlichen Daten können zur Kontaktaufnahme für bestimmte Informationszwecke verwendet werden. Beispielsweise, um Ihnen eine Einladung zu Webinaren oder Schulungen zu senden. Dies geschieht nur nach Einholung einer Erlaubnis eines Verantwortlichen Ihres Unternehmens.</p>
        <p>Falls Sie an Empfehlungsprogrammen oder Gewinnspielen teilnehmen, stellen Sie uns ihre persönlichen Daten zur Verfügung. Diese werden ausschließlich für die Dauer des Empfehlungsprogramms oder Gewinnspiels verwendet.</p>
        <p>Sollten Sie zu uns Informationen bezüglich eines Systemfehlers übermitteln, werden Ihre persönlichen Daten zur Kontaktaufnahme bis zur Behebung des Fehlers verwendet.</p>
        <p>Ihre persönlichen Daten werden zudem für unser Reporting verwendet. Je nach Rahmenvertrag Ihrer Firma können Ihre persönlichen Daten vorgangsbezogen gespeichert und für Informationszwecke durch eine berechtigte Person exportiert und zur Weiterverarbeitung verwendet werden.
          <br />
          <br />
        </p>
        <strong>Persönliche Informationen, die wir von Ihnen über andere erhalten.</strong>
        <p>Veranstaltungen werden in der Regel für mehrere Personen gebucht. Entscheiden Sie sich, die bereitgestellten Kommunikationswege unserer Plattform zur Übermittlung personenbezogener Daten von Dritten zu verwenden, werden auch diese Informationen gesammelt und an Drittdienstleister weitergeleitet, jedoch werden diese Daten nicht anderweitig verarbeitet.
        </p>
        <p>Wir müssen Sie darauf hinweisen, dass es Ihrer Verantwortung obliegt, dass die Personen, über die Sie persönliche Informationen übermitteln, sich dieses Vorgangs bewusst sind und akzeptiert haben, wie das MICE Portal deren Informationen verwendet.
          <br />
        </p>
        <strong><br />Persönliche Informationen, die wir automatisch sammeln.</strong>
        <p>Bei Nutzung unserer Plattform verarbeiten wir eventuell automatisch folgende Daten von Ihnen:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>IP-Adresse</li>
          <li>Datum und Zeit ihres Zugriffs</li>
          <li>Hardware &amp; Software</li>
          <li>Browsertyp</li>
          <li>Betriebssystem ihres Computers</li>
          <li>Applikationsversion</li>
          <li>Spracheinstellungen</li>
          <li>Informationen über Klicks und Seiten, die Ihnen angezeigt werden</li>
        </ul>
        <strong><br />Persönliche Informationen, die wir von anderen Quellen erhalten.</strong>
        <p>Möglicherweise erhalten wir Informationen von anderen Quellen, unter anderem unseren Geschäfts- oder Kooperationspartnern oder anderen unabhängigen dritten Parteien. Alle so erhaltenen Informationen können mit unseren Informationen zusammengefügt werden. Zum Beispiel nutzen Sie zur Buchung einer Location einen unserer Partner. Dabei werden Sie unseren Geschäftspartnern Ihre Buchungsinformationen übermitteln, die anschließend von diesen an uns weitergeleitet werden. Es bestehen auch Integrationen zu Drittanbietern, die die Zahlungsabwicklung zwischen Bucher und Dienstleister handhaben. Diese Leistungsanbieter teilen die Zahlungsinformationen mit uns, sodass wir Ihre Buchung bearbeiten und verwalten können.</p>
        <p>Über unsere verschiedenen Kommunikationsfeatures (z.B. Pinwand) teilen Sie möglicherweise Informationen mit unseren Dienstleistern. Diese Kommunikation zwischen Dienstleister und Nutzer wird auf unserer Plattform gespeichert. Beispielsweise bei Nachverhandlungen, ausstehenden Fragen zur Buchung oder Streitigkeiten bezüglich einer Buchung.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Warum sammelt und verarbeitet das MICE Portal Ihre Persönlichen Daten?</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Ihre gesammelten Informationen können auf folgende Arten und Weisen genutzt werden:</p>
        <p>1. Buchung/Einkauf von Leistungen: Hauptsächlich werden Ihre persönlichen Daten zur Verwaltung und Vervollständigung Ihrer Online-Buchung genutzt. Dies ist notwendig, damit wir unserem zentralen Unternehmenszweck nachkommen können.</p>
        <p>2. Kundenservice: Das MICE Portal bietet Ihnen Werktags von 8:00 bis 18:00 Uhr CET einen Kundenservice. Teilen Sie Details mit unseren Servicemitarbeitern, geben Sie uns die Möglichkeit, Ihnen zu antworten, wenn dies erforderlich ist. Dazu gehört das beantworten technischer Fragen in Bezug auf unserer Plattform oder gegen Gebühr das Suchen passender Dienstleister gemäß ihren Anforderungen und die Beantwortung jeglicher Fragen, die Sie bezüglich ihres Vorgangs haben – oder auch die Beantwortung anderer Fragen.</p>
        <p>3. Kontoeinrichtung: Kunden des MICE Portal haben ein Nutzerkonto. Diese Informationen werden zur Verwaltung dieses Kontos genutzt. Sie können Ihre Vorgänge verwalten, neue Vorgänge erstellen und buchen. Sie haben Zugriff auf alle Ihre vergangenen, aktuellen oder zukünftigen Vorgänge. Je nach Zugangsberechtigung stehen Ihnen verschiedene Features innerhalb unserer Plattform zur Verfügung (z.B. Reporting oder Überblick von Vorgängen von Kollegen). Sie können ihre persönlichen Einstellungen verwalten. Zur Verwaltung Ihrer persönlichen Einstellungen gehören:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Passwortänderung</li>
          <li>Spracheinstellung</li>
          <li>Emailbenachrichtigung über vorgangsbezogene Informationen</li>
          <li>Auswahl einer Urlaubsvertretung</li>
        </ul>
        <p>4. Marketing: Ihre Daten werden nur nach Einholung einer Erlaubnis Ihres Unternehmens zu Marketingzwecken verwendet:
        </p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Bei Teilnahme an Werbeaktivitäten (wie z.B. Gewinnspielen) werden die zur Durchführung notwendigen Informationen genutzt.
          </li>
        </ul>
        <p>5. Sonstige Kommunikation: Je nach hinterlegten Kontaktinformationen kann es vorkommen, dass wir Sie per Telefon, Post oder E-Mail kontaktieren. Wir verarbeiten und dokumentieren die Kommunikation zwischen Ihnen und uns. Dafür kann es folgende Gründe geben:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Zur Beantwortung und Bearbeitung von sämtlicher Anfragen bezgl. unserer Plattform, Vorgängen, Buchungen die Sie erstellt haben. Das MICE Portal bietet seinen Nutzern verschiedene Möglichkeiten, Informationen, Anfragen und Kommentare, sowie Daten zur internen Kommunikation oder zur Kommunikation mit Drittanbietern auszutauschen.
          </li>
          <li>Je nach Nutzereinstellung werden Ihnen Informationen bzgl. Ihrer Projektübersicht, neuer Nachrichten oder Aktivitäten, Reservierungen, Stornierungen geschickt.
          </li>
          <li>Möglicherweise werden Ihnen Fragebögen oder Einladungen zur Abgabe von Bewertungen über Ihre Erfahrungen mit dem MICE Portal oder den Drittanbietern gesendet
          </li>
          <li>Es werden ihnen möglicherweise Nachrichten administrativer Natur, darunter Sicherheitshinweise, gesendet</li>
        </ul>
        <p>6. Marktforschung: Bei Erlaubnis Ihres Unternehmens bitten wir Sie gelegentlich an Umfragen teilzunehmen. Sollten Sie an einer Umfrage eingeladen sein, lesen Sie bitte die Informationen, wie ihre persönlichen Daten bearbeitet werden.
        </p>
        <p>7. Verbesserung der User Experience unserer Services: Wir nutzen persönliche Daten für analytische Zwecke. Die Daten werden zur Verbesserung unserer Plattform, der Optimierung der Nutzererfahrung und für Testzwecke, Problembehebung sowie der der Verbesserung der Funktionalität und Qualität unseres Online-MICE-Buchungsservices verwendet.</p>
        <p>8. Dienstleisterbewertung: Sie können nach Ende einer Veranstaltung eingeladen werden, den Dienstleiser zu bewerten
        </p>
        <p>9. Juristische Zwecke: In bestimmten Fällen können wir Ihre Daten zur Bearbeitung und Lösung von rechtlichen Konflikten verwenden oder im Rahmen von Untersuchungen und zur Einhaltung von Compliance-Richtlinien auf diese zurückgreifen. Wir können sie auch nutzen, um die Nutzungsbestimmungen des MICE Portal Buchungsservices nach zumutbarem Ermessen durchzusetzen oder um Anfragen von Strafverfolgungsbehörden nachzukommen.</p>
        <p>
          <br />Zur Bearbeitung Ihrer Daten, stützen wir uns auf die folgenden rechtlichen Grundlagen:
        </p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Vertragserfüllung: Die Nutzung Ihrer Daten kann erforderlich sein, um den Vertrag, den Sie über das MICE Portal mit den Dienstleistern eingehen, umzusetzen. Bei Nutzung unserer Plattform zur Online-Buchung eines Dienstleisters, werden wir auf Ihre Daten zurückgreifen, um unserer Pflicht nachzukommen, die Buchung gemäß unserer gemeinsamen Vereinbarung abzuschließen und durchzuführen.
          </li>
          <li>Legitime Interessen: Ihre Daten können für unsere legitimen Geschäftsinteressen verwendet werden. Dies Dient zur Verbesserung unserer Produkte und Services, zudem administrativen oder juristischen Zwecken und der Aufdeckung von betrügerischen Aktivitäten.
          </li>
          <li>Für die Zwecke 1 und 2 bezieht sich das MICE Portal auf die Rechtsgrundlage, dass die Verarbeitung Ihrer personenbezogenen Daten notwendig ist, um den Vertrag zwischen Ihnen und dem Dienstleister zu erfüllen, insbesondere um Ihre Veranstaltungsbuchung abzuschließen. Ohne Bereitstellung ihrer Daten kann das MICE Portal Ihre Buchung nicht durchführen und Ihnen keinen Kundenservice anbieten. Für die Zwecke 3 bis 8 bezieht sich das MICE Portal auf sein legitimes Geschäftsinteresse, Services zur Verfügung zu stellen, Betrug zu vermeiden und die Services zu verbessern. Bei Verwendung personenbezogener Daten für die berechtigten Interessen des MICE Portals, oder die berechtigten Interessen Dritter, eruiert das MICE Portal stets Ihre Interessen und Ihre Rechte am Schutz ihrer Daten mit den Rechten und Interessen des MICE Portals sowie der Drittpartei ab. Für den Zweck 9 bezieht sich das MICE Portal sofern anwendbar auf die Einhaltung der gesetzlichen Verpflichtungen. Sofern dies nach geltendem Recht erforderlich ist, wird das MICE Portal Ihre Zustimmung einholen, bevor Ihre persönlichen Daten für Direktmarketingzwecke verarbeitet werden.
          </li>
        </ul>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Wie teilt das MICE Portal Ihre Daten mit Dritten?</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Das MICE Portal teilt unter bestimmten Umständen Ihre persönlichen Daten mit Dritten.</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>MICE Portal: Ihre Daten werden beim Prozess des Einkaufs von Leistungen an den Leistungserbringer i.d.R. Dienstleister weitergeleitet. Dieser Punkt ist je nach Rahmenvertragsbestimmungen mit ihrem Unternehmen geregelt. Üblicherweise werden im Einkaufsprozess des MICE Portal bei Online-Anfrage, Buchung, Stornierung und Kommunikation mit dem Dienstleister ihre persönlichen Daten zur Vertragsgestaltung an den Leistungserbringer übermittelt. Je nach Art der eingekauften Leistung und des Dienstleisters können dies Informationen wie Ihr Name, Ihre Kontaktdaten, Ihre besonderen Anfragen oder sonstige Informationen in Zusammenhang mit dem Vorgang/Buchung sowie Information anderer involvierten Personen (z.B. Teilnehmer der Veranstaltung) sein. Gegebenenfalls kontaktieren wir bei Fragen zu Ihrem Vorgang den Dienstleister sich im Bezug auf die Anforderung bei Ihnen zu melden. Im Falle von buchungsbezogenen Streitigkeiten können wir dem Dienstleister je nach Bedarf Daten zum Vorgang zur Klärung der Unstimmigkeiten übermitteln. Dies beinhaltet womöglich eine Kopie Ihrer Buchungsbestätigung, die als Beleg für den tatsächlichen Buchungsabschluss gilt.
          </li>
          <li>Drittdienstleister: Das MICE Portal greift auf Drittdienstleister zurück, um Ihre persönlichen Daten in unserem Namen zu verarbeiten. Dies kann diversen Zwecken dienen, wie zum Beispiel dem Ermöglichen des Einkaufs von Leistungen auf externen Plattformen. Alle Drittdienstleister unterliegen Vertraulichkeitsvereinbarungen und Auftragsverarbeitungsvereinbarungen gemäß der DSGVO.
          </li>
          <li>Zuständige Behörden: Wir legen Daten zur Rechtsdurchsetzung offen, wenn dies vom Gesetz verlangt wird oder dringend zur Verhinderung, Aufdeckung oder Strafverfolgung von kriminellen Handlungen oder Betrug nötig ist oder wir dazu aus anderen Gründen gesetzlich verpflichtet sind. Darüber hinaus können wir gegebenenfalls personenbezogene Daten mit den zuständigen Behörden teilen, wenn dies unserer Ansicht nach unabdingbar ist, um betrügerische Aktivitäten aufzudecken oder zu Verhindern.
          </li>
        </ul>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Wie verarbeitet das MICE Portal die Kommunikation, die Sie und Ihr gebuchter Dienstleister möglicherweise über das MICE Portal austauschen?</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Das MICE Portal stellt seinen Nutzern diverse Möglichkeiten, über Veranstaltungsleistungen und bestehende Buchungen über das MICE Portal zu kommunizieren. Beispielsweise können Sie auf der Vorgangsübersichtseite über „Kontaktieren“ mit dem ausgewählten Dienstleister kommunizieren. Diese Kommunikation wird nur über das MICE Portal übermittelt und revisionssicher hinterlegt, jedoch nicht zu anderen Zwecken verwendet.</p>
        <p>
          <br />Welche Sicherheits- und Aufbewahrungsmaßnahmen ergreift das MICE Portal, um Ihre persönlichen Daten zu schützen?
        </p>
        <p>Wir verwenden gemäß der DSGVO angemessene Prozeduren an, um den Schutz Ihrer personenbezogenen Daten vor Missbrauch und unberechtigten Zugriff zu gewährleisten.</p>
        <p>Um Ihre persönlichen Daten zu schützen, nutzen wir entsprechende Geschäftssysteme und -abläufe. Zudem wenden wir Sicherheitsverfahren sowie technische Beschränkungen und Zugangsbeschränkungen an, wenn unsere Server auf ihre persönlichen Daten zugreifen und sie verwenden. Nur befugte Mitarbeiter haben für die sachgemäße Erfüllung ihrer Aufgaben Zugang zu ihren persönlichen Daten.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Wie können Sie Ihre Persönlichen Daten im MICE Portal kontrollieren?</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Sie haben jederzeit das Recht, Ihre bei uns gespeicherten persönlichen Daten einzusehen. Um eine Übersicht Ihrer persönlichen Daten zu erhalten, senden Sie uns bitte eine E-Mail (<a href='mailto:privacy@miceportal.com' className='client-theme--color' target='_blank' rel='noopener noreferrer'>privacy@miceportal.com</a>). Bitte geben Sie in der Betreffzeile der E-Mail „Abfrage der persönlichen Daten“ an, um die Bearbeitung Ihrer Anfrage zu beschleunigen.</p>
        <p>Sollten Sie den Verdacht haben, dass Ihre persönlichen Daten fehlerhaft sind oder wir nicht mehr berechtigt sind, Ihre persönlichen Daten zu nutzen, oder bei sonstigen Fragen zur Verarbeitung Ihrer persönlichen Informationen oder Anliegen bezüglich der Datenschutzbestimmung, können Sie uns jederzeit kontaktieren. Wir weisen darauf hin, dass Ihnen ein Recht auf Berichtigung falscher Daten oder Löschung personenbezogener Daten zusteht, sollte diesem Anspruch keine gesetzliche Aufbewahrungspflicht entgegenstehen.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Wer ist für die Verarbeitung von Persönlichen Daten über das MICE Portal verantwortlich und wie können Sie Kontakt aufnehmen?</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Die MICE Portal GmbH ist für die Verarbeitung von persönlichen Daten wie in diesen Datenschutzbestimmungen beschrieben verantwortlich. Das MICE Portal ist eine Gesellschaft mit beschränkter Haftung und unterliegt dem deutschem Recht mit Sitz in Am Bachfeld 1, 85395 Attenkirchen, Deutschland.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Cookies und der Einsatz von Google-Analytics mit Anonymisierungsfunktion</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Wir setzen auf unserer Seite Google-Analytics, einen Webanalysedienst der Firma Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend als „Google“ bezeichnet, ein. Google-Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und hierdurch eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch diese Cookies erzeugten Informationen, beispielsweise Zeit, Ort und Häufigkeit Ihres Webseiten-Besuchs einschließlich Ihrer IP-Adresse, werden an Google in den USA übertragen und dort gespeichert. Wir verwenden auf unserer Website Google-Analytics mit dem Zusatz “_gat._anonymizeIp”. Ihre IP-Adresse wird in diesem Fall von Google schon innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt und dadurch anonymisiert. Google wird diese Informationen benutzen, um Ihre Nutzung unserer Seite auszuwerten, um Reports über die Websiteaktivitäten für uns zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird, nach eigenen Angaben, in keinem Fall Ihre IP-Adresse mit anderen Daten von Google in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen unserer Website vollumfänglich nutzen können.</p>
        <p>Des Weiteren bietet Google für die gängigsten Browser ein Deaktivierungs-Add-on an, welches Ihnen mehr Kontrolle darüber gibt, welche Daten von Google zu der von Ihnen aufgerufenen Websites erfasst werden. Das Add-on teilt dem JavaScript (ga.js) von Google Analytics mit, dass keine Informationen zum Website-Besuch an Google Analytics übermittelt werden sollen. Das Deaktivierungs-Add-on für Browser von Google Analytics verhindert aber nicht, dass Informationen an uns oder an andere von uns gegebenenfalls eingesetzte Webanalyse-Services übermittelt werden. Weitere Informationen zur Installation des Browser Add-on erhalten Sie über nachfolgenden Link:&nbsp;<a href='https://tools.google.com/dlpage/gaoptout?hl=de' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://tools.google.com/dlpage/gaoptout?hl=de</a></p>
        <hr style={{ margin: '25px 0px 25px 0px' }} />
        <h2 id='data-privacy-business-partners-customers'>DATENSCHUTZERKLÄRUNG GESCHÄFTSPARTNER UND KUNDEN</h2>
        <p>
          <br />Diese Datenschutzbestimmungen gelten für die MICE Portal GmbH, Am Bachfeld 1, 85395 Attenkirchen, Deutschland (nachfolgend benannt als „MICE Portal“, „wir“ oder „unser“) und legt fest, wie das MICE Portal personenbezogene Daten von (potenzielle, bestehende oder ehemalige) Dienstleistern und anderen Geschäftspartnern sammelt und verarbeitet. Diese Datenschutzbestimmungen gelten daher für personenbezogene Daten von Eigentümern, Mitarbeitern, Vertretern oder anderen Personen, die im Auftrag eines Dienstleisters oder anderer Geschäftspartner handeln. &nbsp;Anbieter von Buchungsplattformen oder Software, andere Geschäftspartner und die damit verbundenen Personen werden nachfolgend zusammen oder einzeln als „Geschäftspartner“ oder „Sie“ bezeichnet. Ein Geschäftspartner im Sinne dieser Datenschutzbestimmung kann ein Dienstleister sein (einschließlich Anbieter von Unterkünften, Sehenswürdigkeiten, Parks, Museen, Transfers, Catering, Rahmenprogrammen, Locations usw.), Geschäftspartner, die die Buchung von Veranstaltungen über ihre Websites und / oder Apps oder auf andere Weise ermöglichen, einschließlich Reisebüros, Drittanbietern und anderen Geschäftspartnern, die eine Geschäftsbeziehung mit dem MICE Portal unterhalten.
        </p>
        <p>Weitere Informationen finden Sie in den separaten Datenschutzerklärungen für Nutzer, die (i) beschreiben, wie das MICE Portal personenbezogene Daten von Kunden verarbeitet, die den Service vom MICE Portal zur Buchung einer Veranstaltung verwenden, und (ii) wie das MICE Portal Cookies auf den kundenseitigen Websites verwendet. Unternehmen, die die Dienstleistungen vom MICE Portal für Veranstaltungen nutzen, um Veranstaltungsreservierungen für ihre Mitarbeiter zu ermöglichen, unterliegen diesen Datenschutz- und Cookie-Richtlinien für Kunden.</p>
        <p>Die Nutzung unserer Software ist unter Angabe von personenbezogenen Daten möglich. Die zur Versendung oder Erstellung eines Logins notwendigen personenbezogenen Nutzerdaten werden entweder durch Ihren Arbeitgeber oder von Ihnen persönlich zur Verfügung gestellt. Für die Nutzung einzelner Services unserer Seite können sich hierfür abweichende Regelungen ergeben, die in diesem Falle nachstehend gesondert erläutert werden. Ihre personenbezogenen Daten (z.B. Name, Anschrift, E-Mail, Telefonnummer, u.ä.) werden von uns gemäß den Bestimmungen der DSGVO verarbeitet. Daten sind dann personenbezogen, wenn sie eindeutig einer bestimmten natürlichen Person zugeordnet werden können. Die rechtlichen Grundlagen des Datenschutzes finden Sie im Datenschutzgrundverordnung (DSGVO) und dem Telemediengesetz (TMG). Nachstehende Regelungen informieren Sie insoweit über die Art, den Umfang und Zweck der Erhebung, die Nutzung und die Verarbeitung von personenbezogenen Daten durch den Anbieter. Zudem informieren wir Sie, wie Sie sich mit uns in Verbindung setzen können, wenn Sie Fragen zu ihren persönlichen Daten haben.</p>
        <p>Das MICE Portal bietet veranstaltungsbezogene Online-Services an. Alle folgenden Informationen beziehen sich auf all unsere Plattformen. Diese Datenschutzbestimmungen betreffen jegliche personenbezogene Daten, die wir über unsere Plattformen (z.B. Kontakt mit unseren Kundenservice via E-Mail) von Ihnen sammeln.</p>
        <p>Wir empfehlen Ihnen, unsere Datenschutzbestimmung regelmäßig zu lesen, damit Sie immer über unsere aktuellen Datenschutzbestimmungen informiert sind. Sollten Änderungen der Datenschutzbestimmungen, die Sie betreffen, vorgenommen werden, werden Ihnen diese Änderungen, bevor sie in Kraft treten, mitgeteilt.</p>
        <p>Nur unter Zustimmung unserer Datenschutzbestimmung ist eine Nutzung der MICE Portal Plattformen möglich.</p>
        <p>
          <br /><strong>MICE Portal GmbH</strong>
          <br />Am Bachfeld 1
          <br />85395 Attenkirchen
          <br />Deutschland
          <br />
          <br />Tel. +49 (0)8168 9974-1000
          <br />Fax. +49 (0)8168 9974-1199
          <br />info(at)miceportal.com
        </p>
        <br /> vertreten durch:
        <p>Josephine Gräfin von Brühl
          <br />MICE Portal GmbH
          <br />Am Bachfeld 1, 85395 Attenkirchen
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Fragen zum Datenschutz</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Sollten Sie Fragen zum Thema Datenschutz im Hinblick auf unser Unternehmen bzw. unsere Website haben, können Sie sich an unseren Datenschutzbeauftragten wenden:
          <br />
          <br />
        </p>
        <p><strong>{DataProtectionOfficerInfo.company}</strong>
          <br />{DataProtectionOfficerInfo.contact_person}
          <br />E-Mail: privacy(at)miceportal.com
          <br />Anschrift: {DataProtectionOfficerInfo.street} {DataProtectionOfficerInfo.house_nr}, {DataProtectionOfficerInfo.post_code} {DataProtectionOfficerInfo.city}
          <br />HRB-Nr.: 91147 | Amtsgericht Köln | Jürgen Funke, Daniel Wasser
          <br />{DataProtectionOfficerInfo.street} {DataProtectionOfficerInfo.house_nr} | {DataProtectionOfficerInfo.post_code} {DataProtectionOfficerInfo.city} | +49 221 56947927 | {DataProtectionOfficerInfo.company}
        </p>
        <p>Wir weisen darauf hin, dass die internetbasierte Datenübertragung Sicherheitslücken aufweist, ein lückenloser Schutz vor Zugriffen durch Dritte somit unmöglich ist.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Datensammlung</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Die personenbezogenen Daten, die das MICE Portal in Bezug auf Geschäftspartner sammelt, hängen vom Kontext der Geschäftsbeziehung und der Interaktion mit dem MICE Portal, den Entscheidungen des Geschäftspartners und den verwendeten Produkten, Diensten und Funktionen ab. Die personenbezogenen Daten, die das MICE Portal über einen Geschäftspartner sammelt, können Folgendes umfassen; abhängig von der spezifischen Situation werden ggf. nicht alle der folgenden Datenelemente als personenbezogene Daten betrachtet, da sie sich auf ein Unternehmen und nicht auf eine Person beziehen können:
          <br />
          <br />
        </p>
        <strong>Personenbezogene Informationen, die wir von Ihnen erhalten</strong>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Kontaktdaten</li>
        </ul>
        <p>Wir erfassen die Kontaktdaten eines Geschäftspartners, z.B. den Vor- und Nachnamen, die geschäftliche E-Mail-Adresse sowie Ihre Telefon- und Faxnummer.</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Finanzdaten</li>
        </ul>
        <p>Wir sammeln Daten, die für die Zahlung einschließlich der Rechnungstellung erforderlich sind, wie beispielsweise Ihre Bankverbindung, Konto- und Umsatzsteuernummer.</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Sonstiges</li>
        </ul>
        <p>Während Ihrer Kommunikation mit dem MICE Portal sammelt und verarbeitet das MICE Portal Informationen hierrüber. Wenn Sie den Kundenservice kontaktieren (z.B. telefonisch oder per E-Mail) kann der Inhalt der Kommunikation personenbezogen hinterlegt werden.</p>
        <p>
          <br /><strong>Andere Daten, die wir automatisch sammeln</strong>
        </p>
        <p>Wenn Sie die Services des MICE Portals nutzen, wie beispielsweise den Dienstleisterlogin, sammelt das MICE Portal automatisch personenbezogene Daten. Dies beinhaltet Daten wie die IP-Adresse (7 Tage gespeichert für Debugging-Zwecke), Standort, Zugriffszeiten, Nutzungsdauer, abgefragte URL, Information zum Browser, Ergebnis des Besuchs (Besuch, Buchung, Ablehnung einer Abfrage, etc.).</p>
        <p>Indem Sie die personenbezogenen Daten anderer Personen in Bezug auf Ihr Unternehmen (z.B. Mitarbeiter) teilen, bestätigen Sie, dass diese Personen über die Verwendung ihrer personenbezogenen Daten seitens MICE Portal gemäß dieser Datenschutzerklärung informiert wurden und alle erforderlichen Einwilligungen erteilt haben.</p>
        <strong><br />Sonstige Informationen, die wir aus anderen Quellen erhalten</strong>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Informationen zu Insolvenzen</li>
        </ul>
        <p>Im Falle einer Insolvenz kann das MICE Portal Informationen von Insolvenzverwaltern, Gerichten oder anderen öffentlichen Behörden erhalten.</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Daten in Verbindung mit Anfragen von Strafverfolgungsbehörden und Steuerbehörden</li>
        </ul>
        <p>Strafverfolgungsbehörden oder Steuerbehörden können sich mit weiteren Informationen über Geschäftspartner an das MICE Portal wenden, falls Sie Gegenstand einer Untersuchung sind.</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Betrugsaufdeckung</li>
        </ul>
        <p>In bestimmten Fällen und nach anwendbarem Recht sammelt das MICE Portal Daten durch Drittquellen zur Ermittlung und Prävention von Betrug.</p>
        <p>
          <br /><strong>Verarbeitungszweck</strong>
        </p>
        <p>Das MICE Portal benutzt die beschriebenen über Geschäftspartner gesammelten Informationen, worunter sich auch personenbezogene Daten befinden können, zu folgenden Zwecken:</p>
        <p>1. Loginvergabe und Kontoverwaltung</p>
        <p>Das MICE Portal verwendet Kontaktdaten zur Registrierung im MICE Portal, einschließlich der Verifizierung, oder anderweitiger Unterhaltung der Geschäftsbeziehung mit dem Geschäftspartner.</p>
        <p>2. Kundenservice</p>
        <p>Der Kundenservice verwendet die angegebenen Daten, darunter personenbezogene Daten, um die Anfragen, Fragen und Bedenken von Geschäftspartnern zu bearbeiten bzw. zu klären.</p>
        <p>3. Sonstige Aktivitäten, einschließlich Marketing</p>
        <p>Das MICE Portal lädt Geschäftspartner ggf. zur Teilnahme an MICE Portal organisierten Veranstaltungen ein, von denen das MICE Portal glaubt, dass sie für Geschäftspartner relevant sind. Das MICE Portal nutzt personenbezogene Daten, wie beispielsweise Kontaktdaten, zur Information über System- und Produktupdates, für den Versand des MICE Portal Newsletters, zur Einladung von Geschäftspartnern zur Teilnahme an Marketingaktionen oder Promo-Aktionen. Wenn personenbezogene Daten für direkte Werbenachrichten zu Marketingzwecken verwendet werden, bindet das MICE Portal einen Link zur Abmeldung ein.</p>
        <p>4. Nachrichten-Tools</p>
        <p>Das MICE Portal stellt Geschäftspartnern verschiedene Arten der Kommunikation mit den Nutzern/Buchern des MICE Portals zur Verfügung. Bitte beachten Sie, das sämtliche Kommunikation, die über Kommunikationskanäle vom MICE Portal geschickt oder erhalten wird, vom MICE Portal entgegengenommen und gespeichert wird.</p>
        <p>5. Analyse, Verbesserung und Forschung</p>
        <p>Das MICE Portal verwendet personenbezogene Daten zu Forschungs- und Analysezwecken sowie zur Verbesserung der MICE Portal Services und der Nutzererfahrung, wie auch zu Testzwecken, zur Verbesserung der Funktionalität und Qualität der Online-Services vom MICE Portal. Das MICE Portal lädt Geschäftspartner regelmäßig zur Teilnahme an Umfragen ein oder führt Marktforschungen anderer Art durch. Bestimmte Geschäftspartner werden zur Nutzung einer speziellen Online-Plattform eingeladen, auf der Sie zur Interaktion mit dem MICE Portal und/oder zum Erfahrungsaustausch mit anderen Geschäftspartnern eingeladen werden. Bitte lesen Sie die Informationen, wenn Sie zur Teilnahme an einer Umfrage, Marktforschung oder Nutzung der Online-Plattform eingeladen werden, um zu erfahren, wie Ihre personenbezogenen Daten ggf. abweichend von diesen Datenschutzbestimmungen behandelt werden.</p>
        <p>6. Sicherheit, Betrugsaufdeckung und -prävention</p>
        <p>Wir verwenden Informationen, darunter auch personenbezogene Daten, um Betrugsfälle und andere illegale oder rechtsverletzende Tätigkeiten zu ermitteln, zu verhindern oder aufzudecken. Diese können personenbezogene Daten eines Geschäftspartners beinhalten, die das MICE Portal im Rahmen der Registrierung zur Verifizierung gegeben wurden, automatisch gesammelte personenbezogene Daten, oder personenbezogene Daten von Dritten (einschließlich von Veranstaltungsteilnehmern). Das MICE Portal kann, falls erforderlich, personenbezogene Daten zur Unterstützung der Strafverfolgung nutzen. Für diesen Zweck können personenbezogene Daten mit Strafverfolgungsbehörden und externen Beratern geteilt werden. Das MICE Portal kann personenbezogene Daten zur Risikoabschätzung und zu Sicherheitszwecken verwenden, inklusive der Authentifizierung der Nutzer.</p>
        <p>7. Rechtliche Regelungen und Regelbefolgung</p>
        <p>In bestimmten Fällen muss das MICE Portal die angegebenen Daten, darunter auch personenbezogene Daten, für die Bearbeitung und Lösung von Rechtstreitigkeiten oder Beschwerden verwenden sowie für regulatorische Nachforschungen und Regelbefolgungszwecke oder um vertragliche Ansprüche gegenüber Geschäftspartnern durchzusetzen.</p>
        <p>Bitte beachten Sie, dass die Dienstleisterinformationen, einschließlich Name und Adresse des Dienstleister gemäß den Allgemeinen Geschäftsbedingungen, welche Teil des separat unterzeichneten Partnervertrags sind, verwendet werden (z.B. zur Anzeige auf Webseiten Dritter, um die Durchführung einer Reisebuchung zu ermöglichen).</p>
        <p>Falls wir für die Verarbeitung personenbezogener Daten, die rechtliche Auswirkungen für Sie haben oder Sie generell schwerwiegend betreffen, automatisierte Mittel verwenden, wenden wir angemessene Sicherheitsmaßnahmen an, um Ihre Rechte und Freiheiten zu schützen, inklusive des Rechts auf menschliche Intervention.</p>
        <p>
          <br /><strong>Rechtliche Grundlagen:</strong> Für die Zwecke 1 und 2 bezieht sich das MICE Portal auf die Rechtsgrundlage, dass die Verarbeitung Ihrer personenbezogenen Daten nötig ist, um den Vertrag zwischen den Geschäftspartnern zu erfüllen. Wenn die erforderlichen Daten nicht bereitgestellt werden, kann das MICE Portal einen Dienstleister weder registrieren noch mit einem Geschäftspartner zusammenzuarbeiten sowie keinen Kundenservice anbieten. Für die Zwecke 3 bis 7 bezieht sich das MICE Portal auf sein legitimes Geschäftsinteresse, seine Services zur Verfügung zu stellen oder Services von seinen Geschäftspartnern zu nutzen, Betrug zu vermeiden und die Services zu verbessern. Wenn wir personenbezogene Daten für die berechtigten Interessen vom MICE Portal, oder die berechtigten Interessen Dritter, verwenden, dann wägt das MICE Portal stets die Interessen und Rechte der Geschäftspartner in Bezug auf den Schutz ihrer Daten mit den Rechten und Interessen vom MICE Portal sowie der Drittpartei ab.
        </p>
        <p>Für die Zwecke 6 und 7 bezieht sich das MICE Portal sofern anwendbar auf die Einhaltung der gesetzlichen Verpflichtungen (wie rechtmäßige Strafverfolgungsanträge).</p>
        <p>Sollten Sie einer Verarbeitung gemäß Ziffer 1 bis 5 widersprechen wollen und Ihnen kein Abmelde-Mechanismus zur Verfügung steht (z.B. in Ihren Konto-Einstellungen), wenden Sie sich bitte falls erforderlich an <a href='mailto:privacy@miceportal.com' className='client-theme--color'>privacy@miceportal.com</a>.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Datenweitergabe</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p><strong>Weitergabe an Dritte:</strong> Wir geben Informationen von Geschäftspartnern, einschließlich personenbezogener Daten an Dritte weiter, soweit dies gesetzlich zulässig ist und nachfolgend beschrieben wird. Wir verkaufen oder vermieten Ihre personenbezogenen Daten nicht.</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Serviceprovider (einschl. Anbieter): Wir geben Ihre Daten an dritte Dienstleister weiter, um unsere Produkte und Dienste bereitzustellen, Daten zu speichern und oder Geschäfte in unserem Auftrag zu tätigen. Diese Dienstleister verarbeiten personenbezogene Daten nur gemäß den Anweisungen von und zur Erbringung der Dienstleistungen für das MICE Portal
          </li>
          <li>Zahlungsanbieter und andere Finanzinstitutionen: Um Zahlungen zwischen einem Geschäftspartner und dem MICE Portal oder einem Buchenden und einem Geschäftspartner zu verarbeiten, werden personenbezogene Daten an Zahlungsanbieter und andere Finanzinstitutionen weitergegeben.
          </li>
          <li>Erzwungene Offenlegung: Wenn gesetzlich vorgeschrieben, unbedingt erforderlich für die Erbringung unserer Dienstleistungen, in Gerichtsverfahren, oder zum Schutz unserer Rechte oder der Rechte der Nutzer des MICE Portals geben wir personenbezogene Daten an Strafverfolgungs- und Ermittlungsbehörden weiter
          </li>
          <li>Über das MICE Portal können auch Dienstleistungen anderer Geschäftspartner gebucht werden. Die dazu notwendigen personenbezogenen Daten werden an diese Geschäftspartner weitergeleitet. Diese Geschäftspartner verarbeiten personenbezogene Daten nur gemäß den Anweisungen von und zur Erbringung der Dienstleistungen für das MICE Portal
          </li>
        </ul>
        <p>Weitergabe und Offenlegung aggregierter Daten: Wir teilen Daten in aggregierter Form und / oder in einer Form, die es dem Empfänger solcher Informationen nicht ermöglicht, Sie zu identifizieren, ggf. mit Dritten z.B. für Branchenanalysen und die demografische Profilierung.
          <br />
          <br />
        </p>
        <strong>Internationale Datenübertragung</strong>
        <p>Die Übermittlung personenbezogener Daten, wie in dieser Datenschutzerklärung beschrieben, kann die Übermittlung personenbezogener Daten aus dem Ausland in Länder umfassen, deren Datenschutzgesetze nicht so umfassend sind wie die der Länder innerhalb der Europäischen Union. Soweit dies nach europäischem Recht erforderlich ist, übermitteln wir personenbezogene Daten nur an Empfänger, die ein angemessenes Datenschutzniveau bieten. In diesen Fällen treffen wir gegebenenfalls vertragliche Vereinbarungen, um sicherzustellen, dass Ihre personenbezogenen Daten nach europäischen Standards geschützt sind. Sie können eine Kopie dieser vertraglichen Vereinbarungen unter Verwendung der unten angegebenen Kontaktdaten anfordern.
          <br />
          <br />
        </p>
        <strong>Sicherheit</strong>
        <p>Gemäß der europäischen Gesetze zum Datenschutz handelt das MICE Portal nach angemessenen Sicherheitsrichtlinien, um den Schutz Ihrer personenbezogenen Daten vor Missbrauch und unberechtigtem Zugriff zu gewährleisten.</p>
        <p>Um Ihre personenbezogenen Daten zu schützen, nutzen wir entsprechende Geschäftssysteme und -abläufe. Zudem wenden wir Sicherheitsverfahren sowie technische Restriktionen und Zugangsbeschränkungen an, wenn unsere Server auf Ihre personenbezogenen Daten zugreifen und sie verwenden. Ausschließlich befugte Mitarbeiter haben für die sachgemäße Erfüllung ihrer Aufgaben Zugang zu Ihren personenbezogenen Daten.
          <br />
          <br />
        </p>
        <strong>Datenspeicherung</strong>
        <p>Wir bewahren personenbezogenen Daten so lange auf, wie wir es für notwendig erachten, um die Geschäftsbeziehung mit einem Geschäftspartner zu pflegen, dem Geschäftspartner die Dienstleistungen vom MICE Portal bereitzustellen, unseren rechtlichen Pflichten (einschließlich der Gesetze zur Dokumentenspeicherung) nachzukommen, Streitigkeiten mit anderen Parteien beizulegen und andernfalls wie erforderlich, um es dem MICE Portal zu ermöglichen, seinen Geschäftsbetrieb aufrechtzuerhalten. Alle personenbezogenen Daten, die wir speichern, unterliegen dieser Datenschutzerklärung und unseren internen Aufbewahrungsrichtlinien. Wenn Sie eine Frage zu einem bestimmten Aufbewahrungszeitraum für bestimmte Arten personenbezogener Daten haben, die wir über Sie bearbeiten, kontaktieren Sie uns bitte über die unten angegebenen Kontaktdaten.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Ihre Optionen und Rechte</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Wir möchten, dass Sie die Kontrolle darüber haben, wie Ihre personenbezogenen Daten von uns verwendet werden. Sie haben folgende Möglichkeiten:</p>
        <p>Sie können uns auffordern, Ihnen eine Kopie der personenbezogenen Daten, die wir von Ihnen gespeichert haben, zur Verfügung zu stellen;</p>
        <p>Sie können uns über jegliche Änderung an Ihren personenbezogenen Daten in Kenntnis setzen oder Sie können uns darum bitten, jegliche personenbezogenen Daten, die wir über Sie speichern, zu korrigieren;</p>
        <p>In bestimmten Fällen können Sie uns auffordern, die personenbezogenen Daten, die wir über Sie gespeichert haben, zu löschen, zu blockieren oder einzuschränken oder Sie können bestimmten Zwecken, zu denen wir Ihre persönlichen Daten verwenden, widersprechen; und</p>
        <p>in bestimmten Situationen können Sie uns auch auffordern, Ihre personenbezogenen Daten, die Sie uns gegeben haben, einem Dritten zu übermitteln.</p>
        <p>Wenn wir Ihre personenbezogenen Daten auf Grundlage Ihrer Einwilligung verwenden, können Sie diese Einwilligung jederzeit unter Einhaltung des geltenden Rechts widerrufen. Außerdem können Sie der Verarbeitung Ihrer personenbezogenen Daten gemäß der anwendbaren Gesetze jederzeit dort widersprechen, wo diese Verarbeitung auf Grundlage eines berechtigten Interesses oder auf Grundlage des öffentlichen Interesses erfolgt.</p>
        <p>Wir vertrauen darauf, dass Sie dafür Sorge tragen, dass Ihre personenbezogenen Daten vollständig, richtig und aktuell sind. Bitte informieren Sie uns umgehend über Änderungen oder Unrichtigkeiten Ihrer personenbezogenen Daten, indem Sie uns kontaktieren.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Fragen oder Beschwerden</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Wenn Sie Fragen oder Bedenken bezüglich der Verarbeitung Ihrer persönlichen Daten haben oder wenn Sie eines der Rechte aus dieser Datenschutzerklärung ausüben möchten, kontaktieren Sie bitte unseren Datenschutzbeauftragten unter <a href='mailto:privacy@miceportal.com' className='client-theme--color'>privacy@miceportal.com</a>. Alle Fragen und Beschwerden, die an das MICE Portal gemeldet werden, werden untersucht. Das MICE Portal wird versuchen, Beschwerden und Fragen in Übereinstimmung mit internen Richtlinien zu lösen. Sie können sich mit Fragen und Beschwerden ebenfalls an Ihre örtliche Datenschutzbehörde wenden.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Änderung der Datenschutzerklärung</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Genauso wie sich unsere Geschäftsabläufe ständig ändern, können sich auch diese Datenschutzbestimmungen von Zeit zu Zeit ändern. Falls wir Änderungen vornehmen, die grundlegende Auswirkungen auf Sie haben (z.B. falls wir beginnen, personenbezogene Daten für andere Zwecke als die oben genannten zu verwenden), werden wir Sie vor Beginn dieser Verarbeitung kontaktieren.</p>
      </div>
    )
  }

  renderEn () {
    return (
      <div className='containerFluid' style={{ paddingTop: '40px', paddingBottom: '40px' }}>
        <div className='c-content-title-1'>
          <h1 className='c-font-uppercase'>Privacy Policy</h1>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>INFORMATION ABOUT THE PROCECCING OF YOUR DATA</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>In accordance with Art. 12 of the General Data Protection Regulation (hereinafter: "GDPR"), we are obliged to inform you about the processing of your data when using our website. We take the protection of your personal data very seriously and this Privacy Policy informs you about the details of the processing of your data, and about your legal rights in this regard.</p>
        <p>We reserve the right to adapt the Privacy Policy with future effect, in particular in the event of further development of the website, the use of new technologies, or changes to the legal basis or the corresponding jurisdiction.</p>
        <p>We recommend that you read the Privacy Policy from time to time and keep a printout or a copy in your records.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Definitions</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <ul style={{ paddingLeft: '20px' }}>
          <li>„<strong>Website</strong>“ or „<strong>Internet presence</strong>“ hereinafter means all pages of the responsible provider (controller) on <a className='client-theme--color' href='/'>https://www.miceportal.com</a>.
          </li>
          <li>„<strong>Personal data</strong>“ means any information relating to an identified or identifiable natural person. An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person. Personal data is therefore e.g. a person's name, e-mail address and telephone number, but may also include data about preferences, hobbies and memberships.
          </li>
          <li>„<strong>Processing</strong>“ means any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, usage, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.
          </li>
          <li>„<strong>Pseudonymization</strong>“ means the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separately and is subject to technical and organizational measures to ensure that the personal data is not attributed to an identified or identifiable natural person.
          </li>
          <li>„<strong>Consent</strong>“ of the data subject means any freely given, specific, informed and unambiguous indication of the data subject’s wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.
          </li>
          <li>„<strong>Google</strong>“ hereinafter means Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States; available in the European Union at: Google Dublin, Google Ireland Ltd, Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001.
          </li>
        </ul>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Scope</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>This Privacy Policy applies to all pages of https://www.miceportal.com/. It does not extend to any linked websites or Internet presences of other providers.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Responsible Provider</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Responsible for the processing of personal data within the scope of this Privacy Policy is:
          <br />
          <br />
        </p>
        <p><strong>MICE Portal GmbH</strong>
          <br />Am Bachfeld 1
          <br />85395 Attenkirchen
          <br />Germany
          <br />
          <br />Phone. +49 (0)8168 9974-1000
          <br />Fax. +49 (0)8168 9974-1199
          <br />info(at)miceportal.com
        </p>
        <br /> represented by:
        <p>Josephine Gräfin von Brühl
          <br />MICE Portal GmbH
          <br />Am Bachfeld 1, 85395 Attenkirchen
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Questions on Data Protection</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>If you have any questions on data protection with regard to our company or our website, please contact our data protection officer:
          <br />
          <br />
        </p>
        <p><strong>{DataProtectionOfficerInfo.company}</strong>
          <br />{DataProtectionOfficerInfo.contact_person}
          <br />{DataProtectionOfficerInfo.street} {DataProtectionOfficerInfo.house_nr}
          <br />{DataProtectionOfficerInfo.post_code} {DataProtectionOfficerInfo.city}
          <br />Germany
          <br />
          <br />
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Security</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>We have taken comprehensive technical and organizational measures to protect your personal data from unauthorized access, misuse, loss and other external disturbances. For this purpose, we regularly review our security measures and adapt them to the state of the art.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Your Rights</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>You have the following rights with regard to personal data concerning you that you may assert against us:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Right of access (Art. 15 GDPR)</li>
          <li>Right to rectification (Art. 16 GDPR) or erasure (Art. 17 GDPR)</li>
          <li>Right to restriction of processing (Art. 18 GDPR)</li>
          <li>Right to object to the processing (Art. 21 GDPR)</li>
          <li>Right to withdrawal of consent (Art. 7 para. 3 GDPR)</li>
          <li>Right to receive the data in a structured, common, machine-readable format (data portability) and the right to pass on the data to another responsible person if the prerequisite of Art. 20 para. 1 lit. a), b) GDPR is met (Art. 20 GDPR)</li>
        </ul>
        <p>You can assert your rights by notifying the contact data specified in the section "Responsible provider" or the data protection officer appointed by us.</p>
        <p>In addition, you have the right to lodge a complaint with a supervisory authority about our processing of your personal data (Art. 77 GDPR).</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Use of the Website, Access Data</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>In principle, you can use our website for purely informational purposes without disclosing your identity. When visiting individual pages of the website in this sense, only access data is transmitted to our web space provider so that the website can be displayed to you. This is the following data:
          <br />
          <br />
        </p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Browser type/browser version</li>
          <li>Operating system used</li>
          <li>Language and version of the browser software</li>
          <li>Host name of the accessing device</li>
          <li>IP address</li>
          <li>Website from which the request is received</li>
          <li>Content of the request (specific page)</li>
          <li>Date and time of the server request</li>
          <li>Access status/HTTP status code</li>
          <li>Referrer URL (the previously visited page)</li>
          <li>Amount of data transferred</li>
          <li>Time zone difference to Greenwich Mean Time (GMT)</li>
        </ul>
        <p>
          <br />Temporary processing of the IP address by the system is necessary to technically enable the website to be delivered to your computer. Processing your IP address for the duration of the session is required. The legal basis for the processing is Art. 6 para. 1 p. 1 lit. f) GDPR.
        </p>
        <p>Access data is not used to identify individual users and is not merged with other data sources. Access data is deleted when it is no longer required to achieve the purpose of their processing. In the case of collecting data to deliver the website, this is done when you end your visit to the website.
          <br />
          <br />
        </p>
        <p>IP addresses are stored in log files to ensure the functionality of the website. In addition, the data serves to optimize the website and to ensure the security of our information technology systems. An evaluation of the data for marketing purposes does not take place in this context either. In principle, the data will be deleted after seven days at the latest. Further processing is possible in individual cases. In this case, the IP address is deleted or alienated, so that an allocation of the calling client is no longer possible.
          <br />
          <br />
        </p>
        <p>The collection of data for the delivery of the website and the processing of data in log files is absolutely necessary for the operation of the website. You may object to the processing. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling grounds for processing that outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). In the case of a justified objection, we will examine the situation and either suspend or adjust the processing of the data, or point out to you the compelling legitimate grounds on the basis of which we will continue the processing.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Cookies</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>In addition to the aforementioned access data, so-called cookies are stored in the Internet browser of the device you are using when accessing the website. These are small text files with a sequence of numbers that are stored locally in the cache of the browser used. Cookies do not become part of the PC system and cannot execute any programs. They serve to make our website user-friendly. The use of cookies may be technically necessary or may be used for other purposes (e.g. analysis/evaluation of website usage).
          <br />
          <br />
        </p>
        <p><strong>a) Technically necessary Cookies</strong></p>
        <p>Some elements of our website require that the calling browser can be identified even after changing the page. The following data is processed in the cookies:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Language Settings</li>
          <li>Log-In-Information</li>
        </ul>
        <p>
          <br />The user data collected by technically necessary cookies is not processed to create user profiles. We also use so-called session cookies, which store a session ID that can be used to allocate various requests from your browser to the shared session. Session cookies are necessary to use the website. In particular, this allows us to recognize your device when you return to the website. We use this cookie to recognize you on subsequent visits to the website if you have an account with us; otherwise you would have to log in again each time you visit. The legal basis for the processing is Art. 6 para. 1 p. 1 lit. f) GDPR. We use session cookies to make the use of the website more attractive and effective. Session cookies are deleted as soon as you log out or close your browser.
          <br />
          <br />
        </p>
        <p>Most browsers are pre-set to automatically accept cookies. You may object to the processing of your data by cookies. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling grounds for processing that outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). In the case of a justified objection, we will examine the situation and either suspend or adjust the processing of the data, or point out to you the compelling legitimate grounds on the basis of which we will continue the processing.
          <br />
          <br />
        </p>
        <p>You can deactivate or restrict the transmission of cookies by changing the settings in your Internet browser. Cookies that have already been saved can be deleted at any time. This can also be done automatically. If cookies are deactivated for our website, it may not be possible to fully use all features of the website.
          <br />
          <br />
        </p>
        <p><strong>b) Technically unnecessary cookies</strong></p>
        <p>Moreover, we use cookies on the website which enable an analysis of the user's surfing behavior. For example, the following data is stored and processed in the cookies:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Search terms entered</li>
          <li>Frequency of page views</li>
          <li>Use of web site features</li>
        </ul>
        <p>
          <br />These cookies are used to make the use of the website more efficient and attractive. The legal basis for the processing is Art. 6 para. 1 p. 1 lit. f) GDPR. Technically unnecessary cookies are automatically deleted after a specified period, which may vary depending on the cookie.
          <br />
          <br />
        </p>
        <p>You may object to the processing of your data by cookies. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling grounds for processing that outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). If you do not wish to use cookies, you have the possibility to generally or selectively block the storage of cookies, or to remove cookies that have already been stored by changing your browser settings. You can also opt to have corresponding information displayed before a cookie is set. If you change the browser settings for the use of cookies or if you deactivate cookies, the functionality of this website may be limited.
          <br />
          <br />
        </p>
        <p>If we integrate third-party cookies into our website, we will point this out to you separately below.
          <br />
          <br />
        </p>
        <p><strong>c) Cookie banner note</strong></p>
        <p>When you visit the website, an information banner will inform you about the use of cookies for analytical purposes and this Privacy Policy will be referenced. In this context, there will also be a note on how the storage of cookies can be prevented in the browser settings.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Contacting our company</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>When contacting our company, e.g. by e-mail or via the contact form on the website, the personal data provided by you will be processed by us in order to answer your enquiry.</p>
        <p>ZFor the processing of inquiries through the contact form on the website, it is absolutely necessary to provide a name or pseudonym, including title, as well as a valid e-mail address. For example, you can optionally enter your first name, your company and company size, as well as the number of organized events, a telephone number and an additional message text.
          <br />
          <br />
        </p>
        <p>At the time of sending the message to us, the following data will also be processed:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>IP-Address</li>
          <li>Date/time of registration</li>
        </ul>
        <p>
          <br />The legal basis for the processing is Art.1p. 1 lit. f) GDPR or rather Art. 6 para. 1 p. 1 lit. b) GDPR, if the contact is aimed at the conclusion of a contract.
        </p>
        <p>The processing of personal data from the contact form serves solely for the processing of the enquiry. In case of contact by e-mail, this also constitutes the necessary legitimate interest in the processing of the data. Other personal data processed during the sending process serves to prevent misuse of the contact form, and to ensure the security of our information technology systems.
        </p>
        <p>In this context, the data will not be passed on to third parties. The data is processed exclusively for the processing of the conversation. We delete the data collected in this context after the processing is no longer necessary, or limit the processing to the compliance with existing statutory retention obligations.
          <br />
          <br />
        </p>
        <p>You have the possibility to object to the processing of your personal data for contact inquiries at any time. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling grounds for processing that outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). The right to object is given in particular if the processing is not necessary to fulfil a contract obligation with you, which is described in the preceding description of the features each time. In that case, the processing of the enquiry may not be continued. In the case of a justified objection, we will examine the situation and either suspend or adjust the processing of the data, or point out to you the compelling legitimate grounds on the basis of which we will continue the processing.
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>PROCESSING AND FORWARDING OF PERSONAL DATA FOR CONTRACTUAL PURPOSES</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>We process your personal data if and insofar as this is necessary for the initiation, justification, execution and/or termination of a legal transaction with our company. The legal basis for this arises from Art. 6 para. 1 p. 1 lit. b) GDPR.</p>
        <p>After the purpose has been achieved (e.g. contract processing), the personal data will be blocked or deleted for further processing, unless we are entitled to further storage and processing required in the respective context on the basis of your consent (e.g. consent to the processing of the e-mail address for sending electronic advertising mail), a contractual agreement, a statutory agreement (e.g. authorization to send direct advertising) or on the basis of justified interests (e.g. storage for the assertion of claims).</p>
        <p>Your personal data will be passed on if</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>it is necessary for the establishment, execution or termination of legal transactions with our company (e.g. transer of data to a payment service provider/shipping company for the processing of a contract with you), (Art. 6 para. 1 p. 1 lit. b) GDPR), or</li>
          <li>a subcontractor or vicarious agent whom we use exclusively within the framework of the provision of the offers or services requested by you needs this data (such vicarious agents are only entitled to process the data insofar as this is necessary for the provision of the offer or service, unless you are expressly informed otherwise), or</li>
          <li>an enforceable administrative order (Art. 6 para. 1 p. 1 lit. c) GDPR) or</li>
          <li>there is an enforceable court order (Art. 6 para. 1 p. 1 lit. c) GDPR) or</li>
          <li>we are required by law to do so (Art. 6 para. 1 p. 1 lit. c) GDPR), or</li>
          <li>the processing is necessary to protect the vital interests of the data subject or another natural person (Art. 6 para. 1 p. 1 lit. c) GDPR), or</li>
          <li>we are authorized or even obliged to a transfer for the pursuit of overriding legitimate interests (Art. 6 para. 1 p. 1 lit. f) GDPR).</li>
        </ul>
        <p>Your personal data will not be passed on to other persons, companies or bodies unless you have effectively consented to such a transfer. In this case, the legal basis for the processing is Art. 6 para. 1 p. 1 lit. a) GDPR.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Registration for Events</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>You may register on the website as a guest for company events or events at which our company is represented. The basis of the processing of your data for the participation in events is Art. 6 para. 1 p. 1 lit b) GDPR. The processing is necessary for the establishment, execution or termination of legal transactions with our company (e.g. also the transfer of data to a payment service provider/mail order company for the processing of a contract with you).</p>
        <p>In the registration form you are required to enter your name, your company, your e-mail address, your telephone number and the size of your company. The purpose of the processing is contact maintenance as well as the improvement of our external image and our public relations. If you have expressed interest in a personal appointment, your data will also be processed for the preparation and execution of the appointment.</p>
        <p>Your data will be passed on to third parties (e.g. the organizer) for the purpose of registering the event with the organizer. A targeted transfer of your personal data to a so-called third country is not planned.</p>
        <p>We delete the data collected in this context after the storage is no longer necessary, or limit the processing if there are statutory retention obligations.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Email Marketing</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <strong>Customer Advertising</strong>
        <p>We reserve the right to process the e-mail address provided by you within the scope of registration in accordance with the statutory provisions in order to send you the following content by e-mail during or following contract processing, unless you have already objected to this processing of your e-mail address:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Other interesting offers from our portfolio</li>
          <li>Information regarding events by our company</li>
          <li>Technical information</li>
          <li>Request for feedback after events</li>
        </ul>
        <p>The legal basis for the processing is Art. 6 para. 1 p. 1 lit. f) GDPR. The aforementioned processing is carried out for customer care and to improve our services. We delete your data when you cancel your newsletter subscription, no later than two years after termination of the contract.
          <br />
          <br />
        </p>
        <p>We would like to point out that you may object to receiving direct advertising at any time without incurring any costs other than the transmission costs at basic rates. You have the general right to object without giving reasons (Art. 21 para. 2 GDPR). Click on the unsubscribe link in the newsletter or send us your objection to the contact data mentioned in the section "Responsible provider (controller)".
          <br />
          <br />
        </p>
        <strong>Newsletter</strong>
        <p>You have the possibility to subscribe to our e-mail newsletter on the website, with which we regularly inform you about the following contents:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Offers from our portfolio</li>
          <li>Events by our company</li>
          <li>Information on relevant topics/blog posts</li>
          <li>Feedback requests after conducted webinars</li>
        </ul>
        <p>
          <br />In order to receive the newsletter, the following personal data is required:
        </p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Recipient (name or pseudonym)</li>
          <li>Valid e-mail address</li>
        </ul>
        <p>
          <br />For subscriptions to our e-mail newsletter we use a double opt-in process. After entering the data marked as mandatory, we will send you an e-mail to the e-mail address you have provided, in which we ask you to explicitly confirm your subscription to the newsletter (by clicking on a confirm link). This way we ensure that you really wish to receive our e-mail newsletter. If we do not receive your confirmation within 24 hours, we block the information transmitted to us and automatically delete it after one month at the latest.
          <br />
          <br />
        </p>
        <p>Furthermore, the following data is processed at the time of the subscription:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>IP-Address</li>
          <li>Date/ time of the subscription to the newsletter</li>
          <li>Date/time of sending the confirm link</li>
          <li>Date/time of your confirmation via confirm link</li>
        </ul>
        <p>
          <br />We process your IP address, the time of subscription to the newsletter and the time of your confirmation in order to document your newsletter registration and to prevent misuse of your personal data. The legal basis for the processing is Art.6para.1p. 1 lit. f) GDPR. We process this data up to two years after termination of the contract. If the newsletter subscription takes place outside of a contract, we process the data up to two years after termination of the utilization. We delete this data when the newsletter subscription ends.
        </p>
        <p>After your confirmation we will process the e-mail address and name/pseudonym of the concerned recipient for the purpose of delivery of our e-mail newsletter. The legal basis for the processing is Art.6para.1p. 1 lit. a) GDPR. We delete this data when you terminate the newsletter subscription.
          <br />
          <br />
        </p>
        <p>You may revoke your consent to the processing of your e-mail address to receive the newsletter at any time, either by sending us a message (see contact details in the section "Responsible provider (controller)/representative of the provider in the European Union") or by clicking directly on the unsubscribe link contained in the newsletter. The legality of the processing based on consent until revocation thereof is not affected by the revocation (Art. 13 para. 2 lit. c) GDPR).</p>
        <p>We would like to point out that we evaluate your user behavior when sending you the newsletter. For this analysis, the e-mails sent contain so-called web beacons or tracking pixels, which represent single-pixel image files that are integrated on our website. For evaluation purposes, we link the data mentioned in the section "access data" and the web beacons with your e-mail address and an individual ID. Links received in the newsletter also contain this ID.
          <br />
          <br />
        </p>
        <p>With the collected data we create a user profile to tailor the newsletter to your individual interests. We record when you read our newsletters, which contained links you click on, and we deduce your personal interests. We link this data to actions taken by you on our website. The information is processed as long as you have subscribed to the newsletter. After you unsubscribe, we will process the data anonymously and purely for statistical reasons.</p>
        <p>This is done to evaluate the usage and optimization of the e-mail advertising we send you. The legal basis for the processing is Art. 6 para. 1 p. 1 lit. f) GDPR.
          <br />
          <br />
        </p>
        <p>You have the general right to object to the processing of data for the purpose of direct advertising without giving reasons (Art. 21 para. 2 GDPR). You may object to this tracking at any time by clicking on the separate unsubscribe link provided in each newsletter, or by informing us via the contact data specified in the section "Responsible provider (controller)".</p>
        <p>You can also prevent tracking by disabling the display of images in your e-mail program by default. In this case the newsletter will not be displayed entirely and you may not be able to use all features. If you display the images manually, the aforementioned tracking takes place.
          <br />
          <br />
        </p>
        <strong>HubSpot Email-Marketing-Service</strong>
        <p>We use the e-mail marketing service HubSpot by HubSpot Inc. with registered office in 1801, 25 First St, 2nd Floor, Cambridge, Massachusetts, 02141 Web: https://www.hubspot.com.
          <br />
          <br />
        </p>
        <p>Sending out newsletters with HubSpot enables us to analyze the behavior of newsletter recipients. Among other things, it is possible to analyze how many recipients have opened the newsletter, and how often links contained in the newsletter were clicked on. By means of so-called conversion tracking it is also possible to analyze whether a pre-defined action (e.g. purchase of a product on our website) was carried out after clicking on a link in the newsletter.
          <br />
          <br />
        </p>
        <p>The evaluation of the information serves to recognize the reading habits of the recipients, in order to better adapt and scatter our newsletter contents. The legal basis for the processing is Art. 6 para. 1 p. 1 lit. f) GDPR. We will process this data up to two years after termination of the contract. If the newsletter subscription takes place outside of a contract, we process the data up to two years after termination of the utilization. We delete this data when the newsletter subscription ends. We have no knowledge of the storage duration at HubSpot, and no possibility of influence. Further information on data protection and the protection of your privacy can be found at:
          <br /><a className='client-theme--color' href='https://legal.hubspot.com/privacy-policy?_ga=2.20881147.2130845781.1527015808-1451404429.1527015808&amp;__hstc=261432146.c05db45035dbd13b9a0d79af40c13a1c.1527746990420.1544084358494.1549550993943.239&amp;__hssc=261432146.1.1549550993943&amp;__hsfp=156456894' target='_blank' rel='noopener noreferrer'>https://legal.hubspot.com/privacy-policy?_ga=2.20881147.2130845781.1527015808-1451404429.1527015808</a>.
          <br />
          <br />
        </p>
        <p>You have the general right to object to the processing of data for the purpose of direct advertising without giving reasons (Art. 21 para. 2 GDPR). You may object to the processing of data for HubSpot at any time by activating the unsubscribe link at the bottom of HubSpot e-mails, or by informing us via the contact data provided in the section "Responsible provider (controller)". At the same time the processing for receiving the newsletter and for statistical analysis ends. A separate objection to the distribution via HubSpot or the statistical evaluation is not possible.
          <br />
          <br />
        </p>

        <p>You can download whitepapers on our website. We store and process the information you provide for this purpose to fulfill your request and to provide the whitepaper. The legal basis for the processing is Art. 6 para. 1 p. 1 lit. b) GDPR. We delete the data collected in this context after the storage is no longer necessary, or limit the processing if there are statutory retention obligations. Due to mandatory commercial and tax regulations, we may be obliged to retain your address, payment and order data for a period of ten years. Two years after termination of the contract, we restrict and reduce the processing in compliance with existing legal obligations.</p>
        <p>Also, the data will not be deleted if you have given consent to the further processing of your data for the purpose of e-mail advertising. In this case, we will process your data beyond the fulfilment of the contract. The legal basis for the processing is Art. 6 para. 1 p. 1 lit. a) GDPR. You may revoke your consent at any time by e-mail to the contact data provided in the section "Responsible provider (controller)" without giving reasons. The legality of the processing based on consent until revocation thereof is not affected by the revocation (Art. 13 para. 2 lit. c) GDPR). As far as no other legal basis is relevant for the processing, we will delete your data following your revocation.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Hosting</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>We use external hosting services to provide the following services: Infrastructure and platform services, computing capacity, storage resources and database services, security and technical maintenance services. All data necessary for the operation and use of our website will be processed.</p>
        <p>We use external hosting services for the operation of this website. By using external hosting services, we aim to make our website available in an effective and secure manner. The legal basis for the processing is Art. 6 para. 1 p. 1 lit. f) GDPR.
        </p>
        <p>The collection of data for the provision and use of the website and the processing of data via external web hosts is absolutely essential for the operation of the website. You may object to the processing. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). In the case of a justified objection, we will examine the situation and either suspend or adjust the processing of the data, or point out to you the compelling legitimate grounds on the basis of which we will continue the processing.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Integration of Third-Party Content</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>The website includes third-party content such as videos, maps, RSS feeds or graphics from other websites. This integration always requires that the providers of this content (third party providers) perceive the IP addresses of the users. Without IP address they are unable send the content to the browser of the respective user. The IP address is required for the display of the content.</p>
        <p>We make every effort to only use content from third parties that process the IP address solely for the purpose of delivering the content. We have, however, no influence on whether third-party providers process the IP addresses, e.g. for statistical purposes. As far as this is known to us, we will inform you below.</p>
        <p>Some of these third parties may process data outside the European Union.</p>
        <p>You can object by installing a JavaScript blocker such as the browser plugin NoScript (<a href='http://www.noscript.net/' className='client-theme--color' target='_blank' rel='noopener noreferrer'>www.noscript.net</a>) oor by deactivating JavaScript in your browser. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). However, this can lead to restrictions of the features of the website.
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Google Web Fonts</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>We use so-called web fonts provided by Google for the uniform display of fonts. When you call up a page, your browser loads the required web fonts into your browser cache to display texts and fonts correctly.</p>
        <p>For this purpose, the browser you are using must connect to Google's servers. As a result, Google becomes aware that our website was called by your IP address. The use of Google web fonts is based on the interest of a uniform and appealing presentation of our online offers. This constitutes a legitimate interest within the meaning of Art. 6 para. 1 p. 1 lit. f) GDPR. We have no knowledge of the storage duration at Google, and no possibility of influence.</p>
        <p>You can object to the processing by changing the browser settings so that the browser does not support web fonts - then a default font is used by your computer. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). In the case of a justified objection, we will examine the situation and either suspend or adjust the processing of the data, or point out to you the compelling legitimate grounds on the basis of which we will continue the processing.</p>
        <p>urther information about Google web fonts can be found at <a href='https://developers.google.com/fonts/faq' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://developers.google.com/fonts/faq</a> and in Google's privacy policy: <a href='https://www.google.com/policies/privacy/' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.com/policies/privacy/</a>.
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Google Tag Manager</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>We use Google Tag Manager on our website. Google Tag Manager is a solution that allows marketers to manage website tags in one single interface. The Tag Manager (which implements the tags) is a cookie-less domain and does not collect any personal data. This tool triggers other tags, which in turn may collect data. Google Tag Manager does not access this data. If a deactivation was performed at domain or cookie level, it will remain in effect for all tracking tags implemented by Google Tag Manager.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>YouTube-Videos</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>We use plugins by the video platform YouTube.de or YouTube.com on the website. This service is represented by Google and operated by YouTube LLC (headquarters at 901 Cherry Avenue, San Bruno, CA 94066, United States; hereinafter "YouTube"). Using plugins allows us to include visual content (videos) which we have published on Youtube.de and Youtube.com on this website.</p>
        <p>All videos are embedded in extended privacy mode, which means that no data about you as a user will be transmitted to YouTube if you do not play the videos. Only when you play the videos, the following data will be transmitted. We have no influence on this data transmission.</p>
        <p>By visiting the website, YouTube is made aware that you have accessed the corresponding subpage of our website. In addition, the data specified in the section "access data" is transmitted. This is done regardless of whether YouTube provides a user account on which you are logged in or whether no user account exists. If you are logged in to Google, your information will be directly associated with your account. If you do not wish to be associated with your profile on YouTube, you must log out before activating the button. YouTube stores your data as user profiles and processes them for purposes of advertising, market research and/or demand-oriented design of its website. Such evaluation takes place in particular (even for users that are not logged in) to provide demand-oriented advertising and to inform other users of the social network about your activities on our website. Google also processes your personal data in the United States and has submitted to the EU-US Privacy Shield, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>.
        </p>
        <p>The legal basis for the processing is Art. 6 para. 1 p. 1 lit. f) GDPR. The processing is used to make our website more attractive and to offer you additional services. We have no knowledge of the storage duration at YouTube, and no possibility of influence.</p>
        <p>You have the right to object to the processing, however, you are required to contact YouTube to exercise this right. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). You can deactivate or restrict the transmission of cookies by changing the settings in your Internet browser. Cookies that have already been saved can be deleted at any time. This can also be done automatically. If cookies are deactivated for our website, it may not be possible to fully use all features of the website.</p>
        <p>For more information on the purpose and scope of the processing by YouTube, please see their privacy policy at <a href='https://www.google.de/intl/de/policies/privacy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.de/intl/de/policies/privacy</a>.
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Adobe Stock (formerly Fotolia)</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>We use plugins from Adobe Stock on our website, which provides images and other image material. The provider of Adobe Stock components is Adobe Systems Software Ireleand Ltd, 4-6 Riverwalk, Citywest Business Campus, Dublin 24, Ireland (hereinafter "Adobe Stock").</p>
        <p>Adobe Stock allows us to embed stock images using an embed code, e.g. text, video, or image data provided by an external website. The technical implementation of the embed code transfers your IP address to Adobe Stock. Adobe Stock also processes information about our website, the type of browser used, the browser language, the time and duration of access. FFurthermore, Adobe Stock may collect information about your use of our website (e.g. access to sub-pages and clicking on links) and other interactions you may have performed while visiting our website. Adobe Stock also processes your personal data in the United States and has submitted to the EU-US Privacy Shield <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>. For more information on the purpose and scope of the processing by Adobe Stock, please see their privacy policy at <a href='https://www.adobe.com/en/privacy/policy.html' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.adobe.com/en/privacy/policy.html</a>.
        </p>
        <p>The legal basis for the processing is Art. 6 para. 1 p. 1 lit. f) GDPR. The processing is used to make our website more attractive and to offer you additional services. We have no knowledge of the storage duration at Adobe Stock, and no possibility of influence.</p>
        <p>You have the right to object to the processing, however, you and are required to contact Adobe Stock to exercise this right. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). You can deactivate or restrict the transmission of cookies by changing the settings in your Internet browser. Cookies that have already been saved can be deleted at any time. This can also be done automatically. If cookies are deactivated for our website, it may not be possible to fully use all features of the website.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Integration of Google Maps</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>This website also uses the service Google Maps by Google to display maps or map sections to allow you to conveniently use the map feature on the website.</p>
        <p>By visiting the website, Google is made aware that you have accessed the corresponding subpage of our website. In addition, the data specified in the section "access data" is transmitted to Google. This is done regardless of whether Google provides a user account on which you are logged in or whether no user account exists. If you are logged in to Google, your information will be directly associated with your account. If you do not wish to be associated with your profile on Google, you must log out before activating the button.</p>
        <p>Google stores your data as user profiles and processes them for purposes of advertising, market research and/or demand-oriented design of its website. Such evaluation takes place in particular (even for users that are not logged in) to provide demand-oriented advertising and to inform other users of the social network about your activities on our website.</p>
        <p>The legal basis for the processing is Art. 6 para. 1 p. 1 lit. f) GDPR. The processing is used to make our website more attractive and to offer you additional services. We have no knowledge of the storage duration at Google, and no possibility of influence.</p>
        <p>You have the right to object to the processing, however, you are required to contact Google to exercise this right. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). You can deactivate or restrict the transmission of cookies by changing the settings in your Internet browser. Cookies that have already been saved can be deleted at any time. This can also be done automatically. If cookies are deactivated for our website, it may not be possible to fully use all features of the website.</p>
        <p>For more information on the purpose and scope of the processing by the plugin provider, please refer to the provider's privacy policy. There you will also find further information on your relevant rights and setting options for the protection of your privacy: <a href='http://www.google.de/intl/de/policies/privacy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.de/intl/de/policies/privacy</a>.
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Eventilo</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>For the planning and organization of events we use the service Eventilo (eventilo.com, Phoenixstraat 28b, 2611AL Delft, Netherlands). If you enter your data when you book an appointment, it will be transmitted to Eventilo and processed there. Your data will be processed within the European Union. We have no knowledge of the storage duration, and no possibility of influence. The data is processed exclusively for scheduling and organization. Integrating Eventilo serves to improve our range of services. The legal basis for the processing is Art. 6 para. 1 p. 1 lit. f) GDPR.</p>
        <p>You may object to data processing at any time. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). The right to object is given in particular if the processing is not necessary to fulfil a contract obligation with you, which is described in the preceding description of the features each time. In that case, the processing of the enquiry may not be continued. In the case of a justified objection, we will examine the situation and either suspend or adjust the processing of the data, or point out to you the compelling legitimate grounds on the basis of which we will continue the processing. You can exercise your right to object by sending a message to the contact details provided in the section "Responsible provider/controller".</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>SERVICES OF STATISTICAL, ANALYTICAL AND MARKETING PURPOSES</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>We use third-party services for statistical, analytical and marketing purposes. This allows us to provide a user-friendly, optimized use of the website. Third-party providers use cookies to control their services (see section "Cookies"). Personal data will not be processed, unless otherwise explained below.</p>
        <p>Some of the third-party providers allow you to directly object to the use of the respective service, e.g. by setting an opt-out cookie.</p>
        <p>If you activate a corresponding opt-out cookie, the external provider will no longer process any data regarding your user behavior. A mere selective objection to an individual selection of external services is also possible. If you change the browser or the device used, or delete all cookies, you must set the opt-out cookie again.</p>
        <p>Furthermore, you can also opt-out of the use of cookies via the opt-out platform of the Bundesverband Digitale Wirtschaft e.V. (German Digital Industry Association). (BVDW) at <a href='http://www.meine-cookies.org/cookies_verwalten/praeferenzmanager.html' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.meine-cookies.org/cookies_verwalten/praeferenzmanager.html</a> or via the deactivation page of the Network Advertising Initiative at <a href='http://www.networkadvertising.org/choices/' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.networkadvertising.org/choices/</a>. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). Additional information on usage-based advertising and opt-out options can also be found at the following link: <a href='http://www.youronlinechoices.com/de/' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.youronlinechoices.com/de/</a>.</p>
        <p>Hereafter we inform you on the services of external providers currently used on our website, on the purpose and scope of the respective processing in individual cases, and on your options of objection.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Google Analytics</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>In order to fully tailor our website to your interests, we use Google Analytics, a web analysis service by Google. Google Analytics uses so-called cookies (see section "Cookies"), which are stored on your computer and enable an analysis of your use of the website. The information collected on your use of this website is transmitted to a Google server in the United States and processed there.</p>
        <p>If IP anonymization is activated on this website, your IP address will be reduced by Google within Member States of the European Union or in other countries party to the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be transmitted to a Google server in the United States and shortened there. On our behalf, Google will use this information to evaluate your use of the website, to compile reports for us on website activity, and to provide us with further services relating to website and Internet use.</p>
        <p>The IP address transmitted by your browser in the context of Google Analytics is not merged with other Google data.</p>
        <p>This website uses Google Analytics with the extension "_anonymizeIp()". As a result, IP addresses are processed in abbreviated form, so that personal association can be ruled out. If the data collected about you is personal, it will be excluded and deleted immediately.</p>
        <p>The use of Google Analytics serves to analyse and regularly improve the use of our website. The statistics help us to improve our range of services and to make them more interesting for you as a user. For exceptional cases in which personal data is transferred to the United States, Google has submitted to the EU-US Privacy Shield,6para.1p. 1 lit. f) GDPR. Analytics cookies are deleted after fourteen months at the latest.</p>
        <p>You have the right to object. You may refuse the use of cookies by choosing appropriate settings on your browser, however, please note that if you do this you may not be able to use all features of this website. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). Furthermore, you may prevent Google from collecting data generated by the cookie and relating to your use of the website (including your IP address) and from processing this data by Google by downloading and installing the browser plug-in available at the following link: <a href='http://tools.google.com/dlpage/gaoptout?hl=de' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://tools.google.com/dlpage/gaoptout?hl=de</a>.
        </p>
        <p>You can find more information about Google at:</p>
        <p><a href='http://www.google.com/analytics/terms/de.html' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.com/analytics/terms/de.html</a>,
        </p>
        <p>
          <a href='http://www.google.com/intl/de/analytics/learn/privacy.html' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.com/intl/de/analytics/learn/privacy.html</a><em>,</em>
        </p>
        <p><a href='http://www.google.de/intl/de/policies/privacy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.de/intl/de/policies/privacy</a><em>.</em>
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Usage-Based Online-Advertisement</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <strong>Facebook CUSTOM AUDIENCES</strong>
        <p>Furthermore, the website uses the feature Website custom audiences via so-called Facebook pixels by Facebook Inc. (Provider is Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, e-mail: <a href='mailto:impressum-support@support.facebook.com' className='client-theme--color'>impressum-support@support.facebook.com</a>, Data Privacy under: <a href='https://www.facebook.com/privacy/explanation' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.facebook.com/privacy/explanation</a>).</p>
        <p>This allows users of the website to see interest-based advertisements (Facebook ads) when visiting Facebook or other social network websites that use the process. We are interested in showing you advertisements that might be of interest to you in order to make our website more interesting.
        </p>
        <p>Due to the marketing tools used, your browser automatically establishes a direct connection to the Facebook server. We have no influence on the scope and further processing of the data collected by Facebook through the use of this tool, and therefore inform you according to our state of knowledge: By integrating Facebook Custom Audiences, Facebook is made aware that you have called up the corresponding website of our Internet presence, or that you have clicked on an advertisement from us. If you are registered with a Facebook service, Facebook can associate your visit with your account. Even if you are not registered with Facebook or have not logged in, there is the possibility that the provider may obtain and store your IP address and other identifying information.</p>
        <p>The legal basis for the processing is Art.6 Abs.1 S.1 lit. f) GDPR.</p>
        <p>Logged-in users can deactivate Facebook Custom Audiences at
          <a href='https://www.facebook.com/settings/?tab=ads#_' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.facebook.com/settings/?tab=ads#_</a>.
        </p>
        <p>
          <br />You can prevent Facebook Custom Audiences in various ways and thus exercise your right to object:
        </p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>By setting your browser software accordingly, in particular the suppression of third-party cookies, you will not receive any ads from third-party providers;</li>
          <li>by deactivating interest-based ads by providers that are part of the About Ads self-regulation campaign via <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a>.</li>
        </ul>
        <p>For more information on the processing by Facebook, please visit <a href='https://www.facebook.com/about/privacy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.facebook.com/about/privacy</a>.
          <br />
          <br />
        </p>
        <strong>Facebook ANALYTICS</strong>
        <p>For the application of Facebook Analytics we use so-called tracking pixels by Facebook Inc. (provider is Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, E-Mail: <a href='mailto:impressum-support@support.facebook.com'>impressum-support@support.facebook.com</a>, privacy policy at:: <a href='https://www.facebook.com/privacy/explanation' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.facebook.com/privacy/explanation</a>; hereinafter: „Facebook“) to track your user behavior. The information obtained through the tracking pixel cookie serves solely for statistical purposes, is transmitted to us anonymously by Facebook and does not provide any information about the identity of the user. However, Facebook will connect you to your Facebook account, store and use your information for promotional purposes in accordance with Facebook's privacy policy and may also transfer your information to Facebook's partners. Even if you are not registered with Facebook or have not logged in, there is the possibility that the provider may obtain and store your IP address and other identifying information.
        </p>
        <p>The processing of your data ensues on the basis of Art. 6 para. 1 lit. f) GDPR.By using Facebook Analytics, we pursue the interest of being able to better evaluate our website and to improve our range of services.We do not store any personal data in this case. We have no knowledge of the storage duration at Facebook, and no possibility of influence.</p>
        <p>Logged-in users can deactivate Facebook Analytics at <a href='https://www.facebook.com/settings/?tab=ads#_' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.facebook.com/settings/?tab=ads#_</a>.</p>
        <p>You can prevent Facebook Analytics in various ways and thus exercise of your right to object:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>By setting your browser software accordingly, in particular the suppression of third-party cookies, you will not receive any ads from third-party providers</li>
          <li>by deactivating interest-based ads by providers that are part of the "About Ads" self-regulation campaign via <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a>.</li>
        </ul>
        <p>For more information about Facebook's privacy policy, please visit <a href='https://de-de.facebook.com/about/privacy/' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://de-de.facebook.com/about/privacy/</a>.
          <br />
          <br />
        </p>
        <strong>GOOGLE AdSense</strong>
        <p>The website uses Google's online advertising service Google AdSense, which presents you with advertising tailored to your interests. We are interested in showing you advertisements that might be of interest to you in order to make our website more interesting. For this purpose, statistical information about you is collected and processed by our advertising partners. These advertisements can be recognized by the reference "Google ads" in the respective advertisement.</p>
        <p>By visiting the website, Google is made aware that you have accessed our website. Google uses a web beacon to set a cookie on your computer. The data specified in the section "access data" is transmitted. We have no influence on the data collected, nor do we have knowledge of the full scope of data processing and the storage period. Your data will be transferred to and processed in the United States. If you are logged in with your Google account, your data can be associated to it directly. If you do not wish to be associated with your Google profile, you must log out. It is possible that this data will be passed on to Google's contractual partners, to third parties and authorities. The legal basis for the processing of your data is Art.6para.1sentence 1 lit. f) GDPR. We have no knowledge of the storage duration at Google, and no possibility of influence.</p>
        <p>This site does not serve any ads from third parties through Google AdSense.</p>
        <p>You have the right to object to the processing of your data. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR).</p>
        <p>You can prevent the installation of Google AdSense cookies through the following measures:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>By setting your browser software accordingly, in particular the suppression of third-party cookies, you will not receive any ads from third-party providers</li>
          <li>By disabling interest-based ads on Google via <a href='http://www.google.de/ads/preferences' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.de/ads/preferences</a></li>
          <li>by deactivating interest-based ads by providers that are part of the About Ads self-regulation campaign via <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a></li>
          <li>by permanent deactivation in your Firefox, Internet Explorer or Google Chrome browsers at <a href='http://www.google.com/settings/ads/plugin' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.com/settings/ads/plugin</a></li>
        </ul>
        <p>For more information on the purpose and scope of the processing, and to learn more about your rights and privacy choices, please visit: <a href='http://www.google.de/intl/de/policies/technologies/ads' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.de/intl/de/policies/technologies/ads</a>.
        </p>
        <p>Google has submitted to the EU-US Privacy Shield, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>.
          <br />
          <br />
        </p>
        <strong>GOOGLE AdWords CONVERSION</strong>
        <p>We use Google AdWords by Google to draw attention to our attractive offers with the help of advertising materials (so-called Google AdWords) on external websites. In relation to the data of the advertising campaigns, we can evaluate how successful individual advertising measures are. We are interested in showing you advertisements that might be of interest to you in order to make our website more interesting and to achieve a fair calculation of advertising costs.</p>
        <p>These ads are delivered by Google via so-called ad servers. We use ad server cookies, through which certain parameters for measuring success, such as the insertion of ads or clicks by users, can be measured. If you access our website via a Google ad, Google AdWords stores a cookie on your PC. These cookies usually expire after 30 days and are not intended to identify you personally. For this cookie, the unique cookie ID, number of ad impressions per placement (frequency), last impression (relevant for post-view conversions) and opt-out information (marking that the user no longer wishes to be addressed) are usually stored as analysis values.</p>
        <p>These cookies allow Google to recognize your web browser. If a user visits certain pages of an AdWords customer's website and the cookie stored on their computer has not expired, Google and the customer may recognize that the user has clicked on the ad and has been redirected to this page. Each AdWords customer is assigned a different cookie. Cookies can therefore not be traced via the websites of AdWords customers. We do not process any personal data in these advertising measures. We only receive statistical evaluations from Google. On the basis of these evaluations we can ascertain which of the used advertising measures are particularly effective. We do not receive any further data from the use of advertising material; in particular, we cannot identify users on the basis of this information.
        </p>
        <p>Due to the marketing tools used, your browser automatically establishes a direct connection to the Google server. We have no influence on the scope and the further processing of the data collected by Google through the use of this tool, and therefore inform you according to our state of knowledge: By integrating AdWords Conversion, Google is made aware that you have called up the corresponding part of our Internet presence or clicked on an advertisement from us. If you are registered with a Google service, Google may associate your visit with your account. Even if you are not registered with Google or have not logged in, it is possible that the provider may obtain and store your IP address.
        </p>
        <p>The legal basis for the processing is Art. 6 Abs. 1 S. 1 lit. f) GDPR.</p>
        <p>You have the right to object to the processing of your data. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR).<br /><br />You can prevent participation in this tracking procedure in various ways:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>By setting your browser software accordingly, in particular the suppression of third-party cookies, you will not receive any ads from third-party providers</li>
          <li>by disabling cookies for conversion tracking by setting your browser to block cookies from the domain „www.googleadservices.com“, via <a href='https://www.google.de/settings/ads' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.de/settings/ads</a></li>
          <li>by deactivating interest-based ads of providers that are part of the About Ads self-regulation campaign via <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a></li>
          <li>by permanent deactivation in your Firefox, Internet Explorer or Google Chrome browsers at <a href='http://www.google.com/settings/ads/plugin' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.com/settings/ads/plugin</a></li>
        </ul>
        <p>Further information on data protection at Google can be found here: <a href='http://www.google.com/intl/de/policies/privacy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.com/intl/de/policies/privacy</a> and <a href='https://services.google.com/sitestats/de.html' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://services.google.com/sitestats/de.html</a>.
        </p>
        <p>Google has submitted to the EU-US Privacy Shield, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>.
          <br />
          <br />
        </p>
        <strong>GOOGLE DoubleClick</strong>
        <p>We continue to use Google's online marketing tool DoubleClick on the website. DoubleClick uses cookies to serve ads relevant to users, to improve campaign performance reports, or to prevent a user from seeing the same ads more than once. Google uses a cookie ID - a pseudonymous identification number assigned to your browser - to track which ads are displayed in which browser and to prevent them from being displayed more than once. This pseudonym is assigned information about user activities on the website. This allows Google and its partner sites to serve ads based on previous visits to websites. In addition, DoubleClick may use the cookie ID to collect conversions related to ad requests. For example, if a user sees a DoubleClick ad and later visits the advertiser's website with the same browser and buys something there.</p>
        <p>The information generated by DoubleClick cookies is transmitted to and stored by Google on servers in the United States. Google complies with the data protection provisions of the EU-US Privacy Shield Agreement and is certified for the Privacy Shield, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>.</p>
        <p>Due to the marketing tools used, your browser automatically establishes a direct connection to the Google server. We have no influence on the scope and the further processing of the data collected by Google through the use of this tool, and therefore inform you according to our state of knowledge: By integrating DoubleClick, Google is made aware that you have called up the corresponding part of our Internet presence or clicked on an advertisement from us. If you are registered with a Google service, Google may associate your visit with your account. Even if you are not registered with Google or have not logged in, it is possible that the provider may obtain and store your IP address.</p>
        <p>The legal basis for the processing is Art. 6 para. 1 p. 1 lit. f) GDPR. DoubleClick cookies are deleted after six months at the latest. By using DoubleClick, we aim to show you advertisements that may be of interest to you in order to make our website more interesting.</p>
        <p>You have the right to object. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). You can object to participation in this tracking procedure in various ways:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>By setting your browser software accordingly, in particular the suppression of third-party cookies, you will not receive any ads from third-party providers</li>
          <li>by disabling cookies for conversion tracking by setting your browser to block cookies from the the domain "www.googleadservices.com", via <a href='https://www.google.de/settings/ads' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.de/settings/ads</a></li>
          <li>by deactivating interest-based ads by providers that are part of the "About Ads" self-regulation campaign via <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a></li>
          <li>by permanent deactivation in your Firefox, Internet Explorer or Google Chrome browsers at <a href='http://www.google.com/settings/ads/plugin' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.com/settings/ads/plugin</a>.
          </li>
        </ul>
        <p>For more information about DoubleClick, please visit: <a href='https://www.google.de/doubleclick' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.de/doubleclick</a>, <a href='http://support.google.com/' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://support.google.com</a>, <a href='https://policies.google.com/privacy?hl=de' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://policies.google.com/privacy?hl=de</a> (Google's privacy policy).
          <br />
          <br />
        </p>
        <strong>GOOGLE REMARKETING</strong>
        <p>Besides AdWords conversion we use the application Google Remarketing by Google. This is a process we use to contact you again. This application allows you to see our advertisements after visiting our website when you continue to use the Internet. This is done by means of cookies stored in your browser, which are used to record and evaluate your usage behavior when Google visits various websites. This is how Google is made aware of your previous visit to our website. According to own statements, Google does not merge the data collected in the context of remarketing with your personal data, which may be processed by Google. According to Google, pseudonymization is used for remarketing in particular.</p>
        <p>The legal basis for the processing is Art. 6 para. 1 p. 1 lit. f) GDPR. We have no knowledge of the storage duration at Google, and no possibility of influence.</p>
        <p>You have the right to object to the processing of your data. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling grounds for processing that outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). You can prevent participation in this tracking procedure in various ways:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>By setting your browser software accordingly, in particular the suppression of third-party cookies, you will not receive any ads from third-party providers</li>
          <li>by disabling cookies for conversion tracking by setting your browser to block cookies from the the domain "www.googleadservices.com", via <a href='https://www.google.de/settings/ads' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.de/settings/ads</a></li>
          <li>by deactivating interest-based ads by providers that are part of the About Ads self-regulation campaign via <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a></li>
          <li>by permanent deactivation in your Firefox, Internet Explorer or Google Chrome browsers at <a href='http://www.google.com/settings/ads/plugin' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.google.com/settings/ads/plugin</a>.
          </li>
        </ul>
        <br />
        <strong>LinkedIn Ads</strong>
        <p>The features and contents of the LinkedIn service offered by LinkedIn Corporation, 2029 Stierlin Court, Mountain View CA 94043 (hereinafter "LinkedIn") are incorporated into our online content.</p>
        <p>This enables users of the website to be shown interest-related advertisements (LinkedIn ads) when visiting the LinkedIn social network or other websites that also use the procedure. We are interested in showing you advertisements that might be of interest to you in order to make our website more interesting.
        </p>
        <p>Due to the marketing tools used, your browser automatically establishes a direct connection to the LinkedIn server. We have no influence on the scope and the further processing of the data collected by LinkedIn through the use of this tool and therefore inform you according to our state of knowledge: By integrating LinkedIn ads, LinkedIn is made aware that you have called up the corresponding website of our Internet presence or clicked on an advertisement from us. If you are registered with a LinkedIn service, LinkedIn can associate your visit to your account. Even if you are not registered with LinkedIn or have not logged in, the provider may obtain and store your IP address and other identifying information. LinkedIn has submitted to the EU-US Privacy Shield (<a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>) , thereby offering a guarantee to comply with European Data Protection Regulation.</p>
        <p>The legal basis for the processing of your data is Art.6para.1p. 1 lit. f) GDPR. We have no knowledge of the storage duration at LinkedIn, and no possibility of influence. Further information on data protection at LinkedIn can be found at <a href='https://www.linkedin.com/legal/privacy-policy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.linkedin.com/legal/privacy-policy.</a>
        </p>
        <p>You have the right to object to the processing of your data. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). You can object to the processing in various ways:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>by clicking an opt-out link at: <a href='https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>.
          </li>
          <li>by adjusting your browser software accordingly, in particular the suppression of third party cookies means that you will not receive any ads from third parties, or</li>
          <li>by deactivating interest-based ads by providers that are part of the About Ads self-regulation campaign via <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a></li>
        </ul>
        <br />
        <strong>Bing Ads</strong>
        <p>We use the conversion and tracking tool Bing Ads from Microsoft Corporation (One Microsoft Way, Redmond, WA 98052-6399, hereinafter "Microsoft") on our website.</p>
        <p>Microsoft stores a cookie on the user's computer to enable an analysis of the use of our online offer. The prerequisite for this is that the user has reached our website via an advertisement from Microsoft Bing Ads. This allows Microsoft and us to recognize if someone has clicked on an ad, has been redirected to our online offer, and has reached a predetermined target page. We only see the total number of users who clicked on a Bing ad and were then forwarded to the target page (conversions). No IP addresses are stored.</p>
        <p>Due to the marketing tools used, your browser automatically establishes a direct connection to the Microsoft server. We have no influence on the extent and the further processing of the data that are caused by the use of Bing Ads. Microsoft has submitted to the EU-US Privacy Shield (<a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>) , thereby offering a guarantee to comply with European Data Protection Regulation.</p>
        <p>The legal basis for the processing of your data is Art. 6 para. 1 p. 1 lit. f) GDPR. We have no knowledge of the storage duration at Microsoft, and no possibility of influence. For more information about Microsoft's privacy practices, please visit: <a href='https://privacy.microsoft.com/de-de/privacystatement' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://privacy.microsoft.com/de-de/privacystatement</a>.
        </p>
        <p>You have the right to object to the processing of your data. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). You can object to the processing in various ways:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>by adjusting your browser software accordingly, in particular the suppression of third party cookies means that you will not receive any ads from third parties, or</li>
          <li>by deactivating interest-based ads by providers that are part of the About Ads self-regulation campaign via <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a></li>
        </ul>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Social Network Plug-Ins</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Our website includes plugins from social networks. These are provided by the following providers:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Facebook (provider is Facebook Ireland limited, 4 Grand Canal Square, Dublin 2, Ireland,  E-Mail: <a href='mailto:impressum-support@support.facebook.com'>impressum-support@support.facebook.com</a>, privacy policy at <a href='https://www.facebook.com/privacy/explanation' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.facebook.com/privacy/explanation</a>; <a href='http://www.facebook.com/about/privacy/your-info-on-other#applications' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.facebook.com/about/privacy/your-info-on-other#applications</a> and <a href='http://www.facebook.com/about/privacy/your-info#everyoneinfo' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.facebook.com/about/privacy/your-info#everyoneinfo</a>. Facebook has submitted to the EU-US Privacy Shield, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>
          </li>
          <li>Google (see definitions, <a href='https://www.google.com/policies/privacy/partners/?hl=de' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.google.com/policies/privacy/partners/?hl=de</a>. Google has submitted to the EU-US Privacy Shield, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>)
          </li>
          <li>Instagram Inc., 1601 Willow Road, Menlo Park, California 94025, USA;</li>
          <li>LinkedIn Corporation , 2029 Stierlin Court, Mountain View CA 94043 (<a href='http://www.linkedin.com/legal/privacy-policy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.linkedin.com/legal/privacy-policy</a>. LinkedIn has submitted to the EU-US Privacy Shield, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>);
          </li>
          <li>Tumblr, Inc., 770 Broadway, New York, NY 10003, USA (<a href='https://www.tumblr.com/privacy/de' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.tumblr.com/privacy/de</a>);</li>
          <li>Twitter Inc.,795 Folsom St., Suite 600, San Francisco, CA 94107, USA (<a href='https://twitter.com/privacy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://twitter.com/privacy</a>. Twitter has submitted to the EU-US Privacy Shield, <a href='https://www.privacyshield.gov/EU-US-Framework' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.privacyshield.gov/EU-US-Framework</a>);
          </li>
          <li>XING AG, Dammtorstraße 29-32, 20354 Hamburg, Germany (<a href='https://www.xing.com/privacy' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://www.xing.com/privacy</a>);</li>
        </ul>
        <p>You may communicate directly with the provider of the plugin via the button. Only if you click on the marked field and activate it, the plug-in provider receives the information that you have accessed the corresponding website of our online offer. In addition, the data specified in the section "access data" is transmitted.</p>
        <p>In the case of Facebook and Xing, the IP address is anonymized immediately after collection, according to the respective provider in Germany. By activating the plugin, personal data is transferred to the respective plug-in provider and processed there (for US providers in the United States). Since the plugin provider collects data mainly via cookies, we recommend that you delete all cookies before clicking on the grayed-out box using your browser's security settings.</p>
        <p>We have no influence on the data collected and the processing procedures, nor are we aware of the full scope of data processing, the purposes of processing, and retention periods. We also have no information on the deletion of the data collected by the plugin provider.</p>
        <p>The plugin provider stores the data collected about you as user profiles and processes these for purposes of advertising, market research and/or demand-oriented design of its website. Such an evaluation takes place in particular (also for users that are not logged in) for the representation of demand-fair advertisement and to inform other users of the social network about your activities on our website.</p>
        <p>The legal basis for the processing is Art. 6 para. 1 p. 1 lit. f) GDPR. Via the plugins we offer you the possibility to interact with social networks and other users, allowing us to improve our range of services and make it more interesting for you as a user.</p>
        <p>You have the right to object to the creation of these user profiles, however, you are required to contact the respective plugin provider to exercise this right. Your right to object exists for reasons arising from your particular situation, unless we can prove compelling reasons worthy of protection for the processing which outweigh your interests, rights and freedoms, moreover, if the processing serves to assert, exercise or defend against legal claims (Art. 21 para. 1 GDPR). You can also prevent the creation of user profiles in various ways and thus make use of your right to object:
        </p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>By setting your browser software accordingly, in particular the suppression of third-party cookies, you will not receive any ads from third-party providers</li>
          <li>by deactivating interest-based ads by providers that are part of the About Ads self-regulation campaign via <a href='http://www.aboutads.info/choices' className='client-theme--color' target='_blank' rel='noopener noreferrer'>http://www.aboutads.info/choices</a></li>
        </ul>
        <p>The data is transferred regardless of whether you have an account with the plugin provider and are logged in. If you are logged in with the plugin provider, your data collected with us will be directly assigned to your existing account with the plugin provider. For example, if you click the activated button and link the page, the plug-in provider also stores this information in your user account and shares it publicly with your contacts.</p>
        <p>We recommend that you log out regularly after using a social network, especially before activating the button, as this avoids being assigned to your profile with the plug-in provider.</p>
        <p><a href='https://www.spiritlegal.com/de/' className='client-theme--color' target='_blank' rel='noopener noreferrer'>Copyright&nbsp;© by Spirit Legal LLP</a></p>
        <hr style={{ margin: '25px 0px 25px 0px' }} />
        <h2>PRIVACY POLICY SOFTWARE</h2>
        <p>
          <br />The use of our software is possible by providing personal data. The personal user data necessary for sending or creating a login is provided either by your employer or you personally. Deviating regulations may arise for the use of individual services of our site, which in this case are explained separately below. Your personal data (e.g. name, address, e-mail, telephone number, etc.) will only be processed by us in accordance with the provisions of the DSGVO. Data is considered personal if it can be clearly assigned to a specific natural person. The legal basis of data protection can be found in the Basic Data Protection Ordinance (DSGVO) and the Telemedia Act (TMG). The following regulations inform you about the type, scope and purpose of the collection, the use and processing of personal data by the provider. In addition, we inform you on how to contact us if you have questions with regards to your personal data.
        </p>
        <p>MICE Portal offers event-related online services. All of the following information applies to all of our platforms. This Privacy Policy applies to any kind of personal information we collect from you through our platforms (e.g. correspondence with our customer service via email).</p>
        <p>We recommend that you read our Privacy Policy regularly to ensure you are always up to date on our current Privacy Policy. If any changes are made to the Privacy Policy that affect you, you will be notified before they take effect.</p>
        <p>The use of MICE Portal platforms is only possible with the consent of our Privacy Policy.</p>
        <p><br /><strong>MICE Portal GmbH</strong>
          <br />Am Bachfeld 1
          <br />85395 Attenkirchen
          <br />Germany
          <br />
          <br />Phone. +49 (0)8168 9974-1000
          <br />Fax. +49 (0)8168 9974-1199
          <br />info(at)miceportal.com
        </p>
        <br /> represented by:
        <p>Josephine Gräfin von Brühl
          <br />MICE Portal GmbH
          <br />Am Bachfeld 1, 85395 Attenkirchen
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Questions on Data Protection</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>If you have any questions on data protection with regard to our company or our website, please contact our data protection officer:
          <br />
          <br />
        </p>
        <p><strong>{DataProtectionOfficerInfo.company}</strong>
          <br />{DataProtectionOfficerInfo.contact_person}
          <br />E-Mail: privacy(at)miceportal.com
          <br />Adress: {DataProtectionOfficerInfo.street} {DataProtectionOfficerInfo.house_nr}, {DataProtectionOfficerInfo.post_code} {DataProtectionOfficerInfo.city}
          <br />HRB-Nr.: 91147 | Amtsgericht Köln | Jürgen Funke, Daniel Wasser
          <br />{DataProtectionOfficerInfo.street} {DataProtectionOfficerInfo.house_nr} | {DataProtectionOfficerInfo.post_code} {DataProtectionOfficerInfo.city} | +49 221 56947927 | {DataProtectionOfficerInfo.company}
        </p>
        <p><br />We would like to point out that internet-based data transmission has security gaps, making complete protection against access by third parties impossible.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>WHAT KIND OF PERSONAL INFORMATION IS COLLECTED BY MICE PORTAL?</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>In order to warrant the purchase of event services, we require certain information. This is basic personal information such as your name, contact details (e.g. phone number, e-mail address). In addition, we collect information from your computer. This can be your IP address, the browser used, and, if necessary, your language setting.
          <br />
          <br />
        </p>
        <strong>Personal data we receive from you.</strong>
        <p>MICE Portal collects and processes the information you provide. When purchasing/booking event services, you must at least enter your name and e-mail address. We may also need your phone number.</p>
        <p>If there is communication between you and our customer service by phone or e-mail, this information is also collected. At the end of the event you may receive a request from MICE Portal to rate the service provider on a scale of 0 to 5, 5 representing the best rating. This evaluation is anonymized and serves purely as information for future users of the platform. The input of personal data is optional.</p>
        <p>Your personal data may be used to contact you for certain information purposes. For example, to send you an invitation to webinars or training sessions. This is only done after permission has been obtained from an officer responsible for your company.</p>
        <p>If you participate in referral programs or sweepstakes, you provide us with your personal information, which will only be used for the duration of the referral program or sweepstake.</p>
        <p>If you provide us with information regarding a system error, your personal data will be used to contact you to resolve the error.</p>
        <p>Your personal data will also be used for reporting. Depending on your company's general contract, your personal data may be stored for process-related purposes and exported by an authorized person for information purposes and used for further processing.
          <br />
          <br />
        </p>
        <strong>Personal information we receive from you about others.</strong>
        <p>Events are usually booked for several people. If you decide to use the communication channels provided by our platform to transfer personal data from third parties, this information will also be collected and forwarded to third party service providers, however, this data will not be processed in any other way.
        </p>
        <p>We must point out that it is your responsibility to make persons about whom you provide personal information aware of this process and to obtain their consent on how MICE Portal uses their information.
          <br />
        </p>
        <strong><br />Personal information we automatically collect.</strong>
        <p>When using our platform, we may automatically process the following data from you:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>IP address</li>
          <li>Date and time of access</li>
          <li>Hardware &amp; software</li>
          <li>Browser type</li>
          <li>Your computer's operating system</li>
          <li>Application version</li>
          <li>Language settings</li>
          <li>Information about clicks and pages that are displayed to you</li>
        </ul>
        <strong><br />Personal information we receive from other sources.</strong>
        <p>We may receive information from other sources, including our business or cooperation partners or other independent third parties. All information thus obtained may be combined with our information. For example, you use one of our partners to book a location. You will provide our business partners with your booking information, which they will then forward to us. There are also integrations to third party providers who handle the payment processing between the booker and the service provider. These service providers share the payment information with us so that we can process and manage your booking.</p>
        <p>You may possibly share information with our service providers via our various communication features (e.g. pin board). This communication between service provider and user is stored on our platform. For example, in renegotiations, pending questions about a booking, or disputes regarding a booking.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Why does MICE Portal collect and process your personal data?</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Your collected information may be used in the following ways:</p>
        <p>1. Booking/purchasing services: Your personal data is mainly used to manage and complete your online booking. This is necessary so that we can fulfill our central corporate purpose.</p>
        <p>2. Customer service: MICE portal offers customer service on weekdays from 8 am to 6 pm CET. Share details with our service staff, give us the opportunity to answer, if necessary. This includes answering technical questions regarding our platform or, for a fee, looking up suitable service providers, according to your requirements, and answering any questions you may have regarding your process - or any other questions.</p>
        <p>3. Account setup: MICE Portal customers have a user account. This information is used to manage this account. You can manage your operations, create and book new operations. You have access to all your past, current, or future operations. Depending on your access authorization, various features are available within our platform (e.g. reporting or overview of operations of colleagues). You can manage your personal settings. Managing your personal settings includes:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Changing your password</li>
          <li>Language settings</li>
          <li>E-mail notification about operation-related information</li>
          <li>Selection of a holiday replacement</li>
        </ul>
        <p>4. Marketing: Your data will only be used for marketing purposes after obtaining permission from your company:
        </p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>In the case of participation in advertising activities (e.g. competitions), the information necessary for the implementation will be used.
          </li>
        </ul>
        <p>5. Other communication: Depending on the contact information provided, we may contact you by phone, mail or e-mail. We process and document the communication between you and us. This could be for the following reasons:</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>To answer and process all inquiries regarding our platform, processes, bookings you have made. MICE portal offers its users various possibilities to exchange information, inquiries and comments, as well as data for internal communication or communication with third parties.</li><li>Depending on your user settings, information about your project overview, new messages or activities, reservations, cancellations will be sent to you.</li><li>You may be sent questionnaires or invitations to rate your experience with MICE Portal or third parties</li>
          <li>You may receive messages of an administrative nature, including safety information</li>
        </ul>
        <p>6. Market research: If your company permits, we may occasionally ask you to take part in surveys. If you are invited to a survey, please read the information on how your personal data will be processed.</p>
        <p>7. Improvement of the user experience of our services: We use personal data for analytical purposes. The data will be used to improve our platform, optimize the user experience and for testing purposes, troubleshooting and improving the functionality and quality of our online MICE booking service.</p>
        <p>8. Service provider evaluation: You may be invited to evaluate the service provider at the end of an event.</p>
        <p>9. Legal purposes: In certain cases, we may use your information to process and resolve legal disputes or to investigate and comply with compliance guidelines. We may also use the information to enforce the terms of use of MICE Portal booking services at our reasonable discretion, or to comply with requests from law enforcement agencies.</p>
        <p>
          <br />To process your data, we rely on the following legal bases:
        </p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Contract fulfillment: The use of your data may be necessary to implement the contract you enter into with service providers via MICE Portal. When using our platform for online booking of a service provider, we will use your data to fulfill our obligation to conclude and carry out the booking in accordance with our mutual agreement.</li><li>Legitimate interests: Your data may be used for our legitimate business interests. This serves to improve our products and services, as well as for administrative or legal purposes and to detect fraudulent activities.</li><li>For purposes 1 and 2, MICE Portal refers to the legal basis that the processing of your personal data is necessary to fulfill the contract between you and the service provider, in particular to complete your event booking. Without providing your data, MICE Portal is unable to carry out your booking and cannot offer customer service. For purposes 3 to 8, MICE Portal refers to its legitimate business interest in providing services, avoiding fraud and improving the services. When using personal data for the legitimate interests of MICE Portal or the legitimate interests of third parties, MICE Portal will always weigh your interests and your rights to protect your data against the rights and interests of MICE Portal and the third party. For purpose 9, MICE Portal refers to compliance with legal obligations, where applicable. If required by applicable law, MICE Portal will obtain your consent before processing your personal data for direct marketing purposes.</li>
        </ul>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>How does MICE Portal share your data with third parties?</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Under certain circumstances, MICE Portal shares your personal information with third parties.</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>MICE Portal: Your data is usually forwarded to the service provider during the process of purchasing services. This point is regulated according to the provisions of the general contract with your company. In the purchasing process of MICE Portal, your personal data is usually transmitted to the service provider for contract drafting in the case of online inquiries, bookings, cancellations and communication with the service provider. Depending on the type of service purchased and the service provider, this may include information such as your name, contact details, special requests or other information in connection with the transaction/booking, as well as information from other persons involved (e.g. participants in the event). If necessary, we will contact the service provider with questions regarding your request and ask him to contact you. In the event of booking-related disputes, we may provide the service provider with data on the dispute resolution process as required. This may include a copy of your booking confirmation as proof of the actual completion of the booking.</li>
          <li>Third party service providers: MICE Portal uses third party service providers to process your personal data on our behalf. This can serve various purposes, such as enabling the purchase of services on external platforms. All third party service providers are subject to confidentiality agreements and order processing agreements in accordance with the DSGVO.</li><li>Appropriate authorities: We disclose information for law enforcement if required by law or if necessary to prevent, detect or prosecute criminal activity or fraud, or if we are by law required to do so for other reasons. In addition, we may share personal information with appropriate authorities if we believe that this is necessary to detect or prevent fraudulent activities.</li>
        </ul>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>How does MICE Portal process the communication that you and your booked service provider may exchange via MICE Portal?</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>MICE Portal offers its users various possibilities to communicate about event services and existing bookings via MICE Portal. For example, you can communicate with the selected service provider on the operation overview page by choosing "Contact". This communication is only transmitted via MICE Portal and stored in an audit-proof manner. It is, however, not used for any other purposes.</p>
        <p>
          <br />What kind of security and retention measures does MICE Portal take to protect your personal information?
        </p>
        <p>We use appropriate procedures in accordance with the GDPR to ensure the protection of your personal data against misuse and unauthorized access.</p>
        <p>To protect your personal data, we use appropriate business systems and processes. We also apply security procedures and technical limitations and access restrictions when our servers access and use your personal data. Only authorized employees have access to your personal data, for the proper performance of their duties.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>How can you control your personal data in MICE Portal?</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>You are entitled to view your personal data stored with us at any time. To obtain an overview of your personal data, please send us an e-mail (<a href='mailto:privacy@miceportal.com' className='client-theme--color' target='_blank' rel='noopener noreferrer'>privacy@miceportal.com</a>). Bitte geben Sie in der Betreffzeile der E-Mail „Abfrage der persönlichen Daten“ an, um die Bearbeitung Ihrer Anfrage zu beschleunigen.</p>
        <p>If you suspect that your personal data is incorrect or that we are no longer authorized to use your personal data, or if you have any other questions about the processing of your personal information or concerns regarding the Privacy Policy, you can contact us at any time. We would like to point out that you are entitled to the correction of incorrect data or the deletion of personal data if there is no legal obligation to retain such data.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Who is responsible for the processing of personal data via MICE Portal and how can they be contacted?</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>MICE Portal GmbH is responsible for the processing of personal data as described in this Privacy Policy. MICE Portal is a limited liability company and subject to German law with registered office at Am Bachfeld 1, 85395 Attenkirchen, Germany.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Cookies and the USE OF GOOGLE ANALYTICS WITH ANONYMISATION FEATURE</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>We use Google Analytics, a web analysis service of Google Inc. 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, hereinafter referred to as "Google". Google Analytics uses "cookies", which are text files placed on your computer, to help the website analyze how users use the site. The information generated by these cookies, such as the time, place and frequency of your visit to the website, including your IP address, is transmitted to Google in the USA and stored there. We use Google Analytics on our website with the suffix "_gat._anonymizeIp". In this case, Google will already shorten your IP address within member states of the European Union or in other signatory states subject to the Agreement on the European Economic Area and thereby render it anonymous. Google will use this information to evaluate your use of our site, to compile reports on website activity for us and to provide other services relating to website and Internet use. Google may also transfer this information to third parties if this is required by law or if third parties process this data on behalf of Google. Google will, according to its own statements, under no circumstances associate your IP address with other Google data. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to make full use of all features of our website.</p>
        <p>Google also offers a deactivation add-on for the most popular browsers, giving you more control over what information Google collects about the websites you visit. The add-on informs Google Analytics' JavaScript (ga.js) that no information about the website visit should be transmitted to Google Analytics. However, the deactivation add-on for browsers from Google Analytics does not prevent information from being transmitted to us or to other web analytics services we may use. For more information on installing the browser add-on, click on the link below: <a href='https://tools.google.com/dlpage/gaoptout?hl=de' className='client-theme--color' target='_blank' rel='noopener noreferrer'>https://tools.google.com/dlpage/gaoptout?hl=de</a></p>
        <hr style={{ margin: '25px 0px 25px 0px' }} />
        <h2>PRIVACY POLICY Business Partners and Customers</h2>
        <p>
          <br />This Privacy Policy applies to MICE Portal GmbH, Am Bachfeld 1, 85395 Attenkirchen, Germany (hereinafter referred to as "MICE Portal", "we" or "our") and sets out how MICE Portal collects and processes personal data from (potential, existing or former) service providers and other business partners. This Privacy Policy therefore applies to personal data of owners, employees, agents or other persons acting on behalf of a service provider or other business partners. Providers of booking platforms or software, other business partners and related persons are hereinafter collectively or individually referred to as "business partner" or "you". For the purposes of this Privacy Policy, a business partner may be a service provider (including providers of accommodation, attractions, parks, museums, transfers, catering, supporting programs, locations, etc.), business partners who enable the booking of events through their websites and/or apps or otherwise, including travel agencies, third party providers and other business partners who have a business relationship with MICE Portal.
        </p>
        <p>For more information, please refer to the separate Privacy Policy for Users that (i) describes how MICE Portal processes personal data of customers who use MICE Portal services to book an event, and (ii) how MICE Portal uses cookies on the customer's websites. Companies that use the services of MICE Portal for events, to enable event reservations for their employees, are subject to this privacy and cookie policy for customers.</p>
        <p>The use of our software is possible by providing personal data. The personal user data necessary for sending or creating a login is provided either by your employer or you personally. Deviating regulations may arise for the use of individual services of our site, which in this case are explained separately below. Your personal data (e.g. name, address, e-mail, telephone number, etc.) will be processed by us in accordance with the provisions of the DSGVO. Data is considered personal if it can be clearly assigned to a specific natural person. The legal basis of data protection can be found in the Basic Data Protection Ordinance (DSGVO) and the Telemedia Act (TMG). The following regulations inform you about the extent of type, scope and purpose of the collection, use and processing of personal data by the provider. In addition, we inform you on how to contact us if you have questions with regards to your personal data.</p>
        <p>MICE Portal offers event-related online services. All of the following information applies to all of our platforms. This Privacy Policy applies to any kind of personal information we collect from you through our platforms (e.g. correspondence with our customer service via email).</p>
        <p>We recommend that you read our Privacy Policy regularly to ensure you are always up to date on our current Privacy Policy. If any changes are made to the Privacy Policy that affect you, you will be notified before they take effect.</p>
        <p>The use of MICE Portal platforms is only possible with the consent of our Privacy Policy.</p>
        <p><br /><strong>MICE Portal GmbH</strong>
          <br />Am Bachfeld 1
          <br />85395 Attenkirchen
          <br />Germany
          <br />
          <br />Phone. +49 (0)8168 9974-1000
          <br />Fax. +49 (0)8168 9974-1199
          <br />info(at)miceportal.com
        </p>
        <br /> represented by:
        <p>Josephine Gräfin von Brühl
          <br />MICE Portal GmbH
          <br />Am Bachfeld 1, 85395 Attenkirchen
        </p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Questions on Data Protection</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>If you have any questions on data protection with regard to our company or our website, please contact our data protection officer:
          <br />
          <br />
        </p>
        <p><strong>{DataProtectionOfficerInfo.company}</strong>
          <br />{DataProtectionOfficerInfo.contact_person}
          <br />E-Mail: privacy(at)miceportal.com
          <br />Adress: {DataProtectionOfficerInfo.street} {DataProtectionOfficerInfo.house_nr}, {DataProtectionOfficerInfo.post_code} {DataProtectionOfficerInfo.city}
          <br />HRB-Nr.: 91147 | Amtsgericht Köln | Jürgen Funke, Daniel Wasser
          <br />{DataProtectionOfficerInfo.street} {DataProtectionOfficerInfo.house_nr} | {DataProtectionOfficerInfo.post_code} {DataProtectionOfficerInfo.city} | +49 221 56947927 | {DataProtectionOfficerInfo.company}
        </p>
        <p>We would like to point out that internet-based data transmission is exposed to security gaps, making complete protection against access by third parties impossible.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>Data collection</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>The kind of personal information that MICE Portal collects about business partners depends on the context of the business relationship and interaction with MICE Portal, the business partner's decisions, and the products, services and features used. The personal data that MICE Portal collects about a business partner may include the following; depending on specific situations, not all of the following data elements may be considered personal data as they may relate to a company and not to a person:
          <br />
          <br />
        </p>
        <strong>Personal information we receive from you</strong>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Contact details</li>
        </ul>
        <p>We record the contact data of a business partner, e.g. first and last name, business e-mail address, as well as your telephone and fax number.</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Financial data</li>
        </ul>
        <p>We collect information necessary for payment, including billing, e.g. your bank details, account and VAT number.</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Other</li>
        </ul>
        <p>During your communication with MICE Portal, MICE Portal collects and processes information thereof. If you contact customer service (e.g. by telephone or e-mail), the content of the communication may be stored on a personal basis.</p>
        <p>
          <br /><strong>Other information we automatically collect</strong>
        </p>
        <p>When you use the services of MICE Portal, for example the service provider login, MICE Portal automatically collects personal data. This includes data such as IP address (stored for 7 days for debugging purposes), location, access times, duration of use, URL queried, browser information, result of the visit (visit, booking, rejection of a query, etc.).</p>
        <p>By sharing personal data of other persons with respect to their company (e.g. employees), you confirm that these persons have been informed about the use of their personal data by MICE Portal in accordance with this Privacy Policy, and have given the necessary consent.</p>
        <strong><br />Other information we receive from other sources</strong>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Information about insolvency</li>
        </ul>
        <p>In the event of insolvency, MICE portal may receive information from insolvency administrators, courts or other public authorities.</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Data in connection with inquiries from law enforcement and tax authorities</li>
        </ul>
        <p>Law enforcement or tax authorities may contact MICE portal with further information about business partners, if they are the subject of an investigation.</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Fraud detection</li>
        </ul>
        <p>In certain cases, and in accordance with applicable law, MICE Portal collects data from third party sources for the purpose of investigation and fraud prevention.</p>
        <p>
          <br /><strong>Purpose of processing</strong>
        </p>
        <p>MICE Portal uses the previously described information collected about business partners, which may also include personal data, for the following purposes.</p>
        <p>1. Login allocation and account management</p>
        <p>MICE Portal uses contact information to register with MICE Portal, including verification, or for the maintenance of the business relationship with the business partner.</p>
        <p>2. Customer service</p>
        <p>Customer service uses the information provided, including personal information, to process and resolve inquiries, questions and concerns by the business partner.</p>
        <p>3. Other activities, including marketing</p>
        <p>MICE Portal invites business partners to participate in events organized by MICE Portal, which MICE Portal believes to be relevant for business partners. MICE Portal uses personal data, such as contact information, to inform you about system and product updates, to send you the MICE Portal newsletter, to invite business partners to participate in marketing campaigns or promotions. If personal data is used for direct advertising messages for marketing purposes, MICE Portal includes a link to unsubscribe.</p>
        <p>4. News tools</p>
        <p>MICE Portal provides business partners with different types of communication with the users/bookers of MICE Portal. Please note that all communication sent or received by MICE Portal via its communication channels is received and stored by MICE Portal.</p>
        <p>5. Analysis, improvement and research</p>
        <p>MICE Portal uses personal data for research and analysis purposes, and to improve MICE Portal services as well as the user experience, and for testing purposes, to improve the functionality and quality of the online services provided by MICE Portal. MICE Portal regularly invites business partners to participate in surveys or conducts other types of market research. Certain business partners are invited to use a special online platform on which you are invited to interact with MICE Portal and/or to exchange experiences with other business partners. Please read the information when you are invited to participate in a survey, market research or use an online platform, to learn how your personal data may be treated differently from this Privacy Policy.</p>
        <p>6. Security, fraud detection and prevention</p>
        <p>We use information, including personal data, to investigate, prevent or detect fraud and other illegal or infringing activities. These may include personal data of a business partner provided to MICE Portal for verification during registration, automatically collected personal data, or personal data of third parties (including event participants). MICE Portal may, if necessary, use personal data to support law enforcement. For this purpose, personal data may be shared with law enforcement agencies and external consultants. MICE Portal may use personal information for risk assessment and security purposes, including user authentication.</p>
        <p>7. Legal regulations and compliance</p>
        <p>In certain cases, MICE Portal must use the information provided, including personal data, to process and resolve legal disputes or complaints, for regulatory investigations and compliance purposes, or to enforce contractual claims against business partners.</p>
        <p>Please note that the service provider information, including the name and address of the service provider, is used in accordance with the General Terms and Conditions, which are part of the separately signed partner contract (e.g. to display on third-party websites in order to enable travel bookings).</p>
        <p>If we use automated means to process personal data that have legal consequences for you or that generally affect you seriously, we will apply appropriate security measures to protect your rights and freedoms, including the right to human intervention.</p>
        <p>
          <br /><strong>Legal basis:</strong> For purposes 1 and 2, MICE Portal refers to the legal basis that the processing of your personal data is necessary to fulfill the contract between business partners. If the required data is not provided, MICE Portal cannot register a service provider, cooperate with a business partner or provide customer service. For purposes 3 to 7, MICE Portal refers to its legitimate business interest in providing its services or using services from its business partners, avoiding fraud and improving the services. When we use personal data for the legitimate interests of MICE Portal or the legitimate interests of third parties, MICE Portal always weighs the interests and rights of business partners with the rights and interests of MICE Portal and the third party with respect to the protection of their data.
        </p>
        <p>For purposes 6 and 7, MICE Portal refers to compliance with legal obligations (such as lawful prosecution requests), where applicable.</p>
        <p>If you wish to object to processing in accordance with paragraph 1 to 5 and no logout mechanism is available to you (e.g. in your account settings), please contact <a href='mailto:privacy@miceportal.com' className='client-theme--color'>privacy@miceportal.com</a>.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>DATA TRANSFER</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p><strong>Data transfer to third parties:</strong> We pass on information from business partners, including personal data, to third parties insofar as this is legally permissible and described below. We do not sell or rent your personal data.</p>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Service providers (including vendors): We may share your information with third parties to provide our products and services, to store information and to do business on our behalf. These service providers process personal data only in accordance with the instructions from and to provide services for MICE Portal</li><li>Payment providers and other financial institutions: To process payments between a business partner and MICE Portal or a booker and a business partner, personal data is passed on to payment providers and other financial institutions.</li><li>Forced Disclosure: If required by law, absolutely necessary for the provision of our services, in legal proceedings, or to protect our rights or the rights of users of MICE Portal, we disclose personal data to law enforcement and investigative authorities.</li><li>Services of other business partners can also be booked via MICE Portal. The necessary personal data will be forwarded to these business partners. These business partners process personal data only in accordance with instructions from and to provide services to MICE Portal.</li>
        </ul>
        <p>Weitergabe und Offenlegung aggregierter Daten: Wir teilen Daten in aggregierter Form und / oder in einer Form, die es dem Empfänger solcher Informationen nicht ermöglicht, Sie zu identifizieren, ggf. mit Dritten z.B. für Branchenanalysen und die demografische Profilierung.
          <br />
          <br />
        </p>
        <strong>International data transfer</strong>
        <p>The transfer of personal data, as described in this Privacy Policy, may include the transfer of personal data from abroad to countries whose data protection laws are not as comprehensive as those of countries within the European Union. To the extent required by European law, we only transfer personal data to recipients who offer an appropriate level of data protection. In such cases, we may enter into contractual agreements to ensure that your personal data is protected according to European standards. You can request a copy of these contractual agreements using the contact details below.
          <br />
          <br />
        </p>
        <strong>Security</strong>
        <p>In accordance with European data protection laws, MICE Portal acts in accordance with appropriate security guidelines to protect your personal data from misuse and unauthorized access.</p>
        <p>To protect your personal data, we use appropriate business systems and processes. We also apply security procedures and technical limitations and access restrictions when our servers access and use your personal data. Only authorized employees have access to your personal data, for the proper performance of their duties.
          <br />
          <br />
        </p>
        <strong>Data storage</strong>
        <p>We will retain personal data for as long as we deem necessary to maintain the business relationship with a business partner, provide the services of MICE Portal to the business partner, comply with our legal obligations (including document retention laws), resolve disputes with other parties, and otherwise as necessary to enable MICE Portal to maintain its business operations. All personal data we store is subject to this Privacy Policy and our internal storage guidelines. If you have questions about a specific retention period for specific types of personal information we process about you, please contact us using the contact information below.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>YOUR OPTIONS AND RIGHTS</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>We want you to have control over how your personal data is used by us. You have the following options:</p>
        <ul style={{ paddingLeft: '20px' }}><li>You may ask us to provide you with a copy of the personal data we have stored about you;</li><li>You may notify us of any changes to your personal information or you may ask us to correct any personal information we hold about you;</li><li>In certain cases, you may ask us to delete, block or restrict the personal information we hold about you or you may object to certain purposes for which we use your personal information; and</li><li>In certain situations, you may also ask us to transfer the personal data you have provided to us to a third party.</li></ul>
        <p>If we use your personal data on the basis of your consent, you may revoke this consent at any time in compliance with applicable law. In addition, you may object to the processing of your personal data in accordance with the applicable laws at any time if such processing is based on a legitimate interest or on the basis of the public interest. We trust that you will ensure that your personal data is complete, correct and up-to-date. Please inform us immediately about changes or inaccuracies of your personal data by contacting us.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>QUESTIONS OR COMPLAINTS</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>If you have any questions or concerns regarding the processing of your personal data or if you wish to exercise any of the rights under this Privacy Policy, please contact our data protection officer at <a href='mailto:privacy@miceportal.com' className='client-theme--color'>privacy@miceportal.com</a>. All queries and complaints reported to MICE Portal will be investigated. MICE Portal will try to resolve complaints and questions in accordance with internal guidelines. You can also contact your local data protection authority with questions and complaints.</p>
        <div className='c-content-title-1'>
          <h3 className='c-font-uppercase'>CHANGES TO THE PRIVACY POLICY</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' />
        </div>
        <p>Just as our business processes are constantly changing, this Privacy Policy may also change from time to time. If we make changes that have a fundamental impact on you (e.g. if we begin to use personal data for purposes other than those mentioned above), we will contact you prior to such processing.</p>
        <br /><br /><br /><br />
      </div>
    )
  }

  render () {
    return (
      <Row>
        <Col>
          {
            this.props.i18n.language === 'de'
              ? this.renderDe()
              : this.renderEn()
          }
        </Col>
      </Row>
    )
  }
}

export default withTranslation()(DataPrivacy)
