import React from 'react'
import './FormElements.scss'
import { UUID } from '../../shared/utils'
import PropTypes from 'prop-types'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css'

function FormElement (props) {
  return (
    <div className={`w-100 ${props.containerClassName || ''}`}>
      {props.label ? (
        <label htmlFor={props.htmlFor}>
          {props.label}
          {props.tooltip && (
            <Tooltip
              placement='top'
              trigger={['hover', 'focus']}
              overlay={props.tooltip}
              overlayClassName='custom-tooltip'
            >
              <span className={`tooltipIcon ${props.name}`} style={{ marginLeft: '4px' }}>&#9432;</span>
            </Tooltip>
          )}
        </label>
      ) : null}
      {props.children}
      {props.hint ? (
        <small className='form-text text-muted'>
          {props.hint}
        </small>
      ) : null}
      {props.error ? (
        <div className='invalid-feedback' dangerouslySetInnerHTML={{ __html: props.error }} />
      ) : null}
    </div>
  )
}

function TextInput (props) {
  const inputId = props.id ? props.id : `UUID-${UUID()}`

  return (
    <FormElement htmlFor={inputId} {...props}>
      <input
        id={inputId}
        type={props.isPassword ? 'password' : 'text'}
        name={props.name ? props.name : inputId}
        className={`form-control ${props.className} ${props.error ? 'is-invalid' : ''}`}
        style={props.style || {}}
        value={props.value || ''}
        placeholder={props.placeholder}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </FormElement>
  )
}

function Textarea (props) {
  const inputId = props.id ? props.id : `UUID-${UUID()}`

  return (
    <FormElement htmlFor={inputId} {...props}>
      <textarea
        id={inputId}
        name={props.name ? props.name : inputId}
        className={`form-control ${props.className} ${props.error ? 'is-invalid' : ''}`}
        style={props.style || {}}
        value={props.value || ''}
        rows={props.rows || ''}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </FormElement>
  )
}

TextInput.propTypes = {
  error: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

export { FormElement, TextInput, Textarea }
