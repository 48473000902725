import React from 'react'
import { AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import { Row, Col, Card, CardBody } from 'reactstrap'
import TextInputGroup from '../../../TextInputGroup/TextInputGroup'
import { TextInput } from '../../../FormElements/FormElements'
import Checkbox from '../../../Checkbox/Checkbox'
import Can from '../../../Layout/Can'
import HashtagsSelect from '../../../HashtagsSelect/HashtagsSelect'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import UploadImage from '../../../UploadImage/UploadImage'
import ButtonWithIcon from '../../../ButtonWithIcon/ButtonWithIcon'
import { getSelectedOption, getSelectedOptions, sortArrayByKey, mapKeysToOptions } from '../../../../shared/utils'

class HotelGeneral extends React.Component {
  handleCheckInTimeChange (date) {
    this.setState({
      startTime: date
    })
  }

  handleCheckOutTimeChange (date) {
    this.setState({
      endTime: date
    })
  }

  render () {
    const { t, i18n } = this.props
    const deliverable = this.props.supplier && this.props.supplier.supplier_hotel

    if (deliverable) {
      const categoryOptions = sortArrayByKey(deliverable.categories, `name_${i18n.language}`)
      const categoryValues = mapKeysToOptions(deliverable.category_keys, categoryOptions)

      return (
        <div>
          <Row className='m-0 mt-3 mb-3'>
            <Col className='p-0' xs={12}>
              <Card>
                <CardBody>
                  <AccordionItem>
                    <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                      <AccordionItemButton>
                        <h5 className='u-position-relative'>
                          {t('supplier.edit.general.facts_and_figures')}
                          <div className='accordion__arrow' role='presentation' />
                        </h5>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row className='mt-3 mb-3'>
                        <Col md={4} sm={6} xs={12}>
                          <TextInput
                            name='check_in_time'
                            className='borderPrimary'
                            label={`${t('supplier.edit.general.checkin_time')} *`}
                            placeholder=' '
                            value={deliverable.check_in_time}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col md={4} sm={6} xs={12}>
                          <TextInput
                            name='check_out_time'
                            className='borderPrimary'
                            label={`${t('supplier.edit.general.checkout_time')} *`}
                            placeholder=' '
                            value={deliverable.check_out_time}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col md={4} sm={6} xs={12}>
                          <Checkbox
                            name='check_out_lat_time'
                            checked={deliverable.check_out_lat_time}
                            onChange={this.props.onDeliverableChange}
                            label={t('supplier.edit.general.late_checkout')}
                          />
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-3'>
                        <Col xs={12}>
                          <label htmlFor='hotelCategories'>{t('hotel_category')}</label>
                          <Select
                            id='hotelCategories'
                            name='category_keys'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={categoryOptions}
                            getOptionValue={(option) => option.key}
                            getOptionLabel={(option) => option[`name_${i18n.language}`]}
                            placeholder={t('select_all_that_apply')}
                            value={categoryValues}
                            isSearchable
                            isClearable
                            isMulti
                            onChange={(values) => this.props.onMultipleSelectChangedKeys('category_keys', values, true)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                        <Col className='mt-3' xs={12}>
                          <label htmlFor='paymentInfo'>{t('payment_options')}</label>
                          <Select
                            id='paymentInfo'
                            name='payment_info'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={this.props.supplier.payment_info.payment_options}
                            getOptionValue={(option) => option.attribute_name}
                            getOptionLabel={option => option[`name_${i18n.language}`]}
                            placeholder={t('select_all_that_apply')}
                            value={getSelectedOptions(this.props.supplier.payment_info.payment_options)}
                            isSearchable
                            isMulti
                            onChange={(values) => this.props.onPaymentSelectChange(values)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                      </Row>

                      <Can perform='manage' on='all'>
                        <Row className='mt-3 mb-3'>
                          <Col xs={12}>
                            <label htmlFor='hashtags'>{t('hashtags')}</label>
                            <HashtagsSelect
                              id='hashtags'
                              name='hashtags'
                              value={deliverable.hashtags}
                              onChange={this.props.onDeliverableChange}
                              className='defaultSelectDropdown'
                              classNamePrefix='defaultSelectDropdown'
                            />
                          </Col>
                        </Row>
                      </Can>

                      <Row className='mt-3 mb-3'>
                        <Col md={6} sm={8} xs={12}>
                          <TextInput
                            name='last_refurbishment'
                            className='borderPrimary'
                            label={t('supplier.edit.general.last_refurbished')}
                            placeholder=''
                            value={deliverable.last_refurbishment}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                      </Row>
                    </AccordionItemPanel>
                  </AccordionItem>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className='m-0 mt-3 mb-3'>
            <Col className='p-0' xs={12}>
              <Card>
                <CardBody>
                  <AccordionItem>
                    <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                      <AccordionItemButton>
                        <h5 className='u-position-relative'>
                          {t('supplier.edit.general.distances.title')}
                          <div className='accordion__arrow' role='presentation' />
                        </h5>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row className='mt-1 mb-1'>
                        <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                          <TextInputGroup
                            name='distance_airport'
                            label={t('supplier.edit.general.distances.airport')}
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='km'
                            value={deliverable.distance_airport}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                          <TextInputGroup
                            name='distance_city'
                            label={t('supplier.edit.general.distances.city_center')}
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='km'
                            value={deliverable.distance_city}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                          <TextInputGroup
                            name='distance_main_road'
                            label={t('supplier.edit.general.distances.main_road')}
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='km'
                            value={deliverable.distance_main_road}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                          <TextInputGroup
                            name='distance_station'
                            label={t('supplier.edit.general.distances.train_station')}
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='km'
                            value={deliverable.distance_station}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                          <TextInputGroup
                            name='distance_public_transport'
                            label={t('supplier.edit.general.distances.public_transport')}
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='km'
                            value={deliverable.distance_public_transport}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                          <TextInputGroup
                            name='distance_local_station'
                            label={t('supplier.edit.general.distances.local_station')}
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='km'
                            value={deliverable.distance_local_station}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                          <TextInputGroup
                            name='distance_convention'
                            label={t('supplier.edit.general.distances.convention')}
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='km'
                            value={deliverable.distance_convention}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-3'>
                        <Col xs={12}>
                          <label htmlFor='locationSurroundings'>{t('supplier.edit.general.location_surroundings')}</label>
                          <Select
                            id='locationSurroundings'
                            name='locateds'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={deliverable.locateds}
                            getOptionValue={(option) => option.attribute_name}
                            getOptionLabel={option => option[`name_${i18n.language}`]}
                            placeholder={t('select_all_that_apply')}
                            value={getSelectedOptions(deliverable.locateds)}
                            isSearchable
                            isMulti
                            onChange={(values) => this.props.onMultipleSelectChange('locateds', values, true)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                      </Row>
                    </AccordionItemPanel>
                  </AccordionItem>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className='m-0 mt-3 mb-3'>
            <Col className='p-0' xs={12}>
              <Card>
                <CardBody>
                  <AccordionItem>
                    <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                      <AccordionItemButton>
                        <h5 className='u-position-relative'>
                          {t('supplier.edit.general.parking')}
                          <div className='accordion__arrow' role='presentation' />
                        </h5>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row className='mt-1 mb-1'>
                        <Col className='mt-2 mb-2' md={4} sm={6} xs={12}>
                          <TextInput
                            name='parking_spaces'
                            className='borderPrimary'
                            label={`${t('supplier.edit.general.max_parking')} *`}
                            placeholder='0'
                            value={deliverable.parking_spaces}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={4} sm={6} xs={12}>
                          <TextInput
                            name='parking_spaces_outside'
                            className='borderPrimary'
                            label={`${t('supplier.edit.general.outside_parking')} *`}
                            placeholder='0'
                            value={deliverable.parking_spaces_outside}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={4} sm={6} xs={12}>
                          <TextInput
                            name='parking_spaces_garage'
                            className='borderPrimary'
                            label={`${t('supplier.edit.general.inside_parking')} *`}
                            placeholder='0'
                            value={deliverable.parking_spaces_garage}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={4} sm={6} xs={12}>
                          <TextInput
                            name='parking_spaces_count'
                            className='borderPrimary'
                            label={`${t('supplier.edit.general.parking_spaces_count')} *`}
                            placeholder='0'
                            value={deliverable.parking_spaces_count}
                            onChange={this.props.onDeliverableChange}
                          />
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-3'>
                        <Col md={3} sm={6} xs={12}>
                          <Checkbox
                            name='private_parking'
                            checked={deliverable.private_parking}
                            onChange={this.props.onDeliverableChange}
                            label={t('supplier.edit.general.private_parking')}
                          />
                        </Col>
                        <Col md={3} sm={6} xs={12}>
                          <Checkbox
                            name='parking_public'
                            checked={deliverable.parking_public}
                            onChange={this.props.onDeliverableChange}
                            label={t('supplier.edit.general.public_parking')}
                          />
                        </Col>
                        <Col md={3} sm={6} xs={12}>
                          <Checkbox
                            name='parking_free'
                            checked={deliverable.parking_free}
                            onChange={this.props.onDeliverableChange}
                            label={t('supplier.edit.general.free_parking')}
                          />
                        </Col>
                        <Col md={3} sm={6} xs={12}>
                          <Checkbox
                            name='shuttle_service'
                            checked={deliverable.shuttle_service}
                            onChange={this.props.onDeliverableChange}
                            label={t('supplier.edit.general.shuttle_service')}
                          />
                        </Col>
                      </Row>
                    </AccordionItemPanel>
                  </AccordionItem>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className='m-0 mt-3 mb-3'>
            <Col className='p-0' xs={12}>
              <Card>
                <CardBody>
                  <AccordionItem>
                    <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                      <AccordionItemButton>
                        <h5 className='u-position-relative'>
                          {t('supplier.edit.general.classification_and_certificates_and_energy_information')}
                          <div className='accordion__arrow' role='presentation' />
                        </h5>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row className='mt-2 mb-2'>
                        <Col className='mt-1 mb-1' xs={12}>
                          <h5>{t('supplier.edit.general.energy_information')}</h5>
                        </Col>
                      </Row>
                      <Row className='mt-2 mb-2'>
                        <Checkbox
                          name='co2_heating_from_renewables'
                          checked={this.props.supplier.co2_info.co2_heating_from_renewables}
                          onChange={this.props.onCo2InfoChange}
                          label={t('supplier.edit.general.co2_info.co2_heating_from_renewables')}
                        />
                      </Row>
                      <Row className='mt-2 mb-2'>
                        <Checkbox
                          name='co2_electricity_from_renewables'
                          checked={this.props.supplier.co2_info.co2_electricity_from_renewables}
                          onChange={this.props.onCo2InfoChange}
                          label={t('supplier.edit.general.co2_info.co2_electricity_from_renewables')}
                        />
                      </Row>
                      <Row />
                      <Row className='mt-2 mb-2'>
                        <Col className='mt-2 mb-2' md={4} sm={6} xs={12}>
                          <label htmlFor='co2_emissions_per_roomnight' dangerouslySetInnerHTML={{ __html: `${t('supplier.edit.general.co2_info.co2_emissions_per_roomnight')} *` }} />
                          <TextInputGroup
                            name='co2_emissions_per_roomnight'
                            className='borderPrimary'
                            placeholder='0.0'
                            appendText='kg'
                            value={this.props.supplier.co2_info.co2_emissions_per_roomnight}
                            onChange={this.props.onCo2InfoChange}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={4} sm={6} xs={12}>
                          <label htmlFor='co2_energy_efficiency_class'>{t('supplier.edit.general.co2_info.co2_energy_efficiency_class')}</label>
                          <Select
                            id='co2_energy_efficiency_class'
                            name='co2_energy_efficiency_class'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={this.props.supplier.co2_energy_efficiency_classes}
                            placeholder={t('select_all_that_apply')}
                            value={getSelectedOption(this.props.supplier.co2_energy_efficiency_classes, this.props.supplier.co2_info.co2_energy_efficiency_class)}
                            isSearchable
                            isClearable
                            isMulti={false}
                            onChange={(option) => this.props.onCo2InfoSingleSelectChange('co2_energy_efficiency_class', option)}
                          />
                        </Col>
                      </Row>
                      <Row className='m-0 mt-3 mb-2 pl-0 pr-4'>
                        <Col xl={3} lg={4} md={4} sm={6}>
                          {this.props.supplier.co2_info.co2_emissions_document_file_url ? (
                            <Col className='mt-3' xs={12}>
                              <UploadImage
                                imageUrl={this.props.supplier.co2_info.co2_emissions_document_file_url}
                                onRemove={this.props.onCo2EmissionsDocumentRemove}
                                customClick
                                onClick={() => this.props.uploadWidget.open(this.props.onCo2EmissionsDocumentUpload, window.CLOUDINARY_UPLOAD_PRESET_FLOOR_PLAN)}
                              />
                            </Col>
                          ) : null}
                          <ButtonWithIcon
                            className='client-theme--border-color'
                            border='1px solid'
                            margin='5px 0px 0px 0px'
                            label={t('supplier.edit.button.upload_co2_emissions_document')}
                            icon='fa fa-upload'
                            onClick={() => this.props.uploadWidget.open(this.props.onCo2EmissionsDocumentUpload, window.CLOUDINARY_UPLOAD_PRESET_FLOOR_PLAN)}
                          />
                        </Col>
                        <Col className='offset-sm-1' xl={3} lg={4} md={4} sm={6}>
                          {this.props.supplier.co2_info.co2_certificate_file_url ? (
                            <Col className='mt-3' xs={12}>
                              <UploadImage
                                imageUrl={this.props.supplier.co2_info.co2_certificate_file_url}
                                onRemove={this.props.onCo2CertificateRemove}
                                customClick
                                onClick={() => this.props.uploadWidget.open(this.props.onCo2CertificateUpload, window.CLOUDINARY_UPLOAD_PRESET_FLOOR_PLAN)}
                              />
                            </Col>
                          ) : null}
                          <ButtonWithIcon
                            className='client-theme--border-color'
                            border='1px solid'
                            margin='5px 0px 0px 0px'
                            label={t('supplier.edit.button.upload_co2_certificate')}
                            icon='fa fa-upload'
                            onClick={() => this.props.uploadWidget.open(this.props.onCo2CertificateUpload, window.CLOUDINARY_UPLOAD_PRESET_FLOOR_PLAN)}
                          />
                        </Col>
                      </Row>
                      <Row className='mt-3 mb-2'>
                        <Col className='mt-1 mb-1' xs={12}>
                          <h5>{t('supplier.edit.general.classification_and_certificates')}</h5>
                        </Col>
                      </Row>
                      <Row className='mt-2 mb-2'>
                        <Col className='mt-2 mb-2' md={4} sm={6} xs={12}>
                          <label htmlFor='micepoints'>{t('supplier.edit.general.micepoints')}</label>
                          <Select
                            id='micepoints'
                            name='micepoints'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={deliverable.micepoints_options}
                            placeholder={t('select_all_that_apply')}
                            value={getSelectedOption(deliverable.micepoints_options, deliverable.micepoints)}
                            isSearchable
                            isMulti={false}
                            onChange={(option) => this.props.onSingleSelectChange('micepoints', option.value, true)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={4} sm={6} xs={12}>
                          <label htmlFor='classification'>{t('supplier.edit.general.classification')}</label>
                          <Select
                            id='classification'
                            name='classification'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={deliverable.classification_options}
                            placeholder={t('select_all_that_apply')}
                            value={getSelectedOption(deliverable.classification_options, deliverable.classification)}
                            isSearchable
                            isMulti={false}
                            onChange={(option) => this.props.onSingleSelectChange('classification', option.value, true)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                        <Col className='mt-2 mb-2' md={4} sm={6} xs={12}>
                          <label htmlFor='classification'>{t('supplier.edit.general.environment_certificate')}</label>
                          <Select
                            id='certificates'
                            name='certificates'
                            className='defaultSelectDropdown'
                            classNamePrefix='defaultSelectDropdown'
                            options={this.props.supplier.certificates_options}
                            getOptionValue={(option) => option.attribute_name}
                            getOptionLabel={option => option[`name_${i18n.language}`]}
                            placeholder={t('select_all_that_apply')}
                            value={getSelectedOptions(this.props.supplier.certificates_options)}
                            isSearchable
                            isMulti
                            onChange={(values) => this.props.onMultipleSelectChange('certificates_options', values)}
                            style={{ margin: '6px 0' }}
                          />
                        </Col>
                      </Row>
                      <Row className='m-0 mt-3 mb-2 pl-0 pr-4'>
                        {this.props.certificateFiles && this.props.certificateFiles.length > 0 ? (
                          this.props.certificateFiles.map((image, idx) => (
                            image._destroy ? null : (
                              <Col key={idx} className='pl-0 pt-3' lg={3} md={4} sm={6} xs={12}>
                                <UploadImage
                                  label={`${t('certificate')} ${idx + 1}`}
                                  newValueLabel={t('new_certificate')}
                                  imageAsLabel
                                  imageUrl={image.url}
                                  mode='g_auto'
                                  uploadWidget={this.props.uploadWidget}
                                  onUploadComplete={response => this.props.onImageUploadComplete('certificateFiles', idx, response)}
                                  onRemove={() => this.props.onRemovingCertificateFile(idx)}
                                />
                              </Col>
                            )
                          ))
                        ) : (
                          <Col className='p-0 d-flex justify-content-center align-items-center' lg={3} md={4} sm={6} xs={12}>
                            <UploadImage
                              label={t('click_here_to_upload')}
                              imageUrl=''
                              mode='c_fill,g_auto'
                              uploadWidget={this.props.uploadWidget}
                              onUploadComplete={response => this.props.onImageUploadComplete('certificateFiles', null, response)}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row className='m-0 mt-3 mb-2 pl-0 pr-4'>
                        <Col className='p-0' xl={3} lg={4} md={4} sm={6}>
                          <ButtonWithIcon
                            className='client-theme--border-color'
                            border='1px solid'
                            label={t('add_certificate')}
                            icon='fa fa-plus'
                            onClick={this.props.onAddingCertificateFile}
                          />
                        </Col>
                      </Row>
                    </AccordionItemPanel>
                  </AccordionItem>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )
    } else {
      return null
    }
  }
}

HotelGeneral.propTypes = {
  supplier: PropTypes.object,
  onChange: PropTypes.func,
  onDeliverableChange: PropTypes.func,
  onSingleSelectChange: PropTypes.func,
  onMultipleSelectChange: PropTypes.func,
  onMultipleSelectChangedKeys: PropTypes.func,
  onPaymentSelectChange: PropTypes.func
}

export default withTranslation()(HotelGeneral)
