import React from 'react'
import './UploadImage.scss'
import PropTypes from 'prop-types'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'

class UploadImage extends React.Component {
  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleUploadComplete = this.handleUploadComplete.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.state = {}
  }

  componentDidUpdate (prevProps) {
    if (prevProps !== this.props) {
      this.setState({ imageUrl: this.props.imageUrl })
    }
  }

  handleUploadComplete (response) {
    if (this.props.onUploadComplete) {
      this.setState({ imageUrl: response.url })
      this.props.onUploadComplete(response)
    }
  }

  handleClick (e) {
    if (this.node && this.node.contains(e.target)) {
      return null
    } else if (this.props.customClick) {
      this.props.onClick()
    } else {
      this.props.uploadWidget.open(this.handleUploadComplete, window.CLOUDINARY_UPLOAD_PRESET_NO_CUTTING)
    }
  }

  handleKeyDown (e) {
    if (e.key === 'Enter') {
      this.props.uploadWidget.open(this.handleUploadComplete, window.CLOUDINARY_UPLOAD_PRESET_NO_CUTTING)
    }
  }

  imageUrl () {
    const mode = this.props.mode || 'h_240,w_360,c_fill'

    if (this.state.imageUrl) {
      return this.state.imageUrl.replace('/upload/', `/upload/${mode}/`)
    } else if (this.props.imageUrl) {
      return this.props.imageUrl.replace('/upload/', `/upload/${mode}/`)
    }

    return null
  }

  render () {
    const currentImageUrl = this.imageUrl()

    return (
      <div className='upload-image-container position-relative' onClick={this.handleClick}>
        {this.props.onRemove ? (
          <div ref={node => (this.node = node)} className='position-absolute imageActionBar'>
            <ButtonWithIcon
              icon='fa fa-trash'
              iconColor='#fff'
              label=''
              className='imageButton removeImageButton'
              onClick={this.props.onRemove}
            />

            {this.props.movable ? (
              <div>
                <ButtonWithIcon
                  icon='fa fa-arrow-left'
                  iconColor={this.props.canMoveLeft ? '#fff' : '#555'}
                  label=''
                  className='imageButton'
                  onClick={this.props.onMoveLeft}
                />

                <ButtonWithIcon
                  icon='fa fa-arrow-right'
                  iconColor={this.props.canMoveRight ? '#fff' : '#555'}
                  label=''
                  className='imageButton'
                  onClick={this.props.onMoveRight}
                />
              </div>
            ) : ''}
          </div>
        ) : null}
        {currentImageUrl ? (
          this.props.imageAsLabel ? (
            <div className='border w-100 h-100 d-flex bg-light flex-wrap'>
              <div className='align-self-end w-100 text-center h5'>
                {this.props.label}
              </div>
            </div>
          ) : (
            <img src={currentImageUrl} alt='' />
          )
        ) : (
          <div tabIndex='0' onKeyDown={this.handleKeyDown} className='border w-100 h-100 d-flex bg-light flex-wrap'>
            <div className='align-self-center upload-icon w-100 text-center'>
              <i className='fa fa-upload' />
            </div>
            <div className='align-self-end w-100 text-center h5'>
              {this.props.newValueLabel || this.props.label}
            </div>
          </div>
        )}
      </div>
    )
  }
}

UploadImage.propTypes = {
  label: PropTypes.string,
  attributeName: PropTypes.string,
  imageUrl: PropTypes.string,
  onUploadComplete: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  movable: PropTypes.bool,
  canMoveLeft: PropTypes.bool,
  canMoveRight: PropTypes.bool,
  onMoveLeft: PropTypes.func,
  onMoveRight: PropTypes.func
}

export default UploadImage
