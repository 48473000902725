import React from 'react'
import './ButtonIcon.scss'
import PropTypes from 'prop-types'

class ButtonIcon extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      defaultContainerStyle: {},
      defaultContainerClassName: 'buttonIconWrapper',
      defaultIconStyle: { color: '#ffffff' },
      defultAriaLabel: ''
    }
  }

  render () {
    const containerStyle = this.props.containerStyle
      ? this.props.containerStyle
      : this.state.defaultContainerStyle
    const iconStyle = this.props.iconStyle
      ? this.props.iconStyle
      : this.state.defaultIconStyle
    const ariaLabel = this.props.ariaLabel
      ? this.props.ariaLabel
      : this.state.defultAriaLabel

    return (
      <div
        style={containerStyle}
        className={`${this.state.defaultContainerClassName} ${
          this.props.className
        } ${this.props.rounded && 'rounded-button'}`}
        onClick={this.props.onClick}
      >
        <input
          type='button'
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          defaultChecked={this.props.defaultChecked}
        />
        <label tabIndex='0' htmlFor={this.props.id}>
          <i style={iconStyle} className={this.props.icon} aria-label={ariaLabel} />
        </label>
      </div>
    )
  }
}

ButtonIcon.propTypes = {
  id: PropTypes.string,
  containerStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  defaultChecked: PropTypes.bool,
  ariaLabel: PropTypes.string
}

export default ButtonIcon
