import React from 'react'
import PropTypes from 'prop-types'
import Container from './Layout/Container'
import { addScriptTag } from '../../shared/utils'
import './SupplierAdmin.scss'
import Can from '../Layout/Can'
import { withRouter } from 'react-router-dom'

class Messages extends React.Component {
  componentDidMount () {
    addScriptTag('https://durchblick.miceportal.de/javascripts/api/viz_v1.js')
  }

  render () {
    return (
      <Container>
        <Can perform='read' on='TableauMessage' shouldRedirect>
          <div className='c-content-box c-bg-grey' style={{ paddingTop: '0px !important' }} fade={1} speed={300}>
            <div className='container-fluid'>
              <div className='row' style={{ marginBottom: '15px' }}>
                <div className='col-md-12'>
                  <div className='tableau-placeholder'>
                    {
                      this.props.supplier.isA('KonvenitUser')
                        ? (
                          <object className='tableauViz' width='100%' height='677' style={{ display: 'none' }}>
                            <param name='host_url' value='https%3A%2F%2Fdurchblick.miceportal.de%2F' />
                            <param name='embed_code_version' value='3' />
                            <param name='site_root' value='&#47;t&#47;suppliers' />
                            <param name='name' value='Messages_DienstleisterSalesManager&#47;Messages' />
                            <param name='tabs' value='yes' />
                            <param name='toolbar' value='yes' />
                            <param name='showAppBanner' value='false' />
                          </object>
                        ) : (
                          <object className='tableauViz' width='100%' height='825' style={{ display: 'none' }}>
                            <param name='host_url' value='https%3A%2F%2Fdurchblick.miceportal.de%2F' />
                            <param name='embed_code_version' value='3' />
                            <param name='site_root' value='&#47;t&#47;suppliers' />
                            <param name='name' value='Messages_Dienstleister&#47;Messages' />
                            <param name='tabs' value='no' />
                            <param name='toolbar' value='yes' />
                            <param name='showAppBanner' value='false' />
                            <param name='filter' value='iframeSizedToWindow=true' />
                          </object>
                        )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Can>
      </Container>
    )
  }
}

Messages.propTypes = {
  supplier: PropTypes.object
}

export default withRouter(Messages)
