import React from 'react'
import { Row, Col } from 'reactstrap'
import './SupplierContract.scss'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getActiveContract } from '../../shared/utils'

class SupplierContract extends React.Component {
  render () {
    const { t, i18n } = this.props
    const contract = getActiveContract(this.props.contracts, this.props.projectStartDateUnix)

    return (
      <>
        <Row className={`contractsWrapper ${this.props.contractsWrapperClass}`}>
          <Col
            xs={12}
            className='contractPriceColumn px-0'
          >
            {contract && contract.main_price && contract.main_price.number && contract.main_price.currency ? (
              <div className={`contractMainContainer ${this.props.contractMainContainerClass}`}>
                <div className={`contractMainPrice ${this.props.contractMainPriceClass}`}>
                  <span className='contractMainPriceCurrency'>{contract.main_price.currency}</span>
                  {contract.main_price.number}
                  <span className={`contractMainService ${this.props.contractMainServiceClass}`}>{contract[`main_service_label_${i18n.language}`] ? contract[`main_service_label_${i18n.language}`] : ''}</span>
                </div>
              </div>
            ) : (
              <div className='noContractsContainer'>
                <p>{t('rate_on_request')}</p>
              </div>
            )}
          </Col>
        </Row>
      </>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

SupplierContract.defaultProps = {
  contractMainPriceClass: '',
  contractMainServiceClass: '',
  contractsWrapperClass: '',
  contractMainContainerClass: ''
}

SupplierContract.propTypes = {
  t: PropTypes.func,
  contracts: PropTypes.any,
  projectStartDateUnix: PropTypes.number,
  contractMainPriceClass: PropTypes.string,
  contractMainServiceClass: PropTypes.string,
  contractsWrapperClass: PropTypes.string,
  contractMainContainerClass: PropTypes.string
}

export default connect(mapDispatchToProps)(withTranslation()(SupplierContract))
