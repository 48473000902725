import React from 'react'
import Rollbar from 'rollbar'
import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react'
import ErrorPage from '../components/ErrorPage/ErrorPage'
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary'

export const RollbarInstance = new Rollbar({
  accessToken: window.ROLLBAR_CLIENT_ACCESS_TOKEN,
  enabled: window.ROLLBAR_ENABLED,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: window.ROLLBAR_ENV,
  payload: {
    environment: window.ROLLBAR_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true
      }
    }
  },
  ignoredMessages: [/ResizeObserver loop completed with undelivered notifications\./]
})

export const ErrorProvider = ({ children }) => {
  if (RollbarInstance && RollbarInstance.captureException) {
    return (
      <RollbarProvider instance={RollbarInstance}>
        <RollbarErrorBoundary fallbackUI={ErrorPage}>
          {children}
        </RollbarErrorBoundary>
      </RollbarProvider>
    )
  } else {
    return <ErrorBoundary>{children}</ErrorBoundary>
  }
}
