import React from 'react'
import './OverviewInfoBox.scss'
import { Row } from 'reactstrap'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { redirectToSpecifiedUrl, removeFromCart } from '../../redux/actions'
import CartItemsOverviewBox from './CartItemsOverviewBox'
import moment from 'moment'
import { getSearchCompletePath, scrollToTop } from '../../shared/utils'

function OverviewInfoBox ({ t, queryParams = {}, searchLocation, redirectToSpecifiedUrl, cartItems, removeFromCart }) {
  function handleCheckoutClick () {
    redirectToSpecifiedUrl(
      getSearchCompletePath({ searchLocation }, queryParams)
    )
  }

  function handleButtonFocus (e) {
    console.log('button focussed')
    if (!e.currentTarget.contains(e.relatedTarget)) {
      scrollToTop()
    }
  }

  return (
    <div className='overview-info-box-container'>
      <div className='wrapper'>
        <Row className='ml-0 mr-0 w-100'>
          <div className='text-center w-100 info-title'>{t('order_overview')}</div>
        </Row>
        <Row className='ml-0 mr-0 w-100'>
          <div className='info-table-wrapper'>
            <table className='w-100'>
              <tbody>
                <tr>
                  <th className='border-right'>{t('filter_date_from')}</th>
                  <th>{t('filter_date_to')}</th>
                </tr>
                <tr className='border-bottom'>
                  <td className='border-right'>
                    {queryParams.start_date ? moment(queryParams.start_date).format('D MMM YYYY, HH:mm') : '-'}
                  </td>
                  <td>
                    {queryParams.end_date ? moment(queryParams.end_date).format('D MMM YYYY, HH:mm') : '-'}
                  </td>
                </tr>
                <tr>
                  <th colSpan={2}>{t('participants')}</th>
                </tr>
                <tr className='border-bottom'>
                  <td colSpan={2}>
                    {queryParams.participants || '-'}
                  </td>
                </tr>
                <tr>
                  <th colSpan={2}>{t('city')}</th>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {(searchLocation && searchLocation.name) || '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
        <Row onFocus={handleButtonFocus} className='ml-0 mr-0 w-100 d-flex justify-content-center align-items-center plan-event-button'>
          <ButtonWithIcon
            id='gtm-supplierPlanEvent-resultsPage'
            labelColor='#FFFFFF'
            padding='6px 32px'
            label={t('plan_event')}
            className='client-theme--bg text-white'
            onClick={handleCheckoutClick}
          />
        </Row>
        <Row className='ml-0 mr-0 w-100'>
          <div className='text-center w-100 info-title'>{t('selection')} ({cartItems && cartItems.length})</div>
        </Row>
        <div className='ml-0 mr-0 w-100 d-flex flex-column cart-items-container'>
          <CartItemsOverviewBox items={cartItems} removeFromCart={removeFromCart} />
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
    searchSession: state.app.searchSession,
    searchLocation: state.app.searchLocation,
    cartItems: state.app.cartItems
  }
}

const mapDispatchToProps = dispatch => {
  return {
    redirectToSpecifiedUrl: payload => dispatch(redirectToSpecifiedUrl(payload)),
    removeFromCart: payload => dispatch(removeFromCart(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OverviewInfoBox))
