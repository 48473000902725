/* eslint-disable */
function closeDialog () {
  var container = $('.js-cookies-eu')
  if (container) {
    var isSecure = location.protocol === 'https:'
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var expireDate = new Date(year + 1, month, day);
    Cookies.set('requierd_cookies', true, { path: '/', expires: expireDate, secure: isSecure })
    container.css('display', 'none')
    $('body').css('overflow', 'auto')
    if (Cookies.get('chat_cookies') === true && $('#hs-eu-confirmation-button').length) {
      $('#hs-eu-confirmation-button')[0].click()
    }
  }
};

function toggleAllCookies () {
  if ( $('.js-cookies-eu-ok') ) {
    coo = ['.js-cookies-monitoring-ok']
    if ('#{current_shop&.use_userlane?}') {
      coo.push('.js-cookies-tours-ok')
    }
    if ('#{current_shop&.client_instance&.enable_user_tracking}') {
      coo.push('.js-cookies-marketing-ok')
    }
    if ('#{current_shop&.use_chat?}') {
      coo.push('.js-cookies-chat-ok')
    }
    for (let i = 0; i < coo.length; i++) {
      $($(coo[i])).parent('label.switch').find('input[type=checkbox]').prop('checked', false)
      $($(coo[i])).trigger('click')
    }
    setTimeout(closeDialog, 1000)
  }
};

function expandPage () {
  const dots = document.getElementById('dots')
  const moreText = document.getElementById('more')
  const btnText = document.getElementById('expand')
  const allowAllBtn = document.getElementById('allowAllBtn')
  const cookiesText = document.getElementById('cookiesText')
  const cookieHolder = document.getElementById('cookieHolder')

  if (dots.style.display === 'none') {
    dots.style.display = 'inline'
    moreText.style.display = 'none'
    cookieHolder.style.height = '700px'
  } else {
    dots.style.display = 'none'
    btnText.style.display = 'none'
    allowAllBtn.style.display = 'none'
    moreText.style.display = 'inline'
    cookiesText.style.display = 'none'
  }
};

$(document).on('click', '.js-expand-page', expandPage)
$(document).on('click', '.js-close-dialog', closeDialog)
$(document).on('click', '.js-toggle-all-cookies', toggleAllCookies)
/* eslint-enable */
