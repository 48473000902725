import React from 'react'
import './AmenitiesTabContent.scss'
import { Col, Row } from 'reactstrap'
import SupplierFeatureCarouselBox from '../SupplierFeatureCarouselBox/SupplierFeatureCarouselBox'
import SupplierImageGridGallery from '../SupplierImageGridGallery/SupplierImageGridGallery'
import { withTranslation } from 'react-i18next'
import TagsBox from '../TagsBox/TagsBox'

class AmenitiesTabContent extends React.Component {
  constructor (props) {
    super(props)

    this.getDeliverable = this.getDeliverable.bind(this)
    this.renderImagesGallery = this.renderImagesGallery.bind(this)
  }

  getDeliverable () {
    return this.props.supplier && this.props.supplier[`supplier_${this.props.view.replace(/-/g, '_')}`]
  }

  renderImagesGallery (imageType, title) {
    const { supplier } = this.props

    if (!supplier) return

    let imagesKey = `${imageType}_images`

    if (this.props.view) {
      imagesKey = `supplier_${this.props.view.replace(/-/g, '_')}_${imageType}_images`
    }

    return this.props.supplier[imagesKey] && this.props.supplier[imagesKey].length > 0 ? (
      <Row className='m-0 mt-3 mb-3'>
        <div className='c-content-title-1 pt-2'>
          <h3 className='c-font-uppercase'>{title}</h3>
          <div className='c-line-left c-theme-bg client-theme--bg' style={{ marginBottom: '10px' }} />
        </div>
        <SupplierImageGridGallery images={this.props.supplier[imagesKey]} />
      </Row>
    ) : null
  }

  render () {
    const { t, i18n } = this.props
    const deliverable = this.getDeliverable()
    const view = this.props.view.replace(/-/g, '_')
    return (
      <>
        <div className='container'>
          <Row className='m-0'>
            <Col md={4} className='mb-3'>
              {deliverable.amenities && deliverable.amenities.length > 0 ? (
                <TagsBox
                  title={t('hotel_amenities')}
                  tags={deliverable.amenities}
                />
              ) : (
                <div>
                  <TagsBox
                    title={t('hotel_amenities')}
                    tags={deliverable.amenities}
                  />
                  <p style={{ position: 'absolute', top: '40%', left: '40%' }}>{t('no_note')}</p>
                </div>
              )}
            </Col>
            {view === 'hotel' ? (
              <Col md={4} className='mb-3'>
                {deliverable.sport_and_wellness_amenities && deliverable.sport_and_wellness_amenities.length > 0 ? (
                  <SupplierFeatureCarouselBox
                    title={t('sports_and_wellness')}
                    locale={i18n.language}
                    items={deliverable.sport_and_wellness_amenities}
                  />
                ) : (
                  <div style={{ height: '100%' }}>
                    <SupplierFeatureCarouselBox
                      title={t('sports_and_wellness')}
                    />
                    <p style={{ position: 'absolute', top: '40%', left: '40%' }}>{t('no_note')}</p>
                  </div>
                )}
              </Col>
            ) : null}
            <Col md={4} className='mb-3'>
              {deliverable.gastronomy_amenities && deliverable.gastronomy_amenities.length > 0 ? (
                <TagsBox
                  title={t('gastronomy')}
                  tags={deliverable.gastronomy_amenities}
                />
              ) : (
                <div>
                  <TagsBox
                    title={t('gastronomy')}
                    tags={deliverable.gastronomy_amenities}
                  />
                  <p style={{ position: 'absolute', top: '40%', left: '40%' }}>{t('no_note')}</p>
                </div>
              )}
            </Col>
          </Row>
          <Row className='m-0'>
            <Col className='pt-0' md={12}>
              {this.renderImagesGallery('amenity', t('amenities'))}
              {this.renderImagesGallery('wellness', t('wellness'))}
              {this.renderImagesGallery('gastronomy', t('gastronomy'))}
            </Col>
          </Row>
        </div>
      </>
    )
  }
}
export default withTranslation()(AmenitiesTabContent)
