import { isBoolean, isString, isFinite } from 'lodash'
import { getSupplierTypeMapping, hasAreaOfOperation } from './utils'
var dayjs = require('dayjs')

class IndexParams {
  constructor (searchParams) {
    this.searchParams = searchParams || {}
  }

  /* This is a convenience method, to quote the param only if it has a space.
   * TODO: That's how our code was working so far. But you shall validate how
   * would Algolia react to other strange characters, for example to a quote character in the middle.
   * */
  algoliaQuote (str) {
    const s = `${str}`
    const alreadyQuoted = s.startsWith('"') && s.endsWith('"')

    if (!alreadyQuoted && s.includes(' ')) {
      return this.algoliaQuoteParam(s)
    }
    return s
  }

  algoliaQuoteParam (str) {
    return `"${str.replace(/[\\"]/g, '\\$&')}"`
  }

  algoliaMapMultipleValues (key, strValues, mapper) {
    return ('' + strValues)
      .split(/\s*,\s*/)
      .filter((s) => s)
      .map((s) => mapper(s.trim()))
      .filter((s) => s)
      .map((s) => `${key}${this.algoliaQuote(s)}`)
  }

  getAppliedFilters () {
    return Object.keys(this.searchParams)
      .map((key) => this.getAllowedFacets(key, this.searchParams[key]))
      .filter((value) => value || false)
  }

  /* It calls all your search-terms-translating functions passed as arguments,
   * and joins the non-empty strings returned by them into one huge ".. AND .. AND .." monstrosity.
   *
   * All the passed functions are bound to this `this`, because that's how we usually use them.
   * If you don't want that, bind the function yourself before passing it here.
   *
   * */
  getAllFiltersUsing (...functions) {
    const check = (key) => {
      const v = this.searchParams[key]
      return this.isValueValid(v) ? v : null
    }
    const allFilters = []
    const t = this
    functions.forEach((fun) => {
      const filter = fun.bind(t)(check, t.searchParams)
      if (filter) allFilters.push(filter)
    })
    return allFilters.join(' AND ')
  }

  getMatchingSuppliersFilter () {
    const ignoredKeys = this.ignoredSuppliersFilterKeys()
    return this.getAllFiltersUsing(
      this.getMatchingSuppliersFilterWithout(ignoredKeys)
    )
  }

  getMatchingSuppliersFilterWithout (undesiredKeys) {
    const t = this
    return function (check, params) {
      const acc = []
      Object.keys(params).forEach((key) => {
        let filter
        if (
          !undesiredKeys.includes(key) &&
          (filter = t.asAlgoliaFilter(key, check(key)))
        ) {
          acc.push(filter)
        }
      })
      return acc.join(' AND ')
    }
  }

  /* Please note, that this function may be overriden in child classes.
   * */
  algoliaKeyParamToFilter (key) {
    switch (key) {
      case 'feedback_average_min':
        return "feedback_average"; /* eslint-disable-line */
      case 'conference_rooms':
        return "capacity_number_of_conference_rooms"; /* eslint-disable-line */
    }
    return key
  }

  /* Please note, that this function may be overriden in child classes.
   * */
  asAlgoliaFilter (key, value) {
    if (!value) return null
    key = this.algoliaKeyParamToFilter(key)

    switch (key) {
      case 'type':
        return `( ${this.algoliaMapMultipleValues(
          'deliverable_types:',
          value,
          getSupplierTypeMapping
        ).join(' OR ')} )`

      case 'category':
      case 'suitable':
      case 'payment_methods':
        return `(${this.algoliaMapMultipleValues(key + ':', value, (v) =>
          this.algoliaQuote(v)
        ).join(' OR ')})`
      case 'room_amenity':
      case 'conference_equipment':
      case 'amenity':
      case 'wellness_amenity':
      case 'sport_amenity':
      case 'gastronomy_amenity':
      case 'equipment_amenity':
        return `(${this.algoliaMapMultipleValues(key + ':', value, (v) =>
          this.algoliaQuote(v)
        ).join(' AND ')})`

      case 'professional':
      case 'airplus':
      case 'chain_name':
        return `${key} : ${this.algoliaQuote(value)}`

      case 'capacity_event_floor_space':
      case 'capacity_max_square_meter':
      case 'capacity_number_of_conference_rooms':
      case 'capacity_max_conference_capacity':
      case 'capacity_outdoor_floor_space':
      case 'capacity_max_height':
      case 'number_of_rooms': /* XXX: does it really work also for moments? Do they actually HAVE number_of_rooms ? */
      case 'feedback_average':
      case 'parking_spaces':
      case 'e_parking_spaces':
        return `${key} >= ${parseInt(value)}`

      case 'micepoints':
      case 'classification':
        return `(${key} : ${parseInt(value) * 10} OR ${key} : ${
          parseInt(value) * 10 + 1
        })`
      case 'distance_airport':
      case 'distance_station':
      case 'distance_city':
      case 'distance_local_station':
      case 'distance_main_road':
      case 'distance_convention':
        return `${key} <= ${parseInt(value)}`
      case 'location_indoor':
      case 'location_outdoor':
        return `${key} : ${value}`
      case 'participants':
        return `capacity_max_conference_capacity >= ${parseInt(value)}`
    }
    return null
  }

  getContractFilter (check) {
    const val = check('contracts')
    if (!val) return null

    const contractKey = `contracts.${window.CURRENT_SUBDOMAIN}`
    const startDateUnix = this.getStartDateUnix()
    const endDateUnix = this.getEndDateUnix()

    const dateFilter = `${contractKey}.valid_from_unix <= ${startDateUnix} AND ${contractKey}.valid_until_unix >= ${startDateUnix} AND ${contractKey}.valid_from_unix <= ${endDateUnix} AND ${contractKey}.valid_until_unix >= ${endDateUnix}`

    const activeFilter = `${contractKey}.active:true`

    return [activeFilter, dateFilter].join(' AND ')
  }

  getHashtagFilter (check, params) {
    // suppliers uses 'hashtags', moments uses 'hashtag'
    const tags = check('hashtag') || check('hashtags')
    if (!tags) return null

    const filter = []
    tags.split(',').forEach((rawTag) => {
      const tag = rawTag.trim()
      if (tag) {
        filter.push(`hashtags.en:${tag} OR hashtags.de:${tag}`)
      }
    })
    return `(${filter.join(' OR ')})`
  }

  getStartDateUnix () {
    return dayjs(this.getStartDate()).valueOf() / 1000
  }

  getEndDateUnix () {
    return dayjs(this.getEndDate()).valueOf() / 1000
  }

  getStartDate () {
    return (
      (this.searchParams &&
        this.searchParams.start_date &&
        dayjs(this.searchParams.start_date).toDate()) ||
      new Date()
    )
  }

  getEndDate () {
    return (
      (this.searchParams &&
        this.searchParams.end_date &&
        dayjs(this.searchParams.end_date).toDate()) ||
      new Date()
    )
  }

  getAreaOfOperationFilter (check) {
    const type = check('type')
    if (type && hasAreaOfOperation(type)) {
      const areaOfOperation = []
      if (this.areaOfOperationCountry) {
        areaOfOperation.push(
          `operational_area_countries:${this.areaOfOperationCountry}`
        )
      }
      if (this.areaOfOperationPlaceId) {
        areaOfOperation.push(
          `operational_area_cities:${this.areaOfOperationPlaceId}`
        )
      }
      return areaOfOperation.join(' OR ')
    }
    return null
  }

  buildAreaOfOperationFilter (searchLocation) {
    this.areaOfOperationPlaceId = null
    this.areaOfOperationCountry = null

    if (searchLocation) {
      if (searchLocation.country) {
        this.areaOfOperationCountry = searchLocation.country.short_name
      }
      if (searchLocation.placeId) {
        this.areaOfOperationPlaceId = searchLocation.placeId
      }
    }
  }

  isValueValid (value) {
    return value && (isString(value) || isFinite(value) || isBoolean(value))
  }
}

export default IndexParams
