import React from 'react'
import './ClientFeedbackCarouselBox.scss'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import moment from 'moment'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2400 },
    items: 1
  },
  largeDesktop: {
    breakpoint: { max: 2400, min: 1600 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 1600, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}

class ClientFeedbackCarouselBox extends React.Component {
  constructor (props) {
    super(props)
    this.getFeedbackUserName = this.getFeedbackUserName.bind(this)
  }

  getFeedbackUserName (firstName, lastName) {
    let name = ''
    if (firstName !== null) {
      name += firstName.trim()
    }
    if (lastName !== null) {
      name += ' ' + lastName.trim()
    }
    return name.trim() ? name : 'Anonymous'
  }

  render () {
    return this.props.feedbacks && this.props.feedbacks.length > 0 ? (
      <div className='clientFeedbackWrapper'>
        <div className='c-content-label c-font-uppercase c-font-bold c-bg-regular'>
          {this.props.title ? this.props.title : 'Feedback'}
        </div>
        <Carousel
          itemClass='clientFeedbackCarouselItem'
          containerClass='clientFeedbackCarouselContainer'
          infinite
          responsive={responsive}
        >
          {this.props.feedbacks.map((feedback, idx) =>
            feedback.comment && feedback.comment !== '' ? (
              <div key={idx} className='item'>
                <div className='c-content-testimonial-3 c-option-light'>
                  <div className='c-content round-element-no-shadow' style={{ fontSize: '15px' }}>
                    {feedback.comment}
                  </div>
                  <div className='c-person '>
                    <div className='c-person-detail c-font-uppercase'>
                      <div>
                        <h4 className='c-name feedback-creator'>{this.getFeedbackUserName(feedback.first_name, feedback.last_name)}</h4>
                        <h4 className='c-name feedback-date'>{moment(feedback.created_at).format('DD.MM.YYYY')}</h4>
                      </div>
                      <p className='c-position c-font-bold c-theme-font'>{feedback.department}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          )}
        </Carousel>
      </div>
    ) : null
  }
}
export default ClientFeedbackCarouselBox
