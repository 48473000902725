import { Ability } from '@casl/ability'

const TYPE_KEY = Symbol.for('type')

const subjectName = (subject) => {
  if (!subject || typeof subject === 'string') {
    return subject
  }

  return subject[TYPE_KEY]
}

export const wrapAsAnInstanceOf = (obj, type) => {
  obj[TYPE_KEY] = type
  obj.isA = (classType) => obj[TYPE_KEY] === classType

  return obj
}

export default new Ability([], { subjectName })
