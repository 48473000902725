/* global localStorage */

import React from 'react'
import ButtonWithIcon from '../../ButtonWithIcon/ButtonWithIcon'
import '../SupplierAdmin.scss'
import { withTranslation } from 'react-i18next'

class Layout extends React.Component {
  render () {
    const { t } = this.props

    return (
      <>
        <div id='page1'>
          {this.props.renderHero && this.props.renderHero()}
          <div className='c-content-box c-size-md c-bg-grey'>
            <div className='c-container'>
              <div className='row'>
                <div className='col-md-12'>
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
          <div className='c-cookies-bar c-cookies-bar-1 c-cookies-bar-bottom c-bg-red wow animate fadeInUp animated' data-wow-delay='1s' id='cookie' style={{ display: 'none', visibility: 'visible', animationDelay: '1s', animationName: 'fadeInUp', opacity: 1 }}>
            <div className='c-cookies-bar-container'>
              <div className='row'>
                <div className='col-md-9'>
                  <div className='c-cookies-bar-content c-font-white'>{t('cookies_acceptance')}</div>
                </div>
                <div className='col-md-3'>
                  <div className='c-cookies-bar-btn'>
                    <ButtonWithIcon label={t('more_info')} className='btn c-btn-white c-btn-border-1x c-btn-bold c-btn-square c-cookie-bar-link' />
                    <ButtonWithIcon label={t('got_it')} className='c-cookies-bar-close btn c-btn-white c-btn-square c-btn-bold' onClick={() => localStorage.setItem('mice', 'OK')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default withTranslation()(Layout)
