import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { FormElement, TextInput, Textarea } from '../FormElements/FormElements'
import axios from 'axios'
import queryString from 'qs'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { ConfirmModal } from '../Modals/Modals'
import HashtagsSelect from '../HashtagsSelect/HashtagsSelect'
import HashTags from '../HashTags/HashTags'
import ImagesGalleryManager from '../ImagesGalleryManager/ImagesGalleryManager'
import TextPreview from '../TextPreview/TextPreview'
import PropTypes from 'prop-types'
import defaultThumbnail from '../../assets/images/blank-hotel.jpg'
import './MiceMomentCard.scss'
import { Carousel } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'
import MiceMomentButton from './MiceMomentButton'
import Select from 'react-select'

import DatePicker, { registerLocale } from 'react-datepicker'
import en from 'date-fns/locale/en-GB'
import de from 'date-fns/locale/de'
// registerLocale('en', en) -- it would be reasonable to have it here, because these 5 lines form a group,
// registerLocale('de', de) -- but some STUPID %&$!&x%*!%! LINTER has a different opinion on what "nice" means. >:-(

import Switch from 'react-switch'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import Label from '../Label/Label'
var customParseFormat = require('dayjs/plugin/customParseFormat')
var dayjs = require('dayjs')
dayjs.extend(customParseFormat)

// These two lines are needed only for DatePicker component.
registerLocale('en', en)
registerLocale('de', de)

const miceMomentCategories = (t) => {
  const categories = ['meeting', 'location', 'entertainment_package', 'restaurant', 'virtual', 'tms', 'shuttle', 'workplace', 'agency', 'coach']
  return categories.map((value) => ({ value, label: t(`mice_moment_categories.${value}`) }))
}

function MiceMomentCard ({ value, onSave, onCancel, onDelete, t, supplier, deliverableType, edit, hideButtons, isLoggedIn, adminView = false, i18n }) {
  const dateFormat = 'dd.MM.yyyy'
  const [isEditing, setEditing] = useState(edit || false)
  const [isSaving, setIsSaving] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [miceMomentData, setMiceMomentData] = useState({ description: '', title: '', hashtags: [], ...value })
  const [errors, setErrors] = useState({})
  const [lastMiceMomentData, setLastMiceMomentData] = useState(value)
  const [openEnded, setOpenEnded] = useState(true)
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)
  const minDate = dayjs().add(5, 'day')
  const [startDate, setStartDate] = useState(value.start_date ? dayjs(value.start_date, 'YYYY-MM-DD', true).toDate() : new Date())
  const [endDate, setEndDate] = useState(value.end_date ? dayjs(value.end_date, 'YYYY-MM-DD', true).toDate() : null)

  useEffect(() => {
    if (value && value.images && value.images.length > 0) {
      value.images.sort((a, b) => a.element_order - b.element_order)
    }
    setMiceMomentData({ description: '', title: '', hashtags: [], ...value })
  }, [value])

  useEffect(() => {
    if (endDate) {
      setOpenEnded(false)
    }
  }, [endDate])

  supplier = supplier || {}

  const toggleDeleteModal = () => { setShowDeleteModal(!showDeleteModal) }

  const toggleDeactivateModal = () => { setShowDeactivateModal(!showDeactivateModal) }

  const handleEdit = () => {
    setLastMiceMomentData(miceMomentData)
    setEditing(true)
  }

  const handleCancel = () => {
    setMiceMomentData(lastMiceMomentData)
    setStartDate(lastMiceMomentData.start_date !== null ? dayjs(lastMiceMomentData.start_date, 'YYYY-MM-DD', true).toDate() : new Date())
    setEndDate(lastMiceMomentData.end_date !== null ? dayjs(lastMiceMomentData.end_date, 'YYYY-MM-DD', true).toDate() : null)
    setEditing(false)
    if (onCancel) {
      onCancel()
    }
  }

  const handleDelete = () => {
    setShowDeleteModal(false)
    setIsSaving(true)
    setErrors({})

    if (miceMomentData.id) {
      axios
        .delete(`/suppliers/${supplier.id}/deliverables/${deliverableType}/mice_moments/${miceMomentData.id}`)
        .then(() => {
          setIsSaving(false)
          if (onDelete) {
            onDelete(miceMomentData)
          }
        })
        .catch((error) => {
          setIsSaving(false)
          console.log('Unexpected error happened', error.response)
        })
    }
  }

  const successCallback = (response) => {
    setIsSaving(false)
    setEditing(false)
    if (onSave) {
      onSave(response.data)
    }
  }

  const errorCallback = (error) => {
    setIsSaving(false)
    switch (error.response.status) {
      case 422:
        setErrors(error.response.data.errors)
        break
    }
  }

  const handleActivate = () => {
    const baseUrl = `/suppliers/${supplier.id}/deliverables/${deliverableType}/mice_moments`
    if (miceMomentData.id) {
      axios
        .put(`${baseUrl}/${miceMomentData.id}`, { mice_moment: { ...miceMomentData, active: !miceMomentData.active } })
        .then(successCallback)
        .then(toggleDeactivateModal)
        .catch(errorCallback)
    }
  }

  const handleSave = () => {
    setIsSaving(true)
    setErrors({})

    const baseUrl = `/suppliers/${supplier.id}/deliverables/${deliverableType}/mice_moments`
    let formattedEndDate = endDate
    if (formattedEndDate) {
      formattedEndDate = dayjs(endDate).format('YYYY-MM-DD')
    }
    const miceMomentDataWithDate = { ...miceMomentData, start_date: dayjs(startDate).format('YYYY-MM-DD'), end_date: formattedEndDate }
    if (miceMomentData.id) {
      axios
        .put(`${baseUrl}/${miceMomentData.id}`, { mice_moment: miceMomentDataWithDate })
        .then(successCallback)
        .catch(errorCallback)
    } else {
      axios
        .post(`${baseUrl}`, { mice_moment: miceMomentDataWithDate })
        .then(successCallback)
        .catch(errorCallback)
    }
  }

  const handleSwitchChange = () => {
    if (!openEnded) {
      setEndDate(null)
    } else {
      setEndDate(startDate)
    }
    setOpenEnded(!openEnded)
  }

  const handleChange = ({ target }) => {
    const value = target.value
    const name = target.name

    const newMiceMomentData = { ...miceMomentData }
    newMiceMomentData[name] = value
    setMiceMomentData(newMiceMomentData)
  }

  const formatCreationInfo = (miceMomentData, name) => {
    let creationDate = new Date()
    if (miceMomentData.created_at) {
      creationDate = dayjs(miceMomentData.created_at).toDate()
    }

    return t('by_author_on_date', { author: supplier.name, date: creationDate })
  }

  const isMiceMomentActive = () => {
    return miceMomentData && miceMomentData.active
  }

  const renderEditForm = () => {
    return (
      <>
        <Row>
          <Col xs={12} className='mice-moment-card__title mb-3'>
            {miceMomentData.title}
          </Col>
          <Col xs={12} className='mb-3'>
            <TextInput
              name='title'
              label={t('title')}
              value={miceMomentData.title}
              error={errors.title}
              onChange={handleChange}
              placeholder={t('title')}
              disabled={isSaving}
            />
          </Col>
          <Col xs={12} className='mb-3'>
            <FormElement
              label={t('supplier.edit.dates')}
              error={errors.dates}
            >
              <Row>
                <Col xs={6}>
                  <div className='centered-label'>
                    {t('supplier.edit.from')}
                  </div>
                </Col>
                <Col xs={6}>
                  <div className='centered-label'>
                    {t('supplier.edit.until')}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <DatePicker
                    className='test-start-date-datepicker'
                    selected={startDate}
                    dateFormat={dateFormat}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    minDate={minDate}
                    locale={i18n.language}
                  />
                </Col>
                <Col xs={6}>
                  <DatePicker
                    className='test-end-date-datepicker'
                    selected={endDate}
                    dateFormat={dateFormat}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText={openEnded ? t('supplier.edit.unlimited_date') : ''}
                    locale={i18n.language}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6} />
                <Col xs={6}>
                  <label className='test-unlimited-switch mt-2 d-flex align-items-center'>
                    <span className='mr-3'>{t('supplier.edit.unlimited_date')}</span>
                    <Switch
                      onChange={handleSwitchChange}
                      checked={openEnded}
                      height={20}
                      width={45}
                      onColor='#a3c95e'
                    />
                  </label>
                </Col>
              </Row>
            </FormElement>
          </Col>
          <Col xs={12} className='mb-3'>
            <FormElement
              label={t('hashtags')}
              error={errors.hashtags}
            >
              <HashtagsSelect
                name='hashtags'
                className={`defaultSelectDropdown form-element ${errors.hashtags ? 'is-invalid' : ''}`}
                value={miceMomentData.hashtags}
                onChange={handleChange}
                classNamePrefix='defaultSelectDropdown'
              />
            </FormElement>
          </Col>
          <Col xs={12} className='mb-3'>
            <FormElement
              label={t('category')}
              error={errors.category}
            >
              <Select
                id='category'
                name='select_category'
                className='defaultSelectDropdown'
                classNamePrefix='defaultSelectDropdown'
                options={miceMomentCategories(t)}
                placeholder='Select one'
                value={miceMomentData.category && [{ value: miceMomentData.category, label: t(`mice_moment_categories.${miceMomentData.category}`) }]}
                onChange={({ value }) => setMiceMomentData({ ...miceMomentData, category: value })}
                style={{ margin: '6px 0' }}
              />
            </FormElement>
          </Col>
          <Col xs={12} className='mb-3'>
            <Textarea
              name='description'
              label={t('description')}
              value={miceMomentData.description}
              error={errors.description}
              onChange={handleChange}
              disabled={isSaving}
              rows={5}
            />
          </Col>
          <Col xs={12} className='mb-3'>
            <ImagesGalleryManager
              name='images_attributes'
              images={miceMomentData.images}
              maxAmount={10}
              onChange={handleChange}
            />
          </Col>
        </Row>
        {!hideButtons ? (
          <Row>
            <Col xs={6}>
              <ButtonWithIcon
                className='mice-moment-card__cancel-button'
                border='1px solid #444'
                onClick={handleCancel}
                disabled={isSaving}
                icon='fa fa-ban'
                label={t('cancel')}
              />
            </Col>
            <Col xs={6}>
              <ButtonWithIcon
                className='mice-moment-card__save-button client-theme--bg'
                onClick={handleSave}
                disabled={isSaving}
                icon='fa fa-save'
                label={t('save')}
              />
            </Col>
          </Row>
        ) : null}
      </>
    )
  }

  const hashtagUrl = (tagName) => {
    return {
      pathname: '/moments',
      search: queryString.stringify({ query: tagName })
    }
  }

  return (
    <div className='mice-moment-card supplier-feature-box-round'>
      {isEditing ? renderEditForm() : (
        <>
          <Carousel
            className={`mice-moment-card__images ${isMiceMomentActive() ? '' : 'disabled'}`}
            infiniteLoop
            showIndicators={false}
            showThumbs={false}
            showArrows
            showStatus={false}
            swipeable={false}
          >
            {miceMomentData.images && miceMomentData.images.length > 0 ? (
              miceMomentData.images.map((item, index) => (
                <div key={item.id || `newImage-${index}`}>
                  <img
                    src={item.url ? item.url : defaultThumbnail}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src = defaultThumbnail
                    }}
                    className='mice-moment-card-img'
                    alt=''
                  />
                </div>
              ))
            ) : (
              <div>
                <img src={defaultThumbnail} alt='' />
              </div>
            )}
          </Carousel>
          <Row className={`${isMiceMomentActive() ? '' : 'disabled'}`}>
            <Col xs={12}>
              <div className='mice-moment-card__title'>
                <Link to={`/moments/${miceMomentData.canonical_path}`}>{miceMomentData.title}</Link>
              </div>
              <div className='mice-moment-card__creation-info'>
                <Link to={`/suppliers/${supplier.id}`}>{formatCreationInfo(miceMomentData)}</Link>
              </div>
            </Col>
            {adminView && miceMomentData && miceMomentData.start_date ? (
              <Col xs={12} className='mt-3 mb-3'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    {t('valid_from')} :
                    <Label
                      className=''
                      name={dayjs(miceMomentData.start_date, 'YYYY-MM-DD', true).format('DD.MM.YYYY')}
                      icon='fa fa-clock'
                    />
                  </div>
                  <div>
                    {t('valid_to')} :
                    <Label
                      className=''
                      name={miceMomentData.end_date ? dayjs(miceMomentData.end_date, 'YYYY-MM-DD', true).format('DD.MM.YYYY') : t('supplier.edit.unlimited_date')}
                      icon='fa fa-clock'
                    />
                  </div>
                </div>
              </Col>) : null}
            <Col xs={12}>
              <div className='mice-moment-card__hashtags mt-3 mb-2'>
                {miceMomentData && miceMomentData.hashtags && miceMomentData.hashtags.length > 0 ? (
                  <HashTags values={miceMomentData.hashtags} hrefCallback={hashtagUrl} />) : null}
              </div>
              <div className='mice-moment-card__description mt-3'>
                <TextPreview
                  href={`/moments/${miceMomentData.canonical_path}`}
                  text={miceMomentData.description}
                  previewLength={140}
                />
              </div>
            </Col>
          </Row>
          <Row>
            {!hideButtons ? (
              <>
                <Col xs={6} md={4} className='mt-3'>
                  <ButtonWithIcon
                    className='mice-moment-card__edit-button'
                    border='2px solid #444'
                    onClick={handleEdit}
                    icon='fa fa-pencil'
                    label={t('edit')}
                  />
                </Col>
                <Col xs={6} md={4} className='mt-3'>
                  <ButtonWithIcon
                    className={`${isMiceMomentActive() ? 'bg-warning' : 'bg-success'}`}
                    onClick={toggleDeactivateModal}
                    icon={`${isMiceMomentActive() ? 'fa fa-exclamation' : 'fa fa-check'}`}
                    label={isMiceMomentActive() ? t('deactivate_label') : t('activate_label')}
                  />
                </Col>
                <Col xs={6} md={4} className='mt-3'>
                  <ButtonWithIcon
                    className='mice-moment-card__delete-button bg-danger'
                    disabled={isSaving}
                    onClick={toggleDeleteModal}
                    icon='fa fa-remove'
                    label={t('common.remove')}
                    labelColor='#fff'
                    iconColor='#fff'
                  />
                </Col>
              </>
            ) : (<Col xs={{ size: 12, offset: 0 }} lg={{ size: 12, offset: 0 }}><MiceMomentButton miceMoment={miceMomentData} /></Col>)}
          </Row>
        </>
      )}
      {adminView ? (
        <Label
          className={`status-label ${isMiceMomentActive() ? 'bg-success' : 'bg-danger'}`}
          name={isMiceMomentActive() ? t('active_status') : t('inactive_status')}
          icon={isMiceMomentActive() ? 'fa fa-check' : 'fa fa-times'}
        />) : null}
      <ConfirmModal
        className='delete-modal'
        isOpened={showDeleteModal}
        title={t('mice_moment_messages.delete_title')}
        confirmLabel={t('delete')}
        confirmColor='danger'
        onConfirm={handleDelete}
        onCancel={toggleDeleteModal}
      >
        {t('mice_moment_messages.delete', { mice_moment: miceMomentData.title })}
      </ConfirmModal>
      <ConfirmModal
        className='delete-modal'
        isOpened={showDeactivateModal}
        title={isMiceMomentActive() ? t('mice_moment_messages.deactivate_title') : t('mice_moment_messages.activate_title')}
        confirmLabel={isMiceMomentActive() ? t('deactivate_label') : t('activate_label')}
        confirmColor={isMiceMomentActive() ? 'warning' : 'success'}
        onConfirm={handleActivate}
        onCancel={toggleDeactivateModal}
      >
        {isMiceMomentActive() ? t('mice_moment_messages.deactivate_message', { mice_moment: miceMomentData.title }) : t('mice_moment_messages.activate_message', { mice_moment: miceMomentData.title })}
      </ConfirmModal>
    </div>
  )
}

MiceMomentCard.propTypes = {
  supplier: PropTypes.object,
  isLoggedIn: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  value: PropTypes.object
}

const mapStateToProps = state => ({ isLoggedIn: state.app.isLoggedIn })
export default connect(mapStateToProps)(withTranslation()(MiceMomentCard))
