import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

class TagsBox extends React.Component {
  render () {
    const { i18n, t } = this.props

    return (
      <div className='c-content-media-1 contentBox supplier-feature-box-round' style={{ height: '100%', minHeight: '200px' }}>
        {this.props.title ? (
          <div className='c-content-label c-font-uppercase c-font-bold c-bg-regular' dangerouslySetInnerHTML={{ __html: `${this.props.title}` }} />
        ) : null}

        {this.props.tagListTitle ? (
          <div className='c-title c-font-uppercase c-font-bold c-font-bold'>{this.props.tagListTitle}</div>
        ) : null}

        {this.props.tags && this.props.tags.length > 0 ? (
          <ul className='tags' style={{ marginTop: '5px' }}>
            {this.props.tags.map((tag, index) => <li key={index}>{tag[`name_${i18n.language}`]}</li>)}
          </ul>
        ) : null}

        {this.props.certificate_files && this.props.certificate_files.length > 0 ? (
          <ul className='tags certificates-urls' style={{ marginTop: '5px' }}>
            {this.props.certificate_files.map((file, index) => <li className='certificate-file' key={index}> <a key={index} href={`${file.url}`} rel='noopener noreferrer' target='_blank'>{`${t('certificate')} ${index + 1}`}</a></li>)}
          </ul>
        ) : null}

        {this.props.co2_certificates && this.props.co2_certificates.length > 0 ? (
          <ul className='tags certificates-urls' style={{ marginTop: '5px' }}>
            {this.props.co2_certificates.map((file, index) => <li className='certificate-file' key={index}> <a key={index} href={`${file.url}`} rel='noopener noreferrer' target='_blank'>{file[`name_${i18n.language}`]}</a></li>)}
          </ul>
        ) : null}
      </div>
    )
  }
}

TagsBox.propTypes = {
  title: PropTypes.string.isRequired,
  tagListTitle: PropTypes.string,
  tags: PropTypes.array
}

export default withTranslation()(TagsBox)
