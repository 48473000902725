import React from 'react'

/* Don't be surprised by empty functions here. This is a _default_
 * value of the context, used only as a fallback for components,
 * which do not have a provider upper in the tree.
 * This may happen in testing or if you seriously mess things up.
 * */
export const SearchContext = React.createContext({
  momentHitsCount: -1,
  supplierHitsCount: -1,
  setMomentHitsCount: () => {},
  setSupplierHitsCount: () => {}
})

/* The idea behind this component is to gather values relevant to the
 * InstantSearch tree.
 *
 * So far I needed just one: the `momentHitsCount`, but I expect more to follow.
 * (Update: I've just added `supplierHitsCount`)
 *
 * If it happens, then I suggest that we add specific contexts, put their
 * providers in the `return` block, export them, and then pick only the
 * needed consumers (by `useContext()`) in places where they are needed.
 *
 * Maybe this file shall be renamed to `SearchContextProvider`?
 * And maybe the individual contexts shall be declared in their individual files?
 * Maybe this whole grouping of contexts does not make much sense at all?
 *
 * So far the useState() is guaranteed to not fire unless the value really changes,
 * so I hope that this will trigger the context update only when necessary.
 * (The `contextValue` object is always created new, even if the counters would stay the same)
 * But if you add more contexts and/or variables, think about how it plays with the re-rendering.
 * */
export function SearchContextProvider (props) {
  const [momentHitsCount, setMomentHitsCount] = React.useState(-1)
  const [supplierHitsCount, setSupplierHitsCount] = React.useState(-1)

  const contextValue = {
    momentHitsCount: momentHitsCount,
    setMomentHitsCount: setMomentHitsCount,
    supplierHitsCount: supplierHitsCount,
    setSupplierHitsCount: setSupplierHitsCount
  }

  return (
    <SearchContext.Provider value={contextValue}>
      {props.children}
    </SearchContext.Provider>
  )
}

export default SearchContext
