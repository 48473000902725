import React from 'react'
import './Checkbox.scss'
import { UUID } from '../../shared/utils'
import PropTypes from 'prop-types'

class Checkbox extends React.Component {
  constructor (props) {
    super(props)
    this.UUID = UUID()
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  handleKeyDown (e) {
    if (e.key === 'Enter') {
      this.node.click()
    }
  }

  render () {
    const checkboxId = this.props.id ? this.props.id : `UUID-${this.UUID}`
    const labelText = this.props.label ? this.props.label : ''
    return (
      <div className='customCheckboxWrapper'>
        <div onKeyDown={this.handleKeyDown} tabIndex='0' className='customCheckbox'>
          <input ref={node => (this.node = node)} tabIndex='-1' id={checkboxId} name={this.props.name} type='checkbox' checked={this.props.checked} onChange={this.props.onChange} />
          <label htmlFor={checkboxId}>{labelText}
            {this.props.checked ? <i className='fa fa-check' /> : null}
          </label>
        </div>
      </div>
    )
  }
}

Checkbox.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired
}

export default Checkbox
