import React, { Component } from 'react'
import { GeoSearch, Control } from 'react-instantsearch-dom-maps'
import SupplierMapMarker from '../SupplierMapMarker/SupplierMapMarker'
import { connect } from 'react-redux'
import { mapStyles } from '../../MapStyles.js'
import Region from './Region'
import SelectedLocationPinMarker from '../SelectedLocationPinMarker/SelectedLocationPinMarker'
import './ResultsMap.scss'
import SupplierCardMapPopup from '../SupplierCardMapPopup/SupplierCardMapPopup'
import { getGoogleMaps } from '../../services/googleMaps'

class ResultsMap extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mapOptions: {
        styles: mapStyles,
        scrollwheel: false,
        gestureHandling: 'greedy',
        scaleControl: true
      }
    }
    this.getRightTop = this.getRightTop.bind(this)
  }

  getRightTop (map) {
    return map && map.ControlPosition && map.ControlPosition.RIGHT_TOP ? map.ControlPosition.RIGHT_TOP : ''
  }

  render () {
    const { openedSupplierPopup, highlightedSupplier } = this.props
    const googleMaps = getGoogleMaps()
    const RIGHT_TOP = this.getRightTop(googleMaps)
    return (
      <>
        {googleMaps
          ? (
            <GeoSearch
              google={window.google}
              streetViewControl
              mapTypeControl
              rotateControl
              streetViewControlOptions={{ position: RIGHT_TOP }}
              zoomControl
              zoomControlOptions={{ position: RIGHT_TOP }}
              options={this.state.mapOptions}
            >
              {({ hits }) => (
                <>
                  <Control />
                  {this.props.searchLocation && this.props.searchLocation.region && this.props.searchLocation.region.coordinates ? <Region key='region' coordinates={this.props.searchLocation.region.coordinates} /> : null}
                  {hits.map(hit => {
                    const isHighlighted = highlightedSupplier && highlightedSupplier.objectID === hit.objectID
                    return (
                      <SupplierMapMarker
                        key={isHighlighted ? `${hit.objectID}--highlighted` : hit.objectID}
                        hit={hit}
                        isHighlighted={isHighlighted}
                        searchSession={this.props.searchSession}
                        searchLocation={this.props.searchLocation}
                        cartItems={this.props.cartItems}
                        openedSupplierPopup={this.props.openedSupplierPopup}
                      />)
                  })}
                  {this.props.searchLocation && !this.props.searchLocation.region && (
                    <SelectedLocationPinMarker searchLocation={this.props.searchLocation} />)}
                  {openedSupplierPopup ? (
                    <SupplierCardMapPopup hit={openedSupplierPopup} key={`${openedSupplierPopup.objectID}-popup`} />
                  ) : null}
                </>
              )}
            </GeoSearch>) : null}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    searchSession: state.app.searchSession,
    searchLocation: state.app.searchLocation,
    cartItems: state.app.cartItems,
    openedSupplierPopup: state.app.openedSupplierPopup
  }
}

export { ResultsMap }

export default connect(mapStateToProps)(ResultsMap)
