import React, { Component } from 'react'
import ErrorPage from '../ErrorPage/ErrorPage'
class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError (error) {
    return { hasError: true, error: error }
  }

  componentDidCatch (error, errorInfo) {
    console.error('error: ', error)
    console.error('errorInfo: ', errorInfo)
  }

  render () {
    if (this.state.hasError) {
      return (
        <ErrorPage />
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
