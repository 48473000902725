import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'

function ConfirmModal ({
  title,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  children,
  className,
  isOpened,
  confirmColor
}) {
  const { t } = useTranslation()

  const componentClassName = className || 'confirm-modal'

  const handleConfirmClick = (callback) => {
    handleButtonClick(onConfirm)
  }
  const handleCancelClick = (callback) => {
    handleButtonClick(onCancel)
  }

  const handleButtonClick = (callback) => {
    if (callback) callback()
  }

  return (
    <Modal
      isOpen={isOpened}
      toggle={handleCancelClick}
      className={componentClassName}
      zIndex='999999'
      contentClassName='rounded-0'
    >
      {title ? (
        <ModalHeader toggle={handleCancelClick}>{title}</ModalHeader>
      ) : null}
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button
          className={`${componentClassName}__confirm-button`}
          color={confirmColor || 'primary'}
          onClick={handleConfirmClick}
        >
          {confirmLabel || t('confirm')}
        </Button>
        <Button
          className={`${componentClassName}__cancel-button`}
          color='secondary'
          onClick={handleCancelClick}
        >
          {cancelLabel || t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function AddSupplierModal ({
  title,
  onConfirm,
  onCancel,
  children,
  isOpened,
  isDisabled
}) {
  const { t } = useTranslation()

  const componentClassName = 'confirm-modal'

  return (
    <Modal
      isOpen={isOpened}
      toggle={onCancel}
      className={componentClassName}
      zIndex='999999'
      contentClassName='rounded-0'
    >
      {title ? <ModalHeader toggle={onCancel}>{title}</ModalHeader> : null}
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <ButtonWithIcon
          labelColor='#FFFFFF'
          iconColor='#FFFFFF'
          padding='4px 8px'
          icon='fa fa-shopping-bag'
          label={t('add_to_wallet')}
          className='client-theme--bg'
          onClick={onConfirm}
          disabled={isDisabled}
        />
      </ModalFooter>
    </Modal>
  )
}

export { ConfirmModal, AddSupplierModal }
