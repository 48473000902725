export const cloudinaryImageUrl = (cloudinaryId) => (
  /* Note: this is ... whatever.
   * Add some magic to adapt to the displayable size.
   * */
  cloudinaryId
    ? `https://res.cloudinary.com/mice-portal/w_400,h_250,f_auto,c_thumb,g_auto/${cloudinaryId}.jpg`
    : null
)

export const cloudinaryFullImageUrl = (cloudinaryId) => (
  cloudinaryId
    ? `https://res.cloudinary.com/mice-portal/f_auto/${cloudinaryId}.jpg`
    : null
)
