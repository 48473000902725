import React from 'react'
import './MenuCartItem.scss'
import PropTypes from 'prop-types'
import { hasValidContracts, getLogoUrl } from '../../shared/utils'
import SuppliersIndexParams from '../../shared/suppliersIndexParams'
import { withTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'
import { removeFromCart } from '../../redux/actions'
import { connect } from 'react-redux'

class MenuCartItem extends React.Component {
  render () {
    const { t } = this.props
    const suppliersIndexParams = new SuppliersIndexParams(this.props.searchParams)
    const projectStartDateUnix = suppliersIndexParams.getStartDateUnix()

    return (
      <>
        <li>
          <div className='c-cart-menu-close'>
            <button tabIndex='0' onClick={() => this.props.removeFromCart(this.props.item)} className='defaultButton' aria-label={t('aria_labels.delete_supplier')}>
              <i style={{ fontSize: '1.5rem' }} className='fa fa-trash' />
            </button>
          </div>
          <img
            src={getLogoUrl(this.props.item)}
            alt={this.props.item.name}
          />
          <div className='c-cart-menu-content'>
            <Link tabIndex='0' to={`/hotel/${this.props.item.objectID}`}>
              <div className='c-item-name c-font-sbold menuCartItemTitle'>{this.props.item.name}</div>
            </Link>

            {this.props.item.city && this.props.item.post_code ? (
              <div
                style={{ marginTop: '15px' }}
                className='menuCartItemLocation'
              >{`${this.props.item.post_code} ${this.props.item.city}`}
              </div>
            ) : null}
            {this.props.item.deliverable_types && this.props.item.deliverable_types.length > 0 ? (
              <div
                style={{
                  marginTop: '10px',
                  width: '100%',
                  display: 'block'
                }}
              >
                {this.props.item.selected_deliverable_types && this.props.item.selected_deliverable_types.length > 0 ? (
                  this.props.item.selected_deliverable_types.map((deliverableType, idx) => {
                    return (
                      <span
                        key={idx}
                        className='label c-font-slim labelPrimary supplierTypeLabel inline-block float-left client-theme--bg'
                      >
                        {deliverableType}
                      </span>
                    )
                  })
                ) : (
                  this.props.item.deliverable_types.map((deliverableType, idx) => {
                    return (
                      <span
                        key={idx}
                        className='label c-font-slim labelPrimary supplierTypeLabel inline-block float-left client-theme--bg'
                      >
                        {deliverableType}
                      </span>
                    )
                  })
                )}
                {hasValidContracts(this.props.item.contracts, projectStartDateUnix) ? (
                  <span
                    key='contract'
                    className='label c-font-slim labelPrimary supplierTypeLabel inline-block float-left client-theme--bg'
                  >
                    {t('contract')}
                  </span>
                ) : null}
              </div>
            ) : null}
          </div>
        </li>
      </>
    )
  }
}

MenuCartItem.propTypes = {
  item: PropTypes.object,
  removeFromCart: PropTypes.func
}

const mapDispatchToProps = dispatch => {
  return {
    removeFromCart: payload => dispatch(removeFromCart(payload))
  }
}

export default withRouter(connect(null, mapDispatchToProps)(withTranslation()(MenuCartItem)))
