import React from 'react'
import SupplierCardList from '../SupplierCardList/SupplierCardList'
import MapWithListOfSuppliers from '../MapWithListOfSuppliers/MapWithListOfSuppliers'
import { Row, Col } from 'reactstrap'
import BannerAdExtractor from '../BannerAd/BannerAdExtractor'
import { Index, connectStats } from 'react-instantsearch-dom'
import { SearchContext } from '../../contexts/SearchContext'
import SupplierFilters from './SupplierFilters'

/* The purpose of this component is to pass the hits counter to the context, so
 * that other objects - maybe upper in the component tree - can read it.
 *
 * (TODO: How can I pass the name of the setter, which I want from the context?
 * If I could do this, then I could reuse this component also in MomentsResults)
 **/
const CounterSnoop = connectStats(({ nbHits }) => {
  const { setSupplierHitsCount } = React.useContext(SearchContext)

  // Not sure about useLayoutEffect - it may be better, right? This update will trigger context update, and by it another re-render.
  React.useEffect(() => { setSupplierHitsCount(nbHits) }, [nbHits])

  return null
})

function SupplierResults ({ isMapView, changeResultsFilter, onToggleMapView, queryParams, searchLocation, activeFilter }) {
  return (
    <BannerAdExtractor position='results'>
      <div className='containerFluid results' style={{ paddingRight: '0px', paddingLeft: '20px' }}>
        <Index
          indexId='supplier-results'
          indexName={window && window.ALGOLIA_INDEX_NAME}
        >
          <CounterSnoop />
          <SupplierFilters
            isMapView={isMapView}
            changeResultsFilter={changeResultsFilter}
            onToggleMapView={onToggleMapView}
            activeFilter={activeFilter}
          />
          {isMapView ? (
            <MapWithListOfSuppliers
              searchLocation={searchLocation}
              queryParams={queryParams}
            />
          ) : (
            <Row className='result-grid-nomap ml-0 mr-0'>
              <Col xs={12}>
                <SupplierCardList />
              </Col>
            </Row>
          )}
        </Index>
      </div>
    </BannerAdExtractor>
  )
}

export default SupplierResults
