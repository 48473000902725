import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

class ProtectedRoute extends Component {
  render () {
    const {
      component: Component,
      ...otherProps
    } = this.props
    return (
      <Route
        {...otherProps} render={props => (
          <section>
            {this.props.isLoggedIn ? (
              <Component {...otherProps} {...props} />
            ) : (
              <>
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: this.props.location }
                  }}
                  push
                />
              </>
            )}
          </section>
        )}
      />
    )
  }
}

ProtectedRoute.propTypes = {
  render: PropTypes.func,
  isLoggedIn: PropTypes.bool
}

export default ProtectedRoute
