import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { withTranslation } from 'react-i18next'
import { languageSettings } from '../../LanguageSettings.js'
import queryString from 'qs'
import { withRouter } from 'react-router-dom'
import { extractQueryParams } from '../../services/urlStateParser'
import './LanguageSelector.scss'

class LanguageSelector extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showMenu: false
    }
    this.isLocaleActive = this.isLocaleActive.bind(this)
    this.changeLocale = this.changeLocale.bind(this)
    this.handleShowMenu = this.handleShowMenu.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  isLocaleActive (locale) {
    return this.props.i18n.language === locale
  };

  changeLocale (locale) {
    const queryParams = extractQueryParams(this.props)

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString.stringify({ ...queryParams, locale })
    })

    document.documentElement.lang = locale
  };

  handleShowMenu () {
    this.setState({ showMenu: true })
  }

  handleBlur (e) {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      this.setState({ showMenu: false })
    }
  }

  render () {
    const { t, i18n } = this.props

    return (
      <ul onBlur={this.handleBlur} ref={localeMenuNode => (this.localeMenuNode = localeMenuNode)} className='c-ext c-theme-ul client-theme--font-color' style={{ position: 'relative', top: '3px' }}>
        <li className='c-lang dropdown c-last'>
          <a onFocus={this.handleShowMenu} tabIndex='0' className='client-theme--font-color' aria-label={t('lang_select')}>
            {i18n.language} <i className='fal fa-angle-down' style={{ fontSize: '1.15rem' }} />
          </a>
          <ul
            className={`dropdown-menu pull-right locale-dropmenu ${this.state.showMenu ? 'show-menu' : ''}`}
            role='menu'
          >
            {languageSettings.languageList.map((supportedLanguage, index) => {
              const itemClass = `language-selector__item ${
                this.isLocaleActive(supportedLanguage) ? 'language-selector__item--active' : ''
              }`
              return (
                <li key={index} className={itemClass}>
                  <button tabIndex='0' onClick={() => this.changeLocale(supportedLanguage)}>
                    <span aria-hidden='true'>
                      <ReactCountryFlag
                        code={languageSettings.languageFlag(supportedLanguage)}
                        styleProps={{
                          fontSize: '14px',
                          marginRight: '4px'
                        }}
                      />
                    </span>
                    {languageSettings.languageLabel(supportedLanguage)}
                  </button>
                </li>
              )
            })}
          </ul>
        </li>
      </ul>
    )
  }
}

export default withRouter(withTranslation()(LanguageSelector))
