import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import CityPoiCard from '../CityPoiCard/CityPoiCard'

class CityPoiList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      cityPois: []
    }
  }

  componentDidMount () {
    this.fetchCityPoiList()
  }

  componentDidUpdate (prevProps) {
    if (this.props.searchLocation && prevProps.searchLocation && this.props.searchLocation !== prevProps.searchLocation) {
      this.setState({
        cityPois: []
      }, this.fetchCityPoiList)
    }
  }

  fetchCityPoiList () {
    const filters = [
      `filters[lat]=${this.props.searchLocation.lat}`,
      `filters[lng]=${this.props.searchLocation.lng}`
    ].join('&')

    axios
      .get(`api/v2/city_pois.json?${filters}`)
      .then(response => {
        if (response.data) {
          this.setState({
            cityPois: response.data
          })
        }
      })
  }

  render () {
    const { searchLocation, t } = this.props

    return (
      searchLocation && this.state.cityPois.length > 0 ? (
        <div className='c-container c-bg-grey'>
          <div className='containerFluid'>
            <Row>
              <Col md={12}>
                <div className='c-content-title-1 c-center'>
                  <h3 className='c-font-uppercase'>
                    {t('something_in_location', { something: t('destination'), location: searchLocation.name })}
                  </h3>
                  <div className='c-line-center client-theme--bg' />
                </div>
              </Col>
            </Row>
            <Row className='justify-content-center pb-3'>
              {this.state.cityPois.map((poi) => (
                <Col xl={2} md={3} key={poi.id} className='CityPoiCol'>
                  <CityPoiCard poi={poi} />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      ) : null
    )
  }
}

CityPoiList.propTypes = {
  searchLocation: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    searchLocation: state.app.searchLocation
  }
}

export default connect(mapStateToProps)(withTranslation()(CityPoiList))
