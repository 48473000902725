import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import { wrapAsAnInstanceOf } from '../../config/ability'
import './SupplierNoteList.scss'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import Can from '../Layout/Can'
import axios from 'axios'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'

class SupplierNoteList extends React.Component {
  constructor (props) {
    super(props)

    const { pathname, search } = props.location
    this.returnPath = encodeURIComponent(pathname + search)
    this.handleOnClickEdit = this.handleOnClickEdit.bind(this)
    this.handleOnClickDelete = this.handleOnClickDelete.bind(this)
    this.formatAsNote = this.formatAsNote.bind(this)
    this.goTo = this.goTo.bind(this)
  }

  handleOnClickNew () {
    const { user, supplierId } = this.props
    let path

    if (user.is_client_user) {
      path = `/client/${user.client_user_id}/notes/new?return_to=supplier&path=${this.returnPath}&supplier_id=${supplierId}`
    } else if (user.roles_symbols.includes('employee')) {
      path = `/admin/suppliers/${supplierId}/notes/new`
    }

    this.goTo(path)
  }

  handleOnClickEdit (note) {
    const user = this.props.user
    let path

    if (user.is_client_user) {
      path = `/client/${user.client_user_id}/notes/${note.id}/edit?return_to=supplier&path=${this.returnPath}`
    } else if (user.roles_symbols.includes('employee')) {
      path = `/admin/notes/${note.id}/edit?return_to=supplier&path=${this.returnPath}`
    }

    this.goTo(path)
  }

  handleOnClickDelete (note) {
    const { user, t } = this.props
    if (window.confirm(t('supplier.home.tabs.details.notes.are_you_sure'))) {
      let path

      if (user.is_client_user) {
        path = `/client/${user.client_user_id}/notes/${note.id}.json`
      } else if (user.roles_symbols.includes('employee')) {
        path = `/admin/notes/${note.id}.json`
      }

      axios
        .delete(path)
        .then((response) => {
          toast('Note successfully deleted', { type: 'success' })
          window.location.reload()
        })
        .catch((err) => {
          console.log(err)
          toast('Note cannot be deleted', { type: 'error' })
        })
    }
  }

  goTo (path) {
    if (path) {
      window.location.href = path
    }
  }

  formatAsNote (note) {
    return wrapAsAnInstanceOf(note, 'Note')
  }

  canAccessNoteList (user) {
    return user && (user.is_client_user || (user.roles_symbols && user.roles_symbols.includes('employee')))
  }

  render () {
    const { t, user, notes } = this.props

    return (
      this.canAccessNoteList(user) ? (
        <div className='container'>
          <Row className='mt-3 mb-3'>
            <Col className='client_note_admin c-bg-white p-4 supplier-feature-box-round' md={12}>
              <h4>Admin Section</h4>
              {notes.length > 0 ? (
                <>
                  <p>Current Comments</p>
                  <table className='mt-3 table supplerNoteTable'>
                    <thead>
                      <tr>
                        <th>{t('supplier.home.tabs.details.notes.type')}</th>
                        <th>{t('supplier.home.tabs.details.notes.updated_at')}</th>
                        <th>{t('supplier.home.tabs.details.notes.valid_until')}</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.notes
                        .map(this.formatAsNote)
                        .map((note, idx) => (
                          <tr key={`note_${idx}`}>
                            <td>{t(`supplier.home.tabs.details.notes.${note.type}`)}</td>
                            <td>{`${note.updated_at} (${note.updater})`}</td>
                            <td>{note.permanent ? t('supplier.home.tabs.details.notes.always') : note.valid_until}</td>
                            <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Can perform='manage' on={note}>
                                <div className='m-3'>
                                  <ButtonWithIcon
                                    label={t('supplier.home.tabs.details.notes.edit')}
                                    background='#A3C95E'
                                    labelColor='#FFFFFF'
                                    onClick={() => this.handleOnClickEdit(note)}
                                  />
                                </div>

                                <div className='m-3'>
                                  <ButtonWithIcon
                                    label={t('supplier.home.tabs.details.notes.delete')}
                                    background='#EF7E71'
                                    labelColor='#FFFFFF'
                                    onClick={() => this.handleOnClickDelete(note)}
                                  />
                                </div>
                              </Can>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <div className='mt-3 mb-3'>
                  <p className='pb-3'>
                    {t('supplier.home.tabs.details.notes.no_notes')}
                  </p>
                </div>
              )}
              <Col className='pl-0' md={3}>
                <Can perform='create' on='Note'>
                  <ButtonWithIcon
                    label={t('supplier.home.tabs.details.notes.create')}
                    background='#A3C95E'
                    labelColor='#FFFFFF'
                    onClick={() => this.handleOnClickNew()}
                  />
                </Can>
              </Col>
            </Col>
          </Row>
        </div>
      ) : null
    )
  }
}

SupplierNoteList.propTypes = {
  t: PropTypes.func,
  user: PropTypes.object,
  supplierId: PropTypes.number
}

const mapStateToProps = (state) => ({
  user: state.app.user
})

export default connect(mapStateToProps)(withTranslation()(withRouter(SupplierNoteList)))
