/* eslint-disable */
//= require js.cookie
'use strict';
var windowIsTurbolinked = 'Turbolinks' in window;
var miceCookie = {
  init: function() {
    var cookies = {
      "requierdOk": document.querySelector('.js-cookies-requierd-ok'),
      "monitoringOk": document.querySelector('.js-cookies-monitoring-ok'),
      "chatOk": document.querySelector('.js-cookies-chat-ok'),
      "toursOk": document.querySelector('.js-cookies-tours-ok'),
      "marketingOk": document.querySelector('.js-cookies-marketing-ok')
    }
  for (var key in cookies) {
    this.addListener(key, cookies[key]);  
  }
},
  addListener: function(key, target) {
    if (target) {
      // Support for IE < 9
      if (target.attachEvent) {
        target.attachEvent('onclick', this.toggleCookie(key, target));
      } else {
        target.addEventListener('click', this.toggleCookie.bind(this, key, target) , false);
      }
    }
  },
  toggleCookie: function(key, target) {
    if ( key === "requierdOk") {
      this.updateCookie(target, 'requierd_cookies');
    } else if ( key === "monitoringOk") {
      this.updateCookie(target, 'monitoring_cookies');
    } else if (key === "chatOk") {
      this.updateCookie(target, 'chat_cookies');
    } else if (key === "toursOk") {
      this.updateCookie(target, 'tours_cookies');
    } else if (key === "marketingOk") {
      this.updateCookie(target, 'marketing_cookies');
    }
  },
  updateCookie: function(target, cookie_name) {
    var isSecure = location.protocol === 'https:';
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    if (!target.previousElementSibling.checked) {
      var expireDateSet = new Date(year + 1, month, day);
      Cookies.set(cookie_name, true, { path: '/', expires: expireDateSet, secure: isSecure });
    } else {
      var expireDateRemove = new Date(year - 2 , month, day);
      Cookies.set(cookie_name, true, { path: '/', expires: expireDateRemove, secure: isSecure });
    }
  }
};

(function() {
  function eventName(fallback) {
    return windowIsTurbolinked ? 'turbolinks:load' : fallback
  }
  var isCalled = false;
  function isReady() {
    // return early when cookiesEu.init has been called AND Turbolinks is NOT included
    // when Turbolinks is included cookiesEu.init has to be called on every page load
    if (isCalled && !windowIsTurbolinked) {
      return
    }
    isCalled = true;
    miceCookie.init();
  }
  if (document.addEventListener) {
    return document.addEventListener(eventName('DOMContentLoaded'), isReady, false);
  }
  // Old browsers IE < 9
  if (window.addEventListener) {
    window.addEventListener(eventName('load'), isReady, false);
  } else if (window.attachEvent) {
    window.attachEvent(eventName('onload'), isReady);
  }
})();
/* eslint-enable */
