import React from 'react'
import ResultsMap from '../ResultsMap/ResultsMap'
import SupplierMapCardList from '../SupplierMapCardList/SupplierMapCardList'
import { Row, Col } from 'reactstrap'
import { MapWithSupplierMarkersLoader } from '../../shared/contentLoaders'
import './MapWithListOfSuppliers.scss'
import _ from 'lodash'

class MapWithListOfSuppliers extends React.Component {
  constructor (props) {
    super(props)

    this.state = { mapWidth: 0, reloadMap: false, highlightedSupplier: null }
    this.timer = null
    this.mapWrapper = React.createRef()
    this.updateMapWidth = this.updateMapWidth.bind(this)
    this.updateMapWidthThrottled = _.throttle(this.updateMapWidth, 300)
    this.handleHighlightSupplier = this.handleHighlightSupplier.bind(this)
  }

  handleMapClick () {
    window.scrollTo(0, 0)
  }

  componentDidMount () {
    this.updateMapWidth()
    window.addEventListener('resize', this.updateMapWidthThrottled)
  }

  componentDidUpdate (prevProps) {
    if ((prevProps.hits !== this.props.hits) || (prevProps.searchLocation !== this.props.searchLocation)) {
      this.setState({ reloadMap: true }, () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => { this.setState({ reloadMap: false }) }, 500)
      })
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateMapWidthThrottled)
    this.timer = null
  }

  updateMapWidth () {
    if (this.mapWrapper.current) {
      const mapWidth = this.mapWrapper.current.offsetWidth

      if (this.state.mapWidth !== mapWidth) {
        /* This workaround is needed because there s a bug on Google Maps that zoom outs every time we move the map.
           The issue is caused because the map container gets float sizes when
           we use percentage and it causes the map to behave weirdly.
           This implementation just ensure that we have no floats for the map width.
           https://projekte.miceportal.de/browse/MP-44215 */

        this.setState({ mapWidth: mapWidth })
      }
    }
  }

  handleHighlightSupplier (supplier) {
    this.setState({ highlightedSupplier: supplier })
  }

  render () {
    return (
      <Row className='ml-0 mr-0'>
        <Col md={{ size: 7, order: 1 }} xs={{ size: 12, order: 2 }} className='supplier-card-list-wrapper d-sm-block'>
          <SupplierMapCardList onHighlightSupplier={this.handleHighlightSupplier} queryParams={this.props.queryParams} searchLocation={this.props.searchLocation} />
        </Col>
        <Col className='map-container' md={{ size: 5, order: 2 }} xs={{ size: 12, order: 1 }} onClick={this.handleMapClick}>
          <div className='map-wrapper' ref={this.mapWrapper}>
            <div className='h-100' style={{ width: `${this.state.mapWidth}px` }}>
              {!this.state.reloadMap
                ? <ResultsMap highlightedSupplier={this.state.highlightedSupplier} /> : <MapWithSupplierMarkersLoader />}
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default MapWithListOfSuppliers
