import React from 'react'
import PropTypes from 'prop-types'
import './SectionItem.scss'
import { withTranslation } from 'react-i18next'
import defaultImage from '../../assets/images/image-placeholder-square.jpg'

class SectionItem extends React.Component {
  render () {
    const { title, image, imageWebp } = this.props
    return (
      <div className='c-start-page-style' onClick={this.props.onClick}>
        <div style={{ overflow: 'hidden', maxHeight: '80%' }}>
          {
            imageWebp ? (
              <picture>
                <source type='image/webp' srcSet={imageWebp} />
                <img src={image || defaultImage} style={{ width: '100%', height: 'auto' }} alt={title} />
              </picture>
            ) : (
              <img src={image || defaultImage} style={{ width: '100%', height: 'auto' }} alt={title} />
            )
          }
        </div>
        <div style={{ padding: '10px' }}>
          <h3>
            {title}
          </h3>
        </div>
      </div>
    )
  }
}

SectionItem.propTypes = {
  title: PropTypes.string.isRequired,
  filters: PropTypes.string,
  aroundLatLng: PropTypes.string,
  image: PropTypes.string
}
export default (withTranslation()(SectionItem))
