import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Stats } from 'react-instantsearch-dom'
import PropTypes from 'prop-types'
import { setBreadcrumbHistory } from '../../redux/actions'
import { SearchContext } from '../../contexts/SearchContext'

function HitsCounter (pathname) {
  const { momentHitsCount } = React.useContext(SearchContext)
  if (pathname === 'moments') {
    return ` (${momentHitsCount})`
  } else {
    // Here we could probably alse use the same thingy as momentHitsCount
    // above, but this old code also works OK.
    return (
      <Stats
        translations={{
          stats (nbHits) {
            return `(${nbHits})`
          }
        }}
      />
    )
  }
}

const PATHS = {
  '': {
    name: 'home',
    subPaths: ['results', 'cart', 'supplier']
  },
  results: {
    name: 'search_results',
    subPaths: ['cart', 'supplier', 'moments']
  },
  cart: {
    name: 'cart',
    subPaths: ['supplier']
  },
  supplier: {
    name: 'supplier',
    subPaths: ['cart', 'moments']
  },
  imprint: {
    name: 'imprint',
    subPaths: ['home']
  },
  terms: {
    name: 'terms_and_conditions',
    subPaths: ['home']
  },
  'get-listed': {
    name: 'Get Listed',
    subPaths: ['home']
  },
  'data-privacy': {
    name: 'privacy_policy',
    subPaths: ['home']
  },
  moments: {
    name: 'moments',
    subPaths: ['moments', 'supplier']
  }

}

const supplierPaths = ['catering', 'convention-bureau', 'facility', 'hotel', 'location', 'personal-service', 'event-gastronomy', 'transfer', 'supplier-home']

class Breadcrumb extends Component {
  constructor (props) {
    super(props)

    this.getBasePath = this.getBasePath.bind(this)
    this.rebuildHistory = this.rebuildHistory.bind(this)
    this.rebuildOwnHistory = this.rebuildOwnHistory.bind(this)
    this.canAddToHistory = this.canAddToHistory.bind(this)
    this.emptyHistory = this.emptyHistory.bind(this)
    this.isASubPathOfLastLocation = this.isASubPathOfLastLocation.bind(this)
    this.getPageName = this.getPageName.bind(this)
    this.hasAtleastOneNamedCrumb = this.hasAtleastOneNamedCrumb.bind(this)
    this.setPageReloadListener = this.setPageReloadListener.bind(this)
    this.removePageReloadListener = this.removePageReloadListener.bind(this)
  }

  rebuildOwnHistory (location) {
    const stripTrailingSlash = (str) => {
      if (str.length > 1 && str.endsWith('/')) {
        return str.slice(0, -1)
      } else {
        return str
      }
    }
    const savedHistory = JSON.parse(window.localStorage.getItem('breadcrumbHistory'))

    if (savedHistory && savedHistory.page) {
      const origin = window.location.origin
      const locationPage = stripTrailingSlash(location.pathname) + location.search
      const samePath = savedHistory.page.replace(origin, '').indexOf(locationPage) === 0

      // locationPage length check to avoid some corner cases in homepage when pathname is "/"
      if (samePath && locationPage.length > 1) {
        this.props.setBreadcrumbHistory(savedHistory.history)
        return
      }
    }

    this.props.setBreadcrumbHistory(this.rebuildHistory(location))
  }

  rebuildHistory (currentLocation) {
    let history = this.props.breadcrumbHistory || []
    const basePath = this.getBasePath(currentLocation)
    const pathPositionInHistory = history.findIndex(h => h.pathname === basePath)

    const currentPathData = {
      pathname: basePath,
      name: this.getPageName(basePath),
      url: `${currentLocation.pathname}${currentLocation.search}`
    }

    if (pathPositionInHistory > -1) {
      if (this.isASubPathOfLastLocation(basePath)) {
        history.splice(pathPositionInHistory, 1)
        history.push(currentPathData)
      } else {
        history = history.slice(0, pathPositionInHistory)
        history.push(currentPathData)
      }
    } else if (this.canAddToHistory(basePath)) {
      history.push(currentPathData)
    } else {
      history = [currentPathData]
    }

    return history
  }

  canAddToHistory (pathname) {
    return this.emptyHistory() || this.isASubPathOfLastLocation(pathname)
  }

  emptyHistory () {
    return (this.props.breadcrumbHistory || []).length < 1
  }

  isASubPathOfLastLocation (pathname) {
    const ownHistory = this.props.breadcrumbHistory
    const lastHistoryItem = ownHistory[ownHistory.length - 1]
    const lastPath = lastHistoryItem && PATHS[lastHistoryItem.pathname]

    return lastPath && lastPath.subPaths.includes(pathname)
  }

  getBasePath (currentLocation) {
    const basePath = currentLocation.pathname.substr(1).split('/')[0]
    if (supplierPaths.includes(basePath)) {
      return 'supplier'
    }

    return basePath
  }

  getPageName (pathname) {
    let name = PATHS[pathname] ? this.getPathNameTranslation(pathname) : ''

    switch (pathname) {
      case 'supplier':
        name = (this.props.supplier && this.props.supplier.name) || name
        break
      default:
        break
    }

    return name
  }

  getPathNameTranslation (pathname) {
    const t = this.props.t
    if (PATHS[pathname].name === 'supplier') {
      return t('breadcrumb_supplier')
    } else {
      return t(PATHS[pathname].name)
    }
  }

  hasAtleastOneNamedCrumb () {
    return (this.props.breadcrumbHistory || []).some((crumb) => !!this.getPageName(crumb.pathname))
  }

  setPageReloadListener () {
    window.localStorage.removeItem('breadcrumbHistory')
    window.onbeforeunload = (evt) => {
      window.localStorage.setItem('breadcrumbHistory', JSON.stringify({
        page: evt.target.URL,
        history: this.props.breadcrumbHistory
      }))
    }
  }

  removePageReloadListener () {
    window.onbeforeunload = null
    window.localStorage.removeItem('breadcrumbHistory')
  }

  componentDidUpdate (prevProps) {
    if (prevProps.supplier !== this.props.supplier ||
      prevProps.searchLocation !== this.props.searchLocation ||
      prevProps.location.search !== this.props.location.search
    ) {
      this.rebuildOwnHistory(this.props.location)
    }
  }

  componentWillUnmount () {
    this.removePageReloadListener()
  }

  componentDidMount () {
    this.rebuildOwnHistory(this.props.location)
    this.setPageReloadListener()
  }

  render () {
    const { breadcrumbHistory: crumbs } = this.props

    return (
      <ul className='c-page-breadcrumbs c-theme-nav c-fonts-regular breadcrumbWrapper client-theme--color mr-2'>
        {this.hasAtleastOneNamedCrumb() && (
          <>
            {crumbs.map(({ url, pathname, name }, index) => (
              url && (
                <Fragment key={`locationId${index}`}>
                  <li className='c-state_active client-theme--color'>
                    {index === crumbs.length - 1 ? (
                      <span>
                        {name || this.getPageName(pathname)}
                        <HitsCounter pathname={pathname} />
                      </span>
                    ) : (
                      <Link to={url}>
                        {name || this.getPageName(pathname)}
                      </Link>)}
                  </li>
                  {crumbs.length - 1 !== index ? <li>/</li> : null}
                </Fragment>
              )
            ))}
          </>
        )}
      </ul>
    )
  }
}

Breadcrumb.propTypes = {
  location: PropTypes.object,
  supplier: PropTypes.object,
  t: PropTypes.func,
  searchLocation: PropTypes.object,
  history: PropTypes.object,
  setBreadcrumbHistory: PropTypes.func,
  breadcrumbHistory: PropTypes.array
}

const mapStateToProps = state => {
  return {
    searchLocation: state.app.searchLocation,
    supplier: state.app.supplier,
    breadcrumbHistory: state.app.breadcrumbHistory
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setBreadcrumbHistory: payload => dispatch(setBreadcrumbHistory(payload))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(Breadcrumb)))
