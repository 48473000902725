import React from 'react'
import { withRouter } from 'react-router-dom'
import './Advertisement.scss'
import { withTranslation } from 'react-i18next'
import { getObjectFromAlgolia } from '../../services/algolia'
import AddToCart from '../Cart/AddToCart'
import SupplierMarkup from '../SupplierMarkup/SupplierMarkup'
import { getSupplierURL, logAdvertisementEvent } from '../../shared/utils'
import { extractQueryParams } from '../../services/urlStateParser'

class Advertisement extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      hit: null
    }

    this.fetchSupplierAlgoliaHit = this.fetchSupplierAlgoliaHit.bind(this)
    this.handleMoreInfoButtonClick = this.handleMoreInfoButtonClick.bind(this)
  }

  componentDidMount () {
    this.fetchSupplierAlgoliaHit(this.props.supplier.id)
  }

  componentDidUpdate (prevProps) {
    if (this.props.supplier.id !== prevProps.supplier.id) {
      this.fetchSupplierAlgoliaHit(this.props.supplier.id)
    }
  }

  fetchSupplierAlgoliaHit (supplierID) {
    getObjectFromAlgolia(supplierID)
      .then(hit => {
        this.setState(() => ({
          hit: hit
        }))
      })
      .catch(err => {
        console.log(err)
        this.setState({
          hit: null
        })
      })
  }

  handleMoreInfoButtonClick () {
    logAdvertisementEvent(
      'city_ad_clicked',
      this.props.advertisementID,
      this.props.supplier.id
    )

    const queryParams = extractQueryParams(this.props)
    const hit = this.state.hit
    const url = getSupplierURL(queryParams, hit.objectID, hit.deliverable_types)
    this.props.history.push(url)
  }

  render () {
    const { t } = this.props
    const supplier = this.props.supplier
    const hit = this.state.hit
    if (hit) {
      return (
        <div>
          <div
            key={this.props.key}
            className='c-content-product-3 c-bs-grid-reset-space'
          >
            <div className='row'>
              {/* Supplier Details */}
              <div className='col-md-6 col-sm-6'>
                <div
                  className='c-wrapper c-bg-regular'
                  style={{ height: '280px' }}
                >
                  <div className='details-header client-theme--bg'>
                    <h3 className='c-title c-font-30 client-theme--font-color'>
                      {hit.name}
                    </h3>
                  </div>
                  <p
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '3px',
                      fontSize: '16px'
                    }}
                    className='c-font-black'
                  >
                    <i className='fa fa-map-marker' aria-hidden='true' />
                    {hit.post_code && hit.city
                      ? hit.street + ', ' + hit.post_code + ' ' + hit.city
                      : hit.street}
                  </p>

                  <p
                    className='desc-result'
                    style={{
                      borderTop: '1px solid rgba(255, 255, 255, 0.2)',
                      borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
                      color: '#000'
                    }}
                  >
                    {' '}
                    {t('number_of_conference_rooms')}:{' '}
                    {hit.capacity_number_of_conference_rooms + ' '}
                    | {t('max_conference_capacity')}:{' '}
                    {hit.capacity_max_conference_capacity + ' '}|{' '}
                    {t('number_of_rooms')}:{' '}
                    {hit.number_of_rooms}
                  </p>

                  <p
                    className='desc-result'
                    style={{
                      borderTop: '1px solid rgba(255, 255, 255, 0.2)',
                      borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
                      color: '#000'
                    }}
                  >
                    {hit.distance_airport ? (
                      <span>
                        <i className='fa fa-plane' />{' '}
                        {hit.distance_airport.toFixed(0)}km&nbsp;&nbsp;&nbsp;
                      </span>
                    ) : null}
                    {hit.distance_station ? (
                      <span>
                        <i
                          className='fa fa-train'
                          style={{ marginLeft: '5px' }}
                          aria-hidden='true'
                        />{' '}
                        {hit.distance_station.toFixed(0)}km&nbsp;&nbsp;&nbsp;
                      </span>
                    ) : null}
                    {hit.distance_city ? (
                      <span>
                        <i
                          className='fa fa-bullseye'
                          style={{ marginLeft: '5px' }}
                          aria-hidden='true'
                        />{' '}
                        {hit.distance_city.toFixed(0)}km&nbsp;&nbsp;&nbsp;
                      </span>
                    ) : null}

                    {hit.distance_main_road ? (
                      <span>
                        <i
                          className='fa fa-road'
                          style={{ marginLeft: '5px' }}
                          aria-hidden='true'
                        />{' '}
                        {hit.distance_main_road.toFixed(0)}km&nbsp;&nbsp;&nbsp;
                      </span>
                    ) : null}
                    {hit.distance_public_transport ? (
                      <span>
                        <i
                          className='fa fa-bus'
                          style={{ marginLeft: '5px' }}
                          aria-hidden='true'
                        />{' '}
                        {hit.distance_public_transport.toFixed(0)}km&nbsp;&nbsp;&nbsp;
                      </span>
                    ) : null}
                  </p>
                  <p className='adSupplierMarkup'>
                    <SupplierMarkup
                      feedbackAverage={hit.feedback_average}
                      professional={hit.professional}
                      airplus={hit.airplus}
                      classification={hit.classification}
                      micepoints={hit.micepoints}
                    />
                  </p>
                  <div className='advertismentButtons'>
                    <div className='defaultButton' style={{ borderColor: 'black' }}>
                      <a onClick={this.handleMoreInfoButtonClick}>
                        {t('more_info')}
                      </a>
                    </div>

                    <AddToCart forAdvertisement hit={hit} advertisementID={this.props.advertisementID} />
                  </div>
                </div>
                <p className='sponsored'>
                  Sponsored
                </p>
              </div>

              {/* advertisement image */}
              <div
                className='col-md-6 col-sm-6'
                data-height='height'
                style={{
                  height: '280 px',
                  backgroundImage: 'url(' + supplier.image_url + ')',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              />
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default withRouter(withTranslation()(Advertisement))
