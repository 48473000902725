import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import ButtonWithIcon from '../../../../ButtonWithIcon/ButtonWithIcon'
import axios from 'axios'
import SuccessMessage from '../../../../SuccessMessage/SuccessMessage'
import ValidationErrors from '../../../../ValidationErrors/ValidationErrors'
import { scrollToRef } from '../../../../../shared/utils'
import Label from '../../../../Label/Label'
const _ = require('lodash')

class AlgoliaForm extends Component {
  constructor (props) {
    super(props)

    this.componentRef = React.createRef()

    const { t } = props

    this.state = {
      showSuccessMessage: false,
      isSubmiting: false,
      errors: {},
      supplier: {},
      resetButtonIcon: 'fa fa-refresh',
      resetButtonLabel: t('supplier.edit.button.reset.label'),
      unsavedChanges: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  componentDidMount () {
    const supplier = {}
    supplier.algolia_indexed_at = this.props.supplier.algolia_indexed_at
    this.setState({ supplier })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.supplier !== this.props.supplier) {
      this.initializeStateWithExistingFormData()
    }
  }

  initializeStateWithExistingFormData () {
    if (this.props.supplier) {
      const supplier = {}
      supplier.algolia_indexed_at = this.props.supplier.algolia_indexed_at

      this.setState({ supplier })
    }
  }

  handleChange ({ target }) {
    const value = target.value
    const name = target.name

    this.setState({
      showSuccessMessage: false,
      supplier: {
        ...this.state.supplier,
        [name]: value
      }
    })
  }

  handleReset () {
    const { t } = this.props

    this.setState({
      errors: {},
      resetButtonIcon: 'fa fa-spinner fa-spin',
      resetButtonLabel: t('supplier.edit.button.reset.reseting_label')
    })

    setTimeout(() => this.setState({
      resetButtonIcon: 'fa fa-refresh',
      resetButtonLabel: t('supplier.edit.button.reset.label'),
      unsavedChanges: false
    }), 2000)
  }

  handleSubmit () {
    this.setState({
      errors: {},
      isSubmiting: true,
      showSuccessMessage: false
    }, function () {
      axios
        .post(`/admin/suppliers/${this.props.supplier.id}/algolia_reindex.json`)
        .then(response => {
          scrollToRef(this.componentRef)
          this.setState({ showSuccessMessage: true })
        })
        .catch(error => {
          switch (error.response.status) {
            case 403:
              window.location.href = '/pages/access_denied'
              break
            case 422:
              scrollToRef(this.componentRef)
              this.setState({ errors: error.response.data.errors, isSubmiting: false })
              break
          }
        })
    })
  }

  render () {
    const { t } = this.props

    return (
      <div ref={this.componentRef}>
        <SuccessMessage message={t('algolia_reindexed')} hidden={!this.state.showSuccessMessage} />
        <ValidationErrors validationErrors={this.state.errors} />
        <Row className='m-0 mt-4 mb-4'>
          <Col className='pl-0' xl={4} md={4} sm={8} xs={12}>
            <ButtonWithIcon
              icon={`fa ${this.state.isSubmiting ? 'fa-spinner fa-spin' : 'fa-save'}`}
              label={t('algolia_reindexing')}
              className='client-theme--font-color client-theme--bg'
              onClick={this.handleSubmit}
              disabled={this.state.isSubmiting}
            />
          </Col>
        </Row>
        <Row className='m-0 mt-4 mb-4'>
          <Col className='pl-0' xl={4} md={4} sm={8} xs={12}>
            <Label
              className='algolia_synced_at'
              name={`${t('supplier.admin.settings.algolia_indexed_at')}: ${_.isEmpty(this.state.supplier.algolia_indexed_at) ? '' : this.state.supplier.algolia_indexed_at}`}
              icon='fa fa-clock'
            />
          </Col>
        </Row>
      </div>
    )
  }
}

AlgoliaForm.propTypes = {
  supplier: PropTypes.object.isRequired
}

export default withTranslation()(AlgoliaForm)
