import React from 'react'
import PropTypes from 'prop-types'
import './Heading.scss'
class Heading extends React.Component {
  constructor (props) {
    super(props)
    this.fontSizes = {
      1: '2.558rem',
      2: '2.288rem',
      3: '1.858rem',
      4: '1.571rem',
      5: '1.286rem',
      6: '1.143rem'
    }
    this.headingSize = this.headingSize.bind(this)
    this.headingClassName = this.headingClassName.bind(this)
  }

  headingSize () {
    const availableHeadingSizes = [1, 2, 3, 4, 5, 6]
    if (availableHeadingSizes.indexOf(this.props.size) >= 0) {
      return this.props.size
    } else {
      return 1
    }
  }

  headingClassName () {
    const { uppercase, center, bold } = this.props
    const classNames = ['heading']
    if (uppercase) {
      classNames.push('heading--uppercase')
    }
    if (center) {
      classNames.push('heading--center')
    }
    if (bold) {
      classNames.push('heading--bold')
    }
    return classNames.join(' ')
  }

  render () {
    return (
      <div style={{ fontSize: this.fontSizes[this.headingSize()] }} className={this.headingClassName()}>
        <div className='heading__text'>
          {this.props.children}
        </div>
        <div className='heading__underscore client-theme--bg' />
      </div>
    )
  }
}
Heading.propTypes = {
  size: PropTypes.number,
  uppercase: PropTypes.bool,
  center: PropTypes.bool,
  bold: PropTypes.bool
}
export default Heading
