import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import UploadImage from '../UploadImage/UploadImage'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import CloudinaryUploadWidget from '../UploadImage/CloudinaryUploadWidget'
import { withTranslation } from 'react-i18next'
import './ImagesGalleryManager.scss'

class ImagesGalleryManager extends Component {
  constructor (props) {
    super(props)

    this.state = {
      images: []
    }
    this.cloudinaryUploadWidget = new CloudinaryUploadWidget()

    this.notifyChangeListener = this.notifyChangeListener.bind(this)
    this.isGalleryEmpty = this.isGalleryEmpty.bind(this)
    this.handleAddImage = this.handleAddImage.bind(this)
  }

  componentDidMount () {
    const images = this.props.images || []
    if (images && images.length > 0) {
      images.sort((a, b) => a.element_order - b.element_order)
    }
    this.setState({
      images: [...images]
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.images !== this.props.images) {
      this.setState({ images: [...this.props.images] })
    }
  }

  notifyChangeListener () {
    if (this.props.onChange) {
      const images = this.state.images.filter((image) => image.cloudinary_public_id)

      this.props.onChange({
        target: {
          name: this.props.name,
          value: images
        },
        type: 'imagesGallery'
      })
    }
  }

  handleAddImage () {
    const images = [...this.state.images]
    images.push({ element_order: images.length, cloudinary_public_id: null, url: null })
    this.setState({ images: images }, this.notifyChangeListener)
  }

  removeImage (index) {
    const images = [...this.state.images]
    images[index]._destroy = true
    this.setState({ images: images }, this.notifyChangeListener)
  }

  moveImage (idx, newIdx) {
    if (newIdx >= 0 && newIdx < this.state.images.length) {
      const images = [...this.state.images]
      const otherImage = images[newIdx]

      images[newIdx] = images[idx]
      images[idx] = otherImage

      for (let i = 0; i < images.length; i++) {
        images[i] = { ...images[i], element_order: i + 1 }
      }

      this.setState({ images: [...images] }, this.notifyChangeListener)
    }
  }

  handleImageUploadComplete (index, response) {
    const images = [...this.state.images]
    if (response && response.public_id) {
      if (index !== null && index >= 0) {
        images[index].cloudinary_public_id = response.public_id
        images[index].url = response.url
      } else {
        images.push({ cloudinary_public_id: response.public_id, url: response.url })
      }
      this.setState({ images: images }, this.notifyChangeListener)
    }
  }

  isGalleryEmpty () {
    if (this.state.images && this.state.images.length > 0) {
      return this.state.images.every((image) => image._destroy)
    }

    return true
  }

  maxAmountReached () {
    if (this.state.images && this.props.maxAmount) {
      const reachedLimit = this.state.images.filter((image) => !image._destroy).length >= this.props.maxAmount

      if (reachedLimit) return true
    }

    return false
  }

  render () {
    const { t } = this.props
    const images = this.state.images

    return (
      <div className={this.props.className}>
        <div className='m-0 mt-1 mb-3 upload-image-grid-container'>
          {this.isGalleryEmpty() ? (
            <UploadImage
              label={t('click_here_to_upload')}
              imageUrl=''
              mode='c_fill,g_auto'
              uploadWidget={this.cloudinaryUploadWidget}
              onUploadComplete={response => this.handleImageUploadComplete(null, response)}
            />
          ) : (
            images.map((image, idx) =>
              image._destroy ? null : (
                <UploadImage
                  key={idx}
                  label={t('click_here_to_upload')}
                  imageUrl={image.url ? image.url : null}
                  movable
                  canMoveLeft={idx > 0}
                  canMoveRight={idx < images.length - 1}
                  uploadWidget={this.cloudinaryUploadWidget}
                  onUploadComplete={response => this.handleImageUploadComplete(idx, response)}
                  onRemove={() => this.removeImage(idx)}
                  onMoveLeft={() => this.moveImage(idx, idx - 1)}
                  onMoveRight={() => this.moveImage(idx, idx + 1)}
                />
              )
            )
          )}
        </div>
        <Row className='m-0 mt-3 mb-2 pl-0 pr-4'>
          <Col className='p-0' xl={4} lg={4} md={6} sm={8}>
            <ButtonWithIcon
              className='client-theme--border-color'
              border='1px solid'
              label={t('add_image')}
              icon='fa fa-plus'
              onClick={this.handleAddImage}
              disabled={this.maxAmountReached()}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

ImagesGalleryManager.propTypes = {
  images: PropTypes.array,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func
}

export default withTranslation()(ImagesGalleryManager)
