/* global google */

import React from 'react'
import PropTypes from 'prop-types'
import { withGoogleMaps } from 'react-instantsearch-dom-maps'

class Region extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      polygon: null
    }
  }

  componentDidMount () {
    this.drawRegion()
  }

  componentDidUpdate (prevProps) {
    if (this.props !== prevProps) {
      this.drawRegion()
    }
  }

  componentWillUnmount () {
    this.eraseRegion()
  }

  drawRegion () {
    if (this.props.coordinates) {
      this.eraseRegion()

      const coordinates = this.props.coordinates.split(';').filter(v => v !== '')
      const dots = []

      for (let i = 0; i < coordinates.length; i++) {
        const coords = coordinates[i].split(':')
        dots.push(new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1])))
      }

      const polygon = new google.maps.Polygon({
        paths: dots,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map: this.props.googleMapsInstance
      })

      this.setState({ polygon: polygon })
    }
  }

  eraseRegion () {
    const polygon = this.state.polygon

    if (polygon) {
      polygon.setMap(null)
      this.setState({ polygon: null })
    }
  }

  render () {
    return null
  }
}

Region.propTypes = {
  google: PropTypes.object.isRequired,
  googleMapsInstance: PropTypes.object.isRequired,
  coordinates: PropTypes.string
}

export default withGoogleMaps(Region)
