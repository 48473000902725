import axios, { CancelToken } from 'axios'

let call
export const axiosOnce = (config = {}) => {
  if (call) {
    call.cancel()
  }
  call = CancelToken.source()

  config.cancelToken = call.token
  return axios(config)
}
