import React from 'react'
import './MiceMomentCard.scss'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

const MiceMomentButton = ({ miceMoment, isLoggedIn, callbackUrl, t }) => {
  const label = callbackUrl ? 'select_for_event' : 'plan_event'
  const MiceMomentButtonID = callbackUrl ? 'gtm-addMiceMoment-resultsPage' : 'gtm-miceMomentPlanEvent-resultsPage'
  return (
    <a
      href={`/search/add_mice_moment/${miceMoment.id}`}
      className='defaultButton client-theme--bg client-theme--font-color text-center'
      id={`${MiceMomentButtonID}`}
    >
      {t(label)}
    </a>
  )
}

const mapStateToProps = state => ({ callbackUrl: state.app.callbackUrl })
export default connect(mapStateToProps)(withTranslation()(MiceMomentButton))
