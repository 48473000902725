import React, { Component } from 'react'
import './SettingsPage.scss'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import EmailsForm from './EmailsForm/EmailsForm'
import AddressesForm from './AddressesForm/AddressesForm'
import ContactsManager from './ContactsManager/ContactsManager'
import AirplusForm from './AirplusForm/AirplusForm'
import HubspotForm from './HubspotForm/HubspotForm'
import AlgoliaForm from './AlgoliaForm/AlgoliaForm'
import Container from '../../Layout/Container'
import Can from '../../../Layout/Can'

class SettingsPage extends Component {
  render () {
    const { t } = this.props
    if (!this.props.supplier.isA('KonvenitUser')) {
      return (
        <Container>
          <div className='containerFluid pt-3 pb-3'>
            <Accordion
              id='supplierSettings'
              allowMultipleExpanded
              allowZeroExpanded
            >
              <Row className='m-0 mt-3 mb-3'>
                <Col className='p-0' xs={12}>
                  <Card>
                    <CardBody>
                      <AccordionItem>
                        <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                          <AccordionItemButton>
                            <h5 className='u-position-relative'>
                              {t('contact_for_request')}
                              <div className='accordion__arrow' role='presentation' />
                            </h5>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <EmailsForm supplier={this.props.supplier} updateHandler={this.props.updateHandler} resetHandler={this.props.resetHandler} enableUnsavedChangesTracker={this.props.enableUnsavedChangesTracker} />
                        </AccordionItemPanel>
                      </AccordionItem>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row className='m-0 mt-3 mb-3'>
                <Col className='p-0' xs={12}>
                  <Card>
                    <CardBody>
                      <AccordionItem>
                        <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                          <AccordionItemButton>
                            <h5 className='u-position-relative'>
                              {t('user_access_information')}
                              <div className='accordion__arrow' role='presentation' />
                            </h5>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ContactsManager supplierId={this.props.supplier.id} updateHandler={this.props.updateHandler} resetHandler={this.props.resetHandler} enableUnsavedChangesTracker={this.props.enableUnsavedChangesTracker} />
                        </AccordionItemPanel>
                      </AccordionItem>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row className='m-0 mt-3 mb-3'>
                <Col className='p-0' xs={12}>
                  <Card>
                    <CardBody>
                      <AccordionItem>
                        <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                          <AccordionItemButton>
                            <h5 className='u-position-relative'>
                              {t('invoice_address_info')}
                              <div className='accordion__arrow' role='presentation' />
                            </h5>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <AddressesForm supplier={this.props.supplier} updateHandler={this.props.updateHandler} resetHandler={this.props.resetHandler} enableUnsavedChangesTracker={this.props.enableUnsavedChangesTracker} />
                        </AccordionItemPanel>
                      </AccordionItem>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Accordion>

            <Can perform='manage' on='all'>
              <Accordion
                id='supplierAdminSettings'
                allowMultipleExpanded
                allowZeroExpanded
              >
                <Row className='m-0 mt-3 mb-3'>
                  <Col className='p-0' xs={12}>
                    <Card>
                      <CardBody>
                        <AccordionItem>
                          <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                            <AccordionItemButton>
                              <h5 className='u-position-relative'>
                                {t('airplus_configuration')}
                                <span className='accordianAdminOnlyLabel'>{t('admin')}</span>
                                <div className='accordion__arrow' role='presentation' />
                              </h5>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <AirplusForm supplier={this.props.supplier} updateHandler={this.props.updateHandler} resetHandler={this.props.resetHandler} enableUnsavedChangesTracker={this.props.enableUnsavedChangesTracker} />
                          </AccordionItemPanel>
                        </AccordionItem>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row className='m-0 mt-3 mb-3'>
                  <Col className='p-0' xs={12}>
                    <Card>
                      <CardBody>
                        <AccordionItem>
                          <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                            <AccordionItemButton>
                              <h5 className='u-position-relative'>
                                {t('marketing_products')}
                                <span className='accordianAdminOnlyLabel'>{t('admin')}</span>
                                <div className='accordion__arrow' role='presentation' />
                              </h5>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Row className='m-0 mt-4 mb-4'>
                              <Col className='pl-0' xl={6} md={6} sm={8} xs={12}>
                                <a
                                  className='btn c-btn btn-md c-theme-btn client-theme--bg rounded-pill'
                                  href={`/admin/suppliers/${this.props.supplier.id}/marketing_products?locale=${this.props.i18n.language}`}
                                >
                                  <i className='fa fa-link' />
                                  {t('manage_marketing_products')}
                                </a>
                              </Col>
                            </Row>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row className='m-0 mt-3 mb-3'>
                  <Col className='p-0' xs={12}>
                    <Card>
                      <CardBody>

                        <AccordionItem>
                          <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                            <AccordionItemButton>
                              <h5 className='u-position-relative admin-section'>
                                {t('admin')}
                                <span className='accordianAdminOnlyLabel'>{t('admin')}</span>
                                <div className='accordion__arrow' role='presentation' />
                              </h5>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <HubspotForm supplier={this.props.supplier} updateHandler={this.props.updateHandler} resetHandler={this.props.resetHandler} enableUnsavedChangesTracker={this.props.enableUnsavedChangesTracker} />
                          </AccordionItemPanel>
                          <AccordionItemPanel>
                            <AlgoliaForm supplier={this.props.supplier} />
                          </AccordionItemPanel>
                          <AccordionItemPanel>
                            <Row className='m-0 mt-4 mb-4'>
                              <Col className='pl-0' xl={12} md={12} sm={12} xs={12}>
                                <a
                                  className='btn c-btn btn-md c-theme-btn client-theme--bg rounded-pill'
                                  href={`/admin/suppliers/${this.props.supplier.id}/edit?locale=${this.props.i18n.language}`}
                                >
                                  <i className='fa fa-link' />
                                  {t('supplier.admin.settings.supplier_deactivation')}
                                </a>

                                <a
                                  className='btn c-btn btn-md c-theme-btn client-theme--bg rounded-pill'
                                  href={`/admin/suppliers/${this.props.supplier.id}/data_quality_scores?locale=${this.props.i18n.language}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  {t('supplier.admin.settings.data_quality_score_history')}
                                </a>
                              </Col>
                            </Row>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Accordion>
            </Can>
          </div>
        </Container>
      )
    } else {
      return <Redirect to='pages/access_denied' />
    }
  }
}

SettingsPage.propTypes = {
  supplier: PropTypes.object
}

export default withTranslation()(SettingsPage)
