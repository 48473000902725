import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import Select from 'react-select'
import { getSelectedOptions } from '../../shared/utils'
import { withTranslation } from 'react-i18next'
import CitySearch from './CitySearch'

const areaOfOperationTypeOptions = [
  {
    label: 'Regional',
    value: 'regional'
  }, {
    label: 'National / International',
    value: 'national'
  }
]
class AreaOfOperation extends Component {
  constructor (props) {
    super(props)

    this.state = {
      areaOfOperationType: null
    }

    this.handleAreaOfOperationTypechange = this.handleAreaOfOperationTypechange.bind(this)
  }

  componentDidMount () {
    const { deliverable } = this.props
    const areaOfOperationCountries = getSelectedOptions(deliverable.operational_area_europe_country_ids)
    const areaOfOperationCities = deliverable.operational_area_cities

    this.setState((state) => {
      let areaOfOperationType
      if (areaOfOperationCountries.length > 0) {
        areaOfOperationType = areaOfOperationTypeOptions[1]
      } else if (areaOfOperationCities.length > 0) {
        areaOfOperationType = areaOfOperationTypeOptions[0]
      }
      return { ...state, areaOfOperationType }
    })
  }

  handleAreaOfOperationTypechange (values) {
    this.setState({ areaOfOperationType: values }, () => {
      // Clear the other values
      if (values.value === 'national') {
        this.props.onSingleSelectChange('operational_area_cities', [], true)
      } else if (values.value === 'regional') {
        this.props.onMultipleSelectChange('operational_area_europe_country_ids', [], true)
      }
    })
  }

  render () {
    const { i18n, t } = this.props
    const { areaOfOperationType } = this.state

    return (
      <>
        <Row className='mt-3 mb-3'>
          <Col xs={12}>
            <Select
              id='areaOfOperationType'
              name='select_area_of_operation_type'
              className='defaultSelectDropdown'
              classNamePrefix='defaultSelectDropdown'
              options={areaOfOperationTypeOptions}
              placeholder='Select one'
              value={areaOfOperationType}
              onChange={this.handleAreaOfOperationTypechange}
              style={{ margin: '6px 0' }}
            />
          </Col>
        </Row>

        {areaOfOperationType && areaOfOperationType.value === 'regional' ? (
          <Row className='mt-3 mb-3'>
            <Col xs={12}>
              <label htmlFor='citiesSearch'>{t('supplier.edit.general.operational_area_regional')}</label>
              <CitySearch
                value={this.props.deliverable.operational_area_cities}
                onChange={(values) => this.props.onSingleSelectChange('operational_area_cities', values, true)}
              />
            </Col>
          </Row>
        ) : null}

        {areaOfOperationType && areaOfOperationType.value === 'national' ? (
          <Row className='mt-3 mb-3'>
            <Col xs={12}>
              <label htmlFor='areaOfOperationCountries'>{t('supplier.edit.general.operational_area_international')}</label>
              <Select
                id='areaOfOperationCountries'
                name='operational_area_europe_country_ids'
                className='defaultSelectDropdown'
                classNamePrefix='defaultSelectDropdown'
                options={this.props.deliverable.operational_area_europe_country_ids}
                getOptionValue={(option) => option.attribute_name}
                getOptionLabel={option => option[`name_${i18n.language}`]}
                placeholder={t('select_all_that_apply')}
                value={getSelectedOptions(this.props.deliverable.operational_area_europe_country_ids)}
                isSearchable
                isClearable
                isMulti
                onChange={(values) => this.props.onMultipleSelectChange('operational_area_europe_country_ids', values, true)}
                style={{ margin: '6px 0' }}
              />
            </Col>
          </Row>
        ) : null}
      </>
    )
  }
}

AreaOfOperation.propTypes = {
  deliverable: PropTypes.object.isRequired,
  onMultipleSelectChange: PropTypes.func
}

export default withTranslation()(AreaOfOperation)
