import React from 'react'
import './DropMenu.scss'

class DropMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.handleToggle = this.handleToggle.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleControlClick = this.handleControlClick.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick (e) {
    if (this.node && this.node.contains(e.target)) {
      return
    }

    this.handleToggle(false)
  }

  handleControlClick (e) {
    e.preventDefault()
    this.handleToggle()
  }

  handleToggle (isOpen) {
    const oldVal = this.isOpen()
    const newVal = isOpen !== undefined ? isOpen : !oldVal

    if (this.props.onToggle && newVal !== oldVal) {
      this.props.onToggle(newVal)
    }

    if (this.props.isOpen === undefined) {
      this.setState({ isOpen: newVal })
    }
  }

  isOpen () {
    if (this.props.isOpen !== undefined) {
      return this.props.isOpen
    } else {
      return this.state.isOpen
    }
  }

  handleBlur (e) {
    if (e.currentTarget.contains(e.relatedTarget)) {
      return
    }
    this.handleToggle(false)
  }

  render () {
    const divWidthStyle = {}
    const containerWidthStyle = {}

    if (!this.props.dynamicWidth) {
      divWidthStyle.width = '100%'
      containerWidthStyle.width = this.props.menuContainerWidth
        ? this.props.menuContainerWidth
        : '130%'
      if (this.props.maxWidth) {
        containerWidthStyle.maxWidth = this.props.maxWidth
      }
    }

    return (
      <div
        ref={(node) => (this.node = node)}
        style={{ position: 'relative', ...divWidthStyle }}
      >
        <button
          className='toggleMenuButton'
          tag='span'
          onClick={this.handleControlClick}
          data-toggle='dropdown'
          aria-expanded={this.isOpen()}
        >
          <div>{this.props.menuLabel ? this.props.menuLabel : null}</div>
          <div className='menu-icon'>
            <i
              className={
                this.isOpen() ? 'fal fa-angle-up' : 'fal fa-angle-down'
              }
            />
          </div>
        </button>
        <div
          className='menuContainer'
          onBlur={this.handleBlur}
          style={{
            display: this.isOpen() ? 'block' : 'none',
            position: 'absolute',
            top: '42px',
            left: '-5px',
            padding: '32px',
            background: '#fff',
            boxShadow: '2px 2px 9px 0 rgba(0, 0, 0, 0.33)',
            border: '1px solid rgba(0, 0, 0, 0)',
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: window.innerHeight - 200,
            minWidth: '180px',
            borderBottomLeftRadius: '36px',
            borderBottomRightRadius: '36px',
            transition: 'all 0.5s ease-in-out',
            ...containerWidthStyle
          }}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}
export default DropMenu
