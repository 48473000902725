import React from 'react'
import './SupplierMarkup.scss'
import HotelRating from '../HotelRating/HotelRating'
import SupplierCardFeedback from '../SupplierCardFeedback/SupplierCardFeedback'
import airPlusIcon from '../../assets/images/airplus-icon.png'
import { withTranslation } from 'react-i18next'
import Tooltip from 'rc-tooltip'
import SupplierNotes from '../SupplierNotes/SupplierNotes'
import SustainabilityIcon from '../SustainabilityIcon/SustainabilityIcon'
class SupplierMarkup extends React.Component {
  render () {
    const { t, professional, airplus, classification, micepoints, feedbackAverage, containerStyle } = this.props
    return (
      <div style={containerStyle} className={`supplierMarkUp ${this.props.backgroundClass}`}>
        {(this.props.hit && this.props.hit.feedback_average) || feedbackAverage
          ? <SupplierCardFeedback feedback={feedbackAverage || this.props.hit.feedback_average} />
          : null}
        {professional || (this.props.hit && this.props.hit.professional) ? (
          <Tooltip placement='topLeft' trigger={['hover', 'focus']} overlay={<span>{t('professional_tooltip')}</span>} arrowContent={<div className='rc-tooltip-arrow-inner' />}>
            <div className='badgeProfessional' tabIndex='0' aria-label={t('professional_tooltip')}>
              <span aria-hidden='true'>PRO</span>
            </div>
          </Tooltip>
        ) : null}

        <div className='supplierRating'>
          <HotelRating
            hotel={this.props.hit}
            classification={classification}
            micepoints={micepoints}
          />
        </div>

        {this.props.hit && this.props.hit.notes ? <SupplierNotes notes={this.props.hit.notes} /> : null}

        {airplus || (this.props.hit && this.props.hit.airplus) ? (
          <Tooltip placement='top' trigger={['hover', 'focus']} overlay={<span>{t('airplus_tooltip')}</span>} arrowContent={<div className='rc-tooltip-arrow-inner' />}>
            <div className='airplusContainer' tabIndex='0'><img src={airPlusIcon} alt={t('airplus_tooltip')} />
            </div>
          </Tooltip>
        ) : null}
        {this.props.hit && this.props.hit.sustainable ? (
          <SustainabilityIcon />
        ) : null}
      </div>
    )
  }
}
export default withTranslation()(SupplierMarkup)
