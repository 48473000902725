import startPageSchema from './startPage'
import hotelPageSchema from './hotel'
import locationPageSchema from './location'
import eventGastronomyPageSchema from './event_gastronomy'
import { addScriptTag } from '../../shared/utils'

class StructuredDataGenerator {
  constructor (type) {
    this.type = type
  }

  static forStartPage () {
    const content = startPageSchema()
    content && this.addContentToPage(content)
  }

  static forSupplierHome (type, data) {
    let content

    switch (type) {
      case 'hotel':
        content = hotelPageSchema(data)
        break
      case 'location':
        content = locationPageSchema(data)
        break
      case 'event_gastronomy':
        content = eventGastronomyPageSchema(data)
        break
      default:
        break
    }

    content && this.addContentToPage(content)
  }

  static addContentToPage (content) {
    if (content) {
      const type = 'application/ld+json'
      const innerHTML = JSON.stringify(content)
      const scriptAlreadyPresent = document.querySelector(`script[type="${type}"]`)

      if (scriptAlreadyPresent) {
        scriptAlreadyPresent.innerHTML = innerHTML
      } else {
        addScriptTag({ type, innerHTML })
      }
    }
  }
}

export default StructuredDataGenerator
