import React from 'react'
import MiceMomentCardLite from '../MiceMoments/MiceMomentCardLite'
import MiceMomentCard from '../MiceMoments/MiceMomentCard'
import { connectHits } from 'react-instantsearch-dom'
import { Link } from 'react-router-dom'
import 'react-multi-carousel/lib/styles.css'
import './ConnectedCarouselOfMiceMoments.scss'
import Slider from 'react-slick'
import { settings, settingsLite } from './CarouselSettings'

const carouselOfMiceMoments = ({ hits }) => (
  <div className='d-block'>
    <Slider
      itemClass='miceMomentPreviewCarouselCardItem'
      containerClass='ssCarouselContainer'
      lazyLoad
      {...settings}
    >
      {hits.map((hit, idx) => (
        <div key={idx} index={idx + 1} className='miceMomentPreviewCardContainer'>
          <MiceMomentCard hit={hit} />
        </div>
      ))}
    </Slider>
  </div>
)

const hashtagRenderer = tagName => {
  return <Link to='#' tabIndex='-1'>{tagName}</Link>
}

const carouselOfMiceMomentsLite = ({ hits }) => (
  <div className='d-block'>
    {hits && hits.length < 7 ? (
      <Slider
        itemClass='miceMomentPreviewCarouselCardItem'
        containerClass='ssCarouselContainer'
        lazyLoad
        {...settings}
        accessibility={false}
      >
        {hits.map((hit, idx) => (
          <div key={hit.objectID} index={idx + 1} className='miceMomentPreviewCardContainer'>
            <MiceMomentCardLite hit={hit} trendlineMode hashtagRenderer={hashtagRenderer} />
          </div>
        ))}
      </Slider>
    ) : null}
    {hits && hits.length >= 7 ? (
      <Slider
        itemClass='miceMomentPreviewCarouselCardItem'
        containerClass='ssCarouselContainer'
        lazyLoad
        {...settingsLite}
        accessibility={false}
      >
        {hits.map((hit, idx) => (
          <div key={hit.objectID} index={idx + 1} className='miceMomentPreviewCardContainer'>
            <MiceMomentCardLite hit={hit} trendlineMode hashtagRenderer={hashtagRenderer} />
          </div>
        ))}
      </Slider>
    ) : null}
  </div>
)

export const ConnectedCarouselOfMiceMoments = connectHits(carouselOfMiceMoments)

export const ConnectedCarouselOfMiceMomentsLite = connectHits(carouselOfMiceMomentsLite)

export default ConnectedCarouselOfMiceMoments
