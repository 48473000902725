import React from 'react'
import PropTypes from 'prop-types'
import './HotelRating.scss'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css'
import { withTranslation } from 'react-i18next'

class HotelRating extends React.Component {
  render () {
    const { t } = this.props
    const classification = this.props.classification || (this.props.hotel && this.props.hotel.classification)
    const micepoints = this.props.micepoints || (this.props.hotel && this.props.hotel.micepoints)
    if (classification && parseInt(classification) !== 0) {
      return (
        <Tooltip placement='top' trigger={['hover', 'focus']} overlay={<span>Dehoga {parseInt(classification / 10)} {t('dehoga_tooltip')}</span>} arrowContent={<div className='rc-tooltip-arrow-inner' />}>
          <div className='dehogaRating' tabIndex='0' aria-label={'Dehoga ' + parseInt(classification / 10) + t('rating_items.dehoga_rating')}>
            <span aria-hidden='true'>
              {parseInt(classification / 10)}
              <i className='fa fa-star' />
            </span>
          </div>
        </Tooltip>
      )
    } else if (micepoints && parseInt(micepoints) !== 0) {
      return (
        <Tooltip placement='top' trigger={['hover', 'focus']} overlay={<span>{parseInt(micepoints / 10)} MICE Points</span>} arrowContent={<div className='rc-tooltip-arrow-inner' />}>
          <div className='miceRating' tabIndex='0' aria-label={parseInt(micepoints / 10) + t('rating_items.mice_rating')}>
            <span aria-hidden='true'>{parseInt(micepoints / 10)}</span>
            <i className='ti-medall-alt' />
          </div>
        </Tooltip>
      )
    } else {
      return ''
    }
  }
}

HotelRating.propTypes = {
  t: PropTypes.func,
  hotel: PropTypes.object,
  class: PropTypes.string
}

export default (withTranslation()(HotelRating))
