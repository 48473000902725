import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import axios from 'axios'

class HighContrastModeSwitch extends Component {
  constructor (props) {
    super(props)

    this.state = { highContrastMode: this.highContrastModeCookieValue() }
    this.handleHighContrastModeToggleBtn = this.handleHighContrastModeToggleBtn.bind(this)
  }

  parseCookies () {
    return document.cookie.split(';').map((cookieKeyValue) => cookieKeyValue.split('=').map(v => v.trim()))
  }

  highContrastModeCookieValue () {
    const cookie = this.parseCookies().find(c => c[0] === 'high_contrast_mode')
    return (cookie && (cookie[1] === 'true'))
  }

  handleHighContrastModeToggleBtn () {
    const newHighContrastModeState = !this.state.highContrastMode

    this.setState({ highContrastMode: newHighContrastModeState })

    axios.get(`/accessibility/high-contrast.js?enable=${newHighContrastModeState}`)

    if (newHighContrastModeState) {
      document.body.classList.add('accessibility-high-contrast')
      document.body.classList.add('important')
    } else {
      document.body.classList.remove('accessibility-high-contrast')
      document.body.classList.remove('important')
    }
  }

  render () {
    const { t } = this.props

    return (
      <button className='high-contrast-mode-switch' onClick={this.handleHighContrastModeToggleBtn}>
        {this.state.highContrastMode ? t('accessibility.disable_high_contrast') : t('accessibility.enable_high_contrast')}
      </button>
    )
  }
}

export default (withTranslation()(HighContrastModeSwitch))
