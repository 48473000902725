import React, { useState, useEffect } from 'react'
import MiceMomentCard from '../../../MiceMoments/MiceMomentCard'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import Heading from '../../../Heading/Heading'
import axios from 'axios'
import ButtonWithIcon from '../../../ButtonWithIcon/ButtonWithIcon'
import './MiceMoments.scss'

function MiceMoments ({ supplier, deliverableType }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [miceMoments, setMiceMoments] = useState([])
  const [errors, setErrors] = useState(null)
  const [newMoment, setNewMoment] = useState(null)

  useEffect(() => {
    const fetchSupplierMoments = (supplierId) => {
      axios
        .get(`/suppliers/${supplierId}/deliverables/${deliverableType}/mice_moments`)
        .then((response) => {
          setIsLoading(false)
          setMiceMoments(response.data)
        })
        .catch((response) => {
          setIsLoading(false)
          setErrors(t('error_try_later'))
        })
    }

    setMiceMoments([])
    setErrors(null)
    if (supplier && supplier.id) {
      setIsLoading(true)
      fetchSupplierMoments(supplier.id)
    }
  }, [supplier, deliverableType])

  const handleMomentSave = (updatedMoment) => {
    const newMoments = [...miceMoments]
    const elementIndex = newMoments.findIndex((moment) => moment.id === updatedMoment.id)

    newMoments[elementIndex] = updatedMoment
    setMiceMoments(newMoments)
  }

  const handleNewMomentSave = (newMoment) => {
    setMiceMoments([newMoment, ...miceMoments])
    setNewMoment(null)
  }

  const handleNewMomentCancel = () => {
    setNewMoment(null)
  }

  const handleaddNewMomentClick = () => {
    setNewMoment({})
  }

  const handleMomentDelete = (deletedMoment) => {
    setMiceMoments(miceMoments.filter((miceMoment) => miceMoment.id !== deletedMoment.id))
  }

  return (
    <div className='containerFluid'>
      <Row>
        <Col xs={12} md={6} lg={4} className='mb-3'>
          {!newMoment ? (
            <ButtonWithIcon
              className='client-theme--bg client-theme--font-color mb-3'
              onClick={handleaddNewMomentClick}
              icon='fa fa-plus'
              label={t('add_new_mice_moment')}
            />
          ) : (
            <div>
              <div>
                <Heading size={3}>{t('add_new_mice_moment')}</Heading>
              </div>
              <div>
                <MiceMomentCard
                  supplier={supplier}
                  deliverableType={deliverableType}
                  value={newMoment}
                  onSave={handleNewMomentSave}
                  onCancel={handleNewMomentCancel}
                  edit
                  adminView
                />
              </div>
            </div>
          )}
        </Col>
      </Row>

      {isLoading ? (
        <span>{t('common.loading')}</span>
      ) : (
        <Row className='mb-5'>
          <Col xs={12} className='mb-3'>
            <Heading size={3}>{`${t('mice_moments')}`}</Heading>
          </Col>

          {errors ? (
            <Col xs={12} className='mb-3'>
              {t('mice_moment_messages.loading_error')}
            </Col>
          ) : (
            <>
              {miceMoments.length > 0 ? (
                <>
                  {miceMoments.map((miceMoment, index) => (
                    <Col key={miceMoment.id} xs={12} md={6} xl={4} className='mb-3'>
                      <MiceMomentCard
                        supplier={supplier}
                        deliverableType={deliverableType}
                        value={miceMoment}
                        onSave={handleMomentSave}
                        onDelete={handleMomentDelete}
                        adminView
                      />
                    </Col>
                  ))}
                </>
              ) : (
                <Col xs={12} className='mb-3'>
                  {t('mice_moment_messages.there_are_no_mice_moments')}
                </Col>
              )}
            </>
          )}
        </Row>
      )}
    </div>
  )
}

export default MiceMoments
