import React from 'react'
import BannerAdDisplay from './BannerAdDisplay'
import BannerAdExtractor from './BannerAdExtractor'

export default function ({ provider, configurator, position, variant }) {
  return (
    <BannerAdExtractor position={position} provider={provider} configurator={configurator}>
      <BannerAdDisplay variant={variant} />
    </BannerAdExtractor>
  )
}
