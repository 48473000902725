import React from 'react'
import './SupplierMainImageGallery.scss'
import SupplierOverlayBox from '../SupplierOverlayBox/SupplierOverlayBox'
import { Row } from 'reactstrap'
import PropTypes from 'prop-types'
import PlaceholderCoverImage from '../../assets/images/placeholder_cover_image.jpg'

class SupplierMainImageGallery extends React.Component {
  constructor (props) {
    super(props)
    this.getImage = this.getImage.bind(this)
  }

  getImage (number) {
    return this.props.galleryImages &&
    this.props.galleryImages[number] &&
    (this.props.galleryImages[number].url || `https://res-1.cloudinary.com/mice-portal/${this.props.galleryImages[number].cloudinary_public_id}`)
  }

  render () {
    const numberOfImages = this.props.galleryImages ? this.props.galleryImages.length : 0
    return (
      <>
        <Row className='heroImageGridContianer'>
          <div className='coverImageGallery'>
            <figure
              className={`galleryItem galleryItem--1 MSGridRow1-2 ${numberOfImages > 1 ? 'MSGridColumn1-3' : 'MSGridColumn1-5'}`}
              style={{
                gridColumnStart: 1,
                gridColumnEnd: numberOfImages > 1 ? 3 : 5,
                gridRowStart: 1,
                gridRowEnd: 3
              }}
            >
              <img
                className='galleryImage galleryImage--1'
                src={
                  numberOfImages === 0
                    ? PlaceholderCoverImage
                    : this.getImage(0)
                }
                alt={
                  this.props.galleryImages && this.props.galleryImages[0] && this.props.galleryImages[0].caption
                    ? this.props.galleryImages[0].caption
                    : ''
                }
              />
            </figure>
            {numberOfImages >= 2 ? (
              <figure
                className={`galleryItem galleryItem--2 ${numberOfImages <= 2 ? 'MSGridColumn3-5' : 'MSGridColumn3-4'} ${numberOfImages >= 5 ? 'MSGridRow1-1' : 'MSGridRow1-2'}`}
                style={{
                  gridColumnStart: numberOfImages <= 2 ? 3 : 3,
                  gridColumnEnd: numberOfImages <= 2 ? 5 : 4,
                  gridRowStart: 1,
                  gridRowEnd: numberOfImages >= 5 ? 2 : 3
                }}
              >
                <img
                  className='galleryImage'
                  src={this.getImage(1)}
                  alt={
                    this.props.galleryImages && this.props.galleryImages[1] && this.props.galleryImages[1].caption
                      ? this.props.galleryImages[1].caption
                      : ''
                  }
                />
              </figure>
            ) : null}
            {numberOfImages >= 3 ? (
              <figure
                className={`galleryItem galleryItem--3 MSGridColumn4-5 ${numberOfImages === 3 ? 'MSGridRow1-2' : 'MSGridRow1-1'}`}
                style={{
                  gridColumnStart: 4,
                  gridColumnEnd: 5,
                  gridRowStart: 1,
                  gridRowEnd: numberOfImages === 3 ? 3 : 2
                }}
              >
                <img
                  className='galleryImage galleryImage--3'
                  src={this.getImage(2)}
                  alt={
                    this.props.galleryImages && this.props.galleryImages[2] && this.props.galleryImages[2].caption
                      ? this.props.galleryImages[2].caption
                      : ''
                  }
                />
              </figure>
            ) : null}
            {numberOfImages >= 4 ? (
              <figure
                className={`galleryItem galleryItem--4 MSGridRow2-2 ${numberOfImages > 4 ? 'MSGridColumn3-4' : 'MSGridColumn4-5'}`}
                style={{
                  gridColumnStart: numberOfImages > 4 ? 3 : 4,
                  gridColumnEnd: numberOfImages > 4 ? 4 : 5,
                  gridRowStart: 2,
                  gridRowEnd: 3
                }}
              >
                <img
                  className='galleryImage'
                  src={this.getImage(3)}
                  alt={
                    this.props.galleryImages && this.props.galleryImages[3] && this.props.galleryImages[3].caption
                      ? this.props.galleryImages[3].caption
                      : ''
                  }
                />
              </figure>
            ) : null}
            {numberOfImages >= 5 ? (
              <figure
                className='galleryItem galleryItem--5 MSGridColumn4-5 MSGridRow2-2'
                style={{
                  gridColumnStart: 4,
                  gridColumnEnd: 5,
                  gridRowStart: 2,
                  gridRowEnd: 3
                }}
              >
                <img
                  className='galleryImage galleryImage--5'
                  src={this.getImage(4)}
                  alt={
                    this.props.galleryImages && this.props.galleryImages[4] && this.props.galleryImages[4].caption
                      ? this.props.galleryImages[4].caption
                      : ''
                  }
                />
              </figure>
            ) : null}
          </div>
          {this.props.showOverlayBox
            ? (
              <div className='supplierBox'>
                <div
                  style={{
                    padding: '0px 0px',
                    fontSize: '2rem',
                    color: '#FFFFFF',
                    zIndex: '1000',
                    display: 'flex',
                    height: '100%',
                    maxWidth: '100%'
                  }}
                >
                  <SupplierOverlayBox
                    hit={this.props.hit}
                    supplier={this.props.supplier}
                    logo={this.props.logo}
                    view={this.props.view}
                    projectStartDateUnix={this.props.projectStartDateUnix}
                  />
                </div>
              </div>)
            : null}
        </Row>
      </>
    )
  }
}

SupplierMainImageGallery.defaultProps = {
  galleryImages: [],
  projectStartDateUnix: null
}

SupplierMainImageGallery.propTypes = {
  hit: PropTypes.object,
  supplier: PropTypes.object,
  logo: PropTypes.object,
  galleryImages: PropTypes.array,
  projectStartDateUnix: PropTypes.number,
  showOverlayBox: PropTypes.bool
}

export default SupplierMainImageGallery
