import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import PropTypes from 'prop-types'
import SupplierAdminLayout from './Layout/Layout'
import Marketing from './Marketing'
import Analytics from './Analytics'
import Records from './Records'
import Messages from './Messages'
import SettingsPage from './SupplierEdit/SettingsPage/SettingsPage'
import Can from '../Layout/Can'
import { wrapAsAnInstanceOf } from '../../config/ability'
import { errorHandler } from '../../shared/errorHandler'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

class SupplierAdmin extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      supplier: null
    }

    this.getSupplier = this.getSupplier.bind(this)
    this.getSalesManager = this.getSalesManager.bind(this)
    this.abilityInstance = this.abilityInstance.bind(this)
    this.handleDeleteType = this.handleDeleteType.bind(this)
  }

  componentDidMount () {
    const { match } = this.props

    switch (match.params.userType) {
      case 'suppliers':
        this.getSupplier(match.params.objectID)
        break
      case 'sales_managers':
        this.getSalesManager(match.params.objectID)
        break
      default:
        break
    }
  }

  getSupplier (supplierId) {
    axios
      .get(`/suppliers/${supplierId}/admin_records.json?locale=${this.props.i18n.language}`)
      .then(response => {
        this.setState(() => ({
          supplier: wrapAsAnInstanceOf(response.data, 'Supplier')
        }))
      })
      .catch(errorHandler)
  }

  getSalesManager (konvenitUserId) {
    axios
      .get(`/sales_managers/${konvenitUserId}/admin_records.json?locale=${this.props.i18n.language}`)
      .then(response => {
        this.setState(() => ({
          supplier: wrapAsAnInstanceOf(response.data, 'KonvenitUser')
        }))
      })
      .catch(errorHandler)
  }

  renderSelectedTab () {
    const { match } = this.props

    switch (match.params.tabs) {
      case 'analytics':
        return <Analytics supplier={this.state.supplier} />
      case 'records':
        return <Records supplier={this.state.supplier} history={this.props.history} onDeleteType={this.handleDeleteType} />
      case 'messages':
        return <Messages supplier={this.state.supplier} />
      case 'settings':
        return <SettingsPage supplier={this.state.supplier} updateHandler={() => {}} resetHandler={() => {}} enableUnsavedChangesTracker={false} />
      default:
        return <Marketing showHero supplier={this.state.supplier} />
    }
  }

  abilityInstance () {
    if (this.state.supplier && this.state.supplier.isA('KonvenitUser')) {
      return 'Supplier'
    }

    return this.state.supplier
  }

  handleDeleteType (deliverable) {
    const { t } = this.props

    confirmAlert({
      title: t('supplier.admin.records.record.remove_type_confirmation_title'),
      message: t('supplier.admin.records.record.remove_type_confirmation_subtitle'),
      buttons: [
        {
          label: t('common.positive_answer'),
          onClick: () => {
            const supplier = this.state.supplier

            this.setState({ supplier: null })

            axios
              .delete(`/suppliers/${supplier.id}/deliverables/${deliverable.deliverable_type}.json?locale=${this.props.i18n.language}`)
              .then(response => {
                this.setState(() => ({
                  supplier: wrapAsAnInstanceOf(response.data, 'Supplier')
                }))
              })
              .catch(errorHandler)
          }
        },
        {
          label: t('common.negative_answer')
        }
      ]
    })
  }

  render () {
    return (
      <>
        {this.state.supplier && (
          <Can perform='admin' on={this.abilityInstance()}>
            <SupplierAdminLayout supplier={this.state.supplier}>
              {this.renderSelectedTab()}
            </SupplierAdminLayout>
          </Can>
        )}
      </>
    )
  }
}

SupplierAdmin.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object
}

export default withRouter(withTranslation()(SupplierAdmin))
