import React from 'react'
import { Row, Col } from 'reactstrap'
import ButtonWithIcon from '../../../ButtonWithIcon/ButtonWithIcon'
import TextInputGroup from '../../../TextInputGroup/TextInputGroup'
import { TextInput } from '../../../FormElements/FormElements'
import UploadImage from '../../../UploadImage/UploadImage'
import PropTypes from 'prop-types'
import UForm from '../../../../assets/images/conference_room_forms/u-form.gif' /* Uform */
import Classroom from '../../../../assets/images/conference_room_forms/rows-form.gif' /* Classroom actually Rows */
import Theater from '../../../../assets/images/conference_room_forms/reception-form.gif' /* Theatre actually Banquet */
import Block from '../../../../assets/images/conference_room_forms/block-form.gif' /* Block */
import Banquet from '../../../../assets/images/conference_room_forms/reception-form-new.gif' /* Banquet actually Reception */
import Square from '../../../../assets/images/conference_room_forms/classroom-form.gif' /* Cabinett actually ClassRoom */
import { withTranslation } from 'react-i18next'

class ConferenceRoom extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      hasUserMadeChanges: false
    }
    this.initializeStateWithExistingConferenceRoomData = this.initializeStateWithExistingConferenceRoomData.bind(this)
    this.handleAddImage = this.handleAddImage.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this.moveImage = this.moveImage.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleImageUploadComplete = this.handleImageUploadComplete.bind(this)
    this.handlePlanUpload = this.handlePlanUpload.bind(this)
  }

  componentDidMount () {
    this.initializeStateWithExistingConferenceRoomData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.conferenceRoom.images !== prevProps.conferenceRoom.images ||
        this.props.conferenceRoom.id !== prevProps.conferenceRoom.id) {
      this.setState({ ...this.props.conferenceRoom })
    }

    if (prevState !== this.state && this.state.hasUserMadeChanges) {
      this.props.onChange(this.props.idx, this.state)
      this.setState({ hasUserMadeChanges: false })
    }
  }

  initializeStateWithExistingConferenceRoomData () {
    if (this.props.conferenceRoom) {
      this.setState({ ...this.props.conferenceRoom, hasUserMadeChanges: false })
    }
  }

  handleAddImage () {
    const images = [...this.state.images]
    images.push({ element_order: images.length, cloudinary_public_id: null, url: null })
    this.setState({ images: images, hasUserMadeChanges: true })
  }

  removeImage (id) {
    const images = [...this.state.images]
    const image = { ...this.state.images[id] }

    image._destroy = true
    images[id] = image
    this.setState({ images: images, hasUserMadeChanges: true })
  }

  moveImage (idx, newIdx) {
    if (newIdx >= 0 && newIdx < this.state.images.length) {
      const images = [...this.state.images]
      const otherImage = images[newIdx]

      images[newIdx] = images[idx]
      images[idx] = otherImage

      for (let i = 0; i < images.length; i++) {
        images[i].element_order = i
      }

      this.setState({ images: images, hasUserMadeChanges: true })
    }
  }

  handleChange (event) {
    if (event && event.currentTarget) {
      const value = event.currentTarget.type === 'checkbox' ? event.currentTarget.checked : event.currentTarget.value
      this.setState({ [event.currentTarget.name]: value, hasUserMadeChanges: true })
    }
  }

  handleImageUploadComplete (id, response) {
    const images = [...this.state.images]
    const image = { ...this.state.images[id] }
    if (response && response.public_id) {
      if (id !== null && id >= 0) {
        image.cloudinary_public_id = response.public_id
        image.url = response.url
        images[id] = image
      } else {
        images.push({ cloudinary_public_id: response.public_id, url: response.url })
      }
      this.setState({ images: images, hasUserMadeChanges: true })
    }
  }

  handlePlanUpload (response) {
    if (response && response.public_id) {
      this.setState({
        roomplan_cloudinary_public_id: response.public_id,
        roomplan_file_url: response.url.replace('.pdf', '.jpg'),
        hasUserMadeChanges: true
      })
    }
  }

  render () {
    const { t } = this.props

    return (
      this.state ? (
        <>
          <Row className='mt-3 mb-3'>
            <Col xs={12}>
              <TextInput
                name='name_de'
                className='borderPrimary'
                label={t('supplier.edit.conference.input.room.name.label')}
                placeholder={t('supplier.edit.conference.input.room.name.placeholder')}
                value={this.state.name_de}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <Row className='mt-3 mb-3'>
            <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
              <TextInputGroup
                label={t('supplier.edit.conference.input.room.width.label')}
                name='width'
                className='borderPrimary'
                placeholder={t('supplier.edit.conference.input.room.width.placeholder')}
                appendText='m'
                value={this.state.width}
                onChange={this.handleChange}
              />
            </Col>
            <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
              <TextInputGroup
                name='length'
                label={t('supplier.edit.conference.input.room.length.label')}
                className='borderPrimary'
                placeholder={t('supplier.edit.conference.input.room.length.placeholder')}
                appendText='m'
                value={this.state.length}
                onChange={this.handleChange}
              />
            </Col>
            <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
              <TextInputGroup
                name='height'
                label={t('supplier.edit.conference.input.room.height.label')}
                className='borderPrimary'
                placeholder={t('supplier.edit.conference.input.room.height.placeholder')}
                appendText='m'
                value={this.state.height}
                onChange={this.handleChange}
              />
            </Col>
            <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
              <TextInputGroup
                name='area'
                label={t('supplier.edit.conference.input.room.area.label')}
                className='borderPrimary'
                placeholder={t('supplier.edit.conference.input.room.area.placeholder')}
                appendText='m2'
                value={this.state.area}
                onChange={this.handleChange}
              />
            </Col>
          </Row>

          <Row className='mt-3 mb-2'>
            <Col className='mt-1 mb-1' xs={12}>
              <h6>{t('supplier.edit.conference.input.room.sub_heading.number_of_participants')}</h6>
            </Col>
          </Row>

          <Row className='mt-1 mb-3 pl-3' xs={12}>
            <Col className='pl-0' xl={2} lg={2} md={4} sm={4} xs={6}>
              <Row style={{ justifyContent: 'center' }} className='m-0'>
                <label>{t('supplier.edit.conference.input.room.u_form.label')}</label>
              </Row>
              <Row className='m-0 mt-1 mb-1'>
                <img style={{ width: '100%' }} src={UForm} alt='' />
              </Row>
              <Row className='m-0'>
                <TextInput
                  name='capacity_u_form'
                  className='borderPrimary'
                  label=''
                  placeholder={t('supplier.edit.conference.input.room.u_form.placeholder')}
                  value={this.state.capacity_u_form}
                  onChange={this.handleChange}
                />
              </Row>
            </Col>
            <Col className='pl-0' xl={2} lg={2} md={4} sm={4} xs={6}>
              <Row style={{ justifyContent: 'center' }} className='m-0'>
                <label>{t('supplier.edit.conference.input.room.classroom.label')}</label>
              </Row>
              <Row className='m-0 mt-1 mb-1'>
                <img style={{ width: '100%' }} src={Classroom} alt='' />
              </Row>
              <Row className='m-0'>
                <TextInput
                  name='capacity_rows'
                  className='borderPrimary'
                  label=''
                  placeholder={t('supplier.edit.conference.input.room.classroom.placeholder')}
                  value={this.state.capacity_rows}
                  onChange={this.handleChange}
                />
              </Row>
            </Col>
            <Col className='pl-0' xl={2} lg={2} md={4} sm={4} xs={6}>
              <Row style={{ justifyContent: 'center' }} className='m-0'>
                <label>{t('supplier.edit.conference.input.room.theater.label')}</label>
              </Row>
              <Row className='m-0 mt-1 mb-1'>
                <img style={{ width: '100%' }} src={Theater} alt='' />
              </Row>
              <Row className='m-0'>
                <TextInput
                  name='capacity_cluster'
                  className='borderPrimary'
                  label=''
                  placeholder={t('supplier.edit.conference.input.room.theater.placeholder')}
                  value={this.state.capacity_cluster}
                  onChange={this.handleChange}
                />
              </Row>
            </Col>
            <Col className='pl-0' xl={2} lg={2} md={4} sm={4} xs={6}>
              <Row style={{ justifyContent: 'center' }} className='m-0'>
                <label>{t('supplier.edit.conference.input.room.block.label')}</label>
              </Row>
              <Row className='m-0 mt-1 mb-1'>
                <img style={{ width: '100%' }} src={Block} alt='' />
              </Row>
              <Row className='m-0'>
                <TextInput
                  name='capacity_block'
                  className='borderPrimary'
                  label=''
                  placeholder={t('supplier.edit.conference.input.room.block.placeholder')}
                  value={this.state.capacity_block}
                  onChange={this.handleChange}
                />
              </Row>
            </Col>
            <Col className='pl-0' xl={2} lg={2} md={4} sm={4} xs={6}>
              <Row style={{ justifyContent: 'center' }} className='m-0'>
                <label>{t('supplier.edit.conference.input.room.banquet.label')}</label>
              </Row>
              <Row className='m-0 mt-1 mb-1'>
                <img style={{ width: '100%' }} src={Banquet} alt='' />
              </Row>
              <Row className='m-0'>
                <TextInput
                  name='capacity_reception'
                  className='borderPrimary'
                  label=''
                  placeholder={t('supplier.edit.conference.input.room.banquet.placeholder')}
                  value={this.state.capacity_reception}
                  onChange={this.handleChange}
                />
              </Row>
            </Col>
            <Col className='pl-0' xl={2} lg={2} md={4} sm={4} xs={6}>
              <Row style={{ justifyContent: 'center' }} className='m-0'>
                <label>{t('supplier.edit.conference.input.room.square.label')}</label>
              </Row>
              <Row className='m-0 mt-1 mb-1'>
                <img style={{ width: '100%' }} src={Square} alt='' />
              </Row>
              <Row className='m-0'>
                <TextInput
                  name='capacity_cabinett'
                  className='borderPrimary'
                  label=''
                  placeholder={t('supplier.edit.conference.input.room.classroom.placeholder')}
                  value={this.state.capacity_cabinett}
                  onChange={this.handleChange}
                />
              </Row>
            </Col>
          </Row>
          <Row className='mt-3 mb-3'>
            <Col className='mt-1 mb-1' xs={12}>
              <h6>{t('supplier.edit.conference.input.room.upload_images.label')}</h6>
            </Col>
          </Row>
          <Row className='m-0 mt-1 mb-3'>
            {this.state.images && this.state.images.length > 0 ? (
              this.state.images.map((image, idx) =>
                image._destroy ? null : (
                  <Col key={idx} className='pl-0 pt-3' lg={3} md={4} sm={6} xs={12}>
                    <UploadImage
                      label={t('click_here_to_upload')}
                      imageUrl={image.url ? image.url : null}
                      movable
                      canMoveLeft={idx > 0}
                      canMoveRight={idx < this.state.images.length - 1}
                      uploadWidget={this.props.uploadWidget}
                      onUploadComplete={response => this.handleImageUploadComplete(idx, response)}
                      onRemove={() => this.removeImage(idx)}
                      onMoveLeft={() => this.moveImage(idx, idx - 1)}
                      onMoveRight={() => this.moveImage(idx, idx + 1)}
                    />
                  </Col>
                )
              )
            ) : (
              <Col className='pl-0 pt-3 d-flex justify-content-center align-items-center' lg={3} md={4} sm={6} xs={12}>
                <UploadImage
                  label={t('click_here_to_upload')}
                  imageUrl=''
                  mode='c_fill,g_auto'
                  uploadWidget={this.props.uploadWidget}
                  onUploadComplete={response => this.handleImageUploadComplete(null, response)}
                />
              </Col>
            )}
          </Row>
          <Row className='m-0 mt-3 mb-2 pl-0 pr-4'>
            <Col className='p-0' xl={3} lg={4} md={4} sm={6}>
              <ButtonWithIcon
                className='client-theme--border-color'
                border='1px solid'
                label={t('add_image')}
                icon='fa fa-plus'
                onClick={this.handleAddImage}
              />
            </Col>
          </Row>
          <Row className='m-0 mt-5 mb-3'>
            <Col className='p-0' xl={3} lg={4} md={4} sm={6}>
              {this.state.roomplan_file_url ? (
                <Col className='mb-3' lg={3} md={4} sm={6} xs={12}>
                  <img src={this.state.roomplan_file_url.replace('/upload/', '/upload/h_240,w_360,c_fill/')} alt='' />
                </Col>
              ) : null}
              <ButtonWithIcon
                className='client-theme--border-color'
                border='1px solid'
                label={t('supplier.edit.button.upload_floor_plan')}
                icon='fa fa-upload'
                onClick={() => this.props.uploadWidget.open(this.handlePlanUpload, window.CLOUDINARY_UPLOAD_PRESET_FLOOR_PLAN)}
              />
            </Col>
          </Row>

          <Row>
            <Col className='mt-3' md={3}>
              <ButtonWithIcon
                labelColor='#FFFFFF'
                iconColor='#FFFFFF'
                padding='12px'
                icon='fa fa-close'
                label={t('common.remove')}
                className='btn btn-danger c-btn-border1-2x c-btn-uppercase c-btn-bold rounded-pill'
                onClick={() => this.props.onRemove(this.props.idx)}
              />
            </Col>
          </Row>
        </>) : null
    )
  }
}

ConferenceRoom.propTypes = {
  uploadWidget: PropTypes.object,
  conferenceRoom: PropTypes.object,
  idx: PropTypes.number,
  onChange: PropTypes.func
}

export default withTranslation()(ConferenceRoom)
