// <TiledLinkBoard items={searchPresets} />

import React from 'react'
import PropTypes from 'prop-types'
import './TiledLinkBoard.scss'
import { Link } from 'react-router-dom'

// props.items: array of {key: string, label: string, link: (object for Link.to)}
//
// Pass exactly 7 items for the best viewing experience.
// More or less will also be handled perfectly fine, but it may not look as pretty.
// Apply more magic to the CSS if you want to handle such cases.
//
export function TiledLinkBoard ({ items }) {
  function renderImage (anImage) {
    switch (typeof anImage) {
      case 'string':
        return <img src={anImage} alt='' />
      case 'function': // is there other way to test that it's a React component? Does it matter?
        return anImage()
      default:
        return anImage // ..and let the caller be responsible for whatever happens.
    }
  }

  function renderItem (item) {
    return (
      <Link className='' key={item.key} to={item.link}>
        <span>{item.label}</span>
        {item.image ? renderImage(item.image) : null}
      </Link>
    )
  }

  return (
    <div className='TiledLinkBoard'>
      {items.map(renderItem)}
    </div>
  )
}

TiledLinkBoard.propTypes = {
  items: PropTypes.array.isRequired
}

export default TiledLinkBoard
