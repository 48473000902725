import React from 'react'
import './SupplierCardLite.scss'
import { getSupplierURL, hasValidContracts, getDeliverableSpecificImages } from '../../shared/utils'
import { withRouter, Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { connectInsightedHit } from '../../services/algolia'
import SuppliersIndexParams from '../../shared/suppliersIndexParams'
import { extractQueryParams } from '../../services/urlStateParser'
import Tooltip from 'rc-tooltip'
import defaultThumbnail from '../../assets/images/blank-hotel.jpg'
import 'rc-tooltip/assets/bootstrap_white.css'
import SupplierMarkup from '../SupplierMarkup/SupplierMarkup'

class SupplierCardLite extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.handleItemClick = this.handleItemClick.bind(this)
  }

  handleItemClick (params) {
    const supplierUrl = getSupplierURL(params, this.props.hit.objectID, this.props.hit.deliverable_types).split('?')
    const supplierUrlPathName = supplierUrl[0]
    const supplierUrlSearch = supplierUrl[1] || ''

    if (this.props.insights) {
      this.props.insights('clickedObjectIDsAfterSearch', { eventName: 'Supplier Clicked' })
    }

    this.props.history.push({
      pathname: supplierUrlPathName,
      search: `?${supplierUrlSearch}`
    })
  }

  render () {
    const { t } = this.props
    const queryParams = extractQueryParams(this.props)
    const suppliersIndexParams = new SuppliersIndexParams(queryParams)
    const projectStartDateUnix = suppliersIndexParams.getStartDateUnix()
    const deliverableImages = getDeliverableSpecificImages(this.props.hit.thumbnails || [], queryParams.type)
    return (
      <div test-id='supplier-card-lite' className='supplier-card-lite-wrapper'>
        <div onClick={this.handleItemClick} className='image-container'>
          <img
            src={deliverableImages && deliverableImages.length > 0 ? deliverableImages[0].url : defaultThumbnail}
            onError={e => {
              e.target.onerror = console.log('Error Loading Image')
              e.target.src = defaultThumbnail
            }}
            alt=''
          />
        </div>
        {hasValidContracts(this.props.hit.contracts, projectStartDateUnix) ? (
          <div className='ribbon-professional' aria-hidden='true' style={{ position: 'absolute', zIndex: '1002' }}>
            <span className='client-theme--supplier-card-ribbon'>{t('contract')}</span>
          </div>
        ) : null}
        <div className='details-overlay'>
          <SupplierMarkup hit={this.props.hit} backgroundClass='' />
          <div className='supplierName'>
            <Tooltip
              placement='bottomLeft'
              trigger={['hover', 'focus']}
              overlay={this.props.hit.name}
              arrowContent={<div className='rc-tooltip-arrow-inner' />}
            >
              <Link tabIndex='-1' to={getSupplierURL(queryParams, this.props.hit.objectID, this.props.hit.deliverable_types)}>
                {this.props.hit.name}
              </Link>
            </Tooltip>
          </div>
          <div className='supplierStreet'>
            <h5 className='supplierStreetHeading' style={{}}>
              <span className='supplierStreetMarker' style={{}}>
                <i className='far fa-map-marker-alt' />
              </span>
              {this.props.hit.post_code && this.props.hit.city
                ? this.props.hit.post_code + ' ' + this.props.hit.city
                : this.props.hit.street}
            </h5>
          </div>
        </div>
      </div>
    )
  }
}
export default connectInsightedHit(withRouter(withTranslation()(SupplierCardLite)))
