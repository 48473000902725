import React from 'react'
import './SupplierFeaturesBox.scss'
import VisaImage from '../../assets/images/payments/visa.png'
import MaestroImage from '../../assets/images/payments/ec_maestro.png'
import AmexImage from '../../assets/images/payments/amex.png'
import MastercardImage from '../../assets/images/payments/master_card.png'
import DinersImage from '../../assets/images/payments/diners.png'
import JCBImage from '../../assets/images/payments/jcb.png'
import AirplusImage from '../../assets/images/payments/airplus.png'

class SupplierFeaturesBox extends React.Component {
  renderIcon (value) {
    if (value === 'iconNo') {
      return <i className='fa fa-close c-font-red' aria-hidden='true' />
    } else if (value === 'iconYes') {
      return <i className='fa fa-check c-font-green' aria-hidden='true' />
    } else {
      return value
    }
  }

  render () {
    return (
      <div
        className='c-content-media-1 supplier-feature-box-round'
        style={{ height: '100%' }}
      >
        <div className='c-content-label c-font-uppercase c-font-bold'>
          {this.props.title ? this.props.title : 'Info'}
        </div>
        <ul className='supplierFeaturesList'>
          {this.props.features &&
            this.props.features.map((item, idx) => (
              <li key={idx} className='supplierFeaturesListItem'>
                <span className='supplierFeaturesListItemLabel'>
                  {item.label}
                </span>
                <span className='supplierFeaturesListItemValue'>
                  {item.value === 'iconNo' || item.value === 'iconYes'
                    ? this.renderIcon(item.value)
                    : item.value
                      ? item.value
                      : '-'}
                </span>
              </li>
            ))}
        </ul>
        <div className='paymentIconsContainer'>
          {this.props.paymentIcons && this.props.paymentIcons.visa ? (
            <div className='paymentsIcon'>
              <img src={VisaImage} />
            </div>
          ) : null}
          {this.props.paymentIcons && this.props.paymentIcons.master_card ? (
            <div className='paymentsIcon'>
              <img src={MastercardImage} />
            </div>
          ) : null}
          {this.props.paymentIcons && this.props.paymentIcons.amex ? (
            <div className='paymentsIcon'>
              <img src={AmexImage} />
            </div>
          ) : null}
          {this.props.paymentIcons && this.props.paymentIcons.ec_maestro ? (
            <div className='paymentsIcon'>
              <img src={MaestroImage} />
            </div>
          ) : null}
          {this.props.paymentIcons && this.props.paymentIcons.diners ? (
            <div className='paymentsIcon'>
              <img src={DinersImage} />
            </div>
          ) : null}
          {this.props.paymentIcons && this.props.paymentIcons.jcb ? (
            <div className='paymentsIcon'>
              <img src={JCBImage} />
            </div>
          ) : null}
          {this.props.paymentIcons && this.props.paymentIcons.airplus ? (
            <div className='paymentsIcon'>
              <img style={{ width: '22px', marginTop: '3px' }} src={AirplusImage} />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
export default SupplierFeaturesBox
