import React from 'react'
import { withTranslation } from 'react-i18next'
import queryString from 'qs'
import { withRouter } from 'react-router-dom'
import './CityPoiCard.scss'
import { extractQueryParams } from '../../services/urlStateParser'

class CityPoiCard extends React.Component {
  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  iconClassName () {
    switch (this.props.poi.poi_type) {
      case 'airport':
        return 'fa fa-fw fa-plane'
      case 'train_station':
        return 'fa fa-fw fa-train'
      case 'city_center':
        return 'fa fa-fw fa-building'
      default:
        return 'fa fa-fw fa-bullseye'
    }
  };

  handleClick () {
    const searchParams = this.props.poi.poi_type === 'region'
      ? { region: this.props.poi.id }
      : { location: this.props.poi.google_place_id }
    const params = extractQueryParams(this.props)
    if (searchParams.region) {
      params.location = null
    }
    this.props.history.push({
      pathname: '/results',
      search: queryString.stringify({ ...params, ...searchParams, show_map: true })
    })
  }

  render () {
    const { poi, i18n } = this.props

    return (
      <div
        className='city-poi-card'
        onClick={this.handleClick}
      >
        <div className='city-poi-card__icon'>
          <i
            className={this.iconClassName()}
          />
        </div>
        <div className='city-poi-card__text c-font-uppercase c-title'>
          {poi[`name_${i18n.language}`]}
        </div>
      </div>
    )
  }
}

export default withRouter(withTranslation()(CityPoiCard))
