import React from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import { withRouter } from 'react-router-dom'
import Container from './Layout/Container'
import { TextInput } from '../FormElements/FormElements'
import SupplierRecord from './Components/SupplierRecord'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import { withTranslation } from 'react-i18next'
import './SupplierAdmin.scss'
import Can from '../Layout/Can'
import { scrollToTop } from '../../shared/utils'
import feedbackBoxImage from '../../assets/images/feedback_box_4.png'
import { axiosOnce } from '../../shared/axios'
import { errorHandler } from '../../shared/errorHandler'

class Records extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      search: '',
      page: 1,
      pageCount: 1,
      records: [],
      searchResult: [],
      isLoading: false
    }

    this.handleOnAddNewType = this.handleOnAddNewType.bind(this)
    this.deliverables = this.deliverables.bind(this)
    this.buildRecords = this.buildRecords.bind(this)
    this.flattenRecords = this.flattenRecords.bind(this)
    this.updateSearchResult = this.updateSearchResult.bind(this)
    this.handleOnValueChanged = this.handleOnValueChanged.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
  }

  componentDidMount () {
    this.buildRecords()
  }

  componentDidUpdate (prevProps) {
    if (this.props !== prevProps) {
      this.buildRecords()
    }
  }

  buildRecords () {
    const supplier = this.props.supplier
    const suppliers = supplier.isA('KonvenitUser') ? supplier.managed_suppliers : [supplier]
    const records = this.flattenRecords(suppliers)

    this.setState({
      searchResult: records,
      pageCount: supplier.managed_suppliers_count
    })
  }

  deliverables (supplier) {
    return ((supplier && supplier.deliverables) || [])
  }

  flattenRecords (suppliers) {
    const records = []

    suppliers.forEach((supplier) => {
      this.deliverables(supplier).forEach((deliverable) => {
        records.push({
          supplier,
          deliverable
        })
      })
    })

    return records
  }

  fetchSuppliers () {
    this.setState({ isLoading: true })

    const searchQuery = [
      `page=${this.state.page}`,
      `query=${this.state.search}`
    ].join('&')
    const path = `/sales_managers/${this.props.match.params.objectID}/admin_records.json?${searchQuery}`

    axiosOnce({
      method: 'get',
      url: path
    })
      .then(response => {
        this.setState({
          searchResult: this.flattenRecords(response.data.managed_suppliers),
          pageCount: response.data.managed_suppliers_count,
          isLoading: false
        })
      })
      .catch(errorHandler)
  }

  updateSearchResult () {
    this.fetchSuppliers()
  }

  handleOnAddNewType () {
    this.props.history.push(`/supplier/record/new_type/${this.props.supplier.id}`)
  }

  handlePageClick (data) {
    const page = data.selected

    this.setState({
      page: page + 1
    }, () => {
      scrollToTop()
      this.updateSearchResult()
    })
  }

  handleOnValueChanged (event) {
    if (event && event.currentTarget) {
      const value = event.currentTarget.value ? event.currentTarget.value : ''
      this.setState({
        search: value,
        page: 1,
        searchResult: []
      }, () => {
        this.updateSearchResult()
      })
    }
  }

  render () {
    const { t } = this.props

    return (
      <Container>
        <div className='container'>
          <div className='row' style={{ paddingTop: '20px' }}>
            <div className='col-md-8' style={{ paddingRight: '0px' }}>
              {this.props.supplier.isA('KonvenitUser') && (
                <TextInput
                  name='search'
                  className='borderPrimary'
                  placeholder={t('supplier.admin.records.search')}
                  containerClassName='c-search-input c-margin-b-20'
                  value={this.state.search}
                  onChange={this.handleOnValueChanged}
                />
              )}
              {!this.state.isLoading ? (
                this.state.searchResult.length > 0 ? (
                  this.state.searchResult.map((record, index) => (
                    <SupplierRecord
                      supplier={record.supplier}
                      deliverable={record.deliverable}
                      key={`supplier-record-${index}`}
                      onDelete={this.props.onDeleteType}
                    />
                  ))
                ) : (
                  <div className='col-md-12 c-margin-b-20'>
                    <h2>{t('common.not_found')}</h2>
                  </div>
                )
              ) : (
                <div className='col-md-12 c-margin-b-20'>
                  <h2>{t('common.loading')}</h2>
                </div>
              )}

              {this.state.pageCount && this.state.searchResult.length > 0 && (
                <ReactPaginate
                  previousLabel='<'
                  nextLabel='>'
                  breakLabel='...'
                  breakClassName='break-me'
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName='c-content-pagination c-theme'
                  subContainerClassName='pages pagination'
                  activeClassName='c-active'
                />
              )}

              <Can perform='create' on='DeliverableType'>
                <div style={{ maxWidth: '250px', marginBottom: '50px' }}>
                  <ButtonWithIcon
                    labelColor='#FFFFFF'
                    iconColor='#FFFFFF'
                    padding='12px'
                    icon='fa fa-plus'
                    label={t('supplier.admin.records.add_new_type')}
                    className='client-theme--bg'
                    onClick={this.handleOnAddNewType}
                  />
                </div>
              </Can>

            </div>
            <div className='col-md-4'>
              <div className='c-container c-bg-white c-bg-img-bottom-right info-account' style={{ backgroundImage: `url(${feedbackBoxImage})` }}>
                <div className='c-content-title-1 c-inverse c-overflow-hide'>
                  <h3 className='c-font-uppercase c-font-dark c-font-bold' style={{ fontSize: '24px' }}>
                    {t('supplier.admin.records.sidebar.explanation.title')}
                  </h3>
                  <div className='c-line-left' />
                  <p className='c-font c-font-dark'>
                    {t('supplier.admin.records.sidebar.explanation.text')}
                  </p>
                </div>
              </div>
              <div style={{ width: '100%', padding: '25px', marginTop: '20px', paddingTop: '15px' }} className='c-theme-bg'>
                <h3 className='c-font-uppercase c-font-white c-font-bold' style={{ fontSize: '24px' }}>
                  {t('supplier.admin.records.sidebar.help.title')}
                </h3>

                <p className='c-font c-font-white' style={{ fontSize: '20px', fontWeight: 600 }}>
                  <i style={{ paddingRight: '6px' }} className='fa fa-phone' aria-hidden='true' />
                  +49 8168 99 74 1000
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

Records.propTypes = {
  supplier: PropTypes.object,
  history: PropTypes.object,
  t: PropTypes.func,
  match: PropTypes.object,
  onDeleteType: PropTypes.func
}

export default withTranslation()(withRouter(Records))
