import React, { PureComponent } from 'react'
import { CustomMarker } from 'react-instantsearch-dom-maps'
import SupplierCard from '../SupplierCard/SupplierCard'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getDeliverableSpecificImages } from '../../shared/utils'
import './SupplierMapMarker.scss'
import { getGoogleMaps } from '../../services/googleMaps'
import DistanceDurationBox from '../DistanceDurationBox/DistanceDurationBox'
import { changeOpenedSupplierPopup } from '../../redux/actions'
import defaultThumbnail from '../../assets/images/blank-hotel.jpg'

export class SupplierMapMarker extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      distance: null,
      duration: null,
      showsDistance: false
    }
    this.googleMaps = getGoogleMaps()
    this.handleTogglePopup = this.handleTogglePopup.bind(this)
    this.renderTagElement = this.renderTagElement.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.setDirectionData = this.setDirectionData.bind(this)
    this.getFirstDeliverableImage = this.getFirstDeliverableImage.bind(this)
  }

  handleTogglePopup () {
    if (this.props.disablePopup) {
      return
    }
    if (this.props.openedSupplierPopup && this.props.hit.objectID === this.props.openedSupplierPopup.objectID) {
      this.props.changeOpenedSupplierPopup(undefined)
    } else {
      this.props.changeOpenedSupplierPopup(this.props.hit)
    }
  }

  isInProject () {
    const requestedSuppliers = (this.props.searchSession && this.props.searchSession.requested_suppliers) || []
    return requestedSuppliers.includes(this.props.hit.objectID)
  }

  isInCart () {
    if (this.props.cartItems && this.props.cartItems.length > 0) {
      return this.props.cartItems.some(item => item.objectID === this.props.hit.objectID)
    }
  }

  renderTagElement () {
    if (this.isInCart()) {
      return (
        <div className='tag'>
          <i className='fa fa-shopping-bag' />
        </div>
      )
    } else if (this.isInProject()) {
      return (
        <div className='tag'>
          <i className='fa fa-check' />
        </div>
      )
    }

    return null
  }

  handleMouseEnter () {
    // get direction data
    const isDestinationValid = this.props.searchLocation && this.props.searchLocation.lat && this.props.searchLocation.lng
    if (!this.state.showsDistance && isDestinationValid) {
      this.setDirectionData()
    }
  }

  setDirectionData () {
    const directionService = new window.google.maps.DistanceMatrixService()
    const origin = new window.google.maps.LatLng(this.props.hit._geoloc.lat, this.props.hit._geoloc.lng)
    const destination = new window.google.maps.LatLng(this.props.searchLocation.lat, this.props.searchLocation.lng)
    return directionService.getDistanceMatrix({
      origins: [origin],
      destinations: [destination],
      travelMode: 'DRIVING'
    }, (result, status) => {
      if (status === 'OK') {
        if (result && result.rows && result.rows.length > 0) {
          const { distance, duration } = result.rows[0].elements[0]
          if (distance || duration) {
            this.setState({ distance, duration, showsDistance: true })
          }
        }
      } else {
        this.setState({ showsDistance: false })
      }
    })
  }

  getFirstDeliverableImage () {
    const deliverableImages = getDeliverableSpecificImages(this.props.hit.thumbnails || [])
    if (deliverableImages && deliverableImages.length > 0) {
      return deliverableImages[0].thumbnail
    } else {
      return defaultThumbnail
    }
  }

  render () {
    const { isHighlighted } = this.props
    return (
      <CustomMarker
        onMouseEnter={this.handleMouseEnter}
        hit={this.props.hit}
        className={`mapMarker ${isHighlighted ? 'mapMarker--highlighted ' : ''}`}
      >
        <div className='mapMarkerPopup'>
          <span className='mapMarkerPopup__close-button' onClick={this.handleTogglePopup}>
            <i className='ti-close' />
          </span>
          <SupplierCard hit={this.props.hit} />
        </div>
        <div className='title'>{this.props.hit.name}</div>
        {this.state.showsDistance ? (
          <DistanceDurationBox
            distance={this.state.distance}
            duration={this.state.duration}
          />) : null}
        <div className='markerWrapper' onClick={this.handleTogglePopup}>
          {this.renderTagElement()}
          <div className='pin'>
            {isHighlighted ? (
              <div className='image'>
                <i className='fa fa-map-marker' />
              </div>
            ) : (
              <div
                className='image'
                style={{
                  backgroundImage: `url("${this.getFirstDeliverableImage()}")`
                }}
              />
            )}
          </div>
        </div>
      </CustomMarker>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeOpenedSupplierPopup: payload => dispatch(changeOpenedSupplierPopup(payload))
  }
}

SupplierMapMarker.propTypes = {
  cartItems: PropTypes.arrayOf(PropTypes.object),
  openedSupplierPopup: PropTypes.object
}

export default connect(null, mapDispatchToProps)(SupplierMapMarker)
