import React from 'react'
import axios from 'axios'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import { Row, Col, Card, CardBody } from 'reactstrap'
import ButtonWithIcon from '../../../ButtonWithIcon/ButtonWithIcon'
import TextInputGroup from '../../../TextInputGroup/TextInputGroup'
import { TextInput } from '../../../FormElements/FormElements'
import ConferenceRoom from './ConferenceRoom'
import ValidationErrors from '../../../ValidationErrors/ValidationErrors'
import Loading from '../Loading'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import { getSelectedOptions, areAttributesMappedToBool, sortArrayByKey, scrollToTop, getButtonLabel, getButtonIcon, isButtonDisabled } from '../../../../shared/utils'
import FormStatus from '../../../FormStatus/FormStatus'
class ConferenceRooms extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      supplier: {},
      validationErrors: null,
      hasUserMadeChanges: false,
      submitStatus: 'nothing-to-save'
    }

    this.keyDeliverable = null
    this.handleChange = this.handleChange.bind(this)
    this.handleConferenceRoomChange = this.handleConferenceRoomChange.bind(this)
    this.handleAddNewConferenceRoom = this.handleAddNewConferenceRoom.bind(this)
    this.handleRemoveConferenceRoom = this.handleRemoveConferenceRoom.bind(this)
    this.handleMultipleSelectChange = this.handleMultipleSelectChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  componentDidMount () {
    if (this.props.deliverableType) {
      this.keyDeliverable = `supplier_${this.props.deliverableType}`
    }

    this.initializeStateWithExistingFormData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.supplier !== this.props.supplier) {
      this.initializeStateWithExistingFormData()
    }

    if (prevProps.deliverableType !== this.props.deliverableType) {
      this.keyDeliverable = `supplier_${this.props.deliverableType}`
    }

    // we only need to take action for the first change made by the user
    if (!prevState.hasUserMadeChanges && this.state.hasUserMadeChanges) {
      this.props.updateHandler(null, 'ConferenceRooms')
      this.setState({ submitStatus: 'ready-to-save' })
    }
  }

  initializeStateWithExistingFormData () {
    if (this.props.supplier) {
      this.setState({ supplier: { ...this.props.supplier }, hasUserMadeChanges: false, submitStatus: 'nothing-to-save' })
    }
  }

  handleChange (event) {
    if (event && event.currentTarget) {
      const supplierDeliverableInfo = { ...this.state.supplier[this.keyDeliverable] }
      const value = event.currentTarget.type === 'checkbox' ? event.currentTarget.checked : event.currentTarget.value
      supplierDeliverableInfo[event.currentTarget.name] = value
      this.setState({ supplier: { ...this.state.supplier, [this.keyDeliverable]: supplierDeliverableInfo }, hasUserMadeChanges: true })
    }
  }

  handleConferenceRoomChange (idx, conferenceRoom) {
    const conferenceRooms = [...this.state.supplier.conference_rooms]

    conferenceRooms[idx] = conferenceRoom
    this.setState({ supplier: { ...this.state.supplier, conference_rooms: conferenceRooms }, hasUserMadeChanges: true })
  }

  handleAddNewConferenceRoom () {
    const conferenceRooms = [...this.state.supplier.conference_rooms]
    conferenceRooms.push({ images: [] })

    this.setState({ supplier: { ...this.state.supplier, conference_rooms: conferenceRooms }, hasUserMadeChanges: true })
  }

  handleRemoveConferenceRoom (idx) {
    const conferenceRooms = [...this.state.supplier.conference_rooms]
    const conferenceRoom = { ...this.state.supplier.conference_rooms[idx] }

    conferenceRoom._destroy = true
    conferenceRooms[idx] = conferenceRoom
    this.setState({ supplier: { ...this.state.supplier, conference_rooms: conferenceRooms }, hasUserMadeChanges: true })
  }

  handleMultipleSelectChange (attributeName, values, deliverable = null) {
    if (attributeName) {
      let supplier = { ...this.state.supplier }

      if (deliverable) {
        const filteredOptions = this.mapValuesToObject(supplier[this.keyDeliverable][attributeName], values)

        if (areAttributesMappedToBool(attributeName)) {
          const mappedBoolValues = this.mapValuesToBool(filteredOptions)
          supplier[this.keyDeliverable] = { ...supplier[this.keyDeliverable], ...mappedBoolValues }
        }

        supplier[this.keyDeliverable] = { ...supplier[this.keyDeliverable], [attributeName]: filteredOptions }
      } else {
        const filteredOptions = this.mapValuesToObject(supplier[attributeName], values)

        if (areAttributesMappedToBool(attributeName)) {
          const mappedBoolValues = this.mapValuesToBool(filteredOptions)
          supplier = { ...supplier, ...mappedBoolValues }
        }

        supplier[attributeName] = filteredOptions
      }

      this.setState({ supplier: supplier, hasUserMadeChanges: true })
    }
  }

  handleReset () {
    this.setState({
      validationErrors: null,
      hasUserMadeChanges: false,
      submitStatus: 'nothing-to-save',
      wasFormReset: true
    }, this.props.resetHandler())
  }

  handleSubmit () {
    this.setState({ submitStatus: 'in-progress' })

    axios.defaults.withCredentials = true
    axios
      .put(`/suppliers/${this.props.supplier.id}.json`, { ...this.getFormattedFormData(), locale: this.props.i18n.language })
      .then(response => {
        this.setState({
          validationErrors: null,
          hasUserMadeChanges: false,
          submitStatus: 'save-successful'
        })
        this.props.updateHandler(response.data, null)
      })
      .catch(error => {
        this.setState({
          validationErrors: error.response.data && error.response.data.errors,
          submitStatus: 'submit-error',
          hasUserMadeChanges: true
        })
        scrollToTop()
      })
  }

  getFormattedFormData () {
    const supplier = { ...this.state.supplier }

    supplier[`${this.keyDeliverable}_attributes`] = supplier[this.keyDeliverable]

    supplier.conference_rooms = supplier.conference_rooms.map((conferenceRoom) => {
      conferenceRoom.name_en = conferenceRoom.name_de
      conferenceRoom.images_attributes = conferenceRoom.images
      return conferenceRoom
    })

    supplier.conference_rooms_attributes = supplier.conference_rooms

    return {
      facet: 'conference_rooms',
      deliverable_type: this.props.deliverableType,
      locale: this.props.i18n.language,
      supplier: supplier
    }
  }

  mapValuesToObject (attributeOptions, values) {
    return attributeOptions.map((option) => {
      if (values && values.find(value => value.attribute_name === option.attribute_name)) {
        option.checked = true
      } else {
        option.checked = false
      }
      return option
    })
  }

  mapValuesToBool (values) {
    let mappedValues = {}

    if (values && values.length > 0) {
      values.map(value => {
        mappedValues = { ...mappedValues, [value.attribute_name]: value.checked }
      })
    }

    return mappedValues
  }

  renderConferenceRooms () {
    const { t, i18n } = this.props

    return (
      this.state.supplier && this.state.supplier[this.keyDeliverable] ? (
        <div className='containerFluid'>
          {this.state.hasUserMadeChanges ? (
            <Col className='p-0 d-flex justify-content-center align-items-center'>
              <ButtonWithIcon
                labelColor='#FFFFFF'
                iconColor='#FFFFFF'
                padding='12px'
                margin='10px'
                icon={getButtonIcon('reset', this.state.submitStatus, this.state.hasUserMadeChanges)}
                label={getButtonLabel('reset', this.state.submitStatus, this.state.hasUserMadeChanges)}
                className='btn-danger c-btn-square c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold'
                onClick={this.handleReset}
                disabled={isButtonDisabled(this.state.submitStatus)}
              />
            </Col>
          ) : null}
          <ValidationErrors validationErrors={this.state.validationErrors} />

          <Accordion
            id='conferenceAcc'
            allowMultipleExpanded
            allowZeroExpanded
          >
            <Row className='m-0 mt-3 mb-3'>
              <Col className='p-0' xs={12}>
                <Card>
                  <CardBody>
                    <AccordionItem>
                      <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                        <AccordionItemButton>
                          <h5 className='u-position-relative'>
                            {t('supplier.edit.conference.heading.general_property_information')}
                            <div className='accordion__arrow' role='presentation' />
                          </h5>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <Row className='mt-3 mb-3'>
                          <Col md={6} sm={8} xs={12}>
                            <TextInput
                              id='propertyNumberConferenceRooms'
                              name='capacity_number_of_conference_rooms'
                              className='borderPrimary'
                              label={t('supplier.edit.conference.input.number_of_conference_rooms.label')}
                              placeholder={t('supplier.edit.conference.input.number_of_conference_rooms.placeholder')}
                              value={this.state.supplier[this.keyDeliverable].capacity_number_of_conference_rooms || ''}
                              onChange={this.handleChange}
                            />
                          </Col>
                          <Col md={6} sm={8} xs={12}>
                            <TextInput
                              id='propertyMaxConferenceCapacity'
                              name='capacity_max_conference_capacity'
                              className='borderPrimary'
                              label={t('supplier.edit.conference.input.max_conference_capacity.label')}
                              placeholder={t('supplier.edit.conference.input.max_conference_capacity.placeholder')}
                              value={this.state.supplier[this.keyDeliverable].capacity_max_conference_capacity || ''}
                              onChange={this.handleChange}
                            />
                          </Col>
                        </Row>
                        <Row className='mt-3 mb-3'>
                          <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                            <TextInputGroup
                              id='propertyTotalEventArea'
                              name='capacity_event_floor_space'
                              label={t('supplier.edit.conference.input.total_event_area.label')}
                              className='borderPrimary'
                              placeholder={t('supplier.edit.conference.input.total_event_area.placeholder')}
                              value={this.state.supplier[this.keyDeliverable].capacity_event_floor_space || ''}
                              appendText='m2'
                              onChange={this.handleChange}
                            />
                          </Col>
                          <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                            <TextInputGroup
                              id='propertyLargestRoomArea'
                              name='capacity_max_square_meter'
                              className='borderPrimary'
                              label={t('supplier.edit.conference.input.largest_room_area.label')}
                              placeholder={t('supplier.edit.conference.input.largest_room_area.placeholder')}
                              value={this.state.supplier[this.keyDeliverable].capacity_max_square_meter || ''}
                              appendText='m2'
                              onChange={this.handleChange}
                            />
                          </Col>
                          <Col className='mt-2 mb-2' md={3} sm={6} xs={12}>
                            <TextInputGroup
                              id='propertyMaxCeilingHeight'
                              name='capacity_max_height'
                              label={t('supplier.edit.conference.input.max_ceiling_height.label')}
                              className='borderPrimary'
                              placeholder={t('supplier.edit.conference.input.max_ceiling_height.placeholder')}
                              value={this.state.supplier[this.keyDeliverable].capacity_max_height || ''}
                              appendText='m'
                              onChange={this.handleChange}
                            />
                          </Col>
                        </Row>
                        <Row className='mt-3 mb-3'>
                          <Col md={6} sm={8} xs={12}>
                            <label htmlFor='suitableFors'>{t('suitable_for_meeting_types')}</label>
                            <Select
                              id='suitableFors'
                              name='suitable_fors'
                              className='defaultSelectDropdown'
                              classNamePrefix='defaultSelectDropdown'
                              options={sortArrayByKey(this.props.supplier[this.keyDeliverable].suitable_fors, `name_${i18n.language}`)}
                              getOptionValue={(option) => option.attribute_name}
                              getOptionLabel={option => option[`name_${i18n.language}`]}
                              placeholder={t('select_all_that_apply')}
                              value={getSelectedOptions(this.props.supplier[this.keyDeliverable].suitable_fors)}
                              isSearchable
                              isMulti
                              onChange={(values) => this.handleMultipleSelectChange('suitable_fors', values, true)}
                              style={{ margin: '6px 0' }}
                            />
                          </Col>
                          <Col md={6} sm={8} xs={12}>
                            <label htmlFor='equipmentAmenities'>{t('supplier.edit.conference.input.equipments.label')}</label>
                            <Select
                              id='equipmentAmenities'
                              name='equipment_amenities'
                              className='defaultSelectDropdown'
                              classNamePrefix='defaultSelectDropdown'
                              options={sortArrayByKey(this.props.supplier[this.keyDeliverable].equipment_amenities, `name_${i18n.language}`)}
                              getOptionValue={(option) => option.attribute_name}
                              getOptionLabel={option => option[`name_${i18n.language}`]}
                              placeholder={t('select_all_that_apply')}
                              value={getSelectedOptions(this.props.supplier[this.keyDeliverable].equipment_amenities)}
                              isSearchable
                              isMulti
                              onChange={(values) => this.handleMultipleSelectChange('equipment_amenities', values, true)}
                              style={{ margin: '6px 0' }}
                            />
                          </Col>
                        </Row>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {this.state.supplier && this.state.supplier.conference_rooms
              ? this.state.supplier.conference_rooms.map((conferenceRoom, idx) => (
                <Row style={{ display: conferenceRoom._destroy ? 'none' : 'flex' }} key={idx} className='m-0 mt-3 mb-3'>
                  <Col className='p-0' xs={12}>
                    <Card>
                      <CardBody>
                        <AccordionItem>
                          <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                            <AccordionItemButton>
                              <h5 className='u-position-relative'>
                                {conferenceRoom.name_de
                                  ? conferenceRoom.name_de
                                  : t('supplier.edit.conference.default_name')}
                                <div className='accordion__arrow' role='presentation' />
                              </h5>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <ConferenceRoom
                              idx={idx}
                              conferenceRoom={conferenceRoom}
                              uploadWidget={this.props.uploadWidget}
                              onChange={this.handleConferenceRoomChange}
                              onRemove={idx => this.handleRemoveConferenceRoom(idx)}
                            />
                          </AccordionItemPanel>
                        </AccordionItem>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ))
              : null}
            <Row className='m-0 mt-3 mb-3'>
              <Col className='p-0' xs={12}>
                <Card className='mb-4'>
                  <CardBody className='room-groups__add_button'>
                    <ButtonWithIcon
                      labelColor='#FFFFFF'
                      iconColor='#FFFFFF'
                      padding='12px'
                      icon='fa fa-plus'
                      label={t('supplier.edit.conference.add')}
                      className='btn c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold rounded-pill'
                      onClick={this.handleAddNewConferenceRoom}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Accordion>

          <Row className='m-0 mt-4 mb-4'>
            <Col className='pl-0' xl={4} md={4} sm={6} xs={12}>
              <ButtonWithIcon
                labelColor='#FFFFFF'
                iconColor='#FFFFFF'
                padding='12px'
                icon={getButtonIcon('submit', this.state.submitStatus, this.state.hasUserMadeChanges)}
                label={getButtonLabel('submit', this.state.submitStatus, this.state.hasUserMadeChanges)}
                className='btn c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold rounded-pill'
                onClick={this.handleSubmit}
                disabled={isButtonDisabled(this.state.submitStatus)}
              />
            </Col>
            <Col className='pl-0' xl={4} md={4} sm={6} xs={12}>
              <FormStatus
                id='supplierEditConference'
                submitStatus={this.state.submitStatus}
                errors={this.state.validationErrors}
              />
            </Col>
          </Row>
        </div>) : null
    )
  }

  render () {
    if (this.props.supplier && this.props.supplier.id) {
      return this.renderConferenceRooms()
    } else {
      return <Loading />
    }
  }
}

export default withTranslation()(ConferenceRooms)
