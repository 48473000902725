import React from 'react'

export const NextArrow = (props) => {
  const { className, style, onClick, customClassName } = props
  return (
    <button
      className={`${className} ${customClassName} react-slick__arrow react-slick__arrow-next`}
      style={{ ...style, display: 'flex' }}
      onClick={onClick}
      tabIndex='-1'
    />
  )
}

export const PrevArrow = (props) => {
  const { className, style, onClick, customClassName } = props

  return (
    <button
      className={`${className} ${customClassName} react-slick__arrow react-slick__arrow-prev`}
      style={{ ...style, display: 'flex' }}
      onClick={onClick}
      tabIndex='-1'
    />
  )
}
