import React from 'react'
import Autosuggest from 'react-autosuggest'
import { withTranslation, getI18n } from 'react-i18next'
import { getGoogleMaps } from '../../services/googleMaps'
import { searchIndex, searchIndexMiceMoment } from '../../services/algolia'
import { connectGeoSearch } from 'react-instantsearch-dom'
import { hasAreaOfOperation, getSuitableNameFromMapping, initAutocompleteService } from '../../shared/utils'
import { connect } from 'react-redux'
import './AutosuggestSearchBox.scss'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
var dayjs = require('dayjs')

class AutosuggestSearchBox extends React.Component {
  constructor (props) {
    super(props)
    this.googleMaps = getGoogleMaps()
    this.googlePlaces = this.googleMaps !== null ? this.googleMaps.places : null
    if (this.googlePlaces) {
      this.autocompleteService = new this.googlePlaces.AutocompleteService()
    } else {
      this.autocompleteService = null
      initAutocompleteService(this)
    }
    this.searchIndex = searchIndex
    this.searchIndexMiceMoment = searchIndexMiceMoment
    this.latestSuggestionsAt = {}

    this.state = {
      value: '',
      supplierSearchKey: '',
      resultsFrom: 'suppliers',
      facetSuggestions: this.buildSection(null, []),
      placeSuggestions: this.buildSection(null, []),
      miceMomentSuggestions: this.buildSection(null, []),
      isUserChangingLocation: false,
      searchButtonDisabled: true,
      momentSearchKey: '',
      isLoading: false
    }

    this.onKeyDown = this.onKeyDown.bind(this)
    this.handleNewSearch = this.handleNewSearch.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this)
    this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this)
    this.loadSuggestions = this.loadSuggestions.bind(this)
    this.renderSectionTitle = this.renderSectionTitle.bind(this)
    this.renderSuggestion = this.renderSuggestion.bind(this)
    this.getSectionSuggestions = this.getSectionSuggestions.bind(this)
    this.getSuggestionValue = this.getSuggestionValue.bind(this)
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this)
    this.loadMiceMomentsSuggestions = this.loadMiceMomentsSuggestions.bind(this)
    this.onClearSearchValue = this.onClearSearchValue.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleUserWantsToChangeLocation = this.handleUserWantsToChangeLocation.bind(this)
    this.onSearchKeyChange = this.onSearchKeyChange.bind(this)
    this.handleSupplierSuggestionSelected = this.handleSupplierSuggestionSelected.bind(this)
    this.handleSupplierSuggestionsFetchRequested = this.handleSupplierSuggestionsFetchRequested.bind(this)
    this.loadSupplierSuggestions = this.loadSupplierSuggestions.bind(this)
    this.onSearchKeyDown = this.onSearchKeyDown.bind(this)
    this.handleMomentSuggestionsFetchRequested = this.handleMomentSuggestionsFetchRequested.bind(this)
    this.handleMomentSuggestionSelected = this.handleMomentSuggestionSelected.bind(this)
    this.renderMomentSuggestion = this.renderMomentSuggestion.bind(this)
    this.placesSearchTimer = null
    this.supplierSearchTimer = null
    this.momentsSearchTimer = null
  }

  componentDidUpdate (prevProps, prevState) {
    const queryParams = this.props.queryParams

    if (prevProps.searchBoundingBox !== this.props.searchBoundingBox) {
      if (queryParams && queryParams.location && queryParams.type && hasAreaOfOperation(queryParams.type)) {
        // Disable geolocation search for supplier type with area of operation
        // No refine for the searchBoundingBox
      } else {
        this.props.refine(this.props.searchBoundingBox)
      }
    }
    if (prevProps.searchLocation !== this.props.searchLocation) {
      if (this.props.searchLocation && this.props.searchLocation.name) {
        this.setState({ value: this.props.searchLocation.name })
      } else {
        this.setState({ value: '' })
      }
    }
  }

  getSearchableFacets () {
    if (!this.searchableFacets) {
      // This predefined list could also be generated by fetching all searchable facets via searchIndex.getSettings().
      // However, we would need to import "algoliasearch" instead of "algoliasearch/lite", causing a larger JS pack.
      // If more searchable facets are to be added, consider changing to that approach. You will need to implement
      // a small parser for the values in facetAttributes, to determine localized attributes. That should be it.
      const language = getI18n().language
      this.searchableFacets = [
        'chain_name',
        'name',
        `humanized_suitable.${language}`,
        `humanized_category.${language}`
      ]
    }
    return this.searchableFacets
  }

  getMiceMomentsSearchableFacets () {
    if (!this.MiceMomentsSearchableFacets) {
      this.MiceMomentsSearchableFacets = [
        'title',
        'hashtags.de'
      ]
    }
    return this.MiceMomentsSearchableFacets
  }

  onKeyDown (event) {
    if (event.key === 'Enter' && !this.suggestionSelectedEnter) {
      // to enable search on enter press, comment the following line
      // this.handleNewSearch()
    }
    this.suggestionSelectedEnter = false
  }

  onSearchKeyDown (event) {
    if (event.key === 'Enter' && !this.suggestionSelectedEnter) {
      // to disable search on enter press, comment the following line
      this.handleNewSearch()
    }
    this.suggestionSelectedEnter = false
  }

  handleNewSearch () {
    this.setState({
      supplierSearchKey: ''
    }, this.props.onSearch(this.state.supplierSearchKey, undefined, { chain_name: '' }))
  }

  onChange (event, { newValue, suggestion }) {
    const tmpVal = newValue === this.state.value ? '' : newValue
    this.setState({
      value: tmpVal, searchButtonDisabled: !(suggestion && suggestion.place_id)
    })
  }

  onSearchKeyChange (name, newValue) {
    const tmpVal = newValue === this.state[name] ? '' : newValue
    this.setState({
      [name]: tmpVal, searchButtonDisabled: !newValue
    })
  }

  getSectionSuggestions (section) {
    return section.entries
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  handleSuggestionsFetchRequested ({ value }) {
    clearTimeout(this.placesSearchTimer)
    this.placesSearchTimer = setTimeout(() => this.loadSuggestions(value), this.props.delay || 400)
  }

  handleSupplierSuggestionsFetchRequested ({ value }) {
    clearTimeout(this.supplierSearchTimer)
    this.supplierSearchTimer = setTimeout(() => this.loadSupplierSuggestions(value), this.props.delay || 400)
  }

  handleMomentSuggestionsFetchRequested ({ value }) {
    clearTimeout(this.momentsSearchTimer)
    this.momentsSearchTimer = setTimeout(() => this.loadMiceMomentsSuggestions(value), this.props.delay || 400)
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  handleSuggestionsClearRequested () {
    this.setState({
      suggestions: []
    })
  }

  renderSectionTitle (section) {
    return <div className='suggestions-section-title'><i className='fal fa-map-marker-alt' />{section.title}</div>
  }

  renderSuggestion (suggestion, { isHighlighted }) {
    let iconClassNames = `suggestion__icon suggestion__icon--${suggestion.type}`
    if (isHighlighted) iconClassNames = `${iconClassNames} client-theme--color`
    return (
      <div className='suggestion'>
        <div className={iconClassNames} />
        <div className='suggestion__label suggestion-label'>
          <div className='suggestion-label__title'>{`${suggestion.name}`}</div>
          <div className='suggestion-label__subtitle'>{suggestion.count ? `${suggestion.count} ` : ''}{suggestion.subtitle}</div>
        </div>
      </div>
    )
  }

  renderMomentSuggestion (suggestion, { isHighlighted }) {
    let iconClassNames = `suggestion__icon suggestion__icon--${suggestion.type}`
    if (isHighlighted) iconClassNames = `${iconClassNames} client-theme--color`
    return (
      <div className='suggestion'>
        <div className={iconClassNames} />
        <div className='suggestion__label suggestion-label'>
          <div className='suggestion-label__title'>{`${suggestion.name}`}</div>
          <div className='suggestion-label__subtitle'>{suggestion.count ? `${suggestion.count} ` : ''}{suggestion.subtitle}</div>
        </div>
      </div>
    )
  }

  getSuggestionValue (suggestion) {
    return suggestion.name
  }

  buildSection (title, entries) {
    return { title, entries }
  }

  loadSuggestions (value) {
    const { t } = this.props
    this.setState({ isLoading: true })

    value = value.trim().toLowerCase()
    const startedAt = window.performance.now()

    Promise.all([
      this.loadPlaceSuggestions(value).then((suggestions) => {
        this.setStateIfLatestSuggestions('placeSuggestions', startedAt, {
          placeSuggestions: this.buildSection(t('results_around_location'), suggestions)
        })
      })
    ]).finally(() => {
      this.setState({ isLoading: false })
    })
  }

  loadSupplierSuggestions (value) {
    const { t } = this.props
    this.setState({ isLoading: true })

    value = value.trim().toLowerCase()
    const startedAt = window.performance.now()

    Promise.all([
      this.loadFacetSuggestions(value).then((suggestions) => {
        this.setStateIfLatestSuggestions('facetSuggestions', startedAt, {
          facetSuggestions: this.buildSection(t('all_results_for'), suggestions),
          highlightFirstSuggestion: suggestions.length > 0
        })
      })
    ]).finally(() => {
      this.setState({ isLoading: false })
    })
  }

  setStateIfLatestSuggestions (index, startedAt, newState) {
    if (this.latestSuggestionsAt[index] > startedAt) {
      return // Abort; we already have results for a more recent query.
    }

    this.latestSuggestionsAt[index] = startedAt
    this.setState(newState)
  }

  loadPlaceSuggestions (query) {
    const { t } = this.props
    const limit = 6
    const searchArgs = {
      input: query,
      types: ['geocode', 'establishment']
    }

    function suggestionFromResult (result) {
      return { type: 'place', name: result.description, subtitle: t('autosuggested_place'), place_id: result.place_id }
    }

    return new Promise((resolve, reject) => {
      this.autocompleteService.getPlacePredictions(searchArgs, (results, status) => {
        if (status === this.googlePlaces.PlacesServiceStatus.OK) {
          const locationSuggestions = results.slice(0, limit).map(suggestionFromResult)
          resolve(locationSuggestions)
        } else if (status === this.googlePlaces.PlacesServiceStatus.ZERO_RESULTS) {
          resolve([])
        } else {
          reject(new Error(`Error ${status}: ${results}`))
        }
      })
    })
  }

  loadFacetSuggestions (query) {
    const { t } = this.props
    const limit = 3
    const limitTotalSuggestions = 8
    const facets = this.getSearchableFacets()

    // Algolia does not support searching for multiple facets out of the box, at least not via "searchForFacetValues".
    // We query each facet separately, and then join them afterwards.
    // This means that we also have to reorder results ourselves.

    function suggestionFromFacetHit (hit) {
      const unnestedFacetName = hit.facet.replace(/\..+/, '')
      return {
        type: unnestedFacetName,
        name: hit.value,
        subtitle: t([`autosuggested_facet_${unnestedFacetName}`, 'autosuggested_facet'])
      }
    }

    const loadHitsForSingleFacet = (facet) => {
      const optionalParams = {
        maxFacetHits: limit
      }

      return new Promise((resolve, reject) => {
        this.searchIndex.searchForFacetValues(facet, query, optionalParams).then(({ facetHits }) => {
          resolve(facetHits.map(hit => Object.assign(hit, { facet: facet })))
        }, ({ statusCode, message }) => {
          reject(new Error(`Error ${statusCode}: ${message}`))
        })
      })
    }

    return new Promise((resolve, reject) => {
      Promise.all(facets.map(loadHitsForSingleFacet)).then((allHits) => {
        const sortedHits = allHits.flat().sort((one, other) => other.count - one.count)
        resolve(sortedHits.slice(0, limitTotalSuggestions).map(suggestionFromFacetHit))
      }, (error) => {
        reject(error)
      })
    })
  }

  handleSuggestionSelected (event, { suggestion, suggestionValue }) {
    let resultsFrom = 'supplier'

    if (suggestion.resultsFrom === 'mice_moments') {
      resultsFrom = 'mice_moments'
    }

    this.setState({
      value: suggestionValue, suggestion: suggestion, resultsFrom: resultsFrom
    })

    if (suggestion.place_id) {
      this.setState({
        supplierSearchKey: '',
        searchButtonDisabled: false
      })
      if (event.key === 'Enter') {
        this.setState({ isUserChangingLocation: false }, this.props.onSearch(null, suggestion.place_id))
      }
    }
  }

  handleSupplierSuggestionSelected (event, { suggestion, suggestionValue }) {
    if (event.key === 'Enter') {
      this.suggestionSelectedEnter = true
    }
    if (suggestion.type && suggestion.type === 'chain_name') {
      this.setState({
        supplierSearchKey: ''
      })
      this.props.onSearch(undefined, undefined, { chain_name: suggestion.name })
    } else if (suggestion.type && suggestion.type === 'humanized_suitable') {
      this.handleSuitableSelect(suggestionValue)
    } else {
      this.setState({
        supplierSearchKey: ''
      }, this.props.onSearch(suggestionValue, undefined, { chain_name: '' }))
    }
  }

  handleMomentSuggestionSelected (event, { suggestion, suggestionValue }) {
    if (suggestion) {
      if (event.key === 'Enter' || this.props.isInsideHeader) {
        this.suggestionSelectedEnter = true
        this.setState({
          momentSearchKey: '', suggestion: suggestion, resultsFrom: 'mice_moments'
        }, this.props.onSearch(suggestionValue, undefined))
      } else {
        this.setState({
          momentSearchKey: suggestionValue, suggestion: suggestion, resultsFrom: 'mice_moments'
        })
      }
    }
  }

  handleSuitableSelect (suggestionValue) {
    const selectedSuitable = getSuitableNameFromMapping(suggestionValue, this.props.i18n.language)

    if (selectedSuitable) {
      this.setState({
        supplierSearchKey: ''
      })
      this.props.onSearch(undefined, undefined, { suitable: selectedSuitable })
    } else {
      this.setState({
        supplierSearchKey: ''
      }, this.props.onSearch(suggestionValue, undefined, { chain_name: '' }))
    }
  }

  handleSearchClick () {
    const suggestion = this.state.suggestion
    if (suggestion && suggestion.place_id) {
      this.setState({
        value: '',
        isUserChangingLocation: false
      }, this.props.onSearch(null, suggestion.place_id))
    }
    if (suggestion && suggestion.resultsFrom === 'mice_moments') {
      this.props.onSearch(suggestion.name, undefined)
    }
  }

  loadMiceMomentsSuggestions (value) {
    const { t } = this.props
    this.setState({ isLoading: true })

    value = value.trim().toLowerCase()
    const startedAt = window.performance.now()

    Promise.all([
      this.loadMiceMomentsFacetSuggestions(value).then((suggestions) => {
        this.setStateIfLatestSuggestions('miceMomentSuggestions', startedAt, {
          miceMomentSuggestions: this.buildSection(t('mice_moment_suggestions_title'), suggestions),
          highlightFirstSuggestion: suggestions.length > 0
        })
      })
    ]).finally(() => {
      this.setState({ isLoading: false })
    })
  }

  loadMiceMomentsFacetSuggestions (query) {
    const limitPerFacet = 3
    const limitTotalSuggestions = 5
    const facets = this.getMiceMomentsSearchableFacets()

    // Algolia does not support searching for multiple facets out of the box, at least not via "searchForFacetValues".
    // We query each facet separately, and then join them afterwards.
    // This means that we also have to reorder results ourselves.

    function suggestionFromFacetHit (hit) {
      const unnestedFacetName = hit.facet.replace(/\..+/, '')
      return {
        type: unnestedFacetName,
        name: hit.value,
        count: hit.count,
        subtitle: 'Moments',
        resultsFrom: 'mice_moments'
      }
    }

    const loadHitsForSingleFacet = (facet) => {
      const currentTimestamp = dayjs().unix()
      const optionalParams = {
        filters: `active: true AND start_date_timestamp < ${currentTimestamp} AND end_date_timestamp > ${currentTimestamp}`,
        maxFacetHits: limitPerFacet
      }

      return new Promise((resolve, reject) => {
        this.searchIndexMiceMoment.searchForFacetValues(facet, query, optionalParams).then(({ facetHits }) => {
          resolve(facetHits.map(hit => Object.assign(hit, { facet: facet })))
        }, ({ statusCode, message }) => {
          reject(new Error(`Error ${statusCode}: ${message}`))
        })
      })
    }

    return new Promise((resolve, reject) => {
      Promise.all(facets.map(loadHitsForSingleFacet)).then((allHits) => {
        const sortedHits = allHits.flat().sort((one, other) => other.count - one.count)
        resolve(sortedHits.slice(0, limitTotalSuggestions).map(suggestionFromFacetHit))
      }, (error) => {
        reject(error)
      })
    })
  }

  getSuggestionsFromState () {
    return [this.state.placeSuggestions]
  }

  getSupplierSuggestionsFromState () {
    return [this.state.facetSuggestions]
  }

  getMomentSuggestionsFromState () {
    return [this.state.miceMomentSuggestions]
  }

  onClearSearchValue () {
    this.setState({ value: '' })
  }

  handleUserWantsToChangeLocation () {
    if (!this.state.isUserChangingLocation) {
      this.setState({ isUserChangingLocation: true, value: '', searchButtonDisabled: true })
    }
  }

  getResultsCount (countFor) {
    const { t } = this.props
    if (countFor === 'suppliers') {
      return `${this.state.facetSuggestions.entries.length} ${t('num_results')}`
    } else if (countFor === 'mice_moments') {
      return `${this.state.miceMomentSuggestions.entries.length} ${t('num_results')}`
    } else {
      return 'Unknown'
    }
  }

  render () {
    const { t, placeholder } = this.props
    const { value, supplierSearchKey, momentSearchKey } = this.state

    const suggestions = this.getSuggestionsFromState()
    const supplierSuggestions = this.getSupplierSuggestionsFromState()
    const MomentSuggestions = this.getMomentSuggestionsFromState()
    const inputProps = {
      value,
      placeholder: placeholder || t('search_here_location'),
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      autoComplete: 'off',
      autoCorrect: 'off',
      autoCapitalize: 'off',
      spellCheck: 'false',
      required: '',
      maxLength: '512',
      ref: (placeInput) => (this.placeInput = placeInput)
    }
    const supplierSearchInputProps = {
      value: supplierSearchKey,
      placeholder: placeholder || t('search_supplier'),
      onChange: (event, { newValue }) => this.onSearchKeyChange('supplierSearchKey', newValue),
      onKeyDown: this.onSearchKeyDown,
      autoComplete: 'off',
      autoCorrect: 'off',
      autoCapitalize: 'off',
      spellCheck: 'false',
      required: '',
      maxLength: '512',
      ref: (searchInput) => (this.supplierSearchInput = searchInput)
    }
    const momentSearchInputProps = {
      value: momentSearchKey,
      placeholder: t('search_moment') || placeholder,
      onChange: (event, { newValue }) => this.onSearchKeyChange('momentSearchKey', newValue),
      onKeyDown: this.onSearchKeyDown,
      autoComplete: 'off',
      autoCorrect: 'off',
      autoCapitalize: 'off',
      spellCheck: 'false',
      required: '',
      maxLength: '512',
      ref: (searchInput) => (this.momentSearchInput = searchInput)
    }
    return (
      <>
        {this.props.hidePlaceSearch ? null : (
          <>
            {this.props.searchLocation && !this.state.isUserChangingLocation ? (
              <button className='selected-location' onClick={this.handleUserWantsToChangeLocation} aria-label={t('selected_location') + '' + this.props.searchLocation.name}>
                <i className='fal fa-map-marker-alt' /><div className='name'>{this.props.searchLocation.name}</div>
              </button>
            ) : (
              <div className={`autosuggest-search-box-wrapper place-autosuggest ${this.state.isUserChangingLocation ? 'changing-location' : ''} ${this.props.isInsideHeader ? 'inside-header' : ''}`}>
                {this.state.isUserChangingLocation ? null : (
                  <span className='autosuggest-search-box-search-location-label'>
                    {t('search_location')}
                  </span>)}
                {this.googlePlaces
                  ? (
                    <Autosuggest
                      id='place-autosuggest'
                      multiSection
                      highlightFirstSuggestion
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                      onSuggestionSelected={this.handleSuggestionSelected}
                      getSuggestionValue={this.getSuggestionValue}
                      renderSuggestion={this.renderSuggestion}
                      getSectionSuggestions={this.getSectionSuggestions}
                      renderSectionTitle={this.renderSectionTitle}
                      focusInputOnSuggestionClick={false}
                      inputProps={inputProps}
                    />) : null}
                <div className={`autosuggest-search-box-search-button ${this.state.isUserChangingLocation ? 'has-clear-button' : ''}`}>
                  <ButtonWithIcon
                    id='gtm-supplierStartPageSearch'
                    labelColor='#FFFFFF'
                    padding='5px 18px'
                    label={this.state.isUserChangingLocation ? t('search_button') : t('search_button')}
                    disabled={this.state.searchButtonDisabled}
                    className='client-theme--bg text-white'
                    onClick={this.handleSearchClick}
                  />
                </div>
                {this.state.value !== '' ? (
                  <button
                    className='autosuggest-search-box-search-clear-button'
                    onClick={() => { this.placeInput.focus(); this.setState({ value: '', searchButtonDisabled: true }) }}
                  >
                    <i className='fal fa-times' aria-label={t('clear_search')} />
                  </button>) : null}
                {this.state.isUserChangingLocation ? (
                  <button
                    className='autosuggest-search-box-search-close-button'
                    onClick={() => this.setState({ isUserChangingLocation: false })}
                  >
                    <i className='fal fa-times' aria-label={t('clear_search')} />
                  </button>) : null}
              </div>)}
          </>)}
        {!this.props.hideSupplierSearchBar ? (
          <div className='autosuggest-search-box-wrapper supplier-search'>
            <span className='autosuggest-search-box-search-icon'>
              <i className='fal fa-search' aria-hidden='true' />
            </span>
            <Autosuggest
              id='supplier-autosuggest'
              multiSection
              suggestions={supplierSuggestions}
              onSuggestionsFetchRequested={this.handleSupplierSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
              onSuggestionSelected={this.handleSupplierSuggestionSelected}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              getSectionSuggestions={this.getSectionSuggestions}
              renderSectionTitle={this.renderSectionTitle}
              focusInputOnSuggestionClick={false}
              inputProps={supplierSearchInputProps}
            />
            <div id='supplierResultsCount' className='d-none' aria-live='polite'>{this.getResultsCount('suppliers')}</div>
            {this.state.supplierSearchKey !== '' ? (
              <button
                className='autosuggest-search-box-search-clear-button'
                onClick={() => { this.supplierSearchInput.focus(); this.setState({ supplierSearchKey: '' }) }}
              >
                <i className='fal fa-times' aria-label={t('clear_search')} />
              </button>) : null}
          </div>) : null}
        {!this.props.hideMiceMomentSearchBar ? (
          <div className={`autosuggest-search-box-wrapper mice-moment-search ${this.props.isInsideHeader ? 'inside-header' : 'startpage-search'}`}>
            {this.props.isInsideHeader ? (
              <span className='autosuggest-search-box-search-icon'>
                <i className='fal fa-search' aria-hidden='true' />
              </span>
            ) : null}
            {this.props.resultsFrom && this.props.resultsFrom === 'moments' && !this.props.isInsideHeader ? (
              <span className='autosuggest-search-box-search-location-label'>
                {t('search_mice_moments')}
              </span>) : null}
            <Autosuggest
              id='miceMoment-autosuggest'
              multiSection
              suggestions={MomentSuggestions}
              onSuggestionsFetchRequested={this.handleMomentSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
              onSuggestionSelected={this.handleMomentSuggestionSelected}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderMomentSuggestion}
              getSectionSuggestions={this.getSectionSuggestions}
              renderSectionTitle={this.renderSectionTitle}
              focusInputOnSuggestionClick={false}
              inputProps={momentSearchInputProps}
            />
            <div id='miceMomentResultsCount' className='d-none' aria-live='polite'>{this.getResultsCount('mice_moments')}</div>
            {this.props.isInsideHeader ? null : (
              <div className='autosuggest-search-box-search-button'>
                <ButtonWithIcon
                  id='gtm-miceMomentStartPageSearch'
                  labelColor='#FFFFFF'
                  padding='5px 18px'
                  label={t('search_button')}
                  disabled={this.state.searchButtonDisabled}
                  className='client-theme--bg text-white'
                  onClick={this.handleSearchClick}
                />
              </div>
            )}
            {this.state.momentSearchKey !== '' ? (
              <button
                className='autosuggest-search-box-search-clear-button'
                onClick={() => { this.momentSearchInput.focus(); this.setState({ momentSearchKey: '' }) }}
              >
                <i className='fal fa-times' />
              </button>) : null}
          </div>) : null}
      </>
    )
  }
}

AutosuggestSearchBox.defaultProps = {
  hidePlaceSearch: false,
  hideMiceMomentSearchBar: true
}

const mapStateToProps = state => {
  return {
    searchLocation: state.app.searchLocation,
    searchBoundingBox: state.app.searchBoundingBox
  }
}
export default connect(mapStateToProps)(withTranslation()(connectGeoSearch(AutosuggestSearchBox)))

export const UnconnectedAutosuggestSearchBox = withTranslation()(AutosuggestSearchBox)
