import React, { Component } from 'react'
import './SideBar.scss'
import defaultLogo from '../../assets/images/logo.png'
import { withTranslation } from 'react-i18next'

const Logo = window.CLIENT_INSTANCE_LOGO || defaultLogo

class SideBar extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  handleSignIn () {
    console.log('sign in clicked')
  }

  closeSidebar () {
    const sidebar = document.getElementById('sidebar')
    sidebar.classList.remove('sidebar-open')
  }

  render () {
    const { t } = this.props
    return (
      <>
        <nav
          id='sidebar'
          style={{ display: this.props.isLoading ? 'none' : 'block' }}
          className='c-layout-quick-sidebar sidebar-close'
        >
          <div className='c-header'>
            <button onClick={() => this.closeSidebar()} type='button' className='c-link c-close'>
              <i className='icon-login' style={{ fontSize: '24px' }} />
            </button>
          </div>
          <div className='c-content'>
            <div className='c-section'>
              <img src={Logo} alt={t('mice_portal')} className='c-desktop-logo' />
              <h3>{t('mice_portal_menu')}</h3>
            </div>
            <div>
              <ul className='c-content-recent-posts-1'>
                <li>
                  <div className='c-image'>
                    <center>
                      <i
                        className='ti-agenda'
                        style={{
                          fontSize: '36px',
                          color: '#a3c95e'
                        }}
                      />
                    </center>
                  </div>
                  <div className='c-post'>
                    <a id='my-projects' href='#' className='c-title'>
                      {t('my_projects')}
                    </a>
                  </div>
                </li>
                <li>
                  <div className='c-image'>
                    <center>
                      <i
                        className='ti-wallet'
                        style={{
                          fontSize: '36px',
                          color: '#a3c95e'
                        }}
                      />
                    </center>
                  </div>
                  <div className='c-post'>
                    <a id='my-projects' href='#' className='c-title' onClick={this.handleSignIn}>
                      {t('my_requests')}
                    </a>
                  </div>
                </li>
                <li>
                  <div className='c-image'>
                    <center>
                      <i
                        className='ti-comments'
                        style={{
                          fontSize: '36px',
                          color: '#a3c95e'
                        }}
                      />
                    </center>
                  </div>
                  <div className='c-post'>
                    <a id='my-projects' href='#' className='c-title' onClick={this.handleSignIn}>
                      {t('my_messages')}
                    </a>
                  </div>
                </li>
                <li>
                  <div className='c-image'>
                    <center>
                      <i
                        className='ti-archive'
                        style={{
                          fontSize: '36px',
                          color: '#a3c95e'
                        }}
                      />
                    </center>
                  </div>
                  <div className='c-post'>
                    <a id='my-projects' href='#' className='c-title' onClick={this.handleSignIn}>
                      {t('my_booking_history')}
                    </a>
                  </div>
                </li>
                <li>
                  <div className='c-image'>
                    <center>
                      <i
                        className='ti-bar-chart'
                        style={{
                          fontSize: '36px',
                          color: '#a3c95e'
                        }}
                      />
                    </center>
                  </div>
                  <div className='c-post'>
                    <a id='my-projects' href='#' className='c-title' onClick={this.handleSignIn}>
                      {t('my_reports')}
                    </a>
                  </div>
                </li>
                <li>
                  <div className='c-image'>
                    <center>
                      <i
                        className='ti-settings'
                        style={{
                          fontSize: '36px',
                          color: '#a3c95e'
                        }}
                      />
                    </center>
                  </div>
                  <div className='c-post'>
                    <a id='my-projects' href='#' className='c-title' onClick={this.handleSignIn}>
                      {t('my_settings')}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
    )
  }
}

export default withTranslation()(SideBar)
