import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import ButtonWithIcon from '../../../../ButtonWithIcon/ButtonWithIcon'
import ContactForm from './ContactForm'
import axios from 'axios'
import Select from 'react-select'
import { toast, ToastContainer } from 'react-toastify'

class ContactsManager extends Component {
  constructor (props) {
    super(props)

    this.componentRef = React.createRef()

    const { t } = props

    this.state = {
      contactToAdd: null,
      contactRoles: [],
      contacts: [],
      showAddUserSelect: false,
      unsavedChanges: false,
      saveButtonDisabled: true,
      resetButtonIcon: 'fa fa-refresh',
      resetButtonLabel: t('supplier.edit.button.reset.label')
    }

    this.generateContactRoleObject = this.generateContactRoleObject.bind(this)
    this.availableRoles = this.availableRoles.bind(this)
    this.handleAddContact = this.handleAddContact.bind(this)
    this.handleContactTypeSelected = this.handleContactTypeSelected.bind(this)
    this.handleDeletion = this.handleDeletion.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.handleUnsavedChanges = this.handleUnsavedChanges.bind(this)
    this.initializeStateWithExistingFormData = this.initializeStateWithExistingFormData.bind(this)
  }

  componentDidMount () {
    this.initializeStateWithExistingFormData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.state.unsavedChanges && this.state.contactToAdd && JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.handleUnsavedChanges(true)
    }
  }

  handleUnsavedChanges (unsavedChanges) {
    this.setState({ unsavedChanges: unsavedChanges, saveButtonDisabled: !unsavedChanges })
    if (unsavedChanges) {
      this.props.updateHandler(null, 'SettingsPage')
    } else {
      this.props.updateHandler(null, null)
    }
  }

  initializeStateWithExistingFormData () {
    axios
      .get(`/suppliers/${this.props.supplierId}/contacts.json?locale=${this.props.i18n.language}`)
      .then(response => {
        const contactRoles = Object.keys(response.data)

        const supplierContacts = []
        contactRoles.forEach((contactRole) => {
          const supplierContact = response.data[contactRole]
          if (supplierContact) {
            supplierContacts.push(supplierContact)
          }
        })

        this.setState(() => ({
          contacts: supplierContacts,
          contactToAdd: null,
          contactRoles
        }))
      })
      .catch(error => {
        console.log('Error while fetching supplier contacts data', error)
        if (error.response.status === 403) {
          window.location.href = '/pages/access_denied'
        }
      })
  }

  handleChange ({ target }) {
    const value = target.value
    const name = target.name

    this.setState({
      showSuccessMessage: false,
      supplier: {
        ...this.state.supplier,
        [name]: value
      }
    })
  }

  availableRoles (currentRole) {
    const contactRoles = this.state.contactRoles.map((contactRole) => {
      return this.generateContactRoleObject(contactRole)
    })

    const usedContactRoles = this.state.contacts.map((contact) => contact.role)

    if (currentRole && usedContactRoles.includes(currentRole)) {
      usedContactRoles.splice(usedContactRoles.indexOf(currentRole), 1)
    }

    return contactRoles.filter((contactRole) => usedContactRoles.indexOf(contactRole.value) < 0)
  }

  handleContactTypeSelected ({ value }) {
    this.setState({ contactToAdd: { role: value } })
  }

  handleAddContact () {
    const contactToAdd = this.state.contactToAdd
    if (contactToAdd && contactToAdd.role) {
      this.setState({
        contacts: [...this.state.contacts, contactToAdd],
        contactToAdd: null
      })
    }
  }

  handleDeletion (role) {
    toast(this.props.t('contact_deleted'))

    const contacts = this.state.contacts.filter((contact) => contact.role !== role)
    this.setState({ contacts })
  }

  generateContactRoleObject (role) {
    const { t } = this.props
    return { label: t(`contacts_roles.${role}`), value: role }
  }

  handleReset () {
    const { t } = this.props

    this.setState({
      errors: {},
      saveButtonDisabled: true,
      resetButtonIcon: 'fa fa-spinner fa-spin',
      resetButtonLabel: t('supplier.edit.button.reset.reseting_label')
    })

    this.props.resetHandler()
    this.initializeStateWithExistingFormData()
    this.props.updateHandler(null, null)

    setTimeout(() => this.setState({
      resetButtonIcon: 'fa fa-refresh',
      resetButtonLabel: t('supplier.edit.button.reset.label'),
      unsavedChanges: false
    }), 2000)
  }

  render () {
    const { t } = this.props
    const availableRolesToSelect = this.availableRoles()
    return (
      <>
        {this.props.enableUnsavedChangesTracker && this.state.unsavedChanges ? (
          <Col className='p-0 d-flex justify-content-center align-items-center'>
            <ButtonWithIcon
              labelColor='#FFFFFF'
              iconColor='#FFFFFF'
              padding='12px'
              icon={this.state.resetButtonIcon}
              label={this.state.resetButtonLabel}
              className='btn-danger c-btn-square c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold'
              onClick={this.handleReset}
              disabled={this.state.saveButtonDisabled}
            />
          </Col>
        ) : null}

        {this.state.contacts.map((contact) => (
          <div key={contact.role}>
            <ContactForm
              contact={contact}
              supplierId={this.props.supplierId}
              onDelete={() => this.handleDeletion(contact.role)}
              onCreate={() => this.initializeStateWithExistingFormData()}
              updateHandler={this.props.updateHandler}
              availableRoles={this.availableRoles}
              generateContactRoleObject={this.generateContactRoleObject}
              onUnsavedChanges={() => this.handleUnsavedChanges(true)}
              onSavedChanges={() => this.handleUnsavedChanges(false)}
              showSuccessMessage={!this.unsavedChanges}
            />
          </div>
        ))}

        {availableRolesToSelect.length > 0 ? (
          <Row className='m-0 mt-5 mb-2'>
            {this.state.contactToAdd ? (
              <>
                <Col className='pl-0' xl={6} md={6} sm={8} xs={12}>
                  <Select
                    name='add_user_select'
                    className='defaultSelectDropdown mt-0'
                    classNamePrefix='defaultSelectDropdown'
                    options={availableRolesToSelect}
                    placeholder={t('select_user_type')}
                    isSearchable
                    isMulti={false}
                    onChange={this.handleContactTypeSelected}
                    style={{ margin: '0px 0px 6px 0px' }}
                  />
                </Col>
                <Col className='pl-0' xl={4} md={4} sm={8} xs={12}>
                  <ButtonWithIcon
                    padding='8px'
                    icon='fa fa-plus'
                    label={t('add_user')}
                    className='client-theme--border-color'
                    onClick={this.handleAddContact}
                  />
                </Col>
              </>
            ) : (
              <Col className='pl-0' xl={4} md={4} sm={8} xs={12}>
                <ButtonWithIcon
                  padding='8px'
                  icon='fa fa-plus'
                  label={t('add_another_user')}
                  className='client-theme--border-color'
                  onClick={() => { this.setState({ contactToAdd: {} }) }}
                />
              </Col>
            )}
          </Row>
        ) : null}
        <ToastContainer position='bottom-left' progressClassName='defaultToastProgressBar' toastClassName='defaultToast' />
      </>
    )
  }
}

ContactsManager.propTypes = {
  supplierId: PropTypes.number.isRequired
}

export default withTranslation()(ContactsManager)
