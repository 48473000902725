import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import defaultThumbnail from '../../assets/images/blank-hotel.jpg'
import { cloudinaryImageUrl } from '../../shared/cloudinaryUtils'
import TextPreview from '../TextPreview/TextPreview'
import './MiceMomentCardLite.scss'
import { Col, Row } from 'reactstrap'
import MiceMomentButton from './MiceMomentButton'
import { connectInsightedHit } from '../../services/algolia'
import BannerAdDisplay from '../BannerAd/BannerAdDisplay'

var dayjs = require('dayjs')

const imgSetDefault = (e) => {
  /*
   * This is in MiceMomentCard:
        e.target.onerror = null
        e.target.src = defaultThumbnail
   *
   * ..but won't this work better?:
  */
  if (e.target.src !== defaultThumbnail) {
    e.target.src = defaultThumbnail
  }
}

function formatCreationInfo (t, hit) {
  if (!hit.details) { return null }
  const date = hit.details.created_at ? dayjs(hit.details.created_at).toDate() : null
  const author = hit.details.supplier_name

  if (author && date) {
    return t('by_author_on_date', { author: author, date: date })
  } else if (author) {
    return t('by_author', { author: author })
  }
  return null
}

/* This component uses only the bare minimum of the properties -
 * only the ones available in Algolia index.
 *
 * The format of the indexed data is defined by the MiceMoment model,
 * in app/models/mice_moment.rb (look for a block of code with "algoliasearch" word)
 *
 * See the MiceMomentCard (without ..Lite) component for the full display, backed
 * by our backend, and with edit capabilities.
 */
export const MiceMomentCardLite = withTranslation()(({
  hit,
  t,
  onMouseEnter,
  onMouseLeave,
  hashtagRenderer,
  history,
  trendlineMode,
  insights
}) => {
  const momentPath = hit.details && hit.details.canonical_path ? `/moments/${hit.details.canonical_path}` : null
  const momentImage = (hit.details && cloudinaryImageUrl(hit.details.cloudinary_id)) || defaultThumbnail
  const description = hit.details && !trendlineMode ? hit.details.description : null

  function hashTags () {
    if (!hit.hashtags) { return null }
    const tags = hit.hashtags.de || hit.hashtags.en /* We're actually using only "de". The i18n of tags needs more love. */
    return (tags ? (
      <ul className='hashtags-moments'>
        {tags.map(tag => (
          <li key={tag}>
            {hashtagRenderer ? hashtagRenderer(tag) : tag}
          </li>
        ))}
      </ul>
    ) : null)
  }

  function onClick (e) {
    e.preventDefault()
    insights && insights('clickedObjectIDsAfterSearch', { eventName: 'Moment Clicked' })
    history.push({ pathname: momentPath })
  }

  function getAddress () {
    if (hit && hit.details && hit.details.post_code && hit.details.city) {
      return `${hit.details.post_code} ${hit.details.city}`
    }
    if (hit && hit.details && hit.details.street) {
      return `${hit.details.street}`
    }
    return null
  }

  const address = getAddress()

  return (
    <article
      className='MiceMomentCardLite'
      onMouseEnter={onMouseEnter && (() => onMouseEnter(hit))}
      onMouseLeave={onMouseLeave && (() => onMouseLeave(hit))}
    >
      <a onClick={onClick} href=''>
        <img src={momentImage} onError={imgSetDefault} alt='' />
        <h1>{hit.title}</h1>
      </a>
      <div className='CardContentWrapper'>
        {address && (
          <div className='address-street'>
            <h5 className='heading' style={{}}>
              <span className='marker' style={{}}>
                <i className='far fa-map-marker-alt' />
              </span>
              {address}
            </h5>
          </div>)}
        <div className='creationInfo'>
          <Link to={`/suppliers/${hit && hit.details && hit.details.supplier_id}`}>{formatCreationInfo(t, hit)}</Link>
        </div>
        {hashTags()}
        <div className='description'>
          <TextPreview
            href={momentPath}
            text={description}
            previewLength={30}
          />
        </div>
      </div>
      <Row className='ml-0 mr-0'>
        <Col xs={{ size: 12, offset: 0 }} lg={{ size: 12, offset: 0 }}>
          <MiceMomentButton miceMoment={{ id: hit.objectID }} />
        </Col>
      </Row>
    </article>
  )
})

MiceMomentCardLite.propTypes = {
  hit: PropTypes.object.isRequired,
  hashtagRenderer: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
}

const InsightedMiceMomentCardLite = connectInsightedHit(withRouter(MiceMomentCardLite))

/* This is just a wrapper over the whole set of results
 * as received from, for example, Algolia.
 * To use it instead of the Algolia's built-in Hits component,
 * declare this: `const MomentHits = connectHits(MiceMomentCardLite.Wrapper)`
 * */
MiceMomentCardLite.Wrapper = ({ hits, ...rest }) => {
  return (
    <div className='MiceMomentCardLite-wrapper'>
      {hits ? hits.map((hit, idx) => (
        <Fragment key={idx}>
          <InsightedMiceMomentCardLite theId={idx} key={hit.objectID} hit={hit} {...rest} />
          {idx === (hits.length - 1) && <BannerAdDisplay key='bannerad' variant='moments-advt' />}
        </Fragment>
      )) : null}
    </div>
  )
}

MiceMomentCardLite.Wrapper.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.object)
}

export default withRouter(MiceMomentCardLite)
