import React from 'react'
import './FormStatus.scss'
import { toast, ToastContainer } from 'react-toastify'
import PropTypes from 'prop-types'

class FormStatus extends React.Component {
  componentDidUpdate (prevProps) {
    if (prevProps.submitStatus !== 'save-successful') {
      if (this.props.submitStatus === 'save-successful') {
        toast.success('Saved successfully!')
      }
    }
    if (prevProps.submitStatus !== 'submit-error') {
      if (this.props.submitStatus === 'submit-error') {
        toast.error('Saving failed! Please check the errors and try again')
      }
    }
  }

  render () {
    return (
      <>
        <i
          id={this.props.id}
          style={{ color: 'transparent' }}
          className='fa fa-circle'
          data-submit-state={this.props.submitStatus}
        />
        <ToastContainer autoClose={10000} position='bottom-right' />
      </>
    )
  }
}

FormStatus.propTypes = {
  id: PropTypes.string.isRequired
}

export default FormStatus
