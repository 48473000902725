import React from 'react'
import { QueryRuleCustomData, Configure } from 'react-instantsearch-dom'
import BannerAdContext from './BannerAdContext'

const extract = (items) => {
  if (items && items.length) {
    const res = items.sort((a, b) => (b.priority || 0) - (a.priority || 0))
    return res[0]
  } else {
    return null
  }
}

export default function ({ provider, configurator, position, children }) {
  const Provider = provider || QueryRuleCustomData
  const Configurator = configurator || Configure
  return (
    <>
      <Configurator ruleContexts={[`banner_${position}`, `${window.CURRENT_SUBDOMAIN}_banner_${position}`]} />
      <Provider>
        {({ items }) => <BannerAdContext.Provider value={extract(items)}>{children}</BannerAdContext.Provider>}
      </Provider>
    </>
  )
}
