import React from 'react'
import { Col, Row } from 'reactstrap'
import './SupplierContactBox.scss'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

class SupplierContactBox extends React.Component {
  prepareUrl (url) {
    if (/^\s*https?:\/\//i.test(url)) { // This regex for searching for http or https
      return url
    } else {
      return `http://${url.replace(/^\s+|\s+$/g, '')}` // This regex for removing leading and trailing whitespaces
    }
  }

  urlClean (url) {
    return url.replace(/^\s*https?:\/\//i, '') // This regex for removing https or http
  }

  render () {
    const { t } = this.props
    return (
      <div className='containerFluid c-bg-dark SupplierContactBox'>
        <Row>
          <Col className='pl-0 pr-0' md={12} style={{ textAlign: 'center' }}>
            <div>
              <p className='c-font-grey'>{t('footer_custom_headline')}</p>
              <h4 className='client-theme--color c-font-uppercase'>
                {this.props.supplier.name}
              </h4>
            </div>
            <div>
              <p className='c-font-white'>
                {this.props.supplier.address.street}, {this.props.supplier.address.post_code} {this.props.supplier.address.city}
              </p>
              {this.props.supplier.isProfessional ? (
                <p style={{ paddingTop: '15px' }}>
                  {this.props.supplier.url ? (
                    <a href={this.prepareUrl(this.props.supplier.url)} target='_blank' rel='noopener noreferrer'>
                      <i className='ti-world client-theme--color' /> {t('footer_custom_link_to_website')}
                    </a>
                  ) : null}
                  <a href={`tel:${this.props.supplier.phone_number}`}>
                    <i className='fa fa-phone client-theme--color' /> {this.props.supplier.phone_number}
                  </a>
                  <a href={`mailto:${this.props.supplier.email}`}>
                    <i className='ti-email client-theme--color' /> {this.props.supplier.email}
                  </a>
                </p>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

SupplierContactBox.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  supplier: PropTypes.object
}

export default withTranslation()(SupplierContactBox)
