import React from 'react'
import './SupplierImageGridGallery.scss'
import Gallery from 'react-photo-gallery'
import Lightbox from 'react-image-lightbox'

import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

class SupplierImageGridGallery extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      photoIndex: 0,
      isOpen: false,
      images: []
    }
    this.getImages = this.getImages.bind(this)
    this.handleOpenLightbox = this.handleOpenLightbox.bind(this)
    this.closeLightbox = this.closeLightbox.bind(this)
  }

  handleOpenLightbox (event, { index }) {
    this.setState({ photoIndex: index, isOpen: true })
  }

  closeLightbox () {
    this.setState({ photoIndex: 0, isOpen: false })
  }

  getImages (images) {
    if (images && images.length > 0) {
      images.map((image) => {
        image.src = `${image.url || `https://res-1.cloudinary.com/mice-portal/${image.cloudinary_public_id}`}`
        image.width = 3
        image.height = 2
      })
      return images
    } else {
      return []
    }
  }

  componentDidMount () {
    const images = this.getImages(this.props.images)
    if (images) {
      this.setState({ images: images })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.images !== this.props.images) {
      this.setState({ images: this.getImages(this.props.images) })
    }
  }

  render () {
    const { photoIndex, isOpen, images } = this.state
    return (
      <div className='ImageWrapper ImageGridOverlay'>
        {images && images.length > 0 ? (
          <>
            <Gallery onClick={this.handleOpenLightbox} photos={images} direction={this.props.direction || 'row'} />
            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex].src}
                nextSrc={images[(photoIndex + 1) % images.length].src}
                prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + images.length - 1) % images.length
                  })}
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % images.length
                  })}
              />
            )}
          </>
        ) : null}
      </div>

    )
  }
}
export default SupplierImageGridGallery
