import React from 'react'
import { CustomMarker } from 'react-instantsearch-dom-maps'
export default function SelectedLocationPinMarker ({ searchLocation }) {
  const locationHit = {
    _geoloc: {
      lat: searchLocation.lat, lng: searchLocation.lng
    }
  }
  return (
    <CustomMarker key={searchLocation.placeId} hit={locationHit} className='map-pin'>
      <div className='map-pin-icon'>
        <i className='fa fa-map-marker' />
      </div>
    </CustomMarker>)
}
