import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import SectionItem from '../SectionItem/SectionItem'
import * as queryString from 'qs'
import { extractQueryParams } from '../../services/urlStateParser'

class CitySection extends Component {
  constructor (props) {
    super(props)
    this.state = {
      geoLocations: [],
      geoCities: []
    }

    this.getLatLng = this.getLatLng.bind(this)
    this.goTo = this.goTo.bind(this)
  }

  getLatLng (city) {
    return `${city.lat}, ${city.lng}`
  }

  goTo (city) {
    return () => {
      const queryParams = extractQueryParams(this.props)

      const newSearch = {
        pathname: '/results',
        search: queryString.stringify({ ...queryParams, location: city.google_place_id })
      }

      this.props.history.push(newSearch)
    }
  }

  render () {
    const { sectionTitle, sectionDescription, cities } = this.props

    return (
      <div className='c-content-box c-size-md c-bg-white'>
        <div className='containerFluid'>
          <Row>
            <Col md={12}>
              <div className='c-content-title-1'>
                <h2>
                  {sectionTitle}
                </h2>
                <p className='m-0 p-0'>{sectionDescription}</p>
                <div className='c-line-left c-theme-bg client-theme--bg' />
              </div>
            </Col>
            {cities && cities.map((city, idx) => (
              <Col md={4} lg={2} xs={6} className='mb-3' key={`city_${idx}`}>
                <SectionItem
                  title={city.name}
                  aroundLatLng={this.getLatLng(city)}
                  image={city.index_image}
                  imageWebp={city.index_image_webp}
                  onClick={this.goTo(city)}
                />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    )
  }
}

CitySection.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  sectionDescription: PropTypes.string,
  cities: PropTypes.array
}

export default withRouter(CitySection)
