import React, { Component } from 'react'
import { Col, Card, CardBody, Row } from 'reactstrap'
import { AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'
import ButtonWithIcon from '../../../ButtonWithIcon/ButtonWithIcon'
import { withTranslation } from 'react-i18next'
import { UUID } from '../../../../shared/utils'
import PropTypes from 'prop-types'
import './Rooms.scss'
import RoomGroup from './RoomGroup'

class RoomGroups extends Component {
  constructor (props) {
    super(props)

    this.state = {
      roomGroups: []
    }

    this.handleAddRoomGroup = this.handleAddRoomGroup.bind(this)
    this.handleRoomGroupChange = this.handleRoomGroupChange.bind(this)
    this.handleRemoveRoomGroup = this.handleRemoveRoomGroup.bind(this)
    this.notifyChangeListener = this.notifyChangeListener.bind(this)
  }

  componentDidMount () {
    this.setState({
      roomGroups: [...this.props.roomGroups]
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.roomGroups !== this.props.roomGroups) {
      this.setState({
        roomGroups: [...this.props.roomGroups]
      })
    }
  }

  getRoomGroups () {
    return this.state.roomGroups.filter((roomGroup) => { return !roomGroup._destroy })
  }

  handleAddRoomGroup () {
    const roomGroups = [...(this.state.roomGroups || [])]
    roomGroups.push({ images: [], uuid: UUID() })

    this.setState({ roomGroups: roomGroups }, this.notifyChangeListener)
  }

  handleRoomGroupChange (changedRoomGroup, { target }) {
    const roomGroupIndex = this.state.roomGroups.indexOf(changedRoomGroup)
    const roomGroups = [...this.state.roomGroups]

    roomGroups[roomGroupIndex] = target.value
    this.setState({ roomGroups: roomGroups }, this.notifyChangeListener)
  }

  handleRemoveRoomGroup (deletedRoomGroup) {
    const roomGroups = [...this.state.roomGroups]

    const roomGroupToDelete = roomGroups.find((roomGroup) => (roomGroup.uuid && roomGroup.uuid === deletedRoomGroup.uuid) || (roomGroup.id && roomGroup.id === deletedRoomGroup.id))
    roomGroupToDelete._destroy = true

    this.setState({ roomGroups: roomGroups }, this.notifyChangeListener)
  }

  notifyChangeListener () {
    if (this.props.onChange) {
      this.props.onChange({
        target: {
          name: this.props.name,
          value: this.state.roomGroups
        },
        type: 'roomGroups'
      })
    }
  }

  getRoomName (roomTypeId) {
    const { t, i18n } = this.props

    if (roomTypeId) {
      const roomType = this.props.roomTypes.find((roomType) => roomType.id === roomTypeId)

      return roomType[`name_${i18n.language}`]
    }

    return t('edit_room_type')
  }

  render () {
    const { t } = this.props
    const roomGroups = this.getRoomGroups()

    return (
      <>
        {roomGroups.map((roomGroup, index) => (
          <AccordionItem key={(roomGroup.id || roomGroup.uuid)} className='accordion__item mb-4 roomGroup'>
            <Card>
              <CardBody>
                <AccordionItemHeading role='button' className='primaryAccordianTitle'>
                  <AccordionItemButton>
                    <h5>
                      {this.getRoomName(roomGroup.room_type_id)}
                      <div className='accordion__arrow' role='presentation' />
                    </h5>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <RoomGroup
                    roomGroup={roomGroup}
                    errors={this.props.errors && this.props.errors[index]}
                    roomTypes={this.props.roomTypes}
                    roomFacilities={this.props.roomFacilities}
                    onChange={(event) => this.handleRoomGroupChange(roomGroup, event)}
                  />
                  <Row>
                    <Col md={3}>
                      <ButtonWithIcon
                        labelColor='#FFFFFF'
                        iconColor='#FFFFFF'
                        padding='12px'
                        icon='fa fa-close'
                        label={t('common.remove')}
                        className='btn btn-danger c-btn-border1-2x c-btn-uppercase c-btn-bold'
                        onClick={() => this.handleRemoveRoomGroup(roomGroup)}
                      />
                    </Col>
                  </Row>
                </AccordionItemPanel>
              </CardBody>
            </Card>
          </AccordionItem>
        ))}

        <Card className='mb-4'>
          <CardBody className='room-groups__add_button'>
            <ButtonWithIcon
              labelColor='#FFFFFF'
              iconColor='#FFFFFF'
              padding='12px'
              icon='fa fa-plus'
              label={t('add_new_room_type')}
              className='btn c-theme-btn c-btn-border1-2x c-btn-uppercase c-btn-bold rounded-pill'
              onClick={this.handleAddRoomGroup}
            />
          </CardBody>
        </Card>
      </>
    )
  }
}

RoomGroups.propTypes = {
  name: PropTypes.string,
  errors: PropTypes.array,
  onChange: PropTypes.func,
  roomFacilities: PropTypes.array,
  roomGroups: PropTypes.array
}

export default withTranslation()(RoomGroups)
