import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import ReactTags from 'react-tag-autocomplete'
import './CitySearch.scss'
import { UUID } from '../../shared/utils'
import { getGoogleMaps } from '../../services/googleMaps'

class CitySearch extends Component {
  constructor (props) {
    super(props)
    this.googlePlaces = getGoogleMaps().places
    this.autocompleteService = new this.googlePlaces.AutocompleteService()

    this.state = {
      cities: [],
      suggestions: []
    }
  }

  componentDidMount () {
    this.setState({ cities: this.props.value })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ cities: [...(this.props.value || [])] })
    }
  }

  tagExist (placeId) {
    return this.state.cities.some(t => t.name === placeId)
  }

  handleDelete (i) {
    const cities = this.state.cities.slice(0)
    cities.splice(i, 1)
    this.save(cities)
  }

  handleAddition (result) {
    const tag = {
      id: result.id,
      name: result.label,
      place_id: result.place_id
    }

    if (!this.tagExist(tag.place_id)) {
      const cities = [...this.state.cities, tag]
      this.save(cities)
    }
  }

  save (cities) {
    this.setState({ cities }, () => this.props.onChange(this.state.cities))
  }

  handleInputChange (query) {
    this.loadSuggestions(query)
  }

  loadPlaceSuggestions (query) {
    const limit = 3
    const searchArgs = {
      input: query,
      types: ['(cities)']
    }

    function suggestionFromResult (result, id) {
      return {
        id: UUID(),
        type: 'place',
        name: result.description,
        label: result.structured_formatting.main_text,
        place_id: result.place_id
      }
    }

    return new Promise((resolve, reject) => {
      this.autocompleteService.getPlacePredictions(searchArgs, (results, status) => {
        if (status === this.googlePlaces.PlacesServiceStatus.OK) {
          const locationSuggestions = results.slice(0, limit).map(suggestionFromResult)
          resolve(locationSuggestions)
        } else if (status === this.googlePlaces.PlacesServiceStatus.ZERO_RESULTS) {
          resolve([])
        } else {
          reject(new Error(`Error ${status}: ${results}`))
        }
      })
    })
  }

  loadSuggestions (value) {
    value = value.trim().toLowerCase()
    this.loadPlaceSuggestions(value).then((suggestions) => {
      this.setState({ suggestions })
    })
  }

  render () {
    return (
      <ReactTags
        tags={this.state.cities}
        suggestions={this.state.suggestions}
        handleDelete={this.handleDelete.bind(this)}
        handleAddition={this.handleAddition.bind(this)}
        handleInputChange={this.handleInputChange.bind(this)}
        placeholder='Search cities'
        inputAttributes={{
          autoComplete: 'off',
          autoCorrect: 'off',
          autoCapitalize: 'off',
          type: 'text',
          spellCheck: false,
          id: 'citiesSearch'
        }}
      />
    )
  }
}

export default withTranslation()(CitySearch)
