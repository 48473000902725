import React from 'react'
import './BannerAd.scss'
import BannerAdContext from './BannerAdContext'

function BannerAdDisplay ({ variant }) {
  const bannerdata = React.useContext(BannerAdContext)
  if (bannerdata) {
    const klass = variant ? `BannerAd BannerAd-${variant}` : 'BannerAd'
    return (
      <div style={{ gridRowStart: bannerdata.row || '2' }} className={klass}>
        <a style={{ display: 'block' }} href={bannerdata.link}>
          {bannerdata.title && <div><p>{bannerdata.title}</p></div>}
          <img src={bannerdata.banner} alt='' />
        </a>
      </div>
    )
  } else {
    return null
  }
}

export default BannerAdDisplay
