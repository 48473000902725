import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { TextInput } from '../../../FormElements/FormElements'
import Select from 'react-select'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

class GeneralRoomsInformationForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      generalRoomsInformation: {}
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleFacilitiesChange = this.handleFacilitiesChange.bind(this)
    this.notifyChangeListener = this.notifyChangeListener.bind(this)
  }

  componentDidMount () {
    this.setState({
      generalRoomsInformation: { ...this.props.generalRoomsInformation }
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.generalRoomsInformation !== this.props.generalRoomsInformation || (this.props.resetState && !prevProps.resetState)) {
      this.setState({ generalRoomsInformation: { ...this.props.generalRoomsInformation } })
    }
  }

  notifyChangeListener () {
    if (this.props.onChange) {
      const event = { target: {}, type: 'generalRoomsInformation' }
      event.target.name = this.props.name
      event.target.value = this.state.generalRoomsInformation
      event.target.value.general_rooms_information_facilities_attributes = this.state.generalRoomsInformation.general_rooms_information_facilities

      this.props.onChange(event)
    }
  }

  handleChange ({ target }) {
    const value = target.value
    const name = target.name

    this.setState({
      generalRoomsInformation: {
        ...this.state.generalRoomsInformation,
        [name]: value
      }
    }, this.notifyChangeListener)
  }

  handleFacilitiesChange (newFacilities, actionType) {
    let generalRoomsInformationFacilities = [...(this.state.generalRoomsInformation.general_rooms_information_facilities || [])]

    switch (actionType.action) {
      case 'select-option':
        generalRoomsInformationFacilities.push({ facility_id: actionType.option.id })
        break
      case 'remove-value':
        generalRoomsInformationFacilities = generalRoomsInformationFacilities.map((generalRoomsInformationFacility) => {
          if (actionType.removedValue.id === generalRoomsInformationFacility.facility_id) {
            generalRoomsInformationFacility._destroy = true
          }

          return generalRoomsInformationFacility
        })
        break
      case 'clear':
        generalRoomsInformationFacilities = generalRoomsInformationFacilities.map((generalRoomsInformationFacility) => {
          generalRoomsInformationFacility._destroy = true

          return generalRoomsInformationFacility
        })
        break
      default:
        console.log('Unexpected event type', actionType)
    }

    this.setState({
      generalRoomsInformation: {
        ...this.state.generalRoomsInformation,
        general_rooms_information_facilities: generalRoomsInformationFacilities
      }
    }, this.notifyChangeListener)
  }

  getSelectedFacilitiesOptions (options) {
    if (this.props.roomFacilities) {
      let generalRoomsInformationFacilities = this.state.generalRoomsInformation.general_rooms_information_facilities || []
      generalRoomsInformationFacilities = generalRoomsInformationFacilities.filter((generalRoomFacility) => !generalRoomFacility._destroy)

      const generalRoomsInformationFacilitiesIds = generalRoomsInformationFacilities.map((generalRoomFacility) => { return generalRoomFacility.facility_id })

      return this.props.roomFacilities.filter((roomFacility) => {
        const facilityIndex = generalRoomsInformationFacilitiesIds.indexOf(roomFacility.id)
        if (facilityIndex > -1) {
          return !generalRoomsInformationFacilities[facilityIndex]._destroy
        }

        return false
      })
    } else {
      return []
    }
  }

  render () {
    const { t, i18n } = this.props

    return (
      <>
        <Row className='mt-3 mb-3'>
          <Col md={6} sm={8} xs={12}>
            <TextInput
              name='number_of_rooms'
              className='borderPrimary'
              label={t('supplier.edit.rooms.number_of_rooms.label')}
              placeholder={t('supplier.edit.rooms.number_of_rooms.placeholder')}
              value={this.state.generalRoomsInformation.number_of_rooms}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row className='mt-3 mb-3'>
          <Col className='mt-2 mb-2' md={12} sm={12} xs={12}>
            <label htmlFor='generalRoomAmenities'>{t('general_room_amenities')}</label>
            <Select
              id='generalRoomAmenities'
              name='general_rooms_information_facilities'
              className='defaultSelectDropdown'
              classNamePrefix='defaultSelectDropdown'
              options={this.props.roomFacilities}
              getOptionValue={option => option.id}
              getOptionLabel={option => option[`name_${i18n.language}`]}
              placeholder={t('select_all_that_apply')}
              value={this.getSelectedFacilitiesOptions()}
              isSearchable
              isClearable
              isMulti
              onChange={this.handleFacilitiesChange}
              style={{ margin: '6px 0' }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

GeneralRoomsInformationForm.propTypes = {
  onChange: PropTypes.func,
  roomFacilities: PropTypes.array,
  generalRoomsInformation: PropTypes.object
}

export default withTranslation()(GeneralRoomsInformationForm)
