import React from 'react'
import PropTypes from 'prop-types'
import { axiosOnce } from '../../shared/axios'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { withTranslation } from 'react-i18next'
import { UUID } from '../../shared/utils'

class HashtagsSelect extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      hashtags: []
    }

    this.handleCreate = this.handleCreate.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getTagsAsync = this.getTagsAsync.bind(this)
    this.getNewOptionData = this.getNewOptionData.bind(this)
  }

  removeWhiteSpaces (input) {
    return input.split(' ').join('')
  }

  componentDidMount () {
    this.setState({
      hashtags: [...(this.props.value || [])]
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ hashtags: [...(this.props.value || [])] })
    }
  }

  handleCreate (inputValue) {
    this.handleChange([...this.state.hashtags, { name: this.removeWhiteSpaces(inputValue) }], { action: 'create' })
  }

  handleChange (value, actionMeta) {
    if (this.props.onChange) {
      let newTags = []
      switch (actionMeta.action) {
        case 'pop-value':
        case 'remove-value':
          newTags = this.state.hashtags.map((tag) => {
            if (tag === actionMeta.removedValue) {
              tag._destroy = true
            }
            return tag
          })
          break
        case 'clear':
          newTags = this.state.hashtags.map((tag) => ({ ...tag, _destroy: true }))
          break
        default:
          newTags = value
      }

      this.props.onChange({
        target: {
          name: this.props.name,
          value: newTags
        },
        type: 'tags-change'
      })
    }
  }

  getTagsAsync (query) {
    const { language } = this.props.i18n

    return axiosOnce(
      {
        method: 'get',
        url: `/hashtags?locale=${language}&name=${query}`
      })
      .then((response) => {
        return response.data
      })
  }

  getNewOptionData (inputValue, formattedLabel) {
    return { id: null, name: formattedLabel }
  }

  getVisibleTags () {
    return this.state.hashtags.filter((hashtag) => !hashtag._destroy)
  }

  render () {
    const { t, name, className, classNamePrefix } = this.props

    const components = {
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
      LoadingMessage: () => (
        <div className='p-2'>
          <i className='fa fa-spinner fa-spin mr-1' /> {t('common.loading')}
        </div>
      )
    }

    return (
      <AsyncCreatableSelect
        id={this.props.id}
        name={name}
        value={this.getVisibleTags()}
        className={className}
        classNamePrefix={classNamePrefix}
        placeholder={t('add_multiple_hashtags_typing_in_the_input')}
        allowCreateWhileLoading={false}
        loadOptions={this.getTagsAsync}
        onCreateOption={this.handleCreate}
        getOptionValue={option => option.id || UUID()}
        getOptionLabel={option => option.name}
        getNewOptionData={this.getNewOptionData}
        onChange={this.handleChange}
        formatCreateLabel={userInput => `${t('create_new_hashtag')}: #${this.removeWhiteSpaces(userInput)}`}
        components={components}
        noOptionsMessage={() => t('add_multiple_hashtags_typing_in_the_input')}
        isClearable
        isMulti
      />
    )
  }
}

HashtagsSelect.propTypes = {
  value: PropTypes.array,
  name: PropTypes.string,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  onChange: PropTypes.func
}

export default withTranslation()(HashtagsSelect)
