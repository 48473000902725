import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import SectionItem from '../SectionItem/SectionItem'
import { withTranslation } from 'react-i18next'
import Slider from 'react-slick'
import * as queryString from 'qs'
import { extractQueryParams } from '../../services/urlStateParser'
import { NextArrow, PrevArrow } from '../Shared/Shared'
class SuitableForSection extends Component {
  constructor (props) {
    super(props)

    this.goToResultPage = this.goToResultPage.bind(this)
  }

  goToResultPage (suitable) {
    return () => {
      const queryParams = extractQueryParams(this.props)

      const newSearch = {
        pathname: '/results',
        search: queryString.stringify({ ...queryParams, suitable })
      }

      this.props.history.push(newSearch)
    }
  }

  render () {
    const { i18n, sectionTitle, sectionDescription, responsive, suitableFors } = this.props

    return (
      <div className='c-content-box c-size-md c-bg-white'>
        <div className='containerFluid'>
          <Row>
            <Col md={12}>
              <div className='c-content-title-1'>
                <h2>
                  {sectionTitle}
                </h2>
                <p className='m-0 p-0'>{sectionDescription}</p>
                <div className='c-line-left c-theme-bg client-theme--bg' />
              </div>
            </Col>
            <div style={{ width: '100%', position: 'relative', padding: '5px' }}>
              {suitableFors && (
                <Slider
                  itemClass='categoryCarouselItem'
                  containerClass='categoryCarouselContainer'
                  infinite={false}
                  responsive={responsive}
                  lazyLoad
                  nextArrow={<NextArrow />}
                  prevArrow={<PrevArrow />}
                >
                  {suitableFors.map((suitable, idx) => (
                    <SectionItem
                      key={`category_${idx}`}
                      title={suitable[`name_${i18n.language}`]}
                      filters={`suitable:${suitable.key}`}
                      image={suitable.image_url}
                      imageWebp={suitable.image_url_webp}
                      onClick={this.goToResultPage(suitable.key)}
                    />
                  ))}
                </Slider>
              )}
            </div>
          </Row>
        </div>
      </div>
    )
  }
}

SuitableForSection.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  sectionDescription: PropTypes.string,
  responsive: PropTypes.array,
  suitableFors: PropTypes.array
}

export default withTranslation()(withRouter(SuitableForSection))
