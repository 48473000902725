import React from 'react'
import './SupplierConferenceRoomBox.scss'
import { Col, Row } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import PlaceholderImage from '../../assets/images/image-placeholder-square.jpg'

import UForm from '../../assets/images/conference_room_forms/u-form.gif'
import Cabinett from '../../assets/images/conference_room_forms/classroom-form.gif'
import BlockForm from '../../assets/images/conference_room_forms/block-form.gif'
import ClusterForm from '../../assets/images/conference_room_forms/banquet-form.gif'
import RowsForm from '../../assets/images/conference_room_forms/rows-form.gif'
import ReceptionForm from '../../assets/images/conference_room_forms/reception-form-new.gif'
var ResponsiveCarousel = require('react-responsive-carousel').Carousel

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2400 },
    items: 1
  },
  largeDesktop: {
    breakpoint: { max: 2400, min: 1600 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 1600, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}

class SupplierConferenceRoomBox extends React.Component {
  render () {
    const { t } = this.props
    const localeKey = 'supplier.home.tabs.conference_spaces.room.'
    const room = this.props.room
    return (
      <Row className='m-0 p-2 contentBox supplier-feature-box-round' style={{ height: '100%' }}>
        <Col className='pt-3' md={12}>
          <ResponsiveCarousel
            infiniteLoop
            showIndicators={false}
            showThumbs={false}
            showArrows
            showStatus={false}
            onChange={() => this.onChange}
            onClickItem={() => this.onClickItem}
            onClickThumb={() => this.onClickThumb}
            swipeable={false}
          >
            {room && room.images && room.images.length > 0 ? (
              room.images.map((image, idx) => (
                <div key={idx} className='conferenceRoomCarouselPhotoContainer round-element-no-shadow'>
                  <img className='conferenceRoomCarouselPhoto round-element-no-shadow' src={`${image.url}`} alt='' />
                </div>
              ))
            ) : (
              <div className='conferenceRoomCarouselPhotoContainer round-element-no-shadow'>
                <img className='conferenceRoomCarouselPhoto round-element-no-shadow' src={PlaceholderImage} alt='' />
              </div>
            )}
          </ResponsiveCarousel>
        </Col>
        <Col className='mt-3' md={12}>
          <h4 className='c-title c-font-uppercase c-font-bold c-font-bold pt-1'>
            {room && room.name_de ? room.name_de : t(`${localeKey}name_placeholder`)}
          </h4>
          <ul className='supplierConferenceRoomInfoList'>
            {room && room.length ? (
              <li className='supplierConferenceRoomInfoListItem'>
                <span className='supplierConferenceRoomInfoListItemLabel'>{t(`${localeKey}length.label`)}</span>
                <span className='supplierConferenceRoomInfoListItemValue'>{`${room.length} m`}</span>
              </li>
            ) : (
              <li className='supplierConferenceRoomInfoListItem'>
                <span className='supplierConferenceRoomInfoListItemLabel'>{t(`${localeKey}length.label`)}</span>
                <span className='supplierConferenceRoomInfoListItemValue'><i>({t('not_specified')})</i></span>
              </li>
            )}
            {room && room.width ? (
              <li className='supplierConferenceRoomInfoListItem'>
                <span className='supplierConferenceRoomInfoListItemLabel'>{t(`${localeKey}width.label`)}</span>
                <span className='supplierConferenceRoomInfoListItemValue'>{`${room.width} m`}</span>
              </li>
            ) : (
              <li className='supplierConferenceRoomInfoListItem'>
                <span className='supplierConferenceRoomInfoListItemLabel'>{t(`${localeKey}width.label`)}</span>
                <span className='supplierConferenceRoomInfoListItemValue'><i>({t('not_specified')})</i></span>
              </li>
            )}
            {room && room.height ? (
              <li className='supplierConferenceRoomInfoListItem'>
                <span className='supplierConferenceRoomInfoListItemLabel'>{t(`${localeKey}height.label`)}</span>
                <span className='supplierConferenceRoomInfoListItemValue'>{`${room.height} m`}</span>
              </li>
            ) : (
              <li className='supplierConferenceRoomInfoListItem'>
                <span className='supplierConferenceRoomInfoListItemLabel'>{t(`${localeKey}height.label`)}</span>
                <span className='supplierConferenceRoomInfoListItemValue'><i>({t('not_specified')})</i></span>
              </li>
            )}
            {room && room.area ? (
              <li className='supplierConferenceRoomInfoListItem'>
                <span className='supplierConferenceRoomInfoListItemLabel'>{t(`${localeKey}area.label`)}</span>
                <span className='supplierConferenceRoomInfoListItemValue'>
                  {`${room.area} m`}
                  <sup>2</sup>
                </span>
              </li>
            ) : (
              <li className='supplierConferenceRoomInfoListItem'>
                <span className='supplierConferenceRoomInfoListItemLabel'>{t(`${localeKey}area.label`)}</span>
                <span className='supplierConferenceRoomInfoListItemValue'>
                  <i>({t('not_specified')})</i>
                </span>
              </li>
            )}
          </ul>
          {room && room.roomplan_file_url ? (
            <div>
              <a href={`${room.roomplan_file_url}`} className='btn btn-xs c-font-uppercase c-btn-square m-0'>
                <i className='fa fa-download' aria-hidden='true' /> {t(`${localeKey}download_floorplan`)}
              </a>
            </div>
          ) : null}
          {room && room.plan_images && room.plan_images.length > 0
            ? room.plan_images.map((image, idx) => (
              <div key={idx}>
                <a href={`${image.url}`} className='btn btn-xs c-font-uppercase c-btn-square m-0'>
                  <i className='fa fa-download' aria-hidden='true' /> {t(`${localeKey}download_floorplan`)}
                </a>
              </div>
            ))
            : null}
        </Col>
        <Col className='mt-5 mb-4' xl={12} lg={12} md={12} sm={12} style={{ height: '100%' }}>
          <Carousel infinite responsive={responsive} showDots={false} arrows containerClass='pl-5 pr-5'>
            {room && room.capacity_u_form ? (
              <Row className='conferenceRoomArrangementCarouselItem m-0'>
                <Col className='conferenceRoomArrangementCarouselItemImage' xl={6} lg={6} md={6} sm={6}>
                  <img style={{ width: '100%' }} src={UForm} alt='' />
                </Col>
                <Col xl={6} lg={6} md={6} sm={6}>
                  <h5 className='arrangementFormTitle'>{t(`${localeKey}arrangement_forms.u_form`)}</h5>
                  <p className='arrangementFormNumber client-theme--color'>{`${room.capacity_u_form} ${t(
                    `${localeKey}capacity_unit`
                  )}`}
                  </p>
                </Col>
              </Row>
            ) : null}
            {room && room.capacity_cabinett ? (
              <Row className='conferenceRoomArrangementCarouselItem m-0'>
                <Col className='conferenceRoomArrangementCarouselItemImage' xl={6} lg={6} md={6} sm={6}>
                  <img style={{ width: '100%' }} src={Cabinett} alt='' />
                </Col>
                <Col xl={6} lg={6} md={6} sm={6}>
                  <h5 className='arrangementFormTitle'>{t(`${localeKey}arrangement_forms.cabinett`)}</h5>
                  <p className='arrangementFormNumber client-theme--color'>{`${room.capacity_cabinett} ${t(
                    `${localeKey}capacity_unit`
                  )}`}
                  </p>
                </Col>
              </Row>
            ) : null}
            {room && room.capacity_block ? (
              <Row className='conferenceRoomArrangementCarouselItem m-0'>
                <Col className='conferenceRoomArrangementCarouselItemImage' xl={6} lg={6} md={6} sm={6}>
                  <img style={{ width: '100%' }} src={BlockForm} alt='' />
                </Col>
                <Col xl={6} lg={6} md={6} sm={6}>
                  <h5 className='arrangementFormTitle'>{t(`${localeKey}arrangement_forms.block`)}</h5>
                  <p className='arrangementFormNumber client-theme--color'>{`${room.capacity_block} ${t(
                    `${localeKey}capacity_unit`
                  )}`}
                  </p>
                </Col>
              </Row>
            ) : null}
            {room && room.capacity_cluster ? (
              <Row className='conferenceRoomArrangementCarouselItem m-0'>
                <Col className='conferenceRoomArrangementCarouselItemImage' xl={6} lg={6} md={6} sm={6}>
                  <img style={{ width: '100%' }} src={ClusterForm} alt='' />
                </Col>
                <Col xl={6} lg={6} md={6} sm={6}>
                  <h5 className='arrangementFormTitle'>{t(`${localeKey}arrangement_forms.cluster`)}</h5>
                  <p className='arrangementFormNumber client-theme--color'>{`${room.capacity_cluster} ${t(
                    `${localeKey}capacity_unit`
                  )}`}
                  </p>
                </Col>
              </Row>
            ) : null}
            {room && room.capacity_rows ? (
              <Row className='conferenceRoomArrangementCarouselItem m-0'>
                <Col className='conferenceRoomArrangementCarouselItemImage' xl={6} lg={6} md={6} sm={6}>
                  <img style={{ width: '100%' }} src={RowsForm} alt='' />
                </Col>
                <Col xl={6} lg={6} md={6} sm={6}>
                  <h5 className='arrangementFormTitle'>{t(`${localeKey}arrangement_forms.rows`)}</h5>
                  <p className='arrangementFormNumber client-theme--color'>{`${room.capacity_rows} ${t(
                    `${localeKey}capacity_unit`
                  )}`}
                  </p>
                </Col>
              </Row>
            ) : null}
            {room && room.capacity_reception ? (
              <Row className='conferenceRoomArrangementCarouselItem m-0'>
                <Col className='conferenceRoomArrangementCarouselItemImage' xl={6} lg={6} md={6} sm={6}>
                  <img style={{ width: '100%' }} src={ReceptionForm} alt='' />
                </Col>
                <Col xl={6} lg={6} md={6} sm={6}>
                  <h5 className='arrangementFormTitle'>{t(`${localeKey}arrangement_forms.reception`)}</h5>
                  <p className='arrangementFormNumber client-theme--color'>{`${room.capacity_reception} ${t(
                    `${localeKey}capacity_unit`
                  )}`}
                  </p>
                </Col>
              </Row>
            ) : null}
          </Carousel>
        </Col>
      </Row>
    )
  }
}
export default withTranslation()(SupplierConferenceRoomBox)
