import React from 'react'
import PropTypes from 'prop-types'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import { Col, Row } from 'reactstrap'
import './PostCodeInputLine.scss'

class PostCodeInputLine extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}

    this.handleChange = this.handleChange.bind(this)
    this.handleRemoveClick = this.handleRemoveClick.bind(this)

    this.initialized = false
  }

  handleChange (event) {
    this.props.onChange(this.props.idx, event.target.value)
  }

  handleRemoveClick () {
    this.props.onRemove(this.props.idx)
  }

  render () {
    return (
      <Row className='post-code'>
        <Col md={4} sm={6} xs={9}>
          <input
            type='number'
            value={this.props.postCode}
            placeholder={this.props.placeholder}
            className='form-control'
            onChange={this.handleChange}
          />
        </Col>
        <Col md={2} sm={2} xs={3}>
          <ButtonWithIcon
            labelColor='#FFFFFF'
            iconColor='#FFFFFF'
            padding='12px'
            icon='fa fa-close'
            label='Remove'
            className='client-theme--bg'
            onClick={this.handleRemoveClick}
          />
        </Col>
      </Row>
    )
  }
}

PostCodeInputLine.propTypes = {
  idx: PropTypes.number,
  postCode: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func
}

export default PostCodeInputLine
