import { getI18n } from 'react-i18next'
import { isEmpty } from 'lodash'

export const paymentMethods = {
  master_card: 'Master Card',
  amex: 'American Express',
  visa: 'Visa',
  diners: 'Diners',
  jcb: 'JCB',
  ec_maestro: 'Maestro',
  airplus: 'AirPlus'
}

export default ({ supplier, feedback }) => {
  const i18n = getI18n()
  const schema = [
    {
      '@context': 'http://schema.org/',
      name: 'MICE Portal',
      url: 'https://www.miceportal.com/',
      description: '',
      image: {
        '@type': 'ImageObject',
        url: 'https://reaktor.miceportal.de/system/client_instances/konvenator_logos/000/000/016/original/mice-portal-logo.png',
        width: '100.16',
        height: '40'
      },
      '@type': 'Organization',
      telephone: '+49 (0) 816899741000',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Am Bachfeld 1',
        addressLocality: 'Attenkirchen',
        addressRegion: 'Bavaria',
        postalCode: '85395',
        addressCountry: 'Germany'
      },
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer support',
        telephone: '[+49 (0) 816899741000]',
        availableLanguage: [
          'English',
          'German'
        ],
        email: 'info@miceportal.com'
      },
      sameAs: [
        'https://de.linkedin.com/company/mice-portal-gmbh',
        'https://www.facebook.com/miceportalgmbh',
        'https://twitter.com/mice_portal',
        'https://www.instagram.com/mice_portal/'
      ]
    }]

  if (isEmpty(supplier)) {
    return schema
  }

  const locale = i18n.language
  const type = supplier.supplier_hotel
  const supplierImage = supplier.supplier_hotel_general_images[0] ? supplier.supplier_hotel_general_images[0].url : ''
  const numOfRooms = supplier.general_rooms_information ? supplier.general_rooms_information.number_of_rooms : 0
  const payments = supplier.payment_info
  const excludedFacilities = ['vat']
  const amenities = (type.amenities || [])
    .concat(type.gastronomy_amenities || [])
    .concat(type.sport_and_wellness_amenities || [])

  const hotelData = {
    '@context': 'http://schema.org',
    '@type': 'Hotel',
    name: supplier.name,
    alternateName: supplier.name,
    parentOrganization: supplier.chain && supplier.chain.name,
    ...(supplier.classification ? {
      starRating: {
        '@type': 'Rating',
        ratingValue: supplier.classification / 10,
        author: 'DEHOGA'
      }
    } : {}),
    ...(!isEmpty(feedback) && feedback.summary && feedback.summary.feedback_amount > 0 ? {
      aggregateRating: {
        '@type': 'AggregateRating',
        reviewCount: feedback && feedback.summary ? feedback.summary.feedback_amount : null,
        ratingValue: feedback && feedback.summary ? feedback.summary.feedback_average : null,
        bestRating: 5
      }
    } : {}),
    url: `https://www.miceportal.com/hotel/${supplier.id}?locale=${locale}`,
    image: supplierImage,
    description: i18n.t('structured_data.main_description'),
    telephone: supplier.phone_number,
    faxNumber: supplier.fax_number,
    email: supplier.email,
    address: {
      '@type': 'PostalAddress',
      streetAddress: supplier.address.street,
      addressLocality: supplier.address.city,
      addressRegion: supplier.address.province,
      postalCode: supplier.address.post_code,
      addressCountry: supplier.address.country[`name_${locale}`]
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: supplier.location.lat,
      longitude: supplier.location.lng
    },
    hasMap: `https://www.google.com/maps/place/${supplier.location.lat},${supplier.location.lng}`,
    checkinTime: type.check_in_time,
    checkoutTime: type.check_out_time,
    numberOfRooms: numOfRooms,
    paymentAccepted: Object.keys(payments).filter(p => payments[p]).map(p => paymentMethods[p]),
    amenityFeature: amenities.map((amenity) => ({
      '@type': 'LocationFeatureSpecification',
      name: amenity[`name_${locale}`]
    }))
  }

  const meetingRooms = (!isEmpty(supplier) ? supplier.conference_rooms : []).map((room) => (
    {
      '@context': 'http://schema.org/',
      '@type': 'MeetingRoom',
      description: buildMeetingRoomDescription(room),
      floorSize: `${room.area} sq m`,
      image: room.images[0] && room.images[0].url,
      name: room[`name_${locale}`],
      url: `https://www.miceportal.com/hotel/${supplier.id}`,
      amenityFeature: room.facilities
        .filter(facility => !excludedFacilities.includes(facility.key))
        .map((facility) => ({
          '@type': 'LocationFeatureSpecification',
          name: facility[`name_${locale}`],
          value: true
        }))
    }
  ))

  const rooms = (!isEmpty(supplier) ? supplier.room_groups : []).map((room) => (
    {
      '@context': 'http://schema.org/',
      '@type': 'HotelRoom',
      name: room.room_type[`name_${locale}`],
      description: room.room_type[`description_${locale}`],
      floorSize: `${room.room_average_size} sq m`,
      image: room.images[0] && room.images[0].url,
      url: `https://www.miceportal.com/hotel/${supplier.id}`,
      numberOfRooms: room.number_of_rooms,
      smokingAllowed: room.facilities.some(facility => facility.key === 'room_amenity_smoking'),
      amenityFeature: room.facilities.map((facility) => ({
        '@type': 'LocationFeatureSpecification',
        name: facility[`name_${locale}`],
        value: true
      }))
    }
  ))

  hotelData.description = buildHotelDescription({
    numOfRooms: supplier.general_rooms_information ? supplier.general_rooms_information.number_of_rooms : 0,
    numOfMeetingRooms: type.capacity_number_of_conference_rooms,
    location: supplier.address
  })

  return [
    hotelData,
    ...schema,
    ...meetingRooms,
    ...rooms
  ]
}

const buildHotelDescription = ({ numOfRooms, numOfMeetingRooms, location }) => {
  const i18n = getI18n()
  const hasRooms = numOfRooms > 0
  const hasMeetingRooms = numOfMeetingRooms > 0

  const and = hasRooms && hasMeetingRooms ? ` ${i18n.t('common.and')} ` : ''
  const roomsDescription = hasRooms ? `${numOfRooms} ${i18n.t('rooms')}` : ''
  const conferenceRoomsDescription = hasMeetingRooms ? `${numOfMeetingRooms} ${i18n.t('structured_data.meeting_rooms')}` : ''
  const locatedAt = location ? `${location.street}, ${location.post_code}, ${location.city}` : location.country

  return i18n.t('structured_data.hotel_description', {
    and,
    amount_rooms: roomsDescription,
    amount_conference_rooms: conferenceRoomsDescription,
    location: locatedAt
  })
}

const buildMeetingRoomDescription = (room) => {
  const attributes = Object.keys(room)
  const capacities = attributes.filter(attr => attr.startsWith('capacity_'))
    .filter(attr => !!room[attr] && Number.isInteger(room[attr]))
    .map(attr => room[attr])
  const i18n = getI18n()

  return i18n.t('structured_data.meeting_room_description', {
    area: room.area,
    name: room.name,
    min_number: Math.min(...capacities),
    max_number: Math.max(...capacities)
  })
}
