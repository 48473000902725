import React from 'react'
import './SupplierSuitableForCarouselBox.scss'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { withTranslation } from 'react-i18next'
import { sortArrayByKey } from '../../shared/utils'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2400 },
    items: 3,
    slidesToSlide: 2
  },
  largeDesktop: {
    breakpoint: { max: 2400, min: 1600 },
    items: 3,
    slidesToSlide: 2
  },
  desktop: {
    breakpoint: { max: 1600, min: 1024 },
    items: 3,
    slidesToSlide: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
}

class SupplierSuitableForCarouselBox extends React.Component {
  render () {
    const displayables = (this.props.items || []).filter(item => item.image_url)

    return (
      <div
        className='c-content-media-1 contentBox pb-2 supplier-feature-box-round'
      >
        <div className='c-content-label c-font-uppercase c-font-bold c-bg-regular'>
          {this.props.title}
        </div>
        <div className='SupplierPageSuitableFor'>
          {displayables.length > 0 ? (
            <Carousel
              infinite
              responsive={responsive}
              autoPlay
              autoPlaySpeed={6000}
              customTransition='all 1s'
              transitionDuration={1000}
              itemClass='carousel-item-padding-10-px'
            >
              {sortArrayByKey(displayables, `name_${this.props.i18n.language}`).map((item, idx) => (
                <div key={idx} className='supplierSuitableForCarouselItemContainer'>
                  <div className='supplierSuitableForCarouselItemImageContainer'>
                    <img src={item.image_url} alt='' />
                  </div>
                  <div className='supplierSuitableForCarouselItemImageCaptionContainer'>
                    {item[`name_${this.props.i18n.language}`]}
                  </div>
                </div>
              ))}
            </Carousel>
          ) : null}
        </div>
      </div>
    )
  }
}

SupplierSuitableForCarouselBox.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array
}
export default withTranslation()(SupplierSuitableForCarouselBox)
