import React from 'react'
import PropTypes from 'prop-types'
import './Label.scss'

export default function Label ({ className, icon, id, name }) {
  return (
    <span className={`label-wrapper ${className || ''}`} key={id || name}>
      {icon ? <i className={`label-icon ${icon}`} /> : null} {name}
    </span>
  )
}

Label.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string
}
