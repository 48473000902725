import React from 'react'
import './StaticRefinementSelect.scss'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

export const StaticRefinementSelect = ({
  options,
  appliedFilters = [],
  attribute,
  onChange,
  ariaLabel
}) => {
  const { t } = useTranslation()
  const selectedOptions = options.filter(option => appliedFilters.includes(option))
  return (
    <div className='filter-box'>
      <Select
        id={attribute}
        name={attribute}
        className='defaultSelectDropdown'
        classNamePrefix='defaultSelectDropdown'
        options={options}
        getOptionValue={(option) => option}
        getOptionLabel={(option) => t(`filters.${option}`)}
        placeholder={t('select_all_that_apply')}
        value={selectedOptions}
        isSearchable
        isClearable
        isMulti
        onChange={(selected) => {
          onChange(attribute, selected)
        }}
        style={{ margin: '6px 0' }}
        aria-label={ariaLabel}
      />
    </div>
  )
}
