import algoliasearch from 'algoliasearch'
import { connectHitInsights } from 'react-instantsearch-dom'
import aa from 'search-insights'

const algoliaClient = algoliasearch(
  window.ALGOLIA_APPLICATION_ID ? window.ALGOLIA_APPLICATION_ID : 'null',
  window.ALGOLIA_SEARCH_API_KEY ? window.ALGOLIA_SEARCH_API_KEY : 'null'
)

export const searchClient = {
  ...algoliaClient,
  search (requests) {
    return algoliaClient.search(requests)
  }
}

export const searchIndex = algoliaClient.initIndex(window.ALGOLIA_INDEX_NAME)
export const searchIndexMiceMoment = algoliaClient.initIndex(window.ALGOLIA_MOMENTS_INDEX_NAME)

export const getObjectsFromAlgolia = objectIDs => {
  const objectIDsToString = objectIDs.map(o => o.toString())

  return searchIndex
    .getObjects(objectIDsToString)
    .then(response => response.results)
}

export const getObjectFromAlgolia = objectID => {
  const objectIDToString = objectID.toString()
  return searchIndex.getObject(objectIDToString)
}

aa('init', {
  appId: (window.ALGOLIA_APPLICATION_ID ? window.ALGOLIA_APPLICATION_ID : 'null'),
  apiKey: (window.ALGOLIA_SEARCH_API_KEY ? window.ALGOLIA_SEARCH_API_KEY : 'null')
})

export const setupTracking = (key) => {
  aa('setUserToken', key)
}

export const connectInsightedHit = (comp) => {
  return connectHitInsights(aa)(comp)
}
