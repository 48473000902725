import React from 'react'
import './SupplierSuitableForBox.scss'

// FIXME: the name of the component may be wrong. It handles "category_*", not "suitable_for_*"
class SupplierSuitableForBox extends React.Component {
  /***
   * The properties expected by this component:
   * - imageUrl - the full path to the category icon-image
   * - label - (optional) text to be rendered next to the image.
   **/

  render () {
    return (
      <div className='suitableForBox'>
        <div className='suitableImageWrapper'>
          <img
            src={this.props.imageUrl}
            alt=''
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </div>
        <div className='suitableForLabelWrapper'>
          {this.props.label ? (
            <h3 className='c-font-uppercase c-title'>{this.props.label}</h3>
          ) : null}
        </div>
      </div>
    )
  }
}
export default SupplierSuitableForBox
