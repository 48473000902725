import React from 'react'
import './NumericInput.scss'

const NumericInput = ({ value, onChange, step = 5, name, ariaLabel }) => {
  const ref = React.useRef()
  const handleIncrement = () => {
    const currentValue = Number(value) || 0
    handleChange(currentValue + step)
    ref.current.focus()
  }

  const handleDecrement = () => {
    const currentValue = Number(value) || 0
    handleChange(currentValue - step)
    ref.current.focus()
  }

  const handleDirectValueChange = (e) => {
    const newValue = Number(e.target.value)
    handleChange(newValue)
  }

  const handleChange = (val) => {
    if (isValidNumber(val)) {
      onChange(val)
    } else {
      onChange('')
    }
  }

  const isValidNumber = (val) => {
    return !isNaN(val) && val > 0
  }

  return (
    <div className='input-group custom-input'>
      <div className='input-group-prepend'>
        <button
          className='btn btn-outline-secondary'
          type='button'
          onClick={handleDecrement}
          aria-label={`Minus ${ariaLabel}`}
        >
          <i className='far fa-minus' />
        </button>
      </div>
      <input
        ref={ref}
        name={name}
        type='text'
        className='form-control text-center'
        value={value || ''}
        onChange={handleDirectValueChange}
        aria-label={ariaLabel}
      />
      <div className='input-group-append'>
        <button
          className='btn btn-outline-secondary'
          type='button'
          onClick={handleIncrement}
          aria-label={`Plus ${ariaLabel}`}
        >
          <i className='far fa-plus' />
        </button>
      </div>
    </div>
  )
}

export default NumericInput
