import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import axios from 'axios'
import queryString from 'qs'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import './MiceMomentsExpandedView.scss'
import { MiceMomentsExpandedViewLoader } from '../../shared/contentLoaders'
import SupplierMainImageGallery from '../SupplierMainImageGallery/SupplierMainImageGallery'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import HashTags from '../HashTags/HashTags'
import SupplierImageGridGallery from '../SupplierImageGridGallery/SupplierImageGridGallery'
import SupplierPreview from '../SupplierPreview/SupplierPreview'
import copy from 'copy-to-clipboard'
import { toast } from 'react-toastify'
import ErrorView from '../ErrorView/ErrorView'
import { scrollToTop } from '../../shared/utils'
import MiceMomentButton from './MiceMomentButton'

function MiceMomentsExpandedView ({ match, t, history }) {
  const [miceMoment, setMiceMoment] = useState(null)
  const [error, setError] = useState(null)
  useEffect(() => {
    if (match && match.params && match.params.id) {
      const parts = match.params.id.split('-')
      axios
        .get(`/api/v2/mice_moments/${parts[0]}`)
        .then((response) => {
          if (response && response.status === 200) {
            setMiceMoment(response.data)
            scrollToTop()
          }
        })
        .catch((error) => {
          setError(error)
        })
    }
  }, [match.params.id])

  function share () {
    if (window && window.location && window.location.href && copy(window.location.href)) {
      toast.success(t('link_copied'), {
        position: 'bottom-right',
        autoClose: 5000
      })
    } else {
      toast.error(t('link_copy_failed'), {
        position: 'bottom-right',
        autoClose: 5000
      })
    }
  }

  function hashtagUrl (tagName) {
    return {
      pathname: '/moments',
      search: queryString.stringify({ query: tagName })
    }
  }

  return (
    <div className='container-xl'>
      {miceMoment
        ? (
          <>
            <Row className='ml-0 mr-0'>
              <Col className='mt-3 mb-3 pl-0 pr-0' xs={12}>
                <h2>{miceMoment.title}</h2>
              </Col>
              <Col className='pl-0 pr-0' xs={12}>
                <SupplierMainImageGallery
                  galleryImages={miceMoment.images || []}
                  showOverlayBox={false}
                />
              </Col>
              <Col className='pl-0 pr-0' xs={12}>
                <Col className='pl-0 pr-0 m-2' xs={4} md={2}>
                  <ButtonWithIcon
                    labelColor='#333333'
                    iconColor='#333333'
                    padding='4px 8px'
                    icon='fa fa-share'
                    iconSize='20px'
                    label={t('share')}
                    labelClassName='text-capitalize font-weight-normal'
                    className='justify-content-start pl-0'
                    onClick={share}
                  />
                </Col>
              </Col>
            </Row>
            <Row className='ml-0 mr-0'>
              <Col className='pl-0 pr-0' xs={12}>
                <HashTags
                  values={miceMoment.hashtags || []}
                  hrefCallback={hashtagUrl}
                  className='prominent'
                />
              </Col>
            </Row>
            <Row className='ml-0 mr-0 mt-3 mb-4'>
              <Col className='pl-0' xs={12} md={8}>
                <p className='mice-moment-description'>{miceMoment.description}</p>
              </Col>
              <Col className='pr-0' xs={12} md={4}>
                <div className='card'>
                  <Col className='mt-4 mb-4' xs={12}>
                    <ul className='list-unstyled mice-moment-meta-list d-none'>
                      <li>
                        <div className='icon-container'>
                          <i className='fa fa-users' />
                        </div>
                        25-40 People
                      </li>
                      <li>
                        <div className='icon-container'>
                          <i className='fa fa-clock-o' />
                        </div>
                        3 Days
                      </li>
                      <li>
                        <div className='icon-container'>
                          <i className='fa fa-map-marker' />
                        </div>
                        Munich, Germany
                      </li>
                      <li>
                        <div className='icon-container'>
                          <i className='fa fa-eur' />
                        </div>
                        Budget - 15k €
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} className='pl-4 pr-4 mb-4'>
                    <MiceMomentButton miceMoment={miceMoment} />
                  </Col>
                </div>
              </Col>
            </Row>
            <Row className='ml-0 mr-0 mt-2 mb-4'>
              <SupplierImageGridGallery images={miceMoment.images || []} direction='column' />
            </Row>
            <SupplierPreview deliverable={miceMoment.deliverable} />
          </>)
        : error ? <ErrorView error={error} history={history} /> : <MiceMomentsExpandedViewLoader />}
    </div>
  )
}

MiceMomentsExpandedView.propTypes = {
  match: PropTypes.object
}

export default withTranslation()(MiceMomentsExpandedView)
