import React from 'react'
import defaultLogo from '../../assets/images/logo.png'
import './Logo.scss'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
const LogoImage = window.CLIENT_INSTANCE_LOGO || defaultLogo

class Logo extends React.Component {
  render () {
    const { t } = this.props
    return (
      <div className='responsiveLogoContainer'>
        <div
          style={{ border: 'none', background: 'transparent' }}
          className='c-logo logo responsiveLogoButton'
        >
          <img
            src={this.props.logoImage ? this.props.logoImage : LogoImage}
            alt={(window && window.SHOP_NAME) || ''}
            className='c-desktop-logo responsiveLogo'
            title={t('page_head.start_page')}
          />
        </div>
      </div>
    )
  }
}

Logo.propTypes = {
  logoImage: PropTypes.string,
  t: PropTypes.func
}

export default withTranslation()(Logo)
