import React from 'react'
import { Container, Col, Row } from 'reactstrap'
import { connectMenu, Highlight } from 'react-instantsearch-dom'
import { useTranslation } from 'react-i18next'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import $ from 'jquery'

import DropMenu from '../DropMenu/DropMenu'
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon'
import {
  scrollToTop,
  getSupplierTypeMapping,
  getSupplierTypeFromMapping
} from '../../shared/utils'

import 'react-dates/lib/css/_datepicker.css'
import './FilterBar.scss'

function getFormattedProjectDate (projectDate) {
  if (projectDate) {
    const date = moment(projectDate)
    return date.format('YYYY-MM-DD HH:mm')
  }
  return null
}

function updateProjectDateParams (queryParams, startDate, endDate) {
  queryParams.update({
    start_date: getFormattedProjectDate(startDate),
    end_date: getFormattedProjectDate(endDate)
  })
}

function getProjectDateFromParams (projectDate) {
  const result = projectDate ? moment(projectDate, 'YYYY-MM-DD HH:mm') : null

  if (result && result.isValid()) return result

  return null
}

export function parseIntOrZero (param) {
  return parseInt(param || '0')
}

export function handleDropDownFilterFocus (elem) {
  if ($(elem.target).parents('.drop-down-menu').length === 1) {
    $('.TimePickerConfirm_button').click()
  }
}

export const FilterBarContainer = ({ children, isSupplierFilterBar = true }) => (
  <div id='filterBarContainer' className='filterBarContainer'>
    <Container className='filterBarResponsive' style={{ padding: '0px' }}>
      <div className={`filter-bar-wrapper ${isSupplierFilterBar ? 'supplier-filter-wrapper' : ''}`}>{children}</div>
    </Container>
  </div>
)

export const FilterBarElement = ({ className, children }) => (
  <Row
    className={`${className} filter-item-container`}
    onFocus={(elem) => handleDropDownFilterFocus(elem)}
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'column'
    }}
  >
    <Col
      xs={12}
      className='m-0 pl-0 pr-0 d-flex align-items-center justify-content-center'
    >
      {children}
    </Col>
  </Row>
)

function hasFilterApplied (queryParams, params) {
  let filtersApplied = 0
  params.forEach((value) => {
    if (queryParams.params && queryParams.params[value]) {
      filtersApplied++
    }
  })
  return filtersApplied
}

function getFilterClassName (queryParams, isOpen, params, noBackgroundHighlight = false) {
  let classname = ''
  if (noBackgroundHighlight) {
    return classname
  }
  if (isOpen) {
    classname = 'client-theme--bg client-theme--font-color'
  } else if (hasFilterApplied(queryParams, params)) {
    classname = 'client-theme--bg opacity-filter'
  }
  return classname
}

export const FilterDropMenu = ({
  size,
  additionalOpenClassName,
  queryParams,
  menuContainerWidth = '100%',
  name,
  fields,
  children,
  noBackgroundHighlight = false
}) => {
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()

  const klasses = [
    getFilterClassName(queryParams, open, fields, noBackgroundHighlight),
    'drop-down-menu'
  ]
  if (open) {
    klasses.push(additionalOpenClassName)
  }

  return (
    <FilterBarElement size={size} className={klasses.join(' ')}>
      <DropMenu
        menuLabel={t(name)}
        menuContainerWidth={menuContainerWidth}
        isOpen={open}
        onToggle={setOpen}
      >
        {children}
      </DropMenu>
    </FilterBarElement>
  )
}

const phrases = (t) => ({
  timeFromLabel: t('searches.timeFromLabel'),
  timeToLabel: t('searches.timeToLabel'),
  closeDatePicker: t('searches.closeDatePicker')
})

export const DatePickerElement = ({ queryParams }) => {
  const [focusedInput, setFocusedInput] = React.useState()
  const { t } = useTranslation()

  return (
    <FilterBarElement
      className='filterBarDate filter-item-container opacity-filter'
    >
      <DateRangePicker
        startDate={getProjectDateFromParams(queryParams.params.start_date)} // momentPropTypes.momentObj or null,
        startDateId='projectStartDate' // PropTypes.string.isRequired,
        startDatePlaceholderText={t('filter_date_from')}
        endDatePlaceholderText={t('filter_date_to')}
        endDate={getProjectDateFromParams(queryParams.params.end_date)} // momentPropTypes.momentObj or null,
        endDateId='projectEndDate' // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }) =>
          updateProjectDateParams(queryParams, startDate, endDate)}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        small
        keepOpenOnDateSelect
        displayFormat='DD.MM.YY'
        minimumNights={0}
        noBorder
        phrases={phrases(t)}
        hideKeyboardShortcutsPanel
        showDefaultInputIcon
        customArrowIcon={<i className='fal fa-angle-right' />}
      />
    </FilterBarElement>
  )
}

export const RangeFilterInput = ({
  value,
  onChange,
  onApply,
  showButton = true
}) => (
  <div className='range-filter-input-container'>
    <div className='range-filter-input'>
      <div
        className='input-change-button'
        onClick={() => onChange(Math.max(0, value - 1))}
      >
        <i className='fas fa-minus' />
      </div>
      <input
        type='number'
        className='filterRangeInputText'
        onChange={(e) => onChange(parseIntOrZero(e.target.value))}
        value={value}
      />
      <div className='input-change-button' onClick={() => onChange(value + 1)}>
        <i className='fas fa-plus' />
      </div>
    </div>
    {showButton ? (
      <div className='apply-button'>
        <ButtonWithIcon
          labelColor='#FFFFFF'
          iconColor='#FFFFFF'
          padding='6px 8px'
          className='client-theme--bg'
          label='OK'
          onClick={onApply}
        />
      </div>
    ) : null}
  </div>
)

export const FilterElementRange = ({
  queryParams,
  field,
  queryPath,
  additionalOpenClassName
}) => {
  const [value, setValue] = React.useState(
    parseIntOrZero(queryParams.params[field])
  )

  return (
    <FilterDropMenu
      size={2}
      queryParams={queryParams}
      name={`filter_${field}`}
      fields={[field]}
      additionalOpenClassName={additionalOpenClassName}
    >
      <RangeFilterInput
        value={value}
        onApply={() => queryParams.update({ [field]: value }, queryPath)}
        onChange={setValue}
      />
    </FilterDropMenu>
  )
}

function getItemLabel (t, item) {
  const label = item.label.toLowerCase()
  return (
    <>
      {`${t(`categories.${item.label.toLowerCase()}`)} (${item.count})`}
      <div>{t(`categories.${label}_subtext`)}</div>
    </>
  )
}

const DeliverableType = ({ queryParams, items, isFromSearch, refine }) => {
  const { t } = useTranslation()
  return (
    <ul className='filter-list'>
      {items.map((item) => (
        <li
          tabIndex='0'
          className='list-item'
          style={{
            fontWeight: item.isRefined ? '700' : '500'
          }}
          onClick={(event) => {
            event.preventDefault()
            refine(item.value)
            queryParams.update({
              type: getSupplierTypeFromMapping(item.value),
              facet_only: 1
            })
            scrollToTop()
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              refine(item.value)
              queryParams.update({
                type: getSupplierTypeFromMapping(item.value),
                facet_only: 1
              })
              scrollToTop()
            }
          }}
          key={item.value}
        >
          {isFromSearch ? (
            <Highlight attribute='label' hit={item} />
          ) : (
            getItemLabel(t, item)
          )}
        </li>
      ))}
    </ul>
  )
}

const ConnectedDeliverableType = connectMenu(DeliverableType)

export const FilterElementDeliverableType = ({ queryParams, size = 1, noBackgroundHighlight = false }) => {
  return (
    <FilterDropMenu
      size={size}
      queryParams={queryParams}
      name='filter_deliverable_type'
      fields={['type']}
      noBackgroundHighlight
    >
      <ConnectedDeliverableType
        attribute='deliverable_types'
        queryParams={queryParams}
        defaultRefinement={
          queryParams && queryParams.type
            ? getSupplierTypeMapping(queryParams.type)
            : null
        }
      />
    </FilterDropMenu>
  )
}
