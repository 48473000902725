import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Row, Col } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Layout from '../Layout/Layout'
import General from './General/General'
// the following imports can be made dynamic since we only show general tab at start
import Amenities from './Amenities/Amenities'
import ConferenceRooms from './ConferenceRooms/ConferenceRooms'
import Rooms from './Rooms/Rooms'
import MiceMoments from './MiceMoments/MiceMoments'
import SettingsPage from './SettingsPage/SettingsPage'
// end
import axios from 'axios'
import { scrollToTop, formatTimeFromSeconds } from '../../../shared/utils'
import CloudinaryUploadWidget from '../../UploadImage/CloudinaryUploadWidget'
import { wrapAsAnInstanceOf } from '../../../config/ability'
import { SupplierEditHomeLoader } from '../../../shared/contentLoaders'

class SupplierEditHome extends React.Component {
  constructor (props) {
    super(props)

    this.tabsConfigurations = {
      hotel: {
        showGeneral: true,
        showAmenities: true,
        showConferenceRooms: true,
        showRooms: true,
        showMiceMoments: true,
        showSettings: true
      },
      location: {
        showGeneral: true,
        showAmenities: true,
        showConferenceRooms: true,
        showRooms: false,
        showMiceMoments: true,
        showSettings: true
      },
      default: {
        showGeneral: true,
        showAmenities: false,
        showConferenceRooms: false,
        showRooms: false,
        showMiceMoments: true,
        showSettings: true
      }
    }

    this.state = {
      supplier: {},
      isLoading: true,
      tabIndex: 0,
      unsavedTab: null,
      lastModifiedState: null
    }

    this.getSupplier = this.getSupplier.bind(this)
    this.resetHandler = this.resetHandler.bind(this)
    this.updateHandler = this.updateHandler.bind(this)
    this.initializeUploadWidget = this.initializeUploadWidget.bind(this)
    this.getDeliverableTabsConfig = this.getDeliverableTabsConfig.bind(this)
    this.setLastModifiedState = this.setLastModifiedState.bind(this)
  }

  componentDidMount () {
    const { match } = this.props

    if (match !== undefined && match.params) {
      const id = match.params.userType === 'sales_managers' ? 'supplierID' : 'objectID'
      this.getSupplier(match.params[id])
    }

    scrollToTop()
    this.initializeUploadWidget()
  }

  updateHandler (supplier = null, unsavedTab) {
    if (supplier) {
      this.setState({ supplier: wrapAsAnInstanceOf(supplier, 'Supplier'), unsavedTab: unsavedTab }, () => this.setLastModifiedState())
    } else {
      this.setState({ unsavedTab })
    }
  }

  resetHandler () {
    const savedState = JSON.parse(this.state.lastModifiedState)
    this.setState({ ...savedState, supplier: wrapAsAnInstanceOf(savedState.supplier, 'Supplier'), unsavedTab: null, lastModifiedState: this.state.lastModifiedState }, () => this.initializeUploadWidget())
  }

  getSupplier (objectID) {
    axios
      .get(`/suppliers/${objectID}/edit.json?locale=${this.props.i18n.language}`)
      .then(response => {
        this.setState(() => ({
          supplier: wrapAsAnInstanceOf(response.data, 'Supplier'),
          isLoading: false
        }), () => this.setLastModifiedState())
      })
      .catch(error => {
        this.setState({ error: true, errorMessage: error, isLoading: false })
      })
  }

  getDeliverableTabsConfig () {
    const availableSettings = ['hotel', 'location']
    const deliverableType = this.props.match.params.deliverableType

    let deliverableTypeKey = 'default'

    if (availableSettings.indexOf(deliverableType) > -1) {
      deliverableTypeKey = deliverableType
    }

    return this.tabsConfigurations[deliverableTypeKey]
  }

  setLastModifiedState () {
    const savedState = { ...this.state }
    this.setState({ lastModifiedState: JSON.stringify(savedState) })
  }

  initializeUploadWidget () {
    const widget = new CloudinaryUploadWidget()
    this.setState({ uploadWidget: widget })
  }

  renderTab (tabName, tabTranslation) {
    const { t } = this.props

    if (this.state.unsavedTab === null || this.state.unsavedTab === tabName) {
      return (
        <Tab className={`react-tabs__tab ${this.state.unsavedTab ? 'tab-with-unsaved-changes' : ''}`}>
          {tabTranslation}
        </Tab>
      )
    } else {
      return (
        <Tab
          disabled
          title={t('unsaved_changes')}
          onClick={() => alert(t('unsaved_changes'))} // eslint-disable-line
        >
          {tabTranslation}
        </Tab>
      )
    }
  }

  renderTemporaryRecordHeader () {
    if (this.state.supplier) {
      const deliverable = this.state.supplier[`supplier_${this.props.match.params.deliverableType}`]

      if (deliverable && deliverable.temporary_will_be_deleted_in) {
        return (
          <Row className='m-0 mt-3 mb-3'>
            <Col md={12} className='alert alert-danger text-center'>
              {this.props.t('supplier.edit.temporary_record_message', { time: formatTimeFromSeconds(deliverable.temporary_will_be_deleted_in) })}
            </Col>
          </Row>
        )
      }
    }
  }

  render () {
    const { t } = this.props

    const tabsConfig = this.getDeliverableTabsConfig()
    const { deliverableType } = this.props.match.params
    if (this.state.isLoading) {
      return <SupplierEditHomeLoader />
    } else if (this.state.error) {
      throw new Error(this.state.errorMessage)
    } else {
      return (
        <Layout supplier={this.state.supplier}>
          {this.renderTemporaryRecordHeader()}

          <Row className='m-0 mt-3'>
            <Tabs defaultIndex={0} selectedTabClassName=' client-theme--bg client-theme--font-color react-tabs__tab--selected client-theme--border-color'>
              <TabList>
                {tabsConfig.showGeneral ? this.renderTab('General', t('general_information')) : null}
                {tabsConfig.showAmenities ? this.renderTab('Amenities', t('amenities')) : null}
                {tabsConfig.showConferenceRooms ? this.renderTab('ConferenceRooms', t('conference_rooms')) : null}
                {tabsConfig.showRooms ? this.renderTab('Rooms', t('rooms')) : null}
                {tabsConfig.showMiceMoments ? this.renderTab('MiceMoments', t('mice_moments')) : null}
                {tabsConfig.showSettings ? this.renderTab('SettingsPage', t('settings')) : null}
              </TabList>

              {tabsConfig.showGeneral ? (
                <TabPanel>
                  <General
                    uploadWidget={this.state.uploadWidget}
                    supplier={this.state.supplier}
                    deliverableType={deliverableType}
                    updateHandler={this.updateHandler}
                    resetHandler={this.resetHandler}
                  />
                </TabPanel>
              ) : null}

              {tabsConfig.showAmenities ? (
                <TabPanel>
                  <Amenities
                    uploadWidget={this.state.uploadWidget}
                    supplier={this.state.supplier}
                    deliverableType={deliverableType}
                    updateHandler={this.updateHandler}
                    resetHandler={this.resetHandler}
                  />
                </TabPanel>
              ) : null}

              {tabsConfig.showConferenceRooms ? (
                <TabPanel>
                  <ConferenceRooms
                    uploadWidget={this.state.uploadWidget}
                    supplier={this.state.supplier}
                    deliverableType={deliverableType}
                    updateHandler={this.updateHandler}
                    resetHandler={this.resetHandler}
                  />
                </TabPanel>
              ) : null}

              {tabsConfig.showRooms ? (
                <TabPanel>
                  <Rooms
                    supplier={this.state.supplier}
                    updateHandler={this.updateHandler}
                    resetHandler={this.resetHandler}
                  />
                </TabPanel>
              ) : null}

              {tabsConfig.showMiceMoments ? (
                <TabPanel>
                  <MiceMoments
                    supplier={this.state.supplier}
                    deliverableType={deliverableType}
                  />
                </TabPanel>
              ) : null}

              {tabsConfig.showSettings ? (
                <TabPanel>
                  <SettingsPage
                    supplier={this.state.supplier}
                    updateHandler={this.updateHandler}
                    resetHandler={this.resetHandler}
                    enableUnsavedChangesTracker
                  />
                </TabPanel>
              ) : null}
            </Tabs>
          </Row>
        </Layout>
      )
    }
  }
}

SupplierEditHome.propTypes = {
  match: PropTypes.object
}

export default withRouter(withTranslation()(SupplierEditHome))
