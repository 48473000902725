import React from 'react'
import '../SupplierAdmin.scss'
import Header from './Header'
import Footer from '../../Layout/Footer'
import { withRouter } from 'react-router-dom'
import { withLanguageSwitcher } from '../../../shared/languageHelpers'
import ButtonWithIcon from '../../ButtonWithIcon/ButtonWithIcon'
import { withTranslation } from 'react-i18next'

const loadScript = (url) => new Promise((resolve, reject) => {
  const s = document.createElement('script')
  s.src = url
  s.onload = resolve
  s.onerror = reject
  s.async = true
  document.body.appendChild(s)
})

class Layout extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showStagingHeader: true
    }
    this.listenToScroll = this.listenToScroll.bind(this)
    this.initializeUserlane = this.initializeUserlane.bind(this)
    this.handleToggleStagingHeader = this.handleToggleStagingHeader.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.listenToScroll)
    if (!window.Userlane && window.USERLANE_URL) {
      loadScript(window.USERLANE_URL).then(() => this.initializeUserlane())
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.showStagingHeader !== this.state.showStagingHeader) {
      // not the best solution but the DOM element that we need to manipulate is coming from a different project (Konvenit), hence this is the best approach
      const staging = document.getElementById('staging')
      if (staging) {
        if (this.state.showStagingHeader) {
          staging.style.display = 'block'
        } else {
          staging.style.display = 'none'
        }
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  initializeUserlane () {
    if (window.KONVENIT_ENVIRONMENT === 'production') {
      if (window.Userlane && window.USERLANE_PROPERTY_ID) {
        window.Userlane('identify', window.HASHED_CURRENT_PERSON_ID || '', {
          user_creation_date: window.USER_CREATION_DATE || '',
          shop_id: window.SHOP_ID || ''
        })
        window.Userlane('init', window.USERLANE_PROPERTY_ID)
      } else {
        console.error('Userlane Initialization Failed')
      }
    } else {
      console.log('Userlane not initialized because you are on dev env')
    }
  }

  listenToScroll () {
    if (window.pageYOffset > 20) {
      document.body.classList.add('c-page-on-scroll') // we should not manipulate DOM directly, we should refactor this code
    } else {
      document.body.classList.remove('c-page-on-scroll')
    }
  }

  handleToggleStagingHeader () {
    this.setState(prevState => ({
      showStagingHeader: !prevState.showStagingHeader
    }))
  }

  render () {
    return (
      <>
        <Header supplier={this.props.supplier} hideNavBar={this.props.hideNavBar} />

        <div className='c-layout-page layoutContainer sticky-header--inactive' id='layoutContainer'>
          {this.props.children}
        </div>
        {window && window.STAGING ? (
          <div className='staging-button'>
            <ButtonWithIcon
              labelColor='#FFFFFF'
              iconColor='#FFFFFF'
              padding='6px 8px'
              label={this.state.showStagingHeader ? this.props.t('hide_staging_header') : this.props.t('show_staging_header')}
              className='client-theme--bg'
              onClick={this.handleToggleStagingHeader}
            />
          </div>) : null}
        <Footer />
      </>
    )
  }
}

export default (withTranslation())(withRouter(withLanguageSwitcher(Layout)))
