import IndexParams from './IndexParams'

export const supplierFacets = {
  type: 'deliverable_types',
  suitable: 'suitable',
  chain_name: 'chain_name'
}
export const supplierFilters = {
  capacity_number_of_conference_rooms: 'conference_rooms',
  rooms: 'rooms',
  micepoints: ['micepoints', 'dehoga'],
  classification: 'classification',
  capacity_event_floor_space: 'capacity_event_floor_space',
  capacity_max_square_meter: 'capacity_max_square_meter'
}

class SuppliersIndexParams extends IndexParams {
  get index () {
    return window.ALGOLIA_INDEX_NAME
  }

  ignoredSuppliersFilterKeys () {
    return this.searchParams.facet_only ? Object.keys(supplierFacets) : []
  }

  algoliaKeyParamToFilter (key) {
    if (key === 'rooms') return 'number_of_rooms'
    if (key === 'dehoga') return 'classification'
    return super.algoliaKeyParamToFilter(key)
  }

  /* Returns a long (possibly) string with various search
   * criteria joined with " AND ".
   * That string is supposed to be consumed as a valid Algolia filter query.
   * */
  getAllFilters () {
    /* all these functions willbe called with: (checkFun, allParams)
     * The may call `checkFun(key)` to easily get the value only if it passes a test of isValueValid()
     * */
    const ignoredKeys = this.ignoredSuppliersFilterKeys()
    return this.getAllFiltersUsing(
      this.getContractFilter,
      this.getMatchingSuppliersFilterWithout(ignoredKeys),
      this.getHashtagFilter,
      this.supplierGetCountryFilter,
      this.supplierGetCertificateFilter,
      this.getAreaOfOperationFilter,
      this.supplierGetSustainableFilter,
      (check) => {
        return check('algolia')
      },
      (check) => {
        return check('suppliers')
      }
    )
  }

  // XXX: What is the purpose of this function? When is it used?
  //
  getAllowedFacets (key = null, value = null) {
    if (value) {
      switch (key) {
        case 'airplus':
        case 'amenity':
        case 'capacity_event_floor_space':
        case 'capacity_max_conference_capacity':
        case 'capacity_max_height':
        case 'capacity_max_square_meter':
        case 'capacity_number_of_conference_rooms':
        case 'capacity_outdoor_floor_space':
        case 'category':
        case 'chain_name':
        case 'classification':
        case 'conference_equipment':
        case 'distance_airport':
        case 'participants':
        case 'distance_city':
        case 'distance_convention':
        case 'distance_local_station':
        case 'distance_main_road':
        case 'distance_station':
        case 'equipment_amenity':
        case 'e_parking_spaces':
        case 'gastronomy_amenity':
        case 'location_indoor':
        case 'location_outdoor':
        case 'micepoints':
        case 'number_of_rooms':
        case 'payment_methods':
        case 'parking_spaces':
        case 'professional':
        case 'query':
        case 'room_amenity':
        case 'sport_amenity':
        case 'suitable':
        case 'type':
        case 'wellness_amenity':
          return { key, value }
        default:
          return false
      }
    } else {
      return false
    }
  }

  supplierGetCountryFilter (check) {
    const c = check('country')
    return c ? `(country.en:${c} OR country.de:${c})` : ''
  }

  supplierGetCertificateFilter (check) {
    const certificates = check('certificates')
    if (!certificates) return ''

    const filterTag = []
    certificates.split(',').forEach((cert) => {
      const v = cert.trim()
      if (v) filterTag.push(`certificates:${v}`)
    })

    return filterTag.length > 0 ? `(${filterTag.join(' OR ')})` : null
  }

  supplierGetSustainableFilter (check) {
    return check('sustainable') ? 'sustainable:true' : null
  }
}

export default SuppliersIndexParams
