import React from 'react'
import { useTranslation } from 'react-i18next'
import frogImage from '../../assets/images/mice-logo-frog.png'

function ErrorPage () {
  const { t } = useTranslation()
  return (
    <div className='d-flex align-items-stretch justify-content-center mt-5'>
      <div className='mr-4'>
        <img
          src={frogImage}
          alt={t('mice_portal')}
          className='c-desktop-logo responsiveLogo'
        />
      </div>
      <div>
        <h1>{t('something_went_wrong')}</h1>
        <p className='mt-2'>
          {t('please_try_reloading_the_page_we_re_working_on_a_solution')}
        </p>
      </div>
    </div>
  )
}

export default ErrorPage
