import i18n from 'i18next'
import React, { Component } from 'react'
import queryString from 'qs'
import { extractQueryParams } from '../services/urlStateParser'

export const withLanguageSwitcher = (WrappedComponent) => {
  return class extends Component {
    componentDidMount () {
      const queryParams = extractQueryParams(this.props)

      if (queryParams.locale) {
        i18n.changeLanguage(queryParams.locale)
      } else {
        this.props.history.replace({
          pathname: this.props.location.pathname,
          search: queryString.stringify({ ...queryParams, locale: i18n.language })
        })
      }
    }

    componentDidUpdate () {
      const locale = extractQueryParams(this.props).locale

      if (!!locale && locale !== i18n.language) {
        i18n.changeLanguage(locale)
      }
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }
}
