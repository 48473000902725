import React from 'react'
import './SupplierStickyActionBar.scss'
import HotelRating from '../HotelRating/HotelRating'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { truncate } from '../../shared/utils'
import SupplierContract from '../SupplierContract/SupplierContract'
import AddToCart from '../Cart/AddToCart'

class SupplierStickyActionBar extends React.Component {
  render () {
    return (
      <div
        style={{
          display: this.props.isStickyBottomBarActive ? 'flex' : 'none'
        }}
        className='c-content-box c-size-s c-bg-dark c-bg-opacity-4 supplierStickyActionBarContainer client-theme--border-color invisible-mobile'
      >
        <div className='row supplierStickyActionBarWrapper'>
          <div className='col-md-6'>
            <div className='row'>
              {this.props.logo ? (
                <div className='col-md-2 col-sm-2'>
                  <div className='stickyActionBarLogoContainer'>
                    <img src={this.props.logo.url} alt='' />
                  </div>
                </div>
              ) : null}
              <div className={`${this.props.logo ? 'col-md-10 col-sm-10' : 'col-md-12 col-sm-12'}`}>
                <h3
                  className='c-font-uppercase'
                  style={{
                    fontWeight: 700,
                    fontSize: '1.2rem',
                    color: '#fff'
                  }}
                >
                  {truncate(this.props.hit.name, 30)}
                  <div className='stickyActionBarRatingContainer client-theme--color'>
                    <HotelRating hotel={this.props.hit} class='fa-2x' />
                  </div>
                </h3>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-md-8 col-sm-6 stickyActionBarContractsContainer'>
                <SupplierContract
                  contracts={this.props.hit.contracts}
                  contractMainPriceClass='stickyActionBarContractMainPrice'
                  contractMainServiceClass='stickyActionBarContractMainService'
                  contractMainContainerClass='stickyActionBarContractMainContainer'
                  projectStartDateUnix={this.props.projectStartDateUnix}
                />
              </div>
              <div className='col-md-4 col-sm-6 actionButtonsContainer'>
                <AddToCart
                  hit={this.props.hit}
                  notInCartButtonWidth='100%'
                  addButtonPadding='8px 8px'
                  containerJustifyContent='flex-end'
                  deleteIconStyle={{ color: '#FFFFFF' }}
                  type={this.props.view}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SupplierStickyActionBar.propTypes = {
  hit: PropTypes.object,
  isStickyBottomBarActive: PropTypes.bool,
  logo: PropTypes.string,
  projectStartDateUnix: PropTypes.number
}

const mapStateToProps = state => {
  return {
    isStickyBottomBarActive: state.app.isStickyBottomBarActive
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierStickyActionBar)
