import React from 'react'
import { Redirect } from 'react-router-dom'
import { createCanBoundTo } from '@casl/react'
import PropTypes from 'prop-types'
import ability from '../../config/ability'

const CanBound = createCanBoundTo(ability)

const Can = ({ children, perform, on, shouldRedirect, redirectTo, not: reject }) => (
  <>
    <CanBound
      do={perform}
      on={on}
      ability={ability}
      {...reject && { not: reject }}
    >
      {children}
    </CanBound>

    {!reject && shouldRedirect ? (
      <CanBound not do={perform} on={on} ability={ability}>
        {/* {location.href = redirectTo || "/pages/access_denied"} */}
        <Redirect to={redirectTo || '/access_denied'} />
      </CanBound>
    ) : null}
  </>
)

Can.propTypes = {
  perform: PropTypes.string.isRequired,
  on: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  redirectTo: PropTypes.string,
  shouldRedirect: PropTypes.bool,
  not: PropTypes.bool
}

export default Can
